import styled from 'styled-components';
import colors from '../../../../UI/colors';

export const Coutry = styled.img`
  height: 30px;
  width: 30px;
`;

export const ButtonMenu = styled.button`
  background: #fff;
  display: flex;
  height: 40px;
  width: 40px;
  border: none;
  border-bottom: solid ${(props) => (props.valid ? '2px' : '1.5px')};
  border-color: ${(props) =>
    props.valid ? colors.green_secondary : colors.dark_light};
  justify-items: center;
  align-items: center;
`;

export const DivPhone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
