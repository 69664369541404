import React, {useState} from 'react';
import styled from 'styled-components';
import {Star} from './styles';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import I18n from '~/i18n/i18';

export default function InputTextRequire(props) {
  const [viewMessage, setViewMessage] = useState(false);

  function change(event) {
    if (!props.readOnly) {
      if (props.mask) {
        props.mask(event, props.setValue);
      } else {
        props.setValue(event.target.value);
      }
    }
  }

  function handleBlur() {
    props.onFocusOut && props.onFocusOut();
    if (props.value) {
      setViewMessage(false);

      if (props.min && props.max) {
        if (
          props.value.length === props.min ||
          props.value.length === props.max
        ) {
          setViewMessage(false);
        } else if (props.isOptional) {
          setViewMessage(false);
        } else {
          setViewMessage(true);
        }
      }
    } else if (props.isOptional) {
      setViewMessage(false);
    } else {
      setViewMessage(true);
    }
  }

  return (
    <div>
      <Label readOnly={props.readOnly} color={props.value}>
        {props.label} {!props.isOptional && <Star>*</Star>}
      </Label>
      <Input
        onBlur={() => {
          handleBlur();
        }}
        readOnly={props.readOnly}
        disabled={props.disable}
        minLength={props.min ? props.min : null}
        maxLength={props.max ? props.max : null}
        color={props.value}
        value={props.value}
        onChange={(event) => change(event)}
        itens={props.itens}
        placeholder={props.placeHolder}
      />
      {viewMessage ? (
        <Message>
          <I18n t="RequiredField.message" />
        </Message>
      ) : null}
    </div>
  );
}

const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.readOnly
      ? '1px solid #808076'
      : props.value
      ? `2px solid ${colors.green_secondary}`
      : '1px solid #808076'};
  color: ${(props) =>
    props.readOnly ? '#808076' : props.value ? '#1D1D1B' : '#808076'};
  width: 100%;
  font-size: 16px;
  font-family: ${fonts.latoRegular};
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  outline: 0;

  &:disabled {
  }
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  color: ${(props) =>
    props.readOnly
      ? '#808076'
      : props.color
      ? colors.green_secondary
      : '#808076'};
`;

const Message = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 13px;
  color: red;
`;
