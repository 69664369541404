import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import userImg from '~/assets/iconPerson.jpg';

import * as S from './styles';
/* &paginate=true&page=1&limit=5 */
const getSubscribers = (value) =>
  apiBackend.get(`/v1/users-payment?term=${value?.trim()}`, {
    headers: {
      Authorization: `Bearer ${desencrypta(
        localStorage.getItem('@User_informations'),
      )}`,
    },
  });
const searchAPIDebounced = AwesomeDebouncePromise(getSubscribers, 600);

export default function AutoSuggestSubscribers({
  setSuggestItem,
  placeholder = '',
  value = '',
  handleChange,
  handleBlur,
}) {
  const [suggestions, setSuggestions] = useState([]);

  const onChange = (event, {newValue}) => {
    handleChange(newValue);
  };

  const onBlur = (event) => {
    handleBlur(event.target.value);
  };

  const getSuggestionValue = (suggestion) => {
    setSuggestItem(suggestion);

    const name = `${suggestion?.name}${
      suggestion?.surname ? ` ${suggestion?.surname}` : ''
    }`;
    const isBorkerUser =
      suggestion?.profile?.type_advertiser?.toLowerCase() === 'broker';
    const enterpriseName = suggestion?.profile?.enterprise ?? name;

    if (isBorkerUser) {
      return `${enterpriseName}`;
    } else {
      return `${name}`;
    }
  };

  const renderSuggestion = (suggestion) => (
    <S.SuggestItem>
      <div>
        <img src={suggestion?.avatar?.avatar_url || userImg} alt="||" />
      </div>
      <p>
        {suggestion?.profile?.type_advertiser?.toLowerCase() === 'broker' ? (
          <>
            {suggestion?.profile?.enterprise ??
              `${suggestion?.name} ${suggestion?.surname ?? ''}`}
          </>
        ) : (
          <>
            {suggestion?.name} {suggestion?.surname ?? ''}
          </>
        )}
        <span>{suggestion?.email}</span>
      </p>
    </S.SuggestItem>
  );

  const onSuggestionsFetchRequested = async ({value}) => {
    const newArray = await searchAPIDebounced(value);

    setSuggestions(newArray?.data);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const shouldRenderSuggestions = (value) => {
    return value?.trim()?.length >= 0;
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    onBlur,
  };

  return (
    <S.AutoSuggestContainer>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion={true}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </S.AutoSuggestContainer>
  );
}

AutoSuggestSubscribers.propTypes = {
  setSuggestItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
};
