import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import Autocomplete from 'react-google-autocomplete';
import {toMoney} from 'vanilla-masker';
import I18n from '~/i18n/i18';
import {getRoute, desencrypta} from '~/utils';
import {ModalResponsive} from '../../Components/styles';
import {InternDiv, TitleModal, DivInputText, ButtonSave} from './styles';

import {
  DivSelectMetrics,
  DivMetrics,
  DivInputMetrics,
  DivText,
} from '../../Financial/styles';
import Dimension from '../../Components/dimension';
import SelectMenuRequiredI18n from '../../Components/selectMenuRequiredI18n';
import InputText from '../../Components/inputText';
import {Label} from '../../Localize/LocalizeOne/styles';
import baseURL from '~/services/api';
import InputTextRequire from '../../Components/inputTextRequire';
import apiBackend from '~/services/apiaxios';
import {MessageInputs} from '../ModalDelete/styles';
import MenuChangeObject from '../../NewComponents/MenuChanceObjectRequired';
import InputTextRequireSelect from '../../Components/InputRequireSelect';

import * as Sentry from '@sentry/react';

import fonts from '~/UI/fonts';
import {geoCodeDesired} from '~/services/Geocode/geocode';
import colors from '~/UI/colors';

const itensDimension = ['BRL', 'USD', 'EUR'];
const itensCountry = ['Brazil', 'United States', 'Portugal'];

export default function ModalDesired({
  open,
  setOpen,
  id,
  getProperty,
  itensBackend,
}) {
  const [type, setType] = useState('');
  const [selectedValueCountry, setSelectedValueCountry] = useState('Brazil');
  const [utf, setUtf] = useState('Br');
  const [loadGoogle, setLoadGoogle] = useState('');
  const [classGoogleC, setClassGoogleC] = useState('normal');

  const [addrees, setAdress] = useState('');
  const [disable, setDisable] = useState('');
  const [digit, setDigit] = useState('');
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/profile`,
    state: {fromDashboard: true},
  });
  const [choseMetric, setChoseMetric] = useState('USD');
  const [priceMax, setPriceMax] = useState('');
  const [priceMin, setPriceMin] = useState('');
  const [enableButton, setEnableButton] = useState(true);

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [viewLat, setViewLat] = useState('');
  const [viewLon, setViewLon] = useState('');
  const [viewLat2, setViewLat2] = useState('');
  const [viewLon2, setViewLon2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');

  const [address, setAddress] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');

  const [spaceMax, setSpaceMax] = useState(false);
  const [spaceMin, setSpaceMin] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/profile`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  const handleClose = () => {
    setOpen(false);
  };

  async function sendMatches() {
    try {
      setLoading(true);
      let token = desencrypta(localStorage.getItem('@User_informations'));
      const myData = {
        country: selectedValueCountry,
        state,
        city,
        neighborhood,
        property_type_id: 1,
        min_price: convert(priceMin),
        max_price: convert(priceMax),
        currency: choseMetric,
        latitude: latitude,
        longitude: longitude,
        view_lat: viewLat,
        view_lon: viewLon,
        view_lat2: viewLat2,
        view_lon2: viewLon2,
      };

      const {data} = await apiBackend.post(
        `/v1/property/${id}/intention`,
        myData,
        {headers: {Authorization: `Bearer ${token}`}},
      );
      console.log(data);
      getProperty();
      setSelectedValueCountry('Brazil');
      setState('');
      setCity('');
      setType('');
      setPriceMin('');
      setPriceMax('');
      setNeighborhood('');
      setOpen(false);
      setClassGoogleC('normal');
      /* TODO novo */
      setLatitude('');
      setLongitude('');
      setViewLat('');
      setViewLon('');
      setViewLat2('');
      setViewLon2('');
    } catch (err) {
      Sentry.captureException(err);
    } finally {
    }
  }

  useEffect(() => {
    if (selectedValueCountry === 'Brazil') {
      setUtf('Br');
    } else if (selectedValueCountry === 'United States') {
      setUtf('Us');
    } else if (selectedValueCountry === 'Portugal') {
      setUtf('Pt');
    } else {
      setUtf('Us');
    }
    setLoadGoogle(true);
  }, [selectedValueCountry]);

  useEffect(() => {
    if (priceMax && priceMin && selectedValueCountry && type !== '') {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  }, [priceMax, priceMin, selectedValueCountry, type]);

  function renderGoogleAddress() {
    return (
      <Autocomplete
        value={address}
        id="input-auto-test"
        style={{marginBottom: '20px'}}
        onChange={(event) => {
          setAddress(event.target.value);
        }}
        onBlur={(event) => {
          const {value} = event.target;

          if (value !== selectedAddress) {
            setAddress(() => {
              setState('');
              setCity('');
              setNeighborhood('');
              setViewLat('');
              setViewLon('');
              setLatitude('');
              setLongitude('');
              return '';
            });
          }
        }}
        onPlaceSelected={(place) => {
          insert(place, 1);
        }}
        placeholder={I18n.getTranslation(
          location,
          'Localization.placeholderAddress',
        )}
        types={['(regions)']}
        className={classGoogleC}
        componentRestrictions={{country: utf}}
      />
    );
  }

  useEffect(() => {
    if (address) {
      setClassGoogleC('value');
    } else {
      setClassGoogleC('normal');
    }
  }, [address]);

  useEffect(() => {
    setAddress(() => {
      setState('');
      setCity('');
      setNeighborhood('');
      setViewLat('');
      setViewLon('');
      setLatitude('');
      setLongitude('');
      return '';
    });
  }, [selectedValueCountry]);

  function insert(place, number) {
    setAddress(() => {
      setState('');
      setCity('');
      setNeighborhood('');
      setViewLat('');
      setViewLon('');
      setLatitude('');
      setLongitude('');
      return '';
    });

    if (number === 1) {
      geoCodeDesired({
        place_id: place.place_id,
        setLatitude,
        setLongitude,
        setViewLat,
        setViewLon,
        setViewLat2,
        setViewLon2,
      });
      let final_address = [];
      console.log(place);

      place.address_components.forEach((item) => {
        if (
          item.types[0] === 'sublocality_level_1' ||
          item.types[0] === 'sublocality'
        ) {
          setNeighborhood(item.long_name);
          final_address.push(item.long_name);
        } else if (
          item.types[0] === 'locality' ||
          (item.types[0] === 'administrative_area_level_2' && utf !== 'Us')
        ) {
          setCity(item.long_name);
          final_address.push(item.long_name);
        } else if (
          item.types[0] === 'administrative_area_level_1' &&
          utf !== 'Pt'
        ) {
          setState(item.short_name);
          final_address.push(item.short_name);
        }
      });
      console.log(final_address);
      setSelectedAddress(() => {
        const myAddress = final_address
          ?.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          ?.join(', ');
        setAddress(myAddress);

        return myAddress;
      });
    }
  }

  function mask(e, setValue) {
    const float = e.target.value;
    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function transformArea(value) {
    const data = value;
    console.log(value);

    if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      const p1 = data.split('.').join('');

      const transform = parseFloat(p1);
      return transform.toFixed(2);
    }
    const p1 = data.replace(/,/g, '');
    const transform = parseFloat(p1);
    return transform.toFixed(2);
  }

  function convert(value) {
    const data = transformArea(value);
    return data;
  }

  function spaceDimensionMax(value) {
    console.log(value);
    if (value) setSpaceMax(true);
    else setSpaceMax(false);
  }

  function spaceDimensionMin(value) {
    if (value) setSpaceMin(true);
    else setSpaceMin(false);
  }

  function maskChange(e, setValue) {
    const float = e;
    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  useEffect(() => {
    if (priceMin) {
      maskChange(priceMin, setPriceMin);
    }

    if (priceMax) {
      maskChange(priceMax, setPriceMax);
    }
  }, [choseMetric]);

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span>
              <I18n t="DesiredProperty.ModalTitle" />{' '}
            </span>
            <CloseIcon onClick={() => handleClose()} className="iconClose" />
          </TitleModal>
          <ModalResponsive>
            <InternDiv>
              <div>
                <DivInputText>
                  <SelectMenuRequiredI18n
                    i18n="Country"
                    placeHolder={I18n.getTranslation(
                      location,
                      'Localization.placeHolderCountry',
                    )}
                    label={I18n.getTranslation(
                      location,
                      'Localization.labelCountry',
                    )}
                    itens={itensCountry}
                    value={selectedValueCountry}
                    setValue={setSelectedValueCountry}
                  />
                </DivInputText>

                {/*  <DivInputText>
                  <InputText
                    placeHolder={I18n.getTranslation(
                      location,
                      'Localization.placeHolderNeig',
                    )}
                    label={I18n.getTranslation(
                      location,
                      'Localization.labelNeig',
                    )}
                    value={neighborhood}
                    setValue={setNeighborhood}
                  />
                </DivInputText> */}

                <DivInputText>
                  <Label classes={classGoogleC}>
                    <I18n t="Localization.labelAddress" />
                  </Label>
                  {utf === 'Br' && renderGoogleAddress()}
                  {utf === 'Us' && renderGoogleAddress()}
                  {utf === 'Pt' && renderGoogleAddress()}
                </DivInputText>

                {/*    <DivInputText>
                  <InputText
                    placeHolder={I18n.getTranslation(
                      location,
                      'Localization.placeHolderState',
                    )}
                    label={I18n.getTranslation(
                      location,
                      'Localization.labelState',
                    )}
                    value={state}
                    setValue={setState}
                  />
                </DivInputText> */}

                <DivInputText>
                  <MenuChangeObject
                    i18n="PropertyType"
                    placeHolder={I18n.getTranslation(
                      location,
                      'Type.placeholder',
                    )}
                    label={I18n.getTranslation(location, 'Type.rotule')}
                    itensBackend={itensBackend}
                    value={type}
                    setValue={setType}
                  />
                </DivInputText>

                <DivMetrics value={priceMin}>
                  <DivInputMetrics>
                    <InputTextRequireSelect
                      noBorder
                      func={spaceDimensionMin}
                      mask={mask}
                      placeHolder={I18n.getTranslation(
                        location,
                        'ModalFilter.Digit',
                      )}
                      label={<I18n t="ModalFilter.ValueMin" />}
                      value={priceMin}
                      setValue={setPriceMin}
                    />
                  </DivInputMetrics>
                  <DivSelectMetrics noBorder value={priceMin}>
                    <Dimension
                      pdTop={30}
                      value={choseMetric}
                      setValue={setChoseMetric}
                      itens={itensDimension}
                    />
                  </DivSelectMetrics>
                </DivMetrics>
                {spaceMin ? (
                  <MessageInputs>
                    <I18n t="RequiredField.message" />
                  </MessageInputs>
                ) : null}

                <DivMetrics value={priceMax} className="mg-top-30">
                  <DivInputMetrics>
                    <InputTextRequireSelect
                      noBorder
                      func={spaceDimensionMax}
                      mask={mask}
                      placeHolder={I18n.getTranslation(
                        location,
                        'ModalFilter.Digit',
                      )}
                      label={<I18n t="ModalFilter.ValueMax" />}
                      value={priceMax}
                      setValue={setPriceMax}
                    />
                  </DivInputMetrics>
                  <DivSelectMetrics noBorder value={priceMax}>
                    <DivText>
                      <Dimension
                        value={choseMetric}
                        setValue={setChoseMetric}
                        itens={itensDimension}
                      />
                    </DivText>
                  </DivSelectMetrics>
                </DivMetrics>
                {spaceMax ? (
                  <MessageInputs>
                    <I18n t="RequiredField.message" />
                  </MessageInputs>
                ) : null}
              </div>
              <ButtonSave
                disabled={enableButton || loading}
                onClick={() => {
                  sendMatches();
                }}
                className="btnDataAddress btnload">
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color={colors.green_secondary}
                    width="30px"
                    height="30px"
                  />
                ) : (
                  <>
                    + <I18n t="DesiredProperty.AddAds" />
                  </>
                )}
              </ButtonSave>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
      {/* <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="success">
                {t("Placeholders.editUser")}
            </Alert>
        </Snackbar> */}
    </>
  );
}
