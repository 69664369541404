import styled from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const ContainerIntentions = styled.div`
  padding: 10px 20px 10px 0px;
  display: flex;

  @media (max-width: 560px) {
    padding: 6px 6px 6px 0px;
  }
`;

export const TitleIntentions = styled.div`
  font-family: ${fonts.latoBold};
  font-size: 18px;
  color: #1d1d1b;
`;

export const Key = styled.span`
  font-size: 14px;
  color: ${colors.green_secondary};
  font-family: ${fonts.latoBold};
`;

export const Value = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark};
  font-family: ${fonts.latoRegular};

  &::before {
    content: '•';
    color: ${colors.green_secondary};
    display: inline-block;
    width: 1em;
    margin-right: 0.5em;
    margin-left: -0.4em;
    text-align: right;
    direction: rtl;
  }
`;
