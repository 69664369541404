/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dialog} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ReactLoading from 'react-loading';
import apiBackend from '~/services/apiaxios';
import errorXml from '~/services/docs/errorxml';
import colors from '~/UI/colors';
import {desencrypta} from '~/utils';
import {fireAction} from '~/utils/swal';
import {fireActionMethods} from '~/utils/swalMethods';
import {
  ButtonSave,
  DivInputText,
  InternDiv,
  ModalContainer,
  TitleModal,
} from '~/views/User/styles';
import ModalLogs from '../ModalLinkXMLLogs';

import InputTextArea from '../textArea';
import './styles.css';

import * as Sentry from '@sentry/react';

export default function ModalDataPersonal({open, setOpen, info, getAllLeads}) {
  const [linkXml, setLinkXml] = React.useState(
    info.xml && info.xml?.url ? info.xml?.url : '',
  );
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [openLogs, setOpenLogs] = React.useState(false);
  const [log, setLog] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setLinkXml(info.xml && info.xml.url ? info.xml.url : '');
    } else {
      setLinkXml('');
    }
  }, [info, open]);

  async function verifySintaxeXml() {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    setLoading(true);
    if (info?.xml && info.xml.url) {
      try {
        const {data} = await apiBackend.get(
          `v1/xml/validate/sintaxe?url=${linkXml}`,
          {
            headers: {Authorization: `Bearer ${token}`},
          },
        );

        sendLinkXMl();
      } catch (err) {
        console.log(err?.response?.data?.isValid?.err);
        let status = err?.response?.status;
        let message = err?.response?.data?.data?.message;

        if (
          status === 400 &&
          message === 'The url does not have an xml extension'
        ) {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Não conseguimos ler o seu XML. Cerifique-se de que o link possui a extensão .xml e tente novamente.',
            'error',
          );
        } else if (status === 400 && message === 'Invalid XML URL') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'O seu link de XML é inválido. Verifique e tente novamente.',
            'error',
          );
        } else if (status === 404 && message === 'XML URL not found') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'O link de XML inserido não pôde ser acessado ou não foi encontrado.',
            'error',
          );
        } else {
          Sentry.captureException(err);
          setOpenLogs(true);
          setLog(err?.response?.data?.isValid?.err);
        }

        /* ----------------- */
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const {data} = await apiBackend.get(
          `v1/xml/validate/sintaxe?url=${linkXml}`,
          {
            headers: {Authorization: `Bearer ${token}`},
          },
        );

        sendLinkXMl();
      } catch (err) {
        console.log(err?.response?.data?.isValid?.err);
        let status = err?.response?.status;
        let message = err?.response?.data?.data?.message;

        if (
          status === 400 &&
          message === 'The url does not have an xml extension'
        ) {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Não conseguimos ler o seu XML. Cerifique-se de que o link possui a extensão .xml e tente novamente.',
            'error',
          );
        } else if (status === 400 && message === 'Invalid XML URL') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'O seu link de XML é inválido. Verifique e tente novamente.',
            'error',
          );
        } else if (status === 404 && message === 'XML URL not found') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'O link de XML inserido não pôde ser acessado ou não foi encontrado.',
            'error',
          );
        } else {
          Sentry.captureException(err);
          setOpenLogs(true);
          setLog(err?.response?.data?.isValid?.err);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  async function sendLinkXMl() {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    setLoading(true);

    if (info?.xml && info.xml.url) {
      try {
        const {data} = await apiBackend.put(
          `v1/xml/${info.xml.id}`,
          {
            xml: linkXml,
            user_id: info.id,
          },
          {
            headers: {Authorization: `Bearer ${token}`},
          },
        );
        console.log(data);
        setOpen(false);

        fireActionMethods(
          'Link inserido',
          `Link de XML em ${info.profile.enterprise} inserido com sucesso`,
          'success',
          getAllLeads,
        );
      } catch (err) {
        Sentry.captureException(err);
        console.log(err?.response?.data?.errors);
        setOpenLogs(true);
        setLog(err?.response?.data);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const {data} = await apiBackend.post(
          'v1/xml',
          {
            user_id: info.id,
            xml: linkXml,
          },
          {
            headers: {Authorization: `Bearer ${token}`},
          },
        );

        console.log(data);
        setOpen(false);

        fireActionMethods(
          'Link inserido',
          `Link de XML em ${info.profile.enterprise} inserido com sucesso`,
          'success',
          getAllLeads,
        );
      } catch (err) {
        Sentry.captureException(err);
        console.log(err?.response?.data?.errors);
        setOpenLogs(true);
        setLog(err?.response?.data);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <ModalContainer>
            <TitleModal>
              <span>Link do XML</span>
              <CloseIcon
                onClick={() => handleClose()}
                className={'iconClose'}
              />
            </TitleModal>
            <form style={{padding: '20px', maxWidth: 800, width: '100%'}}>
              <InternDiv>
                <div>
                  <DivInputText>
                    <InputTextArea
                      placeHolder={'Cole aqui o link do XML'}
                      label="Link"
                      value={linkXml}
                      setValue={setLinkXml}
                    />
                  </DivInputText>
                </div>
                <ButtonSave
                  onClick={() => {
                    verifySintaxeXml();
                  }}
                  type="submit"
                  disabled={loading}
                  className={'btnDataPersonal btnload'}>
                  {loading ? (
                    <ReactLoading
                      type={'spin'}
                      className="load"
                      color={colors.green_secondary}
                      width={'30px'}
                    />
                  ) : info?.xml && info.xml.url ? (
                    'Atualizar'
                  ) : (
                    'Adicionar'
                  )}
                </ButtonSave>
              </InternDiv>
            </form>
          </ModalContainer>
        </Dialog>
      </div>
      <ModalLogs
        open={openLogs}
        flag={true}
        setOpen={setOpenLogs}
        log={log}
        info={info}
      />
    </>
  );
}
