import React from 'react';
import Ilustracao404 from '../../../assets/Ilustracao404myads.svg';
import I18n from '../../../i18n/i18';
import {Container} from './styles';
// import { Container } from './styles';

function MyAds404() {
  return (
    <Container>
      <div className="divintern">
        <img src={Ilustracao404} alt="image_404" width="100%" />
        <div className="text">Não existe imobiliárias nessa categoria</div>
      </div>
    </Container>
  );
}

export default MyAds404;
