export const translateProfileType = (value) => {
  let string = value?.toLowerCase();

  switch (string) {
    case 'owner':
      return 'Proprietário';
    case 'agent':
      return 'Corretor';
    case 'broker':
      return 'Imobiliária';
    default:
      return 'Não classificado';
  }
};

/* 1 = common;
  2 = seller;
  3 = approved;
  4 = manager;
  5 = admin;
 */

export const translateAccountRoleId = (value) => {
  switch (value) {
    case 1:
      return 'Comum';
    case 2:
      return 'Vendedor';
    case 3:
      return 'Aprovador';
    case 4:
      return 'Gestor';
    case 5:
      return 'Administrador';
    default:
      return 'Não especificado';
  }
};
