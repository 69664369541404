/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {fireAction} from '~/utils/swal';
import MyCSVDownload from '~/components/MyCSVDownload';
import {
  ButtonTransparent,
  ButtonMedium,
  ButtonSmall,
  InputSearch,
} from '~/UI/styles';
import Grid from '@material-ui/core/Grid';
import SimpleModal from '~/components/Modal';
import RegisterUser from './Register';
import Loader from '../loader';
import MenuFilter from '~/components/Menu/MenuFilter';
import {convertDate, desencrypta} from '~/utils';

import TableClients from './Table';
import Dash from '~/components/Dashboard';
import apiBackend from '~/services/apiaxios';
import {ClearFiltersButton, Results} from '../styles';
import Pagination from '~/components/_PaginationTab';
import DatePickerCustom from '~/components/DatePickerCustom';
import {ReportContainer, ActionsContainer, ContainerPagination} from './styles';
import currencyURL from '~/services/currency';

import ModalUserFilters from '~/components/ModalUserFilters';

import * as Sentry from '@sentry/react';

/* ----------------- STYLES ------------------- */
const buttonSmallOverride = {
  letterSpacing: '1.8px',
};
const typeUsersSelect = ['All', 'Owner', 'Agent', 'Broker'];

const permitted_roles = ['5'];

export default function Clients({display, infoUser}) {
  const userStore = useSelector((state) => state.user);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openRegister, setOpenRegister] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [modalRegister, setModalRegister] = useState(false);
  const [loader, setLoader] = useState(false);

  /* ------------------------------------------ */
  const [hasModalFilterVisible, setHasModalFilterVisible] = useState(false);
  const handleToggleModalFilterVisibility = React.useCallback(
    () => setHasModalFilterVisible(!hasModalFilterVisible),
    [hasModalFilterVisible],
  );
  const [filterName, setFilterName] = useState('');
  const [filterByType, setFilterByType] = useState('');
  const [status, setStatus] = useState({
    id: 1,
    name: 'Todos',
    query: 0,
  });
  const [adsQtd, setAdsQtd] = useState('');
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [viewLat, setViewLat] = useState('');
  const [viewLong, setViewLong] = useState('');

  const [viewLat2, setViewLat2] = useState('');
  /* TODO novo  */
  const [viewLong2, setViewLong2] = useState('');

  const [addressChoose, setAddressChoose] = useState({});
  const [query, setQuery] = useState(new URLSearchParams());
  /*  const [csvData, setCsvData] = useState([]); */

  /* --- PAGINATION TABLE --- */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const [currency, setCurrency] = useState({});

  const handleChangePage = (value) => {
    console.log(value);
    setPage(value);
  };
  /* --------------------------------------------------- */

  useEffect(() => {
    async function getCurrency() {
      /* TODO coin */
      fetch(`${currencyURL}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',

        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setCurrency(data);
        })
        .catch((e) => {
          if (e.status === 401) {
            console.log(e);
            fireAction(
              'Erro interno no servidor',
              'Um erro ocorreu durante sua solicitação. Não conseguimos converter as moedas para visualização. Por favor, tente novamente mais tarde.',
              'error',
            );
          } else {
            console.log(e);
            fireAction(
              'Falha na comunicação',
              'Um erro de comunicação com o servidor de conversão de moedas ocorreu. Tente novamente mais tarde.',
              'error',
            );
          }
          Sentry.captureException(e);
        });
    }

    getCurrency();
  }, []);

  async function listUsers() {
    try {
      setLoader(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/users?paginate=true&page=${page}&limit=10&role_id=1${
          query.toString() !== '' ? `&${query.toString()}` : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );
      /*   console.log(data); */
      setTotalResults(data?.users?.meta?.total);
      setLastPage(data?.users?.meta?.last_page);
      setInfoData(data?.users?.data);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    listUsers();
  }, [page, query]);

  function handleFilter() {
    var query = new URLSearchParams();
    if (filterName?.trim()) {
      query.append('term', filterName?.trim());
    }
    if (filterByType && filterByType !== 'All') {
      query.append('type_advertise', filterByType);
    }
    if (dateStart?._isValid && dateStart?._d > new Date('1900-01-01')) {
      let initial =
        dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
      query.append('initial', initial);
    }

    if (dateEnd?._isValid && dateEnd?._d > new Date('1900-01-01')) {
      let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';
      query.append('end', end);
    }

    if (dateStart?._isValid && dateEnd?._isValid) {
      if (dateStart?._d > dateEnd?._d) {
        let initial =
          dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
        let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';

        query.set('initial', end);
        query.set('end', initial);
      }
    }

    if (adsQtd && Number(adsQtd) > 0) {
      query.set('announcement', adsQtd);
    }

    if (status?.query > 0) {
      query.set('common_user_status', status.query);
    }

    if (Object.keys(addressChoose).length > 0) {
      if (latitude) {
        query.set('latitude', latitude);
      }
      if (longitude) {
        query.set('longitude', longitude);
      }
      if (viewLat) {
        query.set('view_lat', viewLat);
      }
      if (viewLong) {
        query.set('view_lon', viewLong);
      }

      /* TODO novo */
      if (viewLat2) {
        query.set('view_lat2', viewLat2);
      }
      if (viewLong2) {
        query.set('view_lon2', viewLong2);
      }
    }

    setQuery(() => {
      setPage(1);

      return query;
    });
  }

  function handleClearFilter() {
    setFilterName('');
    setFilterByType('');
    setStatus({
      id: 1,
      name: 'Todos',
      query: 0,
    });
    setDateStart(null);
    setDateEnd(null);
    setAddressChoose({});
    setAddress('');
    setLatitude('');
    setLongitude('');
    setViewLat('');
    setViewLong('');

    /* TODO novo */
    setViewLat2('');
    setViewLong2('');
    setAdsQtd('');

    setQuery(() => {
      setPage(1);
      return new URLSearchParams();
    });
  }

  /* ----------- REGISTER ----------- */
  const handleRegister = React.useCallback(
    async (values) => {
      /* console.log(JSON.stringify(values, null, 2)); */
      try {
        setIsSubmitting(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.post(`/v1/users`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(data);
        setOpenRegister(false);
        fireAction(
          'Tudo certo',
          'O cadastro do novo usuário foi realizado com sucesso.',
          'success',
        );
        /* Return to page 1 with new list */
        if (page === 1) {
          listUsers();
        } else {
          setPage(1);
        }
      } catch (err) {
        /* Email has exists */
        if (err.response.status === 422) {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Já existe um usuário cadastrado com o e-mail informado. Por favor, tente outro e-mail.',
            'error',
          );
        } else {
          /* error not especifc */
          Sentry.captureException(err);
          fireAction(
            'Não foi possível completar sua solicitação',
            'Um erro interno ocorreu durante o cadastro do novo usuário. Por favor, tente novamente mais tarde.',
            'error',
          );
        }

        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [page],
  );

  /* ------------------------ */
  /* -------------------------------- */
  /* --------- Reports -------------- */
  const [hasSubmitting, setHasSubmitting] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const generateReports = React.useCallback(async () => {
    try {
      setHasSubmitting(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/users?role_id=1${
          query.toString() !== '' ? `&${query.toString()}` : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );

      const userDataInCsv = data?.users?.map((item) => {
        return {
          id: item.id,
          name: item.name ?? '',
          surname: item.surname ?? '',
          email: item.email ?? '',
          cellphone: item.cellphone ?? '',
          cargo: item.cargo ?? '',
          created_at: item.created_at,
          role_id: item.role_id,
          avatar: item?.avatar?.avatar_url ?? '',
          total_properties: item.totalProperties,
          properties_ids: item?.properties?.join(','),
          last_updated: item.updated_at,
          uf: item?.profile?.state ?? '',
          country: item?.profile?.country ?? '',
          city: item?.profile?.address?.city ?? '',
          neighborhood: item?.profile?.neighborhood ?? '',
          address: item?.profile?.address ?? '',
          address_complement: item?.profile?.address_complement ?? '',
          address_number: item?.profile?.address_number ?? '',
          latitude: item?.profile?.latitude ?? '',
          longitude: item?.profile?.longitude ?? '',
          zip_code: item?.profile?.zip_code ?? '',
          commercial_phone: item?.profile?.commercial_phone ?? '',
          home_phone: item?.profile?.home_phone ?? '',
          company_name: item?.profile?.company_name ?? '',
          enterprise: item?.profile?.enterprise ?? '',
          cnpj: item?.profile?.cnpj ?? '',
          cpf: item?.profile?.cpf ?? '',
          number_of_employees: item?.profile?.number_of_employees ?? '',
          license: item?.profile?.license ?? '',
          facebook: item?.profile?.facebook ?? '',
          instagram: item?.profile?.instagram ?? '',
          job: item?.profile?.job ?? '',
          website: item?.profile?.website ?? '',
          whatsapp: item?.profile?.whatsapp ?? '',
          linkedin: item?.profile?.linkedin ?? '',
          twitter: item?.profile?.twitter ?? '',
          status: item?.profile?.status ?? '',
          status_broker: item?.profile?.status_broker ?? '',
          type_advertiser: item?.profile?.type_advertiser ?? '',
        };
      });
      setCsvData(userDataInCsv);
      setHasSubmitting(false);
    } catch (err) {
      setHasSubmitting(false);
      Sentry.captureException(err);
    }
  }, [query]);

  useEffect(() => {
    if (csvData.length > 0) {
      setCsvData([]);
    }
  }, [query]);

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*   useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  return (
    <Dash button={'user'} title={'Usuários'}>
      <div style={{display: display}}>
        <ActionsContainer>
          {isPermitted() && (
            <ButtonMedium
              onClick={() => {
                setOpenRegister(true);
              }}>
              Cadastrar Usuário
            </ButtonMedium>
          )}

          <Grid
            container
            spacing={4}
            style={{marginBottom: '30px', marginTop: '30px'}}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <InputSearch
                value={filterName}
                onChange={(event) => setFilterName(event.target.value)}
                placeholder="Pesquisar por nome ou email..."
                style={{marginRight: '30px'}}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <MenuFilter
                state={filterByType}
                setState={setFilterByType}
                itens={typeUsersSelect}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <ButtonSmall style={buttonSmallOverride} onClick={handleFilter}>
                PESQUISAR
              </ButtonSmall>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <ButtonTransparent
                style={buttonSmallOverride}
                onClick={handleToggleModalFilterVisibility}>
                Mais filtros
              </ButtonTransparent>
            </Grid>
          </Grid>
        </ActionsContainer>

        {infoData?.length > 0 && (
          <ReportContainer>
            <MyCSVDownload
              data={csvData}
              handleGenerate={generateReports}
              loading={hasSubmitting}
              filename="PERMUTI_users_reports.csv"
            />
          </ReportContainer>
        )}

        {query.toString() && (
          <>
            <ReportContainer>
              <ClearFiltersButton onClick={handleClearFilter}>
                Limpar filtros
              </ClearFiltersButton>
            </ReportContainer>
          </>
        )}

        {loader ? (
          <Loader />
        ) : infoData?.length > 0 ? (
          <>
            <TableClients
              infoUser={infoUser}
              information={infoData}
              setInfo={setInfoData}
              setModalRegister={setModalRegister}
              /* new */
              total={totalResults}
              /* rerender controls */
              listUser={listUsers}
              page={page}
              setPage={setPage}
              currency={currency}
            />

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ContainerPagination>
                {lastPage > 1 && (
                  <Pagination
                    count={lastPage}
                    page={page}
                    handleChange={(event, value) => handleChangePage(value)}
                  />
                )}
              </ContainerPagination>
            </Grid>
          </>
        ) : (
          <Results>Nenhum resultado encontrado...</Results>
        )}
        {/* Modals */}
        {/*     <SimpleModal modalInfo={modalInfo} modal={modal} setModal={setModal} /> */}
        {isPermitted() && (
          <RegisterUser
            loading={isSubmitting}
            open={openRegister}
            setOpen={setOpenRegister}
            handleSubmit={handleRegister}
          />
        )}

        <ModalUserFilters
          open={hasModalFilterVisible}
          toggleVisibility={handleToggleModalFilterVisibility}
          handleFilter={handleFilter}
          clearFilters={handleClearFilter}
          /* ------------ */
          status={status}
          setStatus={setStatus}
          adsQtd={adsQtd}
          setAdsQtd={setAdsQtd}
          address={address}
          setAddress={setAddress}
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          /*  */
          viewLat={viewLat}
          setViewLat={setViewLat}
          /*  */
          viewLong={viewLong}
          setViewLong={setViewLong}
          /* TODO novo  */
          viewLat2={viewLat2}
          setViewLat2={setViewLat2}
          viewLong2={viewLong2}
          setViewLong2={setViewLong2}
          /*  */
          addressChoose={addressChoose}
          setAddressChoose={setAddressChoose}
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
        />
      </div>
    </Dash>
  );
}
