import styled, {keyframes} from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

const letDown = keyframes`
  0%, 40%{
    transform: translateY(0px);
  }
  20%{
    transform: translateY(3px);
  }
  30%{
    transform: translateY(-3px);
  }
 
`;

export const ReportAction = styled.div`
  flex: none;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.green_secondary};
  background-color: ${colors.white};
  border: 1px solid ${colors.green_secondary}!important;
  border-radius: 50%;
  box-shadow: 0px 1px 3px #00000033;
  font-size: 14px;
  outline: 0;
  transition: all 0.2s ease;
  overflow: hidden;
`;

export const Box = styled.button`
  background: none;

  &.generate {
    flex: none;
    display: flex;
    align-items: center;
    font-family: ${fonts.latoBlack};
    background: none;
    transition: all 0.2s ease;
    color: ${colors.green_secondary};

    &:disabled {
      > div {
        border: 1px solid ${colors.gray_secondary}!important;
        background: ${colors.white};
        box-shadow: none;
        color: ${colors.gray_secondary};
      }
      color: ${colors.gray_secondary};
      cursor: not-allowed;
    }

    &:hover:not(:disabled),
    &:hover:not(:active) {
      > div {
        > svg {
          animation: ${letDown} 1.4s infinite linear;
        }
      }
    }
  }
  &.download {
    > a {
      text-decoration: none;
      flex: none;
      display: flex;
      align-items: center;
      font-family: ${fonts.latoBlack};
      background: none;
      transition: all 0.2s ease;
      color: ${colors.green_secondary};
    }

    &:disabled {
      > a > div {
        border: 1px solid ${colors.gray_secondary}!important;
        background: ${colors.white};
        box-shadow: none;
        color: ${colors.gray_secondary};
      }
      color: ${colors.gray_secondary};
      cursor: not-allowed;
    }

    &:hover:not(:disabled),
    &:hover:not(:active) {
      > a > div {
        > svg {
          animation: ${letDown} 1.4s infinite linear;
        }
      }
    }
  }
`;
