import React, {useEffect, useState} from 'react';
import ReactLoading from 'react-loading';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import {desencrypta, getRoute} from '~/utils';
import ModalEditSucess from '../../../../../../components/ModalEditSucess';
import InputText from '../../Components/inputText';
import InputTextRequire from '../../Components/inputTextRequire';
import MandatoryField from '../../Components/mandatoryField';
import NextPreview from '../../Components/nextPreview';
import SelectMenuRequiredI18n from '../../Components/selectMenuRequiredI18n';
import {Star} from '../../Components/styles';
import {TitlePage} from '../../Photo/styles';
import {SaveAnnoucement} from '../../Step/styles';
import {ButtonLocalize, CRDiv, DivNext, Label, TitleOptional} from './styles';
import './styles.css';
import * as Sentry from '@sentry/react';

const itensCountry = ['Brazil', 'United States', 'Portugal'];

function LocalizeOne({
  step,
  setStep,
  selectedValueCountry,
  type,
  typeEn,
  id,
  itensBackend,
  ...rest
}) {
  Geocode.setApiKey(process.env.REACT_APP_PLACES);

  const [active, setActive] = useState(false);
  const [utf, setUtf] = useState('Br');
  const [loadGoogle, setLoadGoogle] = useState(false);

  const [classGoogle, setClassGoogle] = useState('');
  const [classGoogleC, setClassGoogleC] = useState('');
  const [disable, setDisable] = useState(true);
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [label, setLabel] = useState('Cep');
  const [activeCep, setActiveCep] = useState(false);
  const viewNumber = verifyTypeNumberOffice(type['name_pt']);

  const viewNumberFloor = verifyTypeStore(type['name_pt']);
  const view = useSelector((state) => state.step.data);
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/advertise`,
    state: {fromDashboard: true},
  });

  const history = useHistory();
  const [openEditSucess, setOpenEditSucess] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState({
    address: rest.digit,
    latitude: rest.latitude,
    longitude: rest.longitude,
  });

  useEffect(() => {
    setSelectedAddress({
      address: rest.digit,
      latitude: rest.latitude,
      longitude: rest.longitude,
    });
  }, [rest.digit, rest.latitude, rest.longitude]);

  useEffect(() => {
    console.log(selectedAddress);
  }, [selectedAddress]);

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  function handleProx(event) {
    saveItens();
    event.preventDefault();
    setActive(false);
  }

  const [loadingLocation, setLoadingLocation] = useState(false);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
    }
  }

  function showPosition(position) {
    geocode(position.coords.latitude, position.coords.longitude);
    rest.setLatitude(position.coords.latitude);
    rest.setLongitude(position.coords.longitude);
  }

  async function geocode(lat, lon) {
    try {
      setLoadingLocation(true);
      const response = await Geocode.fromLatLng(lat, lon);
      const address = response.results[0];
      insertButton(address, 1);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoadingLocation(false);
    }
  }

  function insertButton(place, number) {
    if (number === 1) {
      let final_address = [];

      place.address_components.forEach((item) => {
        if (item.types[0] === 'route') {
          rest.setAdress(item.long_name);
          final_address.push(item.long_name);
        } else if (item.types[0] === 'sublocality_level_1') {
          rest.setNeighborhood(item.long_name);
        } else if (
          item.types[0] === 'locality' ||
          (item.types[0] === 'administrative_area_level_2' && utf !== 'Us')
        ) {
          rest.setCity(item.long_name);
          final_address.push(item.long_name);
        } else if (
          item.types[0] === 'administrative_area_level_1' &&
          utf !== 'Pt'
        ) {
          rest.setState(item.short_name);
          final_address.push(item.short_name);
        } else if (item.types[0] === 'country') {
          rest.setSelectValueCountry(item.long_name);
        } else if (item.types[0] === 'street_number') {
          rest.setNumb(item.long_name);
        } else if (
          item.types[0] === 'postal_code' ||
          item.types[0] === 'postal_code_prefix'
        ) {
          rest.setCep(item.long_name);
          if (item.long_name) {
            setActiveCep(true);
          } else {
            setActiveCep(false);
          }
        } else {
        }
      });

      setSelectedAddress(() => {
        const myAddress = final_address?.join(', ');
        rest.setDigit(myAddress);

        return {
          address: myAddress,
          latitude: place?.geometry?.location?.lat,
          longitude: place?.geometry?.location?.lng,
        };
      });
    }
  }

  async function saveItens() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    console.log(token);
    apiBackend
      .put(
        `/v1/property/${id}/edit`,
        {
          country: selectedValueCountry,
          state: rest.state,
          city: rest.city,
          neighborhood: rest.neighborhood,
          street_name: rest.adreess,
          street_number: rest.numb ? parseInt(rest.numb) : null,
          street_suffix: rest.streetSuffix,
          unit_number: rest.nApartment ? rest.nApartment : null,
          zip_code: rest.cep,
          floor: rest.floor ? parseInt(rest.floor) : null,
          latitude: rest.latitude,
          longitude: rest.longitude,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        setActive(true);
        setOpenEditSucess(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  useEffect(() => {
    if (selectedValueCountry === 'Brazil') {
      setUtf('Br');
    } else if (selectedValueCountry === 'United States') {
      setUtf('Us');
    } else if (selectedValueCountry === 'Portugal') {
      setUtf('Pt');
    } else {
      setUtf('Us');
    }

    setLoadGoogle(true);
  }, [selectedValueCountry]);

  useEffect(() => {
    if (rest.digit.length > 0) {
      setClassGoogle('value');
    } else {
      setClassGoogle('normal');
    }
  }, [rest.digit, selectedValueCountry]);

  useEffect(() => {
    if (rest.city && rest.city.length > 0) {
      setClassGoogleC('value');
    } else {
      setClassGoogleC('normal');
    }
  }, [rest.city, selectedValueCountry]);

  useEffect(() => {
    if (type === 'Condo' || type === 'Office') {
      if (
        /* rest.city &&
        rest.state &&
        rest.neighborhood && */
        rest.adreess &&
        rest.cep.length >= min - 1 &&
        rest.numb &&
        rest.floor &&
        rest.nApartment
      ) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else if (
      /*  rest.city &&
      rest.state &&
      rest.neighborhood && */
      rest.adreess &&
      rest.cep.length >= min - 1 &&
      rest.numb
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [
    /* rest.city,
    rest.state,
    rest.neighborhood, */
    rest.adreess,
    rest.cep,
    rest.numb,
    rest.nApartment,
    rest.floor,
  ]);

  function insert(place, number) {
    if (place?.geometry?.location?.lat && place?.geometry?.location?.lng) {
      rest.setLatitude(place?.geometry?.location?.lat);
      rest.setLongitude(place?.geometry?.location?.lng);
    }

    if (number === 1) {
      let final_address = [];
      if (place.address_components) {
        place.address_components.forEach((item) => {
          if (item.types[0] === 'route') {
            rest.setAdress(item.long_name);
            final_address.push(item.long_name);
          } else if (item.types[0] === 'sublocality_level_1') {
            rest.setNeighborhood(item.long_name);
          } else if (
            item.types[0] === 'locality' ||
            (item.types[0] === 'administrative_area_level_2' && utf !== 'Us')
          ) {
            rest.setCity(item.long_name);
            final_address.push(item.long_name);
          } else if (
            item.types[0] === 'administrative_area_level_1' &&
            utf !== 'Pt'
          ) {
            rest.setState(item.short_name);
            final_address.push(item.short_name);
          } else if (item.types[0] === 'country') {
          } else if (item.types[0] === 'street_number') {
            rest.setNumb(item.long_name);
          } else if (
            item.types[0] === 'postal_code' ||
            item.types[0] === 'postal_code_prefix'
          ) {
            rest.setCep(item.long_name);

            if (item.long_name) {
              setActiveCep(true);
            } else {
              setActiveCep(false);
            }
          } else {
          }
        });

        setSelectedAddress(() => {
          const myAddress = final_address?.join(', ');
          rest.setDigit(myAddress);

          return {
            address: myAddress,
            latitude: place?.geometry?.location?.lat,
            longitude: place?.geometry?.location?.lng,
          };
        });
      }
    }
  }

  useEffect(() => {
    if (selectedValueCountry === 'United States') {
      setLabel('ZIP CODE');
      setMin(5);
      setMax(5);
    } else if (selectedValueCountry === 'Brazil') {
      setLabel('CEP');
      setMin(9);
      setMax(9);
    } else if (selectedValueCountry === 'Portugal') {
      setLabel('Código Postal');
      setMin(4);
      setMax(8);
    } else {
      setLabel('ZIP CODE');
      setMin(5);
      setMax(5);
    }
  }, [selectedValueCountry]);

  function cepNumber(event) {
    if (selectedValueCountry === 'United States') {
      rest.setCep(
        event.target.value.replace(/[^0-9]/g, '').replace(/(\d{5})/, '$1'),
      );
    } else if (selectedValueCountry === 'Brazil') {
      rest.setCep(
        event.target.value
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{5})(\d{3}).*/, '$1-$2'),
      );
    } else if (selectedValueCountry === 'Portugal') {
      rest.setCep(event.target.value.replace(/^(\d{4})(\d{3}).*/, '$1-$2'));
    } else {
      rest.setCep(event.target.value.replace(/[^0-9]/g, ''));
    }
  }

  function renderGoogle() {
    return (
      <Autocomplete
        value={rest.digit}
        id="input-auto"
        style={{marginBottom: '20px'}}
        onChange={(event) => {
          rest.setAdress(event.target.value);
          rest.setDigit(event.target.value);
        }}
        onBlur={(event) => {
          const {value} = event.target;

          if (value !== selectedAddress?.address) {
            rest.setCep('');
            rest.setState('');
            rest.setCity('');
            rest.setAdress('');
            rest.setDigit('');
            rest.setLatitude('');
            rest.setLongitude('');
            rest.setNeighborhood('');

            setSelectedAddress({
              address: '',
              latitude: '',
              longitude: '',
            });
          }
        }}
        onPlaceSelected={(place) => {
          rest.setCep('');
          rest.setState('');
          rest.setCity('');
          rest.setAdress('');
          rest.setNeighborhood('');
          setDisable(false);
          insert(place, 1);
        }}
        placeholder={I18n.getTranslation(
          location,
          'Localization.placeHolderAd',
        )}
        types={['address']}
        className={classGoogle}
        componentRestrictions={{country: utf}}
      />
    );
  }

  function verifyTypeNumberOffice() {
    if (type['name_en'] === 'Condo' || type['name_en'] === 'Office') {
      return true;
    }
    return false;
  }

  function verifyTypeStore() {
    if (type === 'Store') {
      return true;
    }
    return false;
  }

  return (
    <div>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <TitlePage>
          <I18n t="Localization.title" />
        </TitlePage>
        <ButtonLocalize
          type="button"
          disabled={loadingLocation}
          onClick={getLocation}>
          {loadingLocation ? (
            <ReactLoading
              type="spin"
              color={colors.green_secondary}
              width="30px"
              height="30px"
            />
          ) : (
            <I18n t="Localization.btnGreen" />
          )}
        </ButtonLocalize>
        <TitleOptional>
          <I18n t="Localization.other" />
        </TitleOptional>
        <MandatoryField />
        <SelectMenuRequiredI18n
          i18n="Country"
          placeHolder={I18n.getTranslation(
            location,
            'Localization.placeHolderCountry',
          )}
          label={I18n.getTranslation(location, 'Localization.labelCountry')}
          itens={itensCountry}
          value={selectedValueCountry}
          setValue={rest.setSelectValueCountry}
        />
        <Label classes={classGoogle}>
          <I18n t="Localization.labelAd" /> <Star>*</Star>
        </Label>
        {utf === 'Br' && renderGoogle()}
        {utf === 'Us' && renderGoogle()}
        {utf === 'Pt' && renderGoogle()}
        <InputTextRequire
          placeHolder={I18n.getTranslation(
            location,
            'Localization.placeHolderNeig',
          )}
          label={I18n.getTranslation(location, 'Localization.labelNeig')}
          value={rest.neighborhood}
          setValue={rest.setNeighborhood}
          isOptional
        />

        {/*   <div className="mg-top-20">
          <InputTextRequire
            placeHolder={I18n.getTranslation(
              location,
              'Localization.placeHolderCity',
            )}
            label={I18n.getTranslation(location, 'Localization.labelCity')}
            value={rest.city}
            setValue={rest.setcity}
            isOptional
            readOnly
          />
        </div>

        <div className="mg-top-20">
          <InputTextRequire
            placeHolder={I18n.getTranslation(
              location,
              'Localization.placeHolderState',
            )}
            label={I18n.getTranslation(location, 'Localization.labelState')}
            value={rest.state}
            setValue={rest.setState}
            isOptional
            readOnly
          />
        </div> */}
        <CRDiv className="mg-top-20">
          <InputTextRequire
            max={6}
            placeHolder={I18n.getTranslation(
              location,
              'Localization.placeHolderNumber',
            )}
            label={I18n.getTranslation(location, 'Localization.labelNumber')}
            value={rest.numb}
            setValue={rest.setNumb}
          />
          <span className="zipp">
            <InputTextRequire
              min={min}
              max={max}
              mask={cepNumber}
              className="zipp"
              placeHolder={I18n.getTranslation(
                location,
                'Localization.placeHolderZip',
              )}
              label={label}
              value={rest.cep}
              setValue={rest.setCep}
            />
          </span>
        </CRDiv>
        {viewNumber ? (
          <CRDiv className="mg-top-20">
            <InputTextRequire
              value={rest.nApartment}
              setValue={rest.setNApartment}
              placeHolder={I18n.getTranslation(
                location,
                'Localization.placeHolderNApartment',
              )}
              label={I18n.getTranslation(
                location,
                'Localization.labelNApartment',
              )}
            />
            <InputTextRequire
              value={rest.floor}
              setValue={rest.setFloor}
              placeHolder={I18n.getTranslation(
                location,
                'Localization.placeHolderFloor',
              )}
              label={I18n.getTranslation(location, 'Localization.labelFloor')}
            />
          </CRDiv>
        ) : null}
        {viewNumberFloor ? (
          <div className="mg-top-20">
            <InputText
              value={rest.floor}
              setValue={rest.setFloor}
              placeHolder={I18n.getTranslation(
                location,
                'Localization.placeHolderFloor',
              )}
              label={I18n.getTranslation(location, 'Localization.labelFloor')}
            />
          </div>
        ) : null}
        <div className="mg-top-20">
          <InputText
            value={rest.streetSuffix}
            setValue={rest.setStreetSufix}
            placeHolder={I18n.getTranslation(
              location,
              'Localization.placeHolderSufix',
            )}
            label={I18n.getTranslation(location, 'Localization.labelSufix')}
          />
        </div>
        <DivNext>
          <NextPreview
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
        <ModalEditSucess
          func={() => {
            history.goBack();
          }}
          open={openEditSucess}
          setOpen={setOpenEditSucess}
        />
      </div>
    </div>
  );
}

export default LocalizeOne;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.value ? `2px solid ${colors.green_secondary}` : '#808076 1px solid'};
  color: ${(props) => (props.value ? '#1D1D1B' : '#808076')};
  width: 100%;
  font-size: 18px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus {
    outline: 0;
  }
  margin-bottom: 20px;
`;
