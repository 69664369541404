export default {
  green_primary: '#E6FFEF',
  green_secondary: '#50B275',
  green_third: '#2E6643',
  green_limon: '#8ECC51',
  white: '#FFFFFF',
  gray_primary: '#F2F2ED',
  gray_secondary: '#BFBFB2',
  gray_third: '#808076',
  dark: '#1D1D1B',
  /* Está duplicado cp, gray_third, não irei remover agora mas passarei a não usar essa cor */
  dark_light: '#808076',
  alert_ligth: '#FFF2CC',
  alert_default: '#FFD34D',
  alert_dark: '#A88721',
  danger_light: '#FFC3C2',
  danger: '#F56662',
  danger_dark: '#CC2C29',
  sucess_light: '#CCEDAB',
  sucess: '#8ECC51',
  sucess_dark: '#6B915C',
  blue_button: '#005DFF',
  text_dark_popup: '#5B6372',

  /* novas cores que percebi que faltavam */
  text_dark_modal: '#484848',
};
