import styled from 'styled-components';

import colors from '~/UI/colors';
import metrics from '~/UI/metrics';
import fonts from '~/UI/fonts';

export const ContainerBoxProposal = styled.div`
  min-height: 180px;
  box-shadow: 0px 4px 8px #00000033;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;

  @media only screen and (max-width: 991px) {
    display: none;
  }
  z-index: 2;
`;

export const ContainerBoxMobile = styled.div`
  min-height: 90px;
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  @media only screen and (max-width: 991px) {
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export const ButtonGreenGo = styled.button`
  &:focus {
    outline: 0;
  }
  z-index: 1px;
  @media only screen and (max-width: 991px) {
    margin-left: 30px;
    margin-right: 30px;
  }
  background-color: ${colors.green_secondary};
  box-shadow: 0px 1px 3px #00000033;
  border: 2px solid #50b275;
  height: 50px;
  width: 100%;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  font-size: 18px;
  font-family: Lato;
  &:hover {
    background-color: ${colors.green_third};
    transition: 0.1s;
  }

  &:active {
    transition: 0.1s;
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: 2px solid #e6ffef;
  }
`;

export const Price = styled.div`
  font-size: 30px;
  color: ${colors.green_secondary};
  font-family: ${fonts.robotoRegularBold};
`;

export const PriceAlternative = styled.div`
  font-size: 18px;
  font-family: ${fonts.latoRegular};
  color: #1d1d1b;
`;

export const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 20px;
  place-items: center;
  width: 100%;

  margin: 25px 0;
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fonts.latoBlack};
  color: ${colors.green_secondary};
  font-size: 16px;
`;

export const ActionsContainerMobile = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 280px));
  grid-gap: 20px;
  place-items: center;
  justify-content: center;
  width: 100%;

  padding: 25px 10px;
`;

export const MenuContainerMobile = styled.div`
  max-width: 280px;
  width: 100%;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fonts.latoBlack};
  color: ${colors.green_secondary};
  font-size: 16px;
`;

export const ViewJustify = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 6px;

  svg {
    width: 24px;
    height: 24px;
    transition: all 0.2s ease;
  }

  &:hover {
    background: ${colors.green_secondary};
    color: ${colors.white};
  }

  &:active {
    background: ${colors.green_third};
    color: ${colors.white};

    &:hover {
      background: ${colors.green_third};
      color: ${colors.white};
    }
  }
`;

export const Liked = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  width: 100%;
  min-height: 40px;
  background: ${colors.white};
  box-shadow: 0px 3px 6px #00000040;
  border-radius: 5px;

  font-family: ${fonts.latoRegularBold};
  font-size: 14px;
  color: ${colors.green_secondary};

  transition: all 0.2s ease;

  img {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    transition: all 0.2s ease;
  }

  &:hover {
    background: ${colors.gray_secondary};
  }

  &:active {
    background: ${colors.green_third};
    color: ${colors.white};
    img {
      filter: brightness(100);
    }

    &:hover {
      background: ${colors.green_third};
      color: ${colors.white};

      img {
        filter: brightness(100);
      }
    }
  }
`;

export const Disliked = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 140px;
  width: 100%;
  min-height: 40px;
  background: ${colors.white};
  box-shadow: 0px 3px 6px #00000040;
  border-radius: 5px;

  font-family: ${fonts.latoRegularBold};
  font-size: 14px;
  color: ${colors.green_secondary};
  transition: all 0.2s ease;

  img {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    transition: all 0.2s ease;
  }

  &:hover {
    background: ${colors.gray_secondary};
  }

  &:active {
    background: ${colors.green_third};
    color: ${colors.white};
    img {
      filter: brightness(100);
    }

    &:hover {
      background: ${colors.green_third};
      color: ${colors.white};

      img {
        filter: brightness(100);
      }
    }
  }
`;

export const TitleMinor = styled.span`
  font-size: 14px;
  display: flex;
  font-family: Lato Regular;
  color: #1d1d1b;
`;
