import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import {
  TableContainer,
  TitleBold,
  P,
  TitleBoldView,
  RowPhotoName,
  RowClick,
} from './styles';
import {getAdress, getPrice} from '~/views/Annoucement/View/utils/finance';
import ModalPlanDetail from '~/components/ModalPlanDetail';
import ModalPaymentHistory from '~/components/ModalPaymentHistory';
import ModalAdsList from '~/components/ModalAdsList';
import ManagerOptionsSubscriptions from './ManagerOptionsSubscriptions';

import {ContainerTable, ActionsContainer, PlanName} from './styles';
import {translateProfileType} from '~/utils/profileType';

import {dates} from './utils';

export default function TableSubscriptions({
  information,
  setInformation,
  reloadList,
  page,
  setPage,
  currencyValues,
}) {
  const [modalPlanVisible, setModalPlanVisible] = useState(false);
  const [modalPaymentHistory, setModalPaymentHistory] = useState(false);
  const [modalAdsList, setModalAdsList] = useState(false);
  const [subClicked, setSubClicked] = useState(null);
  const [itemHistoryPaiment, setItemHistoryPaiment] = useState(null);
  const [userHistoryPaiment, setUserHistoryPaiment] = useState(null);

  const openModalPlan = (info) => {
    console.log('info', info);
    info.subscriber = info;
    setSubClicked(info);
    setModalPlanVisible(true);
  };

  const handleToggleModalAdsList = (item) => {
    console.log('item', item);
    item.subscriber = item;

    setSubClicked(item);
    setModalAdsList(true);
  };

  return (
    <>
      <ContainerTable id="table-subscriptions">
        <TableContainer>
          <Grid
            className="tableMobile"
            container
            spacing={2}
            style={{
              marginTop: 15,
              marginBottom: 15,
              paddingBottom: 10,
            }}>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <RowClick>
                <TitleBoldView className="tableMobile">
                  Tipo de usuário
                </TitleBoldView>
              </RowClick>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <RowClick>
                <TitleBoldView className="tableMobile">Nome</TitleBoldView>
              </RowClick>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <RowClick>
                <TitleBoldView>Plano</TitleBoldView>
              </RowClick>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <RowClick>
                <TitleBoldView>Vencimento</TitleBoldView>
              </RowClick>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <RowClick>
                <TitleBoldView>Valor</TitleBoldView>
              </RowClick>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <RowClick>
                <TitleBoldView>Ações</TitleBoldView>
              </RowClick>
            </Grid>
          </Grid>
          {information?.map((info, key) => (
            <Grid key={key} container spacing={2} className="item__list">
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Tipo de usuário</TitleBold>
                <RowPhotoName>
                  {translateProfileType(info?.user?.profile?.type_advertiser)}
                </RowPhotoName>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Nome</TitleBold>
                <P>
                  {info?.user?.profile?.type_advertiser?.toLowerCase() ===
                  'broker' ? (
                    <>
                      {info?.user?.profile?.enterprise ? (
                        <>{info?.user?.profile?.enterprise}</>
                      ) : (
                        <>
                          {info?.user?.name} {info?.user?.surname ?? ''}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {info?.user?.name} {info?.user?.surname ?? ''}
                    </>
                  )}
                </P>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Plano</TitleBold>
                <P>
                  <PlanName
                    onClick={() => openModalPlan(info)}
                    freeDays={info.status === 'free'}>
                    {info.status === 'free'
                      ? 'Em período de avaliação gratuita'
                      : info.plan?.name}
                  </PlanName>
                </P>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Vencimento</TitleBold>
                <P>{info.ends ? dates(info.ends) : '-'}</P>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Valor</TitleBold>
                <P style={{textTransform: 'uppercase'}}>
                  {info?.plan?.currency} {info?.plan?.price}
                </P>
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Ações</TitleBold>
                <ManagerOptionsSubscriptions
                  getAllLeads={information}
                  info={info}
                  handleToggleModalAdsList={handleToggleModalAdsList}
                  setItemHistoryPaiment={setItemHistoryPaiment}
                  setUserHistoryPaiment={setUserHistoryPaiment}
                  setModalPaymentHistory={setModalPaymentHistory}
                />
              </Grid>
            </Grid>
          ))}
        </TableContainer>
      </ContainerTable>
      <ModalPlanDetail
        open={modalPlanVisible}
        setOpen={setModalPlanVisible}
        sub={subClicked}
        setSub={setSubClicked}
      />

      <ModalPaymentHistory
        open={modalPaymentHistory}
        setOpen={setModalPaymentHistory}
        item={itemHistoryPaiment}
        user={userHistoryPaiment}
      />
      <ModalAdsList
        open={modalAdsList}
        setOpen={setModalAdsList}
        sub={subClicked}
        setSub={setSubClicked}
        currency={currencyValues}
      />
    </>
  );
}
