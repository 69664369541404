import React, {useEffect, useState} from 'react';
import './styles.css';
import Grid from '@material-ui/core/Grid';
import {TableContainer} from './styles';

import CardPlans from '../Card';
import {useSelector} from 'react-redux';

export default function TablePlans({listPlan}) {
  const [actived, setActived] = useState(false);
  const [plans, setPlans] = useState(useSelector((state) => state.plan.data));
  const str = useSelector((state) => state.plan.data);

  useEffect(() => {
    console.log(str);
    setPlans(str);
  }, [str]);

  // function dateTransform(info) {
  //   let date = (new Date(info))
  //   return ((date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "/" + date.getFullYear());
  // }

  return (
    <>
      <TableContainer>
        <Grid
          container
          spacing={4}
          style={{
            marginBottom: 15,
          }}>
          {plans?.length > 0 &&
            plans?.map((item, key) => {
              return (
                <Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CardPlans listPlan={listPlan} item={item} />
                </Grid>
              );
            })}
        </Grid>
      </TableContainer>
    </>
  );
}
