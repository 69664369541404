import React from 'react';
import Dashboard from './Sidebar';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: '#fff',
    width: '100%',
    overflow: 'hidden',
  },
  toolbar: {
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: '#fff',
    minHeight: '100vh',
    boxSizing: 'border-box',
    width: '100%',
  },
}));

function Dash({title, button, buttonPlan, listPlan, ...rest}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dashboard
        listPlan={listPlan}
        title={title}
        buttonPlan={buttonPlan}
        button={button}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {rest.children}
      </main>
    </div>
  );
}

export default Dash;
