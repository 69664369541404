import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import {Label, Item, Container} from './styles';
import './styles.css';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButtonGroup({selectedValue, setSelectedValue}) {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Container>
      <Item>
        <GreenRadio
          checked={selectedValue === ''}
          onChange={handleChange}
          value=""
          name="recipient"
          inputProps={{'aria-label': ''}}
        />{' '}
        <Label checked={selectedValue === ''}>Todos</Label>
      </Item>
      <Item>
        <GreenRadio
          checked={selectedValue === 'owner'}
          onChange={handleChange}
          value="owner"
          name="recipient"
          inputProps={{'aria-label': 'owner'}}
        />{' '}
        <Label checked={selectedValue === 'owner'}>Comum</Label>
      </Item>
      <Item>
        <GreenRadio
          checked={selectedValue === 'broker'}
          onChange={handleChange}
          value="broker"
          name="recipient"
          inputProps={{'aria-label': 'broker'}}
        />{' '}
        <Label checked={selectedValue === 'broker'}>Imobiliária</Label>
      </Item>
    </Container>
  );
}
