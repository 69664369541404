import styled from 'styled-components';
import colors from '../../UI/colors';

export const Container = styled.div`
   width: 400px;
   height: 640px;
  
   @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: 'Lato';
`;

export const InternDiv = styled.div`
  
  padding: 0px 20px 20px 20px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  padding-top: 20px;
  padding-right: 20px;
  font-size: 24px;
  color: #1D1D1B;
  font-style: italic;
  font-weight: bold;
  font-family: 'Lato';
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`


export const DivInputText = styled.div`
 width: 100%;
 margin-bottom: 20px;
`

export const DivAlterImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonWhite = styled.button`
  background-color: #ffffff;
  border: none;
  height: 50px;
  width: 40%;
  margin-top: 45px;
  border-radius: 5px;
  color: ${colors.green_secondary};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:focus{
      outline: 0;
  }
  box-shadow: 0px 3px 6px #00000033;
  &:hover{
    background-color: #F2F2ED;
  }
   &:active{
    background-color: ${colors.green_third};
    color:#E6FFEF;
    border: #E6FFEF 2px solid;
  }
  
`

export const ButtonGreen = styled(ButtonWhite)` 
  background-color: ${colors.green_secondary} ;
  color: ${colors.white};
  &:hover{
     background-color: ${colors.green_third};
  }
`

export const TextButton = styled.span`
  margin-left: 10px;
`

export const ImagePreview = styled.img`
    max-width:100%;
    max-height:400px;
    width: auto;
    height: auto;
`

export const DivPreview = styled.div`
  display: flex;
  justify-content: center;
`

export const ModalResponsive = styled.div`
  
  @media (min-width: 481px) and (max-width: 700px) {
         width: 100%;
  }
  @media (min-width: 800px) and (max-width: 2560px) {
     width: 100%;
        
  }
  @media(max-width: 480px) {
        width: 100%;
  }
`

export const Message = styled.div`
  width: 100%;
  font-size: 16px;

`
export const DivDanger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const AdvertTitle = styled.div`
  margin-bottom: 20px;
  color: #A88721;
  font-weight: bold;
  font-size: 24px;
  font-family: 'Lato';
`

export const DivConfirm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
`

export const ButtonsWhite = styled.button`
  color: ${colors.green_secondary};
  background: ${colors.white};
  font-size: 18px;
  height: 40px;
  width: 45%;
  border: none;
  box-shadow: 0px 3px 6px #00000033;
  &:hover{
    background-color: #F2F2ED;
  }
  &:active{
    background-color: ${colors.green_third};
    color: #E6FFEF;
    border: 1px solid #E6FFEF;
  }
  &:focus{
    outline: 0;
  }
`

export const ButtonsGreenSend = styled.button`
  color: ${colors.white};
  background: ${colors.green_secondary};
  font-size: 18px;
  height: 40px;
  width: 100%;
  border: none;
  box-shadow: 0px 3px 6px #00000033;
  &:hover{
    background-color: ${colors.green_third};
  }
  &:active{
    background-color: ${colors.green_third};
    color: #E6FFEF;
    border: 1px solid #E6FFEF;
  }
  &:focus{
    outline: 0;
  }
`