/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dialog} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ReactLoading from 'react-loading';
import colors from '~/UI/colors';
import SelectCustomPlan from '~/views/RealState/Expired/SelectCustomPlan';
import {
  ButtonSave,
  DivInputText,
  InternDiv,
  ModalContainer,
  TitleModal,
} from '~/views/User/styles';
import * as S from '~/views/RealState/Register/styles';
import {DivPlan} from './styles';
import './styles.css';
import {desencrypta} from '~/utils';
import apiBackend from '~/services/apiaxios';
import {fireActionMethods} from '~/utils/swalMethods';
import * as Sentry from '@sentry/react';

export default function ModalDataPersonal({
  open,
  setOpen,
  planList,
  getProperties,
  info,
}) {
  const [plan, setPlan] = React.useState(info.plan);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      setPlan(info.plan);
    } else {
      setPlan('');
    }
  }, [open]);

  async function editPlan(event) {
    event.preventDefault();
    setLoading(true);
    const token = desencrypta(localStorage.getItem('@User_informations'));
    try {
      const {data} = await apiBackend.put(
        `v1/subscriber/${info?.subscriber?.id}`,
        {
          plan_id: plan.id,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      );
      fireActionMethods(
        'Tudo certo!',
        'Plano alterado com sucesso.',
        'success',
        getProperties,
      );
      handleClose();
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <ModalContainer>
            <TitleModal>
              <span>Alterar Plano</span>
              <CloseIcon
                onClick={() => handleClose()}
                className={'iconClose'}
              />
            </TitleModal>
            <form
              // onSubmit={onSubmit}
              style={{padding: '20px', maxWidth: 800, width: '100%'}}>
              <InternDiv>
                <div>
                  <DivInputText>
                    <SelectCustomPlan
                      label="Plano de pagamento"
                      placeholder="Selecione um plano"
                      value={plan}
                      items={planList}
                      setValue={setPlan}
                    />
                  </DivInputText>

                  {plan && (
                    <DivPlan>
                      <S.PlanCard>
                        <div className="planName">{plan.name}</div>
                        <span className="rows">
                          <div className="rows">
                            <div className="price_plan">{plan.price} USD</div>{' '}
                            <div className="period">&nbsp; /mês</div>
                          </div>{' '}
                          <div>
                            <b>{plan.ads_quantity}</b> imóveis
                          </div>
                        </span>
                      </S.PlanCard>
                    </DivPlan>
                  )}
                </div>
                <ButtonSave
                  type="submit"
                  disabled={info?.plan?.id === plan?.id || loading}
                  className={'btnDataPersonal btnload'}
                  onClick={(event) => editPlan(event)}>
                  {loading ? (
                    <ReactLoading
                      type={'spin'}
                      className="load"
                      color={colors.green_secondary}
                      width={'30px'}
                    />
                  ) : (
                    'Alterar'
                  )}
                </ButtonSave>
              </InternDiv>
            </form>
          </ModalContainer>
        </Dialog>
      </div>
    </>
  );
}
