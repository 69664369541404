import React, {useEffect, useState} from 'react';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import MyAds404 from '../404';
import MyAdsLoading from '../Loading';
import TableClients from './Table';

// import { Container } from './styles';

function Lead({users, getAllUsers}) {
  return (
    <>
      <TableClients getAllLeads={getAllUsers} information={users} />
    </>
  );
}

export default Lead;
