import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import metrics from '~/UI/metrics';

export const DivButton = styled.div`
  background: ${colors.white};
  height: 60px;
  padding: 10px 20px;
  border: #f2f2ed 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

export const DivButtonList = styled(DivButton)`
  background: ${colors.white};
  height: 60px;
  padding: 10px 20px;
  border: #f2f2ed 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
  margin-top: 40px;
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 100%;
  box-shadow: 0px 1px 3px #0000004d;
  /* padding-right: 5px; */
  background: #fff;
`;

export const ButtonBack = styled(Button)`
  background: ${colors.white};
  &:hover {
    background: #00000033;
  }
  &:disabled {
    background: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
`;

export const DivButtonIntern = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
`;

export const TextBack = styled.div`
  margin-left: 10px;
  font-size: 16px;
  font-family: Lato Regular;
  color: #1d1d1b;
  @media only screen and (min-width: 320px) and (max-width: 370px) {
    display: none;
  }
`;

export const TextForward = styled.div`
  margin-right: 10px;
  font-size: 16px;
  font-family: Lato Regular;
  color: ${(props) =>
    props.active ? colors.green_secondary : colors.gray_secondary};
`;

export const ButtonForward = styled(Button)`
  background: ${colors.green_secondary};
  color: white;
  &:hover {
    background: #2e6643;
    color: #fff;
  }
  &:disabled {
    background: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
`;
export const DivDouble = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 481px) and (max-width: 700px) {
    flex-direction: column;
  }
  @media (min-width: 800px) and (max-width: 2560px) {
    flex-direction: row;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ModalResponsive = styled.div`
  font-family: ${metrics.font_lato};
  @media (min-width: 481px) and (max-width: 700px) {
    width: 100%;
  }
  @media (min-width: 800px) and (max-width: 2560px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Star = styled.span`
  color: #a88721;
  margin-left: 2px;
`;
