import React, {useEffect} from 'react';

import Grid from '@material-ui/core/Grid';

import MenuProfile from '~/components/Menu/MenuProfile';
import InputText from '../components/InputTextRequired';
import InputTextArea from '../components/TextArea';
import AddressInput from '../components/AddressInput';
import SelectCustomStatus from '../components/SelectCustomStatus';
import SelectCustomPlan from '../components/SelectCustomPlan';
import RadioButtonGroup from '../components/RadioButtonGroup';
import ModalConfirmSend from '../components/ModalConfirmSend';

import {fireAction} from '~/utils/swal';

import * as validate from './validations';

import * as S from './styles';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';

import ReactLoading from 'react-loading';

import colors from '~/UI/colors';
import {useHistory} from 'react-router-dom';
import {recipientItems} from './recipients';

import * as Sentry from '@sentry/react';

function SendNotification() {
  const history = useHistory();

  /* ---------------------------------------------------- */
  /* Errors and touched controls */
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const toggleConfirm = React.useCallback(
    () => setModalConfirm(!modalConfirm),
    [modalConfirm],
  );

  /* ---------------------------------------------- */
  const [recipientGroup, setRecipientGroup] = React.useState(null);
  const [recipient, setRecipient] = React.useState({});
  const [recipientOptions, setRecipientOptions] =
    React.useState(recipientItems);
  const [planList, setPlanList] = React.useState([]);
  const [planChoose, setPlanChoose] = React.useState({});
  const [address, setAddress] = React.useState('');
  const [chooseAddress, setChooseAddress] = React.useState({});
  const [latitude, setLatitude] = React.useState('');
  const [longitude, setLongitude] = React.useState('');
  const [viewLat, setViewLat] = React.useState('');
  const [viewLong, setViewLong] = React.useState('');
  const [viewLat2, setViewLat2] = React.useState('');
  const [viewLong2, setViewLong2] = React.useState('');
  /* ---------------------------------------------- */

  /* ---------------------------------------------- */
  const [titlePt, setTitlePt] = React.useState('');
  const [titleEn, setTitleEn] = React.useState('');
  const [titleEs, setTitleEs] = React.useState('');

  const [messagePt, setMessagePt] = React.useState('');
  const [messageEn, setMessageEn] = React.useState('');
  const [messageEs, setMessageEs] = React.useState('');
  /* ---------------------------------------------- */

  /* Filter options group based choose */
  useEffect(() => {
    const updated = recipientGroup
      ? recipientItems?.filter((item) => item?.type === recipientGroup)
      : recipientItems;

    setRecipientOptions(updated);
  }, [recipientGroup]);

  /* Reset choose on change type user */
  useEffect(() => {
    if (Object.keys(recipient).length > 0) {
      recipient?.type !== recipientGroup && setRecipient({});
    }
  }, [recipient, recipientGroup]);

  useEffect(() => {
    getPlansActive();
  }, []);

  function getPlansActive() {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get(
        'v1/plan/plans_active?kind=seller',

        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        console.log(response);
        setPlanList(response.data.plans);
      })
      .catch((e) => Sentry.captureException(e));
  }

  function validAllInputs() {
    const disabled =
      recipientGroup === null ||
      !titlePt?.trim() ||
      (titlePt?.trim()?.length < validate.titleConfig?.MIN_LENGTH &&
        titlePt?.trim()?.length > validate.titleConfig?.MAX_LENGTH) ||
      !titleEn?.trim() ||
      (titleEn?.trim()?.length < validate.titleConfig?.MIN_LENGTH &&
        titleEn?.trim()?.length > validate.titleConfig?.MAX_LENGTH) ||
      !titleEs?.trim() ||
      (titleEs?.trim()?.length < validate.titleConfig?.MIN_LENGTH &&
        titleEs?.trim()?.length > validate.titleConfig?.MAX_LENGTH) ||
      !messagePt?.trim() ||
      (messagePt?.trim()?.length > validate.messageConfig?.MIN_LENGTH &&
        messagePt?.trim()?.length > validate.messageConfig?.MAX_LENGTH) ||
      !messageEn?.trim() ||
      (messageEn?.trim()?.length > validate.messageConfig?.MIN_LENGTH &&
        messageEn?.trim()?.length > validate.messageConfig?.MAX_LENGTH) ||
      !messageEs?.trim() ||
      (messageEs?.trim()?.length > validate.messageConfig?.MIN_LENGTH &&
        messageEs?.trim()?.length > validate.messageConfig?.MAX_LENGTH) ||
      (address.trim() && !Object.keys(chooseAddress).length) ||
      (address.trim() && !latitude) ||
      (address.trim() && !longitude) ||
      (address.trim() && !viewLat) ||
      (address.trim() && !viewLong) ||
      (address.trim() && !viewLat2) ||
      (address.trim() && !viewLong2) ||
      loading;

    return disabled;
  }

  /* ----------------------- */
  /* Changes and Blur */
  /* ----------------------- */
  const handleChange = (value, setValue, name, validation, config) => {
    setValue(value);

    setErrors((errors) => {
      return {
        ...errors,
        [name]: validation(value, config?.minLength, config?.maxLength),
      };
    });
  };

  const handleBlur = (value, setValue, name, validation, config) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        [name]: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        [name]: validation(value, config?.minLength, config?.maxLength),
      };
    });
  };

  /* --------------- */
  async function handleSendNotification() {
    try {
      setLoading(true);
      /* ---------------------- */
      /* PLAN */
      /* ---------------------- */
      const planData =
        recipientGroup === 'broker' && Object.keys(planChoose).length > 0
          ? {plan_id: planChoose?.id}
          : {};
      /* ---------------------- */
      /* BROKER STATUS */
      /* ---------------------- */
      const statusBroker =
        Object.keys(recipient).length > 0 && recipient?.status
          ? {status_broker: recipient?.status}
          : {};
      /* ---------------------- */
      /* USER COMMON STATUS */
      /* ---------------------- */
      const commonUserStatus =
        Object.keys(recipient).length > 0 && recipient?.status
          ? {common_user_status: recipient?.status}
          : {};
      /* ---------------------- */
      /* LOCALIZATION */
      /* ---------------------- */

      /* TODO novo */
      const localizationData =
        recipientGroup === '' && Object.keys(chooseAddress).length > 0
          ? {
              latitude,
              longitude,
              view_lat: viewLat,
              view_lon: viewLong,
              view_lat2: viewLat2,
              view_lon2: viewLong2,
              localization: chooseAddress?.formatted_address?.trim(),
            }
          : {};

      /* ---------------------- */
      /* NOTIFICATION */
      /* ---------------------- */
      const notifications = {
        title_pt: titlePt.trim(),
        message_pt: messagePt.trim(),
        title_en: titleEn.trim(),
        message_en: messageEn.trim(),
        title_es: titleEs.trim(),
        message_es: messageEs.trim(),
        type: 'Admin',
      };

      /* ---------------------- */
      /* BROKER */
      /* ---------------------- */
      const brokerData =
        recipientGroup === 'broker'
          ? {
              user: recipientGroup,
              ...planData,
              ...statusBroker,
            }
          : {};

      /* ---------------------- */
      /* OWNER */
      /* ---------------------- */
      const ownerData =
        recipientGroup === 'owner'
          ? {
              user: recipientGroup,
              ...commonUserStatus,
            }
          : {};

      /* ---------------------- */
      /* FINAL CONTENT REQUEST */
      /* ---------------------- */
      const finalData = {
        ...notifications,
        ...localizationData,
        ...ownerData,
        ...brokerData,
      };

      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.post(`/v1/notifications`, finalData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fireAction(
        'Tudo certo',
        'A notificação foi enviada com sucesso para os usuários com base nas configurações escolhidas. :)',
        'success',
      );

      history.replace('/notifications');
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 422) {
        fireAction(
          'Não foi possível completar sua solicitação',
          'Não encontramos nenhum usuário que atende aos parâmetros enviados para o recebimento da notificação. Por favor, tente utilizando outros parâmetros.',
          'warning',
        );
      } else {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante sua solicitação. Por favor, tente novamente mais tarde.',
          'error',
        );
      }
    } finally {
      toggleConfirm();
      setLoading(false);
    }
  }

  const [hasEmojiVisible, setHasEmojiVisible] = React.useState(false);
  const toggleEmojiVisibility = React.useCallback(
    () => setHasEmojiVisible(!hasEmojiVisible),
    [hasEmojiVisible],
  );

  return (
    <>
      <MenuProfile title="Enviar Nova Notificação" />
      <S.Container>
        {/* ----------------------------------- */}
        {/* General */}
        {/* ----------------------------------- */}

        <S.SafeAreaView noBorderedTop>
          <S.TitleSection>Destinatários</S.TitleSection>
        </S.SafeAreaView>
        <S.SafeAreaView>
          <S.Step>
            <Grid
              container
              spacing={2}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                width: '100%',
                margin: '0 auto',
              }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <S.UserTypeContainer>
                  <strong>Selecione o tipo de usuário</strong>
                  <RadioButtonGroup
                    selectedValue={recipientGroup}
                    setSelectedValue={setRecipientGroup}
                  />
                </S.UserTypeContainer>
              </Grid>
            </Grid>
          </S.Step>
        </S.SafeAreaView>
        {recipientGroup !== null && (
          <>
            {/* INPUTS */}
            {recipientGroup && (
              <S.SafeAreaView>
                <S.Step>
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      width: '100%',
                      margin: '0 auto',
                    }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <SelectCustomStatus
                        label="Destinatário"
                        placeholder="Selecione um grupo..."
                        value={recipient}
                        items={recipientOptions}
                        setValue={setRecipient}
                      />
                    </Grid>
                  </Grid>
                </S.Step>
              </S.SafeAreaView>
            )}
            {recipientGroup !== null && recipientGroup !== 'owner' && (
              <S.SafeAreaView>
                <S.Step>
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      width: '100%',
                      margin: '0 auto',
                    }}>
                    {recipientGroup === '' && (
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AddressInput
                          optional
                          label="Notificação por localidade"
                          placeholder="Informe uma localidade..."
                          value={address}
                          setValue={setAddress}
                          chooseAddress={chooseAddress}
                          setChooseAddress={setChooseAddress}
                          setLat={setLatitude}
                          setLong={setLongitude}
                          setViewLat={setViewLat}
                          setViewLong={setViewLong}
                          /* TODO novo */
                          setViewLat2={setViewLat2}
                          setViewLong2={setViewLong2}
                          errors={errors.address}
                          touched={touched.address}
                        />
                      </Grid>
                    )}

                    {recipientGroup === 'broker' && (
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <SelectCustomPlan
                          label="Enviar para usuários do plano"
                          placeholder="Selecione um plano"
                          value={planChoose}
                          setValue={setPlanChoose}
                          items={planList}
                        />
                      </Grid>
                    )}
                  </Grid>
                </S.Step>
              </S.SafeAreaView>
            )}

            <S.SafeAreaView>
              <S.EmojiButton onClick={toggleEmojiVisibility}>
                {hasEmojiVisible
                  ? 'Fechar caixa de emojis'
                  : 'Abrir caixa de emojis'}
              </S.EmojiButton>
              {hasEmojiVisible && (
                <S.EmojiContainer>
                  <span role="img" aria-label="emoji">
                    😀 😃 😄 😁 😆 😅 😂 🤣 😇 😉 😊 🙂 🙃 ☺ 😋 😌 😍 🥰 😘 😗
                    😙 😚 🤪 😜 😝 😛 🤑 😎 🤓 🧐 🤠 🥳 🤗 🤡 😏 😶 😐 😑 😒 🙄
                    🤨 🤔 🤫 🤭 🤥 😳 😞 😟 😠 😡 🤬 😔 😕 🙁 ☹ 😬 🥺 😣 😖 😫
                    😩 🥱 😤 😮 😱 😨 😰 😯 😦 😧 😢 😥 😪 🤤 😓 😭 🤩 😵 🥴 😲
                    🤯 🤐 😷 🤕 🤒 🤮 🤢 🤧 🥵 🥶 😴 💤 😈 👿 👹 👺 💩 👻 💀 ☠
                    👽 🤖 🎃 😺 😸 😹 😻 😼 😽 🙀 😿 😾 👐 🤲 🙌 👏 🙏 🤝 👍 👎
                    👊 ✊ 🤛 🤜 🤞 ✌ 🤘 🤟 👌 🤏 👈 👉 👆 👇 ☝ ✋ 🤚 🖐 🖖 👋
                    🤙 💪 🦾 🖕 ✍ 🤳 💅 🦵 🦿 🦶 👄 🦷 👅 👂 🦻 👃 👁 👀 🧠 🦴
                    👤 👥 🗣 👶 👧 🧒 👦 👩 🧑 👨 👩‍🦱 🧑‍🦱 👨‍🦱 👩‍🦰 🧑‍🦰 👨‍🦰 👱‍♀️ 👱
                    👱‍♂️ 👩‍🦳 🧑‍🦳 👨‍🦳 👩‍🦲 🧑‍🦲 👨‍🦲 🧔 👵 🧓 👴 👲 👳‍♀️ 👳 👳‍♂️ 🧕 👼 👸
                    🤴 👰 🤵‍♀️ 🤵 🤵‍♂️ 🙇‍♀️ 🙇 🙇‍♂️ 💁‍♀️ 💁 💁‍♂️ 🙅‍♀️ 🙅 🙅‍♂️ 🙆‍♀️ 🙆 🙆‍♂️ 🤷‍♀️
                    🤷 🤷‍♂️ 🙋‍♀️ 🙋 🙋‍♂️ 🤦‍♀️ 🤦 🤦‍♂️ 🧏‍♀️ 🧏 🧏‍♂️ 🙎‍♀️ 🙎 🙎‍♂️ 🙍‍♀️ 🙍 🙍‍♂️ 💇‍♀️ 💇 💇‍♂️
                    💆‍♀️ 💆 💆‍♂️ 🤰 🤱 🧎‍♀️ 🧎 🧎‍♂️ 🧍‍♀️ 🧍 🧍‍♂️ 🚶‍♀️ 🚶 🚶‍♂️ 👩‍🦯 🧑‍🦯 👨‍🦯 🏃‍♀️ 🏃
                    🏃‍♂️ 👩‍🦼 🧑‍🦼 👨‍🦼 👩‍🦽 🧑‍🦽 👨‍🦽 💃 🕺 👫 👭 👬 🧑‍🤝‍🧑 👩‍❤️‍👨 👩‍❤️‍👩 💑 👨‍❤️‍👨 👩‍❤️‍💋‍👨
                    👩‍❤️‍💋‍👩 💏 👨‍❤️‍💋‍👨 ❤ 🧡 💛 💚 💙 💜 🤎 🖤 🤍 💔 ❣ 💕 💞 💓 💗 💖 💘 💝
                    💟 📆🗓📅⚖💰💶💷💸💵
                    🎲🏅🥇🥈🥉🏆🎤👮‍♀️🎮🎳🎯👩‍💻👨‍💻👪👩‍👩‍👦👩‍👩‍👧👨‍👨‍👦👨‍👨‍👧👩‍👦👩‍👧👩‍👧‍👦👩‍👦‍👦👩‍👧‍👧👨‍👦👨‍👧👨‍👧‍👦👨‍👦‍👦👨‍👧‍👧 🚗
                    🚙 🚕 🛺 🚌 🚎 🏎 🚓 🚑 🚒 🚐 🚚 🚛 🚜 🏍 🛵 🚲 🦼 🦽 🛴 🛹 🚨
                    🚔 🚍 🚘 🚖 🚡 🚠 🚟 🚃 🚋 🚝 🚄 🚅 🚈 🚞 🚂 🚆 🚇 🚊 🚉 🚁
                    🛩 ✈ 🛫 🛬 🪂 💺 🛰 🚀 🛸 🛶 ⛵ 🛥 🚤 ⛴ 🛳 🚢 ⚓ ⛽ 🚧 🚏 🚦 🚥
                    🛑 🎡 🎢 🎠 🏗 🌁 🗼 🏭 ⛲ 🎑 ⛰ 🏔 🗻 🌋 🗾 🏕 ⛺ 🏞 🛣 🛤 🌅 🌄 🏜
                    🏖 🏝 🌇 🌆 🏙 🌃 🌉 🌌 🌠 🎇 🎆 🏘 🏰 🏯 🏟 🗽 🏠 🏡 🏚 🏢 🏬 🏣
                    🏤 🏥 🏦 🏨 🏪 🏫 🏩 💒 🏛 ⛪ 🕌 🛕 🕍 🕋 ⛩ 🔔📢📣
                  </span>
                </S.EmojiContainer>
              )}
            </S.SafeAreaView>
            {/* PORTUGUESE MESSAGE */}
            <S.SafeAreaView>
              <S.TitleSection>Notificação em português</S.TitleSection>
            </S.SafeAreaView>
            <S.SafeAreaView>
              <S.Step>
                <Grid
                  container
                  spacing={2}
                  style={{
                    width: '100%',
                    margin: '0 auto 30px',
                  }}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputText
                      label="Título PT"
                      placeholder="Título em português..."
                      value={titlePt}
                      setValue={setTitlePt}
                      error={errors.title_pt}
                      touched={touched.title_pt}
                      handleChange={(e) =>
                        handleChange(
                          e.target.value,
                          setTitlePt,
                          'title_pt',
                          validate.titleSchema,
                          {
                            minLength: validate.titleConfig.MIN_LENGTH,
                            maxLength: validate.titleConfig.MAX_LENGTH,
                          },
                        )
                      }
                      handleBlur={(e) =>
                        handleBlur(
                          e.target.value,
                          setTitlePt,
                          'title_pt',
                          validate.titleSchema,
                          {
                            minLength: validate.titleConfig.MIN_LENGTH,
                            maxLength: validate.titleConfig.MAX_LENGTH,
                          },
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputTextArea
                      label="Mensagem PT"
                      placeholder="Mensagem em português..."
                      value={messagePt}
                      setValue={setMessagePt}
                      error={errors.message_pt}
                      touched={touched.message_pt}
                      handleChange={(e) =>
                        handleChange(
                          e.target.value,
                          setMessagePt,
                          'message_pt',
                          validate.messageSchema,
                          {
                            minLength: validate.messageConfig.MIN_LENGTH,
                            maxLength: validate.messageConfig.MAX_LENGTH,
                          },
                        )
                      }
                      handleBlur={(e) =>
                        handleBlur(
                          e.target.value,
                          setMessagePt,
                          'message_pt',
                          validate.messageSchema,
                          {
                            minLength: validate.messageConfig.MIN_LENGTH,
                            maxLength: validate.messageConfig.MAX_LENGTH,
                          },
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </S.Step>
            </S.SafeAreaView>

            {/* ENGLISH MESSAGE */}
            <S.SafeAreaView>
              <S.TitleSection>Notificação em inglês</S.TitleSection>
            </S.SafeAreaView>
            <S.SafeAreaView>
              <S.Step>
                <Grid
                  container
                  spacing={2}
                  style={{
                    width: '100%',
                    margin: '0 auto 30px',
                  }}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputText
                      label="Título EN"
                      placeholder="Título em inglês..."
                      value={titleEn}
                      setValue={setTitleEn}
                      error={errors.title_en}
                      touched={touched.title_en}
                      handleChange={(e) =>
                        handleChange(
                          e.target.value,
                          setTitleEn,
                          'title_en',
                          validate.titleSchema,
                          {
                            minLength: validate.titleConfig.MIN_LENGTH,
                            maxLength: validate.titleConfig.MAX_LENGTH,
                          },
                        )
                      }
                      handleBlur={(e) =>
                        handleBlur(
                          e.target.value,
                          setTitleEn,
                          'title_en',
                          validate.titleSchema,
                          {
                            minLength: validate.titleConfig.MIN_LENGTH,
                            maxLength: validate.titleConfig.MAX_LENGTH,
                          },
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputTextArea
                      label="Mensagem EN"
                      placeholder="Mensagem em inglês..."
                      value={messageEn}
                      setValue={setMessageEn}
                      error={errors.message_en}
                      touched={touched.message_en}
                      handleChange={(e) =>
                        handleChange(
                          e.target.value,
                          setMessageEn,
                          'message_en',
                          validate.messageSchema,
                          {
                            minLength: validate.messageConfig.MIN_LENGTH,
                            maxLength: validate.messageConfig.MAX_LENGTH,
                          },
                        )
                      }
                      handleBlur={(e) =>
                        handleBlur(
                          e.target.value,
                          setMessageEn,
                          'message_en',
                          validate.messageSchema,
                          {
                            minLength: validate.messageConfig.MIN_LENGTH,
                            maxLength: validate.messageConfig.MAX_LENGTH,
                          },
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </S.Step>
            </S.SafeAreaView>

            {/* SPANISH MESSAGE */}
            <S.SafeAreaView>
              <S.TitleSection>Notificação em espanhol</S.TitleSection>
            </S.SafeAreaView>
            <S.SafeAreaView>
              <S.Step>
                <Grid
                  container
                  spacing={2}
                  style={{
                    width: '100%',
                    margin: '0 auto 30px',
                  }}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <InputText
                      label="Título ES"
                      placeholder="Título em espanhol..."
                      value={titleEs}
                      setValue={setTitleEs}
                      error={errors.title_es}
                      touched={touched.title_es}
                      handleChange={(e) =>
                        handleChange(
                          e.target.value,
                          setTitleEs,
                          'title_es',
                          validate.titleSchema,
                          {
                            minLength: validate.titleConfig.MIN_LENGTH,
                            maxLength: validate.titleConfig.MAX_LENGTH,
                          },
                        )
                      }
                      handleBlur={(e) =>
                        handleBlur(
                          e.target.value,
                          setTitleEs,
                          'title_es',
                          validate.titleSchema,
                          {
                            minLength: validate.titleConfig.MIN_LENGTH,
                            maxLength: validate.titleConfig.MAX_LENGTH,
                          },
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputTextArea
                      label="Mensagem ES"
                      placeholder="Mensagem em espanhol..."
                      value={messageEs}
                      setValue={setMessageEs}
                      error={errors.message_es}
                      touched={touched.message_es}
                      handleChange={(e) =>
                        handleChange(
                          e.target.value,
                          setMessageEs,
                          'message_es',
                          validate.messageSchema,
                          {
                            minLength: validate.messageConfig.MIN_LENGTH,
                            maxLength: validate.messageConfig.MAX_LENGTH,
                          },
                        )
                      }
                      handleBlur={(e) =>
                        handleBlur(
                          e.target.value,
                          setMessageEs,
                          'message_es',
                          validate.messageSchema,
                          {
                            minLength: validate.messageConfig.MIN_LENGTH,
                            maxLength: validate.messageConfig.MAX_LENGTH,
                          },
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </S.Step>
            </S.SafeAreaView>
          </>
        )}
        <div
          style={{
            position: 'absolute',
            bottom: '20px',
            width: '100%',
            left: 'auto',
            right: 'auto',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <S.ButtonAds disabled={validAllInputs()} onClick={toggleConfirm}>
            {loading ? (
              <ReactLoading
                type={'spin'}
                color={colors.green_secondary}
                width={'30px'}
                height={'30px'}
              />
            ) : (
              'Enviar'
            )}
          </S.ButtonAds>
        </div>
      </S.Container>
      <ModalConfirmSend
        open={modalConfirm}
        setOpen={toggleConfirm}
        isSubmitting={loading}
        handleSubmit={handleSendNotification}
      />
    </>
  );
}

export default SendNotification;
