import styled from 'styled-components';
import colors from '../../../UI/colors';
import fonts from '../../../UI/fonts';

export const Container = styled.div`
  .__list_menu {
    a {
      &.active {
        > div {
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          background-color: ${colors.green_secondary};
          color: ${colors.white};
          font-weight: bold;
          svg {
            color: ${colors.white};
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .drawerCloseOverride,
    .paperCloseOverride {
      width: 0px !important;
    }
  }
`;
export const User = styled.div`
  height: 55px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const GroundUser = styled.div`
  display: flex;
  background: #00000033;
  flex-direction: ${(props) => (props.open ? 'row' : 'center')};
  align-items: center;
  justify-content: ${(props) => (props.open ? 'flex-start' : 'center')};
  width: ${(props) => (props.open ? '225px' : '50px')};
  height: 50px;
  border-radius: ${(props) => (props.open ? '30px' : '50%')};
  box-shadow: 0px 3px 6px #00000066;
  padding: ${(props) => (props.open ? '0px 15px 0px 5px' : '0px')};
`;

export const ImgUser = styled.img`
  border-radius: 50%;
  object-fit: cover;
`;
export const UserName = styled.div`
  margin-left: 15px;
  max-width: 140px;
  position: relative;

  /*  margin-right: 35px; */
`;

export const NameUser = styled.p`
  display: ${(props) => (props.open ? 'block' : 'none')};
  color: ${colors.white};

  font-family: ${fonts.latoRegularBold};
  font-size: 14px;
  font-weight: bold;

  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const RoleUser = styled.span`
  display: ${(props) => (props.open ? 'block' : 'none')};
  color: ${colors.white};

  font-family: ${fonts.latoRegular};
  font-size: 12px;
  font-weight: normal;

  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;

  position: absolute;
  top: 20px;
`;

export const DivIcon = styled.div`
  svg {
    color: ${colors.green_secondary};
  }
`;

export const DivItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background-color: transparent;
  color: ${colors.white};
  font-weight: normal;

  transition: background 0.2s linear;

  &:hover {
    color: ${colors.white};
    background: rgba(0, 0, 0, 0.2);
  }

  svg {
    color: ${colors.green_secondary};
  }
`;

export const AdsContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 50px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 434px) {
    > div {
      margin-left: 10px;
    }
  }

  @media (max-width: 545px) {
    /*   flex-direction: column;
    align-items: flex-start;
 */
    > div {
      button {
        font-size: 14px;
        max-width: 150px;
        height: 30px;
      }
    }
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  @media (max-width: 434px) {
    > div {
      margin-left: 10px;
    }
  }

  @media (max-width: 545px) {
    /*  flex-direction: column;
    align-items: flex-start; */

    > div {
      button {
        font-size: 14px;
        max-width: 150px;
        height: 30px;
      }
    }
  }
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }

  @media (max-width: 434px) {
    > div {
      margin-left: 10px;
    }
  }

  @media (max-width: 545px) {
    /*  flex-direction: column;
    align-items: flex-start; */

    > div {
      button {
        font-size: 14px;
        max-width: 150px;
        height: 30px;
      }
    }
  }
`;
