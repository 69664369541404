import React, {useState} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import setUserAvatar from '~/redux/action/action_user_avatar';
import setRoleUser from '~/redux/action/action_user_role';
import logo from '~/assets/Logotipo/logo.png';
import crypto from 'crypto-js';
import colors from '~/UI/colors';
import ReactLoading from 'react-loading';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ModalError from '~/components/ModalError';
import {transformAvatarURL, encrypta} from '~/utils';
import apiBackend from '~/services/apiaxios';

import {fireAction} from '~/utils/swal';

import {
  DivLogin,
  Input,
  Label,
  Eyes,
  ButtonLogin,
  Adrees,
  DivLabel,
  DivError,
  ErrorMessage,
  Scope,
  InputField,
  LinksContainer,
} from './styles';
import './styles.css';
import {desencrypta} from '../Annoucement/View/utils';

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pass, setPass] = useState('');
  const [eyeIcon, setEyeIcon] = useState(true);
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  async function signin() {
    setLoading(true);
    const key = process.env.REACT_APP_KEY_GENERATE_HASH;
    let hash_password = crypto.AES.encrypt(pass, key).toString();

    apiBackend
      .post('/v1/login/collaborator', {
        email: email?.trim(),
        password: hash_password,
      })
      .then((response) => {
        console.log(response);

        if (response?.data?.token) {
          const authToken = encrypta(response?.data?.token?.token);

          localStorage.setItem('id', response?.data?.user?.id);
          localStorage.setItem('name', response?.data?.user?.name);
          if (response?.data?.user?.surname) {
            localStorage.setItem('surname', response?.data?.user?.name);
          }
          localStorage.setItem('@User_informations', authToken);

          const userAvatar = transformAvatarURL(
            response?.data?.user?.avatar?.avatar_url,
          );

          if (userAvatar) {
            dispatch(setUserAvatar(userAvatar));
          }

          const role = response?.data?.user?.role_id;
          dispatch(setRoleUser(role));

          switch (role?.toString()) {
            case '5':
              return history.replace('/dashboard');
            case '4':
              return history.replace('/dashboard');
            case '3':
              return history.replace('/announcement');
            case '2':
              return history.replace('/real-state');
            default:
              return history.replace('/profile');
          }
        } else {
          fireAction(
            'Erro ao logar',
            'Credenciais incorretas, tente novamente',
            'error',
          );
        }
      })
      .catch((err) => {
        if (err.response.data.errors[0].field === 'password') {
          fireAction(
            'Erro ao logar',
            'A senha informada está incorreta, tente novamente',
            'error',
          );
        }

        if (err.response.data.errors[0].field === 'email') {
          fireAction(
            'Erro ao logar',
            'Email não cadastrado, tente novamente!',
            'error',
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  /* Change document.querySelector to useRef */
  const passwordRef = React.useRef(null);

  function handleIsEye() {
    passwordRef.current.type === 'password'
      ? (passwordRef.current.type = 'text')
      : (passwordRef.current.type = 'password');
    setEyeIcon(!eyeIcon);
  }

  /* -------------------------------------- */
  /* ------------- Validations ------------ */
  /* -------------------------------------- */

  const handleChangeEmail = (value) => {
    let reg = /\S+@\S+\.\S+/;

    setEmail(value);

    setErrors((errors) => {
      return {
        ...errors,
        email: !value
          ? 'Campo não preenchido'
          : !reg.test(value)
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  const handleBlurEmail = (value) => {
    let reg = /\S+@\S+\.\S+/;

    setTouched((toucheds) => {
      return {
        ...toucheds,
        email: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        email: !value
          ? 'Campo não preenchido'
          : !reg.test(value)
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  const handleChangePassword = (value) => {
    setPass(value);

    setErrors((errors) => {
      return {
        ...errors,
        password: !value ? 'Campo não preenchido' : '',
      };
    });
  };

  const handleBlurPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        password: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        password: !value ? 'Campo não preenchido' : '',
      };
    });
  };

  /* Controll to hability or disability button submit */
  const isValidForm = React.useMemo(() => {
    if (!email || !pass || errors.email || errors.password || loading) {
      return true;
    } else {
      return false;
    }
  }, [email, errors.email, errors.password, loading, pass]);

  return (
    <>
      <Scope>
        <DivLogin>
          <img
            src={logo}
            width="229.15px"
            height="60px"
            style={{marginBottom: '49px'}}
            alt="Brand"
          />

          <InputField>
            <DivLabel>
              <Label valid={email} error={errors.email}>
                Email
              </Label>
            </DivLabel>
            <Input
              value={email}
              onChange={(event) => handleChangeEmail(event.target.value)}
              onBlur={(event) => handleBlurEmail(event.target.value)}
              placeholder="Digite o email do seu login"
              error={errors.email}
              touched={touched.email}
            />
          </InputField>
          {errors.email && (
            <DivError>
              <ErrorMessage>{errors.email}</ErrorMessage>
            </DivError>
          )}

          <InputField>
            <DivLabel>
              <Label valid={pass} error={errors.password}>
                Senha
              </Label>
            </DivLabel>
            <Input
              value={pass}
              onChange={(event) => handleChangePassword(event.target.value)}
              onBlur={(event) => handleBlurPassword(event.target.value)}
              style={{zIndex: '1'}}
              ref={passwordRef}
              type="password"
              placeholder="Digite sua senha..."
              error={errors.password}
              touched={touched.password}
            />
            <Eyes onClick={() => handleIsEye()}>
              {eyeIcon === false ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Eyes>
          </InputField>
          {errors.password && (
            <DivError>
              <ErrorMessage>{errors.password}</ErrorMessage>
            </DivError>
          )}

          <Adrees type="text" name="adress" className="adress" tabIndex="-1" />
          <ButtonLogin disabled={isValidForm} onClick={() => signin()}>
            {loading ? (
              <ReactLoading
                className="load"
                type={'spin'}
                color={colors.green_secondary}
                width={'30px'}
              />
            ) : (
              'ENTRAR'
            )}
          </ButtonLogin>
          <LinksContainer>
            <p>
              Esqueceu sua senha? <Link to="/forgot-password">Recuperar</Link>
            </p>
          </LinksContainer>
        </DivLogin>
      </Scope>
      <ModalError modal={modal} setModal={setModal} />
    </>
  );
}
