import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {desencrypta} from '~/utils';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalExecutarXML({
  id,
  open,
  setOpen,
  text,
  handleDelete,
  listUser,
  page,
  setPage,
  info,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  /* async function deleteUser() {
    handleDelete(id);
    handleClose();
  } */

  const handleExecuteXML = async () => {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    const id_user = localStorage.getItem('id');
    console.log(token);
    // /xml/:id_admin/run/:id
    await apiBackend
      .put(`v1/xml/${id_user}/run/${info?.xml?.id}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        fireAction(
          'Execução do XML iniciada',
          'Em alguns instantes seu XML será executado',
          'success',
        );
        handleClose();
      })
      .catch((e) => {
        if (e?.response?.data?.data?.message === 'Xml not found') {
          fireAction('A execução do XML falhou', 'O XML não existe', 'error');
        }
        if (e?.response?.data?.data?.message === 'User not found') {
          fireAction('A execução do XML falhou', 'O XML não existe', 'error');
        }
        if (e?.response?.status === 401) {
          fireAction(
            'Acesso negado',
            'Você não tem permissão para realizar essa ação',
            'error',
          );
        } else {
          Sentry.captureException(e);
          fireAction(
            'A execução do XML falhou',
            'Problemas no servidor',
            'error',
          );
        }
        // 404 - user not fould 401 - Acesso negado 404 - XML not found
        // xml, subscription, status_broker
      });
  };

  // const alertExecuteXML = (info) => {
  //   fireActionMethods(
  //     'Anúncio deletado',
  //     'Você excluiu um Lead, ele não poderá mais ser recuperado.',
  //     'success',
  //     handleExecutarXML(info),
  //   );
  // };

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle>Atenção</AdvertTitle>
              <Message>Deseja realmente executar o XML?</Message>
              <DivConfirm>
                <ButtonsWhite
                  disabled={isSubmitting}
                  onClick={handleExecuteXML}>
                  SIM
                </ButtonsWhite>
                <ButtonsWhite disabled={isSubmitting} onClick={handleClose}>
                  NÃO
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
