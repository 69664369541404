import apiBackend from '../../services/apiaxios';
import {desencrypta} from '../../utils';

import * as Sentry from '@sentry/react';

async function getDetailsFeatures(
  optionsIntern,
  optionsExtern,
  setOptionsAllExtern,
  setOptionsExtern,
  setOptionsAllIntern,
  setOptionsIntern,
) {
  let token = desencrypta(localStorage.getItem('@User_informations'));
  apiBackend
    .get('v1/property/data/intl', {
      headers: {Authorization: `Bearer ${token}`},
    })
    .then((response) => {
      console.log(response);
      loadFeatures(
        optionsExtern,
        response.data.featureExternal,
        setOptionsAllExtern,
        setOptionsExtern,
      );
      loadFeatures(
        optionsIntern,
        response.data.featureInternal,
        setOptionsAllIntern,
        setOptionsIntern,
      );
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}

function loadFeatures(options, features, setOptionsAll, setOptions) {
  const itens = features.map((item) => ({
    ...item,
    choose: false,
  }));
  setOptionsAll(itens);

  setOptions(itens.splice(0, 5));
}

export default getDetailsFeatures;
