import React, {useState, useEffect} from 'react';

import VMasker from 'vanilla-masker';
import CloseIcon from '@material-ui/icons/Close';

import * as validate from '~/utils/regex';

import * as Sentry from '@sentry/react';

import {
  TitleModal,
  InternDiv,
  DivInputText,
  Rotule,
  InputText,
  DivError,
  ErrorMessage,
  ButtonSave,
} from '../../Clients/Edit/ModalDataPersonal/styles';
import InputPhone from '~/components/InputPhone';

import {InfoUser} from '../../Clients/Edit/styles';

import './styles.css';
import '../../styles.css';
import colors from '~/UI/colors';

import ReactLoading from 'react-loading';

import {Star} from './styles';
import RadioButtons from './RadioButton';
import {Dialog} from '@material-ui/core';

import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {desencrypta} from '~/utils';

export default function ModalEditColaborator({
  page,
  setPage,
  listUser,
  colab,
  setColab,
  open,
  setOpen,
  flag,
}) {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [cargo, setCargo] = useState('');
  const [selectedValue, setSelectedValue] = useState('5');

  React.useEffect(() => {
    async function getProfileData() {
      try {
        setLoading(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.get(`/v1/users/${colab.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        /* console.log(data); */
        setName(data?.user?.name ? data?.user?.name : '');
        setSurname(data?.user?.surname ? data?.user?.surname : '');
        setEmail(data?.user?.email ? data?.user?.email : '');
        setPhone(data?.user?.cellphone ? data?.user?.cellphone : '');
        setCargo(data?.user?.cargo ? data?.user?.cargo : '');
        setSelectedValue(
          data?.user?.role_id ? String(data?.user?.role_id) : '',
        );
        // listUser();
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    }

    if (colab?.id && open) {
      getProfileData();
    }
  }, [colab, open]);

  const handleClose = () => {
    setOpen(false);
    if (!flag) {
      clearModal();
    }
  };

  function clearModal() {
    setColab('');
    setName('');
    setSurname('');
    setEmail('');
    setPhone('');
    setCargo('');
    setSelectedValue('5');
    setErrors({});
    setTouched({});
  }

  /* ------------------------------------ */
  /* ----------- VALIDATIONS ------------ */
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* ______ NAME ______ */
  const handleChangeName = (value) => {
    setName(value);

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurName = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        name: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ______ Surname ______ */
  const handleChangeSurname = (value) => {
    setSurname(value);

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurSurname = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        surname: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ______ EMAIL ______ */
  const handleChangeEmail = (value) => {
    setEmail(value);

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value.trim())
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  const handleBlurEmail = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        email: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value.trim())
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  /* ______ PHONE ______ */
  const handleChangePhone = (value) => {
    setPhone(value);

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value
          ? 'Campo não preenchido'
          : value.trim() && value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  const handleBlurPhone = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        phone: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value
          ? 'Campo não preenchido'
          : value.trim() && value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  /* ______ Cargo ______ */
  const handleChangeCargo = (value) => {
    setCargo(value);

    setErrors((errors) => {
      return {
        ...errors,
        cargo: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurCargo = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        cargo: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        cargo: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ------------------------------------ */

  /* ----------- UPDATE ----------- */

  async function updateUser(event) {
    event.preventDefault();

    const userUpdated = {
      name: name.trim(),
      surname: surname.trim(),
      email: email.trim(),
      cargo: cargo.trim(),
      cellphone: phone.trim(),
      /* role_id: selectedValue, */
    };

    try {
      setIsSubmitting(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.put(
        `/v1/users/${colab?.id}`,
        userUpdated,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log(data);
      handleClose();
      fireAction(
        'Tudo certo',
        'A edição do novo colaborador foi realizado com sucesso.',
        'success',
      );

      if (flag) {
        localStorage.setItem('name', name.trim());
        localStorage.setItem('surname', surname.trim());
      }

      /* Return to page 1 with new list */
      if (page === 1) {
        listUser(1);
      } else {
        setPage(1);
      }
    } catch (err) {
      /* Email has exists */
      if (err.response.status === 422) {
        fireAction(
          'Não foi possível completar sua solicitação',
          'Já existe um colaborador cadastrado com o e-mail informado. Por favor, tente outro e-mail.',
          'error',
        );
      } else {
        /* error not especifc */
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante a edição do novo colaborador. Por favor, tente novamente mais tarde.',
          'error',
        );
      }

      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  /* Clear on keep out */
  useEffect(() => {
    if (!open) {
      if (!flag) clearModal();
    }
  }, [open]);

  return (
    <div>
      <Dialog
        scroll="body"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <TitleModal>
          <span>Editar Colaborador</span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        {Object.entries(colab).length > 0 && !loading ? (
          <form onSubmit={updateUser} style={{padding: '20px'}}>
            <InternDiv>
              <div>
                <DivInputText>
                  <Rotule error={errors.name} valid={name}>
                    Nome <Star>*</Star>
                  </Rotule>
                  <InputText
                    error={errors.name}
                    touched={touched.name}
                    value={name}
                    onChange={(event) => handleChangeName(event.target.value)}
                    onBlur={(event) => handleBlurName(event.target.value)}
                    placeholder="Informe o nome do usuário"
                  />
                  {errors.name && (
                    <DivError>
                      <ErrorMessage>{errors.name}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule error={errors.surname} valid={surname}>
                    Sobrenome <Star>*</Star>
                  </Rotule>
                  <InputText
                    error={errors.surname}
                    touched={touched.surname}
                    value={surname}
                    onChange={(event) =>
                      handleChangeSurname(event.target.value)
                    }
                    onBlur={(event) => handleBlurSurname(event.target.value)}
                    placeholder="Informe o sobrenome do usuário"
                  />
                  {errors.surname && (
                    <DivError>
                      <ErrorMessage>{errors.surname}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule error={errors.email} valid={email}>
                    Email <Star>*</Star>
                  </Rotule>
                  <InputText
                    error={errors.email}
                    touched={touched.email}
                    value={email}
                    onChange={(event) => handleChangeEmail(event.target.value)}
                    onBlur={(event) => handleBlurEmail(event.target.value)}
                    placeholder="Informe o e-mail do usuário"
                  />
                  {errors.email && (
                    <DivError>
                      <ErrorMessage>{errors.email}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                {/* {!flag && (
                  <DivInputText className="divMargin">
                    <Rotule valid={selectedValue}>
                      Selecione o tipo de acesso <Star>*</Star>
                    </Rotule>
                    <RadioButtons
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                    />
                  </DivInputText>
                )} */}

                <DivInputText className="divMargin">
                  <Rotule error={errors.cargo} valid={cargo}>
                    Cargo <Star>*</Star>
                  </Rotule>
                  <InputText
                    error={errors.cargo}
                    touched={touched.cargo}
                    value={cargo}
                    onChange={(event) => handleChangeCargo(event.target.value)}
                    onBlur={(event) => handleBlurCargo(event.target.value)}
                    placeholder="Informe o cargo do usuário"
                  />
                  {errors.cargo && (
                    <DivError>
                      <ErrorMessage>{errors.cargo}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule error={errors.phone} valid={phone}>
                    Telefone <Star>*</Star>
                  </Rotule>
                  <InputPhone
                    error={errors.phone}
                    touched={touched.phone}
                    value={phone}
                    onChange={handleChangePhone}
                    onBlur={handleBlurPhone}
                    placeholder="Informe o telefone do usuário"
                  />
                  {errors.phone && (
                    <DivError>
                      <ErrorMessage>{errors.phone}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>
              </div>
              <ButtonSave
                type="submit"
                disabled={
                  !name.trim() ||
                  errors.name ||
                  !surname.trim() ||
                  errors.surname ||
                  !email.trim() ||
                  errors.email ||
                  !cargo.trim() ||
                  errors.cargo ||
                  !phone.trim() ||
                  errors.phone ||
                  /* !selectedValue || */
                  isSubmitting
                }
                className={'btnDataPersonal btnload'}>
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color={colors.green_secondary}
                    width="30px"
                    height="30px"
                  />
                ) : (
                  'Atualizar'
                )}
              </ButtonSave>
            </InternDiv>
          </form>
        ) : (
          <InternDiv>
            <InfoUser>
              <div
                style={{
                  minHeight: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ReactLoading
                  type="spin"
                  className="load"
                  color={colors.green_secondary}
                  width="30px"
                  height="30px"
                />
              </div>
            </InfoUser>
          </InternDiv>
        )}
      </Dialog>
    </div>
  );
}
