import styled from 'styled-components';
import colors from '~/UI/colors';
import {ButtonMedium} from '~/UI/styles';

export const DivImg = styled.div`
  display: flex;
  cursor: pointer;
`;

export const LogoProfile = styled.img`
  width: 115px;
  height: 30.06px;
`;

export const DivReturn = styled.div`
  cursor: pointer;
  &:hover {
    color: ${colors.dark_light};
  }
`;

export const ButtonGreen = styled(ButtonMedium)`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    color: ${colors.green_secondary};
  }
  @media (max-width: 768px) {
    display: none;
  }

  &:disabled {
    background-color: ${colors.gray_secondary};
  }
`;

export const Filter = styled.div`
  font-size: 16px;
  color: #e6ffef;
  font-family: 'Lato';
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${colors.white};
  }
  margin-left: 30px;
`;

export const DivDisplay = styled.div`
  @media only screen and (max-width: 768px) {
    padding: 20px 30px 0px 30px;

    .mobileButton {
      display: none;
    }
  }
`;
