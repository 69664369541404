import React from 'react';

import * as S from '../../Register/styles';

function InputText({
  value,
  error,
  touched,
  label,
  handleChange,
  handleBlur,
  placeholder,
  optional,
}) {
  return (
    <S.DivInputText>
      <S.Rotule error={error} valid={value}>
        {label} {!optional && '*'}
      </S.Rotule>
      <S.InputText
        error={error}
        touched={touched}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
      {error && (
        <S.DivError>
          <S.ErrorMessage>{error}</S.ErrorMessage>
        </S.DivError>
      )}
    </S.DivInputText>
  );
}

export default InputText;
