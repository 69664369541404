import apiBackend from '../../services/apiaxios';
import {desencrypta} from '../../utils';

import * as Sentry from '@sentry/react';

export async function checkUser(setValue) {
  let token = localStorage.getItem('@User_informations') || '';
  if (token) {
    let info = desencrypta(token);
    /* console.log(info); */
    return apiBackend
      .post('/check', {}, {headers: {Authorization: `Bearer ${info}`}})
      .then((response) => {
        let resp = response.data.sucess;
        if (resp) {
          setValue(true);
        } else {
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        localStorage.clear();
        window.location.reload();
      });
  }
}

export async function checkUserLoad(setValue, setLoad) {
  let token = localStorage.getItem('@User_informations') || '';
  if (token) {
    let info = desencrypta(token);
    console.log(info);
    apiBackend
      .post('/check', {}, {headers: {Authorization: `Bearer ${info}`}})
      .then((response) => {
        let resp = response.data.sucess;
        setLoad(true);
        if (resp) {
          setValue(true);
        } else {
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
        localStorage.clear();
        window.location.reload();
      });
  }
}

export async function checkTokenExpiration() {
  let token = localStorage.getItem('@User_informations') || '';
  if (token) {
    let authToken = desencrypta(token);

    return apiBackend.post(
      '/v1/check',
      {},
      {headers: {Authorization: `Bearer ${authToken}`}},
    );
  }
}
