import React, {useState} from 'react';
import * as S from '../styles';

export default function InputTextArea({
  value,
  error,
  touched,
  label,
  handleChange,
  handleBlur,
  placeholder,
  optional,
  maxLength,
}) {
  const [rows, setRows] = React.useState(1);
  const [minRows, setMinRows] = React.useState(1);
  const [maxRows, setMaxRows] = React.useState(15);

  const onChange = (event) => {
    const textareaLineHeight = 24;

    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    handleChange(event);
    setRows(currentRows < maxRows ? currentRows : maxRows);
  };

  return (
    <S.DivInputText>
      <S.Rotule error={error} valid={value}>
        {label} {!optional && '*'}
      </S.Rotule>
      <S.InputTextArea
        error={error}
        touched={touched}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        rows={rows}
      />
      {error && (
        <S.DivError>
          <S.ErrorMessage>{error}</S.ErrorMessage>
        </S.DivError>
      )}
    </S.DivInputText>
  );
}
