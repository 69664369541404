import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import {geocodeSearch} from '~/services/geocode';
import {Container} from './styles';
import * as S from '../styles';

function AddressInput({
  value,
  setValue,
  error,
  touched,
  label,
  placeholder,
  optional,
  setLat,
  setLong,
  setViewLat,
  setViewLong,
  setViewLat2,
  setViewLong2,
  chooseAddress,
  setChooseAddress,
}) {
  function insert(place) {
    setChooseAddress(place);
    geocodeSearch({
      place_id: place?.place_id,
      setLatitude: setLat,
      setLongitude: setLong,
      setViewNorthLat: setViewLat,
      setViewNorthLng: setViewLong,
      setViewSouthLat: setViewLat2,
      setViewSouthLng: setViewLong2,
    });
  }

  function handleBlur(value) {
    if (value !== chooseAddress?.formatted_address) {
      setValue('');
      setViewLat('');
      setViewLong('');
      setViewLat2('');
      setViewLong2('');
      setLat('');
      setLong('');
      setChooseAddress({});
    }
  }

  return (
    <S.DivInputText>
      <S.Rotule error={error} valid={value}>
        {label} {!optional && '*'}
      </S.Rotule>
      <Container error={error} value={value}>
        <Autocomplete
          value={value}
          id="input-auto"
          style={{marginBottom: '20px', fontFamily: 'Lato Regular'}}
          onChange={(event) => setValue(event.target.value)}
          onBlur={(event) => handleBlur(event.target.value)}
          onPlaceSelected={(place) => {
            setValue(place?.formatted_address);
            insert(place);
          }}
          placeholder="Informe o endereço"
          types={['(regions)']}
          className={'input-localization'}
        />
      </Container>
      {error && (
        <S.DivError>
          <S.ErrorMessage>{error}</S.ErrorMessage>
        </S.DivError>
      )}
    </S.DivInputText>
  );
}

export default AddressInput;
