import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/colors';

export const Container = styled.div`
  .item-option {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px;
    cursor: pointer;
    font-family: ${fonts.latoRegularBold};
    font-size: 18px;

    &:hover {
      background: ${colors.green_primary};
    }

    svg {
      margin-right: 10px;
    }
  }
`;

export const Icons = styled.div`
  /*   margin-right: 20px; */
  background: ${(props) =>
    props.isOpen ? colors.green_secondary : colors.white};
  height: 45px;
  width: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.54);
  transition: background 0.2s linear, color 0.2s linear;
  cursor: pointer;
  color: ${(props) => (props.isOpen ? colors.white : colors.green_secondary)};

  &:hover {
    color: ${colors.green_secondary};
    background-color: ${colors.gray_primary};
  }
  &:active {
    color: ${colors.white};
    background-color: ${colors.green_secondary};
  }
`;
