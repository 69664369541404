import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.div`
  max-width: 340px;
  width: 100%;
  padding: 0 20px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: ${fonts.latoRegular};
  box-sizing: border-box;
`;

export const DivTitle = styled.div`
  text-align: left;
  max-width: 300px;
  width: 100%;
  margin-top: 10px;

  > em {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 1.4px;
    font-family: ${fonts.latoItalic};
  }
`;

export const InternDiv = styled.div`
  width: 100%;
  padding: 20px 0px 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const LinkContainer = styled.p`
  font-family: ${fonts.latoRegular};
  font-size: 14px;
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  color: ${colors.dark};
  font-family: ${fonts.latoBlackItalic};
  line-height: 32px;
  /* font-weight: bold; */
`;

export const TextSmall = styled.p`
  /* width: 300px; */
  font-size: 12px;
  margin-top: 50px;
  color: ${colors.dark};

  @media (min-width: 480px) {
    &.terms__text {
      width: max-content;
      min-width: 300px;
    }
  }
`;

export const ButtonLarge = styled.button`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 40px;
  font-family: Lato Black;
  letter-spacing: 1.8px;
  color: ${colors.white};
  box-shadow: 0px 1px 3px #00000066;
  border-radius: 5px;
  border: none;
  &:hover {
    border: none;
  }
  &:focus {
    outline: 0px;
  }
  cursor: pointer;
`;

export const ButtonSecondary = styled(ButtonLarge)`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  font-size: ${fonts.bigs};
  transition: all 0.2s ease;
  &:hover {
    background-color: ${colors.green_third};
  }
  &:focus {
    background-color: ${colors.green_third};
    color: ${colors.green_primary};
  }
  &:disabled {
    background-color: ${colors.gray_secondary};
    color: ${colors.white};
  }
`;

export const Terms = styled.span`
  color: ${colors.green_secondary};
  padding-left: 1px;
  font-family: Lato Black;
  &:hover {
    cursor: pointer;
    color: ${colors.green_third};
  }
`;
