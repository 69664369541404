import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Envolved = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  /* * {
    outline: 1px solid red;
  } */
  min-height: 100vh;

  font-family: ${fonts.latoRegular};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  @media (min-width: 481px) and (max-width: 2560px) {
    width: 500px;
    padding-top: 26px;
  }

  @media (min-width: 1500px) and (max-width: 2560px) {
    width: 500px;
    /* padding-top: 10%; */
  }

  @media (max-width: 480px) {
    width: 375px;
    padding: 30px 20px;
  }
`;

export const Card = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  display: flex;
  width: 100%;
  height: 250px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 24px;
  font-style: italic;
  text-align: center;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 20px;
`;

export const CardChoose = styled.div``;

export const TitleQuestion = styled.p`
  color: #1d1d1b;
  font-size: 18px;
  font-weight: bold;
`;

export const SpanAdvertise = styled.span`
  color: ${colors.green_secondary};
`;

export const SpanGo = styled(SpanAdvertise)``;
