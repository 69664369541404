import styled from 'styled-components';
import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const Container = styled.div`
  padding: 30px;
  position: relative;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
  font-family: ${fonts.latoRegular};
`;
export const LoadingIndicator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    font-family: ${fonts.latoItalic};
    font-size: 14px;
    color: ${colors.dark};
    margin-bottom: 0px;
  }
`;

export const InternDiv = styled.div`
  width: 100%;
  /* padding: 0px 30px 20px 60px; */

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  font-size: 24px;
  color: #1d1d1b;
  font-style: italic;
  font-weight: bold;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  text-align: center;
  position: relative;

  svg {
    position: absolute;
    right: 0;
  }
`;

export const TitlePage = styled.p`
  color: #1d1d1b;
  font-size: 24px;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  text-align: left;
  margin-bottom: 0;
  line-height: 29px;
  padding-right: 30px;
`;

export const Hero = styled.div`
  display: flex;
  align-items: center;

  > p {
    font-size: 14px;
    font-family: ${fonts.latoBold};
    color: ${colors.dark};
    margin-bottom: 0;
  }

  > span {
    margin-right: 15px;
    padding: 5px 10px;
    border: 1px solid ${colors.green_secondary};
    border-radius: 5px;
    width: max-content;
    font-size: 12px;
    font-family: ${fonts.latoBold};
    flex: none;
  }
`;
export const Card = styled.div`
  margin-top: 30px;
  border-radius: 15px;
  border: 1px solid #d9d9ce;
  padding: 20px;

  > header {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    > strong {
      font-family: ${fonts.latoBlackItalic};
      color: ${colors.dark};
      font-size: 18px;
      line-height: 32px;
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    > p {
      margin-bottom: 0;
      font-family: ${fonts.latoRegular};
      color: ${colors.dark};

      &.plan__price {
        font-size: 14px;
        line-height: 17px;
        padding-right: 8px;

        > strong {
          font-family: ${fonts.latoBold};
          font-size: 18px;
          line-height: 32px;
          color: ${colors.green_secondary};

          > span {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      &.plan__quantity {
        font-size: 18px;
        line-height: 22px;
        color: ${colors.dark};

        > strong {
          font-family: ${fonts.latoBlack};
        }
      }
    }
  }
`;
