import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, {useState} from 'react';
// import {useHistory} from 'react-router-dom';
// import deleteImg from '~/assets/delete.svg';
// import dollarImg from '~/assets/dollar-sign.svg';
// import editImg from '~/assets/edit.svg';
// import pay from '~/assets/money-bill.svg';
// import xml from '~/assets/table.svg';
// import playxml from '~/assets/playXML.svg';

import colors from '../../../../UI/colors';
import fonts from '../../../../UI/fonts';
import {Container, Icons} from './styles';
// import ModalDelete from '../../../../components/ModalDelete';
// import apiBackend from '~/services/apiaxios';
// import {desencrypta} from '~/utils';
// import {fireAction} from '~/utils/swal';
// import ModalPayMentLink from '~/components/ModalPaymentLink';
// import ModalPaymentPlan from '~/components/ModalPaymentPlan';
// import ModalPaymentPlanChange from '~/components/ModalPaymentPlanChange';
// import ModalLinkXML from '~/components/ModalLinkXML';
// import ModalExecuteXML from '~/components/ModalExecuteXML';
// import {fireActionMethods} from '~/utils/swalMethods';

const styleItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  fontFamily: fonts.latoRegularBold,
  color: colors.dark,
};

// const svgStyle = {
//   color: colors.green_secondary,
//   marginRight: 10,
//   width: 26,
//   height: 26,
//   flex: 'none',
// };

export default function ManagerOptionsSubscriptions({
  info,
  handleToggleModalAdsList,
  setItemHistoryPaiment,
  setUserHistoryPaiment,
  setModalPaymentHistory,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Container>
        <Icons onClick={handleClick} isOpen={Boolean(anchorEl)}>
          <MoreHorizIcon />
        </Icons>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setOpenCollapse(!openCollapse);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          {/* <ActionsContainer>
            <p
              className="link__action"
              onClick={(e) => handleToggleModalAdsList(info)}>
              Ver anúncio
            </p>
          </ActionsContainer>
          <ActionsContainer>
            <p
              className="link__action"
              onClick={() => {
                setItemHistoryPaiment(info);
                setUserHistoryPaiment(info?.user);
                setModalPaymentHistory(true);
              }}>
              Historico de Pagamento
            </p>
          </ActionsContainer> */}

          <MenuItem
            style={styleItem}
            onClick={(e) => handleToggleModalAdsList(info)}>
            Ver anúncio
          </MenuItem>
          <MenuItem
            style={styleItem}
            onClick={() => {
              setItemHistoryPaiment({subscriber_id: info.id});
              setUserHistoryPaiment(info?.user);
              setModalPaymentHistory(true);
            }}>
            Ver Historico de Pagamento
          </MenuItem>
        </Popover>
      </Container>
    </>
  );
}
