import styled from 'styled-components';
import colors from '../../../../UI/colors';
import fonts from '../../../../UI/fonts';

export const TableContainer = styled.div`
  padding: 0px 20px 0px 20px;
  /* background-color: #ccc; */
  a {
    text-decoration: none;
    padding: 0;
    margin: 0;
  }
`;

export const TitleBoldView = styled.div`
  font-family: ${fonts.latoBlack};
  font-size: 16px;
`;

export const TitleBold = styled(TitleBoldView)`
  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export const P = styled.div`
  font-size: ${(props) => (props.largeText ? '14px' : '16px')};
  font-family: ${fonts.latoRegular};

  &.__bold_text {
    strong {
      font-family: ${fonts.latoBlack};
    }
  }
`;

export const RowPhotoName = styled.span`
  padding-top: 10px;
`;

export const FirstPrice = styled.div`
  color: ${colors.green_secondary};
  font-weight: bold;
`;

export const ViewAd = styled.button`
  display: flex;
  align-items: center;

  padding: 0;
  margin: 0;
  margin-bottom: 10px;

  svg {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }

  background: none;
  color: ${colors.green_secondary};
  font-family: ${fonts.latoBlack};
  font-size: 16px;

  /*  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: #2e6643;
    color: #e6ffef;
    box-shadow: 0px 0px 3px #00000033;
  } */
  &:focus {
    outline: 0;
  }
`;

export const MainPrice = styled.p`
  font-size: 18px;
  font-family: ${fonts.robotoRegularBold};
  color: ${colors.green_secondary};
  display: block;
  margin-bottom: 10px !important;
`;
export const OtherPrice = styled.p`
  font-family: ${fonts.latoRegular};
  font-size: 14px;
  color: ${colors.dark};
  display: block;
  margin-bottom: 10px !important;
`;
