import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import {createMuiTheme} from '@material-ui/core';

import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function PaginationTab({labelSecondary, page, handleChange, count}) {
  const theme = createMuiTheme({
    overrides: {
      MuiPaginationItem: {
        page: {
          color: colors.green_secondary,
          fontFamily: `${fonts.latoRegular}, ${fonts.robotoRegular}`,
          fontWeight: 'normal',

          '&$selected': {
            backgroundColor: colors.green_secondary,
            color: colors.white,

            '&:hover': {
              backgroundColor: colors.gray_primary,
              color: colors.green_third,
            },
          },

          '&:hover': {
            backgroundColor: colors.gray_primary,
            color: colors.green_third,
          },
        },
      },
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Pagination count={count} page={page} onChange={handleChange} />
      </ThemeProvider>
    </div>
  );
}

export default PaginationTab;

PaginationTab.propTypes = {
  labelSecondary: PropTypes.bool,
  page: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};
