import styled from 'styled-components';
import colors from '../../UI/colors';

export const User = styled.div`
  height: 55px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroundUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.open ? 'flex-start' : 'center')};
  width: 225px;
  height: 50px;
  border-radius: 30px;
  border: ${(props) => (props.open ? '2px solid #F2F2ED' : 'none')};
  padding: ${(props) => (props.open ? '5px' : '0px')};
`;

export const ImgUser = styled.img`
  border-radius: 100%;
  margin-left: ${(props) => (props.open ? '0px' : '25px')};
`;

export const GroundNameUser = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;

export const NameUser = styled.div`
  color: ${colors.dark};
  font-size: 14px;
  font-weight: bold;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const Permision = styled.div`
  color: ${colors.dark};
  font-size: 12px;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;

export const DivIcon = styled.div`
  color: ${(props) => (props.selected ? colors.white : colors.green_secondary)};
`;

export const DivItem = styled.div`
  margin-top: 10px;
  background-color: ${(props) =>
    props.selected ? colors.green_secondary : colors.white};
  color: ${(props) => (props.selected ? colors.white : colors.dark)};
  &:hover {
    background-color: ${colors.green_secondary};
    color: ${colors.white};
    ${DivIcon} {
      color: ${colors.white};
    }
  }
`;
