import styled from 'styled-components';
import colors from '../../../../../UI/colors';




export const DivNext = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   width: 100%;
`;

export const DivMetrics = styled.div`
   display: flex;
   flex-direction: row;
`
export const DivInputMetrics = styled.div`
  width: 85%;
   
`

export const DivSelectMetrics = styled.div`
   width: 15%;
   height: 71px;
   border: none;
   border-bottom: ${props => props.value ? '2px solid ' + colors.green_secondary : '#808076 1px solid'};
   display: flex;
   flex-direction: column;
   justify-content: center;
`

export const DivText = styled.div`
   padding-top: 15px;
   text-align: center;
   color: #1D1D1B;
   font-size: 18px;
   font-family: 'Lato';
   font-weight: 500;
`
export const ButtonSave = styled.button`
  width: 100%;
  font-weight: bold;
  height: 50px;
  color: ${colors.white};
  font-size: 18px;
  background-color: ${colors.green_secondary};
  border: none;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0000004D;
  margin-top: 20px;
  &:disabled{
    background-color: ${colors.gray_secondary};
  }
  &:focus{
        outline: 0;    
  }
  &:hover{
    background: #2E6643;
  }
  

`