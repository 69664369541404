import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/semantic-ui.css';
import './styles.css';

function InputPhone({
  placeholder,
  value,
  onChange,
  onBlur,
  locale,
  inputProps,
  error,
  touched,
  ...rest
}) {
  return (
    <PhoneInput
      containerClass={`input-phone-container ${
        value || error ? 'input-phone-bordered' : ''
      } ${error ? 'input-phone-error' : value ? 'input-phone-value' : ''}`}
      inputClass="input-phone"
      buttonClass="input-phone-dropdown-container"
      dropdownClass="input-phone-dropdown-list"
      searchClass="input-phone-search"
      inputProps={inputProps}
      preferredCountries={['br', 'us', 'pt']}
      placeholder={placeholder}
      country={locale}
      value={value}
      onChange={(value, data, event, formattedValue) => {
        onChange(formattedValue);
        console.log(formattedValue);
      }}
      onBlur={(event) => onBlur && onBlur(event.target.value)}
      {...rest}
    />
  );
}

export default InputPhone;
