import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import colors from '../../UI/colors';
import {createMuiTheme} from '@material-ui/core';
import styled from 'styled-components';

const defaultMaterialTheme = createMuiTheme({
  palette: {
    // primary: '#000',
  },
});

export default function MaterialUIPickers(props) {
  // The first commit of Material-UI
  // const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    console.log(date);
    props.setSelectedDate(date);
  };

  return (
    <div style={{witdh: '100%'}}>
      <Label>{props.label} </Label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          value={props.selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

const Label = styled.label`
  color: ${(props) =>
    props.valid ? colors.green_secondary : colors.dark_light};
  font-weight: bold;
  font-size: 14px;
`;
