import apiBackend from '../../services/apiaxios';
import {desencrypta} from '../../utils';
import {clearUser} from './clearUserInfo';

import * as Sentry from '@sentry/react';

export default function signOut() {
  let token = desencrypta(localStorage.getItem('@User_informations'));
  return apiBackend
    .post(
      '/v1/logout',
      {},

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(() => {
      clearUser();
      window.location.href = '/';
    })
    .catch((response) => {
      Sentry.captureException(response);
    });
}
