import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivInputText,
  DivConfirm,
  ButtonGreen,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import baseURL from '../../services/api';

import InputTextArea from '../textArea';
import {useEffect} from 'react';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '620px',
  },
}));

export default function ModalEditJustify({
  id,
  open,
  setOpen,
  justifyInfo,
  funcExec,
  advertiseId,
}) {
  const classes = useStyles();
  const [justify, setJustify] = useState(justifyInfo?.reason);

  useEffect(() => {
    if (justifyInfo) {
      setJustify(justifyInfo?.reason);
    }
  }, [justifyInfo]);

  const handleClose = () => {
    setOpen(false);
  };

  async function handleDisapproveAds(event) {
    funcExec(advertiseId, justify, justifyInfo.id);
    event.preventDefault();
    handleClose();
  }

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle> Atenção</AdvertTitle>
              <Message>
                Você confirma a edição desta<b>JUSTIFICATIVA</b> deste anúncio?
              </Message>
            </DivDanger>
          </ModalResponsive>
          <DivInputText>
            <InputTextArea
              placeHolder={'Escreva aqui sua justificativa'}
              label="Justificativa"
              value={justify}
              setValue={setJustify}
            />
          </DivInputText>
          <DivConfirm>
            <ButtonGreen disabled={!justify} onClick={handleDisapproveAds}>
              ENVIAR
            </ButtonGreen>
          </DivConfirm>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
