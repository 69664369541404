/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dialog} from '@material-ui/core';
import crypto from 'crypto-js';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, {useEffect, useState} from 'react';
import ReactLoading from 'react-loading';
import VMasker from 'vanilla-masker';
import Brasil from '../../../../assets/brasil.svg';
import Us from '../../../../assets/estados-unidos.svg';
import Nothing from '../../../../assets/fechar.svg';
import Portugal from '../../../../assets/portugal.svg';
import colors from '../../../../UI/colors';
import * as validate from '../../../../utils/regex';
import {
  ButtonSave,
  DivError,
  DivInputText,
  ErrorMessage,
  InputText,
  InternDiv,
  ModalContainer,
  Rotule,
  TitleModal,
} from '../../styles';
import '../../styles.css';
import RadioButtons from './RadioButton';
import {ButtonMenu, Coutry, DivPhone} from './styles';
import InputPhone from '~/components/InputPhone';
import './styles.css';

export default function ModalDataPersonal({
  open,
  setOpen,
  handleSubmit,
  loading,
}) {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [userType, setUserType] = useState('Agent');

  /* Agent = Corretor, Owner = Proprietário type_advertise */

  const handleClose = () => {
    setOpen(false);
  };

  function clearModal() {
    setTouched(() => {
      setName('');
      setSurname('');
      setEmail('');
      setPassword('');
      setPhone('');
      setUserType('Owner');
      setErrors({});
      return {};
    });
  }

  /* Clear on keep out */
  useEffect(() => {
    if (!open) {
      clearModal();
    }
  }, [open]);

  const passwordRef = React.useRef(null);

  function handleIsEye(e) {
    e.preventDefault();

    let pass = passwordRef.current;
    pass.type === 'password' ? (pass.type = 'text') : (pass.type = 'password');
    setPasswordVisible(!passwordVisible);
  }

  /* ------------------------------------ */
  /* ----------- VALIDATIONS ------------ */
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* ______ NAME ______ */
  const handleChangeName = (value) => {
    setName(value);

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurName = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        name: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ______ SURNAME ______ */
  const handleChangeSurname = (value) => {
    setSurname(value);

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurSurname = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        surname: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ______ EMAIL ______ */
  const handleChangeEmail = (value) => {
    setEmail(value);

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value.trim())
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  const handleBlurEmail = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        email: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value.trim())
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  /* ______ PHONE ______ */
  const handleChangePhone = (value) => {
    setPhone(value);

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value
          ? 'Campo não preenchido'
          : value?.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  const handleBlurPhone = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        phone: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value
          ? 'Campo não preenchido'
          : value?.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  /* ______ PASSWORD ______ */
  const handleChangePassword = (value) => {
    setPassword(value);

    setErrors((errors) => {
      return {
        ...errors,
        password: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
      };
    });
  };

  const handleBlurPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        password: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        password: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
      };
    });
  };
  /* ------------------------------------ */

  function onSubmit(event) {
    event.preventDefault();
    const key = process.env.REACT_APP_KEY_GENERATE_HASH;
    let hash_password = crypto.AES.encrypt(password.trim(), key).toString();

    const data = {
      name: name.trim(),
      surname: surname.trim(),
      email: email.trim(),
      password: hash_password,
      cellphone: phone.trim(),
      type_advertise: userType,
    };

    handleSubmit(data);
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <ModalContainer>
            <TitleModal>
              <span>Cadastrar Usuário</span>
              <CloseIcon
                onClick={() => handleClose()}
                className={'iconClose'}
              />
            </TitleModal>
            <form
              onSubmit={onSubmit}
              style={{padding: '20px', maxWidth: 800, width: '100%'}}>
              <InternDiv>
                <div>
                  <DivInputText>
                    <Rotule error={errors.name} valid={name}>
                      Nome *
                    </Rotule>
                    <InputText
                      error={errors.name}
                      touched={touched.name}
                      value={name}
                      onChange={(e) => handleChangeName(e.target.value)}
                      onBlur={(e) => handleBlurName(e.target.value)}
                      placeholder="Informe o nome do usuário..."
                    />
                    {errors.name && (
                      <DivError>
                        <ErrorMessage>{errors.name}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.surname} valid={surname}>
                      Sobrenome *
                    </Rotule>
                    <InputText
                      error={errors.surname}
                      touched={touched.surname}
                      value={surname}
                      onChange={(e) => handleChangeSurname(e.target.value)}
                      onBlur={(e) => handleBlurSurname(e.target.value)}
                      placeholder="Informe o sobrenome do usuário..."
                    />
                    {errors.surname && (
                      <DivError>
                        <ErrorMessage>{errors.surname}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule valid={userType !== ''}>
                      Selecione o tipo de usuário *
                    </Rotule>
                    <RadioButtons
                      selectedValue={userType}
                      setSelectedValue={setUserType}
                    />
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.email} valid={email}>
                      Email *
                    </Rotule>
                    <InputText
                      type="email"
                      error={errors.email}
                      touched={touched.email}
                      value={email}
                      onChange={(e) => handleChangeEmail(e.target.value)}
                      onBlur={(e) => handleBlurEmail(e.target.value)}
                      placeholder="Informe o email..."
                    />
                    {errors.email && (
                      <DivError>
                        <ErrorMessage>{errors.email}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.phone} valid={phone}>
                      Telefone *
                    </Rotule>
                    <DivPhone>
                      <InputPhone
                        error={errors.phone}
                        touched={touched.phone}
                        value={phone}
                        onChange={handleChangePhone}
                        onBlur={handleBlurPhone}
                        placeholder="Número com código do país..."
                      />
                    </DivPhone>
                    {errors.phone && (
                      <DivError>
                        <ErrorMessage>{errors.phone}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.password} valid={password}>
                      Senha *
                    </Rotule>
                    <div className="passEyes">
                      <a href="#" onClick={handleIsEye}>
                        {!passwordVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </a>
                      <InputText
                        autocomplete="off"
                        id="pass"
                        type="password"
                        ref={passwordRef}
                        error={errors.password}
                        touched={touched.password}
                        value={password}
                        onChange={(e) => {
                          handleChangePassword(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleBlurPassword(e.target.value);
                        }}
                        placeholder="Informe a senha..."
                      />
                    </div>
                    {errors.password && (
                      <DivError>
                        <ErrorMessage>{errors.password}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>
                </div>
                <ButtonSave
                  type="submit"
                  disabled={
                    !name ||
                    name.trim().length < 3 ||
                    !surname ||
                    surname.trim().length < 3 ||
                    !email ||
                    !validate.emailReg.test(email) ||
                    !password ||
                    password.trim().length < 3 ||
                    !phone ||
                    phone.trim().length < 5 ||
                    !userType ||
                    loading
                  }
                  className={'btnDataPersonal btnload'}>
                  {loading ? (
                    <ReactLoading
                      type={'spin'}
                      className="load"
                      color={colors.green_secondary}
                      width={'30px'}
                    />
                  ) : (
                    'Cadastrar'
                  )}
                </ButtonSave>
              </InternDiv>
            </form>
          </ModalContainer>
        </Dialog>
      </div>
    </>
  );
}

/* ? */
function MenuCountry({
  country,
  setCountry,
  className,
  setCountryImg,
  countryImg,
  valid,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <ButtonMenu valid={valid} onClick={handleClick} className={className}>
        {countryImg}
      </ButtonMenu>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}>
        <MenuItem
          onClick={() => {
            handleClose();
            setCountry('BR');
            setCountryImg(<Coutry src={Brasil} />);
          }}>
          <Coutry src={Brasil} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setCountry('US');
            setCountryImg(<Coutry src={Us} />);
          }}>
          <Coutry src={Us} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setCountry('PT');
            setCountryImg(<Coutry src={Portugal} />);
          }}>
          <Coutry src={Portugal} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setCountry('X');
            setCountryImg(<Coutry src={Nothing} />);
          }}>
          <Coutry src={Nothing} />
        </MenuItem>
      </Menu>
    </div>
  );
}
