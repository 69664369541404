/* ----------------------- */
/* Validations Schemas */
/* ----------------------- */
export const statusSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const fantasyNameSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const companyNameSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const cnpjSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const nameSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const surnameSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const cpfSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const licenseSchema = (value) =>
  value.trim() && value.trim().length < 2 ? 'Mínimo de 3 caracteres' : '';

export const roleSchema = (value) =>
  value.trim() && value.trim().length < 2 ? 'Mínimo de 3 caracteres' : '';

export const emailSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const contactSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const websiteSchema = (value) =>
  value.trim() && value.trim().length < 3 ? 'Mínimo de 3 caracteres' : '';

export const facebookSchema = (value) =>
  value.trim() && value.trim().length < 2 ? 'Mínimo de 3 caracteres' : '';

export const instagramSchema = (value) =>
  value.trim() && value.trim().length < 2 ? 'Mínimo de 3 caracteres' : '';

export const linkedinSchema = (value) =>
  value.trim() && value.trim().length < 2 ? 'Mínimo de 3 caracteres' : '';

export const addressSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const neighborhoodSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const complementSchema = (value) =>
  value.trim() && value.trim().length < 3 ? 'Mínimo de 3 caracteres' : '';

export const cepSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const citySchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const ufSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 2
    ? 'Mínimo de 2 caracteres'
    : '';

export const countrySchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const xmlSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 3
    ? 'Mínimo de 3 caracteres'
    : '';

export const numberSchema = (value) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < 1
    ? 'Mínimo de 1 caractere'
    : '';
