import React from 'react';
import styled from 'styled-components';

import colors from '../UI/colors';
import metrics from '../UI/metrics';
import fonts from '../UI/fonts';

export default function InputText(props) {
  function change(event) {
    if (props.mask) {
      props.mask(event, props.setValue);
    } else {
      props.setValue(event.target.value);
    }
  }

  return (
    <div>
      <Label color={props.value}>{props.label} </Label>
      <Input
        inputMode={props.inputMode}
        disabled={props.disable}
        minLength={props.min ? props.min : null}
        maxLength={props.max ? props.max : null}
        color={props.value}
        value={props.value}
        onChange={(event) => change(event)}
        itens={props.itens}
        placeholder={props.placeHolder}></Input>
    </div>
  );
}

const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) => {
    if (props.noBordered) {
      return 'none!important';
    } else {
      return props.value
        ? `2px solid ${colors.green_secondary}!important`
        : '1px solid #808076 !important';
    }
  }};
  color: ${(props) => (props.value ? '#1D1D1B' : '#808076')};
  width: 100%;

  font-size: 16px;
  font-family: ${(props) =>
    props.value ? fonts.latoRegular : fonts.latoRegularItalic};

  justify-content: space-between;
  justify-items: center;
  align-items: center;

  &:focus {
    outline: 0;
  }
  &:disabled {
  }
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 16px;
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;
