import React, {useState} from 'react';

import {
  TitleModal,
  InternDiv,
  Rotule,
  DivInputText,
  DivRadioGroup,
  InputText,
  DivError,
  ErrorMessage,
  ButtonSave,
} from '../ModalDataPersonal/styles';
import InputPhone from '~/components/InputPhone';
import RadioButtonLang from '../../components/RadioButtonLang';

import CloseIcon from '@material-ui/icons/Close';
import '../styles.css';

import ReactLoading from 'react-loading';
import './styles.css';
import {Dialog} from '@material-ui/core';
import colors from '../../../../../UI/colors';

import * as validate from '~/utils/regex';

export default function ModalContact({
  open,
  setOpen,
  profile,
  loading,
  handleSubmit,
}) {
  const [langPref, setLangPref] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [residential, setResidential] = useState('');
  const [comercial, setComercial] = useState('');

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  React.useEffect(() => {
    setEmail(profile?.email ? profile?.email : '');
    setPhone(profile?.cellphone ? profile.cellphone : '');
    setWhatsapp(profile?.profile?.whatsapp ? profile?.profile?.whatsapp : '');
    setResidential(
      profile?.profile?.home_phone ? profile?.profile?.home_phone : '',
    );
    setComercial(
      profile?.profile?.commercial_phone
        ? profile?.profile?.commercial_phone
        : '',
    );
    setLangPref(profile?.lang ? profile?.lang : 'en');
  }, [profile]);

  const resetForm = () => {
    setEmail(profile?.email ? profile?.email : '');
    setPhone(profile?.cellphone ? profile.cellphone : '');
    setWhatsapp(profile?.profile?.whatsapp ? profile?.profile?.whatsapp : '');
    setResidential(
      profile?.profile?.home_phone ? profile?.profile?.home_phone : '',
    );
    setComercial(
      profile?.profile?.commercial_phone
        ? profile?.profile?.commercial_phone
        : '',
    );
    setLangPref(profile?.lang ? profile?.lang : 'en');
    setErrors({});
    setTouched({});
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  /* ------------------------------ */
  /* Email */
  const handleChangeEmail = (value) => {
    setEmail(value);

    setErrors((errors) => {
      return {
        ...errors,
        email:
          value.trim() && !validate.emailReg.test(value)
            ? 'E-mail inválido'
            : '',
      };
    });
  };

  const handleBlurEmail = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        email: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        email:
          value.trim() && !validate.emailReg.test(value)
            ? 'E-mail inválido'
            : '',
      };
    });
  };

  /* ------------------------------ */
  /* Phone */
  const handleChangePhone = (value) => {
    setPhone(value);

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  const handleBlurPhone = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        phone: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  /* ------------------------------ */
  /* Whatsapp */
  const handleChangeWhatsapp = (value) => {
    setWhatsapp(value);

    setErrors((errors) => {
      return {
        ...errors,
        whatsapp:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };

  const handleBlurWhatsapp = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        whatsapp: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        whatsapp:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };

  /* ------------------------------ */
  /* Residential */
  const handleChangeResidential = (value) => {
    setResidential(value);

    setErrors((errors) => {
      return {
        ...errors,
        residential:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };

  const handleBlurResidential = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        residential: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        residential:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };
  /* ------------------------------ */
  /* Comercial */
  const handleChangeComercial = (value) => {
    setComercial(value);

    setErrors((errors) => {
      return {
        ...errors,
        comercial:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };

  const handleBlurComercial = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        comercial: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        comercial:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };
  /* ------------------ */
  /* ------------------ */
  /* ------------------ */
  function editContact(event) {
    event.preventDefault();

    const userUpdated = {
      cellphone: phone.trim(),
      lang: langPref,
      profile: {
        whatsapp: whatsapp.trim(),
        commercial_phone: comercial.trim(),
        home_phone: residential.trim(),
      },
    };

    handleSubmit(userUpdated);
  }

  return (
    <Dialog
      scroll="body"
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <TitleModal>
        <span>Contato</span>
        <CloseIcon onClick={handleClose} className={'iconClose'} />
      </TitleModal>
      <form onSubmit={editContact} style={{padding: '20px'}}>
        <InternDiv>
          <div>
            <DivInputText>
              <Rotule error={errors.email} valid={email}>
                E-mail *
              </Rotule>
              <InputText
                error={errors.email}
                touched={touched.email}
                value={email}
                onChange={(event) => handleChangeEmail(event.target.value)}
                onBlur={(event) => handleBlurEmail(event.target.value)}
                placeholder="Qual o seu email?"
              />
              {errors.email && (
                <DivError>
                  <ErrorMessage>{errors.email}</ErrorMessage>
                </DivError>
              )}
            </DivInputText>

            <DivInputText className="divMargin">
              <Rotule error={errors.phone} valid={phone}>
                Celular *
              </Rotule>
              <InputPhone
                error={errors.phone}
                touched={touched.phone}
                value={phone}
                onChange={handleChangePhone}
                onBlur={handleBlurPhone}
                placeholder="Número do celular (com código do país)..."
              />
              {errors.phone && (
                <DivError>
                  <ErrorMessage>{errors.phone}</ErrorMessage>
                </DivError>
              )}
            </DivInputText>

            <DivInputText className="divMargin">
              <Rotule error={errors.whatsapp} valid={whatsapp}>
                Whatsapp
              </Rotule>
              <InputPhone
                error={errors.whatsapp}
                touched={touched.whatsapp}
                value={whatsapp}
                onChange={handleChangeWhatsapp}
                onBlur={handleBlurWhatsapp}
                placeholder="Número do whatsapp (com código do país)..."
              />
              {errors.whatsapp && (
                <DivError>
                  <ErrorMessage>{errors.whatsapp}</ErrorMessage>
                </DivError>
              )}
            </DivInputText>

            <DivInputText className="divMargin">
              <Rotule error={errors.residential} valid={residential}>
                Telefone Res.
              </Rotule>
              <InputPhone
                error={errors.residential}
                touched={touched.residential}
                value={residential}
                onChange={handleChangeResidential}
                onBlur={handleBlurResidential}
                placeholder="Número do telefone residencial (com código do país)..."
              />
              {errors.residential && (
                <DivError>
                  <ErrorMessage>{errors.residential}</ErrorMessage>
                </DivError>
              )}
            </DivInputText>

            <DivInputText className="divMargin">
              <Rotule error={errors.comercial} valid={comercial}>
                Telefone Com.
              </Rotule>
              <InputPhone
                error={errors.comercial}
                touched={touched.comercial}
                value={comercial}
                onChange={handleChangeComercial}
                onBlur={handleBlurComercial}
                placeholder="Número do telefone comercial (com código do país)..."
              />
              {errors.comercial && (
                <DivError>
                  <ErrorMessage>{errors.comercial}</ErrorMessage>
                </DivError>
              )}
            </DivInputText>

            <DivRadioGroup className="divMargin">
              <Rotule error={errors.lang} valid={langPref}>
                Idioma preferencial para contato
              </Rotule>
              <RadioButtonLang
                selectedValue={langPref}
                setSelectedValue={setLangPref}
              />
            </DivRadioGroup>
          </div>
          <ButtonSave
            type="submit"
            disabled={
              !email.trim() ||
              !validate.emailReg.test(email) ||
              !phone.trim() ||
              (phone.trim() && phone.trim().length < 5) ||
              errors.whatsapp ||
              errors.residential ||
              errors.comercial ||
              loading
            }
            className={'btnDataContact  btnload'}>
            {loading ? (
              <ReactLoading
                type="spin"
                color={colors.green_secondary}
                width="30px"
                height="30px"
              />
            ) : (
              'Editar'
            )}
          </ButtonSave>
        </InternDiv>
      </form>
    </Dialog>
  );
}

/* 

useEffect(() => {
    if (email.length > 0 && phone.length > 0) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [email, phone]);

  function loadData() {
    setId(profile.id);
    if (profile.email) {
      setEmail(profile.email);
    }

    if (profile.cellphone) {
      setPhone(profile.cellphone);
    }
    if (profile.whatsapp) {
      setWhatsapp(profile.whatsapp);
    }
    if (profile.commercial_phone) {
      setComercial(profile.commercial_phone);
    }
    if (profile.home_fone) {
      setResidential(profile.home_fone);
    }

    if (profile.user_type === 'Anunciante') {
      setSimbol('*');
    }

    setLoading(true);
  }

  function verificationClass() {
    validPhone();
    validEmail();
  }

  function validPhone() {
    if (phone.length === 0) {
      setPhoneErrorClass('redEmail');
      setPhoneErrorClassInput('red');
      setMessage('Campo obrigatório!');
      setManPhone(true);
    } else {
      setPhoneErrorClass('greenEmail');
      setPhoneErrorClassInput('green');
      setManPhone(false);
    }
  }

  function validEmail() {
    if (email.length === 0) {
      setEmailErrorClass('redEmail');
      setEmailErrorClassInput('red');
      setMessage('Campo obrigatório!');
      setManEmail(true);
    } else {
      setPhoneErrorClass('greenEmail');
      setPhoneErrorClassInput('green');
      setManEmail(false);
    }
  }
 */
