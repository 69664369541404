export default function transformNameProfile(number) {
  const type = number?.toString();

  switch (type) {
    case '5':
      return 'Administrador';
    case '4':
      return 'Gestor';
    case '3':
      return 'Aprovador';
    case '2':
      return 'Vendedor';
    case '1':
      return 'Comum';
    default:
      return 'Não definido';
  }
}
