import {Player} from '@lottiefiles/react-lottie-player';
import {Dialog} from '@material-ui/core';
import React from 'react';
import {ModalResponsive} from '../../Components/styles';
import {InternDiv} from './styles';
import './styles.css';

export default function OpenAnimation({open, setOpen, src, speed}) {
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Dialog scroll="body" aria-labelledby="simple-dialog-title" open={open}>
          <ModalResponsive>
            <InternDiv>
              {' '}
              <Player
                speed={3}
                autoplay
                loop
                src={src}
                style={{height: '300px', width: '300px'}}></Player>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
    </>
  );
}

OpenAnimation.defaultProps = {
  speed: 1,
};
