import React, {useEffect, useState} from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  move,
  swap,
} from 'react-grid-dnd';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import Trash from '../../../../../../assets/delete.svg';

import OpenAnimation from '../Loading';
// import ModalCropperEdit from '../ModalCropperEdit';
import {
  Container,
  ContainerDesktop,
  DeletePhotoMobile,
  DivIcon,
  DivTrash,
  GridPhotosMobile,
  Image,
  ImageIcon,
  ItemPhotoMobile,
  Subtitle,
  SubtitleMobile,
  TitleHave,
} from './styles';
import './styles.css';

import * as Sentry from '@sentry/react';

function RenderImages({
  files,
  setFiles,
  id,
  fileSend,
  setFileSend,
  activeSkeleton,
}) {
  return (
    <>
      <Container>
        {files.length > 0 ? (
          <>
            {' '}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <TitleHave>
                <I18n t="Photos.titleOcult" />
              </TitleHave>
              <Count unit={files.length} />
            </div>
            <Subtitle>
              <I18n t="Photos.subtitle" />
            </Subtitle>{' '}
            <SubtitleMobile>
              <I18n t="Photos.subtitle2" />
              {/* <I18n t="Photos.subtitle" /> */}
            </SubtitleMobile>
          </>
        ) : null}
        {files.length > 0 ? (
          <Example
            id={id}
            files={files}
            setFiles={setFiles}
            setFileSend={setFileSend}
            fileSend={fileSend}
            activeSkeleton={activeSkeleton}
          />
        ) : null}
      </Container>
      {/* {openCropperEdit ? (
        <ModalCropperEdit
          keyPhoto={keyPhoto}
          open={openCropperEdit}
          setOpen={setCropperEdit}
          file={files[keyPhoto]}
          filesExibition={files}
          setFilesExibition={setFiles}
        />
      ) : null} */}
    </>
  );
}

export default RenderImages;

function Count({unit}) {
  return (
    <span className="lato">
      {unit}
      /20
    </span>
  );
}

function Example({files, setFiles, id, setFileSend, fileSend, activeSkeleton}) {
  const [items, setItems] = React.useState({
    left: files,
  });
  const [size, setSize] = useState('0');
  const [keyPhoto, setKeyPhoto] = useState('');
  const [openCropperEdit, setCropperEdit] = useState(false);
  const [openAnimation, setOpenAnimation] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      if (files.length % 2 !== 0) {
        const value = (files.length + 1) / 2;
        setSize(value * 240);
      } else {
        const value = files.length / 2;
        setSize(value * 240);
      }
    }
  }, [files]);

  useEffect(() => {
    setItems({
      left: files,
    });
  }, [files]);

  useEffect(() => {
    setFiles(items.left);
  }, [items]);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    let result = '';
    if (targetId) {
      const result = move(items[sourceId], sourceIndex, targetIndex);
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }

    if (items[sourceId] !== undefined) {
      result = swap(items[sourceId], sourceIndex, targetIndex);
    }

    return setItems({
      ...items,
      [sourceId]: result,
    });
  }

  async function getImagesReq() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/v1/property/${id}/photos`,
        {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.status === 200 || response.status === 201) {
        response.json().then((data) => {
          setFiles(data.photos);
        });
      } else {
        console.log(response);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  function deleteVector(idImage, item) {
    let fileNew = [...files];
    let fileSendNew = [...fileSend];
    for (let i = 0; i < fileSendNew.length; i++) {
      if (fileSendNew[i] === item) {
        fileSendNew.splice(i, 1);
        break;
      }
    }
    fileNew.splice(idImage, 1);
    setFiles(fileNew);
    setOpenAnimation(false);
  }

  function deleteReq(idImage, item) {
    console.log(item);
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .delete(`/v1/property/${id}/photos/${item.id}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        let fileNew = [...files];
        fileNew.splice(idImage, 1);
        setFiles(fileNew);
        setOpenAnimation(false);
      })
      .catch((e) => console.log(e));
  }

  async function deleteImage(imageId, item) {
    setOpenAnimation(true);
    if (item.image_url) deleteReq(imageId, item);
    else {
      deleteVector(imageId, item);
      console.log('deleta do vetor');
    }
    if (selectFirst !== null) {
      setSelectFirst(null);
    }
    if (selectSecond !== null) {
      setSelectSecond(null);
    }
  }

  /* --------------------------------- */
  /* -------------------- RenderMobile */
  /* --------------------------------- */

  const [selectFirst, setSelectFirst] = useState(null);
  const [selectSecond, setSelectSecond] = useState(null);

  function handleSelectPhoto(index) {
    console.log(index);

    if (index === selectFirst) {
      return setSelectFirst(null);
    }
    if (index === selectSecond) {
      return setSelectSecond(null);
    }
    /* ----- */
    if (selectFirst === null) {
      setSelectFirst(index);
    } else if (selectSecond === null) {
      setSelectSecond(index);
    }
  }

  useEffect(() => {
    if (items?.left?.length && selectFirst !== null && selectSecond !== null) {
      const item1 = items?.left[selectFirst];
      const item2 = items?.left[selectSecond];

      var array = items?.left;

      array[selectFirst] = item2;
      array[selectSecond] = item1;

      /* erased mark */
      setSelectFirst(null);
      setSelectSecond(null);
    }
  }, [items, selectFirst, selectSecond]);

  return (
    <>
      <ContainerDesktop id="grid-image-desktop">
        <GridContextProvider onChange={onChange}>
          <div className="container">
            <GridDropZone
              className="dropzone left"
              id="left"
              boxesPerRow={2}
              rowHeight={240}
              style={{height: size}}>
              {/* {activeSkeleton ? (
              <Skeleton variant="rect" width={210} height={118} />
            ) : null} */}
              {items.left.map((item, key) => (
                <GridItem
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px',
                  }}>
                  <div>
                    <Image
                      src={
                        item.image_url
                          ? item.image_url
                          : URL.createObjectURL(item)
                      }
                    />
                    {/* <Image src={URL.createObjectURL(item) || item.image_url} /> */}
                    <DivTrash>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <DivIcon onClick={() => deleteImage(key, item)}>
                          <ImageIcon src={Trash} key="trash" />
                        </DivIcon>
                      </div>
                    </DivTrash>
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </div>
        </GridContextProvider>
      </ContainerDesktop>

      {/* MOBILE */}
      <GridPhotosMobile>
        {items?.left?.map((photo, index) => (
          <ItemPhotoMobile
            className={`${
              index === selectFirst || index === selectSecond
                ? 'selected'
                : 'unselected'
            }`}
            key={`mobile-pic-${index}`}>
            <DeletePhotoMobile>
              <div className="delete" onClick={() => deleteImage(index, photo)}>
                <img src={Trash} alt="Trash" />
              </div>
            </DeletePhotoMobile>

            <img
              onClick={() => handleSelectPhoto(index)}
              src={
                photo?.image_url ? photo?.image_url : URL.createObjectURL(photo)
              }
              alt="Thumbnail"
            />
          </ItemPhotoMobile>
        ))}
      </GridPhotosMobile>

      {/* {openCropperEdit ? (
        <ModalCropperEdit
          id={id}
          getImagesReq={getImagesReq}
          keyPhoto={keyPhoto}
          open={openCropperEdit}
          setOpen={setCropperEdit}
          file={files[keyPhoto]}
          filesExibition={files}
          setFilesExibition={setFiles}
          fileSend={fileSend}
          setFileSend={setFileSend}
        />
      ) : null} */}
      <OpenAnimation
        open={openAnimation}
        speed={3}
        src={'https://assets4.lottiefiles.com/packages/lf20_WUYL7A.json'}
        setOpen={setOpenAnimation}
      />
    </>
  );
}
