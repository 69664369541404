import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import likedImg from '~/assets/thumbs-up.svg';
import dislikedImg from '~/assets/thumbs-down.svg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useHistory} from 'react-router-dom';
import ManagerOptions from '../../../ManagerOptions';
import * as Sentry from '@sentry/react';

import {
  ContainerBoxProposal,
  ButtonGreenGo,
  Price,
  PriceAlternative,
  Liked,
  Disliked,
  ActionsContainer,
  ActionsContainerMobile,
  ContainerBoxMobile,
  TitleMinor,
  MenuContainer,
  MenuContainerMobile,
  ViewJustify,
} from './styles';
import I18n from '../../../../../i18n/i18';

import ModalApproveAds from '~/components/ModalApproveAds';
import ModalDisapproveAds from '~/components/ModalDisapproveAds';
import ModalAttProposal from '../ModalAttProposal';

import {getRoute} from '../../utils';
import './styles.css';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import {fireAction} from '~/utils/swal';
// import { Container } from './styles';

const permitted_roles = ['5', '3'];
function BoxProposal({
  prices,
  id,
  advertise,
  typeUser,
  langName,
  getAllPropertiesPrice,
  reloadAds,
}) {
  const userStore = useSelector((state) => state.user);
  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /* ---- APPROVE AND DISAPPROVE ---- */
  const [modalApprove, setModalApprove] = useState(false);
  const [modalDisapprove, setModalDisapprove] = useState(false);
  const history = useHistory();
  const [openModalProposal, setOpenModalProposal] = useState(false);
  const [loading, setLoading] = useState(false);

  function approveAds(close) {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    console.log(id);
    setLoading(true);

    apiBackend
      .put(
        `v1/property/${id}/announced`,
        {
          announced: true,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        setLoading(false);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });

    apiBackend
      .put(
        `/v1/property/${id}/approved_refused`,
        {
          status: 'approved',
          reason: '',
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        setLoading(false);
        /* reloadAds(); */
        close();
        history.goBack();
        fireAction(
          'Anúncio Aprovado',
          'Este anúncio foi aprovado com sucesso',
          'success',
        );
      })
      .catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
        fireAction('Error', 'Erro interno, tente novamente', 'error');
      });
  }

  function refuseAds(reason, close) {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    console.log(id);
    setLoading(true);
    apiBackend
      .put(
        `/v1/property/${id}/approved_refused`,
        {
          status: 'refused',
          reason: reason,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        setLoading(false);
        console.log('reason', response);
        /* reloadAds(); */
        close();
        history.goBack();

        fireAction(
          'Anúncio Reprovado',
          'Este anúncio foi reprovado!',
          'success',
        );
      })
      .catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
        fireAction('Error', 'Erro interno, tente novamente', 'error');
      });
  }

  function returnList() {
    history.push('/announcement/approved');
  }

  return (
    <>
      <div className="sty">
        <div style={{height: '30px'}}></div>
        <ContainerBoxProposal>
          <div>
            <Price>
              {' '}
              {prices !== 'Não Definido' ? (
                prices[0]
              ) : (
                <I18n t="ViewAds.Undefined" />
              )}{' '}
            </Price>{' '}
            <div className="rows mg-top-10 ">
              <span className="mg-right-20">
                <PriceAlternative>
                  {' '}
                  {prices !== 'Não Definido' ? (
                    prices[1]
                  ) : (
                    <I18n t="ViewAds.Undefined" />
                  )}{' '}
                </PriceAlternative>{' '}
              </span>{' '}
              <span>
                <PriceAlternative>
                  {' '}
                  {prices !== 'Não Definido' ? (
                    prices[2]
                  ) : (
                    <I18n t="ViewAds.Undefined" />
                  )}{' '}
                </PriceAlternative>{' '}
              </span>{' '}
            </div>{' '}
          </div>
          {/* Se está pendente >>> */}
          {isPermitted() && (
            <ActionsContainer>
              {advertise.status !== 'approved' && (
                <Liked onClick={(e) => setModalApprove(true)}>
                  <img src={likedImg} alt="Aprovar" />
                  Aprovar {/* <I18n t={'ModalProposal.Liked'} /> */}
                </Liked>
              )}

              {advertise.status !== 'refused' && (
                <Disliked onClick={(e) => setModalDisapprove(true)}>
                  <img src={dislikedImg} alt="Desaprovar" />
                  Desaprovar {/* <I18n t={'ModalProposal.Disliked'} /> */}
                </Disliked>
              )}
            </ActionsContainer>
          )}
          <MenuContainer>
            {isPermitted() && typeUser?.toLowerCase() !== 'broker' && (
              <ManagerOptions
                info={advertise}
                getAllProperties={returnList}
                getAllPropertiesPrice={getAllPropertiesPrice}
              />
            )}
            {/* Se já foi aprovado */}
            <ViewJustify
              onClick={() =>
                history.push({
                  pathname: '/view/justify',
                  state: {
                    advertise: advertise,
                    prices: prices,
                  },
                })
              }>
              Ver justificativa <ChevronRightIcon />
            </ViewJustify>
          </MenuContainer>
        </ContainerBoxProposal>
      </div>
      <ContainerBoxMobile>
        {isPermitted() && (
          <ActionsContainerMobile>
            {advertise.status !== 'approved' && (
              <Liked onClick={(e) => setModalApprove(true)}>
                <img src={likedImg} alt="Aprovar" />
                Aprovar {/* <I18n t={'ModalProposal.Liked'} /> */}
              </Liked>
            )}

            {advertise.status !== 'refused' && (
              <Disliked onClick={(e) => setModalDisapprove(true)}>
                <img src={dislikedImg} alt="Desaprovar" />
                Desaprovar {/* <I18n t={'ModalProposal.Disliked'} /> */}
              </Disliked>
            )}
          </ActionsContainerMobile>
        )}
        <MenuContainerMobile>
          {isPermitted() && (
            <ManagerOptions
              info={advertise}
              getAllProperties={returnList}
              getAllPropertiesPrice={getAllPropertiesPrice}
            />
          )}
          {/* Se já foi aprovado */}
          <ViewJustify
            onClick={() =>
              history.push({
                pathname: '/view/justify',
                state: {
                  advertise: advertise,
                  prices: prices,
                },
              })
            }>
            Ver justificativa <ChevronRightIcon />
          </ViewJustify>
        </MenuContainerMobile>
      </ContainerBoxMobile>

      {/* ------------ */}
      {isPermitted() && (
        <>
          <ModalAttProposal
            open={openModalProposal}
            setOpen={setOpenModalProposal}
          />
          <ModalApproveAds
            open={modalApprove}
            setOpen={setModalApprove}
            funcExec={approveAds}
            loading={loading}
          />
          <ModalDisapproveAds
            open={modalDisapprove}
            setOpen={setModalDisapprove}
            funcExec={refuseAds}
            loading={loading}
          />
        </>
      )}
    </>
  );
}

export default BoxProposal;
