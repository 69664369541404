import React, {Component} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Resizer from 'react-image-file-resizer';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {ModalResponsive} from '../../Components/styles';
import {InternDiv, TitleModal, DivAlterImage} from './styles';
import '../RenderImages/styles.css';
import {
  DivPreview,
  ButtonWhite,
  ButtonGreen,
  ImagePreview,
} from '../ModalCropper/styles';
import imageCompression from 'browser-image-compression';
import {desencrypta} from '../../../../../../utils';
import baseURL from '../../../../../../services/api';
import * as Sentry from '@sentry/react';

export default function ModalCropperEdit({
  id,
  keyPhoto,
  open,
  setOpen,
  file,
  filesExibition,
  setFilesExibition,
  getImagesReq,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span>Preview</span>
            <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
          </TitleModal>
          <ModalResponsive>
            <InternDiv>
              <div>
                <CropperImg
                  keyPhoto={keyPhoto}
                  getImagesReq={getImagesReq}
                  id={id}
                  setOpen={setOpen}
                  filesExibition={filesExibition}
                  setFilesExibition={setFilesExibition}
                  file={file}
                />
              </div>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
    </>
  );
}

class CropperImg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgAux: '',
      imgCropper: this.props.file,
      file: this.props.file,
      buttonCropper: false,
    };
  }

  rotation(url) {
    if (typeof url == 'string') {
      fetch(url)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          console.log(blob);
          Resizer.imageFileResizer(
            blob,
            500,
            500,
            'JPEG',
            100,
            90,
            (uri) => {
              let finalFile = new File([uri], 'photo.jpg', {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              this.setState({imgCropper: finalFile});
            },
            'blob',
            200,
            200,
          );
        });
    } else {
      Resizer.imageFileResizer(
        url,
        500,
        500,
        'JPEG',
        100,
        90,
        (uri) => {
          let finalFile = new File([uri], 'photo.jpg', {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          this.setState({imgCropper: finalFile});
        },
        'blob',
        200,
        200,
      );
    }
  }

  async updateImage(idImage, formData) {
    try {
      let response = await fetch(
        `${baseURL}/api/v1/properties/${this.props.id}/photos/${idImage}`,
        {
          method: 'PUT', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

          headers: {
            Authorization: desencrypta(
              localStorage.getItem('@User_informations'),
            ),
          },
          body: formData,
        },
      );

      if (response.status === 200 || response.status === 201) {
        await response.json().then((data) => {
          let array = [...this.props.filesExibition];
          array[this.props.keyPhoto] = data;
          this.props.setFilesExibition(array);
          // this.props.filesExibition()
          this.props.setOpen(false);
        });
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e.json());
      Sentry.captureException(e);
    }
  }

  edit(event) {
    event.preventDefault();
    this.fileSelectedHandler(this.state.imgCropper);
  }

  async fileSelectedHandler(file) {
    let fileImg = await this.compressionImage(file);
    if (fileImg === undefined) {
    } else {
      let array = [...this.props.filesExibition];
      const data = new FormData();
      data.append('photo[ordination]', array[this.props.keyPhoto].ordination);
      data.append('photo[image]', fileImg);
      this.updateImage(array[this.props.keyPhoto].id, data);
    }
  }

  async compressionImage(event) {
    const imageFile = event;
    const options = {
      maxSizeMB: 0.15,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(compressedFile);
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`,
      );
      var file = new File([compressedFile], 'photo.jpg', {
        type: 'image/jpeg',
        lastModified: Date.now(),
      }); // file = URL.createObjectURL(compressedFile);
      return file;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  onChange = async (event) => {
    event.preventDefault();
    this.rotation(this.state.imgCropper.image_url || this.state.imgCropper);
  };

  render() {
    return (
      <>
        <DivPreview>
          <ImagePreview
            src={
              this.state.imgCropper.image_url
                ? this.state.imgCropper.image_url
                : URL.createObjectURL(this.state.imgCropper)
            }
          />
        </DivPreview>

        <DivAlterImage>
          <ButtonWhite onClick={(event) => this.onChange(event)}>
            Girar
          </ButtonWhite>
          <ButtonGreen onClick={(event) => this.edit(event)}>
            Salvar
          </ButtonGreen>
        </DivAlterImage>
      </>
    );
  }
}
