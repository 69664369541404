import styled from 'styled-components';
import metrics from '../../../UI/metrics';
import colors from '../../../UI/colors';

export const Card = styled.div`
  /* height: 120px; */
  box-shadow: 0px 3px 10px #00000033;
  border-radius: 10px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  font-family: 'Lato Regular';

  .titlePlan {
    font-family: 'Roboto Black';
    color: ${colors.green_secondary};
    font-size: 18px;
  }

  .titleBlack {
    font-family: 'Lato Regular';
    color: ${colors.dark};
    font-size: 16px;
  }

  .createdAtDate {
    color: ${colors.dark};
    font-size: 14px;
    font-family: 'Lato Regular';
  }

  .textTwo {
    font-size: 16px;
    font-family: 'Lato Regular';
    margin-right: 30px;
    color: #1d1d1b;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  padding-right: 10px;

  @media (max-width: 461px) {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : 0)};

  .greenNoBorder {
    font-family: 'Lato Regular Bold';
    color: ${colors.green_secondary};
  }

  @media (max-width: 970px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
  }

  @media (max-width: 461px) {
    .description {
      display: flex;
      flex-direction: column;

      > span {
        margin-right: 0;
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  /*  .share {
    cursor: ${(props) => (props.actived ? 'not-allowed' : 'pointer')};
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-left: 50px;
    margin-right: 50px;
    color: ${(props) => (props.actived ? '#BFBFB2' : colors.green_secondary)};
  } */

  .__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > svg {
      cursor: pointer;
    }
  }

  .__item {
    margin-right: 50px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 970px) {
    > div {
      margin: 10px 0;
    }
    justify-content: flex-start;
  }

  @media (max-width: 500px) {
    margin: 10px 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .__item {
      margin-right: 0px;
    }
    /*    .share {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
    } */
  }
`;

export const IconsSets = styled.span`
  background: transparent;
  /* height: 45px;
    width: 45px; */
  /* align-items: center;
    display: flex;
    justify-content: center; */
  border-radius: 100px;
  &:hover {
    color: ${colors.green_secondary};
    background-color: ${colors.gray_secondary};
  }
  &:active {
    color: ${colors.white};
    background-color: ${colors.green_secondary};
  }
`;
