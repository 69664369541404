import styled from 'styled-components';

export const Container = styled.div`
   width: 400px;
   height: 640px;
  
   @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: 'Lato';
`;

export const InternDiv = styled.div`
  height: 90%;
  /* padding: 20px 30px 30px 30px; */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  padding: 30px 30px 20px 50px ;
  font-size: 24px;
  color: #1D1D1B;
  font-style: italic;
  font-weight: bold;
  font-family: 'Lato';
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`


export const DivInputText = styled.div`
 width: 100%;
 margin-bottom: 20px;
`

export const EnvolvedSearchUser = styled.div`
    margin-bottom: 150px;
    margin-top: 120px; 
  .react-autosuggest__input {
    box-shadow: 0px 3px 6px #00000033;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    height: 50px;
    color: #1D1D1B;
    font-size: 18px;
    width: 100%;
    border-radius: 5px;
    font-family: 'Lato';
   
    padding-left: 20px;
  }

 .react-autosuggest__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #1D1D1B;
    font-style: italic;
  }

  .react-autosuggest__input:focus {
    outline: 0;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__container {
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: relative;
    justify-items: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    z-index: 2;
  }
  .react-autosuggest__suggestions-list {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    overflow: auto;
    width: 100%;
    padding-right: 4px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #00000029;
    border: 1px solid #ffffff;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  .react-autosuggest__suggestion:last-child {
  }
  .react-autosuggest__suggestion:first-child {
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #c5e6c3;
    color: #44993d;
  }
`
export const ModalResponsive = styled.div`
  padding: 0px 50px;
  @media (min-width: 481px) and (max-width: 700px) {
         width: 100%;
  }
  @media (min-width: 800px) and (max-width: 2560px) {
     width: 100%;
        
  }
  @media(max-width: 480px) {
        width: 100%;
  }
`