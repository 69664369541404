import styled from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const ContainerTable = styled.div`
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .link__action {
    text-decoration: none;
    cursor: pointer;
    color: ${colors.green_secondary};
    font-family: ${fonts.latoBlack};
    font-size: 14px;
    margin-bottom: 0;
  }

  .link__action.empty {
    color: ${colors.gray_secondary};
    cursor: not-allowed;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PlanName = styled.strong`
  font-family: ${fonts.latoBlack};
  color: ${({ freeDays }) => freeDays ? '#ffa726' : colors.green_secondary};
  cursor: pointer;
`;
export const TableContainer = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;

  @media (max-width: 960px) {
    .tableMobile {
      display: none !important;
    }
  }

  .item__list {
    border-top: 2px solid #bfbfb2;
    display: flex;
    align-items: center;
    padding: 0 0 10px;

    @media (max-width: 587px) {
      padding-bottom: 20px;
      padding-top: 10px;
    }
  }
`;

export const TitleBoldView = styled.div`
  font-weight: bold;
  font-family: ${fonts.latoRegularBold};
`;

export const TitleBold = styled(TitleBoldView)`
  @media only screen and (max-width: 959px) {
    display: block;
  }
  @media only screen and (min-width: 960px) {
    display: none;
  }
`;

export const P = styled.div`
  font-family: ${fonts.latoRegular};
`;

export const RowPhotoName = styled.span`
  /*  padding-top: 10px; */
  font-family: ${fonts.latoRegular};
`;

export const FirstPrice = styled.div`
  color: ${colors.green_secondary};
  font-weight: bold;
`;

export const RowClick = styled.div`
  display: flex;
  flex-direction: row;
  height: 15px;
  cursor: pointer;
  align-items: center;
  &:hover {
    color: ${colors.dark_light};
  }
`;

export const Results = styled.p`
  margin-left: 14px;
  font-size: 14px;
  font-family: ${fonts.latoRegular};
`;
