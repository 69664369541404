import styled from 'styled-components';
import {ButtonLarge} from '../../UI/styles';

import colors from '../../UI/colors';
import fonts from '../../UI/fonts';
import metrics from '../../UI/metrics';

export const DivLogin = styled.div`
  width: 420px;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  align-items: center;
`;

export const InputField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 29px;
  border: none;
  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};
  color: ${colors.dark};
  font-size: 16px;
  padding-bottom: 10px;
  padding-left: 0;

  font-family: ${fonts.latoRegular};
  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};
    /*    font-style: italic; */
    font-size: 16px;
  }
`;

export const InputEmail = styled(Input)`
  border-color: ${(props) => props.colorEmail};
`;

/* ---- LABEL */
export const DivLabel = styled.div`
  width: 100%;
  font-family: ${fonts.latoRegular};
`;

export const Label = styled.label`
  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: ${fonts.latoRegularBold};
  font-weight: bold;
  font-size: 14px;
  margin-top: 19.5px;
`;

export const LabelEmail = styled(Label)`
  color: ${(props) => props.colorEmail};
`;

export const Eyes = styled.a`
  position: absolute;
  right: 18px;
  bottom: 10px;
  z-index: 1;
  cursor: pointer;

  &,
  > svg {
    color: ${colors.gray_third};
  }
`;

export const ButtonLogin = styled(ButtonLarge)`
  margin-top: 30px;
  width: 100%;
  font-family: ${fonts.latoBlack};
  font-size: 18px;
  font-weight: bold;
  background-color: ${colors.green_secondary};
  display: flex;
  text-align: center;
  justify-items: center;
  align-items: center;
  line-height: center;
  justify-content: center;
  align-content: center;
  letter-spacing: 1.8px;
  transition: background 0.2s linear;

  &:hover {
    background-color: ${colors.green_third};
  }
  &:disabled {
    background-color: ${colors.gray_secondary};
    color: ${colors.white};
  }
`;

export const Adrees = styled.input`
  position: absolute;
  top: -10000px;
  font-family: ${metrics.font_lato};
`;

export const DivError = styled(DivLabel)``;

export const ErrorMessage = styled.span`
  margin-top: 9.25px;

  color: ${colors.danger_dark};
  font-family: ${fonts.latoRegular};
  font-weight: 500;
  font-size: 14px;
`;

export const Scope = styled.div`
  font-family: ${metrics.font_lato} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const LinksContainer = styled.div`
  margin-top: 20px;

  p {
    font-family: ${fonts.latoRegular};
    font-size: 14px;
    margin-bottom: 20px;

    transition: all 0.2s ease;

    > a {
      text-decoration: none;
      color: ${colors.green_secondary};
      font-family: ${fonts.latoBlack};
      padding-left: 1px;
    }

    &:hover > a {
      color: ${colors.green_third};
    }
  }
`;
