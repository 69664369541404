import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import {Container} from './styles';
import * as S from '../../Edit/ModalDataPersonal/styles';
import './google.css';

function AddressInput({
  value,
  setValue,
  error,
  touched,
  label,
  placeholder,
  optional = false,
  handleChange,
  handleBlur,
  setLat,
  setLong,
  setPlace,
  setErrors,
  hasMargin,
}) {
  function insert(place, number) {
    setPlace(place);

    setErrors((errors) => {
      return {
        ...errors,
        address: '',
      };
    });

    if (place?.geometry?.location?.lat() && place?.geometry?.location?.lng()) {
      setLat(place?.geometry?.location?.lat());
      setLong(place?.geometry?.location?.lng());
    }

    if (number === 1) {
      if (place.address_components) {
        const route = place.address_components.find(
          (item) => item.types[0] === 'route',
        );

        if (route) {
          setValue(route.long_name);
        }
        /*  place.address_components.forEach((item) => {
          if (item.types[0] === 'route') {
            setValue(item.long_name);
          }
        }); */
      }
    }
  }

  return (
    <S.DivInputText className={hasMargin ? 'divMargin' : ''}>
      <S.Rotule error={error} valid={value}>
        {label} {!optional && '*'}
      </S.Rotule>
      <Container error={error} value={value}>
        <Autocomplete
          value={value}
          id="input-auto"
          style={{
            marginBottom: '20px',
            fontFamily: 'Lato Regular',
          }}
          onChange={(event) => handleChange(event.target.value)}
          onBlur={(event) => handleBlur(event.target.value)}
          onPlaceSelected={(place) => {
            setValue('');
            insert(place, 1);
          }}
          placeholder={placeholder}
          types={['address']}
          className={'input-localization'}
          /* componentRestrictions={{country: 'Br'}} */
        />
      </Container>
      {error && (
        <S.DivError>
          <S.ErrorMessage>{error}</S.ErrorMessage>
        </S.DivError>
      )}
    </S.DivInputText>
  );
}

export default AddressInput;
