import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Container, ButtonFilter} from './styles';
import {makeStyles} from '@material-ui/core/styles';
import signOut from '~/requests/User/signout';
import colors from '../../../../../UI/colors';
import fonts from '../../../../../UI/fonts';

import {desencrypta} from '../../../../../utils';
import {ImgUser} from '../../../../../views/User/styles';
import userImg from '../../../../../assets/iconPerson.jpg';

import {useHistory} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    marginTop: '50px',
  },
  item: {
    width: '100px',
    fontFamily: fonts.latoRegular,

    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    width: '200px',
    marginTop: '10px',
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});

export default function MenuFilter(props) {
  const classes = useStyles();
  const userStore = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClickChoose() {
    setAnchorEl(null);
    /* window.location.href = '/';
    localStorage.clear(); */
    signOut();
  }

  return (
    <Container>
      <ButtonFilter itens={props.itens} onClick={handleClick}>
        <ImgUser
          src={userStore?.avatar ? userStore?.avatar : userImg}
          width="40px"
          alt="Avatar"
          height="40px"
        />
        <span>{localStorage.getItem('name')}</span>

        {anchorEl ? <ExpandMore /> : <ExpandLess />}
      </ButtonFilter>
      <Menu
        className={classes.root}
        style={{}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setOpenCollapse(!openCollapse);
        }}>
        <MenuItem
          className={classes.item}
          onClick={() => history.push('/profile')}>
          Perfil
        </MenuItem>

        <MenuItem className={classes.item} onClick={() => handleClickChoose()}>
          Sair
        </MenuItem>
      </Menu>
    </Container>
  );
}
