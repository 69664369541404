import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import colors from '~/UI/colors';
import I18n from '~/i18n/i18';
import {getRoute} from '~/utils';

function RadioSlide({state, setState, noMargin}) {
  const [location, setLocation] = useState({
    pathname: `/${getRoute()} /advertise`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  return (
    <DivRouded
      state={state}
      onClick={() => setState(!state)}
      resetMargin={noMargin}>
      <Text>
        {state
          ? `${I18n.getTranslation(location, 'StylesDefinied.yes')}`
          : `${I18n.getTranslation(location, 'StylesDefinied.no')}`}
      </Text>
      <DivBall />
    </DivRouded>
  );
}

export default RadioSlide;

const DivBall = styled.div`
  width: 24px;
  height: 24px;
  margin: 3px;
  border: none;
  box-shadow: 0px 1px 3px #0000004d;
  border-radius: 15px;
  background: ${colors.white};
`;

const Text = styled.div`
  color: #fff;
`;

const DivRouded = styled.div`
  cursor: pointer;
  width: 72px;
  flex-direction: ${(props) => (props.state ? 'row' : 'row-reverse')};
  height: 30px;
  border: none;
  box-shadow: 0px 1px 3px #0000004d;
  border-radius: 15px;
  background-color: ${(props) =>
    props.state ? colors.green_secondary : colors.white};
  display: flex;
  ${DivBall} {
    background: ${(props) =>
      props.state ? colors.white : colors.green_secondary};
  }

  ${Text} {
    color: ${(props) => (props.state ? colors.white : colors.green_secondary)};
  }

  transition: 0.4s;
  justify-content: flex-end;
  align-items: center;
  margin-left: ${(props) => (props.resetMargin ? '0px' : '10px')};
`;
