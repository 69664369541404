import React, {useState} from 'react';
import styled from 'styled-components';
import colors from '../UI/colors';
import I18n from '../i18n/i18';

export default function InputTextRequired(props) {
  const [viewMessage, setViewMessage] = useState(false);

  function change(event) {
    if (props.mask) {
      props.mask(event, props.setValue);
    } else {
      props.setValue(event.target.value);
    }
  }

  function handleBlur() {
    props.onFocusOut && props.onFocusOut();
    if (props.value) {
      setViewMessage(false);

      if (props.min && props.max) {
        if (
          props.value.length === props.min ||
          props.value.length === props.max
        ) {
          setViewMessage(false);
        } else if (!props.isRequired) {
          setViewMessage(false);
        } else {
          setViewMessage(true);
        }
      }
    } else {
      setViewMessage(true);
    }
  }

  React.useEffect(() => {
    if (props.value && viewMessage) {
      setViewMessage(false);
    }
  }, [props.value]);

  return (
    <div>
      <Label color={props.value}>
        {props.label} <Star>*</Star>
      </Label>
      <Input
        onBlur={() => {
          handleBlur();
        }}
        disabled={props.disable}
        minLength={props.min ? props.min : null}
        maxLength={props.max ? props.max : null}
        color={props.value}
        value={props.value}
        onChange={(event) => change(event)}
        itens={props.itens}
        placeholder={props.placeHolder}
        inputMode={props.inputMode}
        onFocus={props.onFocus}
      />
      {viewMessage ? <Message>Campo obrigatório</Message> : null}
    </div>
  );
}

const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.value ? `2px solid ${colors.green_secondary}` : '#808076 1px solid'};
  color: ${(props) => (props.value ? '#1D1D1B' : '#808076')};
  width: 100%;
  font-size: 16px;
  font-family: 'Lato Regular';
  justify-content: space-between;
  justify-items: center;
  align-items: center;

  &::placeholder {
    font-family: 'Lato Regular Italic';
  }
  &:focus {
    outline: 0;
  }
  &:disabled {
  }
`;

const Label = styled.div`
  font-family: 'Lato Regular Bold';
  font-size: 16px;
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;

const Message = styled.div`
  font-family: 'Lato Regular Bold';
  font-size: 13px;
  color: red;
`;

export const Star = styled.span`
  color: #a88721;
  margin-left: 2px;
`;
