import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

import {Container, Item, Label} from './styles';

const GreenRadio = withStyles({
  root: {
    color: '#50B275',
    '&$checked': {
      color: '#50B275',
    },
  },
  checked: {},
})((props) => <Radio size="small" color="default" {...props} />);

export default function RadioButtonsPerson({
  selectedValue,
  setSelectedValue,
  distance,
}) {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Container mg={distance || 0}>
      <Item>
        <GreenRadio
          checked={selectedValue === 'seller'}
          onChange={handleChange}
          value="seller"
          name="radio-button-demo"
          inputProps={{'aria-label': 'seller'}}
        />{' '}
        <Label style={{fontSize: '16px'}} checked={selectedValue === 'seller'}>
          Imobiliária
        </Label>
      </Item>
      <Item hasSpace>
        <GreenRadio
          checked={selectedValue === 'common'}
          onChange={handleChange}
          value="common"
          name="radio-button-demo"
          inputProps={{'aria-label': 'common'}}
        />{' '}
        <Label style={{fontSize: '16px'}} checked={selectedValue === 'common'}>
          Comum
        </Label>
      </Item>
    </Container>
  );
}
