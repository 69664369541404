import React from 'react';
import SearchIcon from '~/assets/search-icon.svg';
import AutosuggestSubscribers from './autoComplete';
import * as S from './styles';

function SearchComponent({
  placeholder,
  value,
  handleChange,
  handleBlur,
  handleSubmit,

  setSuggestItem,
}) {
  return (
    <S.Container
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <AutosuggestSubscribers
        value={value}
        handleChange={handleChange}
        handleBlur={handleBlur}
        placeholder={placeholder}
        setSuggestItem={setSuggestItem}
      />
      <button type="submit">
        <div>
          <img src={SearchIcon} alt="Search" />
        </div>
      </button>
    </S.Container>
  );
}

export default SearchComponent;
