import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import baseURL from '../../services/api';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalApproveAds({
  id,
  open,
  setOpen,
  text,
  funcExec,
  loading,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  async function aproveAds(event) {
    event.preventDefault();
    funcExec(handleClose);
  }

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle> Atenção</AdvertTitle>
              <Message>
                Você confirma a {text} <b>APROVAÇÃO</b> o deste anúncio?
              </Message>
              {loading && (
                <Message>
                  <strong style={{display: 'block', marginTop: 40}}>
                    Carregando...
                  </strong>
                </Message>
              )}
              <DivConfirm>
                <ButtonsWhite
                  disabled={loading}
                  onClick={(event) => aproveAds(event)}>
                  SIM
                </ButtonsWhite>
                <ButtonsWhite
                  disabled={loading}
                  onClick={(event) => handleClose()}>
                  NÃO
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
