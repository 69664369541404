import React, {useState, useEffect} from 'react';
import crypto from 'crypto-js';
import VMasker from 'vanilla-masker';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as validate from '~/utils/regex';
import {handleChangePhoneMask} from '~/utils/masks';

import {
  TitleModal,
  InternDiv,
  DivInputText,
  Rotule,
  InputText,
  DivError,
  ErrorMessage,
  ButtonSave,
} from '../../Clients/Edit/ModalDataPersonal/styles';
import InputPhone from '~/components/InputPhone';
import './styles.css';
import '../../styles.css';
import colors from '~/UI/colors';

import ReactLoading from 'react-loading';

import {Star} from './styles';
import RadioButtons from './RadioButton';
import {Dialog} from '@material-ui/core';

export default function ModalRegisterColaborator({
  open,
  setOpen,
  loading,
  handleSubmit,
}) {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');

  const [cargo, setCargo] = useState('');
  const [selectedValue, setSelectedValue] = useState('5');

  const handleClose = () => {
    setOpen(false);
  };

  function clearModal() {
    setTouched(() => {
      setName('');
      setSurname('');
      setEmail('');
      setPassword('');
      setPhone('');
      setCargo('');
      setSelectedValue('5');
      setErrors({});
      return {};
    });
  }

  /* Clear on keep out */
  useEffect(() => {
    if (!open) {
      clearModal();
    }
  }, [open]);

  const [passwordVisible, setPasswordVisible] = useState(true);
  const passwordRef = React.useRef(null);

  function handleIsEye(e) {
    e.preventDefault();

    let pass = passwordRef.current;
    pass.type === 'password' ? (pass.type = 'text') : (pass.type = 'password');
    setPasswordVisible(!passwordVisible);
  }

  /* ------------------------------------ */
  /* ----------- VALIDATIONS ------------ */
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* ______ NAME ______ */
  const handleChangeName = (value) => {
    setName(value);

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurName = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        name: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ______ Surname ______ */
  const handleChangeSurname = (value) => {
    setSurname(value);

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurSurname = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        surname: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ______ EMAIL ______ */
  const handleChangeEmail = (value) => {
    setEmail(value);

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value.trim())
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  const handleBlurEmail = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        email: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value.trim())
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  /* ______ PHONE ______ */
  const handleChangePhone = (value) => {
    setPhone(value);

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value
          ? 'Campo não preenchido'
          : value.trim() && value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  const handleBlurPhone = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        phone: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        phone: !value
          ? 'Campo não preenchido'
          : value.trim() && value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '',
      };
    });
  };

  /* ______ Cargo ______ */
  const handleChangeCargo = (value) => {
    setCargo(value);

    setErrors((errors) => {
      return {
        ...errors,
        cargo: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurCargo = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        cargo: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        cargo: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ______ PASSWORD ______ */
  const handleChangePassword = (value) => {
    setPassword(value);

    setErrors((errors) => {
      return {
        ...errors,
        password: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
      };
    });
  };

  const handleBlurPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        password: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        password: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
      };
    });
  };
  /* ------------------------------------ */
  function createUser(event) {
    event.preventDefault();
    const key = process.env.REACT_APP_KEY_GENERATE_HASH;
    let hash_password = crypto.AES.encrypt(password.trim(), key).toString();

    const data = {
      name: name.trim(),
      surname: surname.trim(),
      email: email.trim(),
      password: hash_password,
      cargo: cargo.trim(),
      cellphone: phone.trim(),
      role_id: selectedValue,
    };

    handleSubmit(data);
  }

  /* Clear on keep out */
  useEffect(() => {
    if (!open) {
      clearModal();
    }
  }, [open]);

  return (
    <div>
      <Dialog
        scroll="body"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <TitleModal>
          <span>Cadastrar Colaborador</span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <form onSubmit={createUser} style={{padding: '20px'}}>
          <InternDiv>
            <div>
              <DivInputText>
                <Rotule error={errors.name} valid={name}>
                  Nome <Star>*</Star>
                </Rotule>
                <InputText
                  error={errors.name}
                  touched={touched.name}
                  value={name}
                  onChange={(event) => handleChangeName(event.target.value)}
                  onBlur={(event) => handleBlurName(event.target.value)}
                  placeholder="Informe o nome do usuário"
                />
                {errors.name && (
                  <DivError>
                    <ErrorMessage>{errors.name}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.surname} valid={surname}>
                  Sobrenome <Star>*</Star>
                </Rotule>
                <InputText
                  error={errors.surname}
                  touched={touched.surname}
                  value={surname}
                  onChange={(event) => handleChangeSurname(event.target.value)}
                  onBlur={(event) => handleBlurSurname(event.target.value)}
                  placeholder="Informe o sobrenome do usuário"
                />
                {errors.surname && (
                  <DivError>
                    <ErrorMessage>{errors.surname}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.email} valid={email}>
                  Email <Star>*</Star>
                </Rotule>
                <InputText
                  error={errors.email}
                  touched={touched.email}
                  value={email}
                  onChange={(event) => handleChangeEmail(event.target.value)}
                  onBlur={(event) => handleBlurEmail(event.target.value)}
                  placeholder="Informe o e-mail do usuário"
                />
                {errors.email && (
                  <DivError>
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule valid={selectedValue}>
                  Selecione o tipo de acesso <Star>*</Star>
                </Rotule>
                <RadioButtons
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                />
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.cargo} valid={cargo}>
                  Cargo <Star>*</Star>
                </Rotule>
                <InputText
                  error={errors.cargo}
                  touched={touched.cargo}
                  value={cargo}
                  onChange={(event) => handleChangeCargo(event.target.value)}
                  onBlur={(event) => handleBlurCargo(event.target.value)}
                  placeholder="Informe o cargo do usuário"
                />
                {errors.cargo && (
                  <DivError>
                    <ErrorMessage>{errors.cargo}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.phone} valid={phone}>
                  Telefone <Star>*</Star>
                </Rotule>
                <InputPhone
                  error={errors.phone}
                  touched={touched.phone}
                  value={phone}
                  onChange={handleChangePhone}
                  onBlur={handleBlurPhone}
                  placeholder="Informe o telefone do usuário"
                />
                {errors.phone && (
                  <DivError>
                    <ErrorMessage>{errors.phone}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.password} valid={password}>
                  Senha <Star>*</Star>
                </Rotule>
                <div className="passEyes">
                  <a href="#pass" onClick={handleIsEye}>
                    {!passwordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </a>
                  <InputText
                    id="pass"
                    type="password"
                    ref={passwordRef}
                    error={errors.password}
                    touched={touched.password}
                    value={password}
                    onChange={(event) => {
                      handleChangePassword(event.target.value);
                    }}
                    onBlur={(event) => handleBlurPassword(event.target.value)}
                    placeholder="Informe a senha..."
                  />
                </div>
                {errors.password && (
                  <DivError>
                    <ErrorMessage>{errors.password}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>
            </div>
            <ButtonSave
              type="submit"
              disabled={
                !name.trim() ||
                errors.name ||
                !surname.trim() ||
                errors.surname ||
                !email.trim() ||
                errors.email ||
                !password.trim() ||
                errors.password ||
                !cargo.trim() ||
                errors.cargo ||
                !phone.trim() ||
                errors.phone ||
                !selectedValue ||
                loading
              }
              className={'btnDataPersonal btnload'}>
              {loading ? (
                <ReactLoading
                  type={'spin'}
                  className="load"
                  color={colors.green_secondary}
                  width={'30px'}
                />
              ) : (
                'Cadastrar'
              )}
            </ButtonSave>
          </InternDiv>
        </form>
      </Dialog>
    </div>
  );
}

{
  /* <Dialog
  scroll="body"
  onClose={handleClose}
  aria-labelledby="simple-dialog-title"
  open={open}>
  <TitleModal>
    <span>
      <I18n t={'DesiredProperty.ModalTitle'} />{' '}
    </span>
    <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
  </TitleModal>
  <ModalResponsive>
    <InternDiv>
      <div>
        <DivInputText>
          <SelectMenuRequiredI18n
            i18n="Country"
            placeHolder={I18n.getTranslation(
              location,
              'Localization.placeHolderCountry',
            )}
            label={I18n.getTranslation(location, 'Localization.labelCountry')}
            itens={itensCountry}
            value={selectedValueCountry}
            setValue={setSelectedValueCountry}
          />
        </DivInputText>

        <DivInputText>
          <InputText
            placeHolder={I18n.getTranslation(
              location,
              'Localization.placeHolderNeig',
            )}
            label={I18n.getTranslation(location, 'Localization.labelNeig')}
            value={neighborhood}
            setValue={setNeighborhood}
          />
        </DivInputText>

        <DivInputText>
          <Label classes={classGoogleC}>
            <I18n t={'Localization.labelCity'} />
          </Label>
          {utf === 'Br' && renderGoogleCity()}
          {utf === 'Us' && renderGoogleCity()}
          {utf === 'Pt' && renderGoogleCity()}
        </DivInputText>

        <DivInputText>
          <InputText
            placeHolder={I18n.getTranslation(
              location,
              'Localization.placeHolderState',
            )}
            label={I18n.getTranslation(location, 'Localization.labelState')}
            value={state}
            setValue={setState}
          />
        </DivInputText>

        <DivInputText>
          <SelectMenuRequiredI18n
            i18n="PropertyType"
            placeHolder={I18n.getTranslation(location, 'Type.placeholder')}
            label={I18n.getTranslation(location, 'Type.rotule')}
            itens={itemEN}
            value={type}
            setValue={setType}
          />
        </DivInputText>

        <DivMetrics>
          <DivInputMetrics>
            <InputTextRequire
              mask={mask}
              placeHolder={I18n.getTranslation(location, 'ModalFilter.Digit')}
              label={<I18n t={'ModalFilter.ValueMin'} />}
              value={priceMin}
              setValue={setPriceMin}
            />
          </DivInputMetrics>
          <DivSelectMetrics value={priceMin}>
            <Dimension
              value={choseMetric}
              setValue={setChoseMetric}
              itens={itensDimension}
            />
          </DivSelectMetrics>
        </DivMetrics>

        <DivMetrics>
          <DivInputMetrics style={{marginBottom: '3px'}}>
            <InputTextRequire
              mask={mask}
              placeHolder={I18n.getTranslation(location, 'ModalFilter.Digit')}
              label={<I18n t={'ModalFilter.ValueMax'} />}
              value={priceMax}
              setValue={setPriceMax}
            />
          </DivInputMetrics>
          <DivSelectMetrics value={priceMax}>
            <DivText>
              <Dimension
                value={choseMetric}
                setValue={setChoseMetric}
                itens={itensDimension}
              />
            </DivText>
          </DivSelectMetrics>
        </DivMetrics>
      </div>
      <ButtonSave
        disabled={enableButton}
        onClick={() => {
          sendMatches();
        }}
        className={'btnDataAddress btnload'}>
        + <I18n t={'DesiredProperty.AddAds'} />
      </ButtonSave>
    </InternDiv>
  </ModalResponsive>
</Dialog>; */
}

/* useEffect(() => {
  setPhone('');
}, [country]);

useEffect(() => {
  setLoading(true);
}, []);

useEffect(() => {
  if (
    emailErrorClass === 'greenEmail' &&
    password.length >= 8 &&
    name &&
    phone.length > 0 &&
    job
  ) {
    setValid(true);
  } else {
    setValid(false);
  }
}, [emailErrorClass, phone, password, name, job, min]);

function validation() {
  verifyEmail();
  verifyPhone();
  verifyPass();
  verifyName();

  verifyJob();
}

function verifyEmail() {
  let reg = /^[\w.\+]+@\w+.\w{2,}(?:.\w{2})?$/;
  if (email.length > 0) {
    if (reg.test(email)) {
      setEmailErrorClass('greenEmail');
      setEmailErrorClassInput('green');
      setManEmail(false);
    } else {
      setEmailErrorClass('redEmail');
      setEmailErrorClassInput('red');
      setMessageEmail('Preencha o email corretamente');
      setManEmail(true);
    }
  } else {
    setEmailErrorClass('redEmail');
    setEmailErrorClassInput('red');
    setMessageEmail('Campo obrigatório');
    setManEmail(true);
  }
}

function verifyName() {
  if (name) {
    setNameErrorClass('greenEmail');
    setNameErrorClassInput('green');
    setManName(false);
  } else {
    setNameErrorClass('redEmail');
    setNameErrorClassInput('red');
    setManName(true);
  }
}

function verifyJob() {
  if (job) {
    setJobErrorClass('greenEmail');
    setJobErrorClassInput('green');
    setManJob(false);
  } else {
    setJobErrorClass('redEmail');
    setJobErrorClassInput('red');
    setManJob(true);
  }
}

function verifyPhone() {
  if (phone.length > 0) {
    setPhoneErrorClass('greenEmail');
    setPhoneErrorClassInput('green');
    setManPhone(false);
  } else {
    setPhoneErrorClass('redEmail');
    setPhoneErrorClassInput('red');
    setManPhone(true);
  }
}

function verifyPass() {
  if (password.length >= 8) {
    setPassErrorClass('greenEmail');
    setPassErrorClassInput('green');
    setManPass(false);
  } else if (password.length === 0) {
    setPassErrorClass('redEmail');
    setPassErrorClassInput('red');
    setMessagePhone('Campo obrigatório!');
    setManPass(true);
  } else {
    setPassErrorClass('redEmail');
    setPassErrorClassInput('red');
    setMessagePass('Preencha com no mínimo 8 caracteres!');
    setManPass(true);
  }
} */
