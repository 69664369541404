const {default: apiBackend} = require('~/services/apiaxios');
const {desencrypta} = require('~/utils');

export function getAlltypes(setItensBackend) {
  let token = desencrypta(localStorage.getItem('@User_informations'));

  apiBackend
    .get('v1/type_property', {
      headers: {Authorization: `Bearer ${token}`},
    })
    .then((response) => {
      setItensBackend(response.data.type_property);
    })
    .catch((e) => {
      console.log(e);
    });
}
