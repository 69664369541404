import React, {useState} from 'react';
import styled from 'styled-components';
import {Star} from './styles';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import I18n from '~/i18n/i18';

export default function InputTextRequireSelect({func, ...props}) {
  const [viewMessage, setViewMessage] = useState(false);

  function change(event) {
    if (props.mask) {
      props.mask(event, props.setValue);
    } else {
      props.setValue(event.target.value);
    }
  }

  function handleBlur() {
    if (props.value) {
      setViewMessage(false);
      func(false);
      if (props.min && props.max) {
        if (
          props.value.length === props.min ||
          props.value.length === props.max
        ) {
          setViewMessage(false);
          func(false);
        } else {
          setViewMessage(true);
          func(true);
        }
      }
    } else {
      func(true);
      setViewMessage(true);
    }
  }

  return (
    <>
      <div>
        <Label color={props.value}>
          {props.label} <Star>*</Star>
        </Label>
        <Input
          onBlur={() => {
            handleBlur();
          }}
          noBorder={props.noBorder}
          disabled={props.disable}
          minLength={props.min ? props.min : null}
          maxLength={props.max ? props.max : null}
          color={props.value}
          value={props.value}
          onChange={(event) => change(event)}
          itens={props.itens}
          placeholder={props.placeHolder}
        />
      </div>
    </>
  );
}

const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.noBorder
      ? 'none!important'
      : props.value
      ? `2px solid ${colors.green_secondary}!important`
      : '1px solid #808076!important'};
  color: ${(props) => (props.value ? '#1D1D1B' : '#808076')};
  width: 100%;
  font-size: 16px;
  font-family: ${fonts.latoRegular};
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus {
    outline: 0;
  }
  &:disabled {
  }
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;

const Message = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 13px;
  color: red;
`;
