import styled from 'styled-components';
import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const ModalContainer = styled.div`
  padding: 20px 40px;

  @media (max-width: 400px) {
    padding: 20px;
  }
`;

export const CloseModal = styled.div`
  flex: none;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding-left: 20px;

  svg {
    color: ${colors.green_secondary};
  }

  &:hover svg {
    opacity: 0.7;
  }
`;

export const TitleModal = styled.span`
  padding: 10px 0 0;
  width: 100%;

  font-size: 18px;
  letter-spacing: 1.8px;
  color: ${colors.green_secondary};
  position: relative;

  font-family: ${fonts.latoBlackItalic};
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;

  display: flex;
  justify-content: space-between;

  .iconClose {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  @media (max-width: 540px) {
    margin-top: 10px;
  }

  /*  @media (max-width: 400px) {
    margin-bottom: 0px;
    padding: 50px 20px 0px;

    > span {
      display: block;
      margin: 0 auto;
    }
  } */
`;

export const DivName = styled.div`
  color: ${colors.dark};
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: ${fonts.latoRegularBold};
`;

export const DivGroupInfo = styled.div`
  display: flex;
  flex-wrap: wrap;

  p:not(:last-child) {
    padding-right: 30px;
  }
`;

export const PInfo = styled.p`
  flex: none;
  color: ${colors.text_dar_modal};
  font-size: 14px;
  font-family: ${fonts.latoRegularBold};

  span {
    font-size: 16px;
    padding-left: 10px;

    font-family: ${fonts.latoRegular};

    &.email__span {
      @media (max-width: 540px) {
        display: inline-block;
        width: 100%;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
