import styled from 'styled-components';
import fonts from '../../../../UI/fonts';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 468px) {
    flex-direction: column;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  color: ${(props) => (props.checked ? '#1D1D1B' : '#808076')};
  /*   font-weight: ${(props) => (props.checked ? 'bold' : '400')}; */
  margin-left: 5px;
  font-family: ${(props) =>
    props.checked ? fonts.latoRegularBold : fonts.latoRegular};
`;

export const Item = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 0;

  .MuiIconButton-label {
    svg {
      color: inherit !important;
    }
  }
`;
