import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.92);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #000000aa, transparent);

    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    max-width: 1366px;
    width: 100%;

    > button {
      cursor: pointer;
      position: absolute;
      right: 40px;
      width: 38px;
      height: 38px;
      border: none;
      border-radius: 5px;
      background: none;

      > svg {
        width: 30px;
        height: 30px;
        color: #fff;
        pointer-events: none;
      }
    }
    > span {
      display: block;
      background: rgba(255, 255, 255, 0.3);
      color: #fff;
      padding: 10px 50px;
      border-radius: 5px;
      font-family: ${fonts.latoBold};
      font-size: 16px;
    }
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-height: 639px) {
    max-height: 70vh;
  }
  @media (min-height: 640px) and (max-height: 669px) {
    max-height: 70vh;
  }
  @media (min-height: 670px) and (max-height: 700px) {
    max-height: 70vh;
  }
  @media (min-height: 701px) {
    max-height: 70vh;
  }
`;

export const SliderItem = styled.div`
  max-width: 1000px;
  position: relative;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  /* img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  } */
`;

export const Controls = styled.div`
  width: 100%;
  max-width: 100vw;

  padding: 0 20vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  button {
    pointer-events: all;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    box-shadow: 0px 1px 3px #00000033;
    border: none;
    background: rgba(255, 255, 255, 0.3);
    color: ${colors.white};
    cursor: pointer;

    > svg {
      width: 32px;
      height: 32px;
      transition: all 0.2s ease;
    }

    transition: all 0.2s ease;

    &:hover:not(:disabled) {
      background-color: #d9d9ce;
      border-color: #d9d9ce;
      color: rgba(0, 0, 0, 0.92);
    }
    &:active:not(:disabled) {
      background-color: rgba(0, 0, 0, 0.92);
      color: #fff;
      border-color: rgba(0, 0, 0, 0.92);

      &:hover {
        background-color: rgba(0, 0, 0, 0.92);
        color: #fff;
        border-color: rgba(0, 0, 0, 0.92);
      }
    }
    &:disabled {
      cursor: not-allowed !important;
      background-color: #fff;
      border-color: #d9d9ce;
      color: #d9d9ce;

      svg {
        color: #d9d9ce;
        pointer-events: none;
      }
    }
  }
`;
