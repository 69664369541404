import React from 'react';
import {Divider, Grid} from '@material-ui/core';

import {Square, TitleRent, ValueRent} from './styles';
import I18n from '../../../../../i18n/i18';

function CostAndRent({advertise}) {
  return (
    <>
      {advertise?.monthly_maintenance_fee ||
      advertise.monthly_tax_fee ||
      advertise?.rental ||
      advertise?.rental_price ? (
        <>
          <div className="subTitleShow">Custos e Aluguel do Imóvel</div>
          <Grid spacing={2} container className="mg-top-30">
            {advertise?.monthly_maintenance_fee ? (
              <Grid
                spacing={1}
                container
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}>
                <Square>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <TitleRent>
                        <I18n t={'DetailsAds.taxMaintenaceFee'} />
                      </TitleRent>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <ValueRent className="mg-top-10">
                        {advertise?.monthly_maintenance_fee}{' '}
                        {advertise?.price_currency}
                      </ValueRent>
                    </Grid>
                  </Grid>
                </Square>
              </Grid>
            ) : null}

            {advertise?.monthly_tax_fee ? (
              <Grid
                spacing={1}
                container
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}>
                <Square>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <TitleRent>
                        <I18n t={'DetailsAds.monthlyTaxFee'} />
                      </TitleRent>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <ValueRent className="mg-top-10">
                        {advertise?.monthly_tax_fee} {advertise?.price_currency}
                      </ValueRent>
                    </Grid>
                  </Grid>
                </Square>
              </Grid>
            ) : null}

            {advertise?.rental ? (
              <Grid
                spacing={1}
                container
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}>
                <Square>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <TitleRent>
                        <I18n t={'DetailsAds.rental'} />
                      </TitleRent>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <ValueRent className="mg-top-10">
                        <I18n t={`ItensRent.${advertise?.rental}`} />
                      </ValueRent>
                    </Grid>
                  </Grid>
                </Square>
              </Grid>
            ) : null}

            {advertise?.rental_price ? (
              <Grid
                spacing={1}
                container
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}>
                <Square>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <TitleRent>
                        <I18n t={'DetailsAds.rentalPrice'} />
                      </TitleRent>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <ValueRent className="mg-top-10">
                        {advertise?.rental_price}{' '}
                        {advertise?.rental_price_currency}
                      </ValueRent>
                    </Grid>
                  </Grid>
                </Square>
              </Grid>
            ) : null}
          </Grid>
          <div className="mg-top-30">
            <Divider />
          </div>
        </>
      ) : null}
    </>
  );
}

export default CostAndRent;
