import React from 'react';
import {useSelector} from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './styles.css';
import Grid from '@material-ui/core/Grid';
import {
  TableContainer,
  TitleBold,
  P,
  TitleBoldView,
  RowPhotoName,
  ViewAd,
  MainPrice,
  OtherPrice,
} from './styles';

/* import {getAdress, getPrice} from '../../utils'; */
import {Link} from 'react-router-dom';
/* import I18nType from '../../../../utils/translate/propertyType.json'; */

import ManagerOptions from '../../ManagerOptions';
import {getAdress, getPrice} from '../../View/utils/finance';

const permitted_roles = ['5', '3'];

export default function TableAdvertise({
  information,
  currencyValues,
  getAllProperties,
}) {
  const userStore = useSelector((state) => state?.user);
  function dateTransform(info) {
    let date = new Date(info);
    return (
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
      '/' +
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    );
  }

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*   useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  return (
    <>
      <TableContainer>
        <Grid
          className="tableMobile"
          container
          spacing={2}
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderBottom: '2px solid #BFBFB2',
          }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            xl={3}
            justify="flex-start"
            alignitems="flex-start">
            <TitleBoldView className="tableMobile">
              Endereço do imóvel / <br />
              Usuário
            </TitleBoldView>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <TitleBoldView>
              Valor <br />
              do imóvel
            </TitleBoldView>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <TitleBoldView>
              Tipo
              <br /> de imóvel
            </TitleBoldView>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <TitleBoldView>Data do anúncio</TitleBoldView>
          </Grid>
          {isPermitted() && (
            <Grid
              item
              xs={12}
              sm={4}
              md={1}
              lg={1}
              xl={1}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
              }}>
              <TitleBoldView>Funções</TitleBoldView>
            </Grid>
          )}
        </Grid>
        {information?.map((info, key) => {
          return (
            <Grid
              key={key}
              container
              spacing={2}
              style={{
                marginTop: 15,
                marginBottom: 15,
                borderBottom: '2px solid #BFBFB2',
                display: 'flex',
                alignItems: 'flex-start',
              }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                style={{paddingRight: 5}}>
                <TitleBold>Endereço do imóvel</TitleBold>
                <P style={{marginBottom: '10px'}} largeText>
                  {getAdress(info?.address)}
                </P>
                <RowPhotoName>
                  Por:{' '}
                  <span>
                    {info?.user?.name} {info?.user?.surname}
                  </span>
                </RowPhotoName>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Valor do imóvel</TitleBold>
                {getPrice(info, currencyValues)}
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Tipo de imóvel</TitleBold>
                <P>
                  {info.type_property
                    ? info.type_property.name_pt
                    : 'Não Definido'}
                </P>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TitleBold>Data do anúncio</TitleBold>
                <P>{dateTransform(info?.created_at)}</P>
                {info?.last_update_by ? (
                  <P className="__bold_text">
                    Modificado por <strong>{info?.last_update_by}</strong>
                  </P>
                ) : (
                  <P className="__bold_text">
                    Criado por <strong>{info?.user?.name}</strong>
                  </P>
                )}
              </Grid>

              {isPermitted() && (
                <Grid
                  className="buttonActions"
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  lg={2}
                  xl={2}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}>
                  {info?.user?.profile?.type_advertiser?.toLowerCase() !==
                    'broker' && (
                    <ManagerOptions
                      getAllProperties={getAllProperties}
                      getAllPropertiesPrice={getAllProperties}
                      info={info}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}
      </TableContainer>
    </>
  );
}
