import {createMuiTheme} from '@material-ui/core';
import fonts from '~/UI/fonts';
import colors from '~/UI/colors';

const materialTheme = createMuiTheme({
  typography: {
    body1: {
      fontSize: '18px',
      fontFamily: fonts.latoRegularBold,
    },
    body2: {
      fontSize: '14px',
      fontFamily: fonts.latoRegularBold,
    },
    caption: {
      fontSize: '14px',
      fontFamily: fonts.latoRegular,
    },
  },
  overrides: {
    pickerView: {
      borderRadius: '10px',
    },

    MuiTextField: {
      root: {
        maxWidth: '100%',
        width: '100%',
      },
    },

    MuiInputBase: {
      root: {
        maxWidth: '100%',
        width: '100%',

        '&::placeholder': {
          color: colors.dark,
          lineHeight: '20px',
          textAlign: 'left',
          opacity: 1,
          fontFamily: fonts.latoRegularItalic,
        },
      },

      input: {
        boxSizing: 'border-box',
        width: '100%',
        minWidth: 200,
        height: '50px',
        borderRadius: '10px',
        padding: '15px 20px',
        fontFamily: fonts.latoRegular,
        fontSize: '16px',
        color: colors.dark,
        boxShadow: '0 3px 6px #00000033',

        '&::placeholder': {
          color: colors.dark,
          lineHeight: '20px',
          textAlign: 'left',
          opacity: 1,
          fontFamily: fonts.latoRegularItalic,
        },

        '@media(max-width: 768px)': {
          maxWidth: '100%',
          width: '100%',
        },
      },
    },

    MuiInputAdornment: {
      positionEnd: {
        marginLeft: '-54px',
      },
    },

    MuiFormControl: {
      root: {
        '@media(max-width: 768px)': {
          maxWidth: '100%',
          width: '100%',
        },
      },
    },

    MuiIconButton: {
      root: {
        color: colors.green_secondary,

        transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

        '&:hover': {
          backgroundColor: '#F2F2F2',
          color: colors.green_secondary,
        },

        '&:active': {
          backgroundColor: colors.green_secondary,
          color: '#fff',

          '&:hover': {
            backgroundColor: colors.green_secondary,
            color: '#fff',
          },
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        display: 'none',
      },
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#000',
        textTransform: 'uppercase',
      },
      switchHeader: {
        color: '#141414',
        textTransform: 'uppercase',
      },
      iconButton: {
        color: colors.green_third,

        '&:hover': {
          color: colors.green_secondary,
        },
      },
    },

    MuiPickersYear: {
      root: {
        color: '#000000',
        '&:hover': {
          backgroundColor: '#F2F2F2',
          color: colors.green_secondary,
        },
        '&:active': {
          backgroundColor: colors.green_secondary,
          color: '#F2F2F2',
        },
        transition: 'all .2s ease-out',
      },

      yearSelected: {
        color: '#F2F2F2',
        backgroundColor: colors.green_secondary,
        fontSize: 16,
        fontWeight: 400,
      },
    },

    MuiPickersMonth: {
      root: {
        flex: '1 0 55px',
        height: '55px',
        boxSizing: 'border-box',
        margin: 10,
        color: '#000000',
        borderRadius: '50%',
        transition: 'all .2s ease-out',
        '&:hover': {
          backgroundColor: '#F2F2F2',
          color: colors.green_secondary,
        },
        '&:active': {
          backgroundColor: colors.green_secondary,
          color: '#F2F2F2',
        },
      },
      monthSelected: {
        color: '#F2F2F2',
        backgroundColor: colors.green_secondary,
        fontSize: 16,
        fontWeight: 400,
      },
    },

    // dias selecionáveis
    MuiPickersDay: {
      // um dia
      day: {
        color: colors.dark,
        '&:hover': {
          backgroundColor: '#F2F2F2',
          color: colors.green_secondary,
        },
      },
      // o dia selecionado
      daySelected: {
        backgroundColor: colors.green_secondary,

        '&:hover': {
          backgroundColor: '#F2F2F2',
          color: colors.green_secondary,
        },
      },
      // dias desabilitados
      dayDisabled: {
        color: 'rgba(0, 0, 0, 0.38)',
      },
      // dia atual
      current: {
        color: colors.dark,
      },
    },
  },
});

export default materialTheme;
