import styled from 'styled-components';

import fonts from '~/UI/fonts';
import colors from '~/UI/colors';

export const TitleIntentions = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 18px;
  color: #1d1d1b;
  &.mg-left-15 {
    margin-left: 15px;
  }
`;

export const ButtonWhiteAccordion = styled.button`
  background: none;
  box-shadow: none;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: ${fonts.latoBold};
  letter-spacing: 0px;
  color: ${colors.green_secondary};
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover:not(:active) {
    background: ${colors.gray_primary};
  }

  &:active {
    background: ${colors.green_third};
    color: #fff;
  }
`;

export const ItemOpacity = styled.div`
  max-height: ${(props) =>
    props.notMask ? 'unset' : props.open === false ? '180px' : 'unset'};
  mask-image: ${(props) =>
    props.notMask
      ? ''
      : props.open === false &&
        'linear-gradient(#fff, #fff, #fff, rgba(255, 255, 255, 0))'};
`;
