import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css'
import { Dialog } from '@material-ui/core';
import { ModalResponsive } from '../../Components/styles';
import { InternDiv, TitleModalGreen, NumberBall, DivTip, TextTip } from './styles';
import { ButtonInfo } from '../styles';



export default function ModalTips({ open, setOpen }) {

    const handleClose = () => {
        setOpen(false);
    };


    return (<>
        <div>
            <Dialog scroll="body" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <TitleModalGreen>
                    <span>Dicas para tirar as melhores fotos para o seu anúncio.</span><CloseIcon onClick={() => handleClose()} className={'iconClose'} />
                </TitleModalGreen>
                <ModalResponsive>
                    <InternDiv>
                        <DivTip>
                            <div>
                                <NumberBall>
                                    1
                                </NumberBall>
                            </div>

                            <TextTip>
                                Tire fotos com a câmera de preferência na horizontal para captura o máximo do seu espaço. Tire fotos a partir dos cantos para adicionar perspectiva.
                            </TextTip>
                        </DivTip>
                        <DivTip>
                            <div>
                                <NumberBall>
                                    2
                                </NumberBall>
                            </div>

                            <TextTip>
                                Os espaços ficam melhor com luz natural. Se for noite, acenda as luzes. Evite usar flash.
                            </TextTip>
                        </DivTip>
                        <DivTip>
                            <div>
                                <NumberBall>
                                    3
                                </NumberBall>
                            </div>

                            <TextTip>
                                Inclua todos os espaços do imóvel, como área não construída e área construída (se tiver).
                            </TextTip>
                        </DivTip>
                        <ButtonInfo onClick={(event) => handleClose()}>
                            Entendido
                        </ButtonInfo>
                    </InternDiv>
                </ModalResponsive>
            </Dialog>
        </div>

    </>
    );
}