import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import {Label} from './styles';
import './styles.css';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio size="small" color="default" {...props} />);

export default function RadioButtonLang({selectedValue, setSelectedValue}) {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'row', marginTop: 8}}>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontFamily: 'Lato',
          fontSize: '12px',
        }}>
        <GreenRadio
          checked={selectedValue === 'pt'}
          onChange={handleChange}
          value="pt"
          name="radio-button-demo"
          inputProps={{'aria-label': 'pt'}}
        />{' '}
        <Label style={{fontSize: '16px'}} checked={selectedValue === 'pt'}>
          pt-BR
        </Label>
      </p>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontFamily: 'Lato',
          fontSize: '16px',
          marginLeft: 15,
        }}>
        <GreenRadio
          checked={selectedValue === 'en'}
          onChange={handleChange}
          value="en"
          name="radio-button-demo"
          inputProps={{'aria-label': 'en'}}
        />{' '}
        <Label style={{fontSize: '16px'}} checked={selectedValue === 'en'}>
          en-US
        </Label>
      </p>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontFamily: 'Lato',
          fontSize: '16px',
          marginLeft: 15,
        }}>
        <GreenRadio
          checked={selectedValue === 'es'}
          onChange={handleChange}
          value="es"
          name="radio-button-demo"
          inputProps={{'aria-label': 'es'}}
        />{' '}
        <Label style={{fontSize: '16px'}} checked={selectedValue === 'es'}>
          es-ES
        </Label>
      </p>
    </div>
  );
}
