import {Dialog, Grid} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, {useEffect, useState} from 'react';
import Autocomplete from 'react-google-autocomplete';
import apiBackend from '~/services/apiaxios';
import {geocodeSearch} from '~/services/geocode';
import SelectCustomPlan from '~/views/RealState/components/SelectCustomPlan';
import fonts from '../../UI/fonts';
import {desencrypta} from '../../utils';
import DatePickerCustom from '../DatePickerCustom';
import {
  ButtonGreen,
  InternDiv,
  ModalResponsive,
  TitleGrid,
  TitleModal,
  TitlePage,
} from './styles';
import './styles.css';

import * as Sentry from '@sentry/react';

export default function ModalFilterBroker({
  open,
  setOpen,
  status_broker,
  plan,
  setPlan,
  dateStart,
  setDateStart,
  dateFinish,
  setDateFinish,
  callBackGetUsers,
  status,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  viewNorthLat,
  setViewNorthLat,
  viewNorthLng,
  setViewNorthLng,
  viewSouthLat,
  setViewSouthLat,
  viewSouthLng,
  setViewSouthLng,
}) {
  const [classGoogle, setClassGoogle] = useState('');
  const [digit, setDigit] = useState('');
  const [planList, setPlanList] = useState([]);
  const [choose, setChoose] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  function insert(place, number) {
    setChoose(place?.formatted_address);
    setDigit(place?.formatted_address);

    geocodeSearch({
      place_id: place?.place_id,
      setLatitude: setLatitude,
      setLongitude: setLongitude,
      setViewNorthLat: setViewNorthLat,
      setViewNorthLng: setViewNorthLng,
      setViewSouthLat: setViewSouthLat,
      setViewSouthLng: setViewSouthLng,
    });
  }

  function getPlansActive() {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get('v1/plan/plans_active?kind=seller', {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        console.log(response);
        setPlanList(response.data.plans);
      })
      .catch((e) => Sentry.captureException(e));
  }

  useEffect(() => {
    getPlansActive();
  }, []);

  useEffect(() => {
    if (digit && digit.length > 0) {
      setClassGoogle('value');
    } else {
      setClassGoogle('normal');
    }
  }, [digit]);

  function handleBlur(value) {
    if (choose && value !== choose) {
      setChoose('');
      setDigit('');
      setLatitude('');
      setLongitude('');
      setViewNorthLat('');
      setViewNorthLng('');
      setViewSouthLat('');
      setViewSouthLng('');
      setClassGoogle('normal');
    }
  }

  function renderGoogle() {
    return (
      <Autocomplete
        value={digit}
        id="input-auto"
        style={{
          marginBottom: '20px',
          fontFamily: !digit ? fonts.latoRegularItalic : fonts.latoRegular,
          fontSize: 16,
        }}
        onBlur={(event) => handleBlur(event.target.value)}
        onChange={(event) => {
          setDigit(event.target.value);
        }}
        onPlaceSelected={(place) => {
          insert(place, 1);
        }}
        placeholder={'Buscar por endereço'}
        types={['(regions)']}
        className={classGoogle}
      />
    );
  }

  return (
    <>
      <div>
        <Dialog
          maxWidth={'lg' || 'md' || 'sm' || 'xl' || 'xs'}
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <InternDiv>
            <TitleModal>
              <TitlePage>Filtrar Imobiliárias</TitlePage>{' '}
              <CloseIcon
                onClick={() => handleClose()}
                className={'iconClose'}
              />
            </TitleModal>
            <ModalResponsive>
              <div className="mg-bottom-40 ">
                <TitleGrid>Filtrar por endereço</TitleGrid>
                {renderGoogle()}
              </div>
              <div className="mg-bottom-40 ">
                <TitleGrid>Filtrar por Plano</TitleGrid>
                <SelectCustomPlan
                  placeholder="Selecione um plano"
                  items={planList}
                  setValue={setPlan}
                  value={plan}
                />
              </div>
              <TitleGrid>Filtrar por data</TitleGrid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <DatePickerCustom
                    currentValue={dateStart}
                    handleDateChange={setDateStart}
                    placeholder="Data inicial..."
                  />

                  {/* <InputText label="Tipo do imóvel" placeHolder="Digite o nome do usuário" /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <DatePickerCustom
                    currentValue={dateFinish}
                    handleDateChange={setDateFinish}
                    placeholder="Data inicial..."
                  />
                </Grid>
              </Grid>
              <ButtonGreen
                disabled={
                  (digit && !latitude) ||
                  (digit && !longitude) ||
                  (digit && !viewNorthLat) ||
                  (digit && !viewNorthLng) ||
                  (digit && !viewSouthLat) ||
                  (digit && !viewSouthLng)
                }
                onClick={(event) => {
                  handleClose();
                  callBackGetUsers();
                }}>
                Filtrar
              </ButtonGreen>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
