import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ModalDelete from '~/components/ModalDelete';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import {ButtonXML, LabelPublishStatic, LabelSoldStatic} from '../../styles';
import ManagerOptionsLead from '../ManagerOptions';
import {
  P,
  Results,
  RowClick,
  RowPhotoName,
  TableContainer,
  TitleBold,
  TitleBoldView,
} from './styles';
import './styles.css';
import imageDown from '../../../../assets/download.svg';
import errorXml from '~/services/docs/errorxml';

import * as Sentry from '@sentry/react';

const permitted_roles = ['5', '2'];
export default function TableClients({
  listUser,

  setInfo,
  information,

  page,
  setPage,
  getAllLeads,
}) {
  const userStore = useSelector((state) => state.user);
  const [sortName, setSortName] = useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortStatus, setSortStatus] = useState(false);
  const [sortDate, setSortDate] = useState(false);

  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');

  const [proibid, setProibid] = useState(false);

  const [planList, setPlanList] = useState([]);

  function getPlansActive() {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get(
        'v1/plan/plans_active?kind=seller',

        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        console.log(response);
        setPlanList(response.data.plans);
      })
      .catch((e) => Sentry.captureException(e));
  }

  React.useEffect(() => {
    getPlansActive();
  }, []);

  function NameSort() {
    let object = [...information];
    let value = !sortName;
    setSortName(!sortName);

    setSortType(false);
    setSortStatus(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function TypeUserSort() {
    let object = [...information];
    let value = !sortType;
    setSortType(!sortType);

    setSortName(false);
    setSortStatus(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a?.profile?.type_advertiser > b?.profile?.type_advertiser) {
          return -1;
        }
        if (a?.profile?.type_advertiser < b?.profile?.type_advertiser) {
          return 1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a?.profile?.type_advertiser > b?.profile?.type_advertiser) {
          return 1;
        }
        if (a?.profile?.type_advertiser < b?.profile?.type_advertiser) {
          return -1;
        }
        return 0;
      });
    }
    setInfo(object);
  }

  function DateSort() {
    let object = [...information];
    let value = !sortDate;
    setSortDate(!sortDate);

    setSortType(false);
    setSortName(false);
    setSortStatus(false);

    if (value) {
      object.sort((a, b) => {
        let d1 = Date.parse(a.created_at);
        let d2 = Date.parse(b.created_at);
        if (d1 > d2) {
          return 1;
        } else if (d1 < d2) {
          return -1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        let d1 = Date.parse(a.created_at);
        let d2 = Date.parse(b.created_at);
        if (d1 > d2) {
          return -1;
        } else if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function dates(info = '') {
    let date = new Date(info);

    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*  useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  return (
    <>
      <Results>{information?.length} resultados encontrados</Results>
      <TableContainer>
        <Grid
          className="tableMobile published"
          container
          spacing={3}
          style={{
            marginTop: 15,
            borderBottom: '2px solid #BFBFB2',
          }}>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => NameSort()}>
              <TitleBoldView>Nome da imobiliária</TitleBoldView>
              <span>{sortName ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <RowClick onClick={() => TypeUserSort()}>
              <TitleBoldView>E-mail</TitleBoldView>{' '}
              <span>{sortType ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => TypeUserSort()}>
              <TitleBoldView>Contato</TitleBoldView>{' '}
              <span>{sortType ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          {isPermitted() && (
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              lg={1}
              xl={1}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}>
              <TitleBoldView>Ações</TitleBoldView>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => DateSort()}>
              <TitleBoldView>Data de Criação</TitleBoldView>{' '}
              <span>{sortDate ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => DateSort()}>
              <TitleBoldView>Status</TitleBoldView>{' '}
              <span>{sortDate ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
        </Grid>

        {information?.map((info, index) => {
          return (
            <Grid
              key={index}
              style={{marginTop: '15px'}}
              container
              spacing={3}
              className="item__list">
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Nome da imobiliária</TitleBold>
                <RowPhotoName>{info?.profile?.enterprise}</RowPhotoName>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TitleBold>E-mail</TitleBold>
                <RowPhotoName>
                  {/* {info.name} {info.surname ?? ''} */}
                  {info?.email}
                </RowPhotoName>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Contato</TitleBold>
                {/* <P>{dates(info.created_at)}</P> */}
                <P> {info?.cellphone}</P>
              </Grid>
              {isPermitted() && (
                <Grid
                  className="buttonActions"
                  item
                  xs={12}
                  sm={6}
                  md={1}
                  lg={1}
                  xl={1}>
                  <TitleBold>Ações</TitleBold>
                  <ManagerOptionsLead
                    // getAllProperties={getAllProperties}
                    planList={planList}
                    getAllLeads={getAllLeads}
                    // getAllPropertiesPrice={getAllProperties}
                    info={info}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Cadastrado em</TitleBold>
                {dates(info?.created_at)}
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <div className="rows" style={{width: '100%', flexWrap: 'wrap'}}>
                  <LabelPublishStatic>Publicado</LabelPublishStatic>
                  {info?.xml?.error && (
                    <ButtonXML onClick={() => errorXml(info?.xml?.error, info)}>
                      <img alt="download" src={imageDown} width="20px" />
                    </ButtonXML>
                  )}
                </div>
              </Grid>
            </Grid>
          );
        })}
      </TableContainer>

      {isPermitted() && (
        <>
          <ModalDelete
            page={page}
            setPage={setPage}
            listUser={listUser}
            open={open}
            setOpen={setOpen}
            id={id}
            text={'Usuário'}
          />
        </>
      )}
    </>
  );
}
