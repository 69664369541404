import styled from 'styled-components';

import fonts from '~/UI/fonts';

export const Square = styled.div`
  width: 100%;
  min-height: 62px;
  border: 1px solid #d9d9d4;
  border-radius: 5px;
  padding: 18px 15px;
`;

export const TitleRent = styled.div`
  font-size: 14px;
  font-family: ${fonts.latoRegularBold};
`;

export const ValueRent = styled.div`
  font-size: 14px;
  font-family: ${fonts.latoRegular};

  &.mg-top-10 {
    margin-top: 10px;
  }
`;
