import {Divider, Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import MenuProfile from '~/components/Menu/MenuProfile';
import apiBackend from '~/services/apiaxios';
import currencyURL from '~/services/currency';
import {Image} from '../ListAds/styles';
import BoxProposal from '../Proposal/BoxProposal';
import {desencrypta, getRoute} from '../utils';
import {getMonthly, getMonthlyTax, getPrice} from '../utils/finance';
import AccordionIntentions from './_AccordionIntentions2';
import DetailsIntern from './Features/Intern';
import GeneralItens from './GeneralItens';
import InformationsAds from './InformationsAds';
import OpenWithMaps from './OpenWithMaps';
import MainCarousel from './MainCarousel';
import CameraIcon from '../../../../assets/camera_white.svg';
import CostAndRent from './Rent';
import SkeletonShow from './Skeleton';

import * as Sentry from '@sentry/react';

import DefaultPropertyImg from '~/assets/default_property_img.jfif';
import {
  ImageShowG,
  ImageShowM,
  Price,
  PriceAlternative,
  TitleAd,
} from './styles';
import './styles.css';

export default function ShowAdsSpecific(props) {
  const {id} = props.match.params;

  /* ---- DATA ---- */
  const [currency, setCurrencyValues] = useState('');

  const [prices, setPrices] = useState([]);
  const [montly, setMontly] = useState(['', '', '']);
  const [montlytax, setMontlyTax] = useState(['', '', '']);
  const [advertise, setAdvertise] = useState({});
  const [typeUser, setTypeUser] = useState('');

  const [keyActual, setKeyActual] = useState('1');
  const [load, setLoad] = useState(false);
  const [photos, setPhotos] = useState('');
  const [openModalProposal, setOpenModalProposal] = useState(false);
  const [loginToken, setLoginToken] = useState(
    localStorage.getItem('@User_informations'),
  );
  const [langName, setLangName] = useState('pt');
  const [checkAble, setCheckAble] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current, next) => setKeyActual(current + 1),
    beforeChange: (current, next) => setKeyActual(current + 1),
  };

  async function getCurrency() {
    /* TODO coin */
    fetch(`${currencyURL}`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrencyValues(data);
        getProperties(data);
      })
      .catch((e) => {
        if (e.status === 401) {
          console.log(e);
        } else {
          Sentry.captureException(e);
        }
      });
  }

  useEffect(() => {
    getCurrency();
    /* alert('ATENÇÃO PÁGINA EM DESENVOLVIMENTO'); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (load);
  }, [load]);

  function listPhotos(photos) {
    setPhotos(photos);
  }

  async function getProperties(currency) {
    apiBackend
      .get(`/v1/property/${id}`)
      .then((response) => {
        console.log(response);
        console.log(response?.data?.type_advertiser);
        setAdvertise(response?.data?.property);
        setTypeUser(response?.data?.type_advertiser);
        setLoad(true);
        let token = '';
        if (localStorage.getItem('@User_informations')) {
          token = desencrypta(localStorage.getItem('@User_informations'));
        }

        // if (
        //   response.data.property.user_id.toString() ===
        //   localStorage.getItem('id')
        // ) {
        //   console.log('é igual');
        //   setCheckId(true);
        // } else {
        //   setCheckId(false);
        if (token) {
          getCheckAble(token);
        } else {
          setCheckAble(true);
        }

        setPrices(getPrice(response.data.property, currency, 'view'));
        setMontly(getMonthly(response.data.property, currency, 'view'));
        setMontlyTax(getMonthlyTax(response.data.property, currency, 'view'));
        listPhotos(response.data.property.photos);
      })
      .catch((e) => Sentry.captureException(e));
  }

  async function getCheckAble(token) {
    console.log('able');
    console.log(id);
    apiBackend
      .get(`/v1/proposal/${id}/check`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        console.log(response);
        setCheckAble(true);
        setLoad(true);
      })
      .catch((e) => Sentry.captureException(e));
  }

  /* -------------------------------------- */
  /* --------- Carrossel 20 fotos --------- */
  /* -------------------------------------- */
  const [carouselPhotosVisible, setCarouselPhotosVisible] =
    React.useState(false);

  const [photoIndex, setPhotoIndex] = React.useState(null);

  const toggleCarouselPhotosVisibility = React.useCallback(
    () => setCarouselPhotosVisible(!carouselPhotosVisible),
    [carouselPhotosVisible],
  );

  const handleClickViewPhoto = (index) => {
    setPhotoIndex(index);
    toggleCarouselPhotosVisibility();
  };

  return (
    <>
      <MenuProfile />

      {checkAble ? (
        <>
          <div className={`visible container__mw_preview`}>
            <Grid container justify="center" /* className="centerItens" */>
              <Grid item xl={2} lg={1} md={1}></Grid>
              <Grid
                item
                xl={11}
                lg={10}
                md={10}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '30px',
                }}>
                <Grid
                  xs={6}
                  className="c-pointer hover-image-filter"
                  onClick={() => handleClickViewPhoto(0)}>
                  <ImageShowG
                    src={advertise?.photos[0]?.image_url ?? DefaultPropertyImg}
                  />
                </Grid>
                <Grid xs={3}>
                  <Grid
                    xs={12}
                    className="c-pointer hover-image-filter"
                    onClick={() => handleClickViewPhoto(1)}>
                    <ImageShowM
                      src={
                        advertise?.photos[1]?.image_url ?? DefaultPropertyImg
                      }
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    style={{marginTop: '4px'}}
                    className="c-pointer hover-image-filter"
                    onClick={() => handleClickViewPhoto(2)}>
                    <ImageShowM
                      src={
                        advertise?.photos[2]?.image_url ?? DefaultPropertyImg
                      }
                    />
                  </Grid>
                </Grid>
                <Grid style={{marginLeft: '2px'}} xs={3}>
                  <Grid
                    xs={12}
                    className="c-pointer hover-image-filter"
                    onClick={() => handleClickViewPhoto(3)}>
                    <ImageShowM
                      className="borderTopRigth"
                      src={
                        advertise?.photos[3]?.image_url ?? DefaultPropertyImg
                      }
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    className="c-pointer hover-image-filter relative-box"
                    onClick={() => handleClickViewPhoto(4)}>
                    <ImageShowM
                      style={{marginTop: '4px'}}
                      className="borderBottomRigth"
                      src={
                        advertise?.photos[4]?.image_url ?? DefaultPropertyImg
                      }
                    />
                    <button>
                      <img src={CameraIcon} alt="cam" />
                      {advertise?.photos?.length}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={2} lg={1} md={1}></Grid>
            </Grid>
          </div>
          <div className={`mobile container__mw_preview`}>
            <Grid
              container
              style={{
                marginBottom: 10,
                display: 'flex',
                position: 'relative',
                zIndex: 200,
              }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{position: 'relative'}}>
                <Slider {...settings}>
                  {advertise?.photos?.length > 0 ? (
                    advertise?.photos?.map((img, key) => {
                      return (
                        <div
                          key={key}
                          id="mobile__slider__preview"
                          className="photoSize">
                          <Image src={img?.image_url} />
                        </div>
                      );
                    })
                  ) : (
                    <div
                      key="default_image_ads"
                      id="mobile__slider__preview"
                      className="photoSize">
                      <Image src={DefaultPropertyImg} />
                    </div>
                  )}
                </Slider>
                <div className="mobileNumber">
                  <img src={CameraIcon} alt="cam" />
                  <span>{`${advertise?.photos?.length > 0 ? keyActual : 0}/${
                    advertise?.photos.length
                  }`}</span>
                </div>
              </Grid>
            </Grid>
          </div>{' '}
          <Grid
            container
            className="centerItens container__mw_preview pd-bottom-responsive">
            <Grid
              container
              xl={11}
              lg={10}
              md={10}
              sm={10}
              xs={10}
              spacing="2"
              justify="center"
              style={{}}>
              <Grid
                style={{marginTop: '30px'}}
                item
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={12}>
                <TitleAd>
                  <>
                    <span>
                      {Object.keys(advertise?.type_property).length
                        ? advertise?.type_property[`name_` + langName]
                        : 'Imóvel'}{' '}
                      em{' '}
                      {[
                        advertise?.address?.neighborhood,
                        advertise?.address?.city,
                        advertise?.address?.state,
                        advertise?.address?.country,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                      ,{' '}
                      {advertise?.intention?.length > 0
                        ? 'tem interesse em trocar por'
                        : 'troca por'}
                    </span>
                  </>
                </TitleAd>
                <div className="mg-top-20">
                  <AccordionIntentions
                    currency={currency}
                    langName={langName}
                    advertise={advertise}
                  />
                </div>
                <div className="mg-top-30" id="visiblePrice">
                  <Price>
                    {' '}
                    {prices !== 'Não Definido' ? (
                      prices[0]
                    ) : (
                      <>
                        undefined
                        {/* <I18n t="ViewAds.Undefined" /> */}
                      </>
                    )}{' '}
                  </Price>{' '}
                  <div className="rows  mg-top-10 mg-bottom-20 price-wrap">
                    <span className="mg-right-20">
                      <PriceAlternative>
                        {' '}
                        {prices !== 'Não Definido' ? (
                          prices[1]
                        ) : (
                          <>
                            undefined
                            {/* <I18n t="ViewAds.Undefined" /> */}
                          </>
                        )}{' '}
                      </PriceAlternative>{' '}
                    </span>{' '}
                    <span>
                      <PriceAlternative>
                        {' '}
                        {prices !== 'Não Definido' ? (
                          prices[2]
                        ) : (
                          <>
                            undefined
                            {/* <I18n t="ViewAds.Undefined" /> */}
                          </>
                        )}{' '}
                      </PriceAlternative>{' '}
                    </span>{' '}
                  </div>{' '}
                </div>
                <GeneralItens advertise={advertise} />
                <div className="mg-top-30 mg-bottom-40">
                  <Divider />
                </div>
                <OpenWithMaps langName={langName} advertise={advertise} />
                <InformationsAds langName={langName} advertise={advertise} />
                {(advertise.internalProperty.length > 0 ||
                  advertise.externalProperty.length > 0) && (
                  <>
                    <div className="mg-bottom-30">
                      <DetailsIntern
                        langName={langName}
                        advertise={advertise}
                      />
                    </div>
                    <div className="mg-top-30 mg-bottom-30">
                      <Divider />
                    </div>
                  </>
                )}
                <CostAndRent advertise={advertise} />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <BoxProposal
                  reloadAds={getCurrency}
                  langName={langName}
                  typeUser={typeUser}
                  advertise={advertise}
                  prices={prices}
                  id={id}
                  getAllPropertiesPrice={getCurrency}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className="container__mw_preview mg-top-40" container></Grid>
        </>
      ) : (
        <div className="container__mw_preview">
          <SkeletonShow />
        </div>
      )}
      {advertise?.photos?.length > 0 && (
        <MainCarousel
          open={carouselPhotosVisible}
          setOpen={toggleCarouselPhotosVisibility}
          slides={advertise.photos}
          slideIndex={photoIndex}
        />
      )}
    </>
  );
  // return <div></div>;
}
