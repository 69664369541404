import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import {Container} from './styles';
import * as S from '../../Register/styles';

function AddressInput({
  value,
  setValue,
  error,
  touched,
  label,
  placeholder,
  optional,
  setLat,
  setLong,
  setNeighborhood,
  setCity,
  setUf,
  setCountry,
  setCep,
}) {
  function insert(place, number) {
    console.log(place);
    if (place?.geometry?.location?.lat() && place?.geometry?.location?.lng()) {
      setLat(place?.geometry?.location?.lat());
      setLong(place?.geometry?.location?.lng());
    }

    if (number === 1) {
      if (place.address_components) {
        place.address_components.forEach((item) => {
          if (item.types[0] === 'route') {
            setValue(item.long_name);
          } else if (item.types[0] === 'sublocality_level_1') {
            setNeighborhood(item.long_name);
          } else if (
            item.types[0] === 'administrative_area_level_2' ||
            item.types[0] === 'locality'
          ) {
            setCity(item.long_name);
          } else if (item.types[0] === 'administrative_area_level_1') {
            setUf(item.short_name);
          } else if (
            item.types[0] === 'postal_code' ||
            item.types[0] === 'postal_code_prefix'
          ) {
            setCep(item.long_name);
          } else {
          }
        });
      }
    }
  }

  return (
    <S.DivInputText>
      <S.Rotule error={error} valid={value}>
        {label} {!optional && '*'}
      </S.Rotule>
      <Container error={error} value={value}>
        <Autocomplete
          value={value}
          id="input-auto"
          style={{marginBottom: '20px', fontFamily: 'Lato Regular'}}
          onChange={(event) => setValue(event.target.value)}
          onPlaceSelected={(place) => {
            setValue('');
            insert(place, 1);
          }}
          placeholder="Informe o endereço"
          types={['address']}
          className={'input-localization'}
          // componentRestrictions={{country: 'Br'}}
        />
      </Container>
      {error && (
        <S.DivError>
          <S.ErrorMessage>{error}</S.ErrorMessage>
        </S.DivError>
      )}
    </S.DivInputText>
  );
}

export default AddressInput;
