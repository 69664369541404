import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {Icons, Container} from './styles';

import ModalEdit from '../../../components/ModalEdit';
import ModalDeleteAds from '../../../components/ModalDeleteAds';
import ModalPrice from '../../../components/ModalPrice';

import colors from '../../../UI/colors';
import fonts from '../../../UI/fonts';

import dollarImg from '~/assets/dollar-sign.svg';
import editImg from '~/assets/edit.svg';
import deleteImg from '~/assets/delete.svg';
import cameraImg from '~/assets/camera.svg';

const styleItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  fontFamily: fonts.latoRegularBold,
  color: colors.dark,
};

const svgStyle = {
  color: colors.green_secondary,
  marginRight: 10,
  width: 26,
  height: 26,
  flex: 'none',
};

export default function ManagerOptions({
  info,
  getAllProperties,
  getAllPropertiesPrice,
}) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);

  /* --- MODALS --- */
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalPrice, setModalPrice] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Container>
        <Icons onClick={handleClick} isOpen={Boolean(anchorEl)}>
          <MoreHorizIcon />
        </Icons>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setOpenCollapse(!openCollapse);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <MenuItem
            style={styleItem}
            onClick={() => {
              setModalEdit(true);
              setAnchorEl(null);
            }}>
            <img src={editImg} alt="Editar" style={svgStyle} /> Editar anúncio
          </MenuItem>

          <Link
            style={{textDecoration: 'none'}}
            to={{
              pathname: `/view/edit`,
              state: {id: info.id, step: '1', property: info},
            }}>
            <MenuItem
              style={styleItem}
              onClick={() => {
                // handleEditPhotos(item);
                setAnchorEl(null);
              }}>
              <img src={cameraImg} alt="Fotos" style={svgStyle} /> Gerenciar
              fotos
            </MenuItem>
          </Link>

          <MenuItem
            style={styleItem}
            onClick={() => {
              setModalPrice(true);
              setAnchorEl(null);
            }}>
            <img src={dollarImg} alt="Valor" style={svgStyle} /> Alterar valor
          </MenuItem>
          <MenuItem
            style={styleItem}
            onClick={() => {
              setModalDelete(true);
              setAnchorEl(null);
            }}>
            <img src={deleteImg} alt="Deletar" style={svgStyle} /> Excluir
            anúncio
          </MenuItem>
        </Popover>
      </Container>
      {/* ---- MODAIS ---- */}
      <ModalEdit infoAd={info} open={modalEdit} setOpen={setModalEdit} />

      <ModalDeleteAds
        inf={info}
        getAllProperties={getAllProperties}
        open={modalDelete}
        setOpen={setModalDelete}
      />
      <ModalPrice
        info={info}
        id={info.id}
        priceInfo={info.price}
        getAllProperties={getAllPropertiesPrice}
        open={modalPrice}
        setOpen={setModalPrice}
        currency={info.price_currency}
      />
    </>
  );
}
