import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import {Label, Item, Container} from './styles';
import './styles.css';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButtons({selectedValue, setSelectedValue}) {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Container>
      <Item>
        <GreenRadio
          checked={selectedValue === 'Owner'}
          onChange={handleChange}
          value="Owner"
          name="radio-button-demo"
          inputProps={{'aria-label': 'Owner'}}
        />{' '}
        <Label checked={selectedValue === 'Owner'}>Proprietário</Label>
      </Item>
      <Item>
        <GreenRadio
          checked={selectedValue === 'Agent'}
          onChange={handleChange}
          value="Agent"
          name="radio-button-demo"
          inputProps={{'aria-label': 'Agent'}}
        />{' '}
        <Label checked={selectedValue === 'Agent'}>Corretor</Label>
      </Item>
    </Container>
  );
}
