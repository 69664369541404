export default {
  /* ------------------------------ */
  /* ------------ SIZES ----------- */
  /* ------------------------------ */

  bigger: '30px',
  bigs: '18px',
  bigf: '17px',
  regular: '14px',
  small: '12px',
  smaller: '11px',

  /* ------------------------------ */
  /* -------- LATO FAMILY --------- */
  /* ------------------------------ */

  /* ----- Bold ----- */
  latoBold: 'Lato Bold',

  /* ----- Thin ----- */
  latoThin: 'Lato Thin',
  latoThinItalic: 'Lato Thin Italic',

  /* ----- Medium ----- */
  latoMedium: 'Lato Medium',

  /* ----- Light ----- */
  latoLight: 'Lato Light',
  latoLightItalic: 'Lato Light Italic',

  /* ----- Regular ----- */
  latoRegular: 'Lato Regular',
  latoRegularItalic: 'Lato Regular Italic',
  latoRegularBold: 'Lato Regular Bold',
  latoRegularBoldItalic: 'Lato Regular Bold Italic',

  /* ----- Black ----- */
  latoBlack: 'Lato Black',
  latoBlackItalic: 'Lato Black Italic',

  /* ------------------------------ */
  /* ------- ROBOTO FAMILY -------- */
  /* ------------------------------ */

  /* ----- Thin ----- */
  robotoThin: 'Roboto Thin',
  robotoThinItalic: 'Roboto Thin Italic',

  /* ----- Light ----- */
  robotoLight: 'Roboto Light',
  robotoLightItalic: 'Roboto Light Italic',

  /* ----- Regular ----- */
  robotoRegular: 'Roboto Regular',
  robotoRegularItalic: 'Roboto Regular Italic',
  robotoRegularBold: 'Roboto Regular Bold',
  robotoRegularBoldItalic: 'Roboto Regular Bold Italic',

  /* ----- Medium ----- */
  robotoMedium: 'Roboto Medium',
  robotoMediumItalic: 'Roboto Medium Italic',

  /*----- Lato Healvy */
  latoHealvy: 'Lato Healvy',

  /* ----- Black ----- */
  robotoBlack: 'Roboto Black',
  robotoBlackItalic: 'Roboto Black Italic',
};
