import React, {useState, useEffect} from 'react';
import {useEmblaCarousel} from 'embla-carousel/react';

import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import './styles.css';
import * as S from './styles';

function MainCarousel({open, setOpen, slides, slideIndex}) {
  /* ----------- CONTROL SLIDE ----------- */
  /* ------------------------------------- */

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [nextSlide, setNextSlide] = useState(true);
  const [prevSlide, setPrevSlide] = useState(true);
  const [mainRef, mainApi] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    align: 'center',
    speed: 7,
  });

  useEffect(() => {
    if (!mainApi) return;
    console.log('reiniciou');
    mainApi.reInit();
  }, [mainApi, open]);

  /* --- PREV AND NEXT --- */
  const prevSlideAction = React.useCallback(
    () => mainApi && mainApi.scrollPrev(),
    [mainApi],
  );
  const nextSlideAction = React.useCallback(
    () => mainApi && mainApi.scrollNext(),
    [mainApi],
  );

  /* --- ON CHANGE --- */
  const onSelectSlide = React.useCallback(() => {
    if (!mainApi) return;
    setPrevSlide(!mainApi.canScrollPrev());
    setNextSlide(!mainApi.canScrollNext());
    setSelectedIndex(mainApi.selectedScrollSnap());
  }, [mainApi, slideIndex]);

  useEffect(() => {
    if (!mainApi) return;
    mainApi.on('select', onSelectSlide);
    onSelectSlide();
  }, [mainApi, onSelectSlide]);

  /* --- SCROLL TO --- */
  useEffect(() => {
    if (!mainApi) return;
    console.log(slideIndex);
    mainApi.scrollTo(slideIndex !== null ? slideIndex : 0);
  }, [mainApi, slideIndex]);

  return (
    <S.Container visible={open}>
      <S.ModalContainer>
        <header className="controls">
          <button onClick={setOpen}>
            <CloseIcon />
          </button>
          <span>
            {selectedIndex + 1} / {slides?.length}
          </span>
        </header>
        <S.CarouselContainer>
          {slides?.length && (
            <div
              className="embla-slide-fullscreen"
              id="embla-slide-fullscreen"
              ref={mainRef}>
              <div className="embla__container">
                {slides?.map((item, index) => {
                  return (
                    <div
                      key={`slide-${index}-ID-${item.id}`}
                      className="embla__slide">
                      <S.SliderItem
                        index={index}
                        key={`image-IN-${index}-ID-${item.id}`}>
                        <img src={item.image_url} alt="Thumbnail" />
                      </S.SliderItem>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <S.Controls>
            <button onClick={prevSlideAction} disabled={prevSlide}>
              <ChevronLeftIcon />
            </button>
            <button onClick={nextSlideAction} disabled={nextSlide}>
              <ChevronRightIcon />
            </button>
          </S.Controls>
        </S.CarouselContainer>
      </S.ModalContainer>
    </S.Container>
  );
}

export default MainCarousel;
