import React, {useEffect, useState, useCallback} from 'react';

import ReactLoading from 'react-loading';
import {Dropzone} from '~/components/Dropzone.js';

import colors from '~/UI/colors';

import {
  TitleModal,
  SpanText,
  DivInfo,
  PName,
  PEdit,
  PType,
  DivCards,
  TextCards,
  Card,
  InternCard,
  SpanCard,
  InfosIntern,
} from '../../styles';
import {
  InternDiv,
  AvatarContainer,
  SpinnerLoading,
  ImgUserG,
  InfoUser,
} from './styles';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import logo from '../../../../assets/iconPerson.jpg';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import {Dialog} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/Email';
import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import ModalDataPersonal from './ModalDataPersonal';
import ModalDataEnterprise from './ModalDataEnterprise';
import ModalContact from './ModalContact';
import ModalAddress from './ModalAddress';
import ModalSocial from './ModalSocial';

import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {desencrypta} from '~/utils';
import {translateProfileType} from '~/utils/profileType';

import * as Sentry from '@sentry/react';

export default function ModalEdit({
  open,
  setOpen,
  profile,
  setProfile,
  page,
  listUser,
}) {
  const handleClose = () => {
    if (reqCount > 0) {
      listUser(page);
    }

    setProfile({});
    setReqCount(0);
    setOpen(false);
  };

  const [reqCount, setReqCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user, setUser] = useState({});
  const [profileImg, setProfileImg] = useState('');
  const [openModalPersonal, setOpenModalPersonal] = useState(false);
  const [openModalEnterprise, setOpenModalEnterprise] = useState(false);
  const [openModalContact, setOpenModalContact] = useState(false);
  const [openModalAddress, setOpenModalAddress] = useState(false);
  const [openModalSocial, setOpenModalSocial] = useState(false);

  useEffect(() => {
    async function getProfileData() {
      try {
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.get(`/v1/users/${profile.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(data?.user);
        console.log(data);
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    if (profile?.id) {
      getProfileData();
    }
  }, [profile]);

  /* ------------------------- */
  /* ------- PERSONAL -------- */
  /* ------------------------- */
  const handleEditPersonalData = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);

        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.put(`/v1/users/${user?.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUser({
          ...data?.user,
          profile: {
            ...data?.profile,
          },
        });
        setReqCount((req) => req + 1);
        fireAction(
          'Tudo certo!',
          'Os dados pessoais do usuário foram atualizados com sucesso.',
          'success',
        );

        setOpenModalPersonal();
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação.',
          'Um erro ocorreu durante a atualização dos dados pessoais do usuário. Por favor, tente novamente mais tarde.',
          'error',
        );
        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [user],
  );

  /* ------------------------- */
  /* ------ Enterprise ------- */
  /* ------------------------- */
  const handleEditEnterpriseData = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);

        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.put(`/v1/users/${user?.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(data);

        setUser({
          ...data?.user,
          profile: {
            ...data?.profile,
          },
        });
        setReqCount((req) => req + 1);
        fireAction(
          'Tudo certo!',
          'Os dados empresariais do usuário foram atualizados com sucesso.',
          'success',
        );

        setOpenModalEnterprise();
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação.',
          'Um erro ocorreu durante a atualização dos dados empresariais do usuário. Por favor, tente novamente mais tarde.',
          'error',
        );
        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [user],
  );

  /* ------------------------- */
  /* -------- Contact -------- */
  /* ------------------------- */
  const handleEditContactData = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);

        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.put(`/v1/users/${user?.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(data);

        setUser({
          ...data?.user,
          profile: {
            ...data?.profile,
          },
        });
        setReqCount((req) => req + 1);
        fireAction(
          'Tudo certo!',
          'Os dados de contato do usuário foram atualizados com sucesso.',
          'success',
        );

        setOpenModalContact();
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação.',
          'Um erro ocorreu durante a atualização dos dados de contato do usuário. Por favor, tente novamente mais tarde.',
          'error',
        );
        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [user],
  );

  /* ------------------------- */
  /* -------- Address -------- */
  /* ------------------------- */
  const handleEditAddressData = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);

        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.put(`/v1/users/${user?.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(data);

        setUser({
          ...data?.user,
          profile: {
            ...data?.profile,
          },
        });
        setReqCount((req) => req + 1);
        fireAction(
          'Tudo certo!',
          'Os dados de endereço do usuário foram atualizados com sucesso.',
          'success',
        );

        setOpenModalAddress();
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação.',
          'Um erro ocorreu durante a atualização dos dados de endereço do usuário. Por favor, tente novamente mais tarde.',
          'error',
        );
        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [user],
  );

  /* ------------------------- */
  /* -------- Social --------- */
  /* ------------------------- */
  const handleEditSocialData = useCallback(
    async (values) => {
      try {
        setIsSubmitting(true);

        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.put(`/v1/users/${user?.id}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(data);

        setUser({
          ...data?.user,
          profile: {
            ...data?.profile,
          },
        });
        setReqCount((req) => req + 1);
        fireAction(
          'Tudo certo!',
          'Os dados de redes sociais do usuário foram atualizados com sucesso.',
          'success',
        );

        setOpenModalSocial();
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação.',
          'Um erro ocorreu durante a atualização dos dados de redes sociais do usuário. Por favor, tente novamente mais tarde.',
          'error',
        );
        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [user],
  );

  /* -------------------------------- */
  /* -------- Avatar Upload --------- */
  /* -------------------------------- */
  const [hasUploading, setHasUploading] = useState(false);
  const uploadNewAvatar = useCallback(
    async (avatar) => {
      try {
        setHasUploading(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const payload = new FormData();
        payload.append('avatar', avatar);

        const {data} = await apiBackend.post(
          `/v1/users/${user?.id}/avatar`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setUser((user) => {
          return {
            ...user,
            avatar: {
              avatar_url: data?.avatar_url,
            },
          };
        });
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante seu pedido. Por favor, tente novamente mais tarde.',
          'error',
        );
        console.log(err);
      } finally {
        setProfileImg('');
        setHasUploading(false);
      }
    },
    [user],
  );

  useEffect(() => {
    async function uploadNewAvatar() {
      try {
        setHasUploading(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const payload = new FormData();
        payload.append('avatar', profileImg);

        const {data} = await apiBackend.put(
          `/v1/users/${user?.id}/avatar`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setUser((user) => {
          return {
            ...user,
            avatar: {
              avatar_url: data?.avatar_url,
            },
          };
        });
        setProfileImg('');
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante seu pedido. Por favor, tente novamente mais tarde.',
          'error',
        );
        setProfileImg('');
        console.log(err);
      } finally {
        setHasUploading(false);
      }
    }

    if (profileImg) {
      uploadNewAvatar(profileImg);
    }
  }, [user?.id, profileImg]);

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span>Editar Usuário</span>
            <CloseIcon onClick={handleClose} className={'iconClose'} />
          </TitleModal>
          {Object.entries(user).length > 0 ? (
            <>
              <InternDiv>
                <InfoUser>
                  <AvatarContainer hasUploading={hasUploading}>
                    {hasUploading && <SpinnerLoading />}
                    <ImgUserG
                      src={
                        profileImg
                          ? URL.createObjectURL(profileImg)
                          : user?.avatar?.avatar_url
                          ? user?.avatar?.avatar_url
                          : logo
                      }
                    />
                  </AvatarContainer>

                  <Dropzone
                    disabled={hasUploading}
                    oneFile
                    state={profileImg}
                    setState={setProfileImg}>
                    Editar foto do perfil
                  </Dropzone>

                  <DivInfo style={{minHeight: '38px', height: '100%'}}>
                    <PName>
                      {user?.name ?? ''} {user?.surname ?? ''}
                    </PName>
                  </DivInfo>
                  <DivInfo>
                    <PType>
                      {translateProfileType(user?.profile?.type_advertiser)}
                    </PType>
                  </DivInfo>
                </InfoUser>
                <DivCards>
                  <TextCards style={{marginLeft: '20px'}}></TextCards>
                  <Card
                    onClick={() => {
                      setOpenModalPersonal(true);
                    }}>
                    <InternCard>
                      <PersonIcon /> <SpanCard>Dados Pessoais</SpanCard>
                    </InternCard>
                    <InfosIntern>Nome, Sobrenome, CPF, Cargo.</InfosIntern>
                  </Card>

                  {user?.profile?.type_advertiser?.toLowerCase() ===
                    'agent' && (
                    <Card
                      onClick={() => {
                        setOpenModalEnterprise(true);
                      }}>
                      <InternCard>
                        <GroupIcon /> <SpanCard>Dados da Empresa</SpanCard>
                      </InternCard>
                      <InfosIntern>
                        Razão Social, Empresa, CNPJ e Licença.
                      </InfosIntern>
                    </Card>
                  )}

                  <Card
                    onClick={() => {
                      setOpenModalContact(true);
                    }}>
                    <InternCard>
                      <EmailIcon /> <SpanCard>Contato</SpanCard>
                    </InternCard>
                    <InfosIntern>
                      E-mail, Celular, WhatsApp e Telefone
                    </InfosIntern>
                  </Card>
                  <Card
                    onClick={() => {
                      setOpenModalAddress(true);
                    }}>
                    <InternCard>
                      <RoomIcon /> <SpanCard>Endereço</SpanCard>
                    </InternCard>
                    <InfosIntern>
                      CEP, Endereço, Bairro, Cidade, Estado, País
                    </InfosIntern>
                  </Card>
                  <Card
                    onClick={() => {
                      setOpenModalSocial(true);
                    }}>
                    <InternCard>
                      <LanguageIcon /> <SpanCard>Redes Sociais</SpanCard>
                    </InternCard>
                    <InfosIntern>
                      Website, Instagram, Facebook, Linkedin...
                    </InfosIntern>
                  </Card>
                </DivCards>
              </InternDiv>
            </>
          ) : (
            <InternDiv>
              <InfoUser>
                <div
                  style={{
                    minHeight: 200,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <ReactLoading
                    type={'spin'}
                    className="load"
                    color={colors.green_secondary}
                    width={'30px'}
                  />
                </div>
              </InfoUser>
            </InternDiv>
          )}
        </Dialog>
      </div>
      {/* Modals */}
      {Object.entries(user).length > 0 && (
        <>
          <ModalDataPersonal
            listUser={listUser}
            open={openModalPersonal}
            setOpen={setOpenModalPersonal}
            /* ---- */
            profile={user}
            handleSubmit={handleEditPersonalData}
            loading={isSubmitting}
          />
          <ModalDataEnterprise
            listUser={listUser}
            open={openModalEnterprise}
            setOpen={setOpenModalEnterprise}
            /* ---- */
            profile={user}
            handleSubmit={handleEditEnterpriseData}
            loading={isSubmitting}
          />
          <ModalContact
            listUser={listUser}
            open={openModalContact}
            setOpen={setOpenModalContact}
            /* ---- */
            profile={user}
            handleSubmit={handleEditContactData}
            loading={isSubmitting}
          />
          <ModalAddress
            listUser={listUser}
            open={openModalAddress}
            setOpen={setOpenModalAddress}
            /* ---- */
            profile={user}
            handleSubmit={handleEditAddressData}
            loading={isSubmitting}
          />
          <ModalSocial
            listUser={listUser}
            open={openModalSocial}
            setOpen={setOpenModalSocial}
            /* ---- */
            profile={user}
            handleSubmit={handleEditSocialData}
            loading={isSubmitting}
          />
        </>
      )}
    </>
  );
}
