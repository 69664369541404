import React, {useEffect, useState} from 'react';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~/utils';
// import apiBackend from '~/services/apiaxios';

// import {desencrypta, getRoute} from '../../../utils';
import MyAds404 from '../404';
import ApprovedCardComponet from '../Approved/card';
import DissaprovedCardComponet from '../Dissaproved/card';
import MyAdsLoading from '../Loading';
import PendingCardComponet from '../Pending/card';

// import { Container } from './styles';

function All({chooseAddress, seeProposal, query}) {
  const [proporsal, setProposal] = useState([]);
  const [langName, setLangName] = useState('en');
  const [currencyValues, setCurrencyValues] = useState('');
  const [view404, setView404] = useState(false);
  const [hasLoading, setHasLoading] = useState(true);
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/login`,
    state: {fromDashboard: true},
  });

  /* */

  useEffect(() => {
    setProposal([]);
    getProperties();
  }, [chooseAddress, seeProposal, query]);

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });

    let suffix = getRoute();
    setLangName(suffix);
  }, [window.location.href]);

  function getProperties() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    let id = localStorage.getItem('id');
    setHasLoading(true);
    setView404(false);
    apiBackend
      .get(
        `/v1/proposal/?property_id=${chooseAddress && chooseAddress?.id}${
          query.toString() !== '' ? '&' + query : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        setProposal(response?.data?.proposals);
        if (response?.data?.proposals?.length === 0) {
          setView404(true);
        } else {
          setView404(false);
        }
      })
      .finally(() => {
        setHasLoading(false);
      });
  }
  function withStatus(item) {
    switch (item?.status) {
      case 'pending':
        return (
          <PendingCardComponet
            item={item}
            langName={langName}
            location={location}
            seeProposal={seeProposal}
          />
        );
      case 'accept':
        return (
          <ApprovedCardComponet
            item={item}
            langName={langName}
            location={location}
            seeProposal={seeProposal}
          />
        );
      case 'refused':
        return (
          <DissaprovedCardComponet
            item={item}
            langName={langName}
            location={location}
            seeProposal={seeProposal}
          />
        );

      default:
    }
  }

  return (
    <>
      {proporsal.map((item) => withStatus(item))}
      {view404 && <MyAds404 />}
      {hasLoading && <MyAdsLoading />}
    </>
  );
}

export default All;
