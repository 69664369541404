import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import {Link} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from '@material-ui/core';
import colors from '~/UI/colors';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Pagination from '~/components/_PaginationTab';
import * as Sentry from '@sentry/react';

import {
  Container,
  InternDiv,
  TitleModal,
  TitlePage,
  Hero,
  AdsList,
  SafeAreaCard,
  SafeAreaInfo,
  Card,
  LoadingIndicator,
  ContainerPagination,
} from './styles';
import {makeStyles} from '@material-ui/core';

import {getAdress, getPrice} from '~/views/Annoucement/View/utils/finance';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import {translateProfileType} from '~/utils/profileType';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '600px!important',
    width: '100%!important',
    marginLeft: '0!important',
    marginRight: '0!important',
  },
}));

export default function ModalAdsForUser({
  open,
  setOpen,
  user,
  setUser,
  currency,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const handleClear = () => {
    setUser('');
    setPage(1);
    setLastPage(1);
    setProperties([]);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  useEffect(() => {
    async function getPropertiesOfSub() {
      try {
        setLoading(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.get(
          `/v1/property?user_id=${user?.id}&status=approved&paginate=true&page=${page}&limit=6`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        console.log(data);
        setProperties(data?.property?.data);
        setLastPage(data?.property?.meta?.last_page);
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    }
    if (open) {
      getPropertiesOfSub();
      console.log(user);
    }
  }, [open, user, page]);

  const handleChangePage = (value) => {
    /*   console.log(value); */
    setPage(value);
  };

  return (
    <div>
      <Dialog
        scroll="body"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{paper: classes.paper}}>
        <Container>
          <InternDiv>
            <TitleModal>
              <TitlePage>Lista de anúncios</TitlePage>{' '}
              <CloseIcon onClick={handleClose} className={'iconClose'} />
            </TitleModal>
            <Hero>
              <span>
                {translateProfileType(user?.profile?.type_advertiser)}
              </span>
              <p>
                {user?.profile?.type_advertiser?.toLowerCase() === 'broker' ? (
                  <>
                    {user?.profile?.enterprise ??
                      `${user?.name} ${user?.surname ?? ''}`}
                  </>
                ) : (
                  <>
                    {user?.name} {user?.surname ?? ''}
                  </>
                )}
              </p>
            </Hero>
          </InternDiv>
          {loading ? (
            <LoadingIndicator>
              <p>Carregando informações...</p>
              <ReactLoading
                type={'spin'}
                className="load"
                color={colors.green_secondary}
                width={'30px'}
              />
            </LoadingIndicator>
          ) : (
            <AdsList>
              {properties?.length > 0 ? (
                <>
                  {properties?.map((info) => (
                    <SafeAreaCard key={`property__${info?.id}`}>
                      <Card>
                        <strong>
                          {info?.type_property
                            ? info?.type_property?.name_pt
                            : 'Imóvel'}
                        </strong>
                        <address>{getAdress(info?.address)}</address>
                        <footer>
                          <div className="prices">
                            <span className="principal">
                              {getPrice(info, currency, 'primary')[0]}
                            </span>
                            <span>
                              {getPrice(info, currency, 'secondary')[1]}
                            </span>
                            <span>
                              {getPrice(info, currency, 'tertiary')[2]}
                            </span>
                          </div>
                          <div className="link">
                            {info.status === 'draft' ? (
                              <div>Rascunho</div>
                            ) : (
                              <Link
                                to={{
                                  pathname: `/view/${info.id}`,
                                }}
                                target="_blank"
                                rel="noopener noreferrer">
                                Ver anúncio <ChevronRightRoundedIcon />
                              </Link>
                            )}
                          </div>
                        </footer>
                      </Card>
                    </SafeAreaCard>
                  ))}
                </>
              ) : (
                <SafeAreaInfo>
                  <p>Sem imóveis associados...</p>
                </SafeAreaInfo>
              )}

              {lastPage > 1 && (
                <SafeAreaInfo>
                  <ContainerPagination>
                    <Pagination
                      count={lastPage}
                      page={page}
                      handleChange={(event, value) => handleChangePage(value)}
                    />
                  </ContainerPagination>
                </SafeAreaInfo>
              )}
            </AdsList>
          )}
        </Container>
      </Dialog>
    </div>
  );
}
