import Swal from 'sweetalert2';
import './reset-swal-style.css';

import AlertIcon from '../../assets/alert-icon.svg';
import SuccessIcon from '../../assets/success-icon.svg';
import ErrorIcon from '../../assets/error-icon.svg';
/**
 *
 * @param {String} title Título da mensagem
 * @param {String} message Mensagem
 * @param {String} type Tipo de ação (success, error, warning)
 */

const selectCorrectIcon = (type) => {
  switch (type) {
    case 'success':
      return SuccessIcon;
    case 'warning':
      return AlertIcon;
    case 'error':
      return ErrorIcon;
    default:
      return '';
  }
};

export function fireAction(title, message, type) {
  Swal.fire({
    title: title,
    text: message,
    icon: selectCorrectIcon(type),
    type: type,
    confirmButtonText: '',
    buttonsStyling: false,
    imageUrl: selectCorrectIcon(type),
    imageWidth: 30,
    imageHeight: 30,
    customClass: {
      confirmButton: 'btn-close-alert',
      header: 'header-alert',
      icon: 'icon-alert',
      title: `title-alert-${type}`,
      content: 'description-alert',
    },
  });
}

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
