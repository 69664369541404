import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {TitleModal} from '../../views/User/styles';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import colors from '../../UI/colors';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 500,
    height: 200,
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    borderColor: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 0,
  },
}));

export default function ModalError({modal, setModal}) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    setModal(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div
        onClick={handleClose}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
        }}>
        {' '}
        <CloseIcon style={{color: colors.green_third}} />
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <CancelIcon style={{marginRight: '15px', color: colors.danger}} />
        <TitleModal style={{color: colors.danger}}>
          Credenciais Incorretas
        </TitleModal>{' '}
      </div>
      <p
        style={{marginTop: '25px', fontSize: '16px', color: colors.dark_light}}>
        Certifique-se que seu email e senha estão corretos!
      </p>
    </div>
  );

  return (
    <div style={{border: 'none'}}>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  );
}
