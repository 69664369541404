import {useDispatch} from 'react-redux';
import planList from '../redux/action/action_plan_list';
import baseURL from '../services/api';
import React from 'react';
import I18n from '../i18n/i18';

export function getRoute() {
  let url = window.location.href;
  let part = url.split('/');
  if (part[3] === 'en' || part[3] === 'es' || part[3] === 'pt') {
    return part[3];
  }
  return 'pt';
}

export function desencrypta(hex) {
  const hexAux = hex.toString(); // force conversion
  let str = '';
  for (let i = 0; i < hexAux.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

export function encrypta(str) {
  let hex = '';
  for (let i = 0; i < str.length; i++) {
    hex += '' + str.charCodeAt(i).toString(16);
  }
  console.log(typeof hex);
  return hex;
}

export function exit() {
  /*  localStorage.clear(); */
  /*  window.location.href = '/'; */
}

export function convertDate(info = '') {
  let date = new Date(info);

  let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function transformAvatarURL(url = '') {
  if (
    url &&
    typeof url === 'string' &&
    (url?.includes('https://') || url?.includes('http://'))
  ) {
    try {
      const avatar_url = new URL(url);
      return avatar_url?.origin + avatar_url?.pathname;
    } catch (err) {
      return '';
    }
  } else {
    return '';
  }
}

export function GoShowAds(route) {
  window.open(`${window.location.origin}${route}`, '_blank');
}

export function mountYearSelect() {
  let minYear = 1900;
  let maxYear = new Date().getFullYear() + 5;
  let years = [];

  while (minYear <= maxYear) {
    years.push(minYear);
    minYear += 1;
  }

  const orderedYears = years.sort((a, b) => b - a);

  return orderedYears;
}

export function formatDate(info) {
  let date = new Date(info);

  return (
    <I18n
      t="Time"
      args={{
        day: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        month:
          date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1,
        year: date.getFullYear(),
      }}
    />
  );
}
