import {Grid} from '@material-ui/core';
import React from 'react';
import House from '../../../../../assets/_new/house.svg';
import Bed from '../../../../../assets/_new/bed.svg';
import Toilet from '../../../../../assets/_new/toilet.svg';
import Ruler from '../../../../../assets/_new/ruler-combined.svg';
import Shower from '../../../../../assets/_new/shower.svg';
import Sink from '../../../../../assets/_new/sink.svg';
import Layer from '../../../../../assets/_new/layer-group.svg';
import Garage from '../../../../../assets/_new/garage.svg';
import I18n from '../../../../../i18n/i18';
import './styles.css';

function GeneralItens(advertise) {
  return (
    <>
      <Grid spacing="1" container>
        {advertise?.advertise?.bedrooms &&
        advertise?.advertise?.bedrooms > 0 ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={Bed} alt="bed" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {advertise?.advertise?.bedrooms}{' '}
                {advertise?.advertise?.bedrooms > 1 ? (
                  <I18n t="StylesDefiniedTwo.labelBedrooms" />
                ) : (
                  <I18n t="StylesDefiniedTwo.labelSingularBedrooms" />
                )}
              </span>
            </Grid>
          </Grid>
        ) : null}

        {advertise?.advertise?.suites && advertise?.advertise?.suites > 0 ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={Shower} alt="shower" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {advertise?.advertise?.suites + ' '}
                {advertise?.advertise?.suites > 1 ? (
                  <I18n t="StylesDefiniedTwo.labelSuites" />
                ) : (
                  <I18n t="StylesDefiniedTwo.labelSingularSuites" />
                )}
              </span>
            </Grid>
          </Grid>
        ) : null}

        {advertise?.advertise?.full_bathrooms &&
        advertise?.advertise?.full_bathrooms > 0 ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={Toilet} alt="toilet" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {' '}
                {advertise.advertise.full_bathrooms + ' '}
                {advertise.advertise?.full_bathrooms > 1 ? (
                  <I18n t="StylesDefiniedTwo.labelBathrooms" />
                ) : (
                  <I18n t="StylesDefiniedTwo.labelSingularBathrooms" />
                )}
              </span>
            </Grid>
          </Grid>
        ) : null}

        {advertise?.advertise?.half_bathrooms &&
        advertise?.advertise?.half_bathrooms > 0 ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={Sink} alt="sink" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {advertise?.advertise?.half_bathrooms + ' '}
                {advertise?.advertise?.half_bathrooms > 1 ? (
                  <I18n t="StylesDefiniedTwo.labelHalf" />
                ) : (
                  <I18n t="StylesDefiniedTwo.labelSingularHalf" />
                )}
              </span>
            </Grid>
          </Grid>
        ) : null}

        {advertise?.advertise?.living_area ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={House} alt="house" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {advertise?.advertise?.living_area}{' '}
                {advertise?.advertise?.area_unit_of_measurement} AC
              </span>
            </Grid>
          </Grid>
        ) : null}

        {advertise?.advertise?.total_area ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={Ruler} alt="ruler" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {advertise?.advertise?.total_area}{' '}
                {advertise?.advertise?.area_unit_of_measurement} AT
              </span>
            </Grid>
          </Grid>
        ) : null}

        {advertise?.advertise?.stories && advertise?.advertise?.stories > 0 ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={Layer} alt="layer" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {' '}
                {advertise?.advertise?.stories + '  '}
                {advertise?.advertise?.stories > 1 ? (
                  <I18n t="StylesDefiniedTwo.labelFloor" />
                ) : (
                  <I18n t="StylesDefiniedTwo.labelSingularFloor" />
                )}
              </span>
            </Grid>
          </Grid>
        ) : null}

        {advertise?.advertise?.parking_spaces &&
        advertise?.advertise?.parking_spaces > 0 ? (
          <Grid
            container
            style={{marginTop: '30px'}}
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}>
            <Grid xl={2} lg={3} md={3} sm={3} xs={3}>
              <img src={Garage} alt="garage" width="23px" height="18px" />
            </Grid>

            <Grid xl={10} lg={9} md={9} sm={9} xs={9}>
              <span className="iconsFontes">
                {advertise?.advertise?.parking_spaces}{' '}
                {advertise?.advertise?.stories > 1 ? (
                  <I18n t="StylesDefiniedTwo.labelSpaces" />
                ) : (
                  <I18n t="StylesDefiniedTwo.labelSingularSpaces" />
                )}
              </span>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export default GeneralItens;
