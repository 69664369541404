import React, {useEffect, useState} from 'react';
import SelectNumberRequired from '../Select/selectNumber';
import {DivDouble} from '../../../Components/styles';
import SelectMenuRequired from '../../../Components/selectMenuRequired';
import SelectNumber from '../Select/selectNumberR';
import SelectMenu from '../../../Components/selectMenu';
import SelectMenuRequiredI18n from '../../../Components/selectMenuRequiredI18n';
import SelectMenuI18n from '../../../Components/selectMenuI18n';

import I18n from '~/i18n/i18';
import {getRoute} from '~/utils';

const valuesEN = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '+10',
];
const itensGarageEN = [
  'Not applicable',
  'Covered',
  'Discovery',
  'Covered and discovered',
];

function RenderSwitch({
  typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  suites,
  setSuites,
  bathrooms,
  setBathrooms,
  washrooms,
  setWashrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  active,
  setActive,
  type,
}) {
  const values = valuesEN;
  const itensGarage = itensGarageEN;

  const required = ['House', 'Gated Community House', 'Condo', 'Country House'];

  const farmer = ['Farm'];

  const store = ['Store'];

  const office = ['Office'];

  const commercialHouse = ['Commercial House'];

  const industrialShed = ['Industrial Shed'];

  const bulding = ['Commercial Building'];

  function renderSpecific() {
    if (required.indexOf(type) !== -1) {
      return AllRequiredOptions(
        {
          typeGarage,
        setTypeGarage,
        dormitories,
        setDormitories,
        suites,
        setSuites,
        bathrooms,
        setBathrooms,
        washrooms,
        setWashrooms,
        paving,
        setPaving,
        vacancies,
        setVacancies,
        active,
        setActive,
        values,
        itensGarage,
        }
      );
    }
    if (farmer.indexOf(type) !== -1) {
      return AllOptionalOptions(
        {
          typeGarage,
        setTypeGarage,
        dormitories,
        setDormitories,
        suites,
        setSuites,
        bathrooms,
        setBathrooms,
        washrooms,
        setWashrooms,
        paving,
        setPaving,
        vacancies,
        setVacancies,
        active,
        setActive,
        values,
        itensGarage,
        }
      );
    }
    if (office.indexOf(type) !== -1) {
      return OfficeOptions(
        {
          typeGarage,
        setTypeGarage,
        dormitories,
        setDormitories,
        bathrooms,
        setBathrooms,
        washrooms,
        setWashrooms,
        paving,
        setPaving,
        vacancies,
        setVacancies,
        active,
        setActive,
        values,
        itensGarage,
        }
      );
    }
    if (store.indexOf(type) !== -1) {
      return StoreOptions(
        {
          typeGarage,
        setTypeGarage,
        bathrooms,
        setBathrooms,
        paving,
        setPaving,
        vacancies,
        setVacancies,
        active,
        setActive,
        values,
        itensGarage,
        }
      );
    }
    if (commercialHouse.indexOf(type) !== -1) {
      return ComercialHouseOptions(
        {
          typeGarage,
        setTypeGarage,
        dormitories,
        setDormitories,
        suites,
        setSuites,
        bathrooms,
        setBathrooms,
        washrooms,
        setWashrooms,
        paving,
        setPaving,
        vacancies,
        setVacancies,
        active,
        setActive,
        values,
        itensGarage,
        }
      );
    }
    if (industrialShed.indexOf(type) !== -1) {
      return IndustrialShedOptions(
       {
        typeGarage,
        setTypeGarage,
        dormitories,
        setDormitories,
        bathrooms,
        setBathrooms,
        washrooms,
        setWashrooms,
        paving,
        setPaving,
        vacancies,
        setVacancies,
        active,
        setActive,
        values,
        itensGarage,
       }
      );
    }
    if (bulding.indexOf(type) !== -1) {
      return CommercialBuildingOptions(
       {
        typeGarage,
        setTypeGarage,
        dormitories,
        setDormitories,
        paving,
        setPaving,
        vacancies,
        setVacancies,
        active,
        setActive,
        values,
        itensGarage,
       }
      );
    }
  }

  return renderSpecific();
}

export default RenderSwitch;

function AllRequiredOptions(
  {
    typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  suites,
  setSuites,
  bathrooms,
  setBathrooms,
  washrooms,
  setWashrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  active,
  setActive,
  values,
  itensGarage,
  }
) {
  useEffect(() => {
    if (
      typeGarage &&
      dormitories &&
      suites &&
      bathrooms &&
      washrooms &&
      paving &&
      vacancies
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [
    typeGarage,
    dormitories,
    suites,
    bathrooms,
    washrooms,
    paving,
    vacancies,
  ]);

  return (
    <>
      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBedrooms" />}
          itens={values}
          value={dormitories}
          setValue={setDormitories}
        />
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSuites" />}
          itens={values}
          value={suites}
          setValue={setSuites}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBathrooms" />}
          itens={values}
          value={bathrooms}
          setValue={setBathrooms}
        />
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelHalf" />}
          itens={values}
          value={washrooms}
          setValue={setWashrooms}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelFloor" />}
          itens={values}
          value={paving}
          setValue={setPaving}
        />
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSpaces" />}
          itens={values}
          value={vacancies}
          setValue={setVacancies}
        />
      </DivDouble>
      <SelectMenuRequiredI18n
        i18n="ItensGarage"
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolderGarageType" />}
        label={<I18n t="StylesDefiniedTwo.labelGarageType" />}
        itens={itensGarage}
        value={typeGarage}
        setValue={setTypeGarage}
      />
    </>
  );
}

function AllOptionalOptions(
 {
  typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  suites,
  setSuites,
  bathrooms,
  setBathrooms,
  washrooms,
  setWashrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  active,
  setActive,
  values,
  itensGarage,
 }
) {
  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <>
      <DivDouble>
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBedrooms" />}
          itens={values}
          value={dormitories}
          setValue={setDormitories}
        />

        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSuites" />}
          itens={values}
          value={suites}
          setValue={setSuites}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBathrooms" />}
          itens={values}
          value={bathrooms}
          setValue={setBathrooms}
        />

        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelHalf" />}
          itens={values}
          value={washrooms}
          setValue={setWashrooms}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelFloor" />}
          itens={values}
          value={paving}
          setValue={setPaving}
        />
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSpaces" />}
          itens={values}
          value={vacancies}
          setValue={setVacancies}
        />
      </DivDouble>
      <SelectMenuI18n
        i18n="ItensGarage"
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolderGarageType" />}
        label={<I18n t="StylesDefiniedTwo.labelGarageType" />}
        itens={itensGarage}
        value={typeGarage}
        setValue={setTypeGarage}
      />
    </>
  );
}

function StoreOptions(
  {
    typeGarage,
  setTypeGarage,
  bathrooms,
  setBathrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  setActive,
  values,
  itensGarage,
  }
) {
  useEffect(() => {
    if (paving) setActive(true);
    else setActive(false);
  }, [paving]);

  return (
    <>
      <SelectNumber
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
        label={<I18n t="StylesDefiniedTwo.labelBathrooms" />}
        itens={values}
        value={bathrooms}
        setValue={setBathrooms}
      />
      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelFloor" />}
          itens={values}
          value={paving}
          setValue={setPaving}
        />
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSpaces" />}
          itens={values}
          value={vacancies}
          setValue={setVacancies}
        />
      </DivDouble>
      <SelectMenuI18n
        i18n="ItensGarage"
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolderGarageType" />}
        label={<I18n t="StylesDefiniedTwo.labelGarageType" />}
        itens={itensGarage}
        value={typeGarage}
        setValue={setTypeGarage}
      />
    </>
  );
}

function OfficeOptions(
  {
    typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  bathrooms,
  setBathrooms,
  washrooms,
  setWashrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  active,
  setActive,
  values,
  itensGarage,
  }
) {
  useEffect(() => {
    if (paving) setActive(true);
    else setActive(false);
  }, [paving]);

  return (
    <>
      <SelectNumber
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
        label={<I18n t="StylesDefiniedTwo.labelBedrooms" />}
        itens={values}
        value={dormitories}
        setValue={setDormitories}
      />
      <DivDouble>
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBathrooms" />}
          itens={values}
          value={bathrooms}
          setValue={setBathrooms}
        />
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelHalf" />}
          itens={values}
          value={washrooms}
          setValue={setWashrooms}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelFloor" />}
          itens={values}
          value={paving}
          setValue={setPaving}
        />
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSpaces" />}
          itens={values}
          value={vacancies}
          setValue={setVacancies}
        />
      </DivDouble>
      <SelectMenuI18n
        i18n="ItensGarage"
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolderGarageType" />}
        label={<I18n t="StylesDefiniedTwo.labelGarageType" />}
        itens={itensGarage}
        value={typeGarage}
        setValue={setTypeGarage}
      />
    </>
  );
}

function ComercialHouseOptions(
 {
  typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  suites,
  setSuites,
  bathrooms,
  setBathrooms,
  washrooms,
  setWashrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  active,
  setActive,
  values,
  itensGarage,
 }
) {
  useEffect(() => {
    if (paving && dormitories) setActive(true);
    else setActive(false);
  }, [paving, dormitories]);

  return (
    <>
      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBedrooms" />}
          itens={values}
          value={dormitories}
          setValue={setDormitories}
        />

        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSuites" />}
          itens={values}
          value={suites}
          setValue={setSuites}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBathrooms" />}
          itens={values}
          value={bathrooms}
          setValue={setBathrooms}
        />
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelHalf" />}
          itens={values}
          value={washrooms}
          setValue={setWashrooms}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelFloor" />}
          itens={values}
          value={paving}
          setValue={setPaving}
        />

        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSpaces" />}
          itens={values}
          value={vacancies}
          setValue={setVacancies}
        />
      </DivDouble>
      <SelectMenuI18n
        i18n="ItensGarage"
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolderGarageType" />}
        label={<I18n t="StylesDefiniedTwo.labelGarageType" />}
        itens={itensGarage}
        value={typeGarage}
        setValue={setTypeGarage}
      />
    </>
  );
}

function IndustrialShedOptions(
  {
    typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  bathrooms,
  setBathrooms,
  washrooms,
  setWashrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  active,
  setActive,
  values,
  itensGarage,
  }
) {
  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <>
      <SelectNumber
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
        label={<I18n t="StylesDefiniedTwo.labelBedrooms" />}
        itens={values}
        value={dormitories}
        setValue={setDormitories}
      />
      <DivDouble>
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelBathrooms" />}
          itens={values}
          value={bathrooms}
          setValue={setBathrooms}
        />
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelHalf" />}
          itens={values}
          value={washrooms}
          setValue={setWashrooms}
        />
      </DivDouble>
      <DivDouble>
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelFloor" />}
          itens={values}
          value={paving}
          setValue={setPaving}
        />

        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSpaces" />}
          itens={values}
          value={vacancies}
          setValue={setVacancies}
        />
      </DivDouble>
      <SelectMenuI18n
        i18n="ItensGarage"
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolderGarageType" />}
        label={<I18n t="StylesDefiniedTwo.labelGarageType" />}
        itens={itensGarage}
        value={typeGarage}
        setValue={setTypeGarage}
      />
    </>
  );
}

function CommercialBuildingOptions(
  {
    typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  active,
  setActive,
  values,
  itensGarage,
  }
) {
  useEffect(() => {
    if (paving) setActive(true);
    else setActive(false);
  }, [paving]);

  return (
    <>
      <SelectNumber
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
        label={<I18n t="StylesDefiniedTwo.labelBedrooms" />}
        itens={values}
        value={dormitories}
        setValue={setDormitories}
      />

      <DivDouble>
        <SelectNumberRequired
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelFloor" />}
          itens={values}
          value={paving}
          setValue={setPaving}
        />
        <SelectNumber
          placeHolder={<I18n t="StylesDefiniedTwo.placeHolder" />}
          label={<I18n t="StylesDefiniedTwo.labelSpaces" />}
          itens={values}
          value={vacancies}
          setValue={setVacancies}
        />
      </DivDouble>
      <SelectMenuI18n
        i18n="ItensGarage"
        placeHolder={<I18n t="StylesDefiniedTwo.placeHolderGarageType" />}
        label={<I18n t="StylesDefiniedTwo.labelGarageType" />}
        itens={itensGarage}
        value={typeGarage}
        setValue={setTypeGarage}
      />
    </>
  );
}
