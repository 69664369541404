import React from 'react';

import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
} from './modal_styles';
import './modal_styles.css';

import {desencrypta} from '~/utils';

import I18n from '~/i18n/i18';
import baseURL from '~/services/api';
import apiBackend from '~/services/apiaxios';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalDelete({idAds, id, open, setOpen, getProperty}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  async function func(idMatch) {
    // fetch(`${baseURL}/api/v1/properties/${idAds}/matches/${idMatch}`, {
    //   method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    //   mode: 'cors', // no-cors, *cors, same-origin
    //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: desencrypta(localStorage.getItem('@User_informations')),
    //   },
    // })
    //   .then((response) => {
    //     console.log(response);
    //     handleClose();
    //     getProperty();
    //   })
    //   .catch((e) => {
    //     console.log('Deu ruim', e);
    //   });

    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .delete(
        `/v1/property/${idAds}/intention/${idMatch}`,

        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        console.log(response);
        handleClose();
        getProperty();
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle>
                {' '}
                <I18n t="ModalDelete.warning" />{' '}
              </AdvertTitle>
              <Message>
                <I18n t="ModalDelete.phrase" />
              </Message>
              <DivConfirm>
                <ButtonsWhite onClick={(event) => func(id, event)}>
                  <I18n t="ModalDelete.yes" />
                </ButtonsWhite>
                <ButtonsWhite onClick={(event) => handleClose()}>
                  <I18n t="ModalDelete.no" />
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
