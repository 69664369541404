import React from 'react';
import {Divider} from '@material-ui/core';
import GarageCar from '../../../../../assets/_new/garage-car.svg';
import Building from '../../../../../assets/_new/building.svg';
import HardHat from '../../../../../assets/_new/hard-hat.svg';
import Refrigerator from '../../../../../assets/_new/refrigerator.svg';
import WareHouse from '../../../../../assets/_new/warehouse-alt.svg';
import WindowFrame from '../../../../../assets/_new/window-frame-open.svg';
import Light from '../../../../../assets/_new/light-ceiling.svg';
import HouseUser from '../../../../../assets/_new/house-user.svg';
import Chair from '../../../../../assets/_new/chair.svg';
import Magic from '../../../../../assets/_new/magic.svg';

import {Grid} from '@material-ui/core';
import I18n from '../../../../../i18n/i18';
import './styles.css';

function InformationsAds({advertise}) {
  console.log('aquii', advertise);

  function returnStyle() {
    return (
      <>
        Estilo
        {' ' + advertise?.style_property[`name_pt`]}
      </>
    );
  }

  function returnGarage() {
    return (
      <>
        Garagem {/* <I18n t="DetailsAds.garage" />{' '} */}
        <I18n t={`ItensGarage.${advertise?.garage_type}`} />
      </>
    );
  }

  function returnWaterFront() {
    return (
      <>
        {advertise?.waterfront === 'Forest' ||
        advertise?.waterfront === 'Mountain' ? (
          <I18n t="DetailsAds.view2" />
        ) : (
          <I18n t="DetailsAds.view" />
        )}{' '}
        <I18n t={`TypeView.${advertise?.waterfront}`} />
      </>
    );
  }

  return (
    <>
      {advertise?.style_property ||
      advertise?.loft ||
      advertise?.year_build ||
      advertise?.penthouse ||
      advertise?.appliances ||
      (advertise?.waterfront &&
        advertise?.waterfront !== 'No' &&
        advertise?.waterfront !== 'Not applicable') ||
      advertise?.decorated ||
      advertise?.property_phase ||
      advertise?.furnished ? (
        <>
          <div className="subTitleShow">Características do Imóvel</div>
          <Grid spacing="1" container>
            {advertise?.style_property ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img src={Magic} alt="magic" width="23px" height="18px" />

                  <span className="iconsFontes mg-left-20">
                    {returnStyle()}
                  </span>
                </div>
              </Grid>
            ) : null}

            {advertise?.style_property &&
            advertise?.style_property !== 'Not applicable' &&
            advertise?.style_property !== 'No' ? (
              <Grid
                style={{marginTop: '30px'}}
                container
                spacing="1"
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img
                    src={GarageCar}
                    alt="garage"
                    width="23px"
                    height="18px"
                  />

                  <span className="iconsFontes mg-left-20">
                    {returnGarage()}
                  </span>
                </div>
              </Grid>
            ) : null}

            {advertise?.loft ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img
                    src={WareHouse}
                    alt="warehouse"
                    width="23px"
                    height="18px"
                  />
                  <span className="iconsFontes mg-left-20">Loft</span>
                </div>
              </Grid>
            ) : null}

            {advertise?.year_build ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img src={HardHat} alt="hardhat" width="23px" height="18px" />

                  <span className="iconsFontes mg-left-20">
                    Criado em {' ' + advertise?.year_build}
                  </span>
                </div>
              </Grid>
            ) : null}

            {advertise?.penthouse ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img
                    src={Building}
                    alt="building"
                    width="23px"
                    height="18px"
                  />

                  <span className="iconsFontes mg-left-20">
                    <I18n t="DetailsAds.coverage" />
                  </span>
                </div>
              </Grid>
            ) : null}

            {advertise?.appliances ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img
                    src={Refrigerator}
                    alt="Refrigerator"
                    width="23px"
                    height="18px"
                  />

                  <span className="iconsFontes mg-left-20">
                    <I18n t="DetailsAds.appliances" />
                  </span>
                </div>
              </Grid>
            ) : null}

            {advertise?.waterfront &&
            advertise?.waterfront !== 'Not applicable' &&
            advertise?.waterfront !== 'No' ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img
                    src={WindowFrame}
                    alt="windowframe"
                    width="23px"
                    height="18px"
                  />
                  <span className="iconsFontes mg-left-20">
                    {returnWaterFront()}
                  </span>
                </div>
              </Grid>
            ) : null}

            {advertise?.decorated ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img src={Light} alt="light" width="23px" height="18px" />

                  <span className="iconsFontes mg-left-20">Decorado</span>
                </div>
              </Grid>
            ) : null}

            {/* {ItensPhases} */}
            {advertise?.property_phase ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                wrap="nowrap"
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img
                    src={HouseUser}
                    alt="houseuser"
                    width="23px"
                    height="18px"
                  />
                  <span className="iconsFontes mg-left-20">
                    <I18n t={`ItensPhases.${advertise?.property_phase}`} />
                  </span>
                </div>
              </Grid>
            ) : null}

            {advertise?.furnished ? (
              <Grid
                style={{marginTop: '30px'}}
                spacing="1"
                container
                item
                wrap="nowrap"
                xl={4}
                lg={6}
                md={6}
                sm={6}
                xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <img src={Chair} alt="chair" width="23px" height="18px" />

                  <span className="iconsFontes mg-left-20">
                    <I18n t="DetailsAds.furnished" />
                  </span>
                </div>
              </Grid>
            ) : null}
          </Grid>
          <div className="mg-top-30 mg-bottom-30">
            <Divider />
          </div>
        </>
      ) : null}
    </>
  );
}

export default InformationsAds;
