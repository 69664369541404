import React, {useState, useEffect} from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';

import styled from 'styled-components';

import setLocationWith from '../../../i18n/utils/viewLang';
import fonts from '~/UI/fonts';
import colors from '~/UI/colors';
import I18n from '~/i18n/i18';
import {getRoute} from '~/utils';

const useStyles = makeStyles({
  root: {
    marginTop: '60px',
  },
  item: {
    width: '300px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    width: '200px',
    marginTop: '10px',
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});

export default function MenuChangeObjectReq(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [choose, setChoose] = useState('');
  const [langName, setLangName] = useState('en');
  const [viewMesssage, setViewMessage] = useState(false);

  useEffect(() => {
    let suffix = getRoute();
    setLangName(suffix === '' ? 'en' : suffix);
  }, [window.location.href]);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  function handleClickChoose(item) {
    setAnchorEl(null);
    setChoose(setLocationWith(item));
    props.setValue(item);
  }

  function closed() {
    setAnchorEl(null);
    setOpenCollapse(!openCollapse);
  }

  useEffect(() => {
    props.value ? setChoose(props.value[`name_pt`]) : setChoose('');
  }, [props.value, langName]);

  const fakeSelectRef = React.useRef(null);

  const ITEM_HEIGHT = 48;
  return (
    <div ref={fakeSelectRef} style={{minWidth: '200px', marginBottom: '20px'}}>
      <ButtonFilter
        disabled={props.disable}
        type="button"
        itens={props.itensBackend}
        color={choose}
        onClick={handleClick}>
        {choose ? choose : props.placeHolder}{' '}
        {anchorEl ? <ExpandLess /> : <ExpandMore />}
      </ButtonFilter>

      <Menu
        className={classes.root}
        style={{}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          closed();
        }}
        PaperProps={{
          style: {
            maxWidth: fakeSelectRef.current
              ? fakeSelectRef.current.clientWidth
              : '300px',
            minWidth: 200,
            width: '100%',
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}>
        {props.itensBackend.map((item, key) => (
          <MenuItem
            key={key}
            className={classes.item}
            onClick={() => handleClickChoose(item)}>
            {item[`name_pt`]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export const ButtonFilter = styled.button`
  height: 50px;
  display: flex;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  color: ${colors.dark};
  padding: 0px 20px 0px 20px;
  font-size: 16px;
  font-family: ${(props) =>
    props.hasChoose ? fonts.latoRegular : fonts.latoRegularItalic};

  svg {
    transition: transform 0.2s linear;
    color: ${colors.green_secondary};
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }

  justify-content: space-between;

  &:focus {
    outline: 0;
  }
`;

const Label = styled.div`
  font-weight: bold;
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;

const Message = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: red;
`;
