import React, {useState} from 'react';

import './styles.css';
import {Divider, Grid} from '@material-ui/core';

import {TitleIntentions, ButtonWhiteAccordion, ItemOpacity} from './styles';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
// import { Container } from './styles';
import Couch from '~/assets/_new/couch.svg';
import Tree from '~/assets/_new/tree-alt.svg';
import {useWindowDimensions} from '~/utils/useDimension';
import ItemAccordion from './ItemIntention';
import I18n from '~/i18n/i18';

function DetailsIntern({langName, advertise}) {
  const [openInternal, setOpenInternal] = useState(false);
  const [openExternal, setOpenExternal] = useState(false);

  const internalRef = React.useRef(null);
  const externalRef = React.useRef(null);
  const [showBtnInternal, setShowBtnInternal] = useState(false);
  const [showBtnExternal, setShowBtnExternal] = useState(false);
  const {width} = useWindowDimensions();

  React.useLayoutEffect(() => {
    if (internalRef.current) {
      const itemStyle = internalRef.current.getBoundingClientRect();
      setShowBtnInternal(() => itemStyle.height > 130);
    }
  }, [width]);

  React.useLayoutEffect(() => {
    if (externalRef.current) {
      const itemStyle = externalRef.current.getBoundingClientRect();
      setShowBtnExternal(() => itemStyle.height > 130);
    }
  }, [width]);

  return (
    <>
      {advertise?.internalProperty?.length > 0 && (
        <>
          <ItemOpacity
            notMask={!showBtnInternal}
            open={showBtnInternal && openInternal}
            className="gridIntentions ">
            <div className="gridItensIntentions" item>
              <img src={Couch} alt="||" width="30px" height="24px" />
              <TitleIntentions className="mg-left-15">
                <I18n t="StepModule.internDetails" />
              </TitleIntentions>
            </div>
            <Divider />
            {advertise?.internalProperty && (
              <div ref={internalRef}>
                <ItemAccordion
                  langName={langName}
                  details={advertise?.internalProperty}
                />
              </div>
            )}
          </ItemOpacity>
          {showBtnInternal && (
            <ButtonWhiteAccordion
              onClick={() => setOpenInternal(!openInternal)}
              className="mg-top-10 center-auto">
              <span>{openInternal ? 'Ver menos' : 'Ver mais'}</span>
              {openInternal ? <ExpandLess /> : <ExpandMore />}
            </ButtonWhiteAccordion>
          )}
        </>
      )}

      {advertise?.externalProperty?.length > 0 && (
        <>
          <ItemOpacity
            notMask={!showBtnExternal}
            open={showBtnExternal && openExternal}
            className="gridIntentions mg-top-40">
            <div className="gridItensIntentions" item>
              <img src={Tree} alt="thumb" width="30px" height="24px" />
              <TitleIntentions className="mg-left-15">
                <I18n t="StepModule.externDetails" />
              </TitleIntentions>
            </div>
            <Divider />

            {advertise?.externalProperty && (
              <div ref={externalRef}>
                <ItemAccordion
                  langName={langName}
                  details={advertise?.externalProperty}
                />
              </div>
            )}
          </ItemOpacity>
          {showBtnExternal && (
            <ButtonWhiteAccordion
              onClick={() => setOpenExternal(!openExternal)}
              className="mg-top-10 center-auto">
              <span>{openExternal ? 'Ver menos' : 'Ver mais'}</span>{' '}
              {openExternal ? <ExpandLess /> : <ExpandMore />}
            </ButtonWhiteAccordion>
          )}
        </>
      )}
    </>
  );
}

export default DetailsIntern;
