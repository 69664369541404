import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~/utils';

import All from './All';
import Approved from './Approved';
import Refused from './Dissaproved';
import Pending from './Pending';
import {Container} from './styles';
import './styles.css';

// import { Container } from './styles';

function MyProposal2({status, setStatus, query, setQuery, ...props}) {
  const [seeProposal, setSeeProposal] = useState();

  const [chooseAddress, setChooseAddress] = useState(
    props?.location?.state?.item || '',
  );

  const [langName, setLangName] = useState('en');

  // useEffect(() => {
  //   setStatus('all');
  // }, [seeProposal]);

  useEffect(() => {
    let suffix = getRoute();
    setLangName(suffix);
  }, [window.location.href]);

  function switchStatus() {
    let result = status;

    switch (result) {
      case 'all':
        return (
          <All
            query={query}
            chooseAddress={chooseAddress}
            seeProposal={seeProposal}
          />
        );
      case 'pending':
        return (
          <Pending
            query={query}
            chooseAddress={chooseAddress}
            seeProposal={seeProposal}
          />
        );
      case 'approved':
        return (
          <Approved
            query={query}
            chooseAddress={chooseAddress}
            seeProposal={seeProposal}
          />
        );
      case 'refused':
        return (
          <Refused
            query={query}
            chooseAddress={chooseAddress}
            seeProposal={seeProposal}
          />
        );
      default:
        return (
          <All
            query={query}
            chooseAddress={chooseAddress}
            seeProposal={seeProposal}
          />
        );
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        {(status || query || seeProposal) && switchStatus()}
      </Grid>
    </>
  );
}

export default MyProposal2;
