import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import {Link} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from '@material-ui/core';
import colors from '~/UI/colors';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import {
  Container,
  InternDiv,
  TitleModal,
  TitlePage,
  Hero,
  AdsList,
  SafeAreaCard,
  SafeAreaInfo,
  Card,
  LoadingIndicator,
} from './styles';
import {makeStyles} from '@material-ui/core';

import {getAdress, getPrice} from '~/views/Annoucement/View/utils/finance';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import {translateProfileType} from '~/utils/profileType';
import MyAdsLoading from '~/views/RealState/Loading';
import MyAdsLoadingBroker from '~/views/RealState/LoadingBroker';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '600px!important',
    width: '100%!important',
    marginLeft: '0!important',
    marginRight: '0!important',
  },
}));

export default function ModalAdsBroketList({
  open,
  setOpen,
  // sub,
  broker,
  // setSub,
  currency,
}) {
  const classes = useStyles();
  const [ads, setAds] = useState([]);
  const [load, setLoad] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAds([]);
  };

  useEffect(() => {
    if (open) {
      getAllAds();
    }
  }, [open]);

  function getAllAds() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    setLoad(true);
    apiBackend
      .get(`/v1/property?user_id=${broker.id}&status=approved`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        setAds(response.data.property);

        console.log(response);
      })
      .catch((e) => {
        Sentry.captureException(e);
      })
      .finally(() => {
        setLoad(false);
      });
  }

  return (
    <div>
      <Dialog
        scroll="body"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{paper: classes.paper}}>
        <Container>
          <InternDiv>
            <TitleModal>
              <TitlePage>Lista de anúncios</TitlePage>{' '}
              <CloseIcon onClick={handleClose} className={'iconClose'} />
            </TitleModal>
            <Hero>
              <span>
                {translateProfileType(broker?.profile?.type_advertiser)}
              </span>
              <p>
                {broker?.profile?.type_advertiser?.toLowerCase() ===
                'broker' ? (
                  <>{broker.profile?.enterprise}</>
                ) : (
                  <>
                    {broker?.name} {broker?.surname ?? ''}
                  </>
                )}
              </p>
            </Hero>
          </InternDiv>
          <AdsList>
            {load && <MyAdsLoadingBroker />}
            {ads.length > 0 && (
              <>
                {ads?.map((info, index) => (
                  <SafeAreaCard key={index}>
                    <Card>
                      <strong>
                        {info?.type_property
                          ? info?.type_property?.name_pt
                          : 'Imóvel'}
                      </strong>
                      <address>{getAdress(info?.address)}</address>
                      <footer>
                        <div className="prices">
                          <span className="principal">
                            {getPrice(info, currency, 'primary')[0]}
                          </span>
                          <span>
                            {getPrice(info, currency, 'secondary')[1]}
                          </span>
                          <span>{getPrice(info, currency, 'tertiary')[2]}</span>
                        </div>
                        <div className="link">
                          <Link
                            to={{
                              pathname: `/view/${info.id}`,
                            }}
                            target="_blank"
                            rel="noopener noreferrer">
                            Ver anúncio <ChevronRightRoundedIcon />
                          </Link>
                        </div>
                      </footer>
                    </Card>
                  </SafeAreaCard>
                ))}
              </>
            )}
          </AdsList>
        </Container>
      </Dialog>
    </div>
  );
}
