import React, {useState, useEffect} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';

import {Dialog} from '@material-ui/core';
import {
  InternDiv,
  TitleModal,
  DivInputText,
  EnvolvedSearchUser,
  ModalResponsive,
} from './styles';

import {ButtonSave} from '../../views/Annoucement/View/Edit/DesiredProfile/styles';
import {getRoute, desencrypta} from '../../utils';
import {InputSearch} from '../../UI/styles';
import AutoComplete from './autoComplete';
import baseURL from '../../services/api';
import * as Sentry from '@sentry/react';

export default function ModalDesired({open, setOpen, id, getProperty}) {
  const [allUser, setAllUser] = useState([]);
  const [enableButton, setEnableButton] = useState(true);

  async function listUsers() {
    await fetch(`${baseURL}/api/v1/clients`, {
      method: 'GET',
      mode: 'cors',

      headers: {
        'Content-Type': 'application/json',
        Authorization: desencrypta(localStorage.getItem('@User_informations')),
      },
    })
      .then((response) => response.json())

      .then((data) => {
        console.log(data);
        setAllUser(data);
      })
      .catch((err) => {
        Sentry.captureException(err);
        // setLoader(false);
      });
  }

  // useEffect(() => {
  //   listUsers();
  // }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span> Selecione um usuário para criar seu anúncio</span>
            <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
          </TitleModal>
          <ModalResponsive>
            <InternDiv>
              <EnvolvedSearchUser>
                <AutoComplete optionsAll={allUser} />
              </EnvolvedSearchUser>
              <div style={{marginBottom: '30px'}}>
                <ButtonSave
                  disabled={enableButton}
                  className={'btnDataAddress btnload'}>
                  Criar Anúncio
                </ButtonSave>
              </div>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
      {/* <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="success">
                {t("Placeholders.editUser")}
            </Alert>
        </Snackbar> */}
    </>
  );
}
