export function getRoute() {
  const url = window.location.href;
  const part = url.split('/');
  if (part[3] === 'en' || part[3] === 'es' || part[3] === 'pt') {
    return part[3];
  }
  return '';
}

export function desencrypta(hex) {
  const hexAux = hex.toString(); // force conversion
  let str = '';
  for (let i = 0; i < hexAux.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

export function encrypta(str) {
  let hex = '';
  for (let i = 0; i < str.length; i++) {
    hex += `${str.charCodeAt(i).toString(16)}`;
  }
  console.log(typeof hex);
  return hex;
}
