import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import ReactLoading from 'react-loading';
// import { ModalResponsive, Star } from '../../Components/styles';

import {toMoney, toNumber} from 'vanilla-masker';
import {ModalResponsive} from '../ModalDelete/styles';
import {ButtonSave} from '../../views/User/styles';
import {ButtonAdd} from '../../UI/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import InputNumberCount from '../inputNumberCount';
import apiBackend from '../../services/apiaxios';
import {desencrypta} from '../../utils/index';
import {makeStyles} from '@material-ui/core';
import colors from '~/UI/colors';

import {fireAction} from '~/utils/swal';

import {
  InternDiv,
  TitleModal,
  DivInputText,
  ButtonRelative,
  Field,
  DivMetrics,
  DivInputMetrics,
  MessageInputs,
  DivText,
  DivSelectMetrics,
  LabelRadioButton,
  StarRadioButton,
} from './styles';
import InputTextRequireSelect from '../inputRequireSelect';
import Dimension from '../dimension2';
import InputTextRequired from '../inputTextRequired';

import Checkbox from './Checkbox';
import RadioButtonPerson from '../RadioButtonPerson';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '440px!important',
    width: 'calc(100% - 20px)!important',
    marginTop: '30px!important',
    marginBottom: '10px!important',
    '@media(max-width: 840px)': {
      width: 'calc(100% - 30px)!important',
      maxWidth: '420px!important',
    },
  },
}));

export default function ModalEditPlan({open, setOpen, item, listPlan}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState(item?.kind);
  const [namePlan, setNamePlan] = useState(item?.name);

  const [adsQtd, setAdsQtd] = useState(item?.ads_quantity);
  const [unlimitedAds, setUnlimitedAds] = useState(
    Number(item?.ads_quantity) === 0,
  );

  const [monthPlan, setMonthPlan] = useState(item?.periodicity_count);
  const [unlimitedPeriod, setUnlimitedPeriod] = useState(
    Number(item?.periodicity_count) === 0,
  );

  const [freePlan, setFreePlan] = useState(item?.free_days);
  const [hasNotFreePeriod, setHasNotFreePeriod] = useState(
    Number(item?.free_days) === 0,
  );

  const [price, setPrice] = useState(() =>
    toMoney(parseInt(item?.price), {
      precision: 0,
      separator: '',
      delimiter: ',',
    }),
  );

  const [spacePrice, setSpacePrice] = useState(false);

  const [choseMetric, setChoseMetric] = useState(item?.currency?.toUpperCase());

  /*  const [activeOnFinish, setActiveOnFinish] = useState(item?.active);
  const [publishOnFinish, setPublishOnFinish] = useState(item?.public); */

  const itensDimension = ['USD'];

  // const itensCountry = ['Brazil', 'United States', 'Portugal'];

  const clearData = () => {
    setNamePlan(item?.name);
    setPrice(
      toMoney(parseInt(item?.price), {
        precision: 0,
        separator: '',
        delimiter: ',',
      }),
    );
    setType(item?.kind);
    /* ------ */
    setAdsQtd(item?.ads_quantity);
    setUnlimitedAds(Number(item?.ads_quantity) === 0);
    /* ------ */
    setFreePlan(item?.free_days);
    setHasNotFreePeriod(Number(item?.free_days) === 0);
    /* ------ */
    setMonthPlan(item?.periodicity_count);
    setUnlimitedPeriod(Number(item?.periodicity_count) === 0);
    /* ------ */
    /*    setPublishOnFinish(item?.public);
    setActiveOnFinish(item?.active); */
    /* ------ */
  };

  const handleClose = () => {
    clearData();
    setOpen();
  };

  async function editPlan() {
    if (!type) {
      return fireAction(
        'Campos obrigatórios',
        'Escolha um perfil para o plano',
        'error',
      );
    }

    /* Name */
    if (!namePlan || namePlan.length < 3) {
      return fireAction(
        'Campos obrigatórios',
        'Nome do plano deve conter no mínimo 3 caracteres',
        'error',
      );
    }
    /* Price */
    if (!price) {
      return fireAction(
        'Campos obrigatórios',
        'Preencha um valor para o plano. Caso deseje torná-lo gratuito, insira o valor 0',
        'error',
      );
    }
    /* Properties */
    if (!unlimitedAds && !adsQtd) {
      return fireAction(
        'Campos obrigatórios',
        'Informe a quantidade de imóveis permitido',
        'error',
      );
    }

    /* Period */
    if (!unlimitedPeriod && !monthPlan) {
      return fireAction(
        'Campos obrigatórios',
        'Informe o período de validade do plano (em meses)',
        'error',
      );
    }

    /* Free days */
    if (
      type === 'common' &&
      !unlimitedPeriod &&
      !hasNotFreePeriod &&
      !freePlan
    ) {
      return fireAction(
        'Campos obrigatórios',
        'Informe o período gratuíto concedido (em dias). Caso não deseje oferecer um período gratuito, você deve atribuir o valor 0',
        'error',
      );
    }

    setIsLoading(true);
    try {
      const token = desencrypta(localStorage.getItem('@User_informations'));

      /* Period unlimited */
      const periodicityData = {
        periodicity: 'month',
        periodicity_count: unlimitedPeriod ? null : monthPlan,
      };

      /* Free period for common user or unlimited */
      const freePeriodData =
        type === 'common'
          ? {
              free_days: unlimitedPeriod ? 0 : hasNotFreePeriod ? 0 : freePlan,
            }
          : {
              free_days: 0,
            };

      /* Data */
      const newPlan = {
        name: namePlan?.trim(),
        code: namePlan
          ?.trim()
          ?.replace(/[^a-z0-9]/gi, '')
          ?.toLowerCase(),
        price: price ? price?.replaceAll(',', '').replaceAll('.', '') : 0,
        currency: choseMetric,
        kind: type,
        ads_quantity: unlimitedAds ? 0 : Number(adsQtd),
        ...freePeriodData,
        ...periodicityData,
        /*      public: publishOnFinish,
        active: activeOnFinish, */
      };

      const {data} = await apiBackend.put(`/v1/plan/${item?.id}`, newPlan, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(data);
      await listPlan();
    } catch (err) {
      if (err.response.status === 422) {
        setIsLoading(false);
        return fireAction(
          'Não foi possível completar sua solicitação',
          'Já existe um plano cadastrado com o nome informado. Por favor, tente outro nome.',
          'error',
        );
      } else {
        Sentry.captureException(err);
        fireAction(
          'Erro interno',
          'Não foi possível completar sua solicitação. Por favor, tente novamente mais tarde.',
          'error',
        );
      }
      setIsLoading(false);
      handleClose();
      console.log(err.response);
    } finally {
      /*  */
    }
  }

  function addNumber(value, setValue) {
    if (value === '') {
      setValue(1);
    } else {
      setValue(parseInt(value) + 1);
    }
  }

  function removeNumber(value, setValue) {
    if (value === '') {
      setValue(0);
    } else {
      if (value === 0) setValue(0);
      else {
        setValue(value - 1);
      }
    }
  }

  function maskNumber(e, setValue) {
    var float = e.target.value;
    setValue(toNumber(float));
  }

  function spaceDimensionPrice(value) {
    if (value) setSpacePrice(true);
    else setSpacePrice(false);
  }

  function mask(e, setValue) {
    const float = e.target.value;
    setValue(
      toMoney(float, {
        precision: 0,
        separator: '',
        delimiter: ',',
      }),
    );
  }

  React.useEffect(() => {
    if (price && spacePrice) {
      setSpacePrice(false);
    }
  }, [price]);

  /* React.useEffect(() => {
    if (
      publishOnFinish &&
      (unlimitedAds ||
        unlimitedPeriod ||
        (price && Number(price?.replaceAll(',', '').replaceAll('.', '')) === 0))
    ) {
      setPublishOnFinish(false);
    }
  }, [price, unlimitedPeriod, unlimitedAds, publishOnFinish]); */

  return (
    <>
      <div>
        <Dialog
          fullWidth
          maxWidth={'lg'}
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          classes={{paper: classes.paper}}>
          <TitleModal>
            <span>Editar plano </span>
            <CloseIcon onClick={handleClose} className={'iconClose'} />
          </TitleModal>
          <ModalResponsive>
            <InternDiv>
              <Field mb={20}>
                <InputTextRequired
                  value={namePlan}
                  setValue={setNamePlan}
                  label="Nome do plano"
                  placeHolder="Digite o nome..."
                />
              </Field>

              <Field mb={20}>
                <DivMetrics value={price}>
                  <DivInputMetrics>
                    <InputTextRequireSelect
                      isRequired
                      func={spaceDimensionPrice}
                      mask={mask}
                      placeHolder="Digite o valor..."
                      label="Preço do plano"
                      value={price}
                      setValue={setPrice}
                    />
                  </DivInputMetrics>
                  <DivSelectMetrics>
                    <DivText>
                      <Dimension
                        value={choseMetric}
                        setValue={setChoseMetric}
                        itens={itensDimension}
                      />
                    </DivText>
                  </DivSelectMetrics>
                </DivMetrics>
                {spacePrice ? (
                  <MessageInputs>Campo obrigatório</MessageInputs>
                ) : null}
              </Field>

              <Field bordered>
                <LabelRadioButton color={type}>
                  Qual o perfil do plano? <StarRadioButton>*</StarRadioButton>
                </LabelRadioButton>
                <RadioButtonPerson
                  distance="5px 0px 0px"
                  selectedValue={type}
                  setSelectedValue={setType}
                />
              </Field>

              <Field bordered>
                {!unlimitedAds && (
                  <>
                    <InputNumberCount
                      isRequired
                      mask={maskNumber}
                      value={adsQtd}
                      setValue={setAdsQtd}
                      label="Quantidade de imóveis"
                      placeHolder="Quantidade..."
                    />
                    <ButtonRelative>
                      <ButtonAdd
                        onClick={() => addNumber(adsQtd, setAdsQtd)}
                        className="mg-right-43">
                        <FontAwesomeIcon icon={faPlus} />
                      </ButtonAdd>
                      <ButtonAdd
                        onClick={() => removeNumber(adsQtd, setAdsQtd)}>
                        <FontAwesomeIcon icon={faMinus} />
                      </ButtonAdd>
                    </ButtonRelative>
                  </>
                )}
                <Checkbox
                  hasChecked={unlimitedAds}
                  handleChange={setUnlimitedAds}
                  label="Imóveis ilimitados"
                />
              </Field>

              <Field bordered>
                {!unlimitedPeriod && (
                  <>
                    <InputNumberCount
                      isRequired
                      mask={maskNumber}
                      value={monthPlan}
                      setValue={setMonthPlan}
                      label="Validade"
                      placeHolder="Validade (meses)..."
                      strongLabel="Meses"
                    />
                    <ButtonRelative>
                      <ButtonAdd
                        onClick={() => addNumber(monthPlan, setMonthPlan)}
                        className="mg-right-43">
                        <FontAwesomeIcon icon={faPlus} />
                      </ButtonAdd>
                      <ButtonAdd
                        onClick={() => removeNumber(monthPlan, setMonthPlan)}>
                        <FontAwesomeIcon icon={faMinus} />
                      </ButtonAdd>
                    </ButtonRelative>
                  </>
                )}
                <Checkbox
                  hasChecked={unlimitedPeriod}
                  handleChange={setUnlimitedPeriod}
                  label="Sem expiração"
                />
              </Field>

              {type === 'common' && !unlimitedPeriod && (
                <Field bordered>
                  {!hasNotFreePeriod && (
                    <>
                      <InputNumberCount
                        isRequired
                        mask={maskNumber}
                        value={freePlan}
                        setValue={setFreePlan}
                        label="Período gratuíto"
                        placeHolder="Validade (dias)..."
                        strongLabel="Dias"
                      />
                      <ButtonRelative>
                        <ButtonAdd
                          onClick={() => addNumber(freePlan, setFreePlan)}
                          className="mg-right-43">
                          <FontAwesomeIcon icon={faPlus} />
                        </ButtonAdd>
                        <ButtonAdd
                          onClick={() => removeNumber(freePlan, setFreePlan)}>
                          <FontAwesomeIcon icon={faMinus} />
                        </ButtonAdd>
                      </ButtonRelative>
                    </>
                  )}
                  <Checkbox
                    hasChecked={hasNotFreePeriod}
                    handleChange={setHasNotFreePeriod}
                    label="Sem período gratuito"
                  />
                </Field>
              )}

              {/*   <Field bordered>
                <Checkbox
                  hasChecked={activeOnFinish}
                  handleChange={setActiveOnFinish}
                  label="Ativar plano ao finalizar"
                />
                {!unlimitedAds &&
                !unlimitedPeriod &&
                (price
                  ? Number(price?.replaceAll(',', '').replaceAll('.', '')) > 0
                  : true) ? (
                  <Checkbox
                    hasChecked={publishOnFinish}
                    handleChange={setPublishOnFinish}
                    label="Publicar plano ao finalizar"
                  />
                ) : null}
              </Field> */}

              <ButtonSave
                disabled={isLoading}
                onClick={editPlan}
                className={'btnDataAddress btnload'}>
                {isLoading ? (
                  <ReactLoading
                    className="load"
                    type={'spin'}
                    color={colors.green_secondary}
                    width={'30px'}
                  />
                ) : (
                  'Editar Plano'
                )}
              </ButtonSave>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
    </>
  );
}
