import {toMoney} from 'vanilla-masker';
import React from 'react';
import {FirstPrice, P, ContainerPrice} from './styles';

export function getAdress(info) {
  if (info && info.country) {
    const street_name = info?.street_name ? info?.street_name : '';
    const city = info?.city ? `, ${info?.city}` : '';
    const street_number = info?.street_number ? `, ${info?.street_number}` : '';
    const neighborhood = info?.neighborhood ? `, ${info?.neighborhood}` : '';
    const floor = info?.floor ? `, Andar ${info?.floor}` : '';
    const unit_number = info?.unit_number ? `, Apto ${info?.unit_number}` : '';
    const state = info?.state ? `, ${info?.state}` : '';
    const country = info?.country ? `, ${info?.country}` : '';
    return `${street_name}${street_number}${neighborhood}${unit_number}${floor}${city}${state}${country} `;
  }
  return 'Não Definido';
}

export function getPrice(info, currencyValues, flag, styles) {
  if (info.price) {
    switch (info.price_currency?.toUpperCase()) {
      case 'BRL':
        return itsBRL(info.price, currencyValues, flag, styles);
      case 'USD':
        return itsUSD(info.price, currencyValues, flag, styles);
      case 'EUR':
        return itsEUR(info.price, currencyValues, flag, styles);
      default:
        return 'Não Definido';
    }
  } else {
    return 'Não Definido';
  }
}

export function getMonthly(info, currencyValues, flag) {
  if (info.monthly_maintenance_fee) {
    switch (info.monthly_maintenance_fee_currency) {
      case 'BRL':
        return itsBRL(info.monthly_maintenance_fee, currencyValues, flag);
      case 'USD':
        return itsUSD(info.monthly_maintenance_fee, currencyValues, flag);
      case 'EUR':
        return itsEUR(info.monthly_maintenance_fee, currencyValues, flag);
      default:
        return 'Não Definido';
    }
  } else {
    return 'Não Definido';
  }
}

export function getMonthlyTax(info, currencyValues, flag) {
  if (info.monthly_tax_fee) {
    switch (info.monthly_tax_fee_currency) {
      case 'BRL':
        return itsBRL(info.monthly_tax_fee, currencyValues, flag);
      case 'USD':
        return itsUSD(info.monthly_tax_fee, currencyValues, flag);
      case 'EUR':
        return itsEUR(info.monthly_tax_fee, currencyValues, flag);
      default:
        return 'Não Definido';
    }
  } else {
    return 'Não Definido';
  }
}

function itsBRL(price, currencyValues, flag, styles) {
  if (flag) {
    return [
      transformPriceBRL(price),
      transformPriceBRLtoUSD(price, currencyValues),
      transformPriceBRLtoEUR(price, currencyValues),
    ];
  }
  return (
    <ContainerPrice style={styles}>
      <FirstPrice>{transformPriceBRL(price)}</FirstPrice>
      <P>{transformPriceBRLtoUSD(price, currencyValues)}</P>
      <P>{transformPriceBRLtoEUR(price, currencyValues)}</P>
    </ContainerPrice>
  );
}

function itsUSD(price, currencyValues, flag, styles) {
  if (flag) {
    return [
      transformPriceUSD(price),
      transformPriceUSDtoBRL(price, currencyValues),
      transformPriceUSDtoEUR(price, currencyValues),
    ];
  }
  return (
    <ContainerPrice style={styles}>
      <FirstPrice>{transformPriceUSD(price)}</FirstPrice>
      <P>{transformPriceUSDtoBRL(price, currencyValues)}</P>
      <P>{transformPriceUSDtoEUR(price, currencyValues)}</P>
    </ContainerPrice>
  );
}

function itsEUR(price, currencyValues, flag, styles) {
  if (flag) {
    return [
      transformPriceEUR(price),
      transformPriceEURtoBRL(price, currencyValues),
      transformPriceEURtoUSD(price, currencyValues),
    ];
  }
  return (
    <ContainerPrice style={styles}>
      <FirstPrice>{transformPriceEUR(price)}</FirstPrice>
      <P>{transformPriceEURtoBRL(price, currencyValues)}</P>
      <P>{transformPriceEURtoUSD(price, currencyValues)}</P>
    </ContainerPrice>
  );
}

function transformPriceBRLtoUSD(price, currencyValues) {
  return `US$ ${toMoney((price / currencyValues?.USD?.ask).toFixed(0), {
    precision: 0,
    separator: '',
    delimiter: ',',
  })}`;
}

function transformPriceBRLtoEUR(price, currencyValues) {
  return `€ ${toMoney((price / currencyValues?.EUR?.ask).toFixed(0), {
    precision: 0,
    separator: '',
    delimiter: '.',
  })}`;
}

function transformPriceUSDtoBRL(price, currencyValues) {
  return `R$ ${toMoney((price * currencyValues?.USD?.ask).toFixed(0), {
    precision: 0,
    separator: '',
    delimiter: '.',
  })}`;
}

function transformPriceUSDtoEUR(price, currencyValues) {
  return `€ ${toMoney(
    ((price * currencyValues?.USD?.ask) / currencyValues?.EUR?.ask).toFixed(0),
    {
      precision: 0,
      separator: '',
      delimiter: '.',
    },
  )}`;
}

function transformPriceEURtoBRL(price, currencyValues) {
  return `R$ ${toMoney((price * currencyValues?.EUR?.ask).toFixed(0), {
    precision: 0,
    separator: '',
    delimiter: '.',
  })}`;
}

function transformPriceEURtoUSD(price, currencyValues) {
  return `US$ ${toMoney(
    ((price * currencyValues?.EUR?.ask) / currencyValues?.USD?.ask).toFixed(0),
    {
      precision: 0,
      separator: '',
      delimiter: ',',
    },
  )}`;
}

function transformPriceEUR(float) {
  return `€ ${toMoney(parseFloat(float).toFixed(0), {
    precision: 0,
    separator: '',
    delimiter: '.',
  })}`;
}

function transformPriceUSD(float) {
  return `US$ ${toMoney(parseFloat(float).toFixed(0), {
    precision: 0,
    separator: '',
    delimiter: ',',
  })}`;
}

function transformPriceBRL(float) {
  return `R$ ${toMoney(parseFloat(float).toFixed(0), {
    precision: 0,
    separator: '',
    delimiter: '.',
  })}`;
}
