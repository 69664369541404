import React from 'react';
import Autosuggest from 'react-autosuggest';
import I18n from '~/i18n/i18';
import {getRoute} from '~/utils';
import './styles.css';

// Imagine you have a list of languages that you'd like to autosuggest.

export default class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
    };
    // console.log('Deu ruim', this.props.optionsAll)
  }

  onChange = (event, {newValue}) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.optionsAll.filter(
          (lang) =>
            lang[getRoute() !== '' ? 'name_' + getRoute() : 'name_en']
              .toLowerCase()
              .slice(0, inputLength) === inputValue,
        );
  };

  getSuggestionValue = (suggestion) => {
    let index = this.props.options.findIndex((val) => val.id === suggestion.id);
    if (index < 0) {
      suggestion.choose = true;
      this.props.setOptions([...this.props.options, suggestion]);
      return '';
    }
    return '';
  };

  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderSuggestion = (suggestion) => (
    <div style={{marginTop: '4px'}}>
      {suggestion[getRoute() !== '' ? 'name_' + getRoute() : 'name_en']}
    </div>
  );

  render() {
    const {value, suggestions} = this.state;

    const inputProps = {
      placeholder: I18n.getTranslation(
        this.props.location,
        'DetailsIntern.addBtnPlaceholder',
      ),
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}
