import styled from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const TitleIntentions = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 18px;
  color: #1d1d1b;
`;

export const Key = styled.span`
  font-size: 14px;
  color: ${colors.green_secondary};
  font-family: ${fonts.latoRegularBold};
`;

export const Value = styled.span`
  font-size: 16px;
  color: ${colors.dark};
  font-family: ${fonts.latoRegular};
  mix-blend-mode: hard-light;
`;
