import axios from 'axios';
import {checkTokenExpiration} from '~/requests/User/check';
import {clearUser} from '~/requests/User/clearUserInfo';

const apiBackend = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND}/api`,
});

/* Para interceptar as chamadas e caso alguma dê não autorizado desloga o usuário ou faz algo que queira */

apiBackend.interceptors.response.use(
  async (res) => res,
  async (err) => {
    if (err.response.status === 401) {
      try {
        const {data} = await checkTokenExpiration();
      } catch (err) {
        clearUser();
        window.location.href = '/';
      }
    }

    return Promise.reject(err);
  },
);

export default apiBackend;
