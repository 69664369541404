import React, {useEffect} from 'react';

import Grid from '@material-ui/core/Grid';

import MenuProfile from '~/components/Menu/MenuProfile';
import InputText from '../components/InputTextRequired';
import AddressInput from '../components/AddressInput';
import SelectCustomStatus from '../components/SelectCustomStatus';
import SelectCustom from '../components/SelectCustom';

import * as validate from './validations';

import * as S from './styles';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import SelectCustomPlan from '../components/SelectCustomPlan';

import ReactLoading from 'react-loading';

import colors from '~/UI/colors';

import {useHistory} from 'react-router-dom';
import {fireActionMethods} from '~/utils/swalMethods';

import * as Sentry from '@sentry/react';

const countryItems = ['United States', 'Brazil', 'Portugal'];
const statusItens = [
  {name: 'Atendido', status: 'attended'},
  {name: 'Não Vendido', status: 'noSold'},
];

function RealStateEdit(props) {
  const user = props.location?.state?.user;

  const [status, setStatus] = React.useState(statusItens[0]);
  /* General Data */
  const [fantasyName, setFantasyName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [cnpj, setCnpj] = React.useState('');
  const [name, setName] = React.useState('');
  const [surname, setSurname] = React.useState('');
  const [cpf, setCpf] = React.useState('');
  const [license, setLicense] = React.useState('');
  const [role, setRole] = React.useState('');

  /* Contact Data */
  const [email, setEmail] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [facebook, setFacebook] = React.useState('');
  const [instagram, setInstagram] = React.useState('');
  const [linkedin, setLinkedin] = React.useState('');

  /* Localization Data */
  const [address, setAddress] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [neighborhood, setNeighborhood] = React.useState('');
  const [complement, setComplement] = React.useState('');
  const [cep, setCep] = React.useState('');
  const [city, setCity] = React.useState('');
  const [uf, setUf] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [latitude, setLatitude] = React.useState('');
  const [longitude, setLongitude] = React.useState('');

  /* Payment Data */
  const [plan_id, setPlan_id] = React.useState('');
  const [planList, setPlanList] = React.useState([]);

  /* XML Data */
  const [xml, setXml] = React.useState('');

  /* Errors and touched controls */
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();

  useEffect(() => {
    loadDataUser();
  }, []);

  function loadDataUser() {
    console.log(user);

    setRole(user?.profile?.job);
    setContact(user?.cellphone);
    setEmail(user?.email);
    setName(user?.name);
    setSurname(user?.surname);

    setAddress(user?.profile?.address);
    setNeighborhood(user?.profile?.neighborhood);
    setNumber(user?.profile?.address_number);
    setCity(user?.profile?.city);
    setUf(user?.profile?.state);
    setCep(user?.profile?.zip_code);
    setCountry(user?.profile?.country);

    setCpf(user?.profile?.cpf);
    setCnpj(user?.profile?.cnpj);

    setCompanyName(user?.profile?.company_name);
    setFantasyName(user?.profile?.enterprise);
    setLicense(user?.profile?.license);

    setWebsite(user?.profile?.website);
    setInstagram(user?.profile?.instagram);
    setLinkedin(user?.profile?.linkedin);
    setFacebook(user?.profile?.facebook);
    setComplement(user?.profile?.address_complement);
  }

  function handleRegister() {
    updateProfile();
  }

  function goBack() {
    history.push('/real-state');
  }

  function updateProfile() {
    setLoading(true);
    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .put(
        `v1/users/${user.id}`,
        {
          name: name.trim(),
          email: email.trim(),
          cellphone: contact.trim(),
          surname: surname,
          profile: {
            enterprise: fantasyName,
            company_name: companyName,
            cnpj: cnpj,
            license: license,
            cpf: cpf,
            job: role,
            website: website,
            instagram: instagram,
            facebook: facebook,
            linkedin: linkedin,
            country: country,
            state: uf,
            city: city,
            address: address,
            neighborhood: neighborhood,
            address_number: number,
            address_complement: complement,
            zip_code: cep,
            latitude: latitude,
            longitude: longitude,
          },
        },
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        console.log(response);
        fireActionMethods(
          'Imobiliária Editada',
          `Imobiliaria ${fantasyName} editada com sucesso`,
          'success',
          goBack,
        );
      })
      .catch((e) => Sentry.captureException(e))
      .finally(() => setLoading(false));
  }

  function validAllInputs() {
    return (
      !cpf?.trim() ||
      cpf?.trim().length < 3 ||
      (license?.trim() && license?.trim().length < 2) ||
      !fantasyName?.trim() ||
      fantasyName?.trim().length < 3 ||
      !companyName?.trim() ||
      companyName?.trim().length < 3 ||
      !cnpj?.trim() ||
      cnpj?.trim().length < 3 ||
      !name?.trim() ||
      name?.trim().length < 3 ||
      !surname?.trim() ||
      surname?.trim().length < 3 ||
      (role?.trim() && role?.trim().length < 3) ||
      !email?.trim() ||
      email?.trim().length < 3 ||
      !contact?.trim() ||
      contact?.trim().length < 3 ||
      (website?.trim() && website?.trim().length < 3) ||
      (facebook?.trim() && facebook?.trim().length < 3) ||
      (instagram?.trim() && instagram?.trim().length < 3) ||
      (linkedin?.trim() && linkedin?.trim().length < 3) ||
      !address?.trim() ||
      address?.trim().length < 3 ||
      !neighborhood?.trim() ||
      neighborhood?.trim().length < 3 ||
      !number?.trim() ||
      number?.trim().length < 3 ||
      (complement?.trim() && complement?.trim().length < 3) ||
      !cep?.trim() ||
      cep?.trim().length < 3 ||
      !city?.trim() ||
      city?.trim().length < 3 ||
      !uf?.trim() ||
      uf?.trim().length < 2 ||
      !country?.trim() ||
      country?.trim().length < 3 ||
      loading
    );
  }

  /* ----------------------- */
  /* Changes and Blur */
  /* ----------------------- */
  const handleChange = (value, setValue, name, validation) => {
    setValue(value);

    setErrors((errors) => {
      return {
        ...errors,
        [name]: validation(value),
      };
    });
  };

  const handleBlur = (value, setValue, name, validation) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        [name]: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        [name]: validation(value),
      };
    });
  };

  return (
    <>
      <MenuProfile title="Atualização de imobiliária" />
      <S.Container>
        {/* ----------------------------------- */}
        {/* General */}
        {/* ----------------------------------- */}
        <S.Step>
          {/* <S.TitleSection>Atendimento</S.TitleSection>
          <Grid
            container
            spacing={2}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              width: '100%',
              margin: '0 auto',
            }}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <SelectCustomStatus
                label="Status"
                i18n="Country"
                placeholder="Informe o status"
                value={status}
                items={statusItens}
                setValue={setStatus}
              />
            </Grid>
          </Grid> */}
          {status.status !== 'noSold' && (
            <>
              <S.TitleSection style={{marginTop: '30px'}}>
                Dados Gerais
              </S.TitleSection>
              <Grid
                container
                spacing={2}
                style={{
                  width: '100%',
                  margin: '0 auto 30px',
                }}>
                {/* -------- Line 1 --------- */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Nome Fantasia"
                    placeholder="Informe o nome fantasia..."
                    value={fantasyName}
                    setValue={setFantasyName}
                    error={errors.fantasyName}
                    touched={touched.fantasyName}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setFantasyName,
                        'fantasyName',
                        validate.fantasyNameSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setFantasyName,
                        'fantasyName',
                        validate.fantasyNameSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Razão Social"
                    placeholder="Informe a razão social..."
                    value={companyName}
                    setValue={setCompanyName}
                    error={errors.companyName}
                    touched={touched.companyName}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setCompanyName,
                        'companyName',
                        validate.companyNameSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setCompanyName,
                        'companyName',
                        validate.companyNameSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="CNPJ"
                    placeholder="Informe o CNPJ..."
                    value={cnpj}
                    setValue={setCnpj}
                    error={errors.cnpj}
                    touched={touched.cnpj}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setCnpj,
                        'cnpj',
                        validate.cnpjSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setCnpj,
                        'cnpj',
                        validate.cnpjSchema,
                      )
                    }
                  />
                </Grid>
                {/* ------------ Line 2 ----------- */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Nome"
                    placeholder="Informe o nome..."
                    value={name}
                    setValue={setName}
                    error={errors.name}
                    touched={touched.name}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setName,
                        'name',
                        validate.nameSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setName,
                        'name',
                        validate.nameSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Sobrenome"
                    placeholder="Informe o sobrenome..."
                    value={surname}
                    setValue={setSurname}
                    error={errors.surname}
                    touched={touched.surname}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setSurname,
                        'surname',
                        validate.surnameSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setSurname,
                        'surname',
                        validate.surnameSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="CPF"
                    placeholder="Informe o CPF..."
                    value={cpf}
                    setValue={setCpf}
                    error={errors.cpf}
                    touched={touched.cpf}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setCpf,
                        'cpf',
                        validate.cpfSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setCpf,
                        'cpf',
                        validate.cpfSchema,
                      )
                    }
                  />
                </Grid>
                {/* ------- Line 3 -------- */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Licença (CRECI)"
                    placeholder="Informe a licença..."
                    value={license}
                    setValue={setLicense}
                    error={errors.license}
                    touched={touched.license}
                    optional={true}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setLicense,
                        'license',
                        validate.licenseSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setLicense,
                        'license',
                        validate.licenseSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Cargo"
                    placeholder="Informe o cargo..."
                    value={role}
                    setValue={setRole}
                    error={errors.role}
                    touched={touched.role}
                    optional={true}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setRole,
                        'role',
                        validate.roleSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setRole,
                        'role',
                        validate.roleSchema,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}
        </S.Step>
        {/* ----------------------------------- */}
        {/* Contact */}
        {/* ----------------------------------- */}
        {status.status !== 'noSold' && (
          <>
            {' '}
            <S.Step mt="30px">
              <S.TitleSection>Contatos</S.TitleSection>
              <Grid
                container
                spacing={2}
                style={{
                  width: '100%',
                  margin: '0 auto 30px',
                }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="E-mail"
                    placeholder="Informe o e-mail..."
                    value={email}
                    setValue={setEmail}
                    error={errors.email}
                    touched={touched.email}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setEmail,
                        'email',
                        validate.emailSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setEmail,
                        'email',
                        validate.emailSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Contato"
                    placeholder="Informe o contato..."
                    value={contact}
                    setValue={setContact}
                    error={errors.contact}
                    touched={touched.contact}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setContact,
                        'contact',
                        validate.contactSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setContact,
                        'contact',
                        validate.contactSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Website"
                    placeholder="Informe o website..."
                    value={website}
                    setValue={setWebsite}
                    error={errors.website}
                    optional={true}
                    touched={touched.website}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setWebsite,
                        'website',
                        validate.websiteSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setWebsite,
                        'website',
                        validate.websiteSchema,
                      )
                    }
                  />
                </Grid>
                {/* ------------ Line 2 ----------- */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Facebook"
                    placeholder="Informe o facebook..."
                    value={facebook}
                    setValue={setFacebook}
                    optional={true}
                    error={errors.facebook}
                    touched={touched.facebook}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setFacebook,
                        'facebook',
                        validate.facebookSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setFacebook,
                        'facebook',
                        validate.facebookSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Instagram"
                    placeholder="Informe o instagram..."
                    value={instagram}
                    setValue={setInstagram}
                    error={errors.instagram}
                    touched={touched.instagram}
                    optional={true}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setInstagram,
                        'instagram',
                        validate.instagramSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setInstagram,
                        'instagram',
                        validate.instagramSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Linkedin"
                    placeholder="Informe o linkedin..."
                    value={linkedin}
                    setValue={setLinkedin}
                    error={errors.linkedin}
                    optional={true}
                    touched={touched.linkedin}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setLinkedin,
                        'linkedin',
                        validate.linkedinSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setLinkedin,
                        'linkedin',
                        validate.linkedinSchema,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </S.Step>
            {/* ----------------------------------- */}
            {/* Localization */}
            {/* ----------------------------------- */}
            <S.Step mt="30px">
              <S.TitleSection>Localização</S.TitleSection>
              <Grid
                container
                spacing={2}
                style={{
                  width: '100%',
                  margin: '0 auto 30px',
                }}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <AddressInput
                    label="Endereço"
                    placeholder="Informe o endereço..."
                    value={address}
                    setValue={setAddress}
                    setLat={setLatitude}
                    setLong={setLongitude}
                    setNeighborhood={setNeighborhood}
                    setCity={setCity}
                    setUf={setUf}
                    setCountry={setCountry}
                    setCep={setCep}
                    errors={errors.address}
                    touched={touched.address}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <InputText
                    max={6}
                    label="Número"
                    placeHolder="Informe o número..."
                    value={number}
                    setValue={setNumber}
                    error={errors.number}
                    touched={touched.number}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setNumber,
                        'number',
                        validate.numberSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setNumber,
                        'number',
                        validate.numberSchema,
                      )
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <InputText
                    label="Bairro"
                    placeholder="Informe o bairro..."
                    value={neighborhood}
                    setValue={setNeighborhood}
                    error={errors.neighborhood}
                    touched={touched.neighborhood}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setNeighborhood,
                        'neighborhood',
                        validate.neighborhoodSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setNeighborhood,
                        'neighborhood',
                        validate.neighborhoodSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <InputText
                    label="Complemento"
                    optional={true}
                    placeholder="Informe o complemento..."
                    value={complement}
                    setValue={setComplement}
                    error={errors.complement}
                    touched={touched.complement}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setComplement,
                        'complement',
                        validate.complementSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setComplement,
                        'complement',
                        validate.complementSchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="CEP"
                    placeholder="Informe o CEP..."
                    value={cep}
                    setValue={setCep}
                    error={errors.cep}
                    touched={touched.cep}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setCep,
                        'cep',
                        validate.cepSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setCep,
                        'cep',
                        validate.cepSchema,
                      )
                    }
                  />
                </Grid>
                {/* ------------ Line 2 ----------- */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Cidade"
                    placeholder="Informe a cidade..."
                    value={city}
                    setValue={setCity}
                    error={errors.city}
                    touched={touched.city}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setCity,
                        'city',
                        validate.citySchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setCity,
                        'city',
                        validate.citySchema,
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="UF"
                    placeholder="Informe a UF..."
                    value={uf}
                    setValue={setUf}
                    error={errors.uf}
                    touched={touched.uf}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setUf,
                        'uf',
                        validate.ufSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(e.target.value, setUf, 'uf', validate.ufSchema)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <SelectCustom
                    label="País"
                    i18n="Country"
                    placeholder="Informe o país"
                    value={country}
                    items={countryItems}
                    setValue={setCountry}
                  />
                </Grid>
              </Grid>
            </S.Step>
            {/* ----------------------------------- */}
            {/* Payment */}
            {/* ----------------------------------- */}
            {/* <S.Step mt="30px"> */}
            {/* <S.TitleSection>Pagamento</S.TitleSection>
              <Grid
                container
                spacing={2}
                style={{
                  width: '100%',
                  margin: '0 auto 30px',
                }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectCustomPlan
                      label="Plano de pagamento"
                      placeholder="Selecione um plano"
                      value={plan_id}
                      items={planList}
                      setValue={setPlan_id}
                    />
                  </Grid> */}
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <S.LinkPayment>
                      <strong>Link para página de pagamento</strong>
                      <div>
                        <a href="#">https://permuti.com/plano-pagamento/gold</a>
                        <S.ButtonCopy>Copiar link</S.ButtonCopy>
                      </div>
                    </S.LinkPayment>
                  </Grid> */}
            {/* </Grid>

                {plan_id && (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <S.PlanCard>
                      <div className="planName">{plan_id.name}</div>
                      <span className="rows">
                        <div className="rows">
                          <div className="price_plan">{plan_id.price} USD</div>{' '}
                          <div className="period">&nbsp; /mês</div>
                        </div>{' '}
                        <div>
                          <b>{plan_id.ads_quantity}</b> imóveis
                        </div>
                      </span>
                    </S.PlanCard>
                  </Grid>
                )}
              </Grid>
            </S.Step> */}
            {/* ----------------------------------- */}
            {/* Advertise */}
            {/* ----------------------------------- */}
            {/* <S.Step mt="30px">
              <S.TitleSection>Anúncios</S.TitleSection>
              <Grid
                container
                spacing={2}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  width: '100%',
                  margin: '0 auto',
                }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputText
                    label="Link XML"
                    placeholder="Informe o link do XML..."
                    value={xml}
                    setValue={setXml}
                    error={errors.xml}
                    touched={touched.xml}
                    handleChange={(e) =>
                      handleChange(
                        e.target.value,
                        setXml,
                        'xml',
                        validate.xmlSchema,
                      )
                    }
                    handleBlur={(e) =>
                      handleBlur(
                        e.target.value,
                        setXml,
                        'xml',
                        validate.xmlSchema,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </S.Step> */}
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '50px',
          }}>
          {status.status === 'noSold' ? (
            <S.ButtonAds onClick={() => handleRegister()}>
              {loading ? (
                <ReactLoading
                  type={'spin'}
                  className="load"
                  color={colors.green_secondary}
                  width={'30px'}
                />
              ) : status.status !== 'noSold' ? (
                'EDITAR'
              ) : (
                'FINALIZAR'
              )}
            </S.ButtonAds>
          ) : (
            <S.ButtonAds
              disabled={validAllInputs()}
              onClick={() => handleRegister()}>
              {loading ? (
                <ReactLoading
                  type={'spin'}
                  className="load"
                  color={colors.green_secondary}
                  width={'30px'}
                />
              ) : status.status !== 'noSold' ? (
                'EDITAR'
              ) : (
                'FINALIZAR'
              )}
            </S.ButtonAds>
          )}
        </div>
      </S.Container>
    </>
  );
}

export default RealStateEdit;
