import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ButtonsGreenSend,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import baseURL from '../../services/api';
import InputRequired from './inputJustify';
import SelectCustomPlan from '~/views/RealState/Attended/SelectCustomPlan';
import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {fireActionMethods} from '~/utils/swalMethods';

import * as Sentry from '@sentry/react';

export default function ModalPaymentPlan({
  id,
  open,
  setOpen,
  getProperties,
  planList,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const [plan, setPlan] = React.useState('');

  async function addPlan(event) {
    const token = desencrypta(localStorage.getItem('@User_informations'));

    apiBackend
      .post(
        'v1/subscriber/real/state',
        {
          plan_id: plan.id,
          user_id: id,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        fireActionMethods(
          'Tudo certo!',
          'Plano Associado ao imóvel com sucesso',
          'success',
          getProperties,
        );
        handleClose();
      })
      .catch((e) => Sentry.captureException(e));
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span></span>
            <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
          </TitleModal>
          <InternDiv>
            <ModalResponsive>
              <DivDanger>
                <FontAwesomeIcon
                  size="2x"
                  color="#A88721"
                  icon={faExclamationCircle}
                />
                <AdvertTitle> Atenção</AdvertTitle>
                <Message>
                  Ao cadastrar esta imobiliária não foi associado um plano, para
                  gerar o link do pagamento é necessário escolher um plano dos
                  listados abaixo.
                </Message>

                <DivConfirm>
                  <SelectCustomPlan
                    label="Plano de pagamento"
                    placeholder="Selecione um plano"
                    value={plan}
                    items={planList}
                    setValue={setPlan}
                  />
                </DivConfirm>

                <DivConfirm>
                  <ButtonsGreenSend
                    style={{width: '100%'}}
                    onClick={(event) => addPlan(event)}>
                    ENVIAR
                  </ButtonsGreenSend>
                </DivConfirm>
              </DivDanger>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
