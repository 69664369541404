import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../UI/colors';

export default function InputText(props) {



    function change(event) {
        if (props.mask) {
            props.mask(event, props.setValue)
        } else {
            props.setValue(event.target.value);
        }
    }

    return (
        <div>
            <Label color={props.value}>{props.label} </Label>
            <Input disabled={props.disable} minLength={props.min ? props.min : null} maxLength={props.max ? props.max : null} color={props.value} value={props.value} onChange={(event) => change(event)} itens={props.itens} placeholder={props.placeHolder}></Input>
        </div>
    );
}



const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${props => props.value ? '2px solid ' + colors.green_secondary : '#808076 1px solid'};
  color:  ${props => props.value ? '#1D1D1B' : '#808076'};
  width: 100%;
  font-size: 18px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus{
        outline: 0;    
  }
  &:disabled{

  }
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: bold;
  color:  ${props => props.color ? colors.green_secondary : '#808076'}
`