import React, {useState} from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import I18n from '~/i18n/i18';

const useStyles = makeStyles({
  root: {},
  item: {
    fontFamily: fonts.latoRegular,
    width: '60px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    fontFamily: fonts.latoRegular,
    width: '200px',
    marginTop: '10px',
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});

export default function Dimension(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClickChoose(item) {
    setAnchorEl(null);
    props.setValue(item);
  }

  return (
    <div>
      <DivText onClick={handleClick} pdTop={props.pdTop}>
        <span>
          <I18n t={`CurrencySymbol.${props?.value}`} />
        </span>{' '}
        {anchorEl ? <ExpandLess /> : <ExpandMore />}
      </DivText>

      <Menu
        className={classes.root}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setOpenCollapse(!openCollapse);
        }}>
        {props?.itens?.map((item, key) => (
          <MenuItem
            key={key}
            className={classes.item}
            onClick={() => handleClickChoose(item)}>
            <I18n t={`CurrencySymbol.${item}`} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const DivText = styled.div`
  width: max-content;
  padding-top: ${(props) => (props.pdTop ? `${props.pdTop}px` : '15px')};
  text-align: center;
  color: #1d1d1b;
  font-size: 18px;
  font-family: ${fonts.latoRegular};

  justify-content: center;
  display: flex;
`;
