import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Dash from '~/components/Dashboard';
import Loader from '../loader';
import TableColaborator from './Table';
import ModalRegisterColaborator from './Register';
import ModalColab from '~/components/ModalColabInfo';
import MenuFilter from '~/components/Menu/MenuFilter';
import Pagination from '~/components/_PaginationTab';
import {desencrypta} from '~/utils';
import apiBackend from '~/services/apiaxios';

import {ButtonMedium, ButtonSmall, InputSearch} from '~/UI/styles';
import {ActionsContainer, ContainerPagination} from '../Clients/styles';
import {fireAction} from '~/utils/swal';
import {ClearFiltersButton, Results} from '../styles';

import * as Sentry from '@sentry/react';

const buttonSmallOverride = {
  letterSpacing: '1.8px',
};
const itensCollaborator = ['All', 'Seller', 'Approved', 'Manager', 'Admin'];
const permitted_roles = ['5'];

export default function Colaborators({display, infoUser}) {
  const userStore = useSelector((state) => state.user);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openRegister, setOpenRegister] = useState(false);
  const [infoData, setInfoData] = useState([]);
  const [modalColab, setModalColab] = useState(false);
  const [modalColabInfo, setModalColabInfo] = useState('');
  const [modalEdit, setModalEdit] = useState('');
  const [viewTable, setViewTable] = useState(true);
  const [modalRegister, setModalRegister] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [proibid, setProibid] = useState('');

  /* ------------------------------------------ */
  const [filterName, setFilterName] = useState('');
  const [filterByType, setFilterByType] = useState('');
  const [query, setQuery] = useState(new URLSearchParams());

  /* --- PAGINATION TABLE --- */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const handleChangePage = (value) => {
    console.log(value);
    setPage(value);
  };

  async function listUsers() {
    try {
      setLoader(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/users?paginate=true&page=${page}&limit=10&role_id=!1`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );
      setTotalResults(data?.users?.meta?.total);
      setLastPage(data?.users?.meta?.last_page);
      setInfoData(data?.users?.data);
      setDataSearch(data?.users?.data);
      setLoader(false);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
    }
  }

  async function filterUsers(query) {
    try {
      setLoader(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(`/v1/users?${query}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      setTotalResults(data?.users?.length);
      setLastPage(0);
      setInfoData(data?.users);
      setDataSearch(data?.users);
      setLoader(false);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
    }
  }

  useEffect(() => {
    if (!query.toString()) {
      listUsers(page);
    } else {
      filterUsers(query.toString());
    }
  }, [page, query]);

  function handleFilter() {
    var query = new URLSearchParams();
    if (filterName?.trim()) {
      query.append('term', filterName?.trim());
    }
    if (filterByType && filterByType !== 'All') {
      query.append('role_id', itensCollaborator.indexOf(filterByType) + 1);
    }

    if (query.toString() === '' && page > 1) {
      setQuery(new URLSearchParams());
      return setPage(1);
    }

    setQuery(query);
  }

  function handleClearFilter() {
    setFilterName('');
    setFilterByType('');
    setQuery(new URLSearchParams());
  }

  /* ------------ */
  /* ------------ */
  /* ------------ */

  /* ----------- REGISTER ----------- */
  const handleRegister = React.useCallback(
    async (values) => {
      /* console.log(JSON.stringify(values, null, 2)); */
      try {
        setIsSubmitting(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.post(`/v1/users/collaborator`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(data);
        setOpenRegister(false);
        fireAction(
          'Tudo certo',
          'O cadastro do novo colaborador foi realizado com sucesso.',
          'success',
        );
        /* Return to page 1 with new list */
        if (page === 1) {
          listUsers(1);
        } else {
          setPage(1);
        }
      } catch (err) {
        /* Email has exists */
        if (err.response.status === 422) {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Já existe um colaborador cadastrado com o e-mail informado. Por favor, tente outro e-mail.',
            'error',
          );
        } else {
          /* error not especifc */
          Sentry.captureException(err);
          fireAction(
            'Não foi possível completar sua solicitação',
            'Um erro interno ocorreu durante o cadastro do novo colaborador. Por favor, tente novamente mais tarde.',
            'error',
          );
        }

        console.log(err);
      } finally {
        setIsSubmitting(false);
      }
    },
    [page],
  );

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*   useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  return (
    <Dash button={'user'} title={'Colaboradores'}>
      <div
        style={{
          display: display,
        }}>
        <ActionsContainer>
          {isPermitted() && (
            <ButtonMedium
              onClick={() => {
                setOpenRegister(true);
              }}>
              Cadastrar Colaborador
            </ButtonMedium>
          )}

          <Grid
            container
            spacing={4}
            style={{marginBottom: '30px', marginTop: '30px'}}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <InputSearch
                value={filterName}
                onChange={(event) => setFilterName(event.target.value)}
                placeholder="Pesquisar por usuários..."
                style={{marginRight: '30px'}}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <MenuFilter
                state={filterByType}
                setState={setFilterByType}
                itens={itensCollaborator}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <ButtonSmall style={buttonSmallOverride} onClick={handleFilter}>
                PESQUISAR
              </ButtonSmall>
            </Grid>
          </Grid>
        </ActionsContainer>

        {query.toString() && (
          <ClearFiltersButton onClick={handleClearFilter}>
            Limpar filtros
          </ClearFiltersButton>
        )}

        {loader ? (
          <Loader />
        ) : infoData?.length > 0 ? (
          <>
            <TableColaborator
              infoUser={infoUser}
              info={dataSearch}
              setInfo={setDataSearch}
              setModalRegister={setModalRegister}
              setModal={setModalColab}
              setModalEdit={setModalEdit}
              setModalInfo={setModalColabInfo}
              /* new */
              total={totalResults}
              /* rerender controls */
              listUser={listUsers}
              page={page}
              setPage={setPage}
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ContainerPagination>
                {lastPage > 1 && (
                  <Pagination
                    count={lastPage}
                    page={page}
                    handleChange={(event, value) => handleChangePage(value)}
                  />
                )}
              </ContainerPagination>
            </Grid>
          </>
        ) : (
          <Results>Nenhum resultado encontrado...</Results>
        )}
        {/* Modals */}
        <ModalColab
          modalInfo={modalColabInfo}
          modal={modalColab}
          setModal={setModalColab}
        />
        {isPermitted() && (
          <ModalRegisterColaborator
            handleSubmit={handleRegister}
            loading={isSubmitting}
            open={openRegister}
            setOpen={setOpenRegister}
          />
        )}
        {/*  */}
      </div>
    </Dash>
  );
}
