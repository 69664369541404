import React from 'react';
import styled from 'styled-components';
import {Divider} from '@material-ui/core';
import MapMarker from '~/assets/map-marker-alt.svg';

import {Grid} from '@material-ui/core';
import I18n from '~/i18n/i18';

function OpenWithMaps({langName, advertise}) {
  return (
    <>
      <div className="subTitleShow">Localização do Imóvel</div>
      <Grid spacing="1" container>
        <Grid
          style={{marginTop: '30px'}}
          spacing="1"
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <img alt="||" src={MapMarker} width="23px" height="18px" />

            <Link
              className="iconsFontes mg-left-21"
              target="_blank"
              rel="noreferrer noopener"
              href={`https://www.google.com/maps/?q=${advertise?.address?.latitude},${advertise?.address?.longitude}`}>
              {[
                advertise?.address?.street_name,
                advertise?.address?.neighborhood,
                advertise?.address?.city,
                advertise?.address?.zip_code,
                advertise?.address?.state,
                advertise?.address?.country,
              ]
                .filter(Boolean)
                .join(', ')}
            </Link>
          </div>
        </Grid>
      </Grid>
      <div className="mg-top-30 mg-bottom-30">
        <Divider />
      </div>
    </>
  );
}

const Link = styled.a`
  color: inherit;
`;

export default OpenWithMaps;
