import React from 'react';
import I18n from '~/i18n/i18';
// import I18n from '../../../i18n/i18';
import {Container} from './styles';
import Ilustracao404 from '../../../../assets/Ilustracao404myads.svg';

function MyAds404() {
  return (
    <Container>
      <div className="divintern">
        <img src={Ilustracao404} alt="image_404" width="100%" />
        <div className="text">
          <I18n t="MyProposal2.404" />
        </div>
      </div>
    </Container>
  );
}

export default MyAds404;
