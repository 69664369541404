import React from 'react';
import styled from 'styled-components';
import I18n from '~/i18n/i18';
import fonts from '~/UI/fonts';
// import { Container } from './styles';

function MandatoryField() {
  return (
    <Field>
      <I18n t="RequiredInput.req" />
    </Field>
  );
}

export default MandatoryField;

const Field = styled.div`
  color: #a88721;
  font-size: 14px;
  font-family: ${fonts.latoRegularBold};
  /* font-weight: bold; */
  margin-bottom: 27px;
`;
