import styled from 'styled-components';

import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const DivButtonsSwitch = styled.div`
  border-bottom: 1px #d9d9ce solid;
  margin-bottom: 30px;
  margin-top: 50px;
`;

export const ButtonsSwitch = styled.button`
  background: transparent;
  border: none;
  font-size: 18px;
  width: 100%;
  max-width: max-content;
  height: 60px;
  border-bottom: ${(props) =>
    props.pressed
      ? `5px solid ${colors.green_secondary}!important`
      : '5px solid transparent!important'};

  font-family: ${fonts.latoBlack};

  color: ${(props) => (props.pressed ? colors.green_secondary : props.color)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: ${(props) => (props.pressed ? '1' : '0.6')};
    border-bottom: ${(props) => '5px solid ' + props.color};
  }
`;

export const Results = styled.p`
  margin-left: 14px;
  font-size: 14px;
  font-family: ${fonts.latoRegular};
`;

export const ContainerPagination = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  display: grid;
  place-items: center;
`;
