import React from 'react';
import styled from 'styled-components';

import colors from '../UI/colors';
import metrics from '../UI/metrics';
import fonts from '../UI/fonts';

export default function InputTextArea(props) {
  const inputRef = React.useRef(null);

  const [textAreaHeight, setTextAreaHeight] = React.useState('auto');
  const [parentHeight, setParentHeight] = React.useState('auto');

  const parentStyle = {
    position: 'relative',
    minHeight: parentHeight,
  };

  const textAreaStyle = {
    height: textAreaHeight,
  };

  function change(event) {
    if (props.mask) {
      props.mask(event, props.setValue);
      setTextAreaHeight('auto');
      setParentHeight(`${inputRef.current.scrollHeight + 5}px`);
    } else {
      props.setValue(event.target.value);
      setTextAreaHeight('auto');
      setParentHeight(`${inputRef.current.scrollHeight + 5}px`);
    }
  }

  React.useEffect(() => {
    setParentHeight(`${inputRef.current.scrollHeight}px`);
    setTextAreaHeight(`${inputRef.current.scrollHeight + 5}px`);
  }, [props.value]);

  return (
    <div style={parentStyle}>
      <Label
        error={props.value && props.value.length > props.max}
        color={props.value}>
        {props.label}{' '}
      </Label>
      <Input
        error={props.value && props.max && props.value.length > props.max}
        ref={inputRef}
        disabled={props.disable}
        color={props.value}
        value={props.value}
        onChange={(event) => change(event)}
        itens={props.itens}
        placeholder={props.placeHolder}
        rows={props.rows ? props.rows : 1}
        style={textAreaStyle}
      />
      <span></span>
    </div>
  );
}

const Input = styled.textarea`
  min-height: 40px;
  resize: none;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.error
      ? `2px solid ${colors.danger}!important`
      : props.value
      ? `2px solid ${colors.green_secondary}!important`
      : '1px solid #808076!important'};
  color: ${(props) => (props.value ? '#1D1D1B' : '#808076')};
  width: 100%;

  font-size: 16px;
  font-family: ${(props) =>
    props.value ? fonts.latoRegular : fonts.latoRegularItalic};

  justify-content: space-between;
  justify-items: center;
  align-items: center;

  &:focus {
    outline: 0;
  }
  &:disabled {
  }
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 16px;
  color: ${(props) =>
    props.error
      ? colors.danger
      : props.color
      ? colors.green_secondary
      : '#808076'};
`;
