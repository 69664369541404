import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import I18n from '~/i18n/i18';
import setLocationWith from '~/i18n/utils/viewLang';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~/utils';
import NextPreview from '../../Components/nextPreview';
import {TitlePage} from '../../Photo/styles';
import {SaveAnnoucement} from '../../Step/styles';
import {Warning} from '../../Type/styles';
import AutoComplete from '../Components/autoComplete';
import {ButtonAdd, ButtonDetails} from '../Components/style';
import {DivNext, OptionsDetails} from './styles';
import ModalEditSucess from '../../../../../../components/ModalEditSucess';
import * as Sentry from '@sentry/react';

function Intern({
  id,
  step,
  setStep,
  options,
  optionsAll,
  setOptions,
  setOptionsAll,
  props,
}) {
  const view = useSelector((state) => state.step.data);
  const active = true;
  const [viewInput, setViewInput] = useState(false);
  const [itensExib, setItensExib] = useState([]);
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/advertise`,
    state: {fromDashboard: true},
  });
  const [openEditSucess, setOpenEditSucess] = useState(false);

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  async function updateIntern() {
    if (options.length > 0) {
      const ids = [];
      const idsDelete = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].choose) {
          ids.push(options[i].id);
        } else {
          idsDelete.push(options[i].id);
        }
      }
      console.log(ids);
      let token = desencrypta(localStorage.getItem('@User_informations'));
      apiBackend
        .put(
          `/v1/property/${id}/edit`,
          {
            features_internal_ids: ids,
            delete_internal_ids: idsDelete,
          },
          {headers: {Authorization: `Bearer ${token}`}},
        )
        .then((response) => {
          // dispatch(addMsgAction(true));
          setOpenEditSucess(true);
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
  }

  useEffect(() => {
    if (options.length > 0) {
      console.log(options);
      setItensExib(options.map((item) => setLocationWith(item)));
    }
  }, [window.location.href, options]);

  function handleProx(event) {
    event.preventDefault();
    updateIntern();
  }

  function choice(key, item, value) {
    const chooses = [...options];
    chooses[key] = {...chooses[key], choose: value};
    setOptions(chooses);
  }

  function handleButtonAddItem() {
    if (viewInput) {
    } else {
      setViewInput(true);
    }
  }

  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            {' '}
            <I18n t="DetailsIntern.title" />
          </TitlePage>
          <Warning>
            {' '}
            <I18n t="DetailsIntern.subtitle" />
          </Warning>
          <OptionsDetails>
            {options
              ? options.map((item, key) => (
                  <ButtonDetails
                    value={item.choose}
                    key={key}
                    onClick={() => {
                      choice(key, item, !item.choose);
                    }}
                    type="button">
                    {itensExib[key]}
                  </ButtonDetails>
                ))
              : null}
          </OptionsDetails>
          {viewInput ? (
            <div style={{marginTop: '50px'}}>
              <AutoComplete
                location={location}
                keyTrad="DetailsInternKey"
                options={options}
                setOptions={setOptions}
                optionsAll={optionsAll}
              />
            </div>
          ) : null}
          {!viewInput ? (
            <div style={{marginTop: '50px'}}>
              <ButtonAdd onClick={handleButtonAddItem} type="button">
                {' '}
                <I18n t="DetailsIntern.addButton" />
              </ButtonAdd>
            </div>
          ) : null}
        </div>

        <DivNext>
          <NextPreview
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>

      <ModalEditSucess
        func={() => {
          window.history.back();
        }}
        open={openEditSucess}
        setOpen={setOpenEditSucess}
      />
    </>
  );
}

export default Intern;
