import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  ModalContainer,
  TitleModal,
  DivName,
  DivGroupInfo,
  PInfo,
  CloseModal,
} from './styles';
import CloseIcon from '@material-ui/icons/Close';

import {
  translateProfileType,
  translateAccountRoleId,
} from '~/utils/profileType';
import {formatPhone} from '~/utils/regUtils';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxWidth: '540px',
    width: '96%',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 4,
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    borderColor: 'none',
    boxShadow: theme.shadows[5],
    outline: 0,
  },
}));

export default function ModalColabInfo({modalInfo, modal, setModal}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  React.useEffect(() => {
    console.log(modalInfo);
  }, [modalInfo]);

  return (
    <Modal
      open={modal}
      onClose={handleClose}
      aria-describedby="simple-modal-description">
      <div style={modalStyle} className={classes.paper}>
        <ModalContainer>
          {Object.keys(modalInfo).length && (
            <>
              <TitleModal>
                Informações do(a) colaborador(a)
                <CloseModal onClick={handleClose}>
                  <CloseIcon />
                </CloseModal>
              </TitleModal>
              <DivName>
                {modalInfo?.name} {modalInfo?.surname ?? ''}
              </DivName>
              <DivGroupInfo>
                <PInfo>
                  Tipo de acesso{' '}
                  <span>{translateAccountRoleId(modalInfo?.role_id)}</span>
                </PInfo>
                <PInfo>
                  Cargo <span>{translateProfileType(modalInfo?.cargo)}</span>
                </PInfo>
              </DivGroupInfo>

              <PInfo>
                Contato{' '}
                <span>
                  {modalInfo?.cellphone
                    ? formatPhone(modalInfo?.cellphone)
                    : '+XX XXXXX-XXXX'}
                </span>
              </PInfo>
              <PInfo>
                E-mail{' '}
                <span className="email__span">
                  {modalInfo?.email ?? 'Não definido'}
                </span>
              </PInfo>
            </>
          )}
        </ModalContainer>
      </div>
    </Modal>
  );
}
