import apiBackend from '../../services/apiaxios';
import {desencrypta} from '../../utils';

import * as Sentry from '@sentry/react';

async function getTypeStyle(setItens) {
  let token = desencrypta(localStorage.getItem('@User_informations'));
  apiBackend
    .get('/v1/style', {headers: {Authorization: `Bearer ${token}`}})
    .then((response) => {
      //   return response.data.type_property;

      setItens(response.data.styles);
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}

export default getTypeStyle;
