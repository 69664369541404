import {Divider, Grid} from '@material-ui/core';
import React from 'react';
import I18n from '../../../../../../../i18n/i18';
import {LabelCountry} from '../../../styles';

import {Key, Value} from './styles';
import './styles.css';
// import { Container } from './styles';

function ItemAccordion({details, langName}) {
  return (
    <>
      {' '}
      <div className="gridItensIntentions columnFlex">
        <Grid container>
          {
            /* details */ details.map((item) => {
              return (
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <div className="mg-top-15">
                    <Value>{item.name_pt}</Value>
                  </div>
                </Grid>
              );
            })
          }
        </Grid>
      </div>
    </>
  );
}

export default ItemAccordion;
