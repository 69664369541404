import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  TitlePage,
  Ball,
  ButtonGreen,
  ButtonEdit,
  BallWhite,
  Container,
  ButtonContainer,
  StepsContainer,
  ContainerClasses,
} from './styles';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '94%',
    marginLeft: '0!important',
    marginRight: '0!important',
  },
}));

export default function ModalEdit({open, setOpen, infoAd}) {
  const classes = useStyles();
  const [step, setStep] = useState('' /* verifyStep(infoAd.step) */);
  const [click, setClick] = useState('');
  const [link, setLink] = useState('');
  /* 
  React.useEffect(() => {
    console.log('Link:', link);
  }, [link]);

  React.useEffect(() => {
    console.log('Click:', click.toString());
  }, [click]);

  React.useEffect(() => {
    console.log('Step:', step);
  }, [step]); */

  function verifyStep(step) {
    switch (step) {
      case '4':
        return '3';
      case '5':
        return '4';
      case '6':
        return '4';
      case '7':
        return '4';
      case '8':
        return '5';
      case '9':
        return '6';
      case '10':
        return '7';
      case '11':
        return '8';
      case '12':
        return '9';
      default:
        return step;
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  function handleButton(event, choose, path) {
    event.preventDefault();
    setClick(choose);
    setLink(path);
  }

  function handleImage(event, choose, path) {
    event.preventDefault();
    setClick(choose);
    setLink(path);
  }

  return (
    <>
      <ContainerClasses>
        <Dialog
          scroll="body"
          fullWidth
          maxWidth={'lg'}
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          classes={{paper: classes.paper}}>
          <Container>
            <InternDiv>
              <TitleModal>
                <CloseIcon
                  onClick={() => handleClose()}
                  className={'iconClose'}
                />
              </TitleModal>
              <ModalResponsive>
                <TitlePage>Selecione a etapa de Edição do anúncio</TitlePage>
              </ModalResponsive>
            </InternDiv>

            <StepsContainer>
              <div>
                <ButtonEdit
                  me={true}
                  click={1 === click}
                  onClick={(event) => {
                    handleImage(event, 1, 'photos');
                  }}>
                  <div className="rowsJ">
                    <Ball>1</Ball> <p>Imagens do imóvel</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={2 === click}
                  onClick={(event) => {
                    handleButton(event, 2, 'type');
                  }}>
                  <div className="rowsJ">
                    <Ball>2</Ball> <p>Tipo de imóvel</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={3 === click}
                  onClick={(event) => {
                    handleButton(event, 3, 'localize');
                  }}>
                  <div className="rowsJ">
                    <Ball>3</Ball> <p>Local do imóvel</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={5 === click}
                  onClick={(event) => {
                    handleButton(event, 5, 'propertyone');
                  }}>
                  <div className="rowsJ">
                    <Ball>4</Ball> <p>Características do imóvel</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={8 === click}
                  onClick={(event) => {
                    handleButton(event, 8, 'detailintern');
                  }}>
                  <div className="rowsJ">
                    <Ball>5</Ball> <p>Detalhes internos</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>
              </div>

              <div>
                <ButtonEdit
                  me={true}
                  click={9 === click}
                  onClick={(event) => {
                    handleButton(event, 9, 'detailextern');
                  }}>
                  <div className="rowsJ">
                    <Ball>6</Ball> <p>Detalhes Externos</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={10 === click}
                  onClick={(event) => {
                    handleButton(event, 10, 'financial');
                  }}>
                  <div className="rowsJ">
                    <Ball>7</Ball> <p>Valor do imóvel</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={11 === click}
                  onClick={(event) => {
                    handleButton(event, 11, 'rent');
                  }}>
                  <div className="rowsJ">
                    <Ball>8</Ball> <p>Imóvel para aluguel</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={12 === click}
                  onClick={(event) => {
                    handleButton(event, 12, 'status');
                  }}>
                  <div className="rowsJ">
                    <Ball>9</Ball> <p>Status do imóvel</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>

                <ButtonEdit
                  me={true}
                  click={13 === click}
                  onClick={(event) => {
                    handleButton(event, 13, 'desired');
                  }}>
                  <div className="rowsJ">
                    <Ball>10</Ball> <p>Imóvel desejado</p>
                  </div>
                  <BallWhite>
                    <FontAwesomeIcon icon={faCheck} />
                  </BallWhite>
                </ButtonEdit>
              </div>
            </StepsContainer>

            <ButtonContainer>
              <Link
                style={{textDecoration: 'none'}}
                to={{
                  pathname: `/view/edit`,
                  state: {id: infoAd.id, step: click, property: infoAd},
                }}>
                <ButtonGreen disabled={click ? false : true}>
                  <EditIcon /> Editar Anúncio
                </ButtonGreen>
              </Link>

              {/* </Link> */}
            </ButtonContainer>
          </Container>
        </Dialog>
      </ContainerClasses>
    </>
  );
}
