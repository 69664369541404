/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';

import Grid from '@material-ui/core/Grid';
import ReactLoading from 'react-loading';
import {formatPrice} from '~/utils/priceFormat';
import colors from '~/UI/colors';
import Loader from './loader';
import {desencrypta} from '~/utils';
import apiBackend from '~/services/apiaxios';
import currencyURL from '~/services/currency';

import TableSubscriptions from './Table';
import Dash from '~/components/Dashboard';
import Pagination from '~/components/_PaginationTab';
import DatePickerCustom from '~/components/DatePickerCustom';
import Filters from './Filters';
import SearchComponent from './SearchComponent';
import MyCSVDownload from '~/components/MyCSVDownload';
import {fireAction} from '~/utils/swal';

import * as Sentry from '@sentry/react';

import {ActionsContainer, ContainerPagination} from './styles';
import {
  ClearFiltersButton,
  ReportContainer,
  Amount,
  Results,
  ReportAction,
  ButtonReport,
} from './styles';

export default function Subscriptions({display}) {
  const [currencyValues, setCurrencyValues] = useState('');
  const [currency, setCurrency] = useState('BRL');
  const [afterCurrency, setAfterCurrency] = useState(false);

  async function getCurrency() {
    /* TODO coin */
    fetch(`${currencyURL}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',

      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrencyValues(data);
        setAfterCurrency(true);
      })
      .catch((e) => {
        if (e.status === 401) {
          fireAction(
            'Erro interno no servidor',
            'Um erro ocorreu durante sua solicitação. Não conseguimos converter as moedas para visualização. Por favor, tente novamente mais tarde.',
            'error',
          );
        } else {
          fireAction(
            'Falha na comunicação',
            'Um erro de comunicação com o servidor de conversão de moedas ocorreu. Tente novamente mais tarde.',
            'error',
          );
        }
        Sentry.captureException(e);
      });
  }

  useEffect(() => {
    getCurrency();
  }, []);

  /* ------------------------------------------ */
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [tag, setTag] = useState('');
  const [filterName, setFilterName] = useState('');
  const [suggestItem, setSuggestItem] = useState({});
  const [query, setQuery] = useState(new URLSearchParams());

  /* --- PAGINATION TABLE --- */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [amount, setAmount] = useState('');

  const handleChangePage = (value) => {
    setPage(value);
  };
  /* --------------------------------------------------- */

  async function listAllSubscriptions() {
    try {
      setLoading(true);

      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/subscriber?paginate=true&page=${page}&limit=10${
          query.toString() !== '' ? `&${query.toString()}` : ''
        }${tag !== '' ? `&status_payment=${tag}` : ''}&origin=admin`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );

      setTotalResults(data?.subscribers?.meta?.total);
      setLastPage(data?.subscribers?.meta?.last_page);

      setAmount(data?.value_total);
      setSubscriptions(data?.subscribers?.data);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  useEffect(() => {
    if (afterCurrency) {
      listAllSubscriptions();
    }
  }, [page, query, tag, afterCurrency]);

  /* ------------------------------------- */
  /* ------------------------------------- */
  /* ------------------------------------- */
  function handleFilter() {
    var query = new URLSearchParams();

    if (Object.keys(suggestItem).length > 0) {
      query.append('user_id', suggestItem?.id);
    } else if (filterName?.trim()) {
      query.append('term', filterName?.trim());
    }

    if (dateStart?._isValid) {
      let initial =
        dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
      query.append('initial', initial);
    }

    if (dateEnd?._isValid) {
      let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';
      query.append('end', end);
    }

    if (dateStart?._isValid && dateEnd?._isValid) {
      if (dateStart?._d > dateEnd?._d) {
        let initial =
          dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
        let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';

        query.set('initial', end);
        query.set('end', initial);
      }
    }

    setQuery(() => {
      setPage(1);
      return query;
    });
  }

  function handleClearFilter() {
    setFilterName('');
    setSuggestItem({});
    setDateStart(null);
    setDateEnd(null);
    setTag('');

    setQuery(() => {
      setPage(1);
      return new URLSearchParams();
    });
  }

  useEffect(() => {
    if (dateStart?._isValid && dateStart?._d > new Date('1900-01-01')) {
      handleFilter();
    }
  }, [dateStart]);

  useEffect(() => {
    if (dateEnd?._isValid && dateEnd?._d > new Date('1900-01-01')) {
      handleFilter();
    }
  }, [dateEnd]);

  /* ------------------------------------- */
  /* ------------------------------------- */
  /* ------------------------------------------- */
  const handleBlurSearch = (value) => {
    if (Object.keys(suggestItem).length > 0) {
      const name = `${suggestItem?.name}${
        suggestItem?.surname ? ` ${suggestItem?.surname}` : ''
      }`;
      const isBorkerUser =
        suggestItem?.profile?.type_advertiser?.toLowerCase() === 'broker';
      const enterpriseName = suggestItem?.profile?.enterprise ?? name;

      if (isBorkerUser) {
        if (enterpriseName.trim() !== value.trim()) {
          setSuggestItem({});
        }
      } else {
        if (name.trim() !== value.trim()) {
          setSuggestItem({});
        }
      }
    }
  };

  /* -------------------------------- */
  /* --------- Reports -------------- */
  const [hasSubmitting, setHasSubmitting] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const generateReports = React.useCallback(async () => {
    try {
      setHasSubmitting(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/payments?${query.toString() !== '' ? `&${query.toString()}` : ''}${
          tag !== '' ? `&status_payment=${tag}` : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );

      const paymentsInCsv =
        data?.payments?.length > 0
          ? data?.payments
              ?.map((item) => {
                return {
                  pagamento_id: item?.id,
                  cadastro: item?.created_at,
                  aquisicao: item?.initiated,
                  vencimento: item?.ends,
                  status_do_pagamento: item?.paid ? 'Pago' : 'Não pago',
                  valor_usd: item?.value,
                  assinatura_id: item?.subscriber_id,
                  usuario_id: item?.user_id,
                  nome: item?.user?.name,
                  sobrenome: item?.user?.surname,
                  email: item?.user?.email,
                  telefone: item?.user?.cellphone,
                  país: item?.user?.profile?.country,
                  estado: item?.user?.profile?.state,
                  cep: item?.user?.profile?.zip_code,
                  cidade: item?.user?.profile?.city,
                  bairro: item?.user?.profile?.neighborhood,
                  endereço: item?.user?.profile?.address,
                  complemento: item?.user?.profile?.address_complement,
                  número: item?.user?.profile?.address_number,
                  latitude: item?.user?.profile?.latitude,
                  longitude: item?.user?.profile?.longitude,
                };
              })
              .sort((a, b) => a.pagamento_id - b.pagamento_id)
          : null;

      setCsvData(paymentsInCsv);
      setHasSubmitting(false);
    } catch (err) {
      setHasSubmitting(false);
      Sentry.captureException(err);
    }
  }, [query, tag]);

  useEffect(() => {
    if (csvData.length > 0) {
      setCsvData([]);
    }
  }, [query, tag]);

  /* -------------------------------- */
  /* -------------------------------- */
  return (
    <Dash button={'user'} title={'Assinaturas'}>
      <div style={{display: display}}>
        <ActionsContainer>
          <Grid
            container
            spacing={2}
            style={{marginBottom: '30px', marginTop: '30px'}}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <SearchComponent
                value={filterName}
                handleChange={setFilterName}
                handleBlur={handleBlurSearch}
                handleSubmit={handleFilter}
                placeholder="Buscar por usuários..."
                setSuggestItem={setSuggestItem}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <DatePickerCustom
                currentValue={dateStart}
                handleDateChange={setDateStart}
                placeholder="Data inicial..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <DatePickerCustom
                currentValue={dateEnd}
                handleDateChange={setDateEnd}
                placeholder="Data final..."
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            style={{marginBottom: '30px', marginTop: '30px'}}>
            <Filters
              value={tag}
              setSelectedValue={setTag}
              resetPage={setPage}
            />
          </Grid>
        </ActionsContainer>
        <ReportContainer>
          <Amount hasPositive={amount > 0}>
            Valor total filtrado <strong>U{formatPrice(amount)}</strong>
          </Amount>
          {subscriptions.length > 0 && (
            <MyCSVDownload
              data={csvData}
              handleGenerate={generateReports}
              loading={hasSubmitting}
              filename="PERMUTI_payments_reports.csv"
            />
          )}
        </ReportContainer>

        {(query.toString() || tag) && (
          <>
            <ClearFiltersButton onClick={handleClearFilter}>
              Limpar filtros
            </ClearFiltersButton>
          </>
        )}
        {totalResults > 0 && (
          <Results>{totalResults} resultados encontrados</Results>
        )}
        {loading ? (
          <Loader />
        ) : subscriptions?.length > 0 ? (
          <>
            <TableSubscriptions
              information={subscriptions}
              setInformation={setSubscriptions}
              reloadList={listAllSubscriptions}
              page={page}
              setPage={setPage}
              currencyValues={currencyValues}
            />

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ContainerPagination>
                {lastPage > 1 && (
                  <Pagination
                    count={lastPage}
                    page={page}
                    handleChange={(event, value) => handleChangePage(value)}
                  />
                )}
              </ContainerPagination>
            </Grid>
          </>
        ) : (
          <Results>Nenhum resultado encontrado...</Results>
        )}
      </div>
    </Dash>
  );
}
