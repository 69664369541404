import React, {Component} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Resizer from 'react-image-file-resizer';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {ModalResponsive} from '../../Components/styles';
import {
  InternDiv,
  TitleModal,
  DivAlterImage,
  ButtonGreen,
  ButtonWhite,
  ImagePreview,
  DivPreview,
} from './styles';
import '../RenderImages/styles.css';
import {desencrypta} from '../../../../../../utils';
import baseURL from '../../../../../../services/api';
import * as Sentry from '@sentry/react';

export default function ModalCropper({
  open,
  setOpen,
  file,
  filesExibition,
  setFilesExibition,
  id,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span>Preview</span>
            <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
          </TitleModal>
          <ModalResponsive>
            <InternDiv>
              <CropperImg
                id={id}
                setOpen={setOpen}
                filesExibition={filesExibition}
                setFilesExibition={setFilesExibition}
                file={file}
              />
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
    </>
  );
}

class CropperImg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgAux: '',
      imgCropper: this.props.file,
      file: this.props.file,
      buttonCropper: false,
    };
  }

  rotation(file) {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      90,
      (uri) => {
        let newFile = new File([uri], 'photo.jpg', {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });
        this.setState({imgCropper: newFile});
      },
      'blob',
      200,
      200,
    );
  }

  async handleImage() {
    const data = new FormData();
    data.append('photo[ordination]', this.props.filesExibition.length || '0');
    data.append('photo[image]', this.state.imgCropper);
    await this.sendImage(this.props.id, data);
  }

  async sendImage(id, formData) {
    try {
      let response = await fetch(`${baseURL}/api/v1/properties/${id}/photos`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

        headers: {
          Authorization: desencrypta(
            localStorage.getItem('@User_informations'),
          ),
        },
        body: formData,
      });

      if (response.status === 200 || response.status === 201) {
        await response.json().then((data) => {
          this.props.setFilesExibition([...this.props.filesExibition, data]);
          this.props.setOpen(false);
        });
      } else {
        console.log(await response);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async save(event) {
    event.preventDefault();
    await this.handleImage();
  }

  onChange = async (event) => {
    event.preventDefault();
    this.rotation(this.state.imgCropper);
  };

  render() {
    return (
      <>
        <DivPreview>
          <ImagePreview src={URL.createObjectURL(this.state.imgCropper)} />
        </DivPreview>

        <DivAlterImage>
          <ButtonWhite onClick={(event) => this.onChange(event)}>
            Girar
          </ButtonWhite>
          <ButtonGreen onClick={(event) => this.save(event)}>
            Salvar
          </ButtonGreen>
        </DivAlterImage>
      </>
    );
  }
}
