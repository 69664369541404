import styled from 'styled-components';
import colors from '~//UI/colors';
import fonts from '~//UI/fonts';

export const Container = styled.div`
  width: 400px;
  height: 640px;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: ${fonts.latoRegular};
`;

export const ButtonSave = styled.button`
  padding: 8px;
  width: 100%;
  font-weight: bold;
  min-height: 50px;
  color: ${colors.white};
  font-size: 18px;
  background-color: ${colors.green_secondary};
  border: none;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0000004d;
  margin-top: 20px;
  font-family: ${fonts.latoBlack};
  transition: all 0.2s ease;

  &:disabled {
    background-color: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
  &:hover:not(:disabled) {
    background: #2e6643;
  }
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 30px 30px 30px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  padding: 30px;
  font-size: 24px;
  color: #1d1d1b;

  font-family: ${fonts.latoBlackItalic};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const DivInputText = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;
