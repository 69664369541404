import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {desencrypta} from '~/utils';
import colors from '~/UI/colors';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalLogs({open, setOpen, log, flag, info}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  function generateLog() {
    let result = '';
    if (flag) {
      result = log;
    } else {
      result =
        Array.isArray(log) &&
        log?.map((item) =>
          item?.map((final) => {
            return `Property Ref: ${
              final?.target?.reference || '123'
            },Error: ${JSON.stringify(
              final?.constraints,
            )},Field: ${JSON.stringify(final?.property)}\n
        `;
          }),
        );
    }

    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(result, null, 2)]),
    );
    const link = document.createElement('a');
    link.href = url;
    const name = info?.profile?.enterprise
      ? info?.profile?.enterprise
      : info?.profile?.company_name
      ? info?.profile?.company_name
      : info?.name
      ? info?.name
      : 'User';
    const date = new Date();
    link.setAttribute('download', `${name} ${date.toUTCString()}.txt`);
    document.body.appendChild(link);
    link.click();
  }

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color={colors.danger}
                icon={faTimesCircle}
              />
              <AdvertTitle>Atenção</AdvertTitle>
              <Message>
                Não foi possível adicionar o XML , verifique o motivo baixando o
                arquivo de logs abaixo.
              </Message>
              <DivConfirm>
                <ButtonsWhite disabled={isSubmitting} onClick={generateLog}>
                  Baixar Logs
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
