import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import userInfoImg from '~/assets/user_info.svg';
import disableImg from '~/assets/disable_account.svg';
import enableImg from '~/assets/enable_account.svg';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import {Container, Icons} from './styles';

const styleItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  fontFamily: fonts.latoRegularBold,
  color: colors.dark,
};

const svgStyle = {
  color: colors.green_secondary,
  marginRight: 10,
  width: 26,
  height: 26,
  flex: 'none',
};

const permitted_roles = ['5'];

export default function ManagerOptionsLead({
  info,
  openInfo,
  openDisableAccount,
  openEnableAccount,
}) {
  const userStore = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenCollapse(!openCollapse);
  };

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*   useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]);
 */
  return (
    <>
      <Container>
        <Icons onClick={handleClick} isOpen={Boolean(anchorEl)}>
          <MoreHorizIcon />
        </Icons>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <MenuItem
            style={styleItem}
            onClick={() => {
              openInfo();
              handleClose();
            }}>
            <img src={userInfoImg} alt="Editar" style={svgStyle} /> Ver Detalhes
          </MenuItem>
          {isPermitted() && (
            <>
              {info?.active ? (
                <MenuItem
                  style={styleItem}
                  onClick={() => {
                    openDisableAccount();
                    handleClose();
                  }}>
                  <img src={disableImg} alt="Editar" style={svgStyle} />{' '}
                  Desabilitar Conta
                </MenuItem>
              ) : (
                <MenuItem
                  style={styleItem}
                  onClick={() => {
                    openEnableAccount();
                    handleClose();
                  }}>
                  <img src={enableImg} alt="Editar" style={svgStyle} />{' '}
                  Habilitar Conta
                </MenuItem>
              )}
            </>
          )}
        </Popover>
      </Container>
    </>
  );
}
