import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

import I18n from '~/i18n/i18';
import {
  ButtonBack,
  ButtonForward,
  DivButton,
  DivButtonIntern,
  TextBack,
  TextForward,
} from './styles';

function NextPreviewPropertyOne({
  active,
  link,
  step,
  setStep,
  handleSubmit,
  mt,
  flag,
}) {
  function alterBool() {}

  function returnStep(event) {
    event.preventDefault();
    alterBool();
    if (flag) {
      window.history.back();
    } else {
      if (step == '5') {
        setStep((step - 2).toString());
      } else {
        setStep((step - 1).toString());
      }
    }
  }

  return (
    <DivButton mt={mt}>
      <DivButtonIntern>
        <ButtonBack
          type="button"
          onClick={(event) => {
            returnStep(event);
          }}>
          {/* <ArrowBackIosIcon style={{color: colors.green_secondary}} /> */}
          <FontAwesomeIcon icon={faChevronLeft} />
        </ButtonBack>
        <TextBack>
          <I18n t="NextPrevComponent.back" />
        </TextBack>
      </DivButtonIntern>
      <DivButtonIntern>
        <TextForward active={active}>
          <I18n t="NextPrevComponent.next" />
        </TextForward>
        <ButtonForward
          type="button"
          disabled={!active}
          onClick={(event) => handleSubmit(event)}>
          {/* <ArrowForwardIosIcon style={{color: colors.white}} /> */}
          <FontAwesomeIcon icon={faChevronRight} />
        </ButtonForward>
      </DivButtonIntern>
    </DivButton>
  );
}

export default NextPreviewPropertyOne;
