import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ModalDelete from '~/components/ModalDelete';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import {
  ButtonXML,
  LabelAttendedStatic,
  LabelExpiredStatic,
  LabelLeadStatic,
  LabelNoSoldStatic,
  LabelPublishStatic,
  LabelSoldStatic,
} from '../../styles';

import {
  P,
  Results,
  RowClick,
  RowPhotoName,
  TableContainer,
  TitleBold,
  TitleBoldView,
} from './styles';
import './styles.css';

import ManagerOptionsLead from '../../Lead/ManagerOptions';
import ManagerOptionsNosold from '../../NoSold/ManagerOptions';
import ManagerOptionPublished from '../../Published/ManagerOptions';
import ManagerOptionSold from '../../Sold/ManagerOptions';
import ManagerOptionAttended from '../../Attended/ManagerOptions';
import ManagerOptionExpired from '../../Expired/ManagerOptions';
import imageDown from '../../../../assets/download.svg';
import errorXml from '~/services/docs/errorxml';

import * as Sentry from '@sentry/react';

const permitted_roles = ['5', '2'];

export default function TableClients({
  listUser,

  setInfo,
  information,

  page,
  setPage,
  getAllLeads,
}) {
  const userStore = useSelector((state) => state.user);
  const [sortName, setSortName] = useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortStatus, setSortStatus] = useState(false);
  const [sortDate, setSortDate] = useState(false);

  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');

  const [proibid, setProibid] = useState(false);

  const [planList, setPlanList] = useState([]);

  function getPlansActive() {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get(
        'v1/plan/plans_active?kind=seller',

        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        setPlanList(response.data.plans);
      })
      .catch((e) => Sentry.captureException(e));
  }

  React.useEffect(() => {
    getPlansActive();
  }, []);

  function dates(info = '') {
    let date = new Date(info);

    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function switchType(item) {
    let status_broker = item.status_broker;
    switch (status_broker) {
      case 1:
        return <LabelSoldStatic>Vendido</LabelSoldStatic>;
      case 2:
        return <LabelAttendedStatic>Atendido</LabelAttendedStatic>;
      case 3:
        return <LabelPublishStatic>Publicado</LabelPublishStatic>;
      case 4:
        return <LabelExpiredStatic>Expirado</LabelExpiredStatic>;

      default:
    }
  }

  function switchTypeContact(item) {
    if (item.status === 'unsold') {
      return <LabelNoSoldStatic>Não Vendido</LabelNoSoldStatic>;
    } else {
      return <LabelLeadStatic>Lead</LabelLeadStatic>;
    }
  }

  function switchTypeManager(item) {
    let status_broker = item.profile.status_broker;

    switch (status_broker) {
      case 1:
        return (
          <ManagerOptionSold
            // getAllProperties={getAllProperties}
            planList={planList}
            getAllLeads={getAllLeads}
            // getAllPropertiesPrice={getAllProperties}
            info={item}
          />
        );

      case 2:
        return (
          <>
            <ManagerOptionAttended
              // getAllProperties={getAllProperties}
              planList={planList}
              getAllLeads={getAllLeads}
              // getAllPropertiesPrice={getAllProperties}
              info={item}
            />
          </>
        );

      case 3:
        return (
          <ManagerOptionPublished
            // getAllProperties={getAllProperties}
            planList={planList}
            getAllLeads={getAllLeads}
            // getAllPropertiesPrice={getAllProperties}
            info={item}
          />
        );

      case 4:
        return (
          <ManagerOptionExpired
            // getAllProperties={getAllProperties}
            planList={planList}
            getAllLeads={getAllLeads}
            // getAllPropertiesPrice={getAllProperties}
            info={item}
          />
        );

      default:
    }
  }

  function switchManagerContact(item) {
    if (item.status === 'unsold') {
      return (
        <ManagerOptionsNosold
          getAllLeads={getAllLeads}
          // getAllPropertiesPrice={getAllProperties}
          info={item}
        />
      );
    } else {
      return (
        <ManagerOptionsLead
          getAllLeads={getAllLeads}
          // getAllPropertiesPrice={getAllProperties}
          info={item}
        />
      );
    }
  }

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /* useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  return (
    <>
      <Results>{information?.length} resultados encontrados</Results>
      <TableContainer>
        <Grid
          className="tableMobile all"
          container
          spacing={3}
          style={{
            marginTop: 15,

            borderBottom: '2px solid #BFBFB2',
          }}>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick>
              <TitleBoldView>Nome da imobiliária</TitleBoldView>
              <span>{sortName ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <RowClick>
              <TitleBoldView>E-mail</TitleBoldView>{' '}
              <span>{sortType ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick>
              <TitleBoldView>Contato</TitleBoldView>{' '}
              <span>{sortType ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          {isPermitted() && (
            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              lg={1}
              xl={1}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}>
              <TitleBoldView>Ações</TitleBoldView>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick>
              <TitleBoldView>Data de Criação</TitleBoldView>{' '}
              <span>{sortDate ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick>
              <TitleBoldView>Status</TitleBoldView>{' '}
              <span>{sortDate ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
        </Grid>

        {information?.map((info, index) => {
          return (
            <Grid
              key={index}
              style={{marginTop: '15px'}}
              container
              spacing={3}
              className="item__list">
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Nome da imobiliária</TitleBold>
                <RowPhotoName>
                  {info?.profile ? info?.profile?.enterprise : info?.name}
                </RowPhotoName>
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TitleBold>E-mail</TitleBold>
                <RowPhotoName>
                  {/* {info.name} {info.surname ?? ''} */}
                  {info?.email}
                </RowPhotoName>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Contato</TitleBold>
                {/* <P>{dates(info.created_at)}</P> */}
                <P> {info?.cellphone ? info?.cellphone : info?.phone}</P>
              </Grid>
              {isPermitted() && (
                <Grid
                  className="buttonActions"
                  item
                  xs={12}
                  sm={6}
                  md={1}
                  lg={1}
                  xl={1}>
                  <TitleBold>Ações</TitleBold>

                  {info?.profile
                    ? switchTypeManager(info)
                    : switchManagerContact(info)}

                  {info?.xml?.error && (
                    <ButtonXML
                      onClick={() =>
                        errorXml(info?.xml?.error, info?.profile?.enterprise)
                      }
                      style={{margin: '10px 0px 0px 0px'}}>
                      <img alt="download" src={imageDown} width="20px" />
                    </ButtonXML>
                  )}
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Cadastrado em</TitleBold>
                {dates(info?.created_at)}
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <div style={{width: '100%'}}>
                  {info?.profile
                    ? switchType(info?.profile)
                    : switchTypeContact(info)}
                </div>
              </Grid>
            </Grid>
          );
        })}
      </TableContainer>

      {isPermitted() && (
        <>
          <ModalDelete
            page={page}
            setPage={setPage}
            listUser={listUser}
            open={open}
            setOpen={setOpen}
            id={id}
            text={'Usuário'}
          />
        </>
      )}
    </>
  );
}
