import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Envolved, Container} from './styles';
/* import Home from './__Home'; */
/* import ListSwitch from './__ListSwitch'; */
import Type from './Type';
import LocalizeOne from './Localize/LocalizeOne';
import Step from './Step';
import PropertyOne from './PropertyFeatures/PropertyOne';
import PropertyTwo from './PropertyFeatures/PropertyTwo';
import PropertyThree from './PropertyFeatures/PropertyThree';
import {desencrypta, getRoute} from '~/utils';
import Adjust from './Localize/Adjust';
import baseURL from '~/services/api';
import Financial from './Financial';
import Rent from './Situation/Rent';
import Status from './Situation/Status';
import Intern from './Details/Intern';
import Extern from './Details/Extern';
import DesiredProfile from './DesiredProfile';
import getTypeProperties from '~/requests/Advertise/typeProperty';
import Photo from './_Photo';
import MenuProfile from '~/components/Menu/MenuProfile';
import getDetailsFeatures from '~/requests/Advertise/details';
import ModalFinish from './DesiredProfile/ModalFinish';
import getProperty, {loadFinancial} from './Utils/getAds';
import apiBackend from '~/services/apiaxios';
import {toMoney} from 'vanilla-masker';

import * as Sentry from '@sentry/react';

function AdvertsUpdate(props) {
  const [loadGeneral, setLoadGeneral] = useState(false);

  const [fileRender, setFileRender] = useState([]);
  const [fileSend, setFileSend] = useState([]);
  // AllProperties
  const [allProperties, setAllProperties] = useState('');
  // Global Variables
  const [viewMessage, setViewMessage] = useState(false);
  const [id, setId] = useState(
    props.location.state ? props.location.state.id.toString() : '',
  );
  const [step, setStep] = useState(
    props.location.state ? props.location.state.step.toString() : '0',
  );

  const [stepVisual, setStepVisual] = useState('0');
  const [valueP, setValueP] = useState(0);

  // Home
  const [selectedValue, setSelectValue] = useState('new');
  // Type
  const [type, setType] = useState({});

  const [typeEn, setTypeEn] = useState('');
  // LocalizeOne
  const [selectedValueCountry, setSelectValueCountry] = useState('Brazil');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  // LocalizeTwo
  const [cep, setCep] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [numb, setNumb] = useState('');
  const [adreess, setAdreess] = useState('');
  const [digit, setDigit] = useState('');
  // LocalizeThree
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [floor, setFloor] = useState('');

  //Faltando
  const [nApartment, setNApartment] = useState('');

  const [streetSuffix, setStreetSufix] = useState('');

  // PropertyFeaturesOne
  const [typeStyle, setTypeStyle] = useState('');
  const [typeView, setTypeView] = useState('');
  const [code, setCode] = useState('');
  const [loft, setLoft] = useState('');
  const [roof, setRoof] = useState('');

  // PropertyFeaturesTwo
  const [typeGarage, setTypeGarage] = useState('');
  const [dormitories, setDormitories] = useState('');
  const [suites, setSuites] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [washrooms, setWashrooms] = useState('');
  const [paving, setPaving] = useState('');
  const [vacancies, setVacancies] = useState('');

  // PropertyFeaturesThree
  const [dimension, setDimension] = useState('sqft');
  const [constructArea, setConstructArea] = useState('');
  const [totalArea, setTotalArea] = useState('');

  // Financial
  const [price, setPrice] = useState('');
  const [monthly_maintenance_fee, setMonthly_maintenance_fee] = useState('');
  const [monthly_tax_fee, setMonthly_tax_fee] = useState('');
  const [metricPrice, setMetricPrice] = useState('');
  const [metricTax, setMetricTax] = useState('');
  const [choseMetric, setChoseMetric] = useState('');

  // Rent
  const [choseMetricRent, setChoseMetricRent] = useState('');
  const [rental, setRental] = useState('');
  const [rental_price, setRental_price] = useState('');

  // Status
  const [year_build, setYear_build] = useState('');
  const [property_phase, setProperty_phase] = useState('');
  const [furnished, setFurnished] = useState(false);
  const [appliances, setAppliances] = useState(false);
  const [decorated, setDecorated] = useState(false);

  // Details Extern
  const [optionsExtern, setOptionsExtern] = useState([]);
  const [optionsExternDelete, setOptionsExternDelete] = useState([]);
  const [optionsAllExtern, setOptionsAllExtern] = useState('');
  // Details Intern
  const [optionsIntern, setOptionsIntern] = useState([]);
  const [optionsInternDelete, setOptionsInternDelete] = useState([]);
  const [optionsAllIntern, setOptionsAllIntern] = useState('');
  // Desired Profile

  const [itensBackend, setItensBackend] = useState({});

  const dispatch = useDispatch();

  const [openFinish, setOpenFinish] = useState(false);

  function clearAll() {
    setFileRender([]);
    // AllProperties
    setAllProperties('');
    // Global Variables
    setViewMessage(false);
    setId('0');
    setStep('0');
    setStepVisual('0');
    setValueP(0);
    setSelectValue('new');
    // Type
    setType('');
    // LocalizeOne
    setSelectValueCountry('Brazil');
    setCity('');
    setState('');
    // LocalizeTwo
    setCep('');
    setNeighborhood('');
    setNumb('');
    setAdreess('');
    // LocalizeThree
    setLatitude('');
    setLongitude('');
    setFloor('');
    setNApartment('');
    setStreetSufix('');
    // PropertyFeaturesOne
    setTypeStyle('');
    setTypeView('');
    setCode('');
    setLoft(false);
    setRoof(false);

    // PropertyFeaturesTwo
    setTypeGarage('');
    setDormitories('');
    setSuites('');
    setBathrooms('');
    setWashrooms('');
    setPaving('');
    setVacancies('');
    // PropertyFeaturesThree
    // setDimension('');
    setConstructArea('');
    setTotalArea('');
    // Financial
    setPrice('');
    setMonthly_maintenance_fee('');
    setMonthly_tax_fee('');
    setMetricPrice('');
    setMetricTax('');
    setChoseMetric('BRL');

    // Rent
    setChoseMetricRent('BRL');
    setRental('');
    setRental_price('');

    // Status
    setYear_build('');
    setProperty_phase('');
    setFurnished(false);
    setAppliances(false);
    setDecorated(false);
  }

  function routeLogin() {
    const rt = getRoute();
    if (rt) {
      return `/${rt}/login`;
    }
    return '/login';
  }

  function redirectLogin() {
    localStorage.removeItem('@User_informations');
    localStorage.removeItem('@User_profile');
    localStorage.removeItem('name');
    localStorage.removeItem('surname');
    localStorage.removeItem('id');
    window.location.href = routeLogin();
  }

  function getAds(idHere) {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get(`/v1/property/${idHere}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        console.log(response);

        // Type
        setType(
          response.data.property.type_property
            ? response.data.property.type_property
            : '',
        );
        loadFinancial(response.data.property);

        if (response.data.property.address) {
          response.data.property.address.country &&
            setSelectValueCountry(response.data.property.address.country);
          setCity(
            response.data.property.address.city
              ? response.data.property.address.city
              : '',
          );
          setState(
            response.data.property.address.state
              ? response.data.property.address.state
              : '',
          );
          setCep(
            response.data.property.address.zip_code
              ? response.data.property.address.zip_code
              : '',
          );
          setNeighborhood(
            response.data.property.address.neighborhood
              ? response.data.property.address.neighborhood
              : '',
          );

          setLatitude(
            response.data.property.address.latitude
              ? response.data.property.address.latitude
              : '',
          );

          setLongitude(
            response.data.property.address.longitude
              ? response.data.property.address.longitude
              : '',
          );

          setNumb(
            response.data.property.address.street_number
              ? response.data.property.address.street_number
              : '',
          );

          setNApartment(
            response.data.property.address.unit_number
              ? response.data.property.address.unit_number
              : '',
          );

          setAdreess(
            response.data.property.address.street_name
              ? response.data.property.address.street_name
              : '',
          );
          setDigit(
            [
              response?.data?.property?.address?.street_name,
              response?.data?.property?.address?.city,
              response?.data?.property?.address?.state,
            ]
              .filter(Boolean)
              .join(', '),
          );
          setFloor(
            response.data.property.address.floor
              ? response.data.property.address.floor
              : '',
          );
          setStreetSufix(
            response.data.property.address.street_suffix
              ? response.data.property.address.street_suffix
              : '',
          );
        }

        setTypeStyle(
          response.data.property.style ? response.data.property.style : '',
        );

        setTypeView(
          response.data.property.waterfront
            ? response.data.property.waterfront
            : '',
        );
        // // setCode(item.mls_number ? item.mls_number : '');
        setCode(
          response.data.property.mls_number
            ? response.data.property.mls_number
            : '',
        );
        setLoft(response.data.property.loft ? response.data.property.loft : '');
        setRoof(
          response.data.property.penthouse
            ? response.data.property.penthouse
            : '',
        );
        setTypeGarage(
          response.data.property.garage_type
            ? response.data.property.garage_type
            : '',
        );
        setDormitories(
          response.data.property.bedrooms
            ? response.data.property.bedrooms.toString()
            : '0',
        );
        setSuites(
          response.data.property.suites
            ? response.data.property.suites.toString()
            : '0',
        );
        setBathrooms(
          response.data.property.full_bathrooms
            ? response.data.property.full_bathrooms.toString()
            : '0',
        );
        setWashrooms(
          response.data.property.half_bathrooms
            ? response.data.property.half_bathrooms.toString()
            : '0',
        );

        setPaving(
          response.data.property.stories
            ? response.data.property.stories.toString()
            : '0',
        );
        setVacancies(
          response.data.property.parking_spaces
            ? response.data.property.parking_spaces.toString()
            : '0',
        );
        setConstructArea(
          response.data.property.living_area
            ? response.data.property.living_area.toString()
            : '',
        );
        setTotalArea(
          response.data.property.total_area
            ? response.data.property.total_area.toString()
            : '',
        );

        setYear_build(
          response?.data?.property?.year_build
            ? response?.data?.property?.year_build
            : '',
        );
        setProperty_phase(
          response.data.property.property_phase
            ? response.data.property.property_phase
            : false,
        );
        setFurnished(
          response.data.property.furnished
            ? response.data.property.furnished
            : false,
        );
        setAppliances(
          response.data.property.appliances
            ? response.data.property.appliances
            : false,
        );
        setDecorated(
          response.data.property.decorated
            ? response.data.property.decorated
            : false,
        );

        response.data.property.internalProperty.length > 0
          ? setOptionsIntern(
              loadItensDetails(response.data.property.internalProperty),
            )
          : getDetailsFeaturesIntern(
              optionsIntern,
              setOptionsAllIntern,
              setOptionsIntern,
            );

        // response.data.internal_features &&
        //   setOptionsInternDelete(
        //     loadItensDetails(response.data.internal_features),
        //   );

        response.data.property.externalProperty.length
          ? setOptionsExtern(
              loadItensDetails(response.data.property.externalProperty),
            )
          : getDetailsFeaturesExtern(
              optionsExtern,
              setOptionsAllExtern,
              setOptionsExtern,
            );

        setDimension(
          response.data.property.area_unit_of_measurement != null
            ? response.data.property.area_unit_of_measurement
            : 'sqft',
        );
        setLoadGeneral(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  function loadItensDetails(item) {
    return item.map((item) => {
      return {...item, choose: true};
    });
  }

  function maskMoneyEdit(e, choseMetric) {
    var float = Math.trunc(e);

    if (choseMetric === 'USD') {
      return toMoney(float, {
        precision: 0,
        separator: '',
        delimiter: ',',
      });
    }
    if (choseMetric === 'BRL') {
      console.log(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
      return toMoney(float, {
        precision: 0,
        separator: '',
        delimiter: '.',
      });
    }
    if (choseMetric === 'EUR') {
      return toMoney(float, {
        precision: 0,
        separator: '',
        delimiter: '.',
      });
    }
  }

  function loadFinancial(item) {
    setPrice(item.price ? maskMoneyEdit(item.price, item.price_currency) : '');
    setMonthly_maintenance_fee(
      item.monthly_maintenance_fee
        ? maskMoneyEdit(item.monthly_maintenance_fee, item.price_currency)
        : '',
    );
    setMonthly_tax_fee(
      item.monthly_tax_fee
        ? maskMoneyEdit(item.monthly_tax_fee, item.price_currency)
        : '',
    );

    setChoseMetric(item?.price_currency ? item?.price_currency : 'BRL');
    setChoseMetricRent(
      item?.rental_price_currency ? item?.rental_price_currency : 'BRL',
    );
    setRental(item.rental ? item.rental : '');
    setRental_price(
      item.rental_price
        ? maskMoneyEdit(item.rental_price, item.rental_price_currency)
        : '',
    );
  }

  function loadFeatures(options, features, setOptionsAll, setOptions) {
    const itens = features.map((item) => ({
      ...item,
      choose: false,
    }));

    console.log(itens);

    setOptionsAll(itens);

    setOptions(itens.splice(0, 5));
  }

  async function getDetailsFeaturesExtern(
    optionsExtern,
    setOptionsAllExtern,
    setOptionsExtern,
  ) {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get('/v1/property/data/intl', {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        console.log(response);
        loadFeatures(
          optionsExtern,
          response.data.featureExternal,
          setOptionsAllExtern,
          setOptionsExtern,
        );
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  async function getDetailsFeaturesIntern(
    optionsIntern,
    setOptionsAllIntern,
    setOptionsIntern,
  ) {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get('/v1/property/data/intl', {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        console.log(response);
        loadFeatures(
          optionsIntern,
          response.data.featureInternal,
          setOptionsAllIntern,
          setOptionsIntern,
        );
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  useEffect(() => {
    getAds(props.location.state.property.id);

    setLoadGeneral(true);
    getDetailsFeatures(
      optionsIntern,
      optionsExtern,
      setOptionsAllExtern,
      setOptionsExtern,
      setOptionsAllIntern,
      setOptionsIntern,
    );
  }, []);

  useEffect(() => {
    getTypeProperties(setItensBackend);
  }, []);

  /* useEffect(() => {
    if (
      selectedValueCountry === 'Brazil' ||
      selectedValueCountry === 'Portugal'
    ) {
      setDimension('m²');
    } else {
      setDimension('sqft');
    }
  }, [selectedValueCountry]); */

  /*  useEffect(() => {
    if (selectedValueCountry === 'Brazil') {
      setChoseMetric('BRL');
      setChoseMetricRent('BRL');
    } else if (selectedValueCountry === 'Portugal') {
      setChoseMetric('EUR');
      setChoseMetricRent('EUR');
    } else {
      setChoseMetric('USD');
      setChoseMetricRent('USD');
    }
  }, [selectedValueCountry]); */

  useEffect(() => {
    switch (step) {
      case '0':
        setStepVisual('0');
        break;
      case '1':
        setValueP(0);
        setStepVisual('1');
        break;
      case '2':
        setValueP(20);
        setStepVisual('2');
        break;
      case '3':
        setValueP(30);
        setStepVisual('3');
        break;
      case '4':
        setValueP(35);
        setStepVisual('3');
        break;
      case '5':
        setValueP(40);
        setStepVisual('4');
        break;
      case '6':
        setValueP(45);
        setStepVisual('4');
        break;
      case '7':
        setValueP(50);
        setStepVisual('4');
        break;
      case '8':
        setValueP(55);
        setStepVisual('5');
        break;
      case '9':
        setValueP(60);
        setStepVisual('6');
        break;
      case '10':
        setValueP(65);
        setStepVisual('7');
        break;
      case '11':
        setValueP(70);
        setStepVisual('8');
        break;
      case '12':
        setValueP(75);
        setStepVisual('9');
        break;
      case '13':
        setValueP(80);
        setStepVisual('10');
        break;
      case '14':
        setValueP(90);
        setStepVisual('11');
        break;
      default:
        break;
    }
  }, [step]);

  function renderEspecific() {
    switch (step) {
      case '1':
        return (
          <Photo
            id={id}
            setId={setId}
            step={step}
            setStep={setStep}
            file={fileRender}
            setFile={setFileRender}
            setFileSend={setFileSend}
            fileSend={fileSend}
          />
        );
      case '2':
        return (
          <Type
            viewMessage={viewMessage}
            setViewMessage={setViewMessage}
            id={id}
            type={type}
            setType={setType}
            step={step}
            setStep={setStep}
            setTypeEn={setTypeEn}
          />
        );
      case '3':
        return (
          <LocalizeOne
            digit={digit}
            setDigit={setDigit}
            adreess={adreess}
            setAdress={setAdreess}
            itensBackend={itensBackend}
            id={id}
            type={type}
            typeEn={typeEn}
            viewMessage={viewMessage}
            setViewMessage={setViewMessage}
            latitude={latitude}
            setLatitude={setLatitude}
            longitude={longitude}
            setLongitude={setLongitude}
            floor={floor}
            setFloor={setFloor}
            nApartment={nApartment}
            setNApartment={setNApartment}
            numb={numb}
            setNumb={setNumb}
            neighborhood={neighborhood}
            setNeighborhood={setNeighborhood}
            cep={cep}
            setCep={setCep}
            state={state}
            setState={setState}
            selectedValueCountry={selectedValueCountry}
            setSelectValueCountry={setSelectValueCountry}
            city={city}
            setCity={setCity}
            step={step}
            streetSuffix={streetSuffix}
            setStreetSufix={setStreetSufix}
            setStep={setStep}
          />
        );
      case '4':
        return (
          <Adjust
            id={id}
            step={step}
            setStep={setStep}
            latitude={latitude}
            setLatitude={setLatitude}
            longitude={longitude}
            setLongitude={setLongitude}
          />
        );
      case '5':
        return (
          <PropertyOne
            id={id}
            type={type}
            typeStyle={typeStyle}
            typeEn={typeEn}
            setTypeStyle={setTypeStyle}
            typeView={typeView}
            setTypeView={setTypeView}
            step={step}
            setStep={setStep}
            code={code}
            setCode={setCode}
            roof={roof}
            setRoof={setRoof}
            loft={loft}
            setLoft={setLoft}
          />
        );
      case '6':
        return (
          <PropertyTwo
            id={id}
            type={type}
            typeEn={typeEn}
            typeGarage={typeGarage}
            setTypeGarage={setTypeGarage}
            dormitories={dormitories}
            setDormitories={setDormitories}
            suites={suites}
            setSuites={setSuites}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            washrooms={washrooms}
            setWashrooms={setWashrooms}
            paving={paving}
            setPaving={setPaving}
            vacancies={vacancies}
            setVacancies={setVacancies}
            step={step}
            setStep={setStep}
            code={code}
            setCode={setCode}
          />
        );
      case '7':
        return (
          <PropertyThree
            id={id}
            dimension={dimension}
            setDimension={setDimension}
            constructArea={constructArea}
            totalArea={totalArea}
            setConstructArea={setConstructArea}
            setTotalArea={setTotalArea}
            step={step}
            setStep={setStep}
            country={selectedValueCountry}
            type={type}
            typeEn={typeEn}
          />
        );
      case '8':
        return (
          <Intern
            props={props}
            id={id}
            dimension={dimension}
            setDimension={setDimension}
            constructArea={constructArea}
            totalArea={totalArea}
            setConstructArea={setConstructArea}
            setTotalArea={setTotalArea}
            step={step}
            setStep={setStep}
            country={selectedValueCountry}
            options={optionsIntern}
            optionsAll={optionsAllIntern}
            setOptionsAll={setOptionsAllIntern}
            setOptions={setOptionsIntern}
          />
        );
      case '9':
        return (
          <Extern
            props={props}
            id={id}
            dimension={dimension}
            setDimension={setDimension}
            constructArea={constructArea}
            totalArea={totalArea}
            setConstructArea={setConstructArea}
            setTotalArea={setTotalArea}
            step={step}
            setStep={setStep}
            country={selectedValueCountry}
            options={optionsExtern}
            optionsAll={optionsAllExtern}
            setOptionsAll={setOptionsAllExtern}
            setOptions={setOptionsExtern}
            type={type}
            typeEn={typeEn}
            optionsExternDelete={optionsExternDelete}
            setOptionsExternDelete={setOptionsExternDelete}
          />
        );
      case '10':
        return (
          <Financial
            id={id}
            price={price}
            setPrice={setPrice}
            monthly_maintenance_fee={monthly_maintenance_fee}
            setMonthly_maintenance_fee={setMonthly_maintenance_fee}
            monthly_tax_fee={monthly_tax_fee}
            setMonthly_tax_fee={setMonthly_tax_fee}
            metricPrice={metricPrice}
            setMetricPrice={setMetricPrice}
            metricTax={metricTax}
            setMetricTax={setMetricTax}
            choseMetric={choseMetric}
            setChoseMetric={setChoseMetric}
            step={step}
            setStep={setStep}
            country={selectedValueCountry}
            type={type}
            typeEn={typeEn}
          />
        );
      case '11':
        return (
          <Rent
            id={id}
            step={step}
            setStep={setStep}
            choseMetric={choseMetricRent}
            setChoseMetric={setChoseMetricRent}
            rental={rental}
            setRental={setRental}
            rental_price={rental_price}
            setRental_price={setRental_price}
          />
        );
      case '12':
        return (
          <Status
            setValueP={setValueP}
            id={id}
            step={step}
            setStep={setStep}
            year_build={year_build}
            setYear_build={setYear_build}
            property_phase={property_phase}
            setProperty_phase={setProperty_phase}
            furnished={furnished}
            setFurnished={setFurnished}
            appliances={appliances}
            setAppliances={setAppliances}
            decorated={decorated}
            setDecorated={setDecorated}
            type={type}
            typeEn={typeEn}
          />
        );
      case '13':
        return (
          <DesiredProfile
            itensBackend={itensBackend}
            setStepVisual={setValueP}
            stepVisual={valueP}
            openFinish={openFinish}
            setOpenFinish={setOpenFinish}
            id={id}
            step={step}
            setStep={setStep}
          />
        );

      default:
        return (
          <Photo
            id={id}
            setId={setId}
            step={step}
            setStep={setStep}
            file={fileRender}
            setFile={setFileRender}
            setFileSend={setFileSend}
            fileSend={fileSend}
          />
        );
    }
  }

  if (openFinish) {
    return <ModalFinish />;
  } else {
    return (
      <div>
        <MenuProfile actual="/advertise" />
        {loadGeneral && (
          <Envolved>
            <Container>
              {step !== '0' ? (
                step !== 'list' ? (
                  <Step valueP={valueP} step={stepVisual} />
                ) : null
              ) : null}
              {step && renderEspecific()}
            </Container>
          </Envolved>
        )}
      </div>
    );
  }
}

export default AdvertsUpdate;
