import Axios from 'axios';
import * as Sentry from '@sentry/react';

export async function geocodeSearch({
  place_id,
  setLatitude,
  setLongitude,
  setViewNorthLat,
  setViewNorthLng,
  setViewSouthLat,
  setViewSouthLng,
}) {
  Axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&language=pt&key=${process.env.REACT_APP_PLACES}`,
  )
    .then((response) => {
      console.log(response);
      const resp = response?.data?.results[0];

      setLatitude(resp?.geometry?.location?.lat);
      setLongitude(resp?.geometry?.location?.lng);
      setViewNorthLat(resp?.geometry?.viewport?.northeast?.lat);
      setViewNorthLng(resp?.geometry?.viewport?.northeast?.lng);
      setViewSouthLat(resp?.geometry?.viewport?.southwest?.lat);
      setViewSouthLng(resp?.geometry?.viewport?.southwest?.lng);
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}
