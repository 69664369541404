import React from 'react';
import SearchIcon from '~/assets/search-icon.svg';
import AutosuggestSubscribers from './autoComplete';
import * as S from './styles';

function SearchComponent({
  placeholder,
  value,
  handleChange,
  handleSubmit,
  setKey,
}) {
  return (
    <S.Container
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
      {/* <AutosuggestSubscribers
        value={value}
        handleChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
      /> */}
      <S.AutoSuggestContainer>
        <input
          placeholder={placeholder}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
        />
      </S.AutoSuggestContainer>

      <button type="submit">
        <div>
          <img src={SearchIcon} alt="Search" />
        </div>
      </button>
    </S.Container>
  );
}

export default SearchComponent;
