import styled from 'styled-components';
import colors from '../../../../../UI/colors';


export const Envolved = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Lato';
`;

export const ButtonGreen = styled.button`
 background-color: ${colors.green_secondary};
 color: ${colors.white};
 box-shadow: 0px 3px 6px #00000033;
 &:hover{
  background-color: ${colors.green_third};
 }
 @media (max-width: 768px) {
    display: none
  }
  &:focus{
    outline: 0;
  }
  &:active{
     background-color: ${colors.green_third};
     color: #E6FFEF;
     box-shadow: 0px 0px 3px #00000033;
  }

`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 416px;
    justify-content: space-between;
    @media (min-width: 481px) and (max-width: 2560px) {
        width: 500px;
        padding-top: 40px;
        
    }
    @media (min-width: 1500px) and (max-width: 2560px) {
        width: 500px;
        padding-top: 10%;
        
    }
    @media(max-width: 480px) {
        width: 375px;
        padding: 30px 20px;
    }
`

export const Card = styled.div`
   justify-content: center;
   flex-direction: column;
   align-items: center;
   align-content: center;
   display: flex;
   width: 100%;
   height: 250px;
   background: #FFFFFF;
   border: none;
   box-shadow: 0px 3px 6px #00000029;
   font-size: 24px;
   font-style: italic;
   text-align: center;
   font-weight: bold;
   padding: 20px;
   margin-bottom: 20px;
`

export const CardChoose = styled.div`
 
`;

export const ButtonNext = styled(ButtonGreen)`
    width: 100%;
    margin-top: 30px;
    display: block !important;
`;


export const TitleQuestion = styled.p`
    color: #1D1D1B;
    font-size: 18px;
    font-weight: bold;
    
`;

export const SpanAdvertise = styled.span`
    color: ${colors.green_secondary};
`;

export const Warning = styled.p`
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 10.47px;
`;

export const DivNext = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   width: 100%;
`;

export const DivRadio = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 31px;
    margin-bottom: 31px;
    align-items: center;

`

export const DivRadioText = styled.div`
    width: 128px;
    font-weight: bold;
    color:  ${props => props.value ? colors.green_secondary : '#808076'};
    font-size: 16px;
    font-family: 'Lato';
`

export const TitlePage = styled.p`
    color: #1D1D1B;
    font-size: 24px;
    font-family: 'Lato';
    font-style: italic;
    display: flex;
    font-weight: bold;
`
export const ButtonInfo = styled.button`
  box-shadow: 0px 3px 6px #00000033;
  border: none;
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.green_secondary};
  border-radius: 5px;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus{
      outline: 0;
  }
  &:hover{
      background-color: #F2F2ED;
  }
  &:active{
      color: #E6FFEF;
      border: 2px solid #E6FFEF;
      background-color: ${colors.green_third};
      box-shadow:0px 0px 3px #00000033;
  }
  @media (min-width: 481px) and (max-width: 2560px) {
    height: 60px;
    font-size: 20px;
  }
  @media (min-width: 1500px) and (max-width: 2560px) {
        height: 60px;
        font-size: 20px;
  }
  @media(max-width: 480px) {
        height: 40px;
        font-size: 16px;
  }
`;

export const ContainerPhoto = styled.div`
    border: 1px solid #BFBFB2;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-style: dashed;
    padding-top: 20px;
    padding-bottom: 20px;
    &:focus{
      outline: 0;
     }
    &:hover{
     opacity: 0.9
  }
`
export const DragDrop = styled.div`
  border: 1px solid #BFBFB2;
  background: #fff;
  height: 200px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-style: dashed;
 
`;

export const SelectText = styled.p`
    color: #1D1D1B;
    font-size: 16px;
    text-align: center;
`

export const PhraseLoad = styled.span`
 margin-left:10px;
 margin-top: 7px;
`

export const ButtonLoad = styled.button`
  box-shadow: 0px 3px 6px #00000033;
  background-color: ${colors.green_secondary};
  border-radius: 5px;
  border: none;
  min-height: 40px;
  color: ${colors.white};
  font-size: 18px;
  font-family: 'Lato';
  font-weight: bold;
  min-width: 280px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  &:hover{
      background-color: ${colors.green_third};
  }
  &:active{
    background-color: ${colors.green_third};
    border: 2px #E6FFEF solid;
    box-shadow: 0px 0px 3px #00000033;
  }
   &:focus{
      outline: 0;
  }
`
