import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import deleteImg from '~/assets/delete.svg';
import dollarImg from '~/assets/dollar-sign.svg';
import editImg from '~/assets/edit.svg';
import hotel from '~/assets/hotel.svg';

import pay from '~/assets/money-bill.svg';
import xml from '~/assets/table.svg';
import playxml from '~/assets/playXML.svg';
import colors from '../../../../UI/colors';
import fonts from '../../../../UI/fonts';
import {Container, Icons} from './styles';
import ModalDelete from '../../../../components/ModalDelete';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import {fireAction} from '~/utils/swal';
import ModalPayMentLink from '~/components/ModalPaymentLink';
import ModalPaymentPlan from '~/components/ModalPaymentPlan';
import ModalLinkXML from '~/components/ModalLinkXML';
import ModalPaymentPlanChange from '~/components/ModalPaymentPlanChange';
import ModalAdsBroketList from '~/components/ModalAdsBrokerList';
import ModalExecuteXML from '~/components/ModalExecuteXML';

const styleItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  fontFamily: fonts.latoRegularBold,
  color: colors.dark,
};

const svgStyle = {
  color: colors.green_secondary,
  marginRight: 10,
  width: 26,
  height: 26,
  flex: 'none',
};

export default function ManagerOptionsLead({info, planList, getAllLeads}) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);

  /* --- MODALS --- */
  const [modalPay, setModalPay] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [linkPayPlan, setLinkPayPlan] = useState(false);
  const [linkPaymnet, setLinkPayment] = useState('');
  const [openList, setOpenList] = useState(false);
  const [modalXml, setModalXml] = useState(false);
  const [modalExecuteXml, setExecuteModalXml] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditPhotos = (info) => {
    alert('Feature em desenvolvimento.');
    /*  history.push({
      pathname: `/view/${info.id}/edit`,
      state: {
        id: info.id,
        step: '1',
        property: info,
      },
    }); */
  };

  function verificExecuteXML() {
    if (
      (info?.profile?.status_broker === 1 ||
        info?.profile?.status_broker === 3) &&
      info?.xml !== null &&
      info?.subscriber.status === 'paid'
    ) {
      return true;
    }
    return false;
    // xml, subscription, status_broker
  }

  function generatePaymentLink() {
    setLinkPayPlan(true);
  }

  function getAllAds() {
    // apiBackend.get(())
  }

  return (
    <>
      <Container>
        <Icons onClick={handleClick} isOpen={Boolean(anchorEl)}>
          <MoreHorizIcon />
        </Icons>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setOpenCollapse(!openCollapse);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <MenuItem
            style={styleItem}
            onClick={() => {
              setOpenList(true);
            }}>
            <img src={hotel} alt="Editar" style={svgStyle} /> Listar Anúncios
          </MenuItem>
          <MenuItem
            style={styleItem}
            onClick={() => {
              history.push('/real-state/edit', {user: info});
            }}>
            <img src={editImg} alt="Editar" style={svgStyle} /> Editar
          </MenuItem>
          <MenuItem
            style={styleItem}
            onClick={() => {
              generatePaymentLink();
            }}>
            <img src={pay} alt="Editar" style={svgStyle} /> Alterar Plano
          </MenuItem>
          <MenuItem
            style={styleItem}
            onClick={() => {
              setModalXml(true);
            }}>
            <img src={xml} alt="Editar" style={svgStyle} /> Link do XML
          </MenuItem>

          {verificExecuteXML() && (
            <MenuItem
              style={styleItem}
              onClick={() => {
                setExecuteModalXml(true);
              }}>
              <img src={playxml} alt="Executar XML" style={svgStyle} /> Executar
              XML
            </MenuItem>
          )}

          {/* <MenuItem
            style={styleItem}
            onClick={() => {
              history.push('/real-state/register', {info: info});
            }}>
            <img src={pay} alt="Editar" style={svgStyle} /> Planos
          </MenuItem> */}

          <MenuItem
            style={styleItem}
            onClick={() => {
              setModalDelete(true);
              setAnchorEl(null);
            }}>
            <img src={deleteImg} alt="Deletar" style={svgStyle} /> Excluir
          </MenuItem>
        </Popover>
      </Container>
      {/* ---- MODAIS ---- */}
      <ModalDelete
        page={1}
        // setPage={setPage}
        listUser={getAllLeads}
        open={modalDelete}
        setOpen={setModalDelete}
        id={info.id}
        text={'Usuário'}
      />
      <ModalPayMentLink
        text={linkPaymnet}
        setText={setLinkPayment}
        open={modalPay}
        setOpen={setModalPay}
      />

      <ModalPaymentPlanChange
        info={info}
        open={linkPayPlan}
        setOpen={setLinkPayPlan}
        planList={planList}
        getProperties={getAllLeads}
      />

      <ModalLinkXML
        open={modalXml}
        setOpen={setModalXml}
        info={info}
        getAllLeads={getAllLeads}
      />
      <ModalExecuteXML
        open={modalExecuteXml}
        setOpen={setExecuteModalXml}
        info={info}
      />

      <ModalAdsBroketList broker={info} open={openList} setOpen={setOpenList} />
    </>
  );
}
