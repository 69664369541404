/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';

import crypto from 'crypto-js';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import ReactLoading from 'react-loading';
import {Dialog} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  Rotule,
  DivInputText,
  InputText,
  ButtonSave,
  DivError,
  ErrorMessage,
} from './styles';
// import '../styles.css';

import {makeStyles} from '@material-ui/core';
import I18n from '~/i18n/i18';
import colors from '~/UI/colors';
import {useLocation} from 'react-router-dom';
const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '428px!important',
    width: '100%!important',
    marginLeft: '0!important',
    marginRight: '0!important',

    '@media(max-width: 662px)': {
      marginTop: '10px!important',
      marginBottom: '30px!important',
    },
  },
}));

export default function ModalSecurity({
  open,
  setOpen,
  profile,
  handleSubmit,
  loading,
}) {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const globalLocation = useLocation();

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [location, setLocation] = useState('');

  React.useEffect(() => {
    setLocation({
      pathname: `/en`,
      state: {fromDashboard: true},
    });
  }, [globalLocation?.pathname]);

  const resetForm = () => {
    setErrors({});
    setTouched({});

    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');

    setOldPasswordVisible(false);
    setNewPasswordVisible(false);
    setConfirmPasswordVisible(false);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);
  /* ---------------------------------------------------------- */

  const [oldPasswordVisible, setOldPasswordVisible] = useState(true);
  const [newPasswordVisible, setNewPasswordVisible] = useState(true);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(true);

  const oldPasswordRef = React.useRef(null);
  const newPasswordRef = React.useRef(null);
  const confirmPasswordRef = React.useRef(null);

  function handleIsEye(e, value, setValue, ref) {
    e.preventDefault();

    let pass = ref.current;
    pass.type === 'password' ? (pass.type = 'text') : (pass.type = 'password');
    setValue(!value);
  }
  /* ---------------------------------------------------------- */
  /* New Password */
  const handleChangeOldPassword = (value) => {
    setOldPassword(value);

    setErrors((errors) => {
      return {
        ...errors,
        oldPassword: !value.trim()
          ? I18n.getTranslation(location, 'Profile.validations.empty')
          : value.trim().length < 8
          ? `${I18n.getTranslation(
              location,
              'Profile.validations.minimal1',
            )} 8 ${I18n.getTranslation(
              location,
              'Profile.validations.minimal2',
            )}`
          : '',
      };
    });
  };

  const handleBlurOldPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        oldPassword: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        oldPassword: !value.trim()
          ? I18n.getTranslation(location, 'Profile.validations.empty')
          : value.trim().length < 8
          ? `${I18n.getTranslation(
              location,
              'Profile.validations.minimal1',
            )} 8 ${I18n.getTranslation(
              location,
              'Profile.validations.minimal2',
            )}`
          : '',
      };
    });
  };

  /* New Password */
  const handleChangeNewPassword = (value) => {
    setNewPassword(value);

    setErrors((errors) => {
      return {
        ...errors,
        newPassword: !value.trim()
          ? I18n.getTranslation(location, 'Profile.validations.empty')
          : value.trim().length < 8
          ? `${I18n.getTranslation(
              location,
              'Profile.validations.minimal1',
            )} 8 ${I18n.getTranslation(
              location,
              'Profile.validations.minimal2',
            )}`
          : '',
        confirmPassword:
          confirmPassword.trim() && value.trim() !== confirmPassword.trim()
            ? I18n.getTranslation(location, 'Profile.validations.passwordDiff')
            : !confirmPassword.trim() && touched.confirmPassword
            ? I18n.getTranslation(location, 'Profile.validations.empty')
            : '',
      };
    });
  };

  const handleBlurNewPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        newPassword: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        newPassword: !value.trim()
          ? I18n.getTranslation(location, 'Profile.validations.empty')
          : value.trim().length < 8
          ? `${I18n.getTranslation(
              location,
              'Profile.validations.minimal1',
            )} 8 ${I18n.getTranslation(
              location,
              'Profile.validations.minimal2',
            )}`
          : '',
        confirmPassword:
          confirmPassword.trim() && value.trim() !== confirmPassword.trim()
            ? I18n.getTranslation(location, 'Profile.validations.passwordDiff')
            : !confirmPassword.trim() && touched.confirmPassword
            ? I18n.getTranslation(location, 'Profile.validations.empty')
            : '',
      };
    });
  };

  /* Confirm Password */
  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value);

    setErrors((errors) => {
      return {
        ...errors,
        confirmPassword: !value.trim()
          ? I18n.getTranslation(location, 'Profile.validations.empty')
          : value.trim() !== newPassword.trim()
          ? I18n.getTranslation(location, 'Profile.validations.passwordDiff')
          : value.trim().length < 8
          ? `${I18n.getTranslation(
              location,
              'Profile.validations.minimal1',
            )} 8 ${I18n.getTranslation(
              location,
              'Profile.validations.minimal2',
            )}`
          : '',
        newPassword:
          value.trim() &&
          newPassword.trim() &&
          value.trim() !== newPassword.trim()
            ? I18n.getTranslation(location, 'Profile.validations.passwordDiff')
            : !newPassword.trim() && touched.newPassword
            ? I18n.getTranslation(location, 'Profile.validations.empty')
            : '',
      };
    });
  };

  const handleBlurConfirmPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        confirmPassword: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        confirmPassword: !value.trim()
          ? I18n.getTranslation(location, 'Profile.validations.empty')
          : value.trim() !== newPassword.trim()
          ? I18n.getTranslation(location, 'Profile.validations.passwordDiff')
          : value.trim().length < 8
          ? `${I18n.getTranslation(
              location,
              'Profile.validations.minimal1',
            )} 8 ${I18n.getTranslation(
              location,
              'Profile.validations.minimal2',
            )}`
          : '',
        newPassword:
          value.trim() &&
          newPassword.trim() &&
          value.trim() !== newPassword.trim()
            ? I18n.getTranslation(location, 'Profile.validations.passwordDiff')
            : !newPassword.trim() && touched.newPassword
            ? I18n.getTranslation(location, 'Profile.validations.empty')
            : '',
      };
    });
  };

  function editDataPersonal(event) {
    event.preventDefault();

    const key = process.env.REACT_APP_KEY_GENERATE_HASH;
    let newPasswordHash = crypto.AES.encrypt(
      newPassword.trim(),
      key,
    ).toString();

    const profileUpdated = {
      previous_password: oldPassword.trim(),
      password: newPasswordHash,
    };

    handleSubmit(profileUpdated);
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          classes={{paper: classes.paper}}>
          <TitleModal>
            <span>
              <I18n t="Profile.Modal.titleSecurity" />
            </span>
            <CloseIcon onClick={handleClose} className={'iconClose'} />
          </TitleModal>
          <form onSubmit={editDataPersonal} style={{padding: '20px'}}>
            <InternDiv>
              <div>
                <DivInputText>
                  <Rotule error={errors.oldPassword} valid={oldPassword}>
                    <I18n t="Profile.form.security.oldPassword" /> *
                  </Rotule>
                  <div className="passEyes">
                    <a
                      href="#"
                      onClick={(e) =>
                        handleIsEye(
                          e,
                          oldPasswordVisible,
                          setOldPasswordVisible,
                          oldPasswordRef,
                        )
                      }>
                      {!oldPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </a>
                    <InputText
                      id="oldPass"
                      type="password"
                      ref={oldPasswordRef}
                      value={oldPassword}
                      error={errors.oldPassword}
                      touched={touched.oldPassword}
                      onChange={(event) =>
                        handleChangeOldPassword(event.target.value)
                      }
                      onBlur={(event) =>
                        handleBlurOldPassword(event.target.value)
                      }
                      placeholder={'Senha anterior'}
                    />
                  </div>

                  {errors.oldPassword && (
                    <DivError>
                      <ErrorMessage>{errors.oldPassword}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule error={errors.newPassword} valid={newPassword}>
                    <I18n t="Profile.form.security.newPassword" /> *
                  </Rotule>
                  <div className="passEyes">
                    <a
                      href="#"
                      onClick={(e) =>
                        handleIsEye(
                          e,
                          newPasswordVisible,
                          setNewPasswordVisible,
                          newPasswordRef,
                        )
                      }>
                      {!newPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </a>
                    <InputText
                      id="oldPass"
                      type="password"
                      ref={newPasswordRef}
                      value={newPassword}
                      error={errors.newPassword}
                      touched={touched.newPassword}
                      onChange={(event) =>
                        handleChangeNewPassword(event.target.value)
                      }
                      onBlur={(event) =>
                        handleBlurNewPassword(event.target.value)
                      }
                      placeholder="Senha Anterior"
                    />
                  </div>

                  {errors.newPassword && (
                    <DivError>
                      <ErrorMessage>{errors.newPassword}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule
                    error={errors.confirmPassword}
                    valid={confirmPassword}>
                    <I18n t="Profile.form.security.confirmPassword" /> *
                  </Rotule>
                  <div className="passEyes">
                    <a
                      href="#"
                      onClick={(e) =>
                        handleIsEye(
                          e,
                          confirmPasswordVisible,
                          setConfirmPasswordVisible,
                          confirmPasswordRef,
                        )
                      }>
                      {!confirmPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </a>
                    <InputText
                      id="oldPass"
                      type="password"
                      ref={confirmPasswordRef}
                      value={confirmPassword}
                      error={errors.confirmPassword}
                      touched={touched.confirmPassword}
                      onChange={(event) =>
                        handleChangeConfirmPassword(event.target.value)
                      }
                      onBlur={(event) =>
                        handleBlurConfirmPassword(event.target.value)
                      }
                      placeholder={'Confime a nova senha'}
                    />
                  </div>

                  {errors.confirmPassword && (
                    <DivError>
                      <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>
              </div>
              <ButtonSave
                type="submit"
                disabled={
                  !oldPassword.trim() ||
                  oldPassword.trim().length < 8 ||
                  !newPassword.trim() ||
                  newPassword.trim().length < 8 ||
                  !confirmPassword.trim() ||
                  confirmPassword.trim().length < 8 ||
                  newPassword.trim() !== confirmPassword.trim() ||
                  loading
                }
                className={'btnDataPersonal btnload'}>
                {loading ? (
                  <ReactLoading
                    type={'spin'}
                    className="load"
                    color={colors.green_secondary}
                    width="30px"
                    height="auto"
                  />
                ) : (
                  <I18n t="Profile.form.submit" />
                )}
              </ButtonSave>
            </InternDiv>
          </form>
        </Dialog>
      </div>
    </>
  );
}
