import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import MenuProfile from '~/components/Menu/MenuProfile';
import {useParams} from 'react-router-dom';
import {Container, Icons} from './styles';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {getAdress, getPrice} from '../View/utils/finance';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import colors from '~/UI/colors';
import edit from '~/assets/edit.svg';
import ModalEditJustify from '~/components/ModalEditJustify';
import {fireAction} from '~/utils/swal';
import * as Sentry from '@sentry/react';

const permitted_roles = ['5', '3'];

function AllJustify(props) {
  const userStore = useSelector((state) => state.user);
  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*  useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  const history = useHistory();
  const advertise = props.location.state?.advertise;
  const prices = props.location.state?.prices;
  const [justify, setJustify] = useState([]);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [itemJustify, setItemJustify] = useState('');

  useEffect(() => {
    if (props.location.state.advertise) {
      getPrice(advertise);
      getJustify();
    } else {
      history.goBack();
    }
  }, []);

  function getJustify() {
    let token = desencrypta(localStorage.getItem('@User_informations'));

    apiBackend
      .get(`/v1/property/${advertise.id}/justification`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        setJustify(response.data);
        console.log(response);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  function editJustify(idProperty, reason, id) {
    let token = desencrypta(localStorage.getItem('@User_informations'));

    apiBackend
      .put(
        `/v1/property/${idProperty}/justification/${id}`,
        {
          reason: reason,
        },
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        getJustify();
        fireAction(
          'Justificativa editada',
          'A justificativa foi editada com sucesso',
          'success',
        );
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  const svgStyle = {
    color: colors.green_secondary,

    width: 20,
    height: 18,
    flex: 'none',
  };

  function dateTransform(info) {
    let date = new Date(info);
    return (
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
      '/' +
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    );
  }

  return (
    <>
      <MenuProfile title={'Justificativa de Reprovação'} />
      <Container>
        <div className="sectionProperties">
          <div className="type_property">{advertise.type_property.name_pt}</div>
          <div className="address_property">{getAdress(advertise.address)}</div>

          <div className="price_property">
            <div className="p1">{prices[0]}</div>
            <span className="rows">
              <div className="p2">{prices[1]}</div>
              <div className="p2">{prices[2]}</div>
            </span>
          </div>
        </div>
      </Container>
      {justify.map((item, index) => {
        return (
          <div key={index}>
            <hr />
            <Container>
              <div className="sectionJustify">
                <div className="superior_info">
                  <div className="justify_name mg-right-20">
                    Justificativa de reprovação {index + 1}
                  </div>
                  <div className="justify_date mg-right-20">
                    {' '}
                    <span className="mg-right-10">
                      {dateTransform(item.updated_at)}
                    </span>
                    <span>
                      Reprovado por <b>{item?.admin_name}</b>
                    </span>
                  </div>
                  {isPermitted() && (
                    <div className="rows">
                      <Icons
                        onClick={() => {
                          setItemJustify(item);
                          setOpenModalEdit(!openModalEdit);
                        }}>
                        <img src={edit} alt="Editar" style={svgStyle} />
                      </Icons>
                    </div>
                  )}
                </div>
                <div className="text_area">{item?.reason}</div>
              </div>
            </Container>
          </div>
        );
      })}

      <hr />
      {isPermitted() && (
        <ModalEditJustify
          advertiseId={advertise.id}
          open={openModalEdit}
          setOpen={setOpenModalEdit}
          justifyInfo={itemJustify}
          funcExec={editJustify}
        />
      )}
    </>
  );
}

export default AllJustify;
