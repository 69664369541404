import React, {useState} from 'react';
import VMasker from 'vanilla-masker';
import {InternDiv} from './styles';
import CloseIcon from '@material-ui/icons/Close';
import '../styles.css';

import ReactLoading from 'react-loading';
import {Dialog} from '@material-ui/core';

import colors from '~/UI/colors';

import {
  TitleModal,
  Rotule,
  DivInputText,
  InputText,
  ButtonSave,
  DivError,
  ErrorMessage,
} from './styles';
import RadioButtons from '../../Register/RadioButton';

export default function ModalDataPersonal({
  open,
  setOpen,
  profile,
  handleSubmit,
  loading,
}) {
  const [userType, setUserType] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [cpf, setCpf] = useState('');
  const [job, setJob] = useState('');

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  React.useEffect(() => {
    setName(profile?.name ? profile.name : '');
    setSurname(profile?.surname ? profile?.surname : '');
    setCpf(profile?.profile?.cpf ? profile?.profile?.cpf : '');
    setJob(profile?.profile?.job ? profile?.profile?.job : '');
    setUserType(
      profile?.profile?.type_advertiser
        ? profile?.profile?.type_advertiser
        : 'Owner',
    );
  }, [profile]);

  const resetForm = () => {
    setName(profile?.name ? profile.name : '');
    setSurname(profile?.surname ? profile?.surname : '');
    setCpf(profile?.profile?.cpf ? profile?.profile?.cpf : '');
    setJob(profile?.profile?.job ? profile?.profile?.job : '');
    setUserType(
      profile?.profile?.type_advertiser
        ? profile?.profile?.type_advertiser
        : 'Owner',
    );
    setErrors({});
    setTouched({});
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  /* ----------------------- */
  /* ----------------------- */
  /* ----------------------- */

  const handleChangeName = (value) => {
    setName(value);

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurName = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        name: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        name: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ------------------------------ */
  const handleChangeSurname = (value) => {
    setSurname(value);

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurSurname = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        surname: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        surname: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ------------------------------ */
  const handleChangeCpf = (value) => {
    setCpf(value);

    setErrors((errors) => {
      return {
        ...errors,
        cpf:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };

  const handleBlurCpf = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        cpf: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        cpf:
          value.trim() && value.trim().length < 5
            ? 'Mínimo de 5 caracteres'
            : '',
      };
    });
  };

  /* ----------------------- */
  /* ----------------------- */
  /* ----------------------- */

  function editDataPersonal(event) {
    event.preventDefault();

    const profileUpdated = {
      name: name.trim(),
      surname: surname.trim(),
      profile: {
        cpf: cpf.trim(),
        job: job.trim(),
        type_advertiser: userType,
      },
    };

    handleSubmit(profileUpdated);
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span>Dados Pessoais</span>
            <CloseIcon onClick={handleClose} className={'iconClose'} />
          </TitleModal>
          <form onSubmit={editDataPersonal} style={{padding: '20px'}}>
            <InternDiv>
              <div>
                <DivInputText>
                  <Rotule error={errors.name} valid={name}>
                    Nome *
                  </Rotule>
                  <InputText
                    value={name}
                    error={errors.name}
                    touched={touched.name}
                    onChange={(event) => handleChangeName(event.target.value)}
                    onBlur={(event) => handleBlurName(event.target.value)}
                    placeholder={'Informe o seu nome...'}
                  />
                  {errors.name && (
                    <DivError>
                      <ErrorMessage>{errors.name}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule error={errors.surname} valid={surname}>
                    Sobrenome *
                  </Rotule>
                  <InputText
                    value={surname}
                    error={errors.surname}
                    touched={touched.surname}
                    onChange={(event) =>
                      handleChangeSurname(event.target.value)
                    }
                    onBlur={(event) => handleBlurSurname(event.target.value)}
                    placeholder={'Informe o seu sobrenome...'}
                  />
                  {errors.surname && (
                    <DivError>
                      <ErrorMessage>{errors.surname}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule error={errors.cpf} valid={cpf}>
                    CPF
                  </Rotule>
                  <InputText
                    value={cpf}
                    touched={touched.cpf}
                    error={errors.cpf}
                    onChange={(event) => handleChangeCpf(event.target.value)}
                    onBlur={(event) => handleBlurCpf(event.target.value)}
                    placeholder="Informe o CPF"
                  />
                  {errors.cpf && (
                    <DivError>
                      <ErrorMessage>{errors.cpf}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule error={errors.job} valid={job}>
                    Cargo
                  </Rotule>
                  <InputText
                    value={job}
                    touched={touched.job}
                    error={errors.job}
                    onChange={(event) => setJob(event.target.value)}
                    placeholder="Qual o seu cargo?"
                  />
                  {errors.job && (
                    <DivError>
                      <ErrorMessage>{errors.job}</ErrorMessage>
                    </DivError>
                  )}
                </DivInputText>

                <DivInputText className="divMargin">
                  <Rotule valid={userType !== ''}>
                    Selecione o tipo de usuário *
                  </Rotule>
                  <RadioButtons
                    selectedValue={userType}
                    setSelectedValue={setUserType}
                  />
                </DivInputText>
              </div>
              <ButtonSave
                type="submit"
                disabled={
                  !name.trim() ||
                  name.trim().length < 3 ||
                  !surname.trim() ||
                  surname.trim().length < 3 ||
                  (cpf.trim() && cpf.trim().length < 5) ||
                  loading
                }
                className={'btnDataPersonal btnload'}>
                {loading ? (
                  <ReactLoading
                    type={'spin'}
                    className="load"
                    color={colors.green_secondary}
                    width={'30px'}
                  />
                ) : (
                  'Editar'
                )}
              </ButtonSave>
            </InternDiv>
          </form>
        </Dialog>
      </div>
    </>
  );
}
