import React, {useState, useEffect} from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {makeStyles} from '@material-ui/core/styles';

import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import I18n from '~/i18n/i18';

const useStyles = makeStyles({
  root: {},
  item: {
    fontFamily: fonts.latoRegular,
    width: '100%',
    /* marginTop: '10px', */
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    fontFamily: fonts.latoRegular,
    width: '100%',
    /* marginTop: '10px', */
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});

export default function SelectMenuI18n(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [choose, setChoose] = useState(props.value);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClickChoose(item) {
    setAnchorEl(null);
    props.setValue(item);
  }

  useEffect(() => {
    props.value
      ? setChoose(<I18n t={`${props.i18n}.${props.value}`} />)
      : setChoose(props.value);
  }, [props.value]);

  useEffect(() => {
    console.log('Valor', props.value);
  }, [props.value]);

  const buttonRef = React.useRef();
  const ITEM_HEIGHT = 48;

  return (
    <div style={{minWidth: '200px'}}>
      <Label color={choose}>{props.label}</Label>
      <ButtonFilter
        ref={buttonRef}
        aria-controls="selectmenui18n"
        aria-haspopup="true"
        disabled={props.disable}
        type="button"
        itens={props.itens}
        color={choose}
        onClick={handleClick}>
        {choose || props.placeHolder}{' '}
        {anchorEl ? <ExpandLess /> : <ExpandMore />}
      </ButtonFilter>
      <Menu
        className={classes.root}
        id="selectmenui18n"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          handleClose();
        }}
        PaperProps={{
          style: {
            width: buttonRef.current ? buttonRef.current.clientWidth : '300px',
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}>
        {props.itens.map((item, key) => (
          <MenuItem
            key={key}
            className={classes.item}
            onClick={() => handleClickChoose(item)}>
            <I18n t={`${props.i18n}.${item}`} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const ButtonFilter = styled.button`
  height: 50px;

  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.color
      ? `2px solid ${colors.green_secondary}!important`
      : '1px solid #808076!important'};
  color: ${(props) => (props.color ? '#1D1D1B' : '#808076')};
  width: 100%;
  font-family: ${fonts.latoRegular};
  font-size: 16px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus {
    outline: 0;
  }
  margin-bottom: 20px;
  padding-left: 0;

  /* @media (min-width: 781px) and (max-width: 2560px) {
    max-width: 380px;
              
    }
    @media(max-width: 480px) {
        max-width: 140px;
    } */
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;
