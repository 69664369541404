import React, {useState, useEffect} from 'react';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {useDispatch, useSelector} from 'react-redux';
import Dropzone from 'react-dropzone';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import BackupIcon from '@material-ui/icons/Backup';
import imageCompression from 'browser-image-compression';
import {DivNext} from '../Type/styles';
import {
  ButtonInfo,
  TitlePage,
  ContainerPhoto,
  ButtonLoad,
  SelectText,
  PhraseLoad,
} from './styles';
import NextPrev from '../Components/nextPrev';
import RenderImages from './RenderImages';
// import ModalCropper from './ModalCropper';
import ModalTips from './ModalTips';

import './styles.css';

import OpenAnimation from './Loading';
import Axios from 'axios';
import NextPreview from '../Components/nextPreview';
import ModalEditSucess from '../../../../../components/ModalEditSucess';
import {desencrypta} from '~/utils';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';

import * as Sentry from '@sentry/react';

export default function Photo({
  id,
  setId,
  step,
  setStep,
  file,
  setFile,
  setFileSend,
  fileSend,
  props,
}) {
  const [active, setActive] = useState(false);
  const view = useSelector((state) => state.step.data);
  const dispatch = useDispatch();
  const [fileCrop, setFileCrop] = useState();
  const [openCroper, setOpenCroper] = useState(false);
  const [openTips, setOpenTips] = useState(false);
  const [activeSkeleton, setActiveSkeleton] = useState(true);
  const [openAnimation, setOpenAnimation] = useState(false);
  // const flagEdit = props.location.state?.flag ? true : false;
  const flagEdit = true;
  const [openEditSucess, setOpenEditSucess] = useState(false);

  useEffect(() => {
    if (file.length >= 5 && file.length <= 20) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [file]);

  useEffect(() => {
    getImages();
  }, []);

  async function fileSelectedHandler(fileNew) {
    let start = fileNew;
    if (file.length < 20) {
      let insert = 20 - file.length;
      start = fileNew.splice(0, insert);
      console.log(start);
    }

    let value = await compressionImage(start);
    let arr = [];
    let arrSend = [];
    if (file.length > 0) {
      arr = [...file];
    }

    if (fileSend.length > 0) {
      arrSend = [...fileSend];
    }
    let final = arr.concat(value);
    let finalSend = arrSend.concat(value);
    setFile(final);
    setFileSend(finalSend);
    setOpenAnimation(false);
  }

  async function compressionImage(event) {
    const options = {
      maxSizeMB: 0.15,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      setOpenAnimation(true);
      const compressedFile = await Promise.all(
        event.map(
          async (imageFile) => await imageCompression(imageFile, options),
        ),
      );

      return compressedFile;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function updateAnouncementWithPhoto(key, idImage, token) {
    fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/property/${id}/photos/${idImage}/order`,
      {
        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ordinate: key,
        }),
      },
    )
      .then((response) => {
        console.log(response);
        // saveItens(value, token);
      })
      .catch((e) => Sentry.captureException(e));
  }

  async function saveItens(value, token) {
    apiBackend
      .put(
        `/v1/property/${id}`,
        {
          step: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        console.log('resposta', response);
        setFile([]);
        setFileSend([]);
        setStep(value);
      })

      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  async function handleProx(event) {
    event.preventDefault();

    // setActive(false);
    if (fileSend.length > 0) {
      let response = await handleImage();
    } else {
      for (let i = 0; i < file.length; i++) {
        if (file[i].image_url) {
          if (file[i].ordinate !== i) {
            file[i].ordinate = i;
            updateAnouncementWithPhoto(
              i,
              file[i].id,
              desencrypta(localStorage.getItem('@User_informations')),
            );
          }
        }
      }

      if (flagEdit) {
        setOpenEditSucess(true);
      } else {
        let value = Number.parseInt(step);
        setActive(false);
        value = (value + 1).toString();
        saveItens(
          value,
          desencrypta(localStorage.getItem('@User_informations')),
        );
      }
    }
  }

  function handleOpenTips(event) {
    event.preventDefault();
    setOpenTips(true);
  }

  async function handleImage() {
    let newArray = [];
    let crop = 5;

    for (var i = 0; i < fileSend.length; i = i + crop) {
      newArray.push(fileSend.slice(i, i + crop));
    }
    let final = [];

    for (let z = 0; z < newArray.length; z++) {
      let formData = new FormData();
      let cont = 0;
      for (let j = 0; j < newArray[z].length; j++) {
        for (let h = 0; h < file.length; h++) {
          if (newArray[z][j] === file[h]) {
            formData.append(`images[${cont}]`, file[h]);
            formData.append(`ordinate[${cont}]`, parseInt(h));
            cont = cont + 1;
          }
        }
      }
      final.push(formData);
    }

    setOpenAnimation(true);
    Promise.all(
      final.map(async (item) => {
        return sendImages(id, item);
      }),
    ).then((response) => {
      setOpenAnimation(false);
      let value = Number.parseInt(step);
      if (flagEdit) {
        setOpenEditSucess(true);
      } else {
        setActive(false);
        value = (value + 1).toString();
        saveItens(
          value,
          desencrypta(localStorage.getItem('@User_informations')),
        );
      }
    });
  }

  // console.log(final);
  // return;
  // if (fileSend.length > 0) return await sendImages(id, data);

  async function sendImages(id, formData) {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    return Axios.post(
      `${process.env.REACT_APP_BACKEND}/api/v1/property/${id}/photos`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    )
      .then((response) => {
        console.log(response);
        return Promise.resolve(true);
      })
      .catch((e) => Sentry.captureException(e));
  }

  async function getImages() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get(`/v1/property/${id}/photos`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        console.log(response);
        setActiveSkeleton(false);
        setFile(response.data.photos ? response.data.photos : []);
      })
      .catch((e) => Sentry.captureException(e));
  }

  return (
    <>
      <form onSubmit={handleProx}>
        <>
          <TitlePage>
            <I18n t="Photos.add" />
          </TitlePage>

          <ButtonInfo onClick={(event) => handleOpenTips(event)}>
            <PriorityHighIcon />
            <I18n t="Photos.tips" />
          </ButtonInfo>

          {file.length < 20 && (
            <Dropzone
              multiple={true}
              accept="image/*"
              type="button"
              onDrop={(file) => fileSelectedHandler(file)}>
              {({getRootProps, getInputProps}) => (
                <ContainerPhoto {...getRootProps()}>
                  <input multiple type="file" {...getInputProps()} />
                  <CameraAltIcon className="camIcon" />
                  <SelectText>
                    <I18n t="Photos.selectedPartOne" /> <br />{' '}
                    <I18n t="Photos.selectedPartTwo" />
                  </SelectText>
                  <ButtonLoad type="button">
                    <BackupIcon />{' '}
                    <PhraseLoad>
                      <I18n t="Photos.load" />
                    </PhraseLoad>
                  </ButtonLoad>
                </ContainerPhoto>
              )}
            </Dropzone>
          )}

          {file.length > 0 ? (
            <RenderImages
              activeSkeleton={activeSkeleton}
              id={id}
              setFiles={setFile}
              files={file}
              fileSend={fileSend}
              setFileSend={setFileSend}
            />
          ) : null}
        </>
        <DivNext>
          {flagEdit ? (
            <NextPreview
              flag={flagEdit}
              mt={file.length > 0 ? '0px' : '40px'}
              step={step}
              setStep={setStep}
              active={active}
              handleSubmit={handleProx}
            />
          ) : (
            <NextPrev
              mt={file.length > 0 ? '0px' : '40px'}
              step={step}
              setStep={setStep}
              active={active}
              handleSubmit={handleProx}
            />
          )}
        </DivNext>
      </form>

      {/* {openCroper ? (
        <ModalCropper
          id={id}
          filesExibition={file}
          setFilesExibition={setFile}
          open={openCroper}
          setOpen={setOpenCroper}
          file={fileCrop}
        />
      ) : null} */}
      <OpenAnimation
        open={openAnimation}
        src={'https://assets9.lottiefiles.com/packages/lf20_2oranrew.json'}
        setOpen={setOpenAnimation}
      />

      <ModalEditSucess
        func={() => {
          window.history.back();
        }}
        open={openEditSucess}
        setOpen={setOpenEditSucess}
      />

      <ModalTips open={openTips} setOpen={setOpenTips} />
    </>
  );
}
