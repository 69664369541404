import React from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const GreenCheckbox = withStyles({
  root: {
    color: '#50B275',
    '&$checked': {
      color: '#50B275',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxLabels({
  hasChecked,
  handleChange,
  label,
  sizeReduzed,
}) {
  const useStyles = makeStyles({
    label: {
      fontSize: sizeReduzed ? 12 : 16,
      fontFamily: [
        `${hasChecked ? 'Lato Regular Bold' : 'Lato Regular'}`,
        'Roboto',
        'sans-serif',
      ].join(','),
      color: hasChecked ? '#1d1d1b' : '#808076',
    },
  });

  const classes = useStyles();

  const onChange = (event) => {
    handleChange(event.target.checked);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        classes={{label: classes.label}}
        control={
          <GreenCheckbox
            checked={hasChecked}
            onChange={onChange}
            value="unlimited"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
