import styled, {keyframes} from 'styled-components';
import {ImgUser} from '../../components/Dashboard/styles';
import colors from '../../UI/colors';

export const Container = styled.div`
  max-width: 320px;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  padding-bottom: 20px;
`;

export const DivIntern = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ImgUserG = styled(ImgUser)`
  width: 100px;
  height: 100px;
  margin: 0;
  object-fit: cover;
`;

export const SpanText = styled.span`
  margin-top: 10.01px;
  margin-bottom: 10px;
  color: ${colors.green_secondary};
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
`;

export const PEdit = styled.p`
  margin: 0px;
  margin-left: 10px;
`;

export const DivInfo = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const PName = styled.p`
  font-size: 24px;
  color: #1d1d1b;
  font-weight: bold;
`;

export const PType = styled.p`
  font-size: 18px;
  color: #1d1d1b;
`;

export const ProfileInfo = styled.div`
  /* background-color: red; */
  margin-top: 20px;
`;

export const Content = styled.p`
  padding-right: 10px;
  margin: 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
`;

export const RowIntern = styled.span`
  display: flex;
  flex-direction: row;
`;

export const Rotule = styled.p`
  padding-right: 10px;
  color: ${colors.green_secondary};
  font-weight: bold;
  margin: 0px;
  flex: none;
`;

export const InternCard = styled.div`
  margin-left: 20px;
  padding-top: 17px;
  color: #50b275;
  font-weight: 14px;
  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  min-height: 70px;
  margin-top: 20px;
  background: ${colors.white};
  color: ${colors.dark};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${colors.gray_primary};
    color: ${colors.green_secondary};

    ${InternCard} {
      color: ${colors.green_secondary};
    }
  }

  &:active {
    background-color: #2e6643;
    color: ${colors.white};

    ${InternCard} {
      color: ${colors.white};
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 0;

  height: 100%;
  display: flex;
  align-items: center;

  > svg {
    width: 28px;
    height: 28px;
  }
`;

export const SpanCard = styled.span`
  margin-left: 6px;
  font-family: 'Lato Bold';
  font-size: 14px;
`;

export const InfosIntern = styled.div`
  margin-left: 20px;
  font-size: 12px;
  font-family: 'Lato Regular';
  line-height: 20px;
  padding-right: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AvatarContainer = styled.div`
  position: relative;
  img {
    filter: ${(props) => (props.hasUploading ? 'brightness(0.4)' : 'none')};
  }
`;

const spinner = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform:  translate(-50%, -50%) rotate(360deg);
  }
`;

export const SpinnerLoading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform-origin: center;
  display: block;
  border-top: 2px solid ${colors.green_secondary};
  border-left: 2px solid ${colors.green_secondary};

  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: ${spinner} 0.7s linear infinite;
`;
