import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import './styles.css';
import Permuti from '~/assets/Logotipo/permuti-logo-branco.svg';
import Cellphone from '~/assets/cellphone.svg';
import {getRoute} from '~/utils';
import I18n from '~/i18n/i18';

export default function ModalFinish({open, setOpen}) {
  const history = useHistory();

  const handleNavigateToDashboard = () => {
    history.replace('/announcement');
  };

  /*  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/profile`,
    state: {fromDashboard: true},
  });

  function goMyAds(event) {
    event.preventDefault();
    window.location.href = routeAds();
  }

  function routeAds() {
    const rt = getRoute();
    if (rt) {
      return `/${rt}/myads`;
    }
    return '/myads';
  } */

  return (
    <div className="containerFinish">
      <div className="internFinish">
        <img src={Permuti} alt="Success" className="logo-img-finished" />
        <img src={Cellphone} alt="Cell phone" className="imageCellPhone" />
        <div className="titleFinish">
          {' '}
          <I18n t="ModalFinal.TitleFinal" />
        </div>
        <div className="messageTime">
          <I18n t="ModalFinal.MessageFinal" />
        </div>

        <button className="buttonSee" onClick={handleNavigateToDashboard}>
          <I18n t="ModalFinal.SeeMyAds" />
        </button>
      </div>
    </div>
  );
}
