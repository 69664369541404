import React, {useState, useEffect} from 'react';
import {
  Base,
  Header,
  Percent,
  NumberName,
  Ball,
  Title,
  SeeStep,
  TextPercent,
} from './styles';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LinearDeterminate from './Progress';
import {getRoute} from '~/utils';
import I18n from '~/i18n/i18';

function Step({step, valueP}) {
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/advertise`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  useEffect(() => {
    titleAlter();
  }, [step, location]);

  function titleAlter() {
    switch (step) {
      case '1':
        setTitle(I18n.getTranslation(location, 'StepModule.image'));
        break;
      case '2':
        setTitle(I18n.getTranslation(location, 'StepModule.type'));
        break;
      case '3':
        setTitle(I18n.getTranslation(location, 'StepModule.local'));
        break;
      case '4':
        setTitle(I18n.getTranslation(location, 'StepModule.caracteristics'));
        break;
      case '5':
        setTitle(I18n.getTranslation(location, 'StepModule.internDetails'));
        break;
      case '6':
        setTitle(I18n.getTranslation(location, 'StepModule.externDetails'));
        break;
      case '7':
        setTitle(I18n.getTranslation(location, 'StepModule.propertiesValue'));
        break;
      case '8':
        setTitle(I18n.getTranslation(location, 'StepModule.rentProperties'));
        break;
      case '9':
        setTitle(I18n.getTranslation(location, 'StepModule.statusProperties'));
        break;
      case '10':
        setTitle(I18n.getTranslation(location, 'StepModule.desiredProperties'));
        break;
      case '11':
        setTitle(I18n.getTranslation(location, 'StepModule.plansProperties'));
        // default:
        //   setTitle(I18n.getTranslation(location, 'StepModule.plansProperties'));
        break;
    }
  }

  return (
    <>
      <Base>
        <Header>
          <NumberName>
            <Ball>{step}</Ball>
            <Title>{title}</Title>
          </NumberName>
          <SeeStep>
            {/* <TextGreen>Ver etapas <ExpandMoreIcon /></TextGreen> */}
          </SeeStep>
        </Header>
        <LinearDeterminate valueP={valueP} />
        <Percent>
          <TextPercent>
            {valueP}% <I18n t="StepModule.finish" />
          </TextPercent>
        </Percent>
      </Base>
    </>
  );
}

export default Step;
