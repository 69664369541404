import styled from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.div`
  max-width: 340px;
  width: 100%;
  padding: 0 20px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: ${fonts.latoRegular};
  box-sizing: border-box;
`;

export const DivTitle = styled.div`
  text-align: left;
  max-width: 300px;
  width: 100%;
  margin-top: 10px;

  > em {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 1.4px;
    font-family: ${fonts.latoItalic};
  }
`;

export const InternDiv = styled.div`
  width: 100%;
  padding: 20px 0px 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const LinkContainer = styled.p`
  font-family: ${fonts.latoRegular};
  font-size: 14px;
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  color: ${colors.dark};
  font-family: ${fonts.latoBlackItalic};
  line-height: 32px;
  /* font-weight: bold; */
`;

export const TextSmall = styled.p`
  /* width: 300px; */
  font-size: 12px;
  margin-top: 50px;
  color: ${colors.dark};

  @media (min-width: 480px) {
    &.terms__text {
      width: max-content;
      min-width: 300px;
    }
  }
`;

export const DivInputText = styled.div`
  width: 100%;

  svg {
    color: ${colors.dark_light};
  }

  .passEyes svg {
    position: relative !important;
    left: 92%;
    top: 35px;
    z-index: 1;
    cursor: pointer;
  }

  .passEyes {
    margin-top: -25px;
  }
`;

export const Rotule = styled.p`
  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: 'Lato Bold';
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const InputText = styled.input`
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  color: #1d1d1b;
  border: none;

  margin: 0px !important;
  font-family: 'Lato Regular';

  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};

  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};

    font-size: 16px;
  }
`;

export const DivError = styled.div`
  width: 100%;
  font-family: 'Lato Regular';
`;

export const ErrorMessage = styled.span`
  margin-top: 9.25px;

  color: ${colors.danger_dark};
  font-family: 'Lato Regular';
  font-weight: 500;
  font-size: 14px;
`;
