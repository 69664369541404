import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px #00000033;
  border-radius: 10px;

  /*  > input {
    background: ${colors.white};
    border: none;
    color: ${colors.dark};

    width: 100%;
    height: 50px;
    border: 1px solid #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    outline: 0;
    font-size: 16px;
    font-family: ${fonts.latoRegular};

    ::placeholder {
      color: ${colors.dark};
      font-family: ${fonts.latoRegularItalic};
      font-style: italic;
    }
  } */
  > button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    border: 1px solid #ffffff;
    width: 100%;
    max-width: 60px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 5px;

    > div {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      height: 80%;
      margin: auto;
      background: ${colors.green_secondary};
      display: grid;
      place-items: center;

      > img {
        width: 18px;
        height: 18px;
        user-select: none;
        pointer-events: none;
      }
    }
  }
`;

export const AutoSuggestContainer = styled.div`
  /*   font-weight: bold; */
  font-family: ${fonts.latoRegular}, sans-serif;
  width: 100%;
  box-sizing: border-box;

  .react-autosuggest__container {
    position: relative;
    width: 100%;
  }

  .react-autosuggest__input {
    background: ${colors.white};
    border: none;
    color: ${colors.dark};
    opacity: 1;
    text-align: left;
    width: 100%;
    height: 50px;
    border: 1px solid #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    padding-right: 55px;
    outline: 0;
    font-size: 16px;
    font-family: ${fonts.latoRegular};

    ::placeholder {
      color: ${colors.dark};
      font-family: ${fonts.latoRegularItalic};
      font-style: italic;
    }
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 52px;
    width: 100%;
    padding: 10px 5px;
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000033;
    border-radius: 5px;

    font-family: ${fonts.latoRegular}, sans-serif;
    font-size: 16px;
    z-index: 25;

    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
      background: ${colors.white};
    }
    ::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 4px;
      background: ${colors.green_secondary};
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: none;
    border: none;
    box-shadow: none;
    height: 100%;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 5px;
    background: none;
    border: none;
    box-shadow: none;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${colors.green_secondary};
    color: #fff;
  }
`;

export const SuggestItem = styled.div`
  position: relative;
  display: flex;

  > p {
    display: flex;
    flex-direction: column;
    color: inherit;
    margin-bottom: 0;
    font-family: ${fonts.latoRegular};

    > span {
      font-size: 12px;
      color: inherit;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      line-height: 14px;
      font-family: ${fonts.latoRegularItalic};
      text-overflow: ellipsis;
    }
  }
  > div {
    flex: none;
    overflow: hidden;
    margin-right: 5px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000033;

    background: #333;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
