import React from 'react';
import {CSVLink} from 'react-csv';
import PropTypes from 'prop-types';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';

import * as S from './styles';

function MyCSVDownload({data, handleGenerate, loading, filename}) {
  return (
    <>
      {!data ? null : Array.isArray(data) && data?.length > 0 ? (
        <S.Box className="download" type="button" disabled={loading}>
          <CSVLink data={data} filename={filename || 'PERMUTI_reports.csv'}>
            <S.ReportAction>
              <GetAppRoundedIcon />
            </S.ReportAction>
            Download CSV
          </CSVLink>
        </S.Box>
      ) : (
        <S.Box
          type="button"
          className="generate"
          onClick={handleGenerate}
          disabled={loading}>
          <S.ReportAction>
            <AssignmentRoundedIcon />
          </S.ReportAction>
          Gerar CSV
        </S.Box>
      )}
    </>
  );
}

MyCSVDownload.propTypes = {
  data: PropTypes.array,
  handleGenerate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filename: PropTypes.string,
};

export default MyCSVDownload;
