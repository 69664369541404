import {createGlobalStyle} from 'styled-components';

/*===================================*/
/*===== All 'Lato' font imports =====*/
/*===================================*/

/* 1. Thin font */
import LatoThin from '../assets/fonts/Lato/lato_thin.ttf';
import LatoThinItalic from '../assets/fonts/Lato/lato_thin_italic.ttf';

/* 2. Light font */
import LatoLight from '../assets/fonts/Lato/lato_light.ttf';
import LatoLightItalic from '../assets/fonts/Lato/lato_light_italic.ttf';

/* 3. Regular font */
import Lato from '../assets/fonts/Lato/lato_regular.ttf';
import LatoItalic from '../assets/fonts/Lato/lato_regular_italic.ttf';
import LatoBold from '../assets/fonts/Lato/lato_regular_bold.ttf';
import LatoBoldItalic from '../assets/fonts/Lato/lato_regular_bold_italic.ttf';

/* 4. Black font */
import LatoBlack from '../assets/fonts/Lato/lato_black.ttf';
import LatoBlackItalic from '../assets/fonts/Lato/lato_black_italic.ttf';

/* 5. Medium font */
import LatoMedium from '../assets/fonts/Lato/lato-medium.ttf';

/* 6. Bold font */
import LatoBoldOriginal from '../assets/fonts/Lato/Lato-Bold.ttf';

/* 7. Lato Healvy */
import LatoHeavyPure from '../assets/fonts/Lato/lato-heavy.ttf';

/*=====================================*/
/*===== All 'Roboto' font imports =====*/
/*=====================================*/

/* 1. Thin font */
import RobotoThin from '../assets/fonts/Roboto/roboto_thin.ttf';
import RobotoThinItalic from '../assets/fonts/Roboto/roboto_thin_italic.ttf';

/* 2. Light font */
import RobotoLight from '../assets/fonts/Roboto/roboto_light.ttf';
import RobotoLightItalic from '../assets/fonts/Roboto/roboto_light_italic.ttf';

/* 3. Regular font */
import Roboto from '../assets/fonts/Roboto/roboto_regular.ttf';
import RobotoItalic from '../assets/fonts/Roboto/roboto_regular_italic.ttf';
import RobotoBold from '../assets/fonts/Roboto/roboto_regular_bold.ttf';
import RobotoBoldItalic from '../assets/fonts/Roboto/roboto_regular_bold_italic.ttf';

/* 4. Medium font */
import RobotoMedium from '../assets/fonts/Roboto/roboto_medium.ttf';
import RobotoMediumItalic from '../assets/fonts/Roboto/roboto_medium_italic.ttf';

/* 5. Black font */
import RobotoBlack from '../assets/fonts/Roboto/roboto_black.ttf';
import RobotoBlackItalic from '../assets/fonts/Roboto/roboto_black_italic.ttf';

const GlobalStyle = createGlobalStyle`

/* =================== */
/* ====== Fonts ====== */
/* =================== */


/* ------- LATO ------- */
/* Thin */
  @font-face {
    font-family: "Lato Thin";
    src: url(${LatoThin});
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: "Lato Thin Italic";
    src: url(${LatoThinItalic});
    font-weight: 200;
    font-style: italic;
  }

  /*Bold*/
  @font-face {
    font-family: "Lato Bold";
    src: url(${LatoBoldOriginal});
 
  }

  /*Medium */
  @font-face {
    font-family: "Lato Medium";
    src: url(${LatoMedium});
    font-weight: 300;
    font-style: normal;
  }

  /* Light */
  @font-face {
    font-family: "Lato Light";
    src: url(${LatoLight});
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Lato Light Italic";
    src: url(${LatoLightItalic});
    font-weight: 300;
    font-style: italic;
  }

  /* Regular */
  @font-face {
    font-family: "Lato Regular";
    src: url(${Lato});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Lato Regular Italic";
    src: url(${LatoItalic});
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Lato Regular Bold";
    src: url(${LatoBold});
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Lato Regular Bold Italic";
    src: url(${LatoBoldItalic});
    font-weight: 700;
    font-style: italic;
  }

  /* Black */
  @font-face {
    font-family: "Lato Black";
    src: url(${LatoBlack});
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "Lato Black Italic";
    src: url(${LatoBlackItalic});
    font-weight: 900;
    font-style: italic;
  }

  /*Healvy */
    @font-face {
    font-family: "Lato Healvy";
    src: url(${LatoHeavyPure});
    font-weight: 500;
    font-style: italic;
  }

  /* ------- ROBOTO ------- */
  /* Thin */
  @font-face {
    font-family: "Roboto Thin";
    src: url(${RobotoThin});
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Thin Italic";
    src: url(${RobotoThinItalic});
    font-weight: 200;
    font-style: italic;
  }

  
  /* Light */
  @font-face {
    font-family: "Roboto Light";
    src: url(${RobotoLight});
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Light Italic";
    src: url(${RobotoLightItalic});
    font-weight: 300;
    font-style: italic;
  }

  /* Regular */
  @font-face {
    font-family: "Roboto Regular";
    src: url(${Roboto});
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Regular Bold";
    src: url(${RobotoBold});
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Regular Italic";
    src: url(${RobotoItalic});
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Roboto Regular Bold Italic";
    src: url(${RobotoBoldItalic});
    font-weight: 700;
    font-style: italic;
  }

   /* Medium */
   @font-face {
    font-family: "Roboto Medium";
    src: url(${RobotoMedium});
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Medium Italic";
    src: url(${RobotoMediumItalic});
    font-weight: 500;
    font-style: italic;
  }

  /* Black */
  @font-face {
    font-family: "Roboto Black";
    src: url(${RobotoBlack});
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Black Italic";
    src: url(${RobotoBlackItalic});
    font-weight: 900;
    font-style: italic;
  }



/* ==================== */
/* ====== Resets ====== */
/* ==================== */

  body {
    margin: 0;
    font-family: 'Lato Regular', 'Roboto Regular', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    * {
      word-break: break-word;
    }
  }
  button {
    font-family: 'Lato Regular', 'Roboto Regular', sans-serif;
    outline: none!important;
    -webkit-tap-highlight-color: transparent;
    border: none!important;
  }
  input {
    font-family: 'Lato', 'Roboto', sans-serif;
  }

/* ================================== */
/* ====== Margins and paddings ====== */
/* ================================== */

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
}

.mg-top-bottom-40 {
  margin: 40px 0 40px 0;
}

.mg-top-bottom-20 {
  margin: 20px 0 20px 0;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-40 {
  margin-bottom: 40px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

.mg-left-20 {
  margin-left: 20px;
}

.mg-left-30 {
  margin-left: 30px;
}

.mg-right-20 {
  margin-right: 20px;
}

.mg-right-10 {
  margin-right: 10px;
}

.mg-right-43 {
  margin-right: 20px;
}

.mg-right-50 {
  margin-right: 50px;
}

.mg-left-50 {
  margin-left: 50px;
}

.mg-right-65 {
  margin-right: 65px;
}

.mg-right-55 {
  margin-right: 55px;
}

.pd-2 {
  padding: 2px;
}

.pd-top-2 {
  padding-top: 2px;
}

.pd-bottom-top-2 {
  padding-bottom: 2px;
  padding-top: 2px;
}

.pd-right-2 {
  padding-right: 2px;
}

.imgView {
  object-fit: cover;
}

.border-5 {
  border-radius: 5;
  padding: 2px;
}

.rows {
  display: flex;
  flex-direction: row;
}

.justify {
  display: flex;
  align-items: center;
}

.flex-page {
  display: flex;
  justify-content: center
}

.rowsJ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.centerItens {
  display: flex;
  justify-content: center;
}

`;

export default GlobalStyle;
