import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../services/auth';

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  permittedRoles: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  permittedRoles: ['5', '4', '3', '2'],
};

export default function PrivateRoute({
  component: Component,
  permittedRoles,
  ...rest
}) {
  const userStore = useSelector((state) => state.user);

  const isPermitted =
    isAuthenticated() &&
    permittedRoles &&
    Array.isArray(permittedRoles) &&
    permittedRoles.includes(userStore?.role_id?.toString())
      ? true
      : false;

  const redirectURL =
    isAuthenticated() && userStore?.role_id ? '/profile' : '/';

  return (
    <Route
      {...rest}
      render={(props) => {
        return isPermitted ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{pathname: redirectURL, state: {from: props.location}}}
          />
        );
      }}
    />
  );
}
