import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Grid} from '@material-ui/core';
import React from 'react';
import {useHistory} from 'react-router-dom';
import I18n from '~/i18n/i18';
import {getRoute, GoShowAds} from '~/utils';
import imgEnchange from '../../../../assets/iconematching.svg';
// import I18n from '../../../i18n/i18';

import {
  Bark,
  CardPending,
  LabelDesapproved,
  TitleLocalize,
  TitleViewPorposal,
} from '../styles';
import './styles.css';

// import { Container } from './styles';

function PendingCardComponet({item, langName, seeProposal}) {
  const history = useHistory();

  function dates(info) {
    let date = new Date(info);
    return (
      <I18n
        t="Time"
        args={{
          day: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
          month:
            date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1,
          year: date.getFullYear(),
        }}
      />
    );
  }

  function routeShowAdsWant(id) {
    return '/ads/show/' + id;
  }

  function routeShowAdsReceived(id, id_proposal) {
    let rt = getRoute();
    console.log('AQUI 1', rt, id, id_proposal);
    if (rt) {
      return (
        '/' +
        rt +
        '/ads/show/' +
        id +
        '/' +
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 5) +
        id_proposal +
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 5)
      );
    }
    return (
      '/ads/show/' +
      id +
      '/' +
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5) +
      id_proposal +
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5)
    );
  }

  function viewReceivedProposal(id, id_proposal) {
    GoShowAds(`${routeShowAdsReceived(id, id_proposal)}`);
    /*  history.push({
      pathname: `${routeShowAdsReceived(id, id_proposal)}`,
      state: {proposal_id: id_proposal},
    }); */
  }

  function viewWantProposal(id) {
    console.log(id);
    history.push({pathname: `${routeShowAdsWant(id)}`, state: {course: true}});
  }

  function locationReceived(item) {
    return (
      <>
        {' '}
        {item.my_property.type_property.name_pt} <I18n t="ViewAds.In" />{' '}
        {item.my_property.address.neighborhood}, {item.my_property.address.city}
        , {item.my_property.address.state}, {item.my_property.address.country}
      </>
    );
  }

  function locationNotReceived(item) {
    return (
      <>
        {item.proposal_property.type_property.name_pt} <I18n t="ViewAds.In" />{' '}
        {item.proposal_property.address.neighborhood},{' '}
        {item.proposal_property.address.city},{' '}
        {item.proposal_property.address.state},{' '}
        {item.proposal_property.address.country}
      </>
    );
  }

  function routeShowPreview(id) {
    const rt = getRoute();
    if (rt) {
      window.location.href = `/${rt}/myads/show/${id}`;
    }
    window.location.href = `/myads/show/${id}`;
  }

  function viewReceivedProposal(id) {
    GoShowAds(`/view/${id}`);
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
      <Bark>
        <CardPending>
          <div>
            <TitleLocalize>{locationReceived(item)}</TitleLocalize>
            <TitleLocalize style={{marginTop: '5px'}}>
              {' '}
              Recebido por{' '}
              <b>
                {' '}
                {item.proposal_property.user?.profile?.enterprise
                  ? item.proposal_property.user?.profile?.enterprise
                  : item.proposal_property.user?.name}{' '}
              </b>
            </TitleLocalize>
            <TitleViewPorposal
              onClick={() => {
                viewReceivedProposal(item.my_property.id);
              }}>
              <span className="mg-right-8">Ver imóvel proposto</span>{' '}
              <FontAwesomeIcon icon={faChevronRight} />
            </TitleViewPorposal>
          </div>

          <div className="flex-page mg-top-bottom-16">
            <img
              src={imgEnchange}
              width="14px"
              height="17px"
              alt="icon matching"
            />
          </div>

          <div>
            <TitleLocalize>{locationNotReceived(item)}</TitleLocalize>
            <TitleLocalize style={{marginTop: '5px'}}>
              {' '}
              Enviado por{' '}
              <b>
                {' '}
                {item.my_property.user?.profile?.enterprise
                  ? item.my_property.user?.profile?.enterprise
                  : item.my_property.user?.name}{' '}
              </b>
            </TitleLocalize>
            <TitleViewPorposal
              onClick={() => {
                viewReceivedProposal(item.proposal_property.id);
              }}>
              <span className="mg-right-8">Ver imóvel de interesse</span>{' '}
              <FontAwesomeIcon icon={faChevronRight} />
            </TitleViewPorposal>
          </div>
          <div className=" rows flex-between mg-top-10">
            <TitleLocalize>
              <I18n t={'ModalProposal.PedingOn'} />
              {'  '} {dates(item.updated_at)}
            </TitleLocalize>
            <LabelDesapproved>Recusada</LabelDesapproved>
          </div>
        </CardPending>
      </Bark>
    </Grid>
  );
}

export default PendingCardComponet;
