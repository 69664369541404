import styled from 'styled-components';
import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const Container = styled.div`
  padding: 30px 50px;
  position: relative;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
  font-family: ${fonts.latoRegular};
`;

export const InternDiv = styled.div`
  /* padding: 0px 30px 20px 60px; */

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  font-size: 24px;
  color: #1d1d1b;
  font-style: italic;
  font-weight: bold;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  text-align: center;

  svg {
    position: absolute;
    top: 15px;
    right: 24px;
  }
`;

export const DivInputText = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const DivAlterImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonWhite = styled.button`
  background-color: #ffffff;
  border: none;
  height: 50px;
  width: 40%;
  margin-top: 50px;
  border-radius: 5px;
  color: ${colors.green_secondary};
  font-size: 18px;
  font-family: ${fonts.latoBlack};
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
  box-shadow: 0px 3px 6px #00000033;
  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: #e6ffef 2px solid;
  }
`;

export const ButtonGreen = styled(ButtonWhite)`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  width: 100%;
  letter-spacing: 1.8px;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: ${colors.green_third};
  }

  &:disabled {
    background: ${colors.gray_secondary};
  }
`;

export const TextButton = styled.span`
  margin-left: 10px;
`;

export const ModalResponsive = styled.div`
  @media (min-width: 481px) and (max-width: 700px) {
    width: 100%;
  }
  @media (min-width: 800px) and (max-width: 2560px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Message = styled.div`
  width: 100%;
  font-size: 16px;
`;
export const DivDanger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const AdvertTitle = styled.div`
  margin-bottom: 20px;
  color: #a88721;

  font-size: 24px;
  font-family: ${fonts.latoRegularBold};
`;

export const DivConfirm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
`;

export const ButtonsGreen = styled.button`
  color: ${colors.white};
  background: ${colors.green_secondary};
  font-size: 18px;
  height: 40px;
  width: 100%;
  border: none;
  box-shadow: 0px 3px 6px #00000033;
  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: 1px solid #e6ffef;
  }
  &:focus {
    outline: 0;
  }
`;

export const DivNext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const DivMetrics = styled.div`
  display: flex;
  flex-direction: row;

  input {
    border: none !important;
  }

  &.with-border {
    width: 100%;
    overflow: hidden;
    justify-content: space-between;

    border-bottom: ${(props) =>
      props.value
        ? `2px solid ${colors.green_secondary}!important`
        : '1px solid #808076!important'};
  }
`;

export const DivInputMetrics = styled.div`
  height: 73px;
  width: 85%;
`;

export const DivSelectMetrics = styled.div`
  /* width: 15%; */
  height: 73px;
  width: 70px;

  border: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: -1px; */
`;

export const DivText = styled.div`
  padding-top: 15px;
  text-align: center;
  color: #1d1d1b;
  font-size: 18px;
`;

export const TitlePage = styled.p`
  color: #1d1d1b;
  font-size: 24px;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  text-align: left;
`;
