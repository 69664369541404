import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {desencrypta} from '~/utils';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalDelete({
  id,
  open,
  setOpen,
  text,
  handleDelete,
  listUser,
  page,
  setPage,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  /* async function deleteUser() {
    handleDelete(id);
    handleClose();
  } */

  async function deleteUser() {
    try {
      setIsSubmitting(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.delete(`/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fireAction(
        'Tudo certo!',
        'O usuário foi deletado da base de dados.',
        'success',
      );

      if (page === 1) {
        listUser();
      } else {
        setPage(1);
      }
    } catch (err) {
      const status = err?.response?.status;
      const message = err?.response?.data?.data?.message;

      if (status === 422) {
        if (message === 'User has associated data') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Não é possível excluir este usuário pois ele possui vínculo com outro dado no sistema, seja uma assinatura/plano, anúncio ou matchings ativos com outros usuários.',
            'error',
          );
        } else if (message === 'User not found') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Não foi possível encontrar este usuário na base de dados. Aparentemente este usuário já foi deletado do sistema.',
            'error',
          );
        } else {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Não foi possível completar sua solicitação no momento. Tente novamente mais tarde.',
            'error',
          );
        }
      } else {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante a exclusão. Por favor, tente novamente mais tarde.',
          'error',
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle>Atenção</AdvertTitle>
              <Message>
                Se confirmar a exclusão do {text}, ele não poderá mais ser
                recuperado. Deseja realmente <b>excluir</b> o {text}?
              </Message>
              <DivConfirm>
                <ButtonsWhite disabled={isSubmitting} onClick={deleteUser}>
                  SIM
                </ButtonsWhite>
                <ButtonsWhite disabled={isSubmitting} onClick={handleClose}>
                  NÃO
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
