import React, {useEffect, useState} from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import colors from '~/UI/colors';
import {Container} from './styles';
import I18n from '~/i18n/i18';
import ModalDesiredEdit from '../ModalDesiredEdit';
import ModalDelete from '../ModalDelete';
import {getRoute} from '~/utils';

function Accordion({
  getProperty,
  info,
  idAds,
  id,
  deleteMatches,
  type,
  country,
  state,
  city,
  valueMax,
  valueMin,
  length,
  currency,
  mask,
  itensBackend,
}) {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [langName, setLangName] = useState('pt');

  return (
    <Container state={true}>
      <div className="header">
        <div className="first">
          <div
            /* onClick={() => {
              setOpen(!open);
            }} */
            className="title">
            <I18n t="DesiredProperty.Profile" /> {length}{' '}
            {/* {open ? <ExpandLess /> : <ExpandMore />} */}
          </div>
          <div className="iconsSet">
            <div>
              <FontAwesomeIcon
                onClick={() => setOpenEdit(true)}
                className="iconEdit"
                icon={faEdit}
                color={colors.green_secondary}
              />
            </div>
            <div className="icon" onClick={() => setOpenDelete(true)}>
              <FontAwesomeIcon
                className="icon"
                icon={faTrashAlt}
                color={colors.green_secondary}
              />
            </div>
          </div>
        </div>
        <div className="second">
          <div className="secondTitle">
            Tipo de imóvel{' '}
            <span className="information">
              {
                info.type_property[
                  langName !== '' ? `name_${langName}` : 'name_en'
                ]
              }
            </span>
          </div>
        </div>
      </div>

      <div className="content ">
        {country && (
          <p className="secondTitle">
            <I18n t="ModalsEdit.country" />{' '}
            <span className="information">{country}</span>
          </p>
        )}
        {state && (
          <p className="secondTitle second10">
            <I18n t="ModalsEdit.state" />{' '}
            <span className="information">{state}</span>
          </p>
        )}
        {city && (
          <p className="secondTitle  second10">
            <I18n t="ModalsEdit.city" />{' '}
            <span className="information">{city}</span>
          </p>
        )}

        {info.neighborhood && (
          <p className="secondTitle  second10">
            Bairro <I18n t="ModalsEdit.neighborhood" />{' '}
            <span className="information">{info.neighborhood}</span>
          </p>
        )}
        {valueMin && (
          <p className="secondTitle  second10">
            <I18n t="ModalFilter.ValueMin" />{' '}
            <span className="information">
              <I18n t={`CurrencySymbol.${currency}`} /> {valueMin}
            </span>
          </p>
        )}
        {valueMax && (
          <p className="secondTitle  second10">
            <I18n t="ModalFilter.ValueMax" />{' '}
            <span className="information">
              <I18n t={`CurrencySymbol.${currency}`} /> {valueMax}
            </span>
          </p>
        )}
      </div>

      <ModalDesiredEdit
        itensBackend={itensBackend}
        maskInit={mask}
        getProperty={getProperty}
        open={openEdit}
        setOpen={setOpenEdit}
        id={id}
        idAds={idAds}
        info={info}
      />
      <ModalDelete
        open={openDelete}
        setOpen={setOpenDelete}
        id={id}
        idAds={idAds}
        getProperty={getProperty}
      />
    </Container>
  );
}

Accordion.prototype = {
  type: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  state: PropTypes.string,
  city: PropTypes.string,
  valueMax: PropTypes.string.isRequired,
  valueMin: PropTypes.string.isRequired,
  length: PropTypes.number,
  deleteMatches: PropTypes.func,
  id: PropTypes.string,
};

export default Accordion;
