import styled from 'styled-components';
import colors from '../../UI/colors';
// import {LabelDraft} from '../AdvertsUpdate/NewComponents/styles';
import metrics from '../../UI/metrics';

export const CardExtern = styled.div`
  background-color: #f2f2ed;
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 5px;
  padding: 20px;
`;

export const Space = styled.div`
  margin: 54px;
  @media only screen and (max-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const Title = styled.div`
  font-family: 'Lato';
  font-size: 18px;
  font-weight: bold;
`;

export const TitlePage = styled(Title)`
  font-size: 30px;
  color: #1d1d1b;
`;

export const TitleDraft = styled(Title)`
  color: #999991;
`;

export const TitleWait = styled(Title)`
  color: #a88721;
`;

export const TitleDessaproved = styled(Title)`
  color: #cc2c29;
`;

export const TitleApproved = styled(Title)`
  color: #6b915c;
`;

export const ButtonOrder = styled.div`
  color: ${colors.green_secondary};
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 5px;
  width: 143px;
  height: 40px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  :focus {
    outline: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #f2f2ed;
    color: #50b275;
  }
  &:active {
    background: #2e6643;
    color: #e6ffef;
  }
  &:disabled {
    background: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
`;

export const ButtonEdit = styled.button`
  background-color: ${colors.green_secondary};
  box-shadow: 0px 3px 5px #00000040;
  color: ${colors.white};
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px #00000040;
  &:hover {
    background: #2e6643;
    color: #fff;
  }
  &:disabled {
    background: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
`;

export const ButtonNew = styled(ButtonEdit)``;

// export const LabelPending = styled(LabelDraft)`
//   background-color: #ffd34d;
//   color: ${colors.white};
// `;

// export const LabelApproved = styled(LabelDraft)`
//   background-color: #8ecc51;
//   color: ${colors.white};
// `;

// export const LabelDesapproved = styled(LabelDraft)`
//   background-color: #f56662;
//   color: ${colors.white};
// `;

export const ViewJustify = styled.div`
  color: ${colors.green_secondary};
  text-decoration: underline;
`;

export const Filter = styled.div`
  color: ${colors.green_secondary};
  font-family: 'Lato';
  font-weight: bold;
`;

export const TitleLocalize = styled.div`
  font-size: 14px;
  color: ${colors.dark};
  font-family: Lato Medium;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TitleViewMy = styled.button`
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${colors.green_secondary};
  margin-top: 8px;
  font-family: Lato Black Original;
  &:hover {
    color: ${colors.white};
    background-color: ${colors.green_secondary};
  }

  &:active {
    color: ${colors.white};
    background-color: ${colors.green_third};
  }
`;

export const TitleViewMyG = styled(TitleViewMy)``;

export const TitleViewPorposal = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 8px;
  color: ${colors.green_secondary};
  font-family: Lato Black Original;
  /* font-weight: bold; */
  /* flex-direction: row; */
  /* align-items: center; */

  &:hover {
    color: ${colors.white};
    background-color: ${colors.green_secondary};
  }

  &:active {
    color: ${colors.white};
    background-color: ${colors.green_third};
  }
`;

export const ButtonSwitch = styled.button`
  color: ${(props) => (props.active ? colors.green_secondary : colors.dark)};
  font-size: 18px;
  font-family: Lato Black Original;
  word-wrap: break-word;
  border: none;
  border-bottom: ${(props) =>
    props.active ? '5px solid ' + colors.green_secondary : 'none'};
  background-color: ${colors.white};
  padding-bottom: 20px;
  &:hover {
    color: ${colors.green_third};
    border-bottom: 5px solid ${colors.green_third};
  }
  &:focus {
    outline: 0;
  }
`;
