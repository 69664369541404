import React, {useState, useEffect} from 'react';
import VMasker from 'vanilla-masker';

import {
  TitleModal,
  Rotule,
  DivInputText,
  InputText,
  ButtonSave,
  InternDiv,
  DivError,
  ErrorMessage,
} from '../ModalDataPersonal/styles';
import '../styles.css';

import CloseIcon from '@material-ui/icons/Close';
import ReactLoading from 'react-loading';
import {Dialog} from '@material-ui/core';

import colors from '~/UI/colors';

export default function ModalDataPersonal({
  open,
  setOpen,
  profile,
  handleSubmit,
  loading,
}) {
  const [enterprise, setEnterprise] = useState('');
  const [company, setCompany] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [license, setLicense] = useState('');
  const [number, setNumber] = useState('');

  useEffect(() => {
    setEnterprise(
      profile?.profile?.enterprise ? profile?.profile?.enterprise : '',
    );
    setCompany(
      profile?.profile?.company_name ? profile?.profile?.company_name : '',
    );
    setCnpj(profile?.profile?.cnpj ? profile?.profile?.cnpj : '');
    setLicense(profile?.profile?.license ? profile?.profile?.license : '');
    setNumber(
      profile?.profile?.number_of_employees
        ? profile?.profile?.number_of_employees
        : '',
    );
  }, [profile]);

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const resetForm = () => {
    setEnterprise(
      profile?.profile?.enterprise ? profile?.profile?.enterprise : '',
    );
    setCompany(
      profile?.profile?.company_name ? profile?.profile?.company_name : '',
    );
    setCnpj(profile?.profile?.cnpj ? profile?.profile?.cnpj : '');
    setLicense(profile?.profile?.license ? profile?.profile?.license : '');
    setNumber(
      profile?.profile?.number_of_employees
        ? profile?.profile?.number_of_employees
        : '',
    );
    setErrors({});
    setTouched({});
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  /* ---------------------- */
  /* ---------------------- */
  /* ---------------------- */

  /* --- DINAMIC FIELDS VALIDATION --- */
  const nameSchema = (value) => {
    switch (profile?.profile?.type_advertiser?.toLowerCase()) {
      case 'agent':
        return value.trim() && value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '';
      case 'broker':
        return !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '';
      default:
        return value.trim() && value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '';
    }
  };

  const cnpjSchema = (value) => {
    switch (profile?.profile?.type_advertiser?.toLowerCase()) {
      case 'agent':
        return value.trim() && value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '';
      case 'broker':
        return !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '';
      default:
        return value.trim() && value.trim().length < 5
          ? 'Mínimo de 5 caracteres'
          : '';
    }
  };

  /* ------------------ */
  /* COMPANY */
  const handleChangeCompany = (value) => {
    setCompany(value);

    setErrors((errors) => {
      return {
        ...errors,
        company: nameSchema(value),
      };
    });
  };

  const handleBlurCompany = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        company: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        company: nameSchema(value),
      };
    });
  };
  /* ------------------ */
  /* ENTERPRISE */
  const handleChangeEnterprise = (value) => {
    setEnterprise(value);

    setErrors((errors) => {
      return {
        ...errors,
        enterprise: nameSchema(value),
      };
    });
  };

  const handleBlurEnterprise = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        enterprise: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        enterprise: nameSchema(value),
      };
    });
  };
  /* ------------------ */
  /* CNPJ */
  const handleChangeCnpj = (value) => {
    setCnpj(value);

    setErrors((errors) => {
      return {
        ...errors,
        cnpj: cnpjSchema(value),
      };
    });
  };

  const handleBlurCnpj = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        cnpj: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        cnpj: cnpjSchema(value),
      };
    });
  };
  /* ------------------ */
  /* License */
  const handleChangeLicense = (value) => {
    setLicense(value);

    setErrors((errors) => {
      return {
        ...errors,
        license: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 4
          ? 'Mínimo de 4 caracteres'
          : '',
      };
    });
  };

  const handleBlurLicense = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        license: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        license: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 4
          ? 'Mínimo de 4 caracteres'
          : '',
      };
    });
  };
  /* ------------------ */
  /* Number */
  const handleChangeNumber = (value) => {
    setNumber(value);

    setErrors((errors) => {
      return {
        ...errors,
        number: Number(value) < 0 ? 'Campo inválido' : '',
      };
    });
  };

  const handleBlurNumber = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        number: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        number: Number(value) < 0 ? 'Campo inválido' : '',
      };
    });
  };
  /* ---------------------- */
  /* ---------------------- */
  /* ---------------------- */
  function editDataEnterprise(event) {
    event.preventDefault();

    const userUpdated = {
      profile: {
        enterprise: enterprise.trim(),
        company_name: company.trim(),
        cnpj: cnpj.trim(),
        license: license.trim(),
        number_of_employees: number.trim(),
      },
    };

    handleSubmit(userUpdated);
  }

  return (
    <div>
      <Dialog
        scroll="body"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <TitleModal>
          <span>Dados da Empresa</span>
          <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
        </TitleModal>
        <form onSubmit={editDataEnterprise} style={{padding: '20px'}}>
          <InternDiv>
            <div>
              {profile?.profile?.type_advertiser?.toLowerCase() === 'agent' ? (
                <>
                  <DivInputText>
                    <Rotule error={errors.license} valid={license}>
                      Licença (CRECI) *
                    </Rotule>
                    <InputText
                      value={license}
                      error={errors.license}
                      touched={touched.license}
                      onChange={(event) =>
                        handleChangeLicense(event.target.value)
                      }
                      onBlur={(event) => handleBlurLicense(event.target.value)}
                      placeholder="Informe a licença..."
                    />
                    {errors.license && (
                      <DivError>
                        <ErrorMessage>{errors.license}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.company} valid={company}>
                      Razão Social
                    </Rotule>
                    <InputText
                      value={company}
                      error={errors.company}
                      touched={touched.company}
                      onChange={(event) =>
                        handleChangeCompany(event.target.value)
                      }
                      onBlur={(event) => handleBlurCompany(event.target.value)}
                      placeholder="Informe a razão social..."
                    />
                    {errors.company && (
                      <DivError>
                        <ErrorMessage>{errors.company}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.enterprise} valid={enterprise}>
                      Empresa (Nome fantasia)
                    </Rotule>
                    <InputText
                      value={enterprise}
                      error={errors.enterprise}
                      touched={touched.enterprise}
                      onChange={(event) =>
                        handleChangeEnterprise(event.target.value)
                      }
                      onBlur={(event) =>
                        handleBlurEnterprise(event.target.value)
                      }
                      placeholder="Informe o nome fantasia..."
                    />
                    {errors.enterprise && (
                      <DivError>
                        <ErrorMessage>{errors.enterprise}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.cnpj} valid={cnpj}>
                      CNPJ
                    </Rotule>
                    <InputText
                      value={cnpj}
                      error={errors.cnpj}
                      touched={touched.cnpj}
                      onChange={(event) => handleChangeCnpj(event.target.value)}
                      onBlur={(event) => handleBlurCnpj(event.target.value)}
                      placeholder="Informe o CNPJ..."
                    />
                    {errors.cnpj && (
                      <DivError>
                        <ErrorMessage>{errors.cnpj}</ErrorMessage>
                      </DivError>
                    )}
                  </DivInputText>
                </>
              ) : (
                <>
                  <DivInputText>
                    <Rotule error={errors.company} valid={company}>
                      Razão Social
                    </Rotule>
                    <InputText
                      value={company}
                      error={errors.company}
                      touched={touched.company}
                      onChange={(event) =>
                        handleChangeCompany(event.target.value)
                      }
                      onBlur={(event) => handleBlurCompany(event.target.value)}
                      placeholder="Informe a razão social..."
                    />
                    {errors.company ? (
                      <DivError>
                        <ErrorMessage>{errors.company}</ErrorMessage>
                      </DivError>
                    ) : null}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.enterprise} valid={enterprise}>
                      Empresa (Nome Fantasia)
                    </Rotule>
                    <InputText
                      value={enterprise}
                      error={errors.enterprise}
                      touched={touched.enterprise}
                      onChange={(event) =>
                        handleChangeEnterprise(event.target.value)
                      }
                      onBlur={(event) =>
                        handleBlurEnterprise(event.target.value)
                      }
                      placeholder="Informe o nome fantasia..."
                    />
                    {errors.enterprise ? (
                      <DivError>
                        <ErrorMessage>{errors.enterprise}</ErrorMessage>
                      </DivError>
                    ) : null}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.cnpj} valid={cnpj}>
                      CNPJ
                    </Rotule>
                    <InputText
                      value={cnpj}
                      error={errors.cnpj}
                      touched={touched.cnpj}
                      onChange={(event) => handleChangeCnpj(event.target.value)}
                      onBlur={(event) => handleBlurCnpj(event.target.value)}
                      placeholder="Informe o CNPJ"
                    />
                    {errors.cnpj ? (
                      <DivError>
                        <ErrorMessage>{errors.cnpj}</ErrorMessage>
                      </DivError>
                    ) : null}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.license} valid={license}>
                      Licença
                    </Rotule>
                    <InputText
                      value={license}
                      error={errors.license}
                      touched={touched.license}
                      onChange={(event) =>
                        handleChangeLicense(event.target.value)
                      }
                      onBlur={(event) => handleBlurLicense(event.target.value)}
                      placeholder="Informe a licença"
                    />
                    {errors.license ? (
                      <DivError>
                        <ErrorMessage>{errors.license}</ErrorMessage>
                      </DivError>
                    ) : null}
                  </DivInputText>

                  <DivInputText className="divMargin">
                    <Rotule error={errors.number} valid={number}>
                      Numero de Funcionários
                    </Rotule>
                    <InputText
                      value={number}
                      error={errors.number}
                      touched={touched.number}
                      onChange={(event) =>
                        handleChangeNumber(VMasker.toNumber(event.target.value))
                      }
                      onBlur={(event) => handleBlurNumber(event.target.value)}
                      placeholder="Informe o número de funcionários"
                    />
                  </DivInputText>
                </>
              )}
            </div>
            <ButtonSave
              type="submit"
              disabled={
                (profile?.profile?.type_advertiser?.toLowerCase() === 'agent'
                  ? !license.trim() ||
                    license.trim().length < 4 ||
                    Number(number.trim()) < 0
                  : !company.trim() ||
                    company.trim().length < 3 ||
                    !enterprise.trim() ||
                    enterprise.trim().length < 3 ||
                    !cnpj.trim() ||
                    cnpj.trim().length < 5 ||
                    !license.trim() ||
                    license.trim().length < 4 ||
                    Number(number.trim()) < 0) || loading
              }
              className={'btnDataPersonal btnload'}>
              {loading ? (
                <ReactLoading
                  type={'spin'}
                  className="load"
                  color={colors.green_secondary}
                  width={'30px'}
                />
              ) : (
                'Editar'
              )}
            </ButtonSave>
          </InternDiv>
        </form>
      </Dialog>
    </div>
  );
}

/*  useEffect(() => {
    if (profile) loadData();
  }, [profile]); */

/*  function loadData() {
    setId(profile.id);
    if (profile.enterprise) {
      setEnterprise(profile.enterprise);
    }
    if (profile.company_name) {
      setCompany(profile.company_name);
    }
    if (profile.cnpj) {
      setCnpj(profile.cnpj);
    }
    if (profile.license) {
      setLicense(profile.license);
    }

    if (profile.user_type === 'Anunciante') {
      if (profile.type_advertiser === 'Imobiliaria') {
        console.log('oii');
        setSimbolImob('*');
        setInputNumber(true);
      }

      if (
        profile.type_advertiser === 'Imobiliaria' ||
        profile.type_advertiser === 'Corretor'
      ) {
        setSimbol('*');
      }
    }
  } */

/*  useEffect(() => {
    if (profile.user_type === 'Anunciante') {
      if (profile.type_advertiser === 'Corretor') {
        if (license) {
          setValidate(true);
        } else {
          setValidate(false);
        }
      } else if (profile.type_advertiser === 'Imobiliaria') {
        console.log('here');
        if (cnpj.length > 0 && enterprise && company && license) {
          setValidate(true);
        } else {
          setValidate(false);
        }
      } else if (profile.type_advertiser === 'Proprietário') {
        setValidate(true);
      }
    } else {
      setValidate(true);
    }
  }, [license, cnpj, company, enterprise]); */

/* function verification() {
    if (profile.user_type === 'Anunciante') {
      if (profile.type_advertiser === 'Corretor') {
        verificationLicense();
      }

      if (
        profile.type_advertiser === 'Imobiliaria' ||
        profile.type_advertiser === 'Corretor'
      ) {
        verificationLicense();
        verificationCnpj();
        verificationEnterprise();
        verificationCompany();
      }
    }
  } */
/* 
  function verificationLicense() {
    if (license) {
      setLicenseErrorClass('greenEmail');
      setLicenseErrorClassInput('green');
      setManLicense(false);
    } else {
      setLicenseErrorClass('redEmail');
      setLicenseErrorClassInput('red');
      setManLicense(true);
    }
  } */

/* function verificationCompany() {
    if (company) {
      setCompanyErrorClass('greenEmail');
      setCompanyErrorClassInput('green');
      setManCompany(false);
    } else {
      setCompanyErrorClass('redEmail');
      setCompanyErrorClassInput('red');
      setManCompany(true);
    }
  } */

/* function verificationEnterprise() {
    if (enterprise) {
      setEnterpriseErrorClass('greenEmail');
      setEnterpriseErrorClassInput('green');
      setManEnterprise(false);
    } else {
      setEnterpriseErrorClass('redEmail');
      setEnterpriseErrorClassInput('red');
      setManEnterprise(true);
    }
  }
 */
/* function verificationCnpj(local) {
    if (cnpj.length > 0) {
      setLicenseErrorClass('greenEmail');
      setLicenseErrorClassInput('green');
      setManCnpj(false);
    } else {
      if (cnpj.length === 0) {
        setPhraseCnpj('Campo obrigatório!');
      } else {
      }
      setCnpjErrorClass('redEmail');
      setCnpjErrorClassInput('red');
      setManCnpj(true);
    }
  } */
