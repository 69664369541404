import React from 'react';
import TableAdvertise from './Table';
import {Grid} from '@material-ui/core';
import {ButtonSmall, ButtonTransparent} from '../../../UI/styles';
import {useState} from 'react';
import {desencrypta, exit} from '../../../utils';
import {useEffect} from 'react';
import Autocomplete from 'react-google-autocomplete';
import I18nItens from '../../../utils/translate/propertyType.json';
import {DivGoogle} from '../Drafts/styles';
import MenuFilterI18n from '../../../components/Menu/MenuFilterI18n';
import ModalFilter from '../../../components/ModalFilter';
import {Results, ContainerPagination} from '../styles';
import Dash from '../../../components/Dashboard';
import ButtonsAd from '../buttonsAd';

import {scrollTop} from '~/utils/scrollTop';

import Pagination from '~/components/_PaginationTab';
import apiBackend from '~/services/apiaxios';
import currencyURL from '~/services/currency';
import FiltersSearch from '../Filters';
import LoadingAds from '../LoadingAds';
import MyAds404 from '../404';

import * as Sentry from '@sentry/react';

const itens = [
  'All',
  'House',
  'Gated Community House',
  'Condo',
  'Land',
  'Gated Community Land',
  'Country House',
  'Farm',
  'Store',
  'Office',
  'Commercial House',
  'Industrial Shed',
  'Commercial Building',
  'Commercial Land',
];

function Pending() {
  const [currencyValues, setCurrencyValues] = useState('');
  const [properties, setProperties] = useState([]);
  const [digit, setDigit] = useState('');
  const [user_name, setUser_name] = useState('');
  const [created_date, setCreated_data] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [property_type, setProperty_type] = useState('');
  const [modalFilter, setModalFilter] = useState(false);
  const [max_price, setMax_price] = useState('');
  const [min_price, setMin_price] = useState('');
  const [currency, setCurrency] = useState('BRL');
  const [query, setQuery] = useState('');

  /* --- PAGINATION TABLE */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [allTotal, setAllTotal] = useState(0);
  const [afterCurrency, setAfterCurrency] = useState(false);
  const [see404, setSee404] = useState(false);
  const [hasLoading, setHasLoading] = useState(false);
  const handleChangePage = (value) => {
    console.log(value);
    setPage(value);
  };
  /* --------------------------------------------------- */

  async function getCurrency() {
    /* TODO coin */
    fetch(`${currencyURL}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrencyValues(data);
        setAfterCurrency(true);
      })
      .catch((e) => {
        if (e.status === 401) {
          console.log(e);
        } else {
          Sentry.captureException(e);
        }
      });
  }

  useEffect(() => {
    getCurrency();
  }, []);

  function getAllCallback() {
    getAllProperties(setProperties, page, query);
  }

  function getAllProperties(setState, page, query) {
    setHasLoading(true);
    setSee404(false);

    let token = desencrypta(localStorage.getItem('@User_informations'));
    console.log(token);
    apiBackend
      .get(
        `/v1/property?paginate=true&status=inactive&order=desc&field=created_at&page=${page}&limit=7${
          query.toString() !== '' ? '&' + query : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        setState(response?.data?.property?.data);

        if (response?.data?.property?.data?.length === 0) {
          setSee404(true);
        } else {
          setLastPage(response?.data?.property?.meta?.last_page);
          setAllTotal(response?.data?.property?.meta?.total);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      })
      .finally(() => {
        setHasLoading(false);
      });
  }

  function search(event) {
    event.preventDefault();
    alert('Está feature está em desenvolvimento.');
  }
  return (
    <>
      <Dash button={'ads'} title={'Anúncios'}>
        <ButtonsAd />
        <FiltersSearch
          I18nItens={I18nItens}
          itens={itens}
          search={search}
          getAllProperties={getAllProperties}
          page={page}
          setProperties={setProperties}
          query={query}
          setQuery={setQuery}
          properties={properties}
          setPage={setPage}
          status={'inactive'}
        />
        <div style={{minHeight: '100vh', background: '#fff'}}>
          {hasLoading && <LoadingAds />}
          {see404 && <MyAds404 />}

          {properties.length > 0 && (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Results>{allTotal} resultados encontrados</Results>
                  <TableAdvertise
                    information={properties}
                    currencyValues={currencyValues}
                    getAllProperties={getAllCallback}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ContainerPagination>
                  {lastPage > 1 && (
                    <Pagination
                      count={lastPage}
                      page={page}
                      handleChange={(event, value) => handleChangePage(value)}
                    />
                  )}
                </ContainerPagination>
              </Grid>
            </>
          )}
        </div>

        <ModalFilter
          search={search}
          digit={digit}
          setDigit={setDigit}
          user_name={user_name}
          setUser_name={setUser_name}
          open={modalFilter}
          setOpen={setModalFilter}
          created_date={created_date}
          setCreated_data={setCreated_data}
          country={country}
          setCountry={setCountry}
          state={state}
          setState={setState}
          city={city}
          setCity={setCity}
          property_type={property_type}
          setProperty_type={setProperty_type}
          max_price={max_price}
          setMax_price={setMax_price}
          min_price={min_price}
          setMin_price={setMin_price}
          currency={currency}
          setCurrency={setCurrency}
        />
      </Dash>
    </>
  );
}

export default Pending;
