import * as Sentry from '@sentry/react';
const {default: Axios} = require('axios');

/* Aparentemente não usado */
export async function geocodeSearch(
  langName,
  address,
  setFormatter,
  searchAdvanced,
  setViewSkeleton,
  setData,
  order,
  field,
  querySearch,
  formatter,
  coin,
) {
  console.log(address);

  if (formatter) {
    Axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&language=${langName}&key=${process.env.REACT_APP_PLACES}`,
    )
      .then((response) => {
        console.log(response);
        const resp = response.data.results[0];
        setFormatter(resp.formatted_address);
        searchAdvanced(
          resp.geometry.location.lat,
          resp.geometry.location.lng,
          resp.geometry.viewport.northeast.lat,
          resp.geometry.viewport.northeast.lng,
          setData,
          setViewSkeleton,
          order,
          field,
          querySearch,
          coin,
        );
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  } else {
    searchAdvanced(
      '',
      '',
      '',
      '',
      setData,
      setViewSkeleton,
      order,
      field,
      querySearch,
      coin,
    );
  }
}

export async function geoCodeDesired({
  place_id,
  setLatitude,
  setLongitude,
  setViewLat,
  setViewLon,
  setViewLat2,
  setViewLon2,
}) {
  Axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${process.env.REACT_APP_PLACES}`,
  )
    .then((response) => {
      const resp = response.data.results[0];
      console.log(resp.geometry.location.lat, 'teste');
      setLatitude(resp.geometry.location.lat);
      setLongitude(resp.geometry.location.lng);
      setViewLat(resp.geometry.viewport.northeast.lat);
      setViewLon(resp.geometry.viewport.northeast.lng);
      setViewLat2(resp?.geometry?.viewport?.southwest?.lat);
      setViewLon2(resp?.geometry?.viewport?.southwest?.lng);
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}

/* Aparentemente não usado */
export async function geoCodeFilter(place, setChoose) {
  console.log(place);
  Axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${process.env.REACT_APP_PLACES}`,
  )
    .then((response) => {
      const resp = response.data.results[0];
      setChoose({
        latitude: resp.geometry.location.lat,
        longitude: resp.geometry.location.lng,
        view_lat: resp.geometry.viewport.northeast.lat,
        view_lon: resp.geometry.viewport.northeast.lng,
        view_lat2: resp?.geometry?.viewport?.southwest?.lat,
        view_lon2: resp?.geometry?.viewport?.southwest?.lng,
      });
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}
