import React from 'react';

import {Divider, Grid} from '@material-ui/core';
import {Container} from './styles';
import ItemAccordion from './ItemIntention';

function AccordionIntentions({currency, langName, advertise}) {
  return (
    <>
      <Container>
        <Grid container>
          {advertise?.intention?.map((item) => {
            return (
              <ItemAccordion
                currency={currency}
                langName={langName}
                intention={item}
              />
            );
          })}
        </Grid>
      </Container>
      <Divider />
    </>
  );
}

export default AccordionIntentions;
