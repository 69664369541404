import React from 'react';
import {
  Container,
  BoxTags,
  LabelPaid,
  LabelNotPaid,
  LabelAll,
  LabelPending,
} from './styles';

export default function Filters({value, setSelectedValue, resetPage}) {
  return (
    <Container>
      <div className="borderedTop" />
      <BoxTags>
        <LabelAll
          status={value === 'all'}
          onClick={() => {
            setSelectedValue(() => {
              resetPage(1);
              return 'all';
            });
          }}>
          Todos
        </LabelAll>
        <LabelPending
          status={value === 'pending'}
          onClick={() => {
            setSelectedValue(() => {
              resetPage(1);
              return 'pending';
            });
          }}>
          Pendentes
        </LabelPending>
        <LabelPaid
          status={value === 'approved'}
          onClick={() => {
            setSelectedValue(() => {
              resetPage(1);
              return 'approved';
            });
          }}>
          Aceitas
        </LabelPaid>
        <LabelNotPaid
          status={value === 'refused'}
          onClick={() => {
            setSelectedValue(() => {
              resetPage(1);
              return 'refused';
            });
          }}>
          Recusadas
        </LabelNotPaid>
      </BoxTags>
      <div className="borderedBottom" />
    </Container>
  );
}
