import styled from 'styled-components';

import colors from '../../../../UI/colors';
import fonts from '../../../../UI/fonts';

export const TableContainer = styled.div`
  padding: 0px 20px 0px 20px;
  /* background-color: #ccc; */

  .item__list {
    border-bottom: 2px solid #bfbfb2;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    @media (max-width: 587px) {
      margin-bottom: 20px;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .link__action {
    text-decoration: none;
    cursor: pointer;
    color: ${colors.green_secondary};
    font-family: ${fonts.latoBlack};
    font-size: 14px;
    margin-bottom: 0;
  }

  .link__action.empty {
    color: ${colors.gray_secondary};
    cursor: not-allowed;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleBoldView = styled.div`
  font-weight: bold;
  font-family: ${fonts.latoRegularBold};
`;

export const TitleBold = styled(TitleBoldView)`
  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 992px) {
    display: none;
  }
`;

export const P = styled.div`
  font-family: ${fonts.latoRegular};
`;

export const RowPhotoName = styled.span`
  padding-top: 10px;
  font-family: ${fonts.latoRegular};
`;

export const FirstPrice = styled.div`
  color: ${colors.green_secondary};
  font-weight: bold;
`;

export const RowClick = styled.div`
  display: flex;
  flex-direction: row;
  height: 15px;
  cursor: pointer;
  align-items: center;
  &:hover {
    color: ${colors.dark_light};
  }
`;

export const Results = styled.p`
  margin-left: 14px;
  font-size: 14px;
  font-family: ${fonts.latoRegular};
`;
