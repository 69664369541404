import React from 'react';
import { DivButton, ButtonBack, ButtonForward, DivButtonIntern, TextForward, TextBack } from './styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import colors from '../../../../../UI/colors';
// import { useDispatch } from 'react-redux';
// import addMsgAction from '../../../redux/action/action_add_user'


function NextPrev({ active, link, step, setStep }) {
    // const dispatch = useDispatch();


    function alterBool() {
        // dispatch(addMsgAction(false));
    }

    function returnStep(event) {
        event.preventDefault();
        window.location.pathname = link
    }

    return <DivButton >
        <DivButtonIntern >
            <ButtonBack type="button" onClick={(event) => { returnStep(event) }}>
                <ArrowBackIosIcon style={{ color: colors.green_secondary }} />
            </ButtonBack>
            <TextBack>
                Voltar
            </TextBack>
        </DivButtonIntern>
        <DivButtonIntern>
            <TextForward active={active}>
                Avançar
            </TextForward>
            <ButtonForward type="submit" disabled={!active} >
                <ArrowForwardIosIcon style={{ color: colors.white }} />
            </ButtonForward>
        </DivButtonIntern>
    </DivButton>;
}

export default NextPrev;