import {Divider, Grid} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';
import BoxProposal from '../../Proposal/BoxProposal';
import {ImageShowG, ImageShowM} from '../styles';

// import { Container } from './styles';

function SkeletonShow() {
  return (
    <>
      {' '}
      <div className={`visible`}>
        <Grid container>
          <Grid item xl={2} lg={1} md={1}></Grid>
          <Grid
            item
            xl={8}
            lg={10}
            md={10}
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
            }}>
            <Grid xs={6}>
              <Skeleton variant="rect" width="99.3%" height={324} />
            </Grid>
            <Grid xs={3}>
              <Grid xs={12}>
                <Skeleton variant="rect" width="99.4%" height={160} />
              </Grid>
              <Grid xs={12}>
                <Skeleton
                  variant="rect"
                  width="99.4%"
                  height={160}
                  style={{marginTop: '4px'}}
                />
                {/* <ImageShowM src={advertise.photos[2].image_url} /> */}
              </Grid>
            </Grid>
            <Grid style={{marginLeft: '2px'}} xs={3}>
              <Grid xs={12}>
                <Skeleton variant="rect" width="99.3%" height={160} />
              </Grid>
              <Grid xs={12}>
                <Skeleton
                  variant="rect"
                  width="99.4%"
                  height={160}
                  style={{marginTop: '4px'}}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={2} lg={1} md={1}></Grid>
        </Grid>
      </div>
      <div className={` mobile`}>
        <Grid
          container
          style={{
            marginBottom: 10,
            display: 'flex',
          }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <Slider {...settings}> */}

            <div className="photoSize">
              <Skeleton variant="rect" width="100%" height={179} />
            </div>

            {/* </Slider> */}
          </Grid>
        </Grid>
      </div>{' '}
      <Grid container className="centerItens">
        <Grid item xl={2} lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid
          container
          xl={8}
          lg={10}
          md={10}
          sm={10}
          xs={10}
          spacing="2"
          justify="center"
          style={{}}>
          <Grid
            style={{marginTop: '30px'}}
            item
            xl={8}
            lg={8}
            md={8}
            sm={12}
            xs={12}>
            {/* <TitleAd>
              <>
                <span>
                  {' '}
                  {advertise.type_property['name_' + langName]}{' '}
                  <I18n t="ViewAds.In" /> {advertise.address.neighborhood},{' '}
                  {advertise.address.city}, {advertise.address.state},{' '}
                  {advertise.address.country}
                </span>
              </>
            </TitleAd> */}
            <Skeleton variant="rect" width="100%" height={60} />
            <div className="mg-top-20">
              <Skeleton variant="rect" width="100%" height={275} />
            </div>
            <div className="mg-top-30" id="visiblePrice">
              {/* <Price>
                {' '}
                {prices !== 'Não Definido' ? (
                  prices[0]
                ) : (
                  <I18n t="ViewAds.Undefined" />
                )}{' '}
              </Price>{' '} */}
              <div className="rows  mg-top-10 mg-bottom-20">
                <span className="mg-right-20">
                  {/* <PriceAlternative>
                    {' '}
                    {prices !== 'Não Definido' ? (
                      prices[1]
                    ) : (
                      <I18n t="ViewAds.Undefined" />
                    )}{' '}
                  </PriceAlternative>{' '} */}
                </span>{' '}
                <span>
                  {/* <PriceAlternative>
                    {' '}
                    {prices !== 'Não Definido' ? (
                      prices[2]
                    ) : (
                      <I18n t="ViewAds.Undefined" />
                    )}{' '}
                  </PriceAlternative>{' '} */}
                </span>{' '}
              </div>{' '}
            </div>

            <div className="mg-top-30 mg-bottom-40">
              {' '}
              <Divider />
            </div>
            <Skeleton variant="rect" width="100%" height={275} />
            <div className="mg-top-30 mg-bottom-30">
              <Divider />
            </div>
            {/* {(advertise.internalProperty.length > 0 ||
              advertise.externalProperty.length > 0) && (
              <>
                <div className="mg-bottom-30">
                  {(advertise.internalProperty.length > 0 ||
                    advertise.externalProperty.length > 0) && (
                    <DetailsIntern langName={langName} advertise={advertise} />
                  )}
                </div>
                <div className="mg-top-30 mg-bottom-30">
                  <Divider />
                </div>
              </>
            )} */}
            {/* <CostAndRent advertise={advertise} /> */}
            <div className="mg-top-30">{/* <Divider /> */}</div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            {/* <BoxProposal
              langName={langName}
              advertise={advertise}
              prices={prices}
              id={id}
            /> */}
            <Skeleton
              variant="rect"
              width="100%"
              height={220}
              style={{marginTop: '30px'}}
            />
          </Grid>
          {/* <TitleAd>
            <>
              <span>
                {' '}
                {advertise.type_property['name_' + langName]}{' '}
                <I18n t="ViewAds.In" /> {advertise.address.city},{' '}
                {advertise.address.state}, {advertise.address.country}
              </span>
            </>
          </TitleAd> */}
        </Grid>
        <Grid item xl={2} lg={1} md={1} sm={1} xs={1}></Grid>
      </Grid>
      <Grid className="mg-top-40" container>
        <Grid item xl={2} lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid
          xl={8}
          lg={10}
          md={10}
          container
          style={{
            marginBottom: 10,
            display: 'flex',
          }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <Slider className="slickRel" {...settingsRel}>
              {advertise.photos.map((img, key) => {
                return (
                  <div key={key}>
                    <ImageRel src={img.image_url} />
                  </div>
                );
              })}
            </Slider> */}
          </Grid>
        </Grid>
        <Grid item xl={2} lg={1} md={1} sm={1} xs={1}></Grid>
      </Grid>
      ;
    </>
  );
}

export default SkeletonShow;
