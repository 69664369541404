import React, {useState} from 'react';
// import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Container, ButtonFilter} from './styles';
import {makeStyles} from '@material-ui/core/styles';

import colors from '../../../UI/colors';
import fonts from '../../../UI/fonts';
import I18n from '~/i18n/i18';

const useStyles = makeStyles({
  root: {
    marginTop: '65px',
  },
  item: {
    marginTop: '10px',

    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    marginTop: '10px',
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});
const ITEM_HEIGHT = 48;
const stylesItem = {
  fontFamily: fonts.latoRegular,
  fontSize: 16,
};

export default function MenuFilter(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  /* apaguei o choose, setChoose e substitui por props.state */

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClickChoose(item) {
    /* setChoose(item) */
    setAnchorEl(null);
    props.setState(item);
  }

  const fakeSelectRef = React.useRef(null);

  return (
    <Container ref={fakeSelectRef}>
      <ButtonFilter
        itens={props.itens}
        onClick={handleClick}
        hasChoose={props.state}
        open={Boolean(anchorEl)}>
        {props.state ? (
          <I18n t={`Users.filter.${props?.state?.toLowerCase()}`} />
        ) : (
          'Filtrar por...'
        )}{' '}
        <ExpandMore />
      </ButtonFilter>
      <Menu
        className={classes.root}
        style={{}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setOpenCollapse(!openCollapse);
        }}
        PaperProps={{
          style: {
            maxWidth: fakeSelectRef.current
              ? fakeSelectRef.current.clientWidth
              : '300px',
            minWidth: 200,
            width: '100%',
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}>
        {props?.itens?.map((item, key) => {
          return (
            <MenuItem
              key={key}
              className={classes.item}
              onClick={() => handleClickChoose(item)}
              style={stylesItem}>
              <I18n t={`Users.filter.${item.toLowerCase()}`} />
            </MenuItem>
          );
        })}
      </Menu>
    </Container>
  );
}
