import React from 'react';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import './styles.css';

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [
        {
          position: {
            lat: this.props.latitude,
            lng: this.props.longitude,
          },
        },
      ],
    };
    this.handleMapReady = this.handleMapReady.bind(this);
  }

  handleMapReady(mapProps, map) {
    map.setOptions({
      draggableCursor: 'default',
      draggingCursor: 'pointer',
    });
  }

  onMarkerDragEnd = (coord, index) => {
    const {latLng} = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log(lat);
    console.log(lng);
    this.props.setLatitude(lat);
    this.props.setLongitude(lng);
    this.setState((prevState) => {
      const markers = [...this.state.markers];
      markers[index] = {...markers[index], position: {lat, lng}};
      return {markers};
    });
  };

  render() {
    return (
      <div style={{marginTop: '20px'}}>
        <Map
          draggable={true}
          onClick={() => console.log('click')}
          google={this.props.google}
          className={'map'}
          zoom={17}
          initialCenter={{
            lat: this.props.latitude,
            lng: this.props.longitude,
          }}
          onReady={this.handleMapReady}>
          {this.state.markers.map((marker, index) => (
            <Marker
              onClick={() => console.log('oiii')}
              key={index}
              position={marker.position}
              draggable={true}
              onDragend={(t, map, coord) => this.onMarkerDragEnd(coord, index)}
              name={marker.name}
            />
          ))}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC0P7Nzde7ggyMDpBpSgzl9Oj-1UPfJ9qc',
  libraries: [],
})(MapContainer);
