import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import baseURL from '../../services/api';
import apiBackend from '../../services/apiaxios';
import {useDispatch} from 'react-redux';

import planList from '../../redux/action/action_plan_list';
import {ButtonMedium} from '../../UI/styles';

import Dash from '../../components/Dashboard';
import {Grid} from '@material-ui/core';
import TablePlans from './Table';
import ModalCreatePlan from '../../components/ModalCreatePlan';

import {desencrypta} from '../../utils';

import * as S from './styles';

import * as Sentry from '@sentry/react';

const permitted_roles = ['5'];

function Plans() {
  const userStore = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [hasModalPlanVisible, setHasModalPlanVisible] = useState(false);

  const toggleVisibilityPlanRegister = React.useCallback(() => {
    setHasModalPlanVisible(!hasModalPlanVisible);
  }, [hasModalPlanVisible]);

  async function listPlan() {
    dispatch(planList(''));

    try {
      const token = desencrypta(localStorage?.getItem('@User_informations'));
      const {data} = await apiBackend.get(`/v1/plan`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      /*       console.log(data?.plans); */
      dispatch(planList(data?.plans));
    } catch (err) {
      Sentry.captureException(err);
    } finally {
    }
  }

  useEffect(() => {
    listPlan();
  }, []);

  const isPermitted = () =>
    permitted_roles.includes(userStore?.role_id?.toString());

  return (
    <>
      <Dash listPlan={listPlan} button={'plan'} title={'Planos'}>
        <div>
          <S.Actions>
            {isPermitted() && (
              <ButtonMedium onClick={toggleVisibilityPlanRegister}>
                Criar novo plano
              </ButtonMedium>
            )}
          </S.Actions>
          <div style={{minHeight: '100vh', background: '#fff'}}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <Results>{allTotal ? allTotal : '0'} resultados encontrados</Results> */}
                <TablePlans listPlan={listPlan} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Dash>
      {isPermitted() && (
        <ModalCreatePlan
          listPlan={listPlan}
          open={hasModalPlanVisible}
          toggleVisibility={toggleVisibilityPlanRegister}
        />
      )}
    </>
  );
}

export default Plans;
