import styled from 'styled-components';
import colors from '../../../../../../UI/colors';

export const Envolved = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Lato';
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 416px;
    justify-content: space-between;
    @media (min-width: 500px) and (max-width: 2560px) {
        width: 500px;
        padding-top: 40px;
        
    }
    @media (min-width: 1500px) and (max-width: 2560px) {
        width: 500px;
        padding-top: 10%;
        
    }
    @media(max-width: 480px) {
        width: 375px;
        padding: 30px 20px;
    }
`

export const Card = styled.div`
   justify-content: center;
   flex-direction: column;
   align-items: center;
   align-content: center;
   display: flex;
   width: 100%;
   height: 250px;
   background: #FFFFFF;
   border: none;
   box-shadow: 0px 3px 6px #00000029;
   font-size: 24px;
   font-style: italic;
   text-align: center;
   font-weight: bold;
   padding: 20px;
   margin-bottom: 20px;
`

export const CardChoose = styled.div`
 
`;


export const ButtonLocalize = styled.button`
    background-color: ${colors.green_secondary};
    color: ${colors.white};
    box-shadow: 0px 3px 6px #00000033;
    width: 100%;
    margin-top: 30px;
    display: block !important;
    margin-bottom: 30px;
    &:focus{
        outline: 0;
    }
    &:active{
        background-color: ${colors.green_third};
        color: #E6FFEF;
        box-shadow: 0px 0px 3px #00000033;
    }
`;


export const TitleQuestion = styled.p`
    color: #1D1D1B;
    font-size: 18px;
    font-weight: bold;
`;

export const TitleOptional = styled.div`
    font-size: 18px;
    color: #1D1D1B;
    font-weight: bold;
    display:flex;
    margin-bottom: 10px;
`

export const SpanAdvertise = styled.span`
    color: ${colors.green_secondary};
`;

export const Warning = styled.p`
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 10.47px;
`;

export const DivNext = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   width: 100%;
`;


export const Label = styled.div`
  font-weight: bold;
  color:  ${props => props.classes === 'value' ? colors.green_secondary : '#808076'};
`

export const CRDiv = styled.div`
  display: flex;
  justify-content: space-between;
   @media (min-width: 481px) and (max-width: 700px) {
       flex-direction: column;
        
    }
    @media (min-width: 800px) and (max-width: 2560px) {
       flex-direction: row;
        
    }
    @media(max-width: 480px) {
         flex-direction: column;
    }
`