import styled from 'styled-components';

import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const ContainerClasses = styled.div``;

export const Container = styled.div`
  padding: 50px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
  }
  font-family: ${fonts.latoRegular};
`;

export const ButtonContainer = styled.div`
  button {
    margin: 0 auto;
    max-width: 320px;
    width: 100%;
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
  /*   outline: 1px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  margin-bottom: 30px;

  @media (max-width: 787px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
  }
`;

export const InternDiv = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  font-size: 32px;
  color: #1d1d1b;

  font-family: ${fonts.latoBlackItalic};
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;
  text-align: center;

  svg {
    position: absolute;
    top: 15px;
    right: 24px;
  }
`;

export const DivInputText = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const DivAlterImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonWhite = styled.button`
  background-color: #ffffff;
  border: none;
  height: 50px;
  width: 40%;
  margin-top: 45px;
  border-radius: 5px;
  color: ${colors.green_secondary};
  font-size: 20px;
  font-family: ${fonts.latoBold};
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;

  svg {
    width: 32px;
    height: 32px;
    padding-right: 10px;
  }

  &:focus {
    outline: 0;
  }
  box-shadow: 0px 3px 6px #00000033;
  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: #e6ffef 2px solid;
  }
`;

export const ButtonGreen = styled(ButtonWhite)`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  width: 100%;

  &:hover {
    background-color: ${colors.green_third};
  }
  &:disabled {
    background-color: ${colors.gray_secondary};
  }
`;

export const TextButton = styled.span`
  margin-left: 10px;
`;

export const ModalResponsive = styled.div`
  padding: 0px 30px 0px 0px;
  @media (min-width: 481px) and (max-width: 700px) {
    width: 100%;
  }
  @media (min-width: 800px) and (max-width: 2560px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Message = styled.div`
  width: 100%;
  font-size: 16px;
`;
export const DivDanger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const AdvertTitle = styled.div`
  margin-bottom: 20px;
  color: #a88721;
  font-weight: bold;
  font-size: 24px;
  font-family: 'Lato';
`;

export const DivConfirm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
`;

export const ButtonsGreen = styled.button`
  color: ${colors.white};
  background: ${colors.green_secondary};
  font-size: 18px;
  height: 40px;
  width: 100%;
  border: none;
  box-shadow: 0px 3px 6px #00000033;
  text-align: left;

  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: 1px solid #e6ffef;
  }
  &:focus {
    outline: 0;
  }
`;

export const DivNext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const DivMetrics = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DivInputMetrics = styled.div`
  width: 100%;
`;

export const DivSelectMetrics = styled.div`
  width: 20%;
  height: 71px;
  border: none;
  border-bottom: ${(props) =>
    props.value ? '2px solid ' + colors.green_secondary : '#808076 1px solid'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1px;
`;

export const DivText = styled.div`
  padding-top: 15px;
  text-align: center;
  color: #1d1d1b;
  font-size: 18px;
  font-family: 'Lato';
  font-weight: 500;
`;

export const TitlePage = styled.p`
  color: #1d1d1b;
  font-size: 32px;
  font-family: ${fonts.latoBlackItalic};
  margin-bottom: 30px;
  display: flex;
`;

export const Ball = styled.span`
  flex: none;
  width: 30px;
  height: 30px;
  background: ${colors.green_secondary};
  border: none;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: ${fonts.latoBlack};
  display: flex;
  text-align: left;
  color: ${colors.white};
  margin-left: 10px;
`;

export const BallWhite = styled(Ball)`
  background: ${colors.white};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  opacity: 0.9 !important;
`;

export const ButtonEdit = styled.button`
  padding: 17px 35px;

  @media only screen and (max-width: 768px) {
    padding: 17px 15px;
  }
  @media only screen and (min-width: 992px) {
    padding: 17px 35px;
  }

  border: none;
  width: 100%;
  height: 65px;
  background-color: ${(props) =>
    props.click ? colors.gray_primary : colors.white};
  border-bottom: 1px solid #f2f2ed !important;
  border-top: 1px solid #f2f2ed !important;

  padding-left: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => (props.click ? '#50B275' : '#1D1D1B')};
  opacity: ${(props) => (props.me ? '0.9' : '1')};
  font-size: 18px;
  font-family: ${(props) =>
    props.click ? fonts.latoBlack : fonts.latoRegularBold};

  div.rowsJ {
    span {
      margin-right: 10px;
    }
    p {
      text-align: left;
      margin-bottom: 0 !important;
    }
  }

  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }

  ${Ball} {
    background: ${(props) => !props.me && colors.gray_secondary};
  }

  ${BallWhite} {
    display: ${(props) => !props.me && 'none'};
    background-color: ${(props) => props.click && colors.green_secondary};
  }
`;
