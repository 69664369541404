import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.form`
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px #00000033;
  border-radius: 10px;
  margin-bottom: 20px;

  /*  > input {
    background: ${colors.white};
    border: none;
    color: ${colors.dark};

    width: 100%;
    height: 50px;
    border: 1px solid #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    outline: 0;
    font-size: 16px;
    font-family: ${fonts.latoRegular};

    ::placeholder {
      color: ${colors.dark};
      font-family: ${fonts.latoRegularItalic};
      font-style: italic;
    }
  } */
  > button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    border: 1px solid #ffffff;
    width: 100%;
    max-width: 60px;
    height: 50px;
    padding-right: 6px;

    > div {
      width: 100%;
      padding: 5px;
      border-radius: 5px;
      height: 80%;
      margin: auto;
      background: ${colors.green_secondary};
      display: grid;
      place-items: center;

      > img {
        width: 18px;
        height: 18px;
        user-select: none;
        pointer-events: none;
      }
    }
  }
`;

export const AutoSuggestContainer = styled.div`
  font-weight: bold;
  font-family: ${fonts.latoRegular}, sans-serif;
  width: 100%;

  & + * {
    box-sizing: border-box;
  }

  input {
    position: relative;
    width: 100%;
  }

  input {
    background: ${colors.white};
    border: none;
    color: ${colors.dark};
    opacity: 1;
    text-align: left;
    width: 100%;
    height: 50px;
    border: 1px solid #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 20px;
    outline: 0;
    font-size: 16px;
    font-family: ${fonts.latoRegular};

    ::placeholder {
      color: ${colors.dark};
      font-family: ${fonts.latoRegularItalic};
      font-style: italic;
    }
  }

  input {
    outline: none;
  }
`;
