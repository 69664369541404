import React from 'react';
import {Container, BoxTags, LabelPaid, LabelNotPaid, LabelAll} from './styles';

export default function Filters({value, setSelectedValue, resetPage}) {
  return (
    <Container>
      <div className="borderedTop" />
      <BoxTags>
        <LabelAll
          status={value === ''}
          onClick={() => {
            setSelectedValue(() => {
              resetPage(1);
              return '';
            });
          }}>
          Todos
        </LabelAll>
        <LabelPaid
          status={value === 'paid'}
          onClick={() => {
            setSelectedValue(() => {
              resetPage(1);
              return 'paid';
            });
          }}>
          Pago
        </LabelPaid>
        <LabelNotPaid
          status={value === 'no-paid'}
          onClick={() => {
            setSelectedValue(() => {
              resetPage(1);
              return 'no-paid';
            });
          }}>
          Não pago
        </LabelNotPaid>
      </BoxTags>
      <div className="borderedBottom" />
    </Container>
  );
}
