import React, {useState, useEffect} from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';

import styled, {css} from 'styled-components';

import setLocationWith from '../../i18n/utils/viewLang';
import fonts from '../../UI/fonts';
import colors from '../../UI/colors';
import {getRoute} from '../../utils';

const useStyles = makeStyles({
  root: {
    marginTop: '60px',
  },
  item: {
    width: '100%',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    width: '200px',
    marginTop: '10px',
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});

export function MenuChangeObject(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [choose, setChoose] = useState('');
  const [langName, setLangName] = useState('en');

  useEffect(() => {
    let suffix = getRoute();
    setLangName(suffix === '' ? 'en' : suffix);
  }, [window.location.href]);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  function handleClickChoose(item) {
    setAnchorEl(null);
    setChoose(setLocationWith(item));
    props.setValue(item);
  }

  function closed() {
    setAnchorEl(null);
    setOpenCollapse(!openCollapse);
  }

  useEffect(() => {
    props.value ? setChoose(props.value[`name`]) : setChoose('');
  }, [props.value, langName]);

  const fakeSelectRef = React.useRef(null);

  return (
    <Container ref={fakeSelectRef} color={choose}>
      <ButtonFilter
        disabled={props.disable}
        type="button"
        itens={props.itensBackend}
        color={choose}
        onClick={handleClick}>
        {choose ? choose : props.placeHolder}{' '}
        {anchorEl ? <ExpandLess /> : <ExpandMore />}
      </ButtonFilter>

      <Menu
        className={classes.root}
        style={{}}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          closed();
        }}
        PaperProps={{
          style: {
            maxWidth: fakeSelectRef.current
              ? fakeSelectRef.current.clientWidth
              : '300px',
            minWidth: 200,
            width: '100%',
            maxHeight: 48 * 4.5,
          },
        }}>
        {props.itensBackend.map((item, key) => (
          <MenuItem
            key={key}
            className={classes.item}
            onClick={() => handleClickChoose(item)}>
            {item[`name`]}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
}

export const ButtonFilter = styled.button`
  height: 50px;
  display: flex;
  width: 100%;
  background-color: ${colors.white};
  /* box-shadow: 0px 3px 6px #00000029; */
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  border-bottom: solid 1px #808076;
  color: ${colors.dark};
  padding: 0px 3px 0px 3px;
  font-size: 16px;
  font-family: ${(props) =>
    props.hasChoose ? fonts.latoRegular : fonts.latoRegularItalic};

  svg {
    transition: transform 0.2s linear;
    color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }

  justify-content: space-between;

  &:focus {
    outline: 0;
  }
`;

const Container = styled.div`
  min-width: 200px;
  margin-bottom: 20px;
  border-bottom: 1px solid #808076;
  ${({color}) =>
    color
      ? css`
          border-bottom: 3px solid ${colors.green_secondary};
        `
      : ''};
`;
