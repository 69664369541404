import {Dialog, Grid} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, {useEffect, useState} from 'react';
import Autocomplete from 'react-google-autocomplete';
import {toMoney} from 'vanilla-masker';
import apiBackend from '~/services/apiaxios';
import {geocodeSearch} from '~/services/geocode';
import SelectCustomPlan from '~/views/RealState/components/SelectCustomPlan';
import fonts from '../../UI/fonts';
import {desencrypta} from '../../utils';
import RadioButtons from '../../views/Annoucement/Filters/RadioButton';
import DatePickerCustom from '../DatePickerCustom';
import Dimension from '../dimension';
import InputTextRequired from './inputTextRequired';
import {DivInputMetrics, DivSelectMetrics, DivText} from '../ModalEdit/styles';
import InputPrice from '../ModalFilter/inputPrice';
import {
  ButtonGreen,
  InternDiv,
  ModalResponsive,
  TitleGrid,
  TitleModal,
  TitlePage,
} from './styles';
import './styles.css';

import {MenuChangeObject} from '../MenuChanceObjectRequired';

import * as Sentry from '@sentry/react';

export default function ModalFilterAnoucement({
  open,
  setOpen,
  dateStart,
  setDateStart,
  dateFinish,
  setDateFinish,
  callBackGetUsers,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  viewNorthLat,
  setViewNorthLat,
  viewNorthLng,
  setViewNorthLng,
  viewSouthLat,
  setViewSouthLat,
  viewSouthLng,
  setViewSouthLng,
  min_price,
  setMin_price,
  max_price,
  setMax_price,
  currency,
  setCurrency,
  area,
  setArea,
  areaTotalMin,
  setAreaTotalMin,
  areaTotalMax,
  setAreaTotalMax,
  areaLiveMin,
  setAreaLiveMin,
  areaLiveMax,
  setAreaLiveMax,
  selectedValues,
  setSelectedValues,
  reference,
  setReference,
  adsId,
  setAdsId,
  clientType,
  setClientType,
}) {
  const [classGoogle, setClassGoogle] = useState('');
  const [digit, setDigit] = useState('');
  const [planList, setPlanList] = useState([]);
  const [choose, setChoose] = useState('');

  const types = [
    {id: '1', name: 'Proprietário ou Corretor', type: 'owner'},
    {id: '2', name: 'Imobiliária', type: 'broker'},
  ];

  const handleClose = () => {
    setOpen(false);
  };

  function insert(place, number) {
    setChoose(place?.formatted_address);
    setDigit(place?.formatted_address);

    geocodeSearch({
      place_id: place?.place_id,
      setLatitude: setLatitude,
      setLongitude: setLongitude,
      setViewNorthLat: setViewNorthLat,
      setViewNorthLng: setViewNorthLng,
      setViewSouthLat: setViewSouthLat,
      setViewSouthLng: setViewSouthLng,
    });
  }

  function getPlansActive() {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get('v1/plan/plans_active?kind=seller', {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        console.log(response);
        setPlanList(response.data.plans);
      })
      .catch((e) => Sentry.captureException(e));
  }

  function mask(e, setValue) {
    var float = e.target.value;
    if (float === '0') {
      setValue('');
    } else if (currency === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '.',
          delimiter: ',',
        }),
      );
    } else if (currency === 'BRL' || currency === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: ',',
          delimiter: '.',
        }),
      );
    }
  }

  function maskMetric(e, setValue) {
    var float = e.target.value;
    if (float === '0') {
      setValue('');
    } else if (area === 'sqft') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '.',
          delimiter: ',',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: ',',
          delimiter: '.',
        }),
      );
    }
  }

  function maskChange(e, setValue) {
    const float = e?.toString().replace(/\D/g, '');
    /*    console.log(float, choose); */
    if (currency === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (currency === 'BRL' || currency === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function maskMetricChange(e, setValue) {
    const float = e?.toString().replace(/\D/g, '');
    /*     console.log(float, choose); */
    if (area === 'sqft') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '.',
          delimiter: ',',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: ',',
          delimiter: '.',
        }),
      );
    }
  }

  useEffect(() => {
    if (min_price) {
      maskChange(min_price, setMin_price);
    }

    if (max_price) {
      maskChange(max_price, setMax_price);
    }
  }, [currency]);

  useEffect(() => {
    if (areaLiveMin) {
      maskMetricChange(areaLiveMin, setAreaLiveMin);
    }
    if (areaLiveMax) {
      maskMetricChange(areaLiveMax, setAreaLiveMax);
    }

    if (areaTotalMin) {
      maskMetricChange(areaTotalMin, setAreaTotalMin);
    }

    if (areaTotalMax) {
      maskMetricChange(areaTotalMax, setAreaTotalMax);
    }
  }, [area]);

  useEffect(() => {
    getPlansActive();
  }, []);

  useEffect(() => {
    if (digit && digit.length > 0) {
      setClassGoogle('value');
    } else {
      setClassGoogle('normal');
    }
  }, [digit]);

  function handleBlur(value) {
    if (value !== choose) {
      setChoose('');
      setDigit('');
      setLatitude('');
      setLongitude('');
      setViewNorthLat('');
      setViewNorthLng('');
      setViewSouthLat('');
      setViewSouthLng('');
      setClassGoogle('normal');
    }
  }

  function renderGoogle() {
    return (
      <Autocomplete
        value={digit}
        id="input-auto"
        style={{
          marginBottom: '20px',
          fontFamily: !digit ? fonts.latoRegularItalic : fonts.latoRegular,
          fontSize: 16,
        }}
        onBlur={(event) => handleBlur(event.target.value)}
        onChange={(event) => {
          setDigit(event.target.value);
        }}
        onPlaceSelected={(place) => {
          console.log(place);
          insert(place, 1);
        }}
        placeholder={'Buscar por endereço'}
        types={['(regions)']}
        className={classGoogle}
        componentRestrictions={{country: ['br', 'pt', 'us']}}
      />
    );
  }

  return (
    <>
      <div>
        <Dialog
          maxWidth={'lg' || 'md' || 'sm' || 'xl' || 'xs'}
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <InternDiv>
            <TitleModal>
              <TitlePage>Filtrar Anúncios</TitlePage>{' '}
              <CloseIcon
                onClick={() => handleClose()}
                className={'iconClose'}
              />
            </TitleModal>
            <ModalResponsive>
              <div className="mg-bottom-40 ">
                <TitleGrid>Filtrar por</TitleGrid>
                <RadioButtons
                  selectedValue={selectedValues}
                  setSelectedValue={setSelectedValues}
                />
              </div>
              {selectedValues === '1' ? (
                <>
                  <div className="mg-bottom-40 ">
                    <TitleGrid>Filtrar por endereço</TitleGrid>
                    {renderGoogle()}
                  </div>
                  <TitleGrid>Filtrar por faixa de preço</TitleGrid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}>
                        <DivInputMetrics>
                          <InputPrice
                            inputMode="numeric"
                            mask={mask}
                            label="Valor mínimo"
                            placeHolder="Digite o valor"
                            value={min_price}
                            setValue={setMin_price}
                          />
                        </DivInputMetrics>
                        <DivSelectMetrics value={min_price}>
                          <DivText>
                            <Dimension
                              pdTop={17}
                              value={currency}
                              setValue={setCurrency}
                              itens={['BRL', 'USD', 'EUR']}
                            />
                          </DivText>
                        </DivSelectMetrics>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}>
                        <DivInputMetrics>
                          <InputPrice
                            mask={mask}
                            label="Valor maximo"
                            placeHolder="Digite o valor"
                            value={max_price}
                            setValue={setMax_price}
                          />
                        </DivInputMetrics>
                        <DivSelectMetrics value={max_price}>
                          <DivText>
                            <Dimension
                              value={currency}
                              setValue={setCurrency}
                              itens={['BRL', 'USD', 'EUR']}
                            />
                          </DivText>
                        </DivSelectMetrics>
                      </div>
                    </Grid>
                  </Grid>
                  <TitleGrid>Filtrar por Área Construida </TitleGrid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}>
                        <DivInputMetrics>
                          <InputPrice
                            inputMode="numeric"
                            mask={maskMetric}
                            label="Valor mínimo"
                            placeHolder="Digite o valor"
                            value={areaLiveMin}
                            setValue={setAreaLiveMin}
                          />
                        </DivInputMetrics>
                        <DivSelectMetrics value={areaLiveMin}>
                          <DivText>
                            <Dimension
                              pdTop={17}
                              value={area}
                              setValue={setArea}
                              itens={['m²', 'sqft']}
                            />
                          </DivText>
                        </DivSelectMetrics>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}>
                        <DivInputMetrics>
                          <InputPrice
                            mask={maskMetric}
                            label="Valor maximo"
                            placeHolder="Digite o valor"
                            value={areaLiveMax}
                            setValue={setAreaLiveMax}
                          />
                        </DivInputMetrics>
                        <DivSelectMetrics value={areaLiveMax}>
                          <DivText>
                            <Dimension
                              value={area}
                              setValue={setArea}
                              itens={['m²', 'sqft']}
                            />
                          </DivText>
                        </DivSelectMetrics>
                      </div>
                    </Grid>
                  </Grid>
                  <TitleGrid>Filtrar por Área Total </TitleGrid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}>
                        <DivInputMetrics>
                          <InputPrice
                            inputMode="numeric"
                            mask={maskMetric}
                            label="Valor mínimo"
                            placeHolder="Digite o valor"
                            value={areaTotalMin}
                            setValue={setAreaTotalMin}
                          />
                        </DivInputMetrics>
                        <DivSelectMetrics value={areaTotalMin}>
                          <DivText>
                            <Dimension
                              pdTop={17}
                              value={area}
                              setValue={setArea}
                              itens={['m²', 'sqft']}
                            />
                          </DivText>
                        </DivSelectMetrics>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}>
                        <DivInputMetrics>
                          <InputPrice
                            mask={maskMetric}
                            label="Valor maximo"
                            placeHolder="Digite o valor"
                            value={areaTotalMax}
                            setValue={setAreaTotalMax}
                          />
                        </DivInputMetrics>
                        <DivSelectMetrics value={areaTotalMax}>
                          <DivText>
                            <Dimension
                              value={area}
                              setValue={setArea}
                              itens={['m²', 'sqft']}
                            />
                          </DivText>
                        </DivSelectMetrics>
                      </div>
                    </Grid>
                  </Grid>
                  <TitleGrid rid>Filtrar por data</TitleGrid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <DatePickerCustom
                        currentValue={dateStart}
                        handleDateChange={setDateStart}
                        placeholder="Data inicial..."
                      />

                      {/* <InputText label="Tipo do imóvel" placeHolder="Digite o nome do usuário" /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <DatePickerCustom
                        currentValue={dateFinish}
                        handleDateChange={setDateFinish}
                        placeholder="Data final..."
                      />
                    </Grid>
                  </Grid>
                </>
              ) : selectedValues === '2' ? (
                <div className="mg-bottom-40 ">
                  <TitleGrid>Filtrar por Referência</TitleGrid>
                  <InputTextRequired
                    value={reference}
                    setValue={setReference}
                    placeHolder="Digite o código do imóvel"
                  />
                </div>
              ) : selectedValues === '3' ? (
                <div className="mg-bottom-40 ">
                  <TitleGrid>Filtrar por ID</TitleGrid>
                  <InputTextRequired
                    value={adsId}
                    setValue={setAdsId}
                    placeHolder="Digite o id do imóvel"
                  />
                </div>
              ) : (
                <div className="mg-bottom-40 ">
                  <TitleGrid>Filtrar por tipo de usuário</TitleGrid>
                  <MenuChangeObject
                    placeHolder="Selecione um tipo"
                    itensBackend={types}
                    value={clientType}
                    setValue={setClientType}
                  />
                </div>
              )}

              <ButtonGreen
                disabled={
                  (digit && !latitude) ||
                  (digit && !longitude) ||
                  (digit && !viewNorthLat) ||
                  (digit && !viewNorthLng) ||
                  (digit && !viewSouthLat) ||
                  (digit && !viewSouthLng)
                }
                onClick={(event) => {
                  handleClose();
                  callBackGetUsers();
                }}>
                Filtrar
              </ButtonGreen>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
