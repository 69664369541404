import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import colors from '~/UI/colors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: colors.green_secondary,
    marginTop: '10px',
    marginBottom: '10px',
  },
  colorPrimary: {
    background: '#F2F2ED',
  },
  barColorPrimary: {
    backgroundColor: colors.green_secondary,
  },
});

export default function LinearDeterminate({valueP}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary,
        }}
        variant="determinate"
        value={valueP}
      />
    </div>
  );
}
