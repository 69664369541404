import styled from 'styled-components';
import colors from '../../../../../../UI/colors';

export const Container = styled.div`
   width: 400px;
   height: 640px;
  
   @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: 'Lato';
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 20px 30px 20px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  padding: 20px;
  font-size: 24px;
  color: #1D1D1B;
  font-style: italic;
  font-weight: bold;
  font-family: 'Lato';
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`


export const DivInputText = styled.div`
 width: 100%;
 margin-bottom: 20px;
`

export const DivAlterImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`





export const ButtonWhite = styled.button`
  background-color: #ffffff;
  border: none;
  height: 50px;
  width: 40%;
  margin-top: 45px;
  border-radius: 5px;
  color: ${colors.green_secondary};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:focus{
      outline: 0;
  }
  box-shadow: 0px 3px 6px #00000033;
  &:hover{
    background-color: #F2F2ED;
  }
   &:active{
    background-color: ${colors.green_third};
    color:#E6FFEF;
    border: #E6FFEF 2px solid;
  }
  
`

export const ButtonGreen = styled(ButtonWhite)` 
  background-color: ${colors.green_secondary} ;
  color: ${colors.white};
  &:hover{
     background-color: ${colors.green_third};
  }
`

export const TextButton = styled.span`
  margin-left: 10px;
`

export const ImagePreview = styled.img`
    max-width:100%;
    max-height:400px;
    width: auto;
    height: auto;
`

export const DivPreview = styled.div`
  display: flex;
  justify-content: center
`