import React from 'react';
// import { Container } from './styles';
import ReactLoading from 'react-loading';
import {Container} from './styles';

function MyAdsLoading() {
  return (
    <Container>
      <div className="divintern">
        <ReactLoading type="spin" color="#50b275" width="30px" height="30px" />
      </div>
    </Container>
  );
}

export default MyAdsLoading;
