import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import {dates} from './utils';
import * as S from './styles';

const Accordion = withStyles({
  root: {
    borderTop: '1px solid #d9d9d4',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    cursor: 'pointer',
    borderBottom: '1px solid #d9d9d4',
    backgroundColor: '#fff',
    marginBottom: -1,
    minHeight: 56,
    '&:hover': {
      backgroundColor: '#f2f2ed',
    },
    '&$expanded': {
      minHeight: 56,
      /*       backgroundColor: '#f2f2ed', */
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function AccordionCustom({item, expanded, handleChange}) {
  return (
    <Accordion
      square
      expanded={expanded === item?.id}
      onChange={handleChange(item?.id)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid container spacing={2} className="item__list">
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <S.Recipient expanded={expanded === item?.id}>
              <KeyboardArrowDownRoundedIcon />
              <S.TagItem>
                {!!item?.remittee ? item?.remittee : 'Todos'}
              </S.TagItem>
              <S.P>{!!item?.status ? item?.status : 'Todos os usuários'}</S.P>
            </S.Recipient>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <S.P>{item?.title_pt}</S.P>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <S.P style={{textAlign: 'right'}}>{dates(item?.created_at)}</S.P>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} className="item__list">
          {!!item?.localization && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <S.P>
                Notificação por Localidade
                <S.ContentNotifyTags>
                  <S.TagItem>{item?.localization}</S.TagItem>
                </S.ContentNotifyTags>
              </S.P>
            </Grid>
          )}
          {!!item?.plan_name && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <S.P>
                Enviar para usuários do plano
                <S.ContentNotifyTags>
                  <S.TagItem>{item?.plan_name}</S.TagItem>
                </S.ContentNotifyTags>
              </S.P>
            </Grid>
          )}
          {/* PT */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <S.MessageBox
              hasBorderTop={!!item?.localization || !!item?.plan_name}>
              <S.P>
                <S.TextBox upper mb={10}>
                  {item?.title_pt}
                </S.TextBox>
                <S.TextBox>{item?.message_pt}</S.TextBox>
              </S.P>
            </S.MessageBox>
          </Grid>
          {/* EN */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <S.MessageBox hasBorderTop>
              <S.P>
                <S.TextBox upper mb={10}>
                  {item?.title_en}
                </S.TextBox>
                <S.TextBox>{item?.message_en}</S.TextBox>
              </S.P>
            </S.MessageBox>
          </Grid>
          {/* ES */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <S.MessageBox hasBorderTop>
              <S.P>
                <S.TextBox upper mb={10}>
                  {item?.title_es}
                </S.TextBox>
                <S.TextBox>{item?.message_es}</S.TextBox>
              </S.P>
            </S.MessageBox>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
