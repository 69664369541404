/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';

import {useDispatch} from 'react-redux';
import ReactLoading from 'react-loading';
import {useParams, useHistory} from 'react-router-dom';
import crypto from 'crypto-js';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import DashBoard from '~/components/MenuPassword';
import apiBackend from '~/services/apiaxios';
import colors from '~/UI/colors';

import {transformAvatarURL, encrypta /* saveUserInfo */} from '~/utils';
import {ButtonSecondary} from '../ForgotPassword/styles';
import setUserAvatar from '~/redux/action/action_user_avatar';
import setRoleUser from '~/redux/action/action_user_role';

import {fireAction} from '~/utils/swal';
import * as S from './styles';

import * as Sentry from '@sentry/react';

function PasswordRecovery() {
  /* -------------------------- */
  const dispatch = useDispatch();
  const history = useHistory();
  const {token} = useParams();

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* New Password */
  const handleChangeNewPassword = (value) => {
    setNewPassword(value);

    setErrors((errors) => {
      return {
        ...errors,
        newPassword: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
        confirmPassword:
          confirmPassword.trim() && value.trim() !== confirmPassword.trim()
            ? 'As senhas não coincidem'
            : !confirmPassword.trim() && touched.confirmPassword
            ? 'Campo não preenchido'
            : '',
      };
    });
  };

  const handleBlurNewPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        newPassword: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        newPassword: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
        confirmPassword:
          confirmPassword.trim() && value.trim() !== confirmPassword.trim()
            ? 'As senhas não coincidem'
            : !confirmPassword.trim() && touched.confirmPassword
            ? 'Campo não preenchido'
            : '',
      };
    });
  };

  /* Confirm Password */
  const handleChangeConfirmPassword = (value) => {
    setConfirmPassword(value);

    setErrors((errors) => {
      return {
        ...errors,
        confirmPassword: !value.trim()
          ? 'Campo não preenchido'
          : value.trim() !== newPassword.trim()
          ? 'As senhas não coincidem'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
        newPassword:
          value.trim() &&
          newPassword.trim() &&
          value.trim() !== newPassword.trim()
            ? 'As senhas não coincidem'
            : !newPassword.trim() && touched.newPassword
            ? 'Campo não preenchido'
            : '',
      };
    });
  };

  const handleBlurConfirmPassword = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        confirmPassword: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        confirmPassword: !value.trim()
          ? 'Campo não preenchido'
          : value.trim() !== newPassword.trim()
          ? 'As senhas não coincidem'
          : value.trim().length < 8
          ? 'Mínimo de 8 caracteres'
          : '',
        newPassword:
          value.trim() &&
          newPassword.trim() &&
          value.trim() !== newPassword.trim()
            ? 'As senhas não coincidem'
            : !newPassword.trim() && touched.newPassword
            ? 'Campo não preenchido'
            : '',
      };
    });
  };

  const [newPasswordVisible, setNewPasswordVisible] = useState(true);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(true);

  const newPasswordRef = React.useRef(null);
  const confirmPasswordRef = React.useRef(null);

  function handleIsEye(e, value, setValue, ref) {
    e.preventDefault();

    let pass = ref.current;
    pass.type === 'password' ? (pass.type = 'text') : (pass.type = 'password');
    setValue(!value);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      /* --------------------------------------- */
      /* ---------- New password set ----------- */
      /* --------------------------------------- */
      const key = process.env.REACT_APP_KEY_GENERATE_HASH;
      let hash_password = crypto.AES.encrypt(
        newPassword.trim(),
        key,
      ).toString();

      const userPass = {
        password: hash_password,
      };

      const {data} = await apiBackend.put(
        `/v1/login/recovery/${token}`,
        userPass,
      );
      console.log(data);

      const user = data?.user;
      const authToken = encrypta(data?.token?.token);

      localStorage.setItem('id', user.id);
      localStorage.setItem('name', user.name);
      localStorage.setItem('@User_informations', authToken);

      const userAvatar = transformAvatarURL(user?.avatar?.avatar_url);
      if (userAvatar) {
        dispatch(setUserAvatar(userAvatar));
      }

      const role = user?.role_id;
      dispatch(setRoleUser(role));

      switch (role?.toString()) {
        case '5':
          return history.replace('/dashboard');
        case '4':
          return history.replace('/dashboard');
        case '3':
          return history.replace('/announcement');
        case '2':
          return history.replace('/real-state');
        default:
          return history.replace('/profile');
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 422) {
        fireAction(
          'Não foi possível completar sua solicitação',
          'O token de recuperação usado expirou. Solicite um novo link de recuperação de senha.',
          'error',
        );

        history.replace('/forgot-password');
      } else {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante sua requisição. Por favor, tente novamente mais tarde.',
          'error',
        );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DashBoard />
      <S.Container>
        <S.DivTitle>
          <S.PageTitle>Criar nova senha</S.PageTitle>
          <em>
            Preencha as informações abaixo para criar sua nova senha de acesso.
          </em>
        </S.DivTitle>
        {/*  */}
        <S.InternDiv>
          <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <div>
              <S.DivInputText>
                <S.Rotule error={errors.newPassword} valid={newPassword}>
                  Nova senha *
                </S.Rotule>
                <div className="passEyes">
                  <a
                    href="#"
                    onClick={(e) =>
                      handleIsEye(
                        e,
                        newPasswordVisible,
                        setNewPasswordVisible,
                        newPasswordRef,
                      )
                    }>
                    {!newPasswordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </a>
                  <S.InputText
                    id="oldPass"
                    type="password"
                    ref={newPasswordRef}
                    value={newPassword}
                    error={errors.newPassword}
                    touched={touched.newPassword}
                    onChange={(event) =>
                      handleChangeNewPassword(event.target.value)
                    }
                    onBlur={(event) =>
                      handleBlurNewPassword(event.target.value)
                    }
                    placeholder="Informe a nova senha..."
                  />
                </div>

                {errors.newPassword && (
                  <S.DivError>
                    <S.ErrorMessage>{errors.newPassword}</S.ErrorMessage>
                  </S.DivError>
                )}
              </S.DivInputText>

              <S.DivInputText className="divMargin">
                <S.Rotule
                  error={errors.confirmPassword}
                  valid={confirmPassword}>
                  Confirme a senha *
                </S.Rotule>
                <div className="passEyes">
                  <a
                    href="#"
                    onClick={(e) =>
                      handleIsEye(
                        e,
                        confirmPasswordVisible,
                        setConfirmPasswordVisible,
                        confirmPasswordRef,
                      )
                    }>
                    {!confirmPasswordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </a>
                  <S.InputText
                    id="oldPass"
                    type="password"
                    ref={confirmPasswordRef}
                    value={confirmPassword}
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                    onChange={(event) =>
                      handleChangeConfirmPassword(event.target.value)
                    }
                    onBlur={(event) =>
                      handleBlurConfirmPassword(event.target.value)
                    }
                    placeholder="Confirme a nova senha..."
                  />
                </div>

                {errors.confirmPassword && (
                  <S.DivError>
                    <S.ErrorMessage>{errors.confirmPassword}</S.ErrorMessage>
                  </S.DivError>
                )}
              </S.DivInputText>
            </div>

            <ButtonSecondary
              disabled={
                !newPassword.trim() ||
                newPassword.trim().length < 8 ||
                !confirmPassword.trim() ||
                confirmPassword.trim().length < 8 ||
                newPassword.trim() !== confirmPassword.trim() ||
                loading
              }
              type="submit"
              style={{
                marginTop: '30px',
                marginBottom: '30px',
                maxWidth: '300px',
                width: '100%',
              }}>
              {loading ? (
                <ReactLoading
                  type="spin"
                  color={colors.green_secondary}
                  width="30px"
                  height="30px"
                />
              ) : (
                'Salvar'
              )}
            </ButtonSecondary>
          </form>
        </S.InternDiv>
      </S.Container>
    </>
  );
}

export default PasswordRecovery;
