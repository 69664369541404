import styled from 'styled-components';
import fonts from '~/UI/fonts';
import colors from '~/UI/colors';

export const Container = styled.div`
  width: 100%;
  > div.borderedTop {
    width: 100%;
    border-top: 1px solid #d9d9d4;
  }

  > div.borderedBottom {
    width: 100%;
    border-bottom: 1px solid #d9d9d4;
  }
`;

export const BoxTags = styled.div`
  padding: 20px 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.div`
  width: max-content;
  /* max-width: 100px; */
  cursor: pointer;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 20px 5px 0;
  text-align: center;
  font-family: ${fonts.latoBold};
  flex: none;
`;

export const LabelAll = styled(Label)`
  border: 1px solid ${colors.green_secondary};
  color: ${(props) => (props.status ? colors.white : colors.green_secondary)};
  background-color: ${(props) =>
    props.status ? colors.green_secondary : 'transparent'};
  transition: 0.2s all ease;

  &:hover {
    background-color: ${colors.green_secondary};
    color: ${colors.white};
  }
`;

export const LabelPaid = styled(Label)`
  border: 1px solid ${colors.green_limon};
  color: ${(props) => (props.status ? colors.white : colors.green_limon)};
  background-color: ${(props) =>
    props.status ? colors.green_limon : 'transparent'};
  transition: 0.2s all ease;

  &:hover {
    background-color: ${colors.green_limon};
    color: ${colors.white};
  }
`;

export const LabelNotPaid = styled(Label)`
  border: 1px solid ${colors.danger};
  color: ${(props) => (props.status ? colors.white : colors.danger)};
  background-color: ${(props) =>
    props.status ? colors.danger : 'transparent'};
  transition: 0.2s all ease;

  &:hover {
    background-color: ${colors.danger};
    color: ${colors.white};
  }
`;
