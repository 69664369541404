import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
} from './styles';
import I18n from '../../../../../i18n/i18';
import {ButtonGreenGo} from '../BoxProposal/styles';
import {getRoute} from '../../utils';

export default function ModalAttProposal({open, setOpen}) {
  const handleClose = () => {
    setOpen(false);
  };

  function goAdvertise() {
    if (getRoute()) window.location.href = `/${getRoute()}/advertise`;
    else window.location.href = `/advertise`;
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span />
            <CloseIcon onClick={() => handleClose()} className="iconClose" />
          </TitleModal>
          <InternDiv>
            <ModalResponsive>
              <DivDanger>
                <FontAwesomeIcon
                  size="2x"
                  color="#A88721"
                  icon={faExclamationCircle}
                />
                <AdvertTitle>
                  <I18n t="ModalDelete.warning" />
                </AdvertTitle>
                <Message>
                  <I18n t="ModalDelete.SendProposalText" />
                </Message>
                <DivConfirm>
                  <ButtonGreenGo onClick={() => goAdvertise()}>
                    <I18n t="ModalDelete.NewAds" />
                  </ButtonGreenGo>
                </DivConfirm>
              </DivDanger>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
