import React from 'react';
import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export default function InputText(props) {
  function change(event) {
    if (props.mask) {
      props.mask(event, props.setValue);
    } else {
      props.setValue(event.target.value);
    }
  }

  return (
    <div>
      <Label color={props.value}>{props.label} </Label>
      <Input
        disabled={props.disable}
        minLength={props.min ? props.min : null}
        maxLength={props.max ? props.max : null}
        color={props.value}
        value={props.value}
        noBorder={props.noBorder}
        onChange={(event) => change(event)}
        itens={props.itens}
        placeholder={props.placeHolder}
      />
    </div>
  );
}

const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.noBorder
      ? 'none'
      : props.value
      ? `2px solid ${colors.green_secondary}!important`
      : '1px solid #808076!important'};
  color: ${(props) => (props.value ? '#1D1D1B' : '#808076')};
  width: ${(props) => (props.width ? '30%' : '100%')};
  font-family: ${fonts.latoRegular};
  font-size: 16px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus {
    outline: 0;
  }
  &:disabled {
  }
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;
