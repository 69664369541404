import styled, {keyframes} from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const ActionsContainer = styled.div`
  margin-top: 5px;

  @media (max-width: 571px) {
    button {
      width: 100%;
    }
  }
`;

export const InputField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ContainerPagination = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  display: grid;
  place-items: center;
`;

export const DivName = styled.div`
  color: ${colors.dark};
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ModalContainer = styled.div``;

export const PInfo = styled.p`
  font-size: 14px;
`;

export const Eyes = styled.a`
  position: relative !important;
  right: 6%;
  top: 0px;
  z-index: 1;
  cursor: pointer;
`;

export const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 793px;
`;

export const PName = styled.p`
  font-size: 16px;
  color: #1d1d1b;
  text-align: center;
  font-weight: bold;
  font-family: ${fonts.latoRegularBold};
`;

export const PType = styled.p`
  font-size: 16px;
  color: #1d1d1b;
  font-family: ${fonts.latoRegular};
`;

export const DivCards = styled.div`
  margin-top: 20px;
`;

export const TextCards = styled.div`
  margin-left: 20px;
  color: #1d1d1b;
  font-size: 16px;
  font-weight: bold;
`;

export const DivInfo = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const Intern = styled.div`
  width: 420px;
  margin-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const DivImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SpanText = styled.span`
  margin-top: 10.01px;
  color: ${colors.green_secondary};
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
`;

export const PEdit = styled.p`
  margin-left: 10px;
  font-family: ${fonts.latoRegular};
`;

export const SpanCard = styled.span`
  margin-left: 6px;
  font-family: ${fonts.latoRegular};
`;

export const InternCard = styled.div`
  margin-left: 20px;
  padding-top: 17px;
  color: #50b275;
  font-weight: 14px;
  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  cursor: pointer;
  width: 100%;
  padding-bottom: 5px;
  padding-right: 5px;
  min-height: 70px;
  margin-top: 20px;
  background: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  &:hover {
    background-color: ${colors.gray_four};
    color: ${colors.green_secondary};

    ${InternCard} {
      color: ${colors.green_secondary};
    }
  }

  &:active {
    background-color: #2e6643;
    color: ${colors.white};

    ${InternCard} {
      color: ${colors.white};
    }
  }
`;
export const ImgUser = styled.img`
  /* width: 100px; */
  /* height: 100px; */
  border-radius: 100%;
`;

export const InfosIntern = styled.div`
  margin-left: 20px;
  font-family: ${fonts.latoRegular};
`;

export const TitleModal = styled.span`
  padding: 40px 20px 0px 40px;
  width: 100%;
  font-size: 18px;
  letter-spacing: 1.8px;
  color: ${colors.green_secondary};
  position: relative;

  font-family: ${fonts.latoBlackItalic};
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;

  display: flex;
  justify-content: space-between;

  .iconClose {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  @media (max-width: 400px) {
    margin-bottom: 0px;
    padding: 50px 20px 0px;

    > span {
      display: block;
      margin: 0 auto;
    }
  }
`;
export const Container = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: red;
  overflow: scroll;
  margin-top: 30px;
  padding: 20px 20px 30px 20px;
  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    padding: 20px 20px 30px 20px;
  }
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 20px 30px 20px;

  @media (max-width: 768px) {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Rotule = styled.p`
  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: ${fonts.latoRegularBold};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

/* ------------------------- */
/* ------------------------- */
/* ------------------------- */
/* ------------------------- */
/* ------------------------- */
/* ------------------------- */
/* ------------------------- */
/* ------------------------- */
export const DivInputText = styled.div`
  width: 100%;

  svg {
    color: ${colors.dark_light};
  }
`;

export const InputText = styled.input`
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  color: #1d1d1b;
  border: none;

  margin: 0px !important;
  font-family: ${fonts.latoRegular};

  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};

  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};

    font-size: 16px;
  }
`;

export const DivError = styled.div`
  width: 100%;
  font-family: ${fonts.latoRegular};
`;

export const ErrorMessage = styled.span`
  margin-top: 9.25px;

  color: ${colors.danger_dark};
  font-family: ${fonts.latoRegular};
  font-weight: 500;
  font-size: 14px;
`;

export const Mandatory = styled.p`
  margin-top: 6px;
  padding: 0;
  font-size: 12px;
  color: ${colors.danger};
`;

export const ButtonSave = styled.button`
  width: 100%;
  height: 50px;
  color: ${colors.white};
  font-size: 18px;
  background-color: ${colors.green_secondary};
  border: none;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0000004d;
  font-weight: bold;
  margin-top: 30px;
  font-family: ${fonts.latoBlack};

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
  &:hover:not(:disabled) {
    background: #2e6643;
  }
`;

export const ButtonCopyWhite = styled(ButtonSave)`
  width: 100%;
  height: 50px;
  color: ${colors.green_secondary};
  font-size: 18px;
  background-color: ${colors.white};
  border: none;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0000004d;
  margin-top: 30px;

  &:disabled {
    background-color: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    background: #f2f2ed;
  }

  &:active {
    color: #e6ffef;
    background-color: ${colors.green_third};
  }
`;

export const ClearFiltersButton = styled.button`
  font-size: 14px;
  font-family: ${fonts.latoBlackOriginal};
  padding: 5px 10px;
  border: 1px solid ${colors.gray_primary}!important;
  border-radius: 5px;
  color: ${colors.gray_third};
  background: none;
  margin-bottom: 20px;

  transition: all 0.2s ease;
  &:hover {
    background: ${colors.gray_primary};
  }

  &:active {
    background: ${colors.green_secondary};
    color: ${colors.white};

    &:hover {
      background: ${colors.green_secondary};
      color: ${colors.white};
    }
  }
`;

export const ReportContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const Amount = styled.p`
  margin-left: 14px;
  font-size: 14px;
  font-family: ${fonts.latoRegularBold};
  color: ${colors.dark};
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  margin-bottom: 0;

  > strong {
    font-size: 18px;
    font-family: ${fonts.latoBlack};
    color: ${(props) =>
      props.hasPositive ? colors.green_secondary : colors.danger};
  }
`;

export const Results = styled.p`
  margin-left: 14px;
  font-size: 14px;
  font-family: ${fonts.latoRegular};
`;

const letDown = keyframes`
  0%, 40%{
    transform: translateY(0px);
  }
  20%{
    transform: translateY(3px);
  }
  30%{
    transform: translateY(-3px);
  }
 
`;

export const ReportAction = styled.div`
  flex: none;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.green_secondary};
  background-color: ${colors.white};
  border: 1px solid ${colors.green_secondary}!important;
  border-radius: 50%;
  box-shadow: 0px 1px 3px #00000033;
  font-size: 14px;
  outline: 0;
  transition: all 0.2s ease;
  overflow: hidden;
`;

export const ButtonReport = styled.button`
  flex: none;
  display: flex;
  align-items: center;
  font-family: ${fonts.latoBlack};
  background: none;
  transition: all 0.2s ease;
  color: ${colors.green_secondary};

  &:disabled {
    > div {
      border: 1px solid ${colors.gray_secondary}!important;
      background: ${colors.white};
      box-shadow: none;
      color: ${colors.gray_secondary};
    }
    color: ${colors.gray_secondary};
    cursor: not-allowed;
  }

  &:hover:not(:disabled),
  &:hover:not(:active) {
    > div {
      > svg {
        animation: ${letDown} 1.4s infinite linear;
      }
    }
  }
`;
