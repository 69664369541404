import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import I18n from '~/i18n/i18';
import colors from '~/UI/colors';
import {ButtonBack, DivButton, DivButtonIntern, TextBack} from './styles';

function NextPreview({active, link, step, setStep, handleSubmit, mt, flag}) {
  const dispatch = useDispatch();

  function alterBool() {
    // dispatch(addMsgAction(false));
  }

  function returnStep(event) {
    event.preventDefault();
    alterBool();
    console.log(flag);
    if (flag) {
      window.history.back();
    } else {
      if (step == '5') {
        setStep((step - 2).toString());
      }
      if (step == '7') {
        setStep((step - 2).toString());
      } else {
        setStep((step - 1).toString());
      }
    }
  }

  return (
    <DivButton mt={mt}>
      <DivButtonIntern>
        <ButtonBack
          type="button"
          onClick={(event) => {
            returnStep(event);
          }}>
          {/* <ArrowBackIosIcon style={{color: colors.green_secondary}} /> */}
          <FontAwesomeIcon icon={faChevronLeft} />
        </ButtonBack>
        <TextBack>
          <I18n t="NextPrevComponent.back" />
        </TextBack>
      </DivButtonIntern>
      <DivButtonIntern>
        <ButtonFinish
          disabled={!active}
          type="button"
          onClick={(event) => handleSubmit(event)}>
          <I18n t="FinishAnnouncement.buttonEdit" />
        </ButtonFinish>
      </DivButtonIntern>
    </DivButton>
  );
}

export const ButtonFinish = styled.button`
  min-width: 160px;

  max-width: 180px;
  width: 100%;
  height: 50px;
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  &:disabled {
    cursor: auto;
    background-color: #d9d9ce;
    color: #ffffff;
  }
  &:hover {
    background: ${(props) =>
      props.disabled ? '#d9d9ce' : '#2e6643 0% 0% no-repeat padding-box'};

    border: ${(props) => (props.disabled ? 'none' : '1px solid #2e6643')};
  }
  &:active {
    background: ${(props) =>
      props.disabled ? '#d9d9ce' : '#2e6643 0% 0% no-repeat padding-box'};
    border: 2px solid ${colors.green_primary};
    color: ${colors.green_primary};
  }
  &:focus {
    outline: 0;
  }
`;

export default NextPreview;
