import styled from 'styled-components';
import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const Container = styled.div`
  width: 100%;
  position: relative;
  font-family: ${fonts.latoRegular};
`;

export const ContainerPagination = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  display: grid;
  place-items: center;
`;

export const LoadingIndicator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    font-family: ${fonts.latoItalic};
    font-size: 14px;
    color: ${colors.dark};
    margin-bottom: 0px;
  }
`;

export const InternDiv = styled.div`
  width: 100%;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  font-size: 24px;
  color: #1d1d1b;
  font-style: italic;
  font-weight: bold;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  text-align: center;
  position: relative;

  svg {
    position: absolute;
    right: 0;
  }
`;

export const TitlePage = styled.p`
  color: #1d1d1b;
  font-size: 24px;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  text-align: left;
  margin-bottom: 0;
  line-height: 29px;
  padding-right: 30px;
`;

export const Hero = styled.div`
  display: flex;
  align-items: center;

  > p {
    font-size: 14px;
    font-family: ${fonts.latoBold};
    color: ${colors.dark};
    margin-bottom: 0;
  }

  > span {
    margin-right: 15px;
    padding: 5px 10px;
    border: 1px solid ${colors.green_secondary};
    border-radius: 5px;
    width: max-content;
    font-size: 12px;
    font-family: ${fonts.latoBold};
    flex: none;
  }
`;

export const AdsList = styled.div`
  padding: 0px 0 10px;
`;

export const SafeAreaCard = styled.div`
  padding: 0px 30px;
  border-top: 1px solid #d9d9ce;

  &:last-child {
    border-bottom: 1px solid #d9d9ce;
  }

  @media (max-width: 768px) {
    padding: 0px 20px;
  }
`;

export const SafeAreaInfo = styled(SafeAreaCard)`
  border-top: none;
  border-bottom: none;

  &:last-child {
    border-bottom: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    font-family: ${fonts.latoRegularBoldItalic};
    font-size: 14px;
    text-align: center;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  color: ${colors.dark};
  font-family: ${fonts.latoBold};
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;

  > strong {
    font-family: ${fonts.latoBold};
    font-size: 14px;
    display: block;
    color: ${colors.dark};
    margin-bottom: 10px;
  }

  > address {
    color: ${colors.dark};
    line-height: 18px;
    font-size: 14px;
    font-family: ${fonts.latoRegular};
    display: block;
    margin-bottom: 10px;
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    > div.prices {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      span {
        font-size: 12px;
        font-family: ${fonts.latoRegular};
        padding-right: 10px;
        line-height: 21px;
      }
      span.principal {
        font-size: 14px;
        line-height: 34px;
        font-family: ${fonts.latoBlack};
        color: ${colors.green_secondary};
      }
    }

    > div.link {
      > a {
        text-decoration: none;
        color: ${colors.green_secondary};
        font-size: 14px;
        font-family: ${fonts.latoBlack};
        display: flex;
        align-items: center;
        width: max-content;
        transition: all 0.2s ease;

        &:hover:not(:active) {
          background: ${colors.green_secondary};
          color: ${colors.white};
        }
        &:active {
          background: ${colors.green_third};
          color: ${colors.white};
        }
      }

      > div {
        text-decoration: none;
        color: ${colors.dark};
        font-size: 14px;
        font-family: ${fonts.latoBlack};
        display: flex;
        align-items: center;
        width: max-content;
        transition: all 0.2s ease;
      }
    }
  }
`;
