import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {isAuthenticated} from '../services/auth';

export default function PublicRoute({...props}) {
  const userStore = useSelector((state) => state.user);

  /* This route redirect auto if user is auth */
  /* Example: user logged access sign-in page, this auto redirect to /dashboard */
  const redirectURL =
    isAuthenticated() && userStore?.role_id ? '/profile' : '/';

  if (isAuthenticated() && userStore?.role_id) {
    return <Redirect to={{pathname: redirectURL}} />;
  }
  return <Route {...props} />;
}
