import React from 'react';
import TableClients from './Table';

// import { Container } from './styles';

function Published({users, getAllUsers}) {
  return (
    <>
      <TableClients getAllLeads={getAllUsers} information={users} />
    </>
  );
}

export default Published;
