import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RadioSlideWithout from '../../Annoucement/View/Edit/Components/radioButtonWithout';
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import colors from '../../../UI/colors';

import ModalActivePlan from '../../../components/ModalActivePlan';

import ModalDesativePlan from '../../../components/ModalDesativePlan';
import ModalDeletePlan from '../../../components/ModalDeletePlan';
import ModalPublishPlan from '../../../components/ModalPublishPlan';
import ModalUnpublishPlan from '../../../components/ModalUnpublishPlan';
import ModalEditPlan from '../../../components/ModalEditPlan';
import {toMoney} from 'vanilla-masker';
import {Card, Content, Actions, HeaderTitle} from './styles';

const permitted_roles = ['5'];

function CardPlans({item, listPlan}) {
  const userStore = useSelector((state) => state.user);
  const [actived, setActived] = useState(item?.active || false);
  const [published, setPublished] = useState(item?.public || false);
  const [openActive, setOpenActive] = useState(false);
  const [openDesative, setOpenDesative] = useState(false);
  /*  const [openShare, setOpenShare] = useState(false); */

  /* --- PUBLISH --- */
  const [openPublish, setOpenPublish] = useState(false);
  const [openUnpublish, setOpenUnpublish] = useState(false);

  /* --- UPDATE --- */
  const [openEdit, setOpenEdit] = useState(false);
  const toggleVisibilityEdit = React.useCallback(() => setOpenEdit(!openEdit), [
    openEdit,
  ]);

  /* --- DELETE --- */
  const [openDelete, setOpenDelete] = useState(false);

  function mask(e) {
    var float = e;
    return toMoney(parseFloat(float).toFixed(2), {
      precision: 2,
      separator: '.',
      delimiter: ',',
    });
  }

  function dateTransform(info) {
    let date = new Date(info);
    return (
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
      '/' +
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    );
  }

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*  useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]);
 */
  return (
    <>
      <Card actived={actived}>
        <Content className="rowBetween">
          <HeaderTitle>
            <span className="titlePlan  mg-right-20">{item?.name}</span>
            <span className="createdAtDate">
              criado em {dateTransform(item?.created_at)}
            </span>
          </HeaderTitle>

          <Actions actived={actived}>
            <div className="__item">
              <span className="titleBlack mg-right-20">
                {actived ? 'Ativado' : 'Desativado'}
              </span>
              <RadioSlideWithout
                permitted={isPermitted()}
                openDesative={openDesative}
                setOpenDesative={isPermitted() ? setOpenDesative : () => {}}
                openActive={openActive}
                setOpenActive={setOpenActive}
                state={actived}
                setState={setActived}
              />
            </div>
            <div className="__item">
              <span className="titleBlack mg-right-20">
                {published ? 'Publicado' : 'Não listado'}
              </span>
              <RadioSlideWithout
                permitted={isPermitted()}
                openDesative={openUnpublish}
                setOpenDesative={isPermitted() ? setOpenUnpublish : () => {}}
                openActive={openPublish}
                setOpenActive={setOpenPublish}
                state={published}
                setState={setPublished}
              />
            </div>
            {/*  <span
              onClick={() => {
                actived === false && setOpenShare(true);
              }}
              className="share">
              Compartilhar link{' '}
              <FontAwesomeIcon className="iconsArrow" icon={faAngleRight} />
            </span> */}
            {isPermitted() && (
              <div className="__actions">
                <FontAwesomeIcon
                  onClick={() => setOpenEdit(true)}
                  className="iconsActions"
                  color={colors.green_secondary}
                  icon={faEdit}
                />

                <FontAwesomeIcon
                  onClick={() => setOpenDelete(true)}
                  className="iconsActions mg-left-50"
                  color={colors.green_secondary}
                  icon={faTrashAlt}
                />
              </div>
            )}
          </Actions>
        </Content>

        <Content mt={20}>
          <div className="description">
            {item?.ads_quantity > 0 ? (
              <span className="textTwo">
                <span className="greenNoBorder">{item?.ads_quantity}</span>{' '}
                {item?.ads_quantity === 1 ? 'Imóvel' : 'Imóveis'}
              </span>
            ) : (
              <span className="textTwo">
                <span className="greenNoBorder">Imóveis</span> ilimitados
              </span>
            )}

            {/* ------------------- */}

            <span className="textTwo">
              <span className="greenNoBorder">
                {Number(item?.price) > 0 ? mask(item?.price) : 'Gratuito'}
              </span>{' '}
              <span style={{textTransform: 'uppercase'}}>{item?.currency}</span>{' '}
              /{' '}
              {item?.periodicity_count !== null
                ? `${item?.periodicity_count} ${item?.periodicity}`
                : `Período ilimitado`}
            </span>

            {/* ------------------- */}
            {item?.free_days >= 1 ? (
              <span className="textTwo">
                <span className="greenNoBorder">{item?.free_days}</span>{' '}
                {item?.free_days === 1 ? 'Dia gratuito' : 'Dias gratuitos'}{' '}
              </span>
            ) : null}

            {/* ------------------- */}
            <span className="textTwo">
              <span className="greenNoBorder">Tipo</span>{' '}
              {item?.kind === 'common' ? 'Comum' : 'Imobiliária'}{' '}
            </span>

            {/* <span className="textTwo">
              <span className="greenNoBorder">Criado em</span>{' '}
              {dateTransform(item?.created_at)}
            </span> */}
          </div>
        </Content>
      </Card>

      {/* --- ACTIVE AND DESACTIVE --- */}
      {isPermitted() && (
        <>
          <ModalActivePlan
            id={item.id}
            actived={actived}
            setActived={setActived}
            open={openActive}
            setOpen={setOpenActive}
          />
          <ModalDesativePlan
            id={item.id}
            actived={actived}
            setActived={setActived}
            setPublished={setPublished}
            open={openDesative}
            setOpen={setOpenDesative}
          />

          {/* --- PUBLISH AND UNPUBLISH --- */}
          <ModalPublishPlan
            id={item.id}
            open={openPublish}
            setOpen={setOpenPublish}
            setPublished={setPublished}
            setActived={setActived}
          />
          <ModalUnpublishPlan
            id={item.id}
            open={openUnpublish}
            setOpen={setOpenUnpublish}
            setPublished={setPublished}
          />

          {/* --- DELETE --- */}
          <ModalDeletePlan
            listPlan={listPlan}
            id={item.id}
            open={openDelete}
            setOpen={setOpenDelete}
          />
          {/* --- EDIT --- */}
          <ModalEditPlan
            id={item.id}
            listPlan={listPlan}
            item={item}
            open={openEdit}
            setOpen={toggleVisibilityEdit}
          />
        </>
      )}

      {/*    <ModalShareLink item={item} open={openShare} setOpen={setOpenShare} /> */}
    </>
  );
}

export default CardPlans;
