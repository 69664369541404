/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ReactLoading from 'react-loading';
import colors from '~/UI/colors';
import Loader from './loader';
import {formatPrice} from '~/utils/priceFormat';
import {desencrypta} from '~/utils';
import apiBackend from '~/services/apiaxios';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import {ButtonMedium} from '~/UI/styles';
import TableNotifications from './Table';
import Dash from '~/components/Dashboard';
import Pagination from '~/components/_PaginationTab';
import DatePickerCustom from '~/components/DatePickerCustom';

import * as Sentry from '@sentry/react';

import {ActionsContainer, ContainerPagination} from './styles';
import {
  ClearFiltersButton,
  ReportContainer,
  Amount,
  Results,
  ReportAction,
  ButtonReport,
} from './styles';

const permitted_roles = ['5'];
export default function Notifications({display}) {
  const userStore = useSelector((state) => state.user);
  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*   useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  /* ------------------------------------------ */
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [query, setQuery] = useState(new URLSearchParams());

  /* --- PAGINATION TABLE --- */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  const handleChangePage = (value) => {
    setPage(value);
  };
  /* --------------------------------------------------- */
  const getNotifications = React.useCallback(async () => {
    try {
      setLoading(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/notifications?paginate=true&page=${page}&limit=5${
          query.toString() !== '' ? `&${query.toString()}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      /*   console.log(data); */

      setNotifications(data?.notification?.data);
      setLastPage(data?.notification?.meta?.last_page);
      setTotalResults(data?.notification?.meta?.total);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [page, query]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  function handleFilter() {
    var query = new URLSearchParams();

    if (dateStart?._isValid) {
      let initial =
        dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
      query.append('initial', initial);
    }

    if (dateEnd?._isValid) {
      let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';
      query.append('end', end);
    }

    if (dateStart?._isValid && dateEnd?._isValid) {
      if (dateStart?._d > dateEnd?._d) {
        let initial =
          dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
        let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';

        query.set('initial', end);
        query.set('end', initial);
      }
    }

    setQuery(() => {
      setPage(1);
      return query;
    });
  }

  function handleClearFilter() {
    setDateStart(null);
    setDateEnd(null);

    setQuery(() => {
      setPage(1);
      return new URLSearchParams();
    });
  }

  useEffect(() => {
    if (dateStart?._isValid) {
      handleFilter();
    }
  }, [dateStart]);

  useEffect(() => {
    if (dateEnd?._isValid) {
      handleFilter();
    }
  }, [dateEnd]);

  function handleSendNotification() {
    history.push(`/notifications/send`);
  }

  return (
    <Dash button={'user'} title={'Notificações'}>
      <div style={{display: display}}>
        <ActionsContainer>
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: '30px',
              marginTop: '30px',
              maxWidth: '1366px',
              width: '100%',
              marginLeft: '0',
            }}>
            {isPermitted() && (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <ButtonMedium
                  style={{
                    height: 'auto',
                    minHeight: '50px',
                    padding: '5px 10px',
                    boxSizing: 'border-box',
                  }}
                  onClick={handleSendNotification}>
                  Enviar nova Notificação
                </ButtonMedium>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <DatePickerCustom
                currentValue={dateStart}
                handleDateChange={setDateStart}
                placeholder="Data inicial..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <DatePickerCustom
                currentValue={dateEnd}
                handleDateChange={setDateEnd}
                placeholder="Data final..."
              />
            </Grid>
          </Grid>
        </ActionsContainer>

        {query.toString() && (
          <ClearFiltersButton onClick={handleClearFilter}>
            Limpar filtros
          </ClearFiltersButton>
        )}

        {totalResults > 0 && (
          <Results>{totalResults} resultados encontrados</Results>
        )}

        {loading ? (
          <Loader />
        ) : notifications?.length > 0 ? (
          <>
            <TableNotifications notifications={notifications} />

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ContainerPagination>
                {lastPage > 1 && (
                  <Pagination
                    count={lastPage}
                    page={page}
                    handleChange={(event, value) => handleChangePage(value)}
                  />
                )}
              </ContainerPagination>
            </Grid>
          </>
        ) : (
          <Results>Nenhum resultado encontrado...</Results>
        )}
      </div>
    </Dash>
  );
}
