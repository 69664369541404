import {Dialog} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import CheckSucess from '../../assets/check-circle.svg';
import I18n from '../../i18n/i18';
import {
  AdvertTitle,
  DivDanger,
  InternDiv,
  ModalResponsive,
  TitleModal,
} from './styles';
import './styles.css';

export default function ModalDeleteSucess({func, open, setOpen}) {
  const handleClose = () => {
    func();
    setOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span />
            <CloseIcon onClick={() => handleClose()} className="iconClose" />
          </TitleModal>
          <InternDiv>
            <ModalResponsive>
              <DivDanger>
                <img src={CheckSucess} width="48" height="48" />
                <AdvertTitle>
                  {' '}
                  <I18n t="ModalSucess.editSucess" />
                </AdvertTitle>
              </DivDanger>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
