export const handleChangePhoneMask = (value) => {
  const string = value.replace(/[^A-Z0-9]+/gi, '');

  if (string.length <= 8) {
    return '9999-9999';
  } else if (string.length === 9) {
    return '99999-9999';
  } else if (string.length === 10) {
    return '(99) 9999-9999';
  } else if (string.length > 10) {
    return '(99) 99999-9999';
  }
};

export const handleChangeCpfMask = (value) => {
  return '999.999.999-99';
};

export const handleChangeCepMask = (value) => {
  return '99999-9999';
};
