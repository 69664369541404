import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {toMoney} from 'vanilla-masker';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~/utils';
import ModalEditSucess from '../../../../../../components/ModalEditSucess';
import Dimension from '../../Components/dimension';
import InputText from '../../Components/inputText';
import MandatoryField from '../../Components/mandatoryField';
import NextPreview from '../../Components/nextPreview';
import SelectMenuI18n from '../../Components/selectMenuI18n';
import {TitlePage} from '../../Photo/styles';
import {SaveAnnoucement} from '../../Step/styles';

import * as Sentry from '@sentry/react';

import {
  DivInputMetrics,
  DivMetrics,
  DivNext,
  DivSelectMetrics,
  DivText,
} from './styles';

/* import addMsgAction from '~/redux/action/action_add_user'; */

const itensDimension = ['BRL', 'USD', 'EUR'];
const itensRentEN = ['Rented', 'For rent', 'Daily rate', 'Not rented'];

function Rent({
  id,
  step,
  setStep,
  country,
  choseMetric,
  setChoseMetric,
  rental,
  setRental,
  rental_price,
  setRental_price,
}) {
  const [active, setActive] = useState(true);
  const view = useSelector((state) => state.step.data);
  const [openEditSucess, setOpenEditSucess] = useState(false);
  const [valueRentView, setValueRentView] = useState(true);
  const itensRent = itensRentEN;
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/advertise`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  useEffect(() => {
    if (rental === 'Not rented' || rental === '') {
      setRental_price('');
      setValueRentView(false);
    } else {
      setValueRentView(true);
    }
  }, [rental]);

  async function updatePropertyThree() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .put(
        `/v1/property/${id}/edit`,
        {
          rental,
          rental_price: convert(rental_price),
          rental_price_currency: choseMetric,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        // dispatch(addMsgAction(true));
        setOpenEditSucess(true);
        //  dispatch(addMsgAction(true));
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  /* TODO troquei a máscara de cima por a de baixo */
  function mask(e, setValue) {
    const float = e.target.value.replace(/\D/g, '');
    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || country === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function maskChange(e, setValue) {
    const float = e;
    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  useEffect(() => {
    if (rental_price) {
      maskChange(rental_price, setRental_price);
    }
  }, [choseMetric]);

  function transformArea(value) {
    const data = value;
    if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      const p1 = data.replace('.', '');
      /* TODO p2 estava sendo usado no transform parseFloat */
      /* const p2 = p1.replace(',', '.'); */
      const transform = parseFloat(p1);
      return transform;
    }
    const p1 = data.replace(',', '');
    const transform = parseFloat(p1);
    return transform;
  }

  function convert(value) {
    if (value) {
      const data = transformArea(value);
      return data;
    }
    return '';
  }

  function handleProx(event) {
    event.preventDefault();
    updatePropertyThree();
  }

  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="Rent.title" />
          </TitlePage>

          <MandatoryField />

          <SelectMenuI18n
            i18n="ItensRent"
            placeHolder={<I18n t="Rent.placeHolderRent" />}
            label={<I18n t="Rent.labelRent" />}
            itens={itensRent}
            value={rental}
            setValue={setRental}
          />

          {valueRentView ? (
            <DivMetrics value={rental_price}>
              <DivInputMetrics>
                <InputText
                  noBorder
                  mask={mask}
                  placeHolder={I18n.getTranslation(
                    location,
                    'Rent.placeHolderValueRent',
                  )}
                  label={<I18n t="Rent.labelValueRent" />}
                  value={rental_price}
                  setValue={setRental_price}
                />
              </DivInputMetrics>
              <DivSelectMetrics noBorder value={rental_price}>
                <DivText>
                  <Dimension
                    value={choseMetric}
                    setValue={setChoseMetric}
                    itens={itensDimension}
                  />
                </DivText>
              </DivSelectMetrics>
            </DivMetrics>
          ) : null}
        </div>

        <DivNext>
          <NextPreview
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>
      <ModalEditSucess
        func={() => {
          window.history.back();
        }}
        open={openEditSucess}
        setOpen={setOpenEditSucess}
      />
    </>
  );
}

export default Rent;
