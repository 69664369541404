import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
/* import ReactLoading from 'react-loading';
import colors from '~/UI/colors'; */
// import { ModalResponsive, Star } from '../../Components/styles';

import {toNumber} from 'vanilla-masker';
import {ModalResponsive} from '../ModalDelete/styles';
import {ButtonSave} from '../../views/User/styles';
import {ButtonAdd} from '../../UI/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus} from '@fortawesome/free-solid-svg-icons';
import InputNumberCount from './inputNumberCount';
import SelectCustom from './SelectCustom';
import AddressInput from './AddressInput';
import DatePickerCustom from '~/components/DatePickerCustom';

import {makeStyles} from '@material-ui/core';

import {InternDiv, TitleModal, ButtonRelative, Field} from './styles';

import * as C from '~/views/User/Clients/Edit/ModalDataPersonal/styles';

import * as validate from './validations';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '440px!important',
    width: 'calc(100% - 20px)!important',
    marginTop: '30px!important',
    marginBottom: '10px!important',
    '@media(max-width: 840px)': {
      width: 'calc(100% - 30px)!important',
      maxWidth: '420px!important',
    },
  },
}));

const statusArray = [
  {
    id: 1,
    name: 'Todos',
    query: 0,
  },
  {
    id: 2,
    name: 'Não anunciantes',
    query: 1,
  },
  {
    id: 3,
    name: 'Anunciantes',
    query: 2,
  },
  {
    id: 4,
    name: 'Inativos',
    query: 3,
  },
];

export default function ModalUserFilters({
  open,
  toggleVisibility,
  handleFilter,
  status,
  setStatus,
  adsQtd,
  setAdsQtd,
  address,
  setAddress,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  viewLat,
  setViewLat,
  viewLong,
  setViewLong,
  /* TODO novo  */
  viewLat2,
  setViewLat2,
  viewLong2,
  setViewLong2,
  /*  */
  addressChoose,
  setAddressChoose,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
}) {
  const classes = useStyles();

  const handleClose = () => {
    toggleVisibility();
  };

  function addNumber(value, setValue) {
    if (value === '') {
      setValue(1);
    } else {
      setValue(parseInt(value) + 1);
    }
  }

  function removeNumber(value, setValue) {
    if (value === '') {
      return;
    }
    if (value <= 0) {
      setValue('');
    } else {
      setValue(value - 1);
    }
  }

  function maskNumber(e, setValue) {
    var float = e.target.value?.replace(/\D+/g, '');
    setValue(toNumber(float));
  }

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleChange = (value, setValue, name, validation) => {
    setValue(value);

    setErrors((errors) => {
      return {
        ...errors,
        [name]: validation(value),
      };
    });
  };

  const handleBlur = (value, setValue, name, validation) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        [name]: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        [name]: validation(value),
      };
    });
  };

  const handleSearch = () => {
    handleFilter();
    handleClose();
  };

  return (
    <>
      <div>
        <Dialog
          fullWidth
          maxWidth={'lg'}
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          classes={{paper: classes.paper}}>
          <TitleModal>
            <span>Filtros avançados </span>
            <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
          </TitleModal>
          <ModalResponsive>
            <InternDiv>
              <Field>
                <SelectCustom
                  label="Status"
                  placeholder="Filtrar por status"
                  value={status}
                  items={statusArray}
                  setValue={setStatus}
                />
              </Field>
              <Field>
                <AddressInput
                  optional
                  label="Endereço"
                  placeholder="Filtrar por endereço..."
                  value={address}
                  setValue={setAddress}
                  choose={addressChoose}
                  setChoose={setAddressChoose}
                  setLat={setLatitude}
                  setLong={setLongitude}
                  setViewLat={setViewLat}
                  setViewLong={setViewLong}
                  setViewLat2={setViewLat2}
                  setViewLong2={setViewLong2}
                  errors={errors.address}
                  touched={touched.address}
                />
              </Field>

              <Field mb="20">
                <InputNumberCount
                  label="Anúncios"
                  placeholder="Filtrar por quantidade..."
                  strongLabel="Anúncios"
                  isRequired={false}
                  value={adsQtd}
                  handleChange={(e) =>
                    handleChange(
                      e.target.value,
                      () => maskNumber(e, setAdsQtd),
                      'adsQtd',
                      validate.adsQtd,
                    )
                  }
                  handleBlur={(e) =>
                    handleBlur(
                      e.target.value,
                      () => maskNumber(e, setAdsQtd),
                      'adsQtd',
                      validate.adsQtd,
                    )
                  }
                />
                <ButtonRelative>
                  <ButtonAdd
                    onClick={() => addNumber(adsQtd, setAdsQtd)}
                    className="mg-right-43">
                    <FontAwesomeIcon icon={faPlus} />
                  </ButtonAdd>
                  <ButtonAdd onClick={() => removeNumber(adsQtd, setAdsQtd)}>
                    <FontAwesomeIcon icon={faMinus} />
                  </ButtonAdd>
                </ButtonRelative>
                {errors.adsQtd && (
                  <C.DivError>
                    <C.ErrorMessage>{errors.adsQtd}</C.ErrorMessage>
                  </C.DivError>
                )}
              </Field>

              <Field bordered>
                <C.Rotule
                  error={dateStart && !dateStart?._isValid}
                  valid={dateStart && dateStart?._isValid}>
                  Data Inicial
                </C.Rotule>
                <DatePickerCustom
                  currentValue={dateStart}
                  handleDateChange={setDateStart}
                  placeholder="Data inicial..."
                />
              </Field>
              <Field mb="20">
                <C.Rotule
                  error={dateEnd && !dateEnd?._isValid}
                  valid={dateEnd && dateEnd?._isValid}>
                  Data Final
                </C.Rotule>
                <DatePickerCustom
                  currentValue={dateEnd}
                  handleDateChange={setDateEnd}
                  placeholder="Data final..."
                />
              </Field>

              {/* ------------------------ */}
              <ButtonSave
                disabled={
                  (address?.trim() && !Object.keys(addressChoose).length) ||
                  (address?.trim() && !latitude) ||
                  (address?.trim() && !longitude) ||
                  (address?.trim() && !viewLat) ||
                  (address?.trim() && !viewLong) ||
                  (address?.trim() && !viewLat2) ||
                  (address?.trim() && !viewLong2)
                }
                onClick={handleSearch}
                className={'btnDataAddress btnload'}>
                Aplicar filtros
              </ButtonSave>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
    </>
  );
}
