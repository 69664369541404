import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {DivNext} from './styles';
import {TitlePage} from '../../Photo/styles';
import NextPrev from '../../Components/nextPrev';
import MandatoryField from '../../Components/mandatoryField';
import {desencrypta, getRoute} from '~/utils';
import baseURL from '~/services/api';
import {SaveAnnoucement} from '../../Step/styles';
import RenderSwitch from './RenderSwitch';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import * as Sentry from '@sentry/react';

/* import addMsgAction from '~/redux/action/action_add_user'; */

function PropertyTwo({
  id,
  typeGarage,
  setTypeGarage,
  dormitories,
  setDormitories,
  suites,
  setSuites,
  bathrooms,
  setBathrooms,
  washrooms,
  setWashrooms,
  paving,
  setPaving,
  vacancies,
  setVacancies,
  step,
  setStep,
  type,
  typeEn,
}) {
  const [active, setActive] = useState(true);
  const view = useSelector((state) => state.step.data);
  const dispatch = useDispatch();
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/login`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  async function updatePropertyOne() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .put(
        `/v1/property/${id}/edit`,
        {
          stories: paving,
          bedrooms: dormitories,
          suites,
          full_bathrooms: bathrooms,
          half_bathrooms: washrooms,
          garage_type: typeGarage,
          parking_spaces: vacancies,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        // dispatch(addMsgAction(true));
        console.log(response);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  function handleProx(event) {
    updatePropertyOne();
    event.preventDefault();
    let value = Number.parseInt(step);
    value = (value + 1).toString();
    console.log();
    setStep(value);
  }

  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="StylesDefiniedTwo.title" />
          </TitlePage>
          <MandatoryField />
          <RenderSwitch
            location={location}
            typeGarage={typeGarage}
            setTypeGarage={setTypeGarage}
            dormitories={dormitories}
            setDormitories={setDormitories}
            suites={suites}
            setSuites={setSuites}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            washrooms={washrooms}
            setWashrooms={setWashrooms}
            paving={paving}
            setPaving={setPaving}
            vacancies={vacancies}
            setVacancies={setVacancies}
            type={type['name_en']}
            active={active}
            setActive={setActive}
          />
        </div>

        <DivNext>
          <NextPrev
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>
    </>
  );
}

export default PropertyTwo;
