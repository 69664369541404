import React, {useEffect} from 'react';
import ReactLoading from 'react-loading';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';

import InputText from '../inputText';
import baseURL from '../../services/api';
import {desencrypta} from '../../utils';
import {useState} from 'react';
import Dimension from '../dimension';
import {toMoney} from 'vanilla-masker';
import colors from '~/UI/colors';

import * as Sentry from '@sentry/react';

import {
  Container,
  InternDiv,
  TitleModal,
  ModalResponsive,
  DivInputMetrics,
  TitlePage,
  DivMetrics,
  DivText,
  DivSelectMetrics,
  ButtonGreen,
} from './styles';

import {makeStyles} from '@material-ui/core';
import apiBackend from '~/services/apiaxios';
import {fireActionMethods} from '~/utils/swalMethods';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '420px!important',
    width: '100%!important',
    marginLeft: '0!important',
    marginRight: '0!important',
  },
}));

const itensDimension = ['BRL', 'USD', 'EUR'];

export default function ModalPrice({
  info,
  id,
  priceInfo,
  currency,
  open,
  setOpen,
  getAllProperties,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(parseInt(priceInfo));
  const [choose, setChoose] = useState(currency ?? 'USD');

  const handleClose = () => {
    setOpen(false);
  };

  async function alterPrice(event) {
    event.preventDefault();
    setLoading(true);

    let token = desencrypta(localStorage.getItem('@User_informations'));
    try {
      const {data} = await apiBackend.put(
        `/v1/property/${id}/edit`,
        {
          price: convert(price),
          price_currency: choose,
        },
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );
      getAllProperties();
      handleClose();
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
    }
  }

  /*  useEffect(() => {
    if (price) maskInit(priceInfo.toString(), setPrice);
  }, [choose]); */

  function maskChange(e, setValue) {
    const float = e?.toString().replace(/\D/g, '');
    /*    console.log(float, choose); */
    if (choose === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choose === 'BRL' || choose === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  useEffect(() => {
    if (price) {
      maskChange(price, setPrice);
    }
  }, [choose]);

  function mask(e, setValue) {
    const float = e.target.value;
    if (choose === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choose === 'BRL' || choose === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function transformArea(value) {
    const data = value;
    console.log(value);

    if (choose === 'BRL' || choose === 'EUR') {
      const p1 = data.split('.').join('');
      /*  const p2 = p1.replace(',', '.'); */
      const transform = parseFloat(p1);
      return transform.toFixed(2);
    }
    const p1 = data.replace(/,/g, '');
    const transform = parseFloat(p1);
    return transform.toFixed(2);
  }

  function convert(value) {
    const data = transformArea(value);
    return data;
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          classes={{paper: classes.paper}}>
          <Container>
            <InternDiv>
              <ModalResponsive>
                <TitleModal>
                  <TitlePage>Edite o valor do imóvel</TitlePage>{' '}
                  <CloseIcon
                    onClick={() => handleClose()}
                    className={'iconClose'}
                  />
                </TitleModal>
                <DivMetrics className="with-border" value={price}>
                  <DivInputMetrics>
                    <InputText
                      inputMode="numeric"
                      style={{border: 'none!important'}}
                      mask={mask}
                      placeHolder={'Altere o valor do imóvel'}
                      label="Valor do imóvel"
                      value={price}
                      setValue={setPrice}
                    />
                  </DivInputMetrics>
                  <DivSelectMetrics /* value={price} */>
                    <DivText>
                      <Dimension
                        pdTop={10}
                        value={choose}
                        setValue={setChoose}
                        itens={itensDimension}
                      />
                    </DivText>
                  </DivSelectMetrics>
                </DivMetrics>
              </ModalResponsive>
              <ButtonGreen
                disabled={loading}
                onClick={(event) => alterPrice(event)}>
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color={colors.green_secondary}
                    width="30px"
                    height="30px"
                  />
                ) : (
                  'SALVAR'
                )}
              </ButtonGreen>
            </InternDiv>
          </Container>
        </Dialog>
      </div>
    </>
  );
}
