import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {toMoney} from 'vanilla-masker';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~/utils';
import ModalEditSucess from '../../../../../components/ModalEditSucess';
import Dimension from '../Components/dimension';
import InputTextRequireSelect from '../Components/InputRequireSelect';
import InputText from '../Components/inputText';
import MandatoryField from '../Components/mandatoryField';
import NextPreview from '../Components/nextPreview';
import {MessageInputs} from '../DesiredProfile/ModalDelete/styles';
import {TitlePage} from '../Photo/styles';
import {SaveAnnoucement} from '../Step/styles';
import {viewMainFee} from '../Verifications';
import {
  DivInputMetrics,
  DivMetrics,
  DivNext,
  DivSelectMetrics,
  DivText,
} from './styles';

import * as Sentry from '@sentry/react';

const itensDimension = ['BRL', 'USD', 'EUR'];

function Financial({
  id,
  step,
  setStep,
  choseMetric,
  setChoseMetric,
  price,
  setPrice,
  monthly_maintenance_fee,
  setMonthly_maintenance_fee,
  monthly_tax_fee,
  setMonthly_tax_fee,
  type,
  typeEn,
}) {
  const [active, setActive] = useState(true);

  const view = useSelector((state) => state.step.data);
  const viewMainFeeTax = viewMainFee(typeEn);
  const [spacePrice, setSpacePrice] = useState(false);
  const [openEditSucess, setOpenEditSucess] = useState(false);

  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/advertise`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  function updateFinancial() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .put(
        `/v1/property/${id}/edit`,
        {
          price: convert(price),
          monthly_tax_fee: convert(monthly_tax_fee),
          monthly_maintenance_fee: convert(monthly_maintenance_fee),
          price_currency: choseMetric,
          monthly_maintenance_fee_currency: choseMetric,
          monthly_tax_fee_currency: choseMetric,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        // dispatch(addMsgAction(true));
        setOpenEditSucess(true);
        console.log(response);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  useEffect(() => {
    if (price) {
      maskChange(price, setPrice);
    }

    if (monthly_maintenance_fee) {
      maskChange(monthly_maintenance_fee, setMonthly_maintenance_fee);
    }

    if (monthly_tax_fee) {
      maskChange(monthly_tax_fee, setMonthly_tax_fee);
    }
  }, [choseMetric]);

  function maskChange(e, setValue) {
    const float = e;

    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function mask(e, setValue) {
    const float = e.target.value;
    console.log(float);
    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      console.log(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function transformArea(value) {
    const data = value;
    console.log(value);

    if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      const p1 = data.split('.').join('');

      const transform = parseFloat(p1);
      return transform.toFixed(2);
    }
    const p1 = data.replace(/,/g, '');
    const transform = parseFloat(p1);
    return transform.toFixed(2);
  }

  function convert(value) {
    const data = transformArea(value);
    return data;
  }

  useEffect(() => {
    if (price && price >= '0') {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [price]);

  function handleProx(event) {
    updateFinancial();

    event.preventDefault();
    setActive(false);
  }

  function spaceDimensionPrice(value) {
    console.log(value);
    if (value) setSpacePrice(true);
    else setSpacePrice(false);
  }

  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="Financial.title" />
          </TitlePage>

          <MandatoryField />

          <DivMetrics value={price}>
            <DivInputMetrics>
              <InputTextRequireSelect
                noBorder
                func={spaceDimensionPrice}
                mask={mask}
                placeHolder={I18n.getTranslation(
                  location,
                  'Financial.placeHolderPrice',
                )}
                label={<I18n t="Financial.labelPrice" />}
                value={price}
                setValue={setPrice}
              />
            </DivInputMetrics>
            <DivSelectMetrics noBorder value={price}>
              <DivText>
                <Dimension
                  value={choseMetric}
                  setValue={setChoseMetric}
                  itens={itensDimension}
                />
              </DivText>
            </DivSelectMetrics>
          </DivMetrics>
          {spacePrice ? (
            <MessageInputs>
              <I18n t="RequiredField.message" />
            </MessageInputs>
          ) : null}

          {viewMainFeeTax ? (
            <DivMetrics value={monthly_maintenance_fee} className="mg-top-30">
              <DivInputMetrics>
                <InputText
                  noBorder
                  mask={mask}
                  placeHolder={I18n.getTranslation(
                    location,
                    'Financial.placeHolderManTax',
                  )}
                  label={<I18n t="Financial.labelManTax" />}
                  value={monthly_maintenance_fee}
                  setValue={setMonthly_maintenance_fee}
                />
              </DivInputMetrics>
              <DivSelectMetrics noBorder value={monthly_maintenance_fee}>
                <DivText>
                  <Dimension
                    value={choseMetric}
                    setValue={setChoseMetric}
                    itens={itensDimension}
                  />
                </DivText>
              </DivSelectMetrics>
            </DivMetrics>
          ) : null}

          <DivMetrics value={monthly_tax_fee} className="mg-top-30">
            <DivInputMetrics>
              <InputText
                noBorder
                mask={mask}
                placeHolder={I18n.getTranslation(
                  location,
                  'Financial.placeHolderIptu',
                )}
                label={<I18n t="Financial.labelManIptu" />}
                value={monthly_tax_fee}
                setValue={setMonthly_tax_fee}
              />
            </DivInputMetrics>
            <DivSelectMetrics noBorder value={monthly_tax_fee}>
              <DivText>
                <Dimension
                  value={choseMetric}
                  setValue={setChoseMetric}
                  itens={itensDimension}
                />
              </DivText>
            </DivSelectMetrics>
          </DivMetrics>
        </div>

        <DivNext>
          <NextPreview
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>
      <ModalEditSucess
        func={() => {
          window.history.back();
        }}
        open={openEditSucess}
        setOpen={setOpenEditSucess}
      />
    </>
  );
}

export default Financial;
