import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import * as Sentry from '@sentry/react';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import baseURL from '../../services/api';
import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {fireActionMethods} from '~/utils/swalMethods';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalDeleteAds({open, setOpen, getAllProperties, inf}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  async function deleteProperty() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    try {
      setLoading(true);
      const endpointToCall = inf?.subscriber_id
        ? `subscriber/${inf.subscriber_id}`
        : `property/${inf.id}`;

      const {data} = await apiBackend.delete(`/v1/${endpointToCall}`, {
        headers: {Authorization: `Bearer ${token}`},
      });

      handleClose();
      fireActionMethods(
        'Anúncio deletado',
        'Você excluiu o anúncio, ele não poderá mais ser recuperado.',
        'success',
        getAllProperties,
      );
    } catch (err) {
      Sentry.captureException(err);
      fireActionMethods(
        'Não foi possível completar sua solicitação',
        'Um erro interno ocorreu durante sua requisição. Por favor, tente novamente mais tarde.',
        'error',
      );
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (open) {
      console.log('Info do menu', inf);
    }
  }, [open, inf]);

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle>Atenção</AdvertTitle>
              <Message>
                Se confirmar a exclusão do anúncio, ele não poderá mais ser
                recuperado e a assinatura associada a ele será cancelada. Deseja
                realmente <b>excluir</b> o anúncio?
              </Message>
              <DivConfirm>
                <ButtonsWhite disabled={loading} onClick={deleteProperty}>
                  SIM
                </ButtonsWhite>
                <ButtonsWhite disabled={loading} onClick={handleClose}>
                  NÃO
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
