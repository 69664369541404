import styled from 'styled-components';
import colors from '~/UI/colors';

export const DivNext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const DivMetrics = styled.div`
  display: flex;
  flex-direction: row;
`;
export const DivInputMetrics = styled.div`
  width: 85%;
`;

export const DivSelectMetrics = styled.div`
  width: 15%;
  height: 71px;
  border: none;
  border-bottom: ${(props) =>
    props.value ? `2px solid ${colors.green_secondary}` : '#808076 1px solid'};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DivText = styled.div`
  padding-top: 15px;
  text-align: center;
  color: #1d1d1b;
  font-size: 18px;
  font-family: 'Lato';
  font-weight: 500;
`;
