import React from 'react';

import {TableContainer} from './styles';
import {ContainerTable} from './styles';
import AccordionCustom from './AccordionCustom';

export default function TableNotifications({notifications}) {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <ContainerTable id="table-notifications">
      <TableContainer>
        {notifications?.map((info, key) => (
          <AccordionCustom
            expanded={expanded}
            handleChange={handleChange}
            key={`accordion-notify-${key}`}
            item={info}
          />
        ))}
      </TableContainer>
    </ContainerTable>
  );
}
