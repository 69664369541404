import React, {useState, useEffect} from 'react';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {
  ButtonInfo,
  TitlePage,
  ContainerPhoto,
  ButtonLoad,
  SelectText,
  PhraseLoad,
  Warning,
  Container,
} from './styles';
import Dropzone from 'react-dropzone';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import BackupIcon from '@material-ui/icons/Backup';
import RenderImages from './RenderImages';
import ModalCropper from './ModalCropper';
import imageCompression from 'browser-image-compression';
import ModalTips from './ModalTips';
import './styles.css';

import baseURL from '../../../../../services/api';
import {desencrypta} from '../../../../../utils';
import {Envolved, DivNext} from '../styles';
import NextPrev from '../Components/nextPrev';
import MenuProfile from '../../../../../components/Menu/MenuProfile';

import * as Sentry from '@sentry/react';

export default function Photo(props) {
  const [file, setFile] = useState([]);
  const [active, setActive] = useState(false);
  const [fileCrop, setFileCrop] = useState();
  const [openCroper, setOpenCroper] = useState(false);
  const [openTips, setOpenTips] = useState(false);
  const [step, setStep] = useState('1');
  const [advertise, setAdvertise] = useState('');

  async function getProperties() {
    fetch(`${baseURL}/api/v1/properties/${props.match.params.id}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        Authorization: desencrypta(localStorage.getItem('@User_informations')),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAdvertise(data);
        if (data.photos.length > 0) setFile(data.photos);
        setStep(data.step);
      })
      .catch((e) => {
        if (e.status === 401) {
          console.log(e);
        } else {
          Sentry.captureException(e);
        }
      });
  }

  useEffect(() => {
    getProperties();
  }, []);

  useEffect(() => {
    if (file.length > 0 && file.length <= 20) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [file]);

  async function fileSelectedHandler(file) {
    let fileImg = await compressionImage(file[0]);
    if (fileImg === undefined) {
    } else {
      console.log(fileImg);
      setFileCrop(fileImg);
      setOpenCroper(true);
    }
  }

  async function compressionImage(event) {
    const imageFile = event;
    const options = {
      maxSizeMB: 0.13,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(compressedFile);
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`,
      );
      var file = new File([compressedFile], 'photo.jpg', {
        type: 'image/jpeg',
        lastModified: Date.now(),
      }); // file = URL.createObjectURL(compressedFile);
      return file;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function updateAnouncementWithPhoto(key, idImage) {
    fetch(`${baseURL}/api/v1/properties/${advertise.id}/photos/${idImage}`, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

      headers: {
        'Content-Type': 'application/json',
        Authorization: desencrypta(localStorage.getItem('@User_informations')),
      },
      body: JSON.stringify({
        photo: {
          ordination: key,
        },
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        window.location.pathname = `view/edit/type/${props.match.params.id}`;
      })
      .catch((e) => Sentry.captureException(e));
  }

  async function handleProx(event) {
    event.preventDefault();
    setActive(false);
    for (let i = 0; i < file.length; i++) {
      if (file[i].ordination !== i) {
        file[i].ordination = i;

        updateAnouncementWithPhoto(i, file[i].id);
      } else {
        window.location.pathname = `view/edit/type/${props.match.params.id}`;
      }
    }
  }

  function handleOpenTips(event) {
    event.preventDefault();
    setOpenTips(true);
  }

  return (
    <>
      <MenuProfile />
      <div className="flex-page">
        <Container>
          <Envolved>
            <form onSubmit={handleProx}>
              <>
                <TitlePage>
                  Adicione as fotos do seu imóvel ao seu anúncio
                </TitlePage>

                <ButtonInfo onClick={(event) => handleOpenTips(event)}>
                  <PriorityHighIcon /> Dicas de fotos
                </ButtonInfo>

                {file.length <= 20 ? (
                  <Dropzone
                    maxSize={3097152}
                    multiple
                    accept={'image/jpeg, image/png'}
                    type="button"
                    onDrop={fileSelectedHandler}>
                    {({getRootProps, getInputProps}) => (
                      <ContainerPhoto {...getRootProps()}>
                        <input multiple type="file" {...getInputProps()} />
                        <CameraAltIcon className="camIcon"></CameraAltIcon>
                        <SelectText>
                          Selecione sempre as melhores <br></br> imagens no seu
                          dispositivo.
                        </SelectText>
                        <ButtonLoad type="button">
                          <BackupIcon style={{marginTop: '7px'}} />{' '}
                          <PhraseLoad>Carregue as imagens</PhraseLoad>
                        </ButtonLoad>
                      </ContainerPhoto>
                    )}
                  </Dropzone>
                ) : null}

                <RenderImages
                  id={advertise.id}
                  setFiles={setFile}
                  files={file}
                />
              </>
              <DivNext>
                <NextPrev step={step} setStep={setStep} active={active} />
              </DivNext>
            </form>
          </Envolved>
        </Container>
      </div>
      {openCroper ? (
        <ModalCropper
          id={advertise.id}
          filesExibition={file}
          setFilesExibition={setFile}
          open={openCroper}
          setOpen={setOpenCroper}
          file={fileCrop}
        />
      ) : null}
      <ModalTips open={openTips} setOpen={setOpenTips} />
    </>
  );
}
