import styled, {css} from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const CardList = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  padding: 20px;

  box-shadow: 0px 3px 6px #00000033;
  margin-bottom: 20px;
`;

export const CardExtern = styled.div`
  background-color: ${(props) => (props.state ? '#f2f2ed' : '#fff')};
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 5px;
  padding: 20px;

  .contentMyAds {
    display: ${(props) => (props.state ? 'block' : 'none')};
    /* transition: ${(props) => (props.state ? '2s' : '2s')}; */
  }
`;

export const Space = styled.div`
  margin: 54px;
  @media only screen and (max-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

const Title = styled.div`
  font-family: ${fonts.latoBold};
  font-size: 18px;
`;

export const TitleDraft = styled(Title)`
  color: #999991;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWait = styled(Title)`
  color: #a88721;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleDessaproved = styled(Title)`
  color: #cc2c29;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleApproved = styled(Title)`
  color: #6b915c;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonOrder = styled.div`
  color: ${colors.green_secondary};
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 5px;
  min-width: 140px;
  max-width: 100%;
  height: 40px;
  border: none;
  font-size: 16px;
  font-family: ${fonts.latoBold};
  :focus {
    outline: 0;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #f2f2ed;
    color: #50b275;
  }
  &:active {
    background: #2e6643;
    color: #e6ffef;
  }
  &:disabled {
    background: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const ButtonEdit = styled.button`
  background-color: ${colors.green_secondary};
  box-shadow: 0px 3px 5px #00000040;
  color: ${colors.white};
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 3px 5px #00000040;
  font-family: ${fonts.latoBlack};
  &:hover {
    background: #2e6643;
    color: #fff;
  }
  &:disabled {
    background: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
`;

export const ButtonNew = styled(ButtonEdit)``;

export const LabelSinalize = styled.div`
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 25px;
  min-width: 82px;
  font-family: ${fonts.latoBold};
`;

export const LabelPending = styled(LabelSinalize)`
  background-color: #ffd34d;
  color: ${colors.white};
  font-weight: bold;
`;

export const LabelApproved = styled(LabelSinalize)`
  background-color: #8ecc51;
  color: ${colors.white};
`;

export const LabelDesapproved = styled(LabelSinalize)`
  background-color: #f56662;
  color: ${colors.white};
`;

export const LabelInative = styled(LabelSinalize)`
  background-color: #f2f2ed;
  color: #1d1d1b;
  font-weight: bold;
`;

export const ViewJustify = styled.div`
  color: ${colors.green_secondary};
  text-decoration: underline;
`;

export const Filter = styled.div`
  cursor: pointer;
  color: ${colors.green_secondary};
  font-family: ${fonts.latoBlack};
  font-weight: bold;
  font-size: 18px;
  /* width: 108px; */
  &:hover {
    color: ${colors.white};
    background-color: ${colors.green_secondary};
  }

  &:active {
    color: ${colors.white};
    background-color: ${colors.green_third};
  }
`;

export const ButtonSeeAnnoucement = styled.button`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  box-shadow: 0px 3px 6px #00000033;
  height: 40px;
  border-radius: 5px;
  border: none;
  &:hover {
    background-color: ${colors.green_third};
  }

  &:focus {
    outline: 0;
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    box-shadow: 0px 0px 3px #00000033;
  }
  width: 180px;
  margin: 0px;
  font-family: ${fonts.latoBlack};
  font-size: 18px;
  font-weight: 900;
  @media (max-width: 321px) {
    width: 140px;
    font-size: 18px;
  }

  @media (min-width: 1200px) {
    width: 140px;
    font-size: 18px;
  }
`;

export const Container = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 26px auto;
  padding: 0px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;

  .sectionButton {
    box-sizing: border-box;
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 382px) {
      flex-direction: column;
    }

    @media (max-width: 1200px) {
      justify-content: center;

      .sectionButtonColors {
        display: none;
      }
    }

    .filterButton {
      margin-right: 20px;
      height: 30px;
      padding: 7px 10px;
      box-sizing: border-box;
      border: 1px solid;
      border-radius: 5px;
      background: transparent;
      font-size: 14px;
    }

    .sectionFilter {
      display: flex;
      flex-direction: row;

      .mg-no-mobile {
        margin-left: 20px;
      }

      .filterGButton {
        color: ${colors.green_secondary};
        background-color: ${colors.white};
        box-shadow: 0px 3px 6px #00000033;
        border-radius: 5px;
        min-width: 120px;
        width: 174px;
        height: 40px;
        border: none;
        font-size: 16px;
        font-family: ${fonts.latoBold};
        :focus {
          outline: 0;
        }
        display: flex;
        justify-content: space-between;
        padding: 0px 15px;
        align-items: center;
        &:hover {
          background: #f2f2ed;
          color: #50b275;
        }
        &:active {
          background: #2e6643;
          color: #e6ffef;
        }
        &:disabled {
          background: ${colors.gray_secondary};
        }
        &:focus {
          outline: 0;
        }

        cursor: pointer;
      }

      @media (max-width: 700px) {
        flex-direction: column;
        .mg-no-mobile {
          margin-left: 0px;
          margin-top: 10px;
        }

        .filterGButton {
          width: 300px;
          box-sizing: border-box;
        }
      }
    }
  }

  .titles {
    display: flex;
    flex-direction: row;
    align-items: center;

    .principalTitle {
      font-size: 30px;
      color: #1d1d1b;
      font-family: ${fonts.latoBlackItalic};
      margin-right: 50px;
    }

    @media (max-width: 382px) {
      flex-direction: column;
      align-items: flex-start;
      .principalTitle {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
`;

export const Bark = styled.div`
  min-height: 295px;
  /* height: 240px; */
  /* max-height: 240px; */
`;

export const Card = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 5px;
  min-height: 195px;
  padding: 15px;
  box-sizing: border-box;
  /* max-height: 240px !important; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 370px;
  /* width: 100%; */
`;
export const CardAll = styled(Card)`
  min-height: 270px;
`;
export const CardPending = styled(Card)`
  min-height: 270px;
  /* max-height: 215px; */
`;
export const CardRefused = styled(Card)`
  min-height: 270px;
`;
export const CardApproved = styled(Card)`
  min-height: 270px;
`;
export const CardDrafts = styled(Card)`
  min-height: 270px;
`;
export const CardInative = styled(Card)`
  min-height: 270px;
`;

const Button = styled.button`
  margin-right: 20px;
  height: 30px;
  padding: 7px 10px;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 5px;
  background: transparent;
  font-size: 14px;
  &:hover {
    color: ${colors.white};
  }
`;

export const ButtonAll = styled(Button)`
  border: 1px solid ${colors.green_secondary};
  color: ${(props) => (props.active ? colors.white : colors.green_secondary)};
  background-color: ${(props) =>
    props.active ? colors.green_secondary : 'transparent'};
  &:hover {
    background-color: ${(props) =>
      props.active ? colors.green_secondary : colors.green_secondary};
    color: ${(props) => (props.active ? colors.white : colors.white)};
  }
`;

export const ButtonPending = styled(Button)`
  border: 1px solid #ffd34d;
  color: ${(props) => (props.active ? colors.white : '#a88721')};
  background-color: ${(props) => (props.active ? '#ffd34d' : 'transparent')};
  &:hover {
    background-color: ${(props) => (props.active ? '#ffd34d' : '#ffd34d')};
    color: ${(props) => (props.active ? colors.white : colors.white)};
  }
`;

export const ButtonRefused = styled(Button)`
  border: 1px solid #f56662;
  color: ${(props) => (props.active ? colors.white : '#f56662')};
  background-color: ${(props) => (props.active ? '#f56662' : 'transparent')};
  &:hover {
    background-color: ${(props) => (props.active ? '#f56662' : '#f56662')};
    color: ${(props) => (props.active ? colors.white : colors.white)};
  }
`;

export const ButtonApproved = styled(Button)`
  border: 1px solid #8ecc51;
  color: ${(props) => (props.active ? colors.white : '#8ecc51')};
  background-color: ${(props) => (props.active ? '#8ecc51' : 'transparent')};
  &:hover {
    background-color: ${(props) => (props.active ? '#8ecc51' : '#8ecc51')};
    color: ${(props) => (props.active ? colors.white : colors.white)};
  }
`;

export const TitleViewPorposal = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  color: ${colors.green_secondary};
  font-family: ${fonts.latoBlack};
  display: flex;
  align-items: center;
  &:hover {
    color: ${colors.white};
    background-color: ${colors.green_secondary};
  }

  &:active {
    color: ${colors.white};
    background-color: ${colors.green_third};
  }

  span {
    margin-right: 8px;
  }
`;

export const TitleLocalize = styled.div`
  font-size: 14px;
  color: ${colors.dark};
  font-family: ${fonts.latoMedium};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
