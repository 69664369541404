import {desencrypta} from '../../../utils';
import baseURL from '../../../services/api';
import {FirstPrice, P} from '../Drafts/Table/styles';
import {toMoney} from 'vanilla-masker';
import React from 'react';
import * as Sentry from '@sentry/react';

export async function getUser(id) {
  fetch(`${baseURL}/api/v1/users/${id}`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

    headers: {
      'Content-Type': 'application/json',
      Authorization: desencrypta(localStorage.getItem('@User_informations')),
    },
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((e) => {
      if (e.status === 401) {
        console.log(e);
      } else {
        Sentry.captureException(e);
      }
    });
}

export function getAdress(info) {
  if (info.country) {
    let street_name = info.street_name ? info.street_name : '';
    let city = info.city ? ', ' + info.city : '';
    let street_number = info.street_number ? ', ' + info.street_number : '';
    let neighborhood = info.neighborhood ? ', ' + info.neighborhood : '';
    let floor = info.floor ? ', Andar ' + info.floor : '';
    let unit_number = info.unit_number ? ', Apto ' + info.unit_number : '';
    let state = info.state ? ', ' + info.state : '';
    let country = info.country ? ', ' + info.country : '';
    return `${street_name}${street_number}${neighborhood}${unit_number}${floor}${city}${state}${country} `;
  } else {
    return 'Não Definido';
  }
}

export function getPrice(info, currencyValues, flag) {
  if (info.price) {
    switch (info.price_currency) {
      case 'BRL':
        return itsBRL(info.price, currencyValues, flag);
      case 'USD':
        return itsUSD(info.price, currencyValues, flag);
      case 'EUR':
        return itsEUR(info.price, currencyValues, flag);
      default:
        return 'Não Definido';
    }
  } else {
    return 'Não Definido';
  }
}

export function getMonthly(info, currencyValues, flag) {
  if (info.monthly_maintenance_fee) {
    switch (info.monthly_maintenance_fee_currency) {
      case 'BRL':
        return itsBRL(info.monthly_maintenance_fee, currencyValues, flag);
      case 'USD':
        return itsUSD(info.monthly_maintenance_fee, currencyValues, flag);
      case 'EUR':
        return itsEUR(info.monthly_maintenance_fee, currencyValues, flag);
      default:
        return 'Não Definido';
    }
  } else {
    return 'Não Definido';
  }
}

export function getMonthlyTax(info, currencyValues, flag) {
  if (info.monthly_tax_fee) {
    switch (info.monthly_tax_fee_currency) {
      case 'BRL':
        return itsBRL(info.monthly_tax_fee, currencyValues, flag);
      case 'USD':
        return itsUSD(info.monthly_tax_fee, currencyValues, flag);
      case 'EUR':
        return itsEUR(info.monthly_tax_fee, currencyValues, flag);
      default:
        return 'Não Definido';
    }
  } else {
    return 'Não Definido';
  }
}

function itsBRL(price, currencyValues, flag) {
  var float = price.replace(/\D/g, '');
  if (flag) {
    return [
      transformPriceBRL(price),
      transformPriceBRLtoUSD(price, currencyValues),
      transformPriceBRLtoEUR(price, currencyValues),
    ];
  } else {
    return (
      <>
        <FirstPrice>{transformPriceBRL(price)}</FirstPrice>
        <P>{transformPriceBRLtoUSD(price, currencyValues)}</P>
        <P>{transformPriceBRLtoEUR(price, currencyValues)}</P>
      </>
    );
  }
}

function itsUSD(price, currencyValues, flag) {
  let float = price.replace(/\D/g, '');

  if (flag) {
    return [
      transformPriceUSD(price),
      transformPriceUSDtoBRL(price, currencyValues),
      transformPriceUSDtoEUR(price, currencyValues),
    ];
  } else {
    return (
      <>
        <FirstPrice>{transformPriceUSD(price)}</FirstPrice>
        <P>{transformPriceUSDtoBRL(price, currencyValues)}</P>
        <P>{transformPriceUSDtoEUR(price, currencyValues)}</P>
      </>
    );
  }
}

function itsEUR(price, currencyValues, flag) {
  let float = price.replace(/\D/g, '');
  if (flag) {
    return [
      transformPriceEUR(price),
      transformPriceEURtoBRL(price, currencyValues),
      transformPriceEURtoUSD(price, currencyValues),
    ];
  } else {
    return (
      <>
        <FirstPrice>{transformPriceEUR(price)}</FirstPrice>
        <P>{transformPriceEURtoBRL(price, currencyValues)}</P>
        <P>{transformPriceEURtoUSD(price, currencyValues)}</P>
      </>
    );
  }
}

function transformPriceBRLtoUSD(price, currencyValues) {
  return (
    'US$ ' +
    toMoney((price / currencyValues.USD.ask).toFixed(2), {
      precision: 2,
      separator: '.',
      delimiter: ',',
    })
  );
}

function transformPriceBRLtoEUR(price, currencyValues) {
  return (
    '£$ ' +
    toMoney((price / currencyValues.EUR.ask).toFixed(2), {
      precision: 2,
      separator: ',',
      delimiter: '.',
    })
  );
}

function transformPriceUSDtoBRL(price, currencyValues) {
  return (
    'R$ ' +
    toMoney((price * currencyValues.USD.ask).toFixed(2), {
      precision: 2,
      separator: ',',
      delimiter: '.',
    })
  );
}

function transformPriceUSDtoEUR(price, currencyValues) {
  return (
    '£$ ' +
    toMoney(
      ((price * currencyValues.USD.ask) / currencyValues.EUR.ask).toFixed(2),
      {
        precision: 2,
        separator: ',',
        delimiter: '.',
      },
    )
  );
}

function transformPriceEURtoBRL(price, currencyValues) {
  return (
    'R$  ' +
    toMoney((price * currencyValues.EUR.ask).toFixed(2), {
      precision: 2,
      separator: ',',
      delimiter: '.',
    })
  );
}

function transformPriceEURtoUSD(price, currencyValues) {
  return (
    'US$ ' +
    toMoney(
      ((price * currencyValues.EUR.ask) / currencyValues.USD.ask).toFixed(2),
      {
        precision: 2,
        separator: '.',
        delimiter: ',',
      },
    )
  );
}

function transformPriceEUR(float) {
  return (
    '£$ ' +
    toMoney(parseFloat(float).toFixed(2), {
      precision: 2,
      separator: ',',
      delimiter: '.',
    })
  );
}

function transformPriceUSD(float) {
  return (
    'US$ ' +
    toMoney(parseFloat(float).toFixed(2), {
      precision: 2,
      separator: '.',
      delimiter: ',',
    })
  );
}

function transformPriceBRL(float) {
  return (
    'R$ ' +
    toMoney(parseFloat(float).toFixed(2), {
      precision: 2,
      separator: ',',
      delimiter: '.',
    })
  );
}

/* ------------------------------ */
/* ------------------------------ */
/* ------------------------------ */
/* ------------------------------ */

export function transformCurrency(item, currency) {
  if (item.price && item.currency) {
    return getCurrencyConfig(parseInt(item.price), item.currency, currency);
  } else {
    return ['', ''];
  }
}

function getCurrencyConfig(e, choseMetric, currencyValues) {
  var float = e;

  if (choseMetric.toUpperCase() === 'USD') {
    return [
      'BRL',
      toMoney((e * currencyValues?.USD?.ask).toFixed(0), {
        precision: 0,
        separator: '',
        delimiter: '.',
      }),
    ];
  }
  if (choseMetric.toUpperCase() === 'BRL') {
    return [
      'BRL',
      toMoney(float, {
        precision: 0,
        separator: '',
        delimiter: '.',
      }),
    ];
  }
  if (choseMetric.toUpperCase() === 'EUR') {
    return [
      'BRL',
      toMoney((e * currencyValues?.EUR?.ask).toFixed(0), {
        precision: 0,
        separator: '',
        delimiter: '.',
      }),
    ];
  }
}
