import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 26px auto;
  padding: 0px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;

  .sectionProperties {
    display: flex;
    flex-direction: column;
    .address_property {
      font-size: 14px;
      font-family: ${fonts.latoRegular};
      margin-top: 10px;
    }

    .type_property {
      font-size: 14px;
      font-family: ${fonts.latoRegularBold};
    }

    .price_property {
      display: flex;
      font-family: ${fonts.latoMedium};
      margin-top: 10px;
      align-items: center;

      @media only screen and (max-width: 345px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .p1 {
        font-size: 14px;
        color: ${colors.green_secondary};
        font-weight: bold;
      }

      .p2 {
        font-size: 12px;
      }
      div {
        margin-right: 20px;
      }
    }
  }
  .sectionJustify {
    .superior_info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .justify_name {
        font-size: 14px;
        font-family: ${fonts.latoRegularBold};
      }

      .justify_date {
        font-size: 12px;
        font-family: ${fonts.latoRegular};
      }

      @media only screen and (max-width: 710px) {
        flex-direction: column;
        align-items: flex-start;

        .justify_date {
          margin: 10px 0px;
          flex-direction: column !important;
        }
      }

      @media only screen and (max-width: 410px) {
        .justify_date {
          display: flex;

          flex-direction: column !important;
        }
      }
    }

    .text_area {
      margin-top: 15px;
      font-family: ${fonts.latoRegular};
      font-size: 14px;
    }
  }
`;

export const Icons = styled.div`
  /*   margin-right: 20px; */
  background: ${(props) =>
    props.isOpen ? colors.green_secondary : colors.white};
  height: 40px;
  width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 3px 6px #00000033;
  transition: background 0.2s linear, color 0.2s linear;
  cursor: pointer;
  color: ${(props) => (props.isOpen ? colors.white : colors.green_secondary)};

  &:hover {
    color: ${colors.green_secondary};
    background-color: ${colors.gray_primary};
  }
  &:active {
    color: ${colors.white};
    background-color: ${colors.gray_secondary};
  }
`;
