export function viewStyleInput(type) {
  if (
    type === 'Land' ||
    type === 'Commercial Land' ||
    type === 'Gated Community Land'
  ) {
    return false;
  }
  return true;
}

export function viewSwitchInput(type) {
  if (type === 'Apartamento' || type === 'Condo') {
    return true;
  }
  return false;
}

export function viewTypeGarage(type) {
  if (
    type === 'Condo' ||
    type === 'Country House' ||
    type === 'Gated Community House' ||
    type === 'House'
  ) {
    return true;
  }
  return false;
}

export function viewComercialBuilding(type) {
  if (type === 'Commercial Building') {
    return false;
  }
  return true;
}

export function viewStoreBath(type) {
  if (type === 'Store') {
    return false;
  }
  return true;
}

export function viewSuiteOfficeIndustrial(type) {
  if (type === 'Office' || type === 'Industrial Shed') {
    return false;
  }
  return true;
}

export function requiredFarm(type) {
  if (type === 'Farm') {
    return true;
  }
  return false;
}

export function requiredTotalArea(type) {
  if (type === 'Condo' || type === 'Store' || type === 'Office') {
    return true;
  }
  return false;
}

export function viewMainFee(type) {
  if (type === 'House' || type === 'Land' || type === 'Farm') {
    return false;
  }
  return true;
}
