import React, { useState } from 'react';
import { Star } from './styles'
import styled from 'styled-components';
import colors from '../../../../../UI/colors';


export default function InputTextRequired(props) {

    const [viewMessage, setViewMessage] = useState(false);

    function change(event) {
        if (props.mask) {
            props.mask(event, props.setValue)
        } else {
            props.setValue(event.target.value);
        }
    }

    function handleBlur() {
        if (props.value) {
            setViewMessage(false);

            if (props.min && props.max) {
                if (props.value.length === props.min || props.value.length === props.max) {
                    setViewMessage(false);
                } else {
                    setViewMessage(true);
                }
            }
        } else {
            setViewMessage(true);
        }


    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <Label color={props.value}>{props.label} <Star>*</Star></Label>
            <Input onBlur={() => { handleBlur() }} disabled={props.disable} minLength={props.min ? props.min : null} maxLength={props.max ? props.max : null} color={props.value} value={props.value} onChange={(event) => change(event)} itens={props.itens} placeholder={props.placeHolder}></Input>
            {viewMessage ? <Message>
                Campo Obrigatório
            </Message> : null}
        </div>
    );
}



const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${props => props.value ? '2px solid ' + colors.green_secondary : '#808076 1px solid'};
  color:  ${props => props.value ? '#1D1D1B' : '#808076'};
  width: 100%;
  font-size: 18px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus{
        outline: 0;    
  }
  &:disabled{

  }
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  color:  ${props => props.color ? colors.green_secondary : '#808076'};
`

const Message = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: red;
`