import styled from 'styled-components';
import colors from '../../../../../UI/colors';
import fonts from '../../../../../UI/fonts';

export const ButtonFilter = styled.button`
  height: 50px;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 10px;
  color: ${colors.dark};
  padding: 4px;
  font-size: 16px;
  font-family: ${fonts.latoRegular};
  /* justify-content: space-between; */
  transition: all 0.2s ease;
  &:focus {
    outline: 0;
  }

  @media (min-width: 800px) {
    &:hover {
      background: #f2f2ed;
    }
  }

  > span {
    max-width: 100px;
    width: max-content;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 6px;
    margin-right: 6px;
  }
`;

export const Container = styled.div`
  @media (max-width: 800px) {
    button {
      width: max-content;

      svg,
      span {
        display: none;
      }
    }
  }
`;
