import React, {useEffect, useState} from 'react';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~/utils';
// import apiBackend from '../../../services/apiaxios';
// import {desencrypta, getRoute} from '../../../utils';
import MyAds404 from '../404';
import MyAdsLoading from '../Loading';
import DessaprovedCardComponet from './card';

// import { Container } from './styles';

function Dessaproved({chooseAddress, seeProposal, query}) {
  const [proposal, setProposal] = useState([]);
  const [langName, setLangName] = useState('en');
  const [view404, setView404] = useState(false);
  const [hasLoading, setHasLoading] = useState(true);
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/login`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setProposal([]);

    getProposal();
  }, [seeProposal, chooseAddress, query]);

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });

    let suffix = getRoute();
    setLangName(suffix);
  }, [window.location.href]);

  /** */

  function getProposal() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    let id = localStorage.getItem('id');
    setHasLoading(true);
    setView404(false);
    apiBackend
      .get(
        `/v1/proposal/?status=accept&property_id=${
          chooseAddress && chooseAddress?.id
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        setProposal(response?.data?.proposals);
        if (response.data?.proposals?.length === 0) {
          setView404(true);
        } else {
          setView404(false);
        }
      })
      .finally(() => {
        setHasLoading(false);
      });
  }

  return (
    <>
      {proposal.map((item) => (
        <DessaprovedCardComponet
          item={item}
          langName={langName}
          location={location}
          seeProposal={seeProposal}
        />
      ))}
      {view404 && <MyAds404 />}
      {hasLoading && <MyAdsLoading />}
    </>
  );
}

export default Dessaproved;
