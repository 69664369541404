import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {useHistory} from 'react-router-dom';
import Dash from '~/components/Dashboard';
import SimpleModal from '~/components/Modal';
import ModalFilter from '~/components/ModalFilter';
import ModalFilterBroker from '~/components/ModalFilterBroker';
import apiBackend from '~/services/apiaxios';
import errorXml from '~/services/docs/errorxml';
import {ButtonMedium, ButtonTransparent} from '~/UI/styles';
import {desencrypta} from '~/utils';
import {ContainerPagination} from '../Annoucement/styles';
import {ClearFiltersButton} from '../User/styles';
import MyAds404 from './404';
import All from './All';
import Attended from './Attended';
import Expired from './Expired';
import Filters from './Filters';
import Lead from './Lead';
import MyAdsLoading from './Loading';
import NoSold from './NoSold';
import Published from './Published';
import SearchComponent from './SearchComponent';
import Sold from './Sold';
import {ActionsContainer} from './styles';
import PaginationTab from '../../components/_PaginationTab';
import {ReportContainer} from '../Annoucement/Filters/styles';
import MyCSVDownload from '~/components/MyCSVDownload';

import * as Sentry from '@sentry/react';

/* ----------------- STYLES ------------------- */

const permitted_roles = ['5', '2'];
export default function RealState({display, infoUser}) {
  const userStore = useSelector((state) => state.user);
  const [openRegister, setOpenRegister] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalInfo, setModalInfo] = useState('');

  const [users, setUsers] = React.useState([]);
  const [hasLoading, setHasLoading] = React.useState(true);
  const [see404, setSee404] = React.useState(false);

  /* ------------------------------------------ */
  const [filterName, setFilterName] = useState('');
  const [query, setQuery] = useState(new URLSearchParams());

  /* --- PAGINATION TABLE --- */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(3);

  /* --- STATUS --- */
  const [status, setStatus] = useState('lead');
  const [dateStart, setDateStart] = useState(null);
  const [dateFinish, setDateFinish] = useState(null);

  const history = useHistory();
  const [openFilter, setOpenFilter] = useState(false);

  /* --- Localization Filter --- */
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [viewNorthLat, setViewNorthLat] = useState('');
  const [viewNorthLng, setViewNorthLng] = useState('');
  /* TODO novo */
  const [viewSouthLat, setViewSouthLat] = useState('');
  const [viewSouthLng, setViewSouthLng] = useState('');
  /*  */

  const [plan, setPlan] = useState('');

  // Reports
  const [csvData, setCsvData] = useState([]);
  const [hasSubmitting, setHasSubmitting] = useState(false);

  const handleChangePage = (value) => {
    setUsers([]);
    setPage(value);
  };
  /* --------------------------------------------------- */

  /* ----------FILTER ACTIONS ---------- */
  function selectStatus(filterName, search) {
    let result = status;
    switch (result) {
      case 'all':
        return <All getAllUsers={callBackGetUsers} users={search} />;
      case 2:
        return <Attended getAllUsers={callBackGetUsers} users={search} />;
      case 'lead':
        return <Lead getAllUsers={callBackGetUsers} users={search} />;
      case 1:
        return <Sold getAllUsers={callBackGetUsers} users={search} />;
      case 'noSold':
        return <NoSold getAllUsers={callBackGetUsers} users={search} />;
      case 3:
        return <Published getAllUsers={callBackGetUsers} users={search} />;
      case 4:
        return <Expired getAllUsers={getAllUsers} users={search} />;
      default:
        return <All getAllUsers={getAllUsers} users={search} />;
    }
  }

  function getAllUsers(status_broker) {
    const token = desencrypta(localStorage.getItem('@User_informations'));
    setHasLoading(true);
    setSee404(false);

    apiBackend
      .get(
        `v1/users?paginate=true&page=1&limit=7&type_advertise=broker&status_broker=${status_broker}${
          query.toString() ? '&' + query : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        console.log(response);
        setUsers(response?.data?.users?.data);
        setLastPage(response?.data?.users?.meta?.last_page);
        if (response?.data?.users?.data?.length === 0) {
          setSee404(true);
        }
      })
      .finally(() => setHasLoading(false));
  }

  function getAllUsersForLeads() {
    setHasLoading(true);
    setSee404(false);

    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get(
        `v1/users?type_advertise=broker&paginate=true&page=${page}&limit=7${
          query.toString() !== '' ? '&' + query : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        console.log(response);
        let allUsers = response?.data?.users?.data;
        console.log(allUsers);
        setLastPage(response?.data?.users?.meta?.last_page);
        setUsers(allUsers);

        if (allUsers.length === 0) {
          setSee404(true);
        } else {
          setLastPage(response?.data?.users?.meta?.last_page);
        }
      })
      .catch((e) => Sentry.captureException(e))
      .finally(() => {
        setHasLoading(false);
      });
  }

  function getAllLeads() {
    getAllUsersForLeads();
  }

  function getUsersLeads(status_broker) {
    setHasLoading(true);
    setSee404(false);

    const token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .get(
        `v1/real-state/contact?paginate=true&limit=7&page${page}1&status=${status_broker}${
          query.toString() !== '' ? '&' + query : ''
        }`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      )
      .then((response) => {
        setUsers(response?.data?.real_state_contact?.data);
        setLastPage(response?.data?.real_state_contact?.last_page);
        if (response?.data?.real_state_contact?.data?.length === 0) {
          setSee404(true);
        }
      })
      .finally(() => setHasLoading(false));
  }

  function callBackGetUsers() {
    setUsers([]);
    if (status === 'all') {
      getAllLeads();
    } else if (status === 'lead') {
      getUsersLeads('created');
    } else if (status === 'noSold') {
      getUsersLeads('unsold');
    } else {
      getAllUsers(status);
    }
  }

  const generateReports = React.useCallback(async () => {
    try {
      setHasSubmitting(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/users?type_advertise=broker${
          status === 'all' ? '' : '&status=' + status
        }${query.toString() !== '' ? `&${query.toString()}` : ''}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );

      const paymentsInCsv =
        data?.users?.length > 0
          ? data?.users
              ?.map((item) => {
                return {
                  id: item.id,
                  name: item.name ?? '',
                  surname: item.surname ?? '',
                  email: item.email ?? '',
                  cellphone: item.cellphone ?? '',
                  cargo: item.cargo ?? '',
                  created_at: item.created_at,
                  role_id: item.role_id,
                  avatar: item?.avatar?.avatar_url ?? '',
                  total_properties: item.totalProperties,
                  properties_ids: item?.properties?.join(','),
                  last_updated: item.updated_at,
                  uf: item?.profile?.state ?? '',
                  country: item?.profile?.country ?? '',
                  city: item?.profile?.address?.city ?? '',
                  neighborhood: item?.profile?.neighborhood ?? '',
                  address: item?.profile?.address ?? '',
                  address_complement: item?.profile?.address_complement ?? '',
                  address_number: item?.profile?.address_number ?? '',
                  latitude: item?.profile?.latitude ?? '',
                  longitude: item?.profile?.longitude ?? '',
                  zip_code: item?.profile?.zip_code ?? '',
                  commercial_phone: item?.profile?.commercial_phone ?? '',
                  home_phone: item?.profile?.home_phone ?? '',
                  company_name: item?.profile?.company_name ?? '',
                  enterprise: item?.profile?.enterprise ?? '',
                  cnpj: item?.profile?.cnpj ?? '',
                  cpf: item?.profile?.cpf ?? '',
                  number_of_employees: item?.profile?.number_of_employees ?? '',
                  license: item?.profile?.license ?? '',
                  facebook: item?.profile?.facebook ?? '',
                  instagram: item?.profile?.instagram ?? '',
                  job: item?.profile?.job ?? '',
                  website: item?.profile?.website ?? '',
                  whatsapp: item?.profile?.whatsapp ?? '',
                  linkedin: item?.profile?.linkedin ?? '',
                  twitter: item?.profile?.twitter ?? '',
                  status: item?.profile?.status ?? '',
                  status_broker: item?.profile?.status_broker ?? '',
                  type_advertiser: item?.profile?.type_advertiser ?? '',
                  plan_name: item?.plan?.name,
                  plan_price: item?.plan?.price,
                };
              })

              .sort((a, b) => a.id - b.id)
              .filter(Boolean)
          : null;

      console.log(paymentsInCsv);
      setCsvData(paymentsInCsv);
      setHasSubmitting(false);
    } catch (err) {
      setHasSubmitting(false);
      Sentry.captureException(err);
    }
  }, [query, status]);

  function handleFilter() {
    var query = new URLSearchParams();
    if (filterName?.trim()) {
      query.append('term', filterName?.trim());
    }
    if (dateStart?._isValid) {
      let initial =
        dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';

      query.append('initial', initial);
    }

    if (dateFinish?._isValid) {
      let end = dateFinish?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';
      query.append('end', end);
    }

    if (latitude) {
      query.append('latitude', latitude);
    }

    if (longitude) {
      query.append('longitude', longitude);
    }

    if (viewNorthLat) {
      query.append('view_lat', viewNorthLat);
    }

    if (viewNorthLng) {
      query.append('view_lon', viewNorthLng);
    }

    /* TODO novo */
    if (viewSouthLat) {
      query.append('view_lat2', viewSouthLat);
    }
    if (viewSouthLng) {
      query.append('view_lon2', viewSouthLng);
    }
    /*  */

    if (plan) {
      query.append('plan_id', plan.id);
    }

    if (query.toString() === '' && page > 1) {
      setQuery(new URLSearchParams());
      return setPage(1);
    }

    setQuery(query);
  }

  useEffect(() => {
    setPage(1);
    callBackGetUsers();
  }, [status, query]);

  useEffect(() => {
    callBackGetUsers();
  }, [page]);

  function clearFilter() {
    setPlan('');
    setLatitude('');
    setLongitude('');
    setDateStart(null);
    setDateFinish(null);
    setFilterName('');
    setViewSouthLat('');
    setViewSouthLng('');
    setViewNorthLat('');
    setViewNorthLng('');

    setQuery(() => {
      setPage(1);
      return new URLSearchParams();
    });
  }

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*   useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */
  /* ----------- REGISTER ----------- */

  return (
    <Dash button={'user'} title={'Imobiliária'}>
      <div style={{display: display}}>
        <ActionsContainer>
          <Grid container spacing={4} style={{marginBottom: '20px'}}>
            {isPermitted() && (
              <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                <ButtonMedium
                  onClick={() => {
                    history.push('/real-state/register');
                  }}>
                  Cadastrar Imobiliária
                </ButtonMedium>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
              <SearchComponent
                value={filterName}
                handleChange={setFilterName}
                handleSubmit={handleFilter}
                placeholder="Buscar imobiliárias"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
              <ButtonTransparent
                onClick={() => {
                  // alert('Feature em desenvolvimento.');
                  /* setModalFilter(true); */
                  setOpenFilter(true);
                }}>
                Mais Filtros
              </ButtonTransparent>
            </Grid>
          </Grid>
        </ActionsContainer>
        {/* Botões de filtros */}
        <Filters status={status} setStatus={setStatus} />
        {query.toString() && (
          <>
            <ClearFiltersButton onClick={() => clearFilter()}>
              Limpar filtros
            </ClearFiltersButton>
          </>
        )}

        {status && users.length > 0 && (
          <>
            {' '}
            <ReportContainer>
              <MyCSVDownload
                data={csvData}
                handleGenerate={generateReports}
                loading={hasSubmitting}
                filename="PERMUTI_broker_reports.csv"
              />
            </ReportContainer>
            {selectStatus(filterName, users)}{' '}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <ContainerPagination>
                {lastPage > 1 && (
                  <PaginationTab
                    count={lastPage}
                    page={page}
                    handleChange={(event, value) => handleChangePage(value)}
                  />
                )}
              </ContainerPagination>
            </Grid>
          </>
        )}
        {hasLoading && <MyAdsLoading />}
        {see404 && <MyAds404 />}
        {/* Modals */}
        <SimpleModal modalInfo={modalInfo} modal={modal} setModal={setModal} />
        <ModalFilterBroker
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          viewNorthLat={viewNorthLat}
          setViewNorthLat={setViewNorthLat}
          viewNorthLng={viewNorthLng}
          setViewNorthLng={setViewNorthLng}
          viewSouthLat={viewSouthLat}
          setViewSouthLat={setViewSouthLat}
          viewSouthLng={viewSouthLng}
          setViewSouthLng={setViewSouthLng}
          open={openFilter}
          setOpen={setOpenFilter}
          dateFinish={dateFinish}
          setDateFinish={setDateFinish}
          dateStart={dateStart}
          setDateStart={setDateStart}
          status={status}
          callBackGetUsers={handleFilter}
          plan={plan}
          setPlan={setPlan}
        />
        {/*  */}
      </div>
    </Dash>
  );
}
