import styled from 'styled-components';
import colors from '~/UI/colors';

export const Container = styled.div`
  margin-top: 30px;
`;

export const TitleHave = styled.p`
  color: #1d1d1b;
  padding-left: 10px;
  font-size: 16px;
  font-family: Lato Bold;
  /* font-weight: bold; */
`;

export const Subtitle = styled.div`
  color: ${colors.green_secondary};
  font-size: 16px;
  font-family: Lato Bold;
  /* font-weight: bold; */
  display: flex;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SubtitleMobile = styled.div`
  color: ${colors.green_secondary};
  font-size: 16px;
  font-family: Lato Bold;
  /* font-weight: bold; */
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-left: 10px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const Renderization = styled.div`
  margin-top: 30px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Image = styled.img`
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  object-fit: cover;
  box-shadow: 0px 3px 6px #0000004d;

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 150px;
    height: 150px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 220px;
    height: 220px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 220px;
    height: 220px;
  }
`;

export const DivIcon = styled.div`
  cursor: pointer;
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  border-radius: 100%;
  background-color: #000000;
  opacity: 0.7;
  margin: 10px 10px 0px 0px;
  &:hover {
    background-color: red;
  }
  &:active {
    color: ${colors.white};
    background-color: red;
  }
`;

export const DivIconEdit = styled(DivIcon)`
  &:hover {
    background-color: ${colors.green_secondary};
  }
  &:active {
    background-color: ${colors.green_secondary};
  }
`;

export const ImageIcon = styled.img`
  width: 20px;
`;

export const DivTrash = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    width: 150px;
    height: 150px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 220px;
    height: 220px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 220px;
    height: 220px;
  }
`;

export const DivIconMove = styled(DivIcon)`
  margin-left: 5px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${colors.green_secondary};
  }
  &:active {
    background-color: ${colors.green_secondary};
  }
`;

/* ----------------------- */
/* -----------RenderDesktop */
/* ----------------------- */
export const ContainerDesktop = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

/* ----------------------- */
/* -----------RenderMobile */
/* ----------------------- */
export const GridPhotosMobile = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 130px));
  grid-row-gap: 20px;
  grid-column-gap: 5px;
  place-items: center;
  justify-content: center;
  margin: 1rem auto;
  margin-bottom: 30px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const ItemPhotoMobile = styled.div`
  width: 140px;
  height: 140px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;

  box-shadow: 0px 3px 6px #0000004d;

  transform: translateY(0px) rotate(0deg);
  transition: all 0.2s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    user-select: none !important;
    pointer-events: all !important;
    transition: all 0.2s ease;
  }

  &::before {
    transition: all 0.2s ease;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 1, 1, 0.4);
    box-shadow: 0px 0px 3px #a45fd5;
    display: block;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
  }

  &.selected {
    transform: translateY(-5px) rotate(2deg);
    transition: all 0.2s ease;
  }
  &.unselected {
    transform: translateY(0px) rotate(0deg);
    animation: unselectedStateAnimation 0.7s ease backwards;
    transition: all 0.2s ease;
  }

  @keyframes unselectedStateAnimation {
    from {
      transform: translateY(-30px) rotate(4deg) scale(1.1);
    }
    to {
      transform: translateY(0px) rotate(0deg) scale(1);
    }
  }

  &.selected::before {
    opacity: 1;
    visibility: visible;
  }

  @media only screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

export const DeletePhotoMobile = styled.div`
  z-index: 300;
  position: absolute;
  top: 0;
  right: 0;

  .delete {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 10px 10px 0px 0px;
    background: #191919aa;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
    }

    &:hover {
      background-color: red;
    }
    &:active {
      color: ${colors.white};
      background-color: red;
    }
  }
`;
