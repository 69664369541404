import {combineReducers, createStore} from 'redux';
import planList from '../reducers/plan_list';
import addMsg from '../reducers/add_msg';
import UserReducer from '../reducers/user_reducer';

let rootReducer = combineReducers({
  plan: planList,
  step: addMsg,
  user: UserReducer,
});

const store = createStore(rootReducer);

export default store;
