import React, {useEffect} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import InputText from '../inputText';
import baseURL from '../../services/api';
import {desencrypta} from '../../utils';
import {useState} from 'react';
import Dimension from '../dimension';
import {toMoney} from 'vanilla-masker';
import DatePickers from './date';
import Autocomplete from 'react-google-autocomplete';
import I18nItens from '../../utils/translate/propertyType.json';
import InputPrice from './inputPrice';
import SelectI18n from './selectI18n';
import I18n from '../../utils/translate/propertyType.json';

import UserAutoComplete from './userAutoComplete';
import fonts from '../../UI/fonts';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  DivInputMetrics,
  TitlePage,
  DivMetrics,
  DivText,
  DivSelectMetrics,
  ButtonGreen,
  TitleGrid,
} from './styles';

const itensDimension = ['BRL', 'USD', 'EUR'];
const itemEN = [
  'All',
  'House',
  'Gated Community House',
  'Condo',
  'Land',
  'Gated Community Land',
  'Country House',
  'Farm',
  'Store',
  'Office',
  'Commercial House',
  'Industrial Shed',
  'Commercial Building',
  'Commercial Land',
];

const Countries = {
  Brasil: 'Brazil',
  'Estados Unidos': 'United States',
  Portugal: 'Portugal',
};

export default function ModalFilter({
  search,
  open,
  setOpen,
  digit,
  setDigit,
  city,
  setCity,
  state,
  setState,
  country,
  setCountry,
  created_date,
  setCreated_data,
  property_type,
  setProperty_type,
  user_name,
  setUser_name,
  max_price,
  setMax_price,
  min_price,
  setMin_price,
  currency,
  setCurrency,
}) {
  // const [price, setPrice] = useState(priceInfo);
  // const [currency, setcurrency] = useState(currency || 'USD');
  const [classGoogle, setClassGoogle] = useState('');
  const [adress, setAdress] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  function insert(place, number) {
    if (number === 1) {
      place.address_components.map((item) => {
        if (item.types[0] === 'route') {
          // setAdress(item.long_name)
          // setDigit(item.long_name)
        } else if (
          item.types[0] === 'administrative_area_level_2' ||
          item.types[0] === 'locality'
        ) {
          setCity(item.long_name);
        } else if (item.types[0] === 'administrative_area_level_1') {
          setState(item.short_name);
        } else if (item.types[0] === 'country') {
          setCountry(Countries[item.long_name]);
        }
      });
    }
  }

  function mask(e, setValue) {
    var float = e.target.value;
    if (float === '0,0' || float === '0.0') {
      setValue('');
    } else if (currency === 'USD') {
      setValue(
        toMoney(float, {
          precision: 2,
          separator: '.',
          delimiter: ',',
        }),
      );
    } else if (currency === 'BRL' || currency === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 2,
          separator: ',',
          delimiter: '.',
        }),
      );
    }
  }

  useEffect(() => {
    if (digit && digit.length > 0) {
      setClassGoogle('value');
    } else {
      setClassGoogle('normal');
    }
  }, [digit, country]);

  function renderGoogle() {
    return (
      <Autocomplete
        value={digit}
        id="input-auto"
        style={{
          marginBottom: '20px',
          fontFamily: !digit ? fonts.latoRegularItalic : fonts.latoRegular,

          fontSize: 16,
        }}
        onChange={(event) => {
          setAdress(event.target.value);
          setDigit(event.target.value);
        }}
        onPlaceSelected={(place) => {
          setState('');
          setCity('');
          setAdress('');
          // console.log(place)
          setDigit(place.formatted_address);
          insert(place, 1);
        }}
        placeholder={'Buscar por cidade'}
        types={['(cities)']}
        className={classGoogle}
        componentRestrictions={{country: ['br', 'pt', 'us']}}
      />
    );
  }

  return (
    <>
      <div>
        <Dialog
          maxWidth={'lg' || 'md' || 'sm' || 'xl' || 'xs'}
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <InternDiv>
            <TitleModal>
              <TitlePage>Filtrar Anúncios</TitlePage>{' '}
              <CloseIcon
                onClick={() => handleClose()}
                className={'iconClose'}
              />
            </TitleModal>
            <ModalResponsive>
              <div className="mg-bottom-40 ">
                <TitleGrid>Filtrar por endereço</TitleGrid>
                {renderGoogle()}
              </div>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TitleGrid>Filtrar por usuário</TitleGrid>
                  {/* <InputText
                    value={user_name}
                    setValue={setUser_name}
                    label="Nome do Usuário"
                    placeHolder="Digite o nome do usuário"
                  /> */}
                  <UserAutoComplete
                    label="Nome do usuário"
                    value={user_name}
                    handleChange={setUser_name}
                    handleBlur={() => {}}
                    placeholder={'Pesquisar por nome ou e-mail...'}
                    setSuggestItem={{}}
                  />
                  <SelectI18n
                    value={property_type}
                    setValue={setProperty_type}
                    label={'Tipo do Imóvel'}
                    placeHolder={'Selecione o tipo de imóvel'}
                    I18n={I18n}
                    itens={itemEN}
                  />
                  {/* <InputText label="Tipo do imóvel" placeHolder="Digite o nome do usuário" /> */}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TitleGrid>Filtrar por faixa de preço</TitleGrid>
                  <div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <DivInputMetrics>
                        <InputPrice
                          inputMode="numeric"
                          mask={mask}
                          label="Valor mínimo"
                          placeHolder="Digite o valor"
                          value={min_price}
                          setValue={setMin_price}
                        />
                      </DivInputMetrics>
                      <DivSelectMetrics value={min_price}>
                        <DivText>
                          <Dimension
                            pdTop={17}
                            value={currency}
                            setValue={setCurrency}
                            itens={itensDimension}
                          />
                        </DivText>
                      </DivSelectMetrics>
                    </div>
                  </div>

                  <div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <DivInputMetrics>
                        <InputPrice
                          mask={mask}
                          label="Valor maximo"
                          placeHolder="Digite o valor"
                          value={max_price}
                          setValue={setMax_price}
                        />
                      </DivInputMetrics>
                      <DivSelectMetrics value={max_price}>
                        <DivText>
                          <Dimension
                            value={currency}
                            setValue={setCurrency}
                            itens={itensDimension}
                          />
                        </DivText>
                      </DivSelectMetrics>
                    </div>
                  </div>

                  {/* <DatePickers
                    selectedDate={created_date}
                    setSelectedDate={setCreated_data}
                    label="Data de criação"
                  /> */}
                </Grid>
              </Grid>
              <ButtonGreen
                onClick={(event) => {
                  search(event);
                  handleClose();
                }}>
                Filtrar Anúncios
              </ButtonGreen>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
