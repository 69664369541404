import React from 'react';
import styled, {css} from 'styled-components';
import colors from '../../UI/colors';
import fonts from '../../UI/fonts';
import metrics from '../../UI/metrics';

export default function InputNumberCount(props) {
  return (
    <div style={{position: 'relative'}}>
      <Rotule error={props.errors} valid={props.value}>
        {props.label} {props.isRequired && <Star>*</Star>}
      </Rotule>
      {props.value !== '' && props.strongLabel ? (
        <StrongLabel>{props.strongLabel}</StrongLabel>
      ) : null}
      <InputText
        placeholder={props.placeholder}
        error={props.errors}
        touched={props.touched}
        minLength={props.min ? props.min : null}
        value={props.value}
        onChange={(event) => props.handleChange(event)}
        onBlur={(event) => props.handleBlur(event)}
        strongLabel={props.strongLabel}
        pdLeft={
          props.value !== '' && props.strongLabel
            ? props.strongLabel.length * 10
            : 0
        }
      />
    </div>
  );
}

export const Rotule = styled.p`
  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: ${fonts.latoRegularBold};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const InputText = styled.input`
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  color: #1d1d1b;
  border: none;

  margin: 0px !important;
  font-family: ${fonts.latoRegular};

  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};
  padding-left: ${(props) => (props.pdLeft ? `${props.pdLeft}px` : 0)};
  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};

    font-size: 16px;
  }
`;

export const StrongLabel = styled.strong`
  content: ${(props) => props.strongLabel};
  position: absolute;
  color: #1d1d1b;
  font-family: ${fonts.latoBlack};
  font-size: 13px;
  letter-spacing: 1.2px;
  z-index: 10;
  top: 33px;
`;

export const Star = styled.span`
  color: #a88721;
  margin-left: 2px;
`;
