import React from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import logo from '../../../assets/Logotipo/permuti-logo-branco-color.svg';

import './styles.css';

import colors from '../../../UI/colors';

import {
  Title,
  ImageLogo,
  GoBack,
  FirstPart,
  NavBarContent,
  NavBar,
} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: colors.green_secondary,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  appbar: {
    backgroundColor: colors.green_secondary,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '80px',
    maxWidth: '1364px',

    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 5,
    paddingBottom: 5,
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    justifyItems: 'center',
    color: 'black',
    padding: 0,
  },
}));

export default function MenuProfile({title}) {
  const classes = useStyles();
  const history = useHistory();

  function goBack(event) {
    event.preventDefault();
    history.goBack();
    /*     window.location.href = '/dashboard/announcement'; */
  }

  function navigateToAds() {
    history.push('/announcement');
  }

  return (
    <>
      <NavBar>
        <AppBar className={classes.appbar} position="static">
          <Toolbar className={classes.toolbar}>
            <NavBarContent>
              <FirstPart>
                <GoBack onClick={(event) => goBack(event)}>
                  <ChevronLeftIcon />
                </GoBack>

                <ImageLogo onClick={navigateToAds} src={logo} />
              </FirstPart>
              <Title>{title}</Title>
            </NavBarContent>
          </Toolbar>
        </AppBar>
      </NavBar>
    </>
  );
}

MenuProfile.defaultProps = {
  title: 'Pré-Visualização do anúncio',
};
