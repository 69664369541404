import styled from 'styled-components';
import colors from '../../../../../../UI/colors';

export const Container = styled.div`
  margin-top: 30px;
`;

export const Subtitle = styled.div`
   color: ${colors.green_secondary};
   font-size: 16px;
   font-weight: bold;
   display: flex;
   justify-content: center;
   margin-top: 10px;
`

export const TitleHave = styled.p`
  color: #1D1D1B;
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
`

export const Renderization = styled.div`
  margin-top: 30px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap; 
  flex-direction: row; 
`

export const Image = styled.img`
 border-radius: 5px;
position: absolute;
 z-index: -1; 
 object-fit: cover;
 box-shadow: 0px 3px 6px #0000004D;
    @media (min-width: 481px) and (max-width: 2560px) {
      width:220px;
      height:220px;
     
    }
    @media (min-width: 1500px) and (max-width: 2560px) {
     width:220px;
     height:220px;
    }
    @media(max-width: 480px) {
      width:150px;
      height:150px;
    }

`

export const DivIcon = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  border-radius:100%;
  background-color: #000000;
  opacity: 0.7;
  margin: 10px 10px 0px 0px;
    &:hover{  
        background-color: red;
    }
    &:active{
        color: ${colors.white};
        background-color: red;
    }
  
   
`

export const DivIconEdit = styled(DivIcon)`
 
    &:hover{
         background-color: ${colors.green_secondary};
    }
    &:active{
        background-color: ${colors.green_secondary};
    }
`

export const ImageIcon = styled.img`
  width: 20px;
`


export const DivTrash = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
    @media (min-width: 481px) and (max-width: 2560px) {
      width:220px; 
      height:220px;
    }
    @media (min-width: 1500px) and (max-width: 2560px) {
      width:220px; 
      height:220px;
    }
    @media(max-width: 480px) {
      width:150px; 
      height:150px;
    }
`

export const DivIconMove = styled(DivIcon)`
  margin-left: 5px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
    &:hover{
         background-color: ${colors.green_secondary};
    }
    &:active{
        background-color: ${colors.green_secondary};
    }
`



