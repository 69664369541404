import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dialog, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, {useState} from 'react';
import {ButtonsGreenSend} from '../ModalJustify/styles';
import {
  AdvertTitle,
  DivConfirm,
  DivDanger,
  InternDiv,
  Message,
  ModalContainer,
  ModalResponsive,
  TitleModal,
} from './styles';
import './styles.css';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalPayMentLink({open, setOpen, text, setText}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setText('');
  };

  /* async function deleteUser() {
    handleDelete(id);
    handleClose();
  } */

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle>Atenção</AdvertTitle>
              <Message>
                Segue link para pagamento do plano escolhido para esta
                imobiliária:
              </Message>
              <Message style={{marginTop: '10px'}}>{text}</Message>
              <DivConfirm>
                <ButtonsGreenSend onClick={() => handleClose()}>
                  FECHAR
                </ButtonsGreenSend>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
