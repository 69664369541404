import styled from 'styled-components';
import colors from '../../../UI/colors';
import fonts from '../../../UI/fonts';

export const Container = styled.div``;

export const ButtonFilter = styled.button`
  height: 50px;
  display: flex;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;

  /* padding: 0px 20px 0px 20px; */
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  font-size: 16px;

  font-family: ${(props) =>
    props.hasChoose ? fonts.latoRegular : fonts.latoRegularItalic};
  color: ${colors.dark};

  svg {
    transition: transform 0.2s linear;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  outline: 0 !important;
`;
