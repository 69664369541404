import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

/* &paginate=true&page=1&limit=5 */
const getSubscribers = (value) =>
  apiBackend.get(`/v1/users-payment?term=${value?.trim()}`, {
    headers: {
      Authorization: `Bearer ${desencrypta(
        localStorage.getItem('@User_informations'),
      )}`,
    },
  });
const searchAPIDebounced = AwesomeDebouncePromise(getSubscribers, 600);

export default function UserAutoComplete({
  setSuggestItem,
  placeholder = '',
  value = '',
  handleChange,
  handleBlur,
  label,
}) {
  const [suggestions, setSuggestions] = useState([]);

  const onChange = (event, {newValue}) => {
    handleChange(newValue);
  };

  const onBlur = (event) => {
    handleBlur(event.target.value);
  };

  const getSuggestionValue = (suggestion) => {
    setSuggestItem(suggestion);

    return `${suggestion?.name}${
      suggestion?.surname ? ` ${suggestion?.surname}` : ''
    }`;
  };

  const renderSuggestion = (suggestion) => (
    <SuggestItem>
      <div>
        <img
          src={
            suggestion?.avatar?.avatar_url || 'https://i.imgur.com/WGUUmeX.jpg'
          }
          alt="||"
        />
      </div>
      <p>
        {suggestion?.name} {suggestion?.surname ?? ''}
        <span>{suggestion?.email}</span>
      </p>
    </SuggestItem>
  );

  const onSuggestionsFetchRequested = async ({value}) => {
    const newArray = await searchAPIDebounced(value);

    setSuggestions(newArray?.data);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const shouldRenderSuggestions = (value) => {
    return value?.trim()?.length >= 0;
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    onBlur,
  };

  return (
    <div>
      <Label color={value}>{label} </Label>
      <Container>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          highlightFirstSuggestion={true}
          shouldRenderSuggestions={shouldRenderSuggestions}
        />
      </Container>
    </div>
  );
}

UserAutoComplete.propTypes = {
  setSuggestItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
};

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 16px;
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;

export const Container = styled.div`
  /*   font-weight: bold; */
  font-family: 'Lato Regular', sans-serif;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 18px;

  .react-autosuggest__container {
    position: relative;
    width: 100%;
  }

  .react-autosuggest__input {
    background: ${colors.white};

    color: ${colors.dark};
    opacity: 1;
    text-align: left;
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #808076 !important;
    border-radius: 0;
    outline: 0;
    font-size: 16px;
    font-family: 'Lato Regular';

    ::placeholder {
      color: #808076;
      font-family: 'Lato Regular Italic';
      font-style: italic;
    }
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 52px;
    width: 100%;
    padding: 10px 5px;
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000033;
    border-radius: 5px;

    font-family: 'Lato Regular', sans-serif;
    font-size: 16px;
    z-index: 25;

    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
      background: ${colors.white};
    }
    ::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 4px;
      background: ${colors.green_secondary};
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    background: none;
    border: none;
    box-shadow: none;
    height: 100%;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 5px;
    background: none;
    border: none;
    box-shadow: none;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${colors.green_secondary};
    color: #fff;
  }
`;

export const SuggestItem = styled.div`
  position: relative;
  display: flex;

  > p {
    display: flex;
    flex-direction: column;
    color: inherit;
    margin-bottom: 0;
    font-family: 'Lato Regular';

    > span {
      font-size: 12px;
      color: inherit;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      line-height: 14px;
      font-family: 'Lato Regular Italic';
      text-overflow: ellipsis;
    }
  }
  > div {
    flex: none;
    overflow: hidden;
    margin-right: 5px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000033;

    background: #333;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
