import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {toMoney} from 'vanilla-masker';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~utils';
import ModalEditSucess from '../../../../../../components/ModalEditSucess';
import Dimension from '../../Components/dimension';
import InputTextRequireSelect from '../../Components/InputRequireSelect';
import InputText from '../../Components/inputText';
import MandatoryField from '../../Components/mandatoryField';
import NextPreview from '../../Components/nextPreview';
import {MessageInputs} from '../../DesiredProfile/ModalDelete/styles';
import {TitlePage} from '../../Photo/styles';
import {SaveAnnoucement} from '../../Step/styles';
/* import addMsgAction from '~redux/action/action_add_user'; */
import {
  requiredFarm,
  requiredTotalArea,
  viewStyleInput,
} from '../../Verifications';
import {
  DivInputMetrics,
  DivMetrics,
  DivNext,
  DivSelectMetrics,
  DivText,
} from './styles';

import * as Sentry from '@sentry/react';

const itensDimension = ['m²', 'sqft'];

function PropertyThree({
  id,
  step,
  setStep,
  dimension,
  setDimension,
  constructArea,
  setConstructArea,
  totalArea,
  setTotalArea,
  country,
  type,
  typeEn,
}) {
  const [active, setActive] = useState(false);
  const view = useSelector((state) => state.step.data);
  const viewSwitch = viewStyleInput(type['name_en']);
  const requireFarm = requiredFarm(type['name_en']);
  const requireTotalArea = requiredTotalArea(type['name_en']);
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/advertise`,
    state: {fromDashboard: true},
  });
  const [spaceBuilding, setSpaceBuilding] = useState(false);
  const [spaceTotal, setSpaceTotal] = useState(false);

  const [openEditSucess, setOpenEditSucess] = useState(false);

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  async function updatePropertyThree() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .put(
        `/v1/property/${id}/edit`,
        {
          total_area: convert(totalArea),
          living_area: convert(constructArea),

          area_unit_of_measurement: dimension,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        // dispatch(addMsgAction(true));
        setOpenEditSucess(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  useEffect(() => {
    if (viewSwitch === false) {
      if (requireTotalArea) {
        setActive(true);
      } else if (totalArea) setActive(true);
    } else if (requireFarm === false && requireTotalArea === false) {
      if (constructArea && totalArea) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else if (requireFarm === false && requireTotalArea) {
      if (constructArea) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else if (totalArea) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [constructArea, totalArea]);

  function mask(e, setValue) {
    const float = e.target.value.replace(/\D/g, '');
    if (country === 'United States') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (country === 'Brazil' || country === 'Portugal') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function transformArea(value) {
    const data = value;
    if (country === 'Brazil' || country === 'Portugal') {
      const p1 = data.replace('.', '');
      /*  const p2 = p1.replace(',', '.'); */
      const transform = parseFloat(p1);
      return transform;
    }
    const p1 = data.replace(',', '');
    const transform = parseFloat(p1);
    return transform;
  }

  function convert(value) {
    const data = transformArea(value);
    return data;
  }
  function handleProx(event) {
    updatePropertyThree();
    event.preventDefault();
  }

  function spaceDimensionBuilding(value) {
    console.log(value);
    if (value) setSpaceBuilding(true);
    else setSpaceBuilding(false);
  }

  function spaceDimensionTotal(value) {
    if (value) setSpaceTotal(true);
    else setSpaceTotal(false);
  }

  function maskChange(e, setValue) {
    const float = e;
    if (country === 'United States') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (country === 'Brazil' || country === 'Portugal') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  useEffect(() => {
    if (constructArea) {
      maskChange(constructArea, setConstructArea);
    }
    if (totalArea) {
      maskChange(totalArea, setTotalArea);
    }
  }, [dimension]);

  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="StylesDefiniedThree.title" />
          </TitlePage>
          <MandatoryField />

          {viewSwitch ? (
            <>
              <DivMetrics value={constructArea}>
                {requireFarm ? (
                  <DivInputMetrics>
                    <InputText
                      noBorder
                      mask={mask}
                      placeHolder={I18n.getTranslation(
                        location,
                        'StylesDefiniedThree.placeHolderConstructArea',
                      )}
                      label={
                        <I18n t="StylesDefiniedThree.labelConstructArea" />
                      }
                      value={constructArea}
                      setValue={setConstructArea}
                    />
                  </DivInputMetrics>
                ) : (
                  <DivInputMetrics>
                    <InputTextRequireSelect
                      noBorder
                      func={spaceDimensionBuilding}
                      mask={mask}
                      placeHolder={I18n.getTranslation(
                        location,
                        'StylesDefiniedThree.placeHolderConstructArea',
                      )}
                      label={
                        <I18n t="StylesDefiniedThree.labelConstructArea" />
                      }
                      value={constructArea}
                      setValue={setConstructArea}
                    />
                  </DivInputMetrics>
                )}

                <DivSelectMetrics noBorder value={constructArea}>
                  <DivText>
                    <Dimension
                      value={dimension}
                      setValue={setDimension}
                      itens={itensDimension}
                    />
                  </DivText>
                </DivSelectMetrics>
              </DivMetrics>

              {spaceBuilding ? (
                <MessageInputs>
                  <I18n t="RequiredField.message" />
                </MessageInputs>
              ) : null}
            </>
          ) : null}

          {requireTotalArea ? (
            <DivMetrics value={totalArea} className="mg-top-30">
              <DivInputMetrics>
                <InputText
                  noBorder
                  mask={mask}
                  placeHolder={I18n.getTranslation(
                    location,
                    'StylesDefiniedThree.placeHolderTotalArea',
                  )}
                  label={<I18n t="StylesDefiniedThree.labelTotalArea" />}
                  value={totalArea}
                  setValue={setTotalArea}
                />
              </DivInputMetrics>
              <DivSelectMetrics noBorder value={totalArea}>
                <DivText>
                  <Dimension
                    value={dimension}
                    setValue={setDimension}
                    itens={itensDimension}
                  />
                </DivText>
              </DivSelectMetrics>
            </DivMetrics>
          ) : (
            <>
              <DivMetrics value={totalArea} className="mg-top-30">
                <DivInputMetrics>
                  <InputTextRequireSelect
                    noBorder
                    func={spaceDimensionTotal}
                    mask={mask}
                    placeHolder={I18n.getTranslation(
                      location,
                      'StylesDefiniedThree.placeHolderTotalArea',
                    )}
                    label={<I18n t="StylesDefiniedThree.labelTotalArea" />}
                    value={totalArea}
                    setValue={setTotalArea}
                  />
                </DivInputMetrics>
                <DivSelectMetrics noBorder value={totalArea}>
                  <DivText>
                    <Dimension
                      value={dimension}
                      setValue={setDimension}
                      itens={itensDimension}
                    />
                  </DivText>
                </DivSelectMetrics>
              </DivMetrics>
              {spaceTotal ? (
                <MessageInputs>
                  <I18n t="RequiredField.message" />
                </MessageInputs>
              ) : null}
            </>
          )}
        </div>
        <DivNext>
          {/* {viewSwitch ? (
            <NextPre
              step={step}
              setStep={setStep}
              active={active}
              handleSubmit={handleProx}
            />
          ) : (
            <NextPrevNumber
              number={2}
              step={step}
              setStep={setStep}
              active={active}
              handleSubmit={handleProx}
            />
          )} */}

          {console.log('steps', step)}
          <NextPreview
            flag={false}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
        <ModalEditSucess
          func={() => {
            window.history.back();
          }}
          open={openEditSucess}
          setOpen={setOpenEditSucess}
        />
      </div>
    </>
  );
}

export default PropertyThree;
