import React, {useState, useEffect} from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';

import styled from 'styled-components';
import I18n from '~/i18n/i18';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import {Star} from './styles';

const useStyles = makeStyles({
  root: {
    /* marginTop: '60px', */
  },
  item: {
    fontFamily: fonts.latoRegular,
    width: '100%',
    /* marginTop: '10px', */
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    fontFamily: fonts.latoRegular,
    width: '100%',
    /* marginTop: '10px', */
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});

export default function SelectMenuRequiredI18n(props, {i18n}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [choose, setChoose] = useState(props.value);

  const [viewMesssage, setViewMessage] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClickChoose(item) {
    setAnchorEl(null);

    props.setValue(item);
    setViewMessage(false);
  }

  function closed() {
    setAnchorEl(null);

    if (props.value) {
      setViewMessage(false);
    } else {
      setViewMessage(true);
    }
  }

  useEffect(() => {
    props.value
      ? setChoose(<I18n t={`${props.i18n}.${props.value}`} />)
      : setChoose(props.value);
  }, [props.value]);

  const ITEM_HEIGHT = 48;
  const buttonRef = React.useRef();

  return (
    <div style={{minWidth: '200px', marginBottom: '20px'}}>
      <Label color={choose}>
        {props.label}
        <Star>*</Star>
      </Label>
      <ButtonFilter
        ref={buttonRef}
        disabled={props.disable}
        type="button"
        itens={props.itens}
        color={choose}
        onClick={handleClick}
        aria-controls="selectmenurequiredi18n"
        aria-haspopup="true">
        {choose || props.placeHolder}{' '}
        {anchorEl ? <ExpandLess /> : <ExpandMore />}
      </ButtonFilter>
      <Menu
        className={classes.root}
        id="selectmenurequiredi18n"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          closed();
        }}
        PaperProps={{
          style: {
            width: buttonRef.current ? buttonRef.current.clientWidth : '300px',
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}>
        {props.itens.map((item, key) => (
          <MenuItem
            key={key}
            className={classes.item}
            onClick={() => handleClickChoose(item)}>
            <I18n t={`${props.i18n}.${item}`} />
          </MenuItem>
        ))}
      </Menu>
      {viewMesssage ? (
        <Message>
          <I18n t="RequiredField.message" />
        </Message>
      ) : null}
    </div>
  );
}

const ButtonFilter = styled.button`
  height: 50px;
  text-align: left;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.color
      ? `2px solid ${colors.green_secondary}!important`
      : '1px solid #808076!important'};
  color: ${(props) => (props.color ? '#1D1D1B' : '#808076')};
  width: 100%;
  font-family: ${fonts.latoRegular};
  font-size: 16px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus {
    outline: 0;
  }
  margin-bottom: 10px;
  padding-left: 0;

  /* @media (min-width: 781px) and (max-width: 2560px) {
    max-width: 380px;
              
    }
    @media(max-width: 480px) {
        max-width: 140px;
    } */
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;

const Message = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 13px;
  color: red;
`;
