import apiBackend from '../../services/apiaxios';
import {desencrypta} from '../../utils';

import * as Sentry from '@sentry/react';

const all = {
  category_en: 'Residential',
  category_es: 'Residencial',
  category_pt: 'Residencial',
  created_at: '2021-03-16T18:08:09.328Z',
  id: '',
  name_en: 'All',
  name_es: 'Todos',
  name_pt: 'Todos',
  updated_at: '2021-03-16T18:08:09.328Z',
};

/* 
async function getTypeProperties(setItens) {
  let token = desencrypta(localStorage.getItem('@User_informations'));
  apiBackend
    .get('/v1/type_property', {headers: {Authorization: `Bearer ${token}`}})
    .then((response) => {
      console.log('aqui', response);
      //   return response.data.type_property;
      setItens(response.data.type_property);
    })
    .catch((e) => {
      console.log(e.response);
    });
} */

async function getTypeProperties(setItens, flag) {
  apiBackend
    .get('/v1/type_property')
    .then((response) => {
      console.log('aqui', response);
      //   return response.data.type_property;

      if (flag === true) {
        let aux = [...response.data.type_property];
        aux.unshift(all);
        setItens(aux);
      } else {
        setItens(response.data.type_property);
      }
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}

export default getTypeProperties;
