import React, {useState, useEffect} from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';

import styled from 'styled-components';
import I18n from '~/i18n/i18';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

import * as S from '../../Register/styles';

const useStyles = makeStyles({
  root: {
    /* marginTop: '60px', */
  },
  item: {
    fontFamily: fonts.latoRegular,
    width: '100%',
    /* marginTop: '10px', */
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_secondary,
    },
  },
  itemBottom: {
    fontFamily: fonts.latoRegular,
    width: '100%',
    /* marginTop: '10px', */
    color: colors.green_secondary,
    '&:hover': {
      backgroundColor: colors.green_primary,
      color: colors.green_third,
    },
  },
});

export default function SelectCustom({
  i18n,
  label,
  value,
  setValue,
  items,
  disabled,
  placeholder,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [choose, setChoose] = useState(value);

  const [viewMessage, setViewMessage] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClickChoose(item) {
    setAnchorEl(null);

    setValue(item);
    setViewMessage(false);
  }

  function closed() {
    setAnchorEl(null);

    if (value) {
      setViewMessage(false);
    } else {
      setViewMessage(true);
    }
  }

  useEffect(() => {
    value ? setChoose(<I18n t={`${i18n}.${value}`} />) : setChoose(value);
  }, [value]);

  const ITEM_HEIGHT = 48;
  const buttonRef = React.useRef();

  return (
    <S.DivInputText>
      <Label color={value}>{label} *</Label>
      <ButtonFilter
        ref={buttonRef}
        disabled={disabled}
        type="button"
        itens={items}
        value={value}
        error={viewMessage}
        onClick={handleClick}
        aria-controls="selectmenurequiredi18n"
        aria-haspopup="true">
        {choose || placeholder} {anchorEl ? <ExpandLess /> : <ExpandMore />}
      </ButtonFilter>
      <Menu
        className={classes.root}
        id="selectmenurequiredi18n"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          closed();
        }}
        PaperProps={{
          style: {
            width: buttonRef.current ? buttonRef.current.clientWidth : '300px',
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}>
        {items?.map((item, key) => (
          <MenuItem
            key={key}
            className={classes.item}
            onClick={() => handleClickChoose(item)}>
            <I18n t={`${i18n}.${item}`} />
          </MenuItem>
        ))}
      </Menu>
      {viewMessage ? (
        <S.DivError>
          <S.ErrorMessage>
            <I18n t="RequiredField.message" />
          </S.ErrorMessage>
        </S.DivError>
      ) : null}
    </S.DivInputText>
  );
}

const ButtonFilter = styled.button`
  padding-left: 0;
  margin: 0px !important;

  width: 100% !important;
  height: 40px;

  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  background-color: ${colors.white};
  outline: 0;

  font-family: ${fonts.latoRegular};
  text-align: left;
  font-size: 16px;

  border-bottom: ${(props) =>
    props.error
      ? `2px solid ${colors.danger_dark}`
      : props.value
      ? `2px solid ${colors.green_secondary}`
      : `1px solid ${colors.dark_light}`}!important;

  font-family: ${(props) =>
    props.value ? fonts.latoRegular : fonts.latoRegularItalic};
  color: ${(props) => (props.value ? colors.dark : colors.dark_light)};
`;

const Label = styled.div`
  font-family: ${fonts.latoRegularBold};
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;

const Message = styled.div`
  font-family: ${fonts.latoRegularBold};
  font-size: 13px;
  color: red;
`;
