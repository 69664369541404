import React, {useEffect, useState} from 'react';
import {toMoney} from 'vanilla-masker';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import ModalEditSucess from '../../../../../components/ModalEditSucess';
import NextPreview from '../Components/nextPreview';
import {ButtonAdd} from '../Details/Components/style';
import {TitlePage} from '../Photo/styles';
import {SaveAnnoucement} from '../Step/styles';
import {Warning} from '../Type/styles';
import Accordion from './Accordion';
import ModalDesired from './ModalDesired';
import {DivNext} from './styles';

import * as Sentry from '@sentry/react';

function DesiredProfile({
  id,
  step,
  setStep,
  setOpenFinish,
  setStepVisual,
  stepVisual,
  itensBackend,
}) {
  const [active, setActive] = useState(true);
  const [goNext, setGoNext] = useState(false);
  const [open, setOpen] = useState(false);
  const [property, setProperty] = useState([]);
  const [openEditSucess, setOpenEditSucess] = useState(false);

  async function updateDesiredProfile() {
    setActive(false);
    next();
  }

  useEffect(() => {
    if (goNext) {
      setTimeout(() => {
        setOpenEditSucess(true);
      }, 1000);
    }
  }, [stepVisual]);

  function next() {
    setGoNext(true);
    setStepVisual(100);
  }

  async function getProperty() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    // setActive(true);

    apiBackend
      .get(`/v1/property/${id}/intention`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        setProperty(response.data.intentions);
        if (response.data.intentions && response.data.intentions.length > 0) {
          setActive(true);
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  /* function mask(e, choseMetric) {
    const float = e;
    if (choseMetric === 'USD') {
      return toMoney(float, {
        precision: 2,
        separator: '.',
        delimiter: ',',
      });
    }
    if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      return toMoney(float, {
        precision: 2,
        separator: ',',
        delimiter: '.',
      });
    }
    return toMoney(float, {
      precision: 2,
      separator: '.',
      delimiter: ',',
    });
  } */
  // TODO verificar se alteração funcionou, troquei a mask acima por essa
  function mask(e, choseMetric) {
    const float = e;
    if (choseMetric === 'USD') {
      return toMoney(float, {
        precision: 0,
        separator: '',
        delimiter: ',',
      });
    }
    if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      return toMoney(float, {
        precision: 0,
        separator: '',
        delimiter: '.',
      });
    }
    return toMoney(float, {
      precision: 0,
      separator: '',
      delimiter: ',',
    });
  }

  useEffect(() => {
    getProperty();
  }, []);

  function handleProx(event) {
    event.preventDefault();
    console.log('foi');
    updateDesiredProfile();
  }

  return (
    <>
      <SaveAnnoucement>
        <I18n t="MessageRedux.save" />
      </SaveAnnoucement>
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="DesiredProperty.Title" />
          </TitlePage>
          <Warning>
            <I18n t="DesiredProperty.SubTitle" />
          </Warning>
          <div
            style={{
              fontFamily: 'Lato Regular',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            {property.length + ' / 3'}
          </div>

          {property &&
            property.map((item, key) => (
              <div key={key} className="mg-top-20">
                <Accordion
                  idAds={id}
                  mask={mask}
                  getProperty={getProperty}
                  itensBackend={itensBackend}
                  info={item}
                  currency={item?.currency}
                  id={item.id}
                  length={key + 1}
                  type={item.property_type}
                  country={item.country}
                  city={item.city}
                  state={item.state}
                  valueMin={
                    item.min_price
                      ? mask(parseInt(item.min_price), item.currency)
                      : ''
                  }
                  valueMax={
                    item.max_price
                      ? mask(parseInt(item.max_price), item.currency)
                      : ''
                  }
                />
              </div>
            ))}

          {property && property.length < 3 && (
            <div style={{marginTop: '50px'}}>
              <ButtonAdd
                onClick={() => {
                  setOpen(true);
                }}
                type="button">
                + <I18n t="DesiredProperty.AddAds" />
              </ButtonAdd>
              {/* <ButtonAdd onClick={() => { setOpen(true) }} type="button" >+ Adicionar um imóvel</ButtonAdd> */}
            </div>
          )}
        </div>

        <DivNext>
          <NextPreview
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>
      <ModalDesired
        getProperty={getProperty}
        open={open}
        setOpen={setOpen}
        id={id}
        itensBackend={itensBackend}
      />

      <ModalEditSucess
        func={() => {
          window.history.back();
        }}
        open={openEditSucess}
        setOpen={setOpenEditSucess}
      />
    </>
  );
}

export default DesiredProfile;
