import React, {useState} from 'react';
import ReactLoading from 'react-loading';
import {Link} from 'react-router-dom';
import DashBoard from '../../components/MenuPassword';
import * as Sentry from '@sentry/react';

import apiBackend from '../../services/apiaxios';
import colors from '../../UI/colors';

import {
  Rotule,
  DivInputText,
  InputText,
  DivError,
  ErrorMessage,
} from '../User/Clients/Edit/ModalDataPersonal/styles';
import * as validate from '~/utils/regex';
import {fireAction} from '~/utils/swal';
import * as S from './styles';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  /* ------------------------------ */
  /* Email */
  const handleChangeEmail = (value) => {
    setEmail(value);

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value)
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  const handleBlurEmail = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        email: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        email: !value.trim()
          ? 'Campo não preenchido'
          : !validate.emailReg.test(value)
          ? 'E-mail inválido'
          : '',
      };
    });
  };

  /* ----------------- */

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);

      const {data} = await apiBackend.post(`/v1/login/forgot`, {
        email: email.trim(),
      });

      console.log(data);

      fireAction(
        'Tudo certo',
        'Sua solicitação foi concluída e um link de recuperação de conta foi enviado para o e-mail informado.',
        'success',
      );
    } catch (err) {
      /*      console.log(err); */
      if (err.response.status === 422) {
        fireAction(
          'Não foi possível completar sua solicitação',
          'O e-mail informado é inválido, não existe ou não está associado a uma conta Permuti. Por favor, tente outro e-mail.',
          'error',
        );
      } else {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante sua requisição. Por favor, tente novamente mais tarde.',
          'error',
        );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <DashBoard />
      <S.Container>
        <S.DivTitle>
          <S.PageTitle>Esqueci minha senha</S.PageTitle>
          <em>
            Informe seu e-mail para enviarmos o link de alteração de senha.
          </em>
        </S.DivTitle>
        {/*  */}
        <S.InternDiv>
          <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <div>
              <DivInputText>
                <Rotule error={errors.email} valid={email}>
                  E-mail *
                </Rotule>
                <InputText
                  error={errors.email}
                  touched={touched.email}
                  value={email}
                  onChange={(event) => handleChangeEmail(event.target.value)}
                  onBlur={(event) => handleBlurEmail(event.target.value)}
                  placeholder="Qual o seu e-mail?"
                />
                {errors.email && (
                  <DivError>
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>
            </div>

            <S.ButtonSecondary
              disabled={
                !email.trim() || !validate.emailReg.test(email) || loading
              }
              type="submit"
              style={{marginTop: '30px', maxWidth: '300px', width: '100%'}}>
              {loading ? (
                <ReactLoading
                  type="spin"
                  color={colors.green_secondary}
                  width="30px"
                  height="30px"
                />
              ) : (
                'Enviar'
              )}
            </S.ButtonSecondary>

            <S.LinkContainer mt="20px">
              Já possui uma conta?
              <Link style={{textDecoration: 'none'}} to="/">
                <S.Terms>Iniciar sessão</S.Terms>
              </Link>
            </S.LinkContainer>
          </form>
        </S.InternDiv>
      </S.Container>
    </>
  );
}

export default ForgotPassword;
