import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
  IconContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
    borderRadius: 15,
  },
}));

export default function ModalConfirmSend({
  handleSubmit,
  isSubmitting,
  open,
  setOpen,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen();
  };

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <IconContainer>
            <CloseIcon onClick={handleClose} className={'iconClose'} />
          </IconContainer>
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle>Atenção</AdvertTitle>
              <Message>
                Depois de enviado, a ação não poderá ser desfeita. Você confirma
                o <b>envio</b> desta notificação?
              </Message>
              <DivConfirm>
                <ButtonsWhite disabled={isSubmitting} onClick={handleClose}>
                  NÃO
                </ButtonsWhite>
                <ButtonsWhite
                  primary
                  disabled={isSubmitting}
                  onClick={handleSubmit}>
                  SIM
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
