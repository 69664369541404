import styled from 'styled-components';
import colors from '../../../../../../UI/colors';

export const Container = styled.div`
   width: 400px;
   height: 640px;
  
   @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: 'Lato';
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 20px 30px 20px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  padding: 20px;
  font-size: 24px;
  color: #1D1D1B;
  font-style: italic;
  font-weight: bold;
  font-family: 'Lato';
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const TitleModalGreen = styled(TitleModal)`
  color: ${colors.green_secondary};
  margin-bottom: 30px;
`


export const DivInputText = styled.div`
 width: 100%;
 margin-bottom: 20px;
`

export const NumberBall = styled.div`
  background-color: ${colors.green_secondary};
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: none;
  margin-right: 10px;
  display: flex;
  color: ${colors.white};
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
`

export const DivTip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const TextTip = styled.div`
  color: #1D1D1B;
  font-size: 18px;
  text-align: justify;
`