import React from 'react';
import {
  LabelLead,
  LabelAttended,
  LabelSold,
  LabelNoSold,
  LabelExpired,
  LabelPublish,
  LabelAll,
} from '../styles';

import {Container} from './styles';

export default function Filters({status, setStatus}) {
  return (
    <>
      <hr />
      <Container>
        <LabelLead
          status={status === 'lead'}
          onClick={() => {
            setStatus('lead');
          }}>
          Lead
        </LabelLead>
        <LabelNoSold
          status={status === 'noSold'}
          onClick={() => {
            setStatus('noSold');
          }}>
          Não Vendido
        </LabelNoSold>
        <LabelAttended
          status={status === 2}
          onClick={() => {
            setStatus(2);
          }}>
          Atendido
        </LabelAttended>
        <LabelSold
          status={status === 1}
          onClick={() => {
            setStatus(1);
          }}>
          Vendido
        </LabelSold>
        <LabelPublish
          status={status === 3}
          onClick={() => {
            setStatus(3);
          }}>
          Publicado
        </LabelPublish>
        <LabelExpired
          status={status === 4}
          onClick={() => {
            setStatus(4);
          }}>
          Inativo
        </LabelExpired>
        <LabelAll
          status={status === 'all'}
          onClick={() => {
            setStatus('all');
          }}>
          Todos
        </LabelAll>
      </Container>
      <hr />
    </>
  );
}
