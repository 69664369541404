import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';

import App from './App';
import {BrowserRouter} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux';
import store from './redux/store';

Sentry.init({
  dsn: 'https://e0770255a9e64ee79ec1cc381a21e086@o1169521.ingest.sentry.io/6269145',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.Fragment>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root'),
);
