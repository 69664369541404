import * as Sentry from '@sentry/react';

const {toMoney} = require('vanilla-masker');
const {default: apiBackend} = require('~/services/apiaxios');
const {desencrypta} = require('~/utils');

export function getProperty(
  id,

  setSelectValueCountry,
  setCity,
  setState,
  setCep,
  setNeighborhood,
  setNumb,
  setAdreess,
  setLatitude,
  setLongitude,
  setFloor,
  setNApartment,
  setStreetSufix,
  // PropertyFeaturesOne
  setTypeStyle,
  setTypeView,
  setCode,
  setLoft,
  setRoof,
  // PropertyFeaturesTwo
  setTypeGarage,
  setDormitories,
  setSuites,
  setBathrooms,
  setWashrooms,
  setPaving,
  setVacancies,
  // PropertyFeaturesThree
  setDimension,
  setConstructArea,
  setTotalArea,
  // Financial
  setPrice,
  setMonthly_maintenance_fee,
  setMonthly_tax_fee,
  setMetricPrice,
  setMetricTax,
  setChoseMetric,
  // Rent
  setChoseMetricRent,
  setRental,
  setRental_price,
  // Status
  setYear_build,
  setProperty_phase,
  setFurnished,
  setAppliances,
  setDecorated,

  // Details Extern
  setOptionsExtern,
  setOptionsAllExtern,
  // Details Intern
  setOptionsIntern,
  setOptionsAllIntern,
  setStep,
  step,
  digit,
  setDigit,
  file,
  setFile,
  setOptionsInternDelete,
  setOptionsExternDelete,
) {
  console.log(id);
  let token = desencrypta(localStorage.getItem('@User_informations'));
  apiBackend
    .get(`/v1/property/${id}`, {
      headers: {Authorization: `Bearer ${token}`},
    })
    .then((response) => {
      //   console.log(response);
      //   console.log('Tipo', typeof s;

      if (response.data.property.address) {
        console.log('chamou o país', response.data.property.address.country);
        setSelectValueCountry(
          response.data.property.address.country
            ? response.data.property.address.country
            : 'Brazil',
        );
        // response.data.property.address.country &&
        //   setSelectValueCountry(response.data.property.address.country);
        setCity(
          response.data.property.address.city
            ? response.data.property.address.city
            : '',
        );
        setState(
          response.data.property.address.state
            ? response.data.property.address.state
            : '',
        );
        setCep(
          response.data.property.address.zip_code
            ? response.data.property.address.zip_code
            : '',
        );
        setNeighborhood(
          response.data.property.address.neighborhood
            ? response.data.property.address.neighborhood
            : '',
        );
        setNumb(
          response.data.property.address.street_number
            ? response.data.property.address.street_number
            : '',
        );

        setAdreess(
          response.data.property.address.street_name
            ? response.data.property.address.street_name
            : '',
        );
        setDigit(
          response.data.property.address.street_name
            ? response.data.property.address.street_name
            : '',
        );
        setFloor(
          response.data.property.address.floor
            ? response.data.property.address.floor
            : '',
        );
        setStreetSufix(
          response.data.property.address.street_suffix
            ? response.data.property.address.street_suffix
            : '',
        );
      }

      setTypeStyle(
        response.data.property.style ? response.data.property.style : '',
      );

      setTypeView(
        response.data.property.waterfront
          ? response.data.property.waterfront
          : '',
      );
      // // setCode(item.mls_number ? item.mls_number : '');
      setCode(
        response.data.property.mls_number
          ? response.data.property.mls_number
          : '',
      );
      setLoft(response.data.property.loft ? response.data.property.loft : '');
      setRoof(
        response.data.property.penthouse
          ? response.data.property.penthouse
          : '',
      );
      setTypeGarage(
        response.data.property.garage_type
          ? response.data.property.garage_type
          : '',
      );
      setDormitories(
        response.data.property.bedrooms ? response.data.property.bedrooms : '',
      );
      setSuites(
        response.data.property.suites
          ? response.data.property.suites.toString()
          : '0',
      );
      setBathrooms(
        response.data.property.full_bathrooms
          ? response.data.property.full_bathrooms.toString()
          : '0',
      );
      setWashrooms(
        response.data.property.half_bathrooms
          ? response.data.property.half_bathrooms.toString()
          : '0',
      );
      setPaving(
        response.data.property.stories
          ? response.data.property.stories.toString()
          : '0',
      );
      setVacancies(
        response.data.property.parking_spaces
          ? response.data.property.parking_spaces.toString()
          : '0',
      );
      setConstructArea(
        response.data.property.living_area
          ? response.data.property.living_area.toString()
          : '',
      );
      setTotalArea(
        response.data.property.total_area
          ? response.data.property.total_area.toString()
          : '',
      );
      loadFinancial(
        response.data.property,
        setMonthly_tax_fee,
        setMonthly_maintenance_fee,
        setPrice,
        setChoseMetric,
        setRental,
        setRental_price,
        setChoseMetricRent,
      );
      setYear_build(
        response.data.property.year_build
          ? response.data.property.year_build
          : '',
      );
      setProperty_phase(
        response.data.property.property_phase
          ? response.data.property.property_phase
          : false,
      );
      setFurnished(
        response.data.property.furnished
          ? response.data.property.furnished
          : false,
      );
      setAppliances(
        response.data.property.appliances
          ? response.data.property.appliances
          : false,
      );
      setDecorated(
        response.data.property.decorated
          ? response.data.property.decorated
          : false,
      );
      // getAllImages(response.data.property.id);
      // // // setFile(item.photos);

      response.data.property.internalProperty.length > 0 &&
        setOptionsIntern(
          loadItensDetails(response.data.property.internalProperty),
        );

      // response.data.internal_features &&
      //   setOptionsInternDelete(
      //     loadItensDetails(response.data.internal_features),
      //   );

      response.data.property.externalProperty.length &&
        setOptionsExtern(
          loadItensDetails(response.data.property.externalProperty),
        );

      // response.data.external_features &&
      //   setOptionsExternDelete(
      //     loadItensDetails(response.data.external_features),
      //   );

      setDimension(
        response.data.property.area_unit_of_measurement != null
          ? response.data.property.area_unit_of_measurement
          : 'sqft',
      );
    })
    .catch((e) => {
      Sentry.captureException(e);
    });
}

function loadItensDetails(item) {
  console.log('chamou');
  return item.map((item) => {
    return {...item, choose: true};
  });
}

export function loadFinancial(
  item,
  setMonthly_tax_fee,
  setMonthly_maintenance_fee,
  setPrice,
  setChoseMetric,
  setRental,
  setRental_price,
  setChoseMetricRent,
) {
  setPrice(item.price ? maskMoneyEdit(item.price, item.price_currency) : '');
  setMonthly_maintenance_fee(
    item.monthly_maintenance_fee
      ? maskMoneyEdit(item.monthly_maintenance_fee, item.price_currency)
      : '',
  );
  setMonthly_tax_fee(
    item.monthly_tax_fee
      ? maskMoneyEdit(item.monthly_tax_fee, item.price_currency)
      : '',
  );
  (item.price_currency && setChoseMetric(item.price_currency)) ||
    setChoseMetric('BRL');
  setRental(item.rental ? item.rental : '');
  setRental_price(
    item.rental_price
      ? maskMoneyEdit(item.rental_price, item.rental_price_currency)
      : '',
  );
  item.rental_price_currency && setChoseMetricRent(item.rental_price_currency);
}

function maskMoneyEdit(e, choseMetric) {
  var float = e.replace(/\D/g, '');
  if (choseMetric === 'USD') {
    return toMoney(float, {
      precision: 2,
      separator: '.',
      delimiter: ',',
    });
  }
  if (choseMetric === 'BRL') {
    return toMoney(float, {
      precision: 2,
      separator: ',',
      delimiter: '.',
    });
  }
  if (choseMetric === 'EUR') {
    return toMoney(float, {
      precision: 2,
      separator: ',',
      delimiter: '.',
    });
  }
}
