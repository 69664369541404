import React from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import colors from '~/UI/colors';
import logo from '~/assets/Logotipo/logo.png';

import {LogoHeader, DivImg, DivReturn} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '28px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  appbar: {
    backgroundColor: colors.white,
    /*     boxShadow: '0px 3px 6px #00000029', */
    boxShadow: '0px 1px 0px #D9D9D4!important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    height: '80px',
  },

  toolbar: {
    color: colors.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function ButtonAppBar() {
  const history = useHistory();
  const classes = useStyles();

  function returnPage() {
    history.goBack();
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} position="static">
        <Toolbar className={classes.toolbar}>
          <DivReturn onClick={() => returnPage()}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </DivReturn>
          <DivImg>
            <LogoHeader src={logo} />
          </DivImg>
        </Toolbar>
      </AppBar>
    </div>
  );
}
