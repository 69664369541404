import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const NumberName = styled.div`
  flex-direction: row;
  font-family: ${fonts.latoBlack};
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
`;

export const Ball = styled.span`
  width: 30px;
  height: 30px;
  background: ${colors.green_secondary};
  border: none;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  color: ${colors.white};
  margin-right: 10px;
`;
export const Title = styled.div`
  color: #1d1d1b;
  font-size: 14px;
  font-family: ${fonts.latoBlack};
  /* font-weight: bold; */
`;
export const SeeStep = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextGreen = styled.div`
  color: ${colors.green_secondary};
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  &:hover {
    background: ${colors.green_secondary};
    color: ${colors.white};
  }
`;

export const TextPercent = styled.div`
  color: ${colors.green_secondary};
  font-family: ${fonts.latoBlack};
  /* font-weight: bold; */
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const Percent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SaveAnnoucement = styled.p`
  color: #a88721;
  margin-bottom: 21px;
  font-family: ${fonts.latoBlack};
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
`;
