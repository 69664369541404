import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {
  InternDiv,
  DivDanger,
  TitleModal,
  ModalResponsive,
  Message,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import apiBackend from '../../services/apiaxios';
import {makeStyles} from '@material-ui/core';

import {fireAction} from '~/utils/swal';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '420px',
  },
}));

export default function ModalActivePlan({
  id,
  open,
  setOpen,
  actived,
  setActived,
  listPlan,
}) {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setActived(false);
    setOpen(false);
  };

  async function activePlan() {
    try {
      setLoading(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.put(
        `/v1/plan/${id}`,
        {
          active: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      console.log(data);
      setActived(true);
      setOpen(false);
    } catch (err) {
      Sentry.captureException(err);
      fireAction(
        'Erro interno',
        'Um erro ocorreu durante sua solicitação. Por favor, tente novamente mais tarde.',
        'error',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          classes={{paper: classes.paper}}>
          <TitleModal>
            <span></span>
            <CloseIcon onClick={handleClose} className={'iconClose'} />
          </TitleModal>
          <InternDiv>
            <ModalResponsive>
              <DivDanger>
                <FontAwesomeIcon
                  size="2x"
                  color="#A88721"
                  icon={faExclamationCircle}
                />
                <AdvertTitle>Atenção</AdvertTitle>
                <Message>
                  Se confirmar a ativação do plano, este passará a ser sujeito a
                  inscrições, mesmo que não esteja veiculado publicamente na
                  plataforma da Permuti. Deseja realmente{' '}
                  <strong>ativar</strong> o plano?
                </Message>
                <DivConfirm>
                  <ButtonsWhite disabled={loading} primary onClick={activePlan}>
                    SIM
                  </ButtonsWhite>
                  <ButtonsWhite disabled={loading} onClick={handleClose}>
                    NÃO
                  </ButtonsWhite>
                </DivConfirm>
              </DivDanger>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
