import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageRel = styled.img`
  width: 320px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 180px;
  background-size: cover;
  object-fit: cover;
`;
