/* ----------------------- */
/* Validations Schemas */
/* ----------------------- */

export const messageConfig = {
  MAX_LENGTH: 800,
  MIN_LENGTH: 5,
};

export const titleConfig = {
  MAX_LENGTH: 100,
  MIN_LENGTH: 5,
};

export const emptySchema = (value) => '';

export const titleSchema = (
  value,
  minLength = titleConfig.MIN_LENGTH,
  maxLength = titleConfig.MAX_LENGTH,
) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < minLength
    ? `Mínimo de ${minLength} caracteres`
    : value.trim().length > maxLength
    ? `Máximo de ${maxLength} caracteres`
    : '';

export const messageSchema = (
  value,
  minLength = messageConfig.MIN_LENGTH,
  maxLength = messageConfig.MAX_LENGTH,
) =>
  !value.trim()
    ? 'Campo não preenchido'
    : value.trim().length < minLength
    ? `Mínimo de ${minLength} caracteres`
    : value.trim().length > maxLength
    ? `Máximo de ${maxLength} caracteres`
    : '';
