import styled, {css} from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const ContainerTable = styled.div`
  width: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;

  .item__list {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
  }
`;

export const P = styled.div`
  color: ${colors.dark};
  font-family: ${fonts.latoRegular};
`;

export const TagItem = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  flex: none;
  width: 100%;
  max-width: max-content;
  border: 1px solid ${colors.green_secondary};
  color: ${colors.dark};
  font-family: ${fonts.latoBold};
  font-size: 12px;
  line-height: 15px;
`;

export const Recipient = styled.div`
  font-family: ${fonts.latoRegular};
  display: flex;
  align-items: center;
  color: ${colors.dark};

  > svg,
  > span {
    margin-right: 10px;
  }

  > svg {
    transition: all 0.2s ease;
    transform: ${(props) => (props.expanded ? 'rotate(180deg)' : '')};
  }
`;

export const Results = styled.p`
  margin-left: 14px;
  font-size: 14px;
  font-family: ${fonts.latoRegular};
`;

export const ContentNotifyTags = styled.div`
  margin-top: 6px;
`;

export const MessageBox = styled.div`
  width: 100%;
  border-top: ${({hasBorderTop}) => (hasBorderTop ? '1px solid #D9D9D4' : '')};
  border-bottom: ${({hasBorderBottom}) =>
    hasBorderBottom ? '1px solid #D9D9D4' : ''};
  margin-top: ${({mt}) => (mt ? `${mt}px` : '0px')};
  margin-bottom: ${(mb) => (mb ? `${mb}px` : '0px')};
  padding: 20px 0;
`;

export const TextBox = styled.p`
  margin: 0;
  color: ${colors.dark};
  font-family: ${fonts.latoRegular};
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : 0)};
  ${(props) =>
    props.upper &&
    css`
      text-transform: uppercase;
    `};
`;
