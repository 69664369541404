import styled from 'styled-components';
import colors from '../../../UI/colors';
import metrics from '../../../UI/metrics';

export const TableContainer = styled.div`
  padding: 0px 20px 0px 20px;
  /* background-color: #ccc; */
`;

export const Card = styled.div`
  height: 120px;
  box-shadow: 0px 3px 10px #00000033;
  border-radius: 10px;
  padding: 30px 20px 30px 20px;
  display: flex;
  flex-direction: column;

  .titlePlan {
    font-family: ${metrics.font_lato};
    color: ${colors.green_secondary};
    font-size: 18px;
    font-weight: bold;
  }

  .titleBlack {
    font-family: 'Lato';
    color: ${colors.dark};
    font-size: 16px;
    font-weight: 500;
  }

  .wd-80 {
    width: 80px;
  }

  .createdAtDate {
    color: ${colors.dark};
    font-size: 14px;
    font-family: ${metrics.font_lato};
  }

  .rowBetween {
    display: flex;
    justify-content: space-between;
  }

  .share {
    font-size: 16px;
    display: flex;
    margin-left: 50px;
  }

  .textTwo {
    font-size: 16px;
    font-family: ${metrics.font_lato};
    margin-right: 30px;
    color: #1d1d1b;
  }
`;
