import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import ReactLoading from 'react-loading';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from '@material-ui/core';
import colors from '../../UI/colors';
import Pagination from '../_PaginationTab';

import {
  Container,
  InternDiv,
  TitleModal,
  TitlePage,
  Hero,
  History,
  SafeAreaCard,
  SafeAreaInfo,
  ContainerPagination,
  Card,
  LoadingIndicator,
} from './styles';

import I18n from '../../i18n/i18';
import {makeStyles} from '@material-ui/core';
import apiBackend from '../../services/apiaxios';

import {formatDate, desencrypta} from '../../utils';
import {translateProfileType} from '../../utils/profileType';
import {TitleViewMy} from '../../views/MyProposal/styles';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '420px!important',
    width: '100%!important',
    marginLeft: '0!important',
    marginRight: '0!important',
  },
}));

export default function ModalPaymentHistory({
  location,
  item,
  user,
  open,
  setOpen,
  reload,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [historic, setHistoric] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setPage(1);
    setLastPage(1);
  };

  console.log(item);

  useEffect(() => {
    async function getPaymentHistoric() {
      try {
        setLoading(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.get(
          `/v1/payments/${item?.subscriber_id}?paginate=true&page=${page}&limit=6`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setHistoric(data?.data);
        setLastPage(data?.meta?.last_page);
      } catch (err) {
        Sentry.captureException(err);
        setHistoric([]);
      } finally {
        setLoading(false);
      }
    }
    if (open) {
      getPaymentHistoric();
      /*   console.log(item); */
    }
  }, [open, page, item]);

  const handleChangePage = (value) => {
    /*   console.log(value); */
    setPage(value);
  };

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{paper: classes.paper}}>
      <Container>
        <InternDiv>
          <TitleModal>
            <TitlePage>
              <I18n t="Subscriptions.modalHistoric.title" />
            </TitlePage>{' '}
            <CloseIcon onClick={handleClose} className={'iconClose'} />
          </TitleModal>
          <Hero>
            <span>
              <I18n
                t={`Subscriptions.modalList.typeAdvertiser.${user?.profile?.type_advertiser?.toLowerCase()}`}
              />
            </span>
            <p>
              {user?.profile?.type_advertiser?.toLowerCase() === 'broker' ? (
                <>
                  {user?.profile?.enterprise ??
                    `${user?.name} ${user?.surname ?? ''}`}
                </>
              ) : (
                <>
                  {user?.name} {user?.surname ?? ''}
                </>
              )}
            </p>
          </Hero>
        </InternDiv>
        {loading ? (
          <LoadingIndicator>
            <p>
              <I18n t="Subscriptions.modalHistoric.loading" />
            </p>
            <ReactLoading
              type={'spin'}
              className="load"
              color={colors.green_secondary}
              width={'30px'}
            />
          </LoadingIndicator>
        ) : (
          <History>
            {historic?.length > 0 ? (
              <>
                {historic?.map((item, index) => (
                  <SafeAreaCard key={`propertie-${item?.id}-pos-${index}`}>
                    <Card>
                      <span className="date">
                        {formatDate(item?.created_at)}
                      </span>
                      <span>
                        {item?.paid ? (
                          <I18n t="Subscriptions.modalHistoric.switchStatus.paid" />
                        ) : (
                          <>
                            <I18n t="Subscriptions.modalHistoric.switchStatus.nopaid" />
                            {/* <TitleViewMy
                              onClick={(event) => {
                                history.push('/payment', {
                                  status: item?.subscribe_status,
                                  subscribe_id: item?.subscribe_id,
                                });
                                // setOpenEdit(true);
                                // setAdvertise(item);
                              }}
                              // style={{margin: '0px 0px 0px 50px'}}
                            >
                              {/* <span className="mg-right-8">
                                <I18n t={'MyAds2.Pay'} />
                              </span>{' '} */}
                            {/* </TitleViewMy> */}
                          </>
                        )}
                      </span>
                    </Card>
                  </SafeAreaCard>
                ))}
              </>
            ) : (
              <SafeAreaInfo>
                <p>
                  <I18n t="Subscriptions.modalHistoric.empty" />
                </p>
              </SafeAreaInfo>
            )}
          </History>
        )}
        {lastPage > 1 && (
          <SafeAreaInfo>
            <ContainerPagination>
              <Pagination
                count={lastPage}
                page={page}
                handleChange={(event, value) => handleChangePage(value)}
              />
            </ContainerPagination>
          </SafeAreaInfo>
        )}
      </Container>
    </Dialog>
  );
}
