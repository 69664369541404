import {getRoute} from '../../utils';

export default function setLocationWith(item) {
  /*  if (getRoute() === 'en' || getRoute() === '') {
    return item.name_en;
  } else if (getRoute() === 'es') {
    return item.name_es;
  } else if (getRoute() === 'pt') {
    return item.name_pt;
  } */
  return item.name_pt;
}
