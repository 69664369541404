import React from 'react';
import {useDispatch} from 'react-redux';
import NextPrev from '../../Components/nextPrev';
import {DivNext} from '../LocalizeOne/styles';
import Map from './Map/index';
import {desencrypta} from '~/utils/index';
import baseURL from '~/services/api';
import {TitlePage} from '../../Photo/styles';
/* import addMsgAction from '~/redux/action/action_add_user'; */
import I18n from '~/i18n/i18';
// Geocode.setApiKey("AIzaSyDYFZcGNmvU2_-LJ63uoVkbPVvxz3b_OcU");
import './styles.css';
import apiBackend from '~/services/apiaxios';

import * as Sentry from '@sentry/react';

export default function Adjust({
  step,
  setStep,
  latitude,
  longitude,
  id,
  setLatitude,
  setLongitude,
}) {
  const dispatch = useDispatch();

  function handleProx(event) {
    saveItens();
    event.preventDefault();
    let value = Number.parseInt(step);
    value = (value + 1).toString();
    saveItens(value);
  }

  async function saveItens(value) {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .put(
        `/v1/property/${id}`,
        {
          latitude: '0',
          longitude: '0',
          step: 4,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        // dispatch(addMsgAction(true));
        setStep(value);
        console.log(response);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  return (
    <div /* form onSubmit={handleProx} */>
      <TitlePage>
        <I18n t="Localization.adjust" />
      </TitlePage>
      <Map
        latitude={latitude}
        longitude={longitude}
        setLatitude={setLatitude}
        setLongitude={setLongitude}
      />
      <div style={{marginTop: '400px'}}>
        <DivNext>
          <NextPrev
            step={step}
            setStep={setStep}
            active
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>
    </div>
  );
}
