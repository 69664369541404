import styled from 'styled-components';

import colors from '~/UI/colors';
import fonts from '~/UI/fonts';
import metrics from '~/UI/metrics';

export const ContainerInfo = styled.div`
  /* margin: 31px 100px 0 100px; */
  font-family: ${metrics.font_lato};
  @media only screen and (max-width: 991px) {
    .gridContent {
      padding: 30px 30px 0px 30px;
      .visiblePrice {
        display: none;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .gridContent {
      padding: 30px 0px 0px 138px;
    }

    .secondBox {
      display: flex;
      justify-content: center;
      padding: 30px 138px 0px 0px;
    }
  }

  .capitalize:first-letter {
    text-transform: capitalize;
  }
`;

export const ImageShow = styled.img`
  width: 99.5%;
  height: 99.5%;
  border-radius: 5px;
  object-fit: cover;
  /* box-shadow: 0px 3px 1px #00000066; */
`;

export const ImageShowG = styled.img`
  width: 99.3%;
  /* height: 420px; */
  height: 324px;
  object-fit: cover;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  /* box-shadow: 0px 3px 1px #00000066; */
`;

export const ImageShowM = styled.img`
  width: 99.4%;
  height: 160px;
  object-fit: cover;
  /* box-shadow: 0px 3px 1px #00000066; */
`;

export const TitleAd = styled.div`
  font-size: 32px;
  font-family: ${fonts.latoRegular};
  font-weight: bold;
  line-height: 40px;
  color: #484848;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
  justify-content: center;
`;

export const AdressAd = styled.div`
  font-size: 14px;
  color: #1d1d1b;
`;

export const Price = styled.div`
  font-size: 30px;
  font-family: ${fonts.robotoRegularBold};
  color: ${colors.green_secondary};
`;

export const PriceAlternative = styled.div`
  font-size: 18px;
  font-family: ${fonts.latoRegular};
  color: #1d1d1b;
`;

export const ButtonEditTable = styled.button`
  border: none;
  background-color: ${colors.white};
  font-size: 18px;

  box-shadow: 0px 3px 5px #00000040;
  width: 120px;
  height: 40px;

  font-family: ${fonts.latoBlack};
  color: ${colors.green_secondary};

  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: #2e6643;
    color: #e6ffef;
    box-shadow: 0px 0px 3px #00000033;
  }
  &:focus {
    outline: 0;
  }
`;

export const ButtonWhite = styled(ButtonEditTable)`
  font-size: 14px;
  font-family: ${fonts.latoBlack};
  color: ${colors.green_secondary};
  border-radius: 5px;
`;

export const ButtonWhiteLg = styled(ButtonWhite)`
  width: 190px;
`;

export const CardInfo = styled.div`
  border: 1px solid #d9d9d4;
  border-radius: 5px;
  padding: 16px 20px;
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  @media only screen and (max-width: 991px) {
    width: 120px;
  }
  @media only screen and (min-width: 992px) {
  }
`;
export const TitleDetails = styled(TitleAd)`
  font-size: 24px;
`;

export const InfoIcons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Values = styled.div``;
