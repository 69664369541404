import React from 'react';
import TableClients from './Table';

// import { Container } from './styles';

function Expired({users}) {
  return (
    <>
      <TableClients information={users} />
    </>
  );
}

export default Expired;
