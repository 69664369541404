import React from 'react';
import colors from '../../UI/colors';
import ReactLoading from 'react-loading';

function Loader() {
  return (
    <div
      style={{
        height: '40vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <ReactLoading
        className="load"
        type={'spin'}
        color={colors.green_secondary}
        width={'30px'}
      />
    </div>
  );
}

export default Loader;
