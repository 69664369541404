import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.div`
  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};

  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  .input-localization {
    width: 100% !important;
    height: 40px;
    font-size: 16px;
    color: #1d1d1b;
    border: none;

    margin: 0px !important;
    font-family: ${fonts.latoRegular};

    &:focus {
      outline: 0;
    }
    &::-webkit-input-placeholder {
      color: ${colors.dark_light};
      font-family: ${fonts.latoRegularItalic};

      font-size: 16px;
    }
  }
`;
