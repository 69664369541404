import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {useHistory} from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import React from 'react';
import Dash from '~/components/Dashboard';
import apiBackend from '~/services/apiaxios';
import {transformAvatarURL, desencrypta} from '~/utils';
import logo from '../../assets/iconPerson.jpg';
import {Dropzone} from './dropzone';
import ModalEditColaborator from '../User/Colaborators/ModalEdit';
import Loader from '../User/loader';
import {
  Card,
  Container,
  Content,
  DivInfo,
  DivIntern,
  IconContainer,
  ImgUserG,
  InfosIntern,
  InternCard,
  PEdit,
  PName,
  ProfileInfo,
  Rotule,
  Row,
  AvatarContainer,
  SpinnerLoading,
  RowIntern,
  SpanCard,
  SpanText,
} from './styles';
import './styles.css';
import {fireAction} from '~/utils/swal';
import ModalSecurity from '~/components/ModalSecurity';
import {useDispatch} from 'react-redux';
import setUserAvatar from '~/redux/action/action_user_avatar';
import setUserRole from '~/redux/action/action_user_role';
import transformNameProfile from './profile_type';
import {clearUser} from '~/requests/User/clearUserInfo';

import * as Sentry from '@sentry/react';

function Profile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [profile, setProfile] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalSecurity, setModalSecurity] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [profileImg, setProfileImg] = React.useState('');

  const [hasUploading, setHasUploading] = React.useState(false);

  React.useEffect(() => {
    async function uploadNewAvatar() {
      try {
        setHasUploading(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const payload = new FormData();
        payload.append('avatar', profileImg);

        const {data} = await apiBackend.put(
          `/v1/users/${profile?.id}/avatar`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        /*   console.log(data); */
        const newAvatar = transformAvatarURL(data?.avatar_url);

        setProfile((profile) => {
          return {
            ...profile,
            avatar: {
              avatar_url: newAvatar,
            },
          };
        });

        localStorage.setItem('img', newAvatar);
        dispatch(setUserAvatar(newAvatar));

        setProfileImg('');
      } catch (err) {
        Sentry.captureException(err);
        fireAction(
          'Não foi possível completar sua solicitação',
          'Um erro interno ocorreu durante seu pedido. Por favor, tente novamente mais tarde.',
          'error',
        );
        setProfileImg('');
        console.log(err);
      } finally {
        setHasUploading(false);
      }
    }
    if (profile?.id && profileImg) {
      uploadNewAvatar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id, profileImg]);

  const getProfile = React.useCallback(async () => {
    try {
      setLoading(true);

      const id = localStorage.getItem('id');
      const token = desencrypta(localStorage.getItem('@User_informations'));
      if (!id) {
        clearUser();
        history.push('/');
        return fireAction(
          'Não foi possível completar sua solicitação',
          'Um de seus dados salvos ao logar foi obstruído. Faça login novamente para normalizar.',
          'error',
        );
      }
      const {data} = await apiBackend.get(`v1/users/${id}`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      /*  console.log(data?.user); */
      const actualAvatar = localStorage.getItem('img');
      const avatarResponse = transformAvatarURL(data?.user?.avatar?.avatar_url);

      const actualName = localStorage.getItem('name');
      const nameResponse = data?.user?.name;

      const actualRole = localStorage.getItem('role_id');
      const roleResponse = data?.user?.role_id;

      if (avatarResponse !== actualAvatar) {
        dispatch(setUserAvatar(avatarResponse));
      }

      if (nameResponse !== actualName) {
        localStorage.setItem('name', nameResponse);
      }

      if (roleResponse !== actualRole) {
        dispatch(setUserRole(roleResponse));
      }

      setProfile(data?.user);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(false);
      console.log('perfil');
    }
  }, []);

  React.useEffect(() => {
    getProfile();
  }, [getProfile]);

  async function handleEditSecurityData(values) {
    try {
      // setIsSubmitting(true);

      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.put(
        `/v1/users/password/${profile?.id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      /* console.log(data); */

      fireAction(
        'Senha Alterada',
        'Sua senha foi alterada com sucesso',
        'success',
      );

      setModalSecurity();
    } catch (err) {
      /*  console.log(err.response.data); */

      if (
        err?.response?.status === 422 &&
        err?.response?.data?.data?.field === 'previous password'
      ) {
        fireAction(
          'Não foi possível alterar a sua senha',
          'As informações inseridas não coincide com a senha anterior deste usuário',
          'error',
        );
      } else {
        fireAction('', '', 'error');
      }

      console.log(err);
    } finally {
      // setIsSubmitting(false);
    }
  }

  return (
    <>
      <Dash title={'Perfil'}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Container>
            {loading ? (
              <Loader />
            ) : (
              <DivIntern>
                <AvatarContainer hasUploading={hasUploading}>
                  {hasUploading && <SpinnerLoading />}
                  <ImgUserG
                    src={
                      profileImg
                        ? URL.createObjectURL(profileImg)
                        : profile?.avatar?.avatar_url
                        ? profile?.avatar?.avatar_url
                        : logo
                    }
                  />
                </AvatarContainer>

                <Dropzone
                  disabled={hasUploading}
                  oneFile
                  state={profileImg}
                  setState={setProfileImg}>
                  Editar foto do perfil
                </Dropzone>

                <DivInfo
                  style={{
                    minHeight: '38px',
                    height: 'max-content',
                    marginTop: 20,
                  }}>
                  <PName style={{lineHeight: '28px', textAlign: 'center'}}>
                    {profile?.name} {profile?.surname ?? ''}
                  </PName>
                </DivInfo>
                <DivInfo>{transformNameProfile(profile?.role_id)}</DivInfo>
                <ProfileInfo>
                  <Row>
                    <RowIntern>
                      <Rotule>Email</Rotule>
                      <Content>{profile?.email}</Content>
                    </RowIntern>
                  </Row>
                  <Row className="rowWidth">
                    <RowIntern>
                      <Rotule>Celular</Rotule>
                      <Content>{profile?.cellphone}</Content>
                    </RowIntern>
                  </Row>
                  <Row className="rowWidth">
                    <RowIntern>
                      <Rotule>Cargo</Rotule>
                      <Content>{profile?.cargo}</Content>
                    </RowIntern>
                  </Row>
                </ProfileInfo>
                <Card
                  onClick={() => {
                    setModalEdit(true);
                  }}>
                  <InternCard>
                    <PersonIcon />
                    <SpanCard>Editar Informações</SpanCard>
                  </InternCard>
                  <InfosIntern>
                    Nome, sobrenome, telefone, email e cargo
                  </InfosIntern>
                  <IconContainer>
                    <ChevronRightRoundedIcon />
                  </IconContainer>
                </Card>

                <Card
                  onClick={() => {
                    setModalSecurity(true);
                  }}>
                  <InternCard>
                    <LockIcon />
                    <SpanCard>Segurança</SpanCard>
                  </InternCard>
                  <InfosIntern>Atualização de senha</InfosIntern>
                  <IconContainer>
                    <ChevronRightRoundedIcon />
                  </IconContainer>
                </Card>
              </DivIntern>
            )}
          </Container>
        </div>
      </Dash>

      <ModalEditColaborator
        page={page}
        setPage={setPage}
        open={modalEdit}
        setOpen={setModalEdit}
        colab={profile}
        listUser={getProfile}
        flag={true}
      />

      <ModalSecurity
        open={modalSecurity}
        setOpen={setModalSecurity}
        handleSubmit={handleEditSecurityData}
      />
    </>
  );
}

export default Profile;
