import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {green} from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';

import {Label, Item, Container} from './styles';

import './styles.css';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButtons({selectedValue, setSelectedValue}) {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Container>
      <Item>
        <GreenRadio
          checked={selectedValue === '5'}
          onChange={handleChange}
          value="5"
          name="radio-button-demo"
          inputProps={{'aria-label': 'Administrativo'}}
        />{' '}
        <Label checked={selectedValue === '5'}>Administrador</Label>
      </Item>
      <Item>
        <GreenRadio
          checked={selectedValue === '4'}
          onChange={handleChange}
          value="4"
          name="radio-button-demo"
          inputProps={{'aria-label': '4'}}
        />{' '}
        <Label checked={selectedValue === '4'}>Gestor</Label>
      </Item>
      <Item>
        <GreenRadio
          checked={selectedValue === '3'}
          onChange={handleChange}
          value="3"
          name="radio-button-demo"
          inputProps={{'aria-label': '3'}}
        />{' '}
        <Label checked={selectedValue === '3'}>Aprovador</Label>
      </Item>
      <Item>
        <GreenRadio
          checked={selectedValue === '2'}
          onChange={handleChange}
          value="2"
          name="radio-button-demo"
          inputProps={{'aria-label': '2'}}
        />{' '}
        <Label checked={selectedValue === '2'}>Vendedor</Label>
      </Item>
    </Container>
  );
}
