import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivInputText,
  DivConfirm,
  ButtonGreen,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import baseURL from '../../services/api';

import InputTextArea from '../textArea';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '620px',
  },
}));

export default function ModalDisapproveAds({
  id,
  open,
  setOpen,
  text,
  funcExec,
  loading,
}) {
  const classes = useStyles();
  const [justify, setJustify] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  async function handleDisapproveAds(event) {
    funcExec(justify, handleClose);
    event.preventDefault();
  }

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle> Atenção</AdvertTitle>
              <Message>
                Se você confirma a <b>DESAPROVAÇÃO </b> deste anúncio,
                <br /> escreva a justificativa de desaprovação para que o
                usuário possa corrigi-lo.
              </Message>
            </DivDanger>
          </ModalResponsive>
          <DivInputText>
            <InputTextArea
              /* ative para adicionar uma quantidade máxima de caracteres */
              /* max={150} */
              placeHolder={'Escreva aqui sua justificativa'}
              label="Justificativa"
              value={justify}
              setValue={setJustify}
            />
          </DivInputText>

          <DivConfirm>
            <ButtonGreen
              disabled={!justify || loading}
              onClick={handleDisapproveAds}>
              {loading ? 'AGUARDE...' : 'ENVIAR'}
            </ButtonGreen>
          </DivConfirm>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
