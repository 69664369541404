import {Dialog} from '@material-ui/core';
import ReactLoading from 'react-loading';
import CloseIcon from '@material-ui/icons/Close';
import React, {useEffect, useState} from 'react';
import Autocomplete from 'react-google-autocomplete';
import {toMoney} from 'vanilla-masker';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import {geoCodeDesired} from '~/services/Geocode/geocode';
import fonts from '~/UI/fonts';
import colors from '~/UI/colors';
import {desencrypta, getRoute} from '~/utils';
import Dimension from '../../Components/dimension';
import InputTextRequireSelect from '../../Components/InputRequireSelect';
import InputText from '../../Components/inputText';
import SelectMenuRequiredI18n from '../../Components/selectMenuRequiredI18n';
import MenuChangeObject from '../../NewComponents/MenuChanceObjectRequired';

import {ModalResponsive} from '../../Components/styles';
import {
  DivInputMetrics,
  DivMetrics,
  DivSelectMetrics,
  DivText,
} from '../../Financial/styles';
import {Label} from '../../Localize/LocalizeOne/styles';
import {MessageInputs} from '../ModalDelete/styles';
import {ButtonSave, DivInputText, InternDiv, TitleModal} from './styles';
import './styles.css';

import * as Sentry from '@sentry/react';

const itensDimension = ['BRL', 'USD', 'EUR'];
const itensCountry = ['Brazil', 'United States', 'Portugal'];

export default function ModalDesiredEdit({
  open,
  setOpen,
  info,
  getProperty,
  maskInit,
  idAds,
  itensBackend,
}) {
  const [type, setType] = useState(
    info.type_property ? info.type_property : '',
  );
  const [selectedValueCountry, setSelectedValueCountry] = useState(
    info.country ? info.country : 'Brazil',
  );
  const [utf, setUtf] = useState('Br');
  const [loadGoogle, setLoadGoogle] = useState('');
  const [classGoogleC, setClassGoogleC] = useState('normal');

  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/profile`,
    state: {fromDashboard: true},
  });
  const [choseMetric, setChoseMetric] = useState(info?.currency ?? 'USD');
  const [priceMax, setPriceMax] = useState(
    info.max_price ? maskInit(parseInt(info.max_price)) : '',
  );
  const [priceMin, setPriceMin] = useState(
    info.min_price ? maskInit(parseInt(info.min_price)) : '',
  );
  const [enableButton, setEnableButton] = useState(true);

  const [spaceMax, setSpaceMax] = useState(false);
  const [spaceMin, setSpaceMin] = useState(false);

  const [latitude, setLatitude] = useState(info.latitude ? info.latitude : '');
  const [longitude, setLongitude] = useState(
    info.longitude ? info.longitude : '',
  );
  const [viewLat, setViewLat] = useState(info.view_lat ? info.view_lat : '');
  const [viewLon, setViewLon] = useState(info.view_lon ? info.view_lon : '');
  const [viewLat2, setViewLat2] = useState(
    info.view_lat2 ? info.view_lat2 : '',
  );
  const [viewLon2, setViewLon2] = useState(
    info.view_lon2 ? info.view_lon2 : '',
  );

  const [city, setCity] = useState(info.city ? info.city : '');
  const [state, setState] = useState(info.state ? info.state : '');
  const [neighborhood, setNeighborhood] = useState(
    info?.neighborhood ? info?.neighborhood : '',
  );

  const [address, setAddress] = useState(
    [info?.neighborhood, info.city, info.state].filter(Boolean).join(', '),
  );
  const [selectedAddress, setSelectedAddress] = useState(
    [info?.neighborhood, info.city, info.state].filter(Boolean).join(', '),
  );

  const [loading, setLoading] = useState(false);

  function maskChange(e, setValue) {
    const float = e;
    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  useEffect(() => {
    if (priceMin) {
      maskChange(priceMin, setPriceMin);
    }

    if (priceMax) {
      maskChange(priceMax, setPriceMax);
    }
  }, [choseMetric]);

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/profile`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  const handleClose = () => {
    setOpen(false);
  };

  async function sendMatches() {
    try {
      setLoading(true);
      let token = desencrypta(localStorage.getItem('@User_informations'));
      const myData = {
        country: selectedValueCountry,
        state,
        city,
        neighborhood,
        property_type_id: type.id,
        min_price: convert(priceMin),
        max_price: convert(priceMax),
        currency: choseMetric,
        latitude,
        longitude,
        view_lat: viewLat,
        view_lon: viewLon,
        view_lat2: viewLat2,
        view_lon2: viewLon2,
      };

      const {data} = await apiBackend.put(
        `/v1/property/${idAds}/intention/${info.id}`,
        myData,
        {headers: {Authorization: `Bearer ${token}`}},
      );
      /* console.log(data); */
      getProperty();
      setOpen(false);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (selectedValueCountry === 'Brazil') {
      setUtf('Br');
    } else if (selectedValueCountry === 'United States') {
      setUtf('Us');
    } else if (selectedValueCountry === 'Portugal') {
      setUtf('Pt');
    } else {
      setUtf('Us');
    }
    setLoadGoogle(true);
  }, [selectedValueCountry]);

  useEffect(() => {
    if (priceMax && priceMin && selectedValueCountry && type !== '') {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  }, [priceMax, priceMin, selectedValueCountry, type]);

  function renderGoogleAddress() {
    return (
      <Autocomplete
        value={address}
        id="input-auto-test"
        style={{marginBottom: '20px'}}
        onChange={(event) => {
          setAddress(event.target.value);
        }}
        onBlur={(event) => {
          const {value} = event.target;

          if (value !== selectedAddress) {
            setAddress(() => {
              setState('');
              setCity('');
              setNeighborhood('');
              setViewLat('');
              setViewLon('');
              setLatitude('');
              setLongitude('');
              return '';
            });
          }
        }}
        onPlaceSelected={(place) => {
          setLoadingInsertAddress(true);
          insert(place, 1);
        }}
        placeholder={I18n.getTranslation(
          location,
          'Localization.placeholderAddress',
        )}
        types={['(regions)']}
        className={classGoogleC}
        componentRestrictions={{country: utf}}
      />
    );
  }

  useEffect(() => {
    if (address) {
      setClassGoogleC('value');
    } else {
      setClassGoogleC('normal');
    }
  }, [address]);

  /*   useEffect(() => {
    setAddress(() => {
      setState('');
      setCity('');
      setNeighborhood('');
      setViewLat('');
      setViewLon('');
      setLatitude('');
      setLongitude('');
      return '';
    });
  }, [selectedValueCountry]); */

  const [loadingInsertAddress, setLoadingInsertAddress] = useState(false);

  async function insert(place, number) {
    try {
      setAddress(() => {
        setState('');
        setCity('');
        setNeighborhood('');
        setViewLat('');
        setViewLon('');
        setLatitude('');
        setLongitude('');
        return '';
      });

      if (number === 1) {
        await geoCodeDesired({
          place_id: place.place_id,
          setLatitude,
          setLongitude,
          setViewLat,
          setViewLon,
          setViewLat2,
          setViewLon2,
        });
        let final_address = [];
        console.log(place);

        place.address_components.forEach((item) => {
          if (
            item.types[0] === 'sublocality_level_1' ||
            item.types[0] === 'sublocality'
          ) {
            setNeighborhood(item.long_name);
            final_address.push(item.long_name);
          } else if (
            item.types[0] === 'locality' ||
            (item.types[0] === 'administrative_area_level_2' && utf !== 'Us')
          ) {
            setCity(item.long_name);
            final_address.push(item.long_name);
          } else if (
            item.types[0] === 'administrative_area_level_1' &&
            utf !== 'Pt'
          ) {
            setState(item.short_name);
            final_address.push(item.short_name);
          }
        });

        console.log(final_address);
        setSelectedAddress(() => {
          const myAddress = final_address
            ?.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            })
            ?.join(', ');

          setAddress(myAddress);

          return myAddress;
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setLoadingInsertAddress(false);
    }
  }

  /* TODO troquei a função mask de cima pela de baixo */

  function mask(e, setValue) {
    const float = e.target.value;
    if (choseMetric === 'USD') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    } else if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: '.',
        }),
      );
    } else {
      setValue(
        toMoney(float, {
          precision: 0,
          separator: '',
          delimiter: ',',
        }),
      );
    }
  }

  function transformArea(value) {
    const data = value;
    console.log(value);

    if (choseMetric === 'BRL' || choseMetric === 'EUR') {
      const p1 = data.split('.').join('');

      const transform = parseFloat(p1);
      return transform.toFixed(2);
    }
    const p1 = data.replace(/,/g, '');
    const transform = parseFloat(p1);
    return transform.toFixed(2);
  }

  function convert(value) {
    const data = transformArea(value);
    return data;
  }

  function spaceDimensionMax(value) {
    /*  console.log(value); */
    if (value) setSpaceMax(true);
    else setSpaceMax(false);
  }

  function spaceDimensionMin(value) {
    if (value) setSpaceMin(true);
    else setSpaceMin(false);
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModal>
            <span>
              <I18n t="DesiredProperty.ModalEditTitle" />{' '}
            </span>
            <CloseIcon onClick={() => handleClose()} className="iconClose" />
          </TitleModal>
          <ModalResponsive>
            <InternDiv>
              <div>
                <DivInputText>
                  <SelectMenuRequiredI18n
                    i18n="Country"
                    placeHolder={I18n.getTranslation(
                      location,
                      'Localization.placeHolderCountry',
                    )}
                    label={I18n.getTranslation(
                      location,
                      'Localization.labelCountry',
                    )}
                    itens={itensCountry}
                    value={selectedValueCountry}
                    setValue={setSelectedValueCountry}
                  />
                </DivInputText>

                {/*   <DivInputText>
                  <InputText
                    placeHolder={I18n.getTranslation(
                      location,
                      'Localization.placeHolderNeig',
                    )}
                    label={I18n.getTranslation(
                      location,
                      'Localization.labelNeig',
                    )}
                    value={neighborhood}
                    setValue={setNeighborhood}
                  />
                </DivInputText> */}

                <DivInputText>
                  <Label classes={classGoogleC}>
                    <I18n t="Localization.labelAddress" />
                  </Label>
                  {utf === 'Br' && renderGoogleAddress()}
                  {utf === 'Us' && renderGoogleAddress()}
                  {utf === 'Pt' && renderGoogleAddress()}
                </DivInputText>

                {/*  <DivInputText>
                  <InputText
                    placeHolder={I18n.getTranslation(
                      location,
                      'Localization.placeHolderState',
                    )}
                    label={I18n.getTranslation(
                      location,
                      'Localization.labelState',
                    )}
                    value={state}
                    setValue={setState}
                  />
                </DivInputText> */}

                <DivInputText>
                  <MenuChangeObject
                    itensBackend={itensBackend}
                    placeHolder={I18n.getTranslation(
                      location,
                      'Type.placeholder',
                    )}
                    i18n="PropertyType"
                    label={I18n.getTranslation(location, 'Type.rotule')}
                    value={type}
                    setValue={setType}
                  />
                </DivInputText>

                <DivMetrics value={priceMin}>
                  <DivInputMetrics>
                    <InputTextRequireSelect
                      noBorder
                      mask={mask}
                      func={spaceDimensionMin}
                      placeHolder={I18n.getTranslation(
                        location,
                        'ModalFilter.Digit',
                      )}
                      label={<I18n t="ModalFilter.ValueMin" />}
                      value={priceMin}
                      setValue={setPriceMin}
                    />
                  </DivInputMetrics>
                  <DivSelectMetrics noBorder value={priceMin}>
                    <Dimension
                      pdTop={30}
                      value={choseMetric}
                      setValue={setChoseMetric}
                      itens={itensDimension}
                    />
                  </DivSelectMetrics>
                </DivMetrics>
                {spaceMin ? (
                  <MessageInputs>
                    <I18n t="RequiredField.message" />
                  </MessageInputs>
                ) : null}

                <DivMetrics value={priceMax} className="mg-top-30">
                  <DivInputMetrics>
                    <InputTextRequireSelect
                      noBorder
                      func={spaceDimensionMax}
                      mask={mask}
                      placeHolder={I18n.getTranslation(
                        location,
                        'ModalFilter.Digit',
                      )}
                      label={<I18n t="ModalFilter.ValueMax" />}
                      value={priceMax}
                      setValue={setPriceMax}
                    />
                  </DivInputMetrics>
                  <DivSelectMetrics noBorder value={priceMax}>
                    <DivText>
                      <Dimension
                        value={choseMetric}
                        setValue={setChoseMetric}
                        itens={itensDimension}
                      />
                    </DivText>
                  </DivSelectMetrics>
                </DivMetrics>
                {spaceMax ? (
                  <MessageInputs>
                    <I18n t="RequiredField.message" />
                  </MessageInputs>
                ) : null}
              </div>
              <ButtonSave
                disabled={enableButton || loadingInsertAddress || loading}
                onClick={() => {
                  sendMatches();
                }}
                className="btnDataAddress btnload">
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color={colors.green_secondary}
                    width="30px"
                    height="30px"
                  />
                ) : (
                  <>
                    + <I18n t="DesiredProperty.EditAds" />
                  </>
                )}
              </ButtonSave>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
      {/* <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="success">
                {t("Placeholders.editUser")}
            </Alert>
        </Snackbar> */}
    </>
  );
}
