const INITIAL_STATE = {
  data: '',
};

export default function step(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'PLAN_LIST':
      return {data: action.text};
    default:
      return state;
  }
}
