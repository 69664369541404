import React, {useState, useEffect} from 'react';
import Trash from '../../../../../../assets/delete.svg';
import Edit from '../../../../../../assets/edit.svg';
import {
  Container,
  TitleHave,
  Image,
  DivIcon,
  DivTrash,
  DivIconEdit,
  ImageIcon,
} from './styles';
import ModalCropperEdit from '../ModalCropperEdit';
import './styles.css';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from 'react-grid-dnd';
import {Subtitle} from './styles';
import {desencrypta} from '../../../../../../utils';
import baseURL from '../../../../../../services/api';
import * as Sentry from '@sentry/react';

function RenderImages({files, setFiles, id}) {
  const [keyPhoto, setKeyPhoto] = useState('');
  const [openCropperEdit, setCropperEdit] = useState(false);

  return (
    <>
      <Container>
        {files.length > 0 ? (
          <>
            {' '}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <TitleHave>Imagens do Imóvel</TitleHave>
              <Count unit={files.length} />
            </div>
            <Subtitle>
              Clique e arraste para mover os cards de imagens.
            </Subtitle>{' '}
          </>
        ) : null}
        {files.length > 0 ? (
          <Example id={id} files={files} setFiles={setFiles} />
        ) : null}
      </Container>
      {openCropperEdit ? (
        <ModalCropperEdit
          keyPhoto={keyPhoto}
          open={openCropperEdit}
          setOpen={setCropperEdit}
          file={files[keyPhoto]}
          filesExibition={files}
          setFilesExibition={setFiles}
        />
      ) : null}
    </>
  );
}

export default RenderImages;

function Count({unit}) {
  return <span>{unit}/20</span>;
}

function Example({files, setFiles, id}) {
  const [items, setItems] = React.useState({
    left: files,
  });
  const [size, setSize] = useState('0');
  const [keyPhoto, setKeyPhoto] = useState('');
  const [openCropperEdit, setCropperEdit] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      if (files.length % 2 !== 0) {
        let value = (files.length + 1) / 2;
        setSize(value * 240);
      } else {
        let value = files.length / 2;
        setSize(value * 240);
      }
    }
  }, [files]);

  useEffect(() => {
    setItems({
      left: files,
    });
  }, [files]);

  useEffect(() => {
    setFiles(items.left);
  }, [items]);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    let result = '';
    if (targetId) {
      const result = move(items[sourceId], sourceIndex, targetIndex);
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }

    if (items[sourceId] !== undefined) {
      result = swap(items[sourceId], sourceIndex, targetIndex);
    }

    return setItems({
      ...items,
      [sourceId]: result,
    });
  }

  async function getImagesReq() {
    try {
      let response = await fetch(`${baseURL}/api/v1/properties/${id}`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

        headers: {
          Authorization: desencrypta(
            localStorage.getItem('@User_informations'),
          ),
        },
      });

      if (response.status === 200 || response.status === 201) {
        response.json().then((data) => {
          setFiles(data.photos);
        });
      } else {
        console.log(response);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function deleteReq(idImage) {
    try {
      let response = await fetch(
        `${baseURL}/api/v1/properties/${id}/photos/${idImage}`,
        {
          method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

          headers: {
            Authorization: desencrypta(
              localStorage.getItem('@User_informations'),
            ),
          },
        },
      );

      if (response.status === 200 || response.status === 201) {
        getImagesReq();
      } else {
        console.log(response);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async function deleteImage(imageId) {
    await deleteReq(imageId);
  }

  return (
    <>
      <GridContextProvider onChange={onChange}>
        <div className="container">
          <GridDropZone
            className="dropzone left"
            id="left"
            boxesPerRow={2}
            rowHeight={240}
            style={{height: size}}>
            {items.left.map((item, key) => (
              <GridItem
                className="itemDrag"
                key={item.lastModified}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}>
                <div>
                  <Image src={item.image_thumbnail_url} />
                  <DivTrash>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <DivIconEdit
                        onClick={(event) => {
                          event.preventDefault();
                          setKeyPhoto(key);
                          setCropperEdit(true);
                        }}>
                        <ImageIcon src={Edit} key={'edit'} />
                      </DivIconEdit>
                      <DivIcon onClick={() => deleteImage(item.id)}>
                        <ImageIcon src={Trash} key={'trash'} />
                      </DivIcon>
                    </div>
                  </DivTrash>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </div>
      </GridContextProvider>
      {openCropperEdit ? (
        <ModalCropperEdit
          id={id}
          getImagesReq={getImagesReq}
          keyPhoto={keyPhoto}
          open={openCropperEdit}
          setOpen={setCropperEdit}
          file={files[keyPhoto]}
          filesExibition={files}
          setFilesExibition={setFiles}
        />
      ) : null}
    </>
  );
}
