/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@material-ui/core/Grid';
import React, {useEffect, useState} from 'react';
import Dash from '~/components/Dashboard';
import DatePickerCustom from '~/components/DatePickerCustom';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import {fireAction} from '~/utils/swal';
import Filters from './Filters';
import Loader from './loader';
import SearchComponent from './SearchComponent';
import {
  ActionsContainer,
  ClearFiltersButton,
  ReportContainer,
  Results,
} from './styles';
import MyProposal2 from './_Reuse';

/* const currencyURl = 'https://economia.awesomeapi.com.br/json'; */

export default function Proposal({display}) {
  /*   const [currencyValues, setCurrencyValues] = useState('');
  const [afterCurrency, setAfterCurrency] = useState(false); */

  /*   async function getCurrency() {
    fetch(`${currencyURl}/all/USD-BRL,EUR-BRL`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',

      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrencyValues(data);
        setAfterCurrency(true);
      })
      .catch((e) => {
        if (e.status === 401) {
          console.log(e);
          fireAction(
            'Erro interno no servidor',
            'Um erro ocorreu durante sua solicitação. Não conseguimos converter as moedas para visualização. Por favor, tente novamente mais tarde.',
            'error',
          );
        } else {
          console.log(e);
          fireAction(
            'Falha na comunicação',
            'Um erro de comunicação com o servidor de conversão de moedas ocorreu. Tente novamente mais tarde.',
            'error',
          );
        }
      });
  }

  useEffect(() => {
    getCurrency();
  }, []); */

  /* ------------------------------------------ */
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [tag, setTag] = useState('all');
  const [filterName, setFilterName] = useState('');
  const [suggestItem, setSuggestItem] = useState({});
  const [query, setQuery] = useState(new URLSearchParams());

  /* --- PAGINATION TABLE --- */
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const handleChangePage = (value) => {
    setPage(value);
  };
  /* --------------------------------------------------- */

  /* ------------------------------------- */
  /* ------------------------------------- */
  /* ------------------------------------- */
  function handleFilter() {
    var query = new URLSearchParams();

    if (Object?.keys(suggestItem)?.length > 0) {
      query.append('user_id', suggestItem?.id);
    }

    if (dateStart?._isValid) {
      let initial =
        dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
      query.append('initial', initial);
    }

    if (dateEnd?._isValid) {
      let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';
      query.append('end', end);
    }

    if (dateStart?._isValid && dateEnd?._isValid) {
      if (dateStart?._d > dateEnd?._d) {
        let initial =
          dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';
        let end = dateEnd?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';

        query.set('initial', end);
        query.set('end', initial);
      }
    }
    console.log('chamou');

    setQuery(() => {
      setPage(1);
      return query;
    });
  }

  function handleClearFilter() {
    setFilterName('');
    setSuggestItem({});
    setDateStart(null);
    setDateEnd(null);
    setTag('');

    setQuery(() => {
      setPage(1);
      return new URLSearchParams();
    });
  }

  useEffect(() => {
    if (dateStart?._isValid && dateStart?._d > new Date('1900-01-01')) {
      handleFilter();
    }
  }, [dateStart]);

  useEffect(() => {
    if (dateEnd?._isValid && dateEnd?._d > new Date('1900-01-01')) {
      handleFilter();
    }
  }, [dateEnd]);

  /* ------------------------------------- */
  /* ------------------------------------- */
  /* ------------------------------------------- */
  const handleBlurSearch = (value) => {
    if (Object.keys(suggestItem).length > 0) {
      const name = `${suggestItem?.name}${
        suggestItem?.surname ? ` ${suggestItem?.surname}` : ''
      }`;
      const isBorkerUser =
        suggestItem?.profile?.type_advertiser?.toLowerCase() === 'broker';
      const enterpriseName = suggestItem?.profile?.enterprise ?? name;

      if (isBorkerUser) {
        if (enterpriseName?.trim() !== value?.trim()) {
          setSuggestItem({});
        }
      } else {
        if (name?.trim() !== value?.trim()) {
          setSuggestItem({});
        }
      }
    }
  };

  /* -------------------------------- */
  /* -------------------------------- */
  return (
    <Dash button={'user'} title={'Propostas'}>
      <div style={{display: display}}>
        <ActionsContainer>
          <Grid
            container
            spacing={2}
            style={{marginBottom: '30px', marginTop: '30px'}}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <SearchComponent
                value={filterName}
                handleChange={setFilterName}
                handleBlur={handleBlurSearch}
                handleSubmit={handleFilter}
                placeholder="Buscar por usuários..."
                setSuggestItem={setSuggestItem}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <DatePickerCustom
                currentValue={dateStart}
                handleDateChange={setDateStart}
                placeholder="Data inicial..."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <DatePickerCustom
                currentValue={dateEnd}
                handleDateChange={setDateEnd}
                placeholder="Data final..."
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            style={{marginBottom: '30px', marginTop: '30px'}}>
            <Filters
              value={tag}
              setSelectedValue={setTag}
              resetPage={setPage}
            />
          </Grid>
        </ActionsContainer>
        <ReportContainer>
          {/* <Amount hasPositive={amount > 0}>
            Valor total filtrado <strong>U{formatPrice(amount)}</strong>
          </Amount>
          {subscriptions.length > 0 && (
            <MyCSVDownload
              data={csvData}
              handleGenerate={generateReports}
              loading={hasSubmitting}
              filename="PERMUTI_payments_reports.csv"
            />
          )} */}
        </ReportContainer>

        {query.toString() && (
          <>
            <ClearFiltersButton onClick={handleClearFilter}>
              Limpar filtros
            </ClearFiltersButton>
          </>
        )}
        {/* {totalResults > 0 && (
          <Results>{totalResults} resultados encontrados</Results>
        )} */}
        {/* {loading ? (
          <Loader />
        ) : subscriptions?.length > 0 ? (
          <> */}
        <MyProposal2
          query={query}
          setQuery={setQuery}
          status={tag}
          setStatus={setTag}
        />
      </div>
    </Dash>
  );
}
