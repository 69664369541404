import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import I18n from '~/i18n/i18';
/* import addMsgAction from '~/redux/action/action_add_user'; */
import getTypeProperties from '~/requests/Advertise/typeProperty';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute} from '~/utils';
import ModalEditSucess from '../../../../../components/ModalEditSucess';
import MandatoryField from '../Components/mandatoryField';
import NextPreview from '../Components/nextPreview';
import MenuChangeObject from '../NewComponents/MenuChanceObjectRequired';
import {TitlePage} from '../Photo/styles';
import {SaveAnnoucement} from '../Step/styles';
import {DivNext, Warning} from './styles';

import * as Sentry from '@sentry/react';

function Type({type, setTypeEn, setType, step, setStep, id}) {
  const [active, setActive] = useState(false);
  const view = useSelector((state) => state.step.data);
  const [itensBackend, setItensBackEnd] = useState([]);
  const [openEditSucess, setOpenEditSucess] = useState(false);
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/login`,
    state: {fromDashboard: true},
  });

  /*   {
    console.log(itensBackend);
  }
 */
  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  useEffect(() => {
    getTypeProperties(setItensBackEnd);
  }, []);

  useEffect(() => {
    if (type) setActive(true);
  }, [type]);

  async function update(value, setActive) {
    setActive(false);

    console.log(type);
    let token = desencrypta(localStorage.getItem('@User_informations'));
    console.log(token);
    apiBackend
      .put(
        `/v1/property/${id}/edit`,
        {
          property_type_id: type.id,
          step,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        setActive(true);
        setOpenEditSucess(true);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  function handleProx(event) {
    event.preventDefault();
    let value = Number.parseInt(step);
    value = (value + 1).toString();
    setActive(false);
    update(value, setActive);
  }

  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="Type.what" />
          </TitlePage>
          <Warning>
            <I18n t="Type.select" />
          </Warning>
          <MandatoryField />

          <MenuChangeObject
            itensBackend={itensBackend}
            placeHolder={I18n.getTranslation(location, 'Type.placeholder')}
            label={I18n.getTranslation(location, 'Type.rotule')}
            value={type}
            setValue={setType}
          />
        </div>
        <DivNext>
          <NextPreview
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
        <ModalEditSucess
          func={() => {
            window.history.back();
          }}
          open={openEditSucess}
          setOpen={setOpenEditSucess}
        />
      </div>
    </>
  );
}

export default Type;
