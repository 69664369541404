import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';

import {ModalResponsive} from '../../Components/styles';
import {
  InternDiv,
  TitleModalGreen,
  NumberBall,
  DivTip,
  TextTip,
} from './styles';
import {ButtonInfo} from '../styles';
import I18n from '~/i18n/i18';

export default function ModalTips({open, setOpen}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}>
          <TitleModalGreen>
            <I18n t="TipsPhotos.tipTitle" />
            <CloseIcon onClick={() => handleClose()} className="iconClose" />
          </TitleModalGreen>
          <ModalResponsive>
            <InternDiv>
              <DivTip>
                <div>
                  <NumberBall>1</NumberBall>
                </div>

                <TextTip>
                  <I18n t="TipsPhotos.one" />
                </TextTip>
              </DivTip>
              <DivTip>
                <div>
                  <NumberBall>2</NumberBall>
                </div>

                <TextTip>
                  <I18n t="TipsPhotos.two" />
                </TextTip>
              </DivTip>
              <DivTip>
                <div>
                  <NumberBall>3</NumberBall>
                </div>

                <TextTip>
                  <I18n t="TipsPhotos.three" />
                </TextTip>
              </DivTip>
              <ButtonInfo onClick={(event) => handleClose()}>
                <I18n t="TipsPhotos.button" />
              </ButtonInfo>
            </InternDiv>
          </ModalResponsive>
        </Dialog>
      </div>
    </>
  );
}
