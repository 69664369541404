import {Grid} from '@material-ui/core';
import React, {useEffect} from 'react';
import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import MyAds404 from '../404';
import MyAdsLoading from '../Loading';
import SearchComponent from '../SearchComponent';
import TableClients from './Table';

// import { Container } from './styles';

function Attended({users, getAllUsers}) {
  return (
    <>
      {/* <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
        <SearchComponent
          value={filterName}
          handleChange={setFilterName}
          // handleSubmit={handleFilter}

          placeholder="Buscar imobiliárias"
        />
      </Grid> */}

      <TableClients getAllLeads={getAllUsers} information={users} />
    </>
  );
}

export default Attended;
