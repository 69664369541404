import React, {useState, useEffect} from 'react';
import ReactLoading from 'react-loading';
import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from '@material-ui/core';
import colors from '~/UI/colors';

import {
  Container,
  InternDiv,
  TitleModal,
  TitlePage,
  Hero,
  Card,
  LoadingIndicator,
} from './styles';

import {makeStyles} from '@material-ui/core';
import apiBackend from '~/services/apiaxios';

import {desencrypta} from '~/utils';
import {translateProfileType} from '~/utils/profileType';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '420px!important',
    width: '100%!important',
    marginLeft: '0!important',
    marginRight: '0!important',
  },
}));

export default function ModalPlanDetail({sub, setSub, open, setOpen}) {
  const classes = useStyles();
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSub(null);
  };

  useEffect(() => {
    async function getPlanData() {
      try {
        setLoading(true);
        const token = desencrypta(localStorage.getItem('@User_informations'));
        const {data} = await apiBackend.get(
          `/v1/plan/${sub?.subscriber?.plan_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setPlan(data?.plan);
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        setLoading(false);
      }
    }

    if (open && sub) {
      getPlanData();
    }
  }, [open, sub]);

  return (
    <div>
      <Dialog
        scroll="body"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        classes={{paper: classes.paper}}>
        <Container>
          <InternDiv>
            <TitleModal>
              <TitlePage>Tipo de assinatura</TitlePage>{' '}
              <CloseIcon onClick={handleClose} className={'iconClose'} />
            </TitleModal>
            {loading ? (
              <LoadingIndicator>
                <p>Carregando informações...</p>
                <ReactLoading
                  type={'spin'}
                  className="load"
                  color={colors.green_secondary}
                  width={'30px'}
                />
              </LoadingIndicator>
            ) : (
              <>
                <Hero>
                  <span>
                    {translateProfileType(sub?.user?.profile?.type_advertiser)}
                  </span>
                  <p>
                    {sub?.user?.profile?.type_advertiser?.toLowerCase() ===
                    'broker' ? (
                      <>{sub?.user?.profile?.enterprise}</>
                    ) : (
                      <>
                        {sub?.user?.name} {sub?.user?.surname ?? ''}
                      </>
                    )}
                  </p>
                </Hero>
                <Card>
                  <header>
                    <strong>{plan?.name}</strong>
                  </header>
                  <footer>
                    <p className="plan__price">
                      <strong>
                        {plan?.price}{' '}
                        <span style={{textTransform: 'uppercase'}}>
                          {plan.currency}
                        </span>
                      </strong>{' '}
                      {plan?.periodicity_count !== null
                        ? `/ ${plan?.periodicity_count} mês`
                        : `/ Período ilimitado`}
                    </p>
                    {/*   <p className="plan__quantity">
                      <strong>{plan?.ads_quantity}</strong> imóveis
                    </p> */}

                    {plan?.ads_quantity ? (
                      <>
                        <p className="plan__quantity">
                          <strong>{plan?.ads_quantity}</strong>{' '}
                          {plan?.ads_quantity > 1 ? 'Imóveis' : 'Imóvel'}
                        </p>
                      </>
                    ) : (
                      <p className="plan__quantity">Imóveis ilimitados</p>
                    )}
                  </footer>
                </Card>
              </>
            )}
          </InternDiv>
        </Container>
      </Dialog>
    </div>
  );
}
