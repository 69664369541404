import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const ButtonDetails = styled.button`
  background-color: ${(props) =>
    props.value ? colors.green_secondary : colors.gray_primary};

  color: ${(props) => (props.value ? colors.white : colors.dark)};
  font-weight: ${(props) => (props.value ? 551 : 500)};
  border-radius: 5px;
  border: ${colors.green_secondary} 1px solid;
  min-height: 40px;
  margin-bottom: 20px;
  margin-right: 10px;
  flex: 1 1 30%;
  text-align: center;
  font-size: 16px;
  font-family: ${fonts.latoBlack};

  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const InputAdd = styled.input`
  border: rgba(80, 178, 117, 1) 1px solid;
  background-color: ${colors.white};
  height: 40px;
  color: rgba(29, 29, 27, 1);
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
  &:focus {
    outline: 0;
  }
  text-align: center;
`;

export const ButtonAdd = styled.button`
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  height: 40px;
  text-align: center;
  color: ${colors.green_secondary};
  font-size: 18px;
  border: none;

  font-family: ${fonts.latoBlack};

  width: 100%;

  &:hover {
    background: #00000033;
  }
  &:disabled {
    background: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
`;

// background - color: ${ props => props.value ? colors.white : colors.green_secondary };
// color: ${ props => props.value ? colors.dark : colors.white };
// font - weight: ${ props => props.value ? 500 : 551 };
