import styled from 'styled-components';

import colors from '../../../UI/colors';
import fonts from '../../../UI/fonts';

export const DivGoogle = styled.div`
  .googleautocomplete {
    background: ${colors.white};
    border: none;
    color: ${colors.dark};
    box-shadow: 0px 3px 6px #00000033;
    width: 100%;
    height: 50px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    padding-left: 20px;
    font-size: 16px;
    font-family: ${fonts.latoRegular};

    &:focus {
      outline: 0;
    }
    &::-webkit-input-placeholder {
      color: ${colors.dark};

      /* padding-left: 30px; */
      font-family: ${fonts.latoRegularItalic};
    }
  }
`;
