import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.div`
  font-family: ${fonts.latoRegular};
  background: ${colors.white};
  padding: 20px;
  color: #1d1d1b;
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 5px;
  font-size: 16px;

  .iconEdit {
    margin-right: 36px;
  }

  .iconsSet {
    display: flex;
    flex-direction: row;
  }

  .icon:hover {
    color: ${colors.gray_secondary};
    cursor: pointer;
  }

  .iconEdit:hover {
    color: ${colors.gray_secondary};
    cursor: pointer;
  }

  .title {
    color: #1d1d1b;
    font-family: ${fonts.latoRegularBold};
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .content {
    display: ${(props) => (props.state ? 'block' : 'none')};
    transition: ${(props) => (props.state ? '2s' : '2s')};
    margin-top: 10px;
  }

  .first {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .second {
    margin-top: 20px;
  }

  .second10 {
    margin-top: 10px;
  }
  .secondTitle {
    font-family: ${fonts.latoRegularBold};
    color: ${colors.green_secondary};
  }

  .information {
    color: #1d1d1b;
    margin-left: 10.2px !important;
  }
`;
