import {createI18n} from 'react-router-i18n';

import pt from './translations/pt.json';

const locales = ['pt'];

const translations = {
  pt: {
    ...pt,
    Time: ({day, month, year}) => `${day}/${month}/${year}`,
  },
};

const I18n = createI18n(locales, translations);

export default I18n;
