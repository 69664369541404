import React from 'react';
import SearchIcon from '~/assets/search-icon.svg';
import AutosuggestSubscribers from './autoComplete';
import * as S from './styles';

function SearchComponent({placeholder, value, handleChange}) {
  return (
    <S.Container>
      {/* <AutosuggestSubscribers
        value={value}
        handleChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
      /> */}
      <S.AutoSuggestContainer>
        <input
          placeholder={placeholder}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
        />
      </S.AutoSuggestContainer>
    </S.Container>
  );
}

export default SearchComponent;
