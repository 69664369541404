import styled from 'styled-components';
import colors from './colors';
import fonts from './fonts';

export const Container = styled.div`
  background: #fff;
  margin-top: 30px;
  min-height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

export const InputField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ButtonLarge = styled.button`
  height: 50px;
  color: ${colors.white};
  box-shadow: 0px 1px 3px #00000066;
  border-radius: 5px;
  border: none;
  font-family: ${fonts.robotoBlack};
  transition: 0.2s all ease;

  &:hover {
    border: none;
  }
  &:focus {
    outline: 0;
  }
`;

export const ButtonModal = styled(ButtonLarge)`
  background-color: ${colors.green_secondary};
  width: 400px;
  font-weight: 700;
  font-size: 22px;
  &:hover {
    background-color: ${colors.green_third};
  }
  &:disabled {
    background-color: ${colors.gray_secondary};
    color: ${colors.white};
  }
  /* text-align: center; */
`;

export const ButtonSwitch = styled.button`
  color: ${(props) => (props.active ? colors.green_secondary : colors.dark)};
  font-size: ${fonts.bigs};
  text-align: center;
  border: none;
  border-bottom: ${(props) =>
    props.active
      ? '3px solid ' + colors.green_secondary
      : '3px solid ' + colors.gray_secondary};
  background-color: ${colors.white};
  padding: 20px;
  &:hover {
    color: ${colors.green_third};
    border-bottom: 3px solid ${colors.green_third};
  }
  &:focus {
    outline: 0;
  }
`;

export const ButtonMedium = styled(ButtonLarge)`
  background-color: ${colors.green_secondary};
  width: 230px;
  font-size: ${fonts.bigs};
  font-weight: 900;

  &:hover:not(:disabled) {
    background-color: ${colors.green_third};
  }
  &:active {
    background-color: ${colors.green_third};
    color: ${colors.green_primary};

    &:hover {
      background-color: ${colors.green_third};
      color: ${colors.green_primary};
    }
  }
  &:disabled {
    background-color: ${colors.gray_secondary};
    color: ${colors.white};
    cursor: not-allowed;
  }
`;

export const ButtonSmall = styled(ButtonMedium)`
  width: 100%;
`;

export const ButtonSmallWhite = styled(ButtonMedium)`
  width: 100%;
  background: #fff;
  color: ${colors.green_secondary};

  &:hover:not(:disabled) {
    background-color: ${colors.gray_primary};
  }

  &:active {
    background-color: ${colors.green_third};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.green_third};
      color: ${colors.white};
    }
  }
  &:disabled {
    background-color: ${colors.gray_secondary};
    color: ${colors.white};
  }
`;

export const ButtonTransparent = styled(ButtonSmall)`
  background: transparent;
  color: ${colors.green_secondary};
  box-shadow: none;
  border: none;
  transition: 0.2s all ease;

  &:hover {
    color: ${colors.white};
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export const InputSearch = styled.input`
  background: ${colors.white};
  border: none;
  color: ${colors.dark};
  box-shadow: 0px 3px 6px #00000033;
  width: 100%;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-family: ${fonts.latoRegular};
  outline: 0;

  &::placeholder {
    color: ${colors.dark};
    font-family: ${fonts.latoRegularItalic};
    font-style: italic;
  }
`;

export const Select = styled.select`
  background: ${colors.white};
  border: none;
  color: ${colors.dark};
  box-shadow: 0px 3px 6px #00000033;
  height: 50px;
  width: 200px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-size: 16px;
  font-family: ${fonts.latoRegular};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};
    font-style: italic;
    font-size: 14px;
    padding-left: 30px;
    font-display: italic;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  /* color: ${(props) =>
    props.valid ? colors.green_secondary : colors.dark_light}; */
  font-weight: bold;
  font-size: 14px;
  margin-top: 19.5px;

  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: ${fonts.latoRegularBold};
`;

export const LabelEmail = styled(Label)`
  color: ${(props) => props.colorEmail};
`;

export const Input = styled.input`
  width: 400px;
  height: 29px;
  border: none;
  border-bottom: 2px solid;
  color: ${colors.dark};
  font-size: 16px;
  /*  border-color: ${(props) =>
    props.value ? colors.green_secondary : colors.dark_light}; */
  /* padding-bottom: 10px;
  padding-left: 0; */

  font-family: ${fonts.latoRegular};
  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};
    font-style: italic;
    font-size: 16px;
  }
`;

export const InputEmail = styled(Input)`
  border-color: ${(props) => props.colorEmail};
`;

export const InputPass = styled(Input)`
  border-color: ${(props) => props.colorEmail};
`;

export const PageTitle = styled.p`
  font-size: 18px;
  color: ${colors.dark};
  font-weight: bold;
`;

export const DivTitle = styled.div`
  text-align: left !important;
  width: 300px;
  margin-top: 10px;
`;

export const Error = styled.p`
  font-size: 12px;
  color: ${colors.danger};
`;

export const ErrorPass = styled(Error)`
  margin-top: 27px;
`;

export const Icons = styled.div`
  margin-right: 20px;
  background: transparent;
  height: 45px;
  width: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  transition: background 0.2s linear, color 0.2s linear;
  cursor: pointer;

  &:hover {
    color: ${colors.green_secondary};
    background-color: ${colors.gray_secondary};
  }
  &:active {
    color: ${colors.white};
    background-color: ${colors.green_secondary};
  }
`;

export const ButtonEditTable = styled.button`
  border: none;
  background-color: ${colors.white};
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0px 3px 5px #00000040;
  width: 120px;
  height: 40px;
  color: ${colors.green_secondary};
  font-family: ${fonts.latoBlack};

  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: #2e6643;
    color: #e6ffef;
    box-shadow: 0px 0px 3px #00000033;
  }
  &:focus {
    outline: 0;
  }
`;

export const ButtonCreateAds = styled(ButtonEditTable)`
  width: 200px;
  height: 40px;
  box-shadow: 0px 3px 6px #00000066;
  border-radius: 10px;
`;

export const ButtonCreatePlan = styled(ButtonCreateAds)`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  &:hover {
    background-color: ${colors.green_third};
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: 2px solid #e6ffef;
    box-shadow: 0px 0px 3px #00000033;
  }
  &:focus {
    outline: 0;
  }
`;

export const ViewAd = styled.button`
  border: none;
  background-color: transparent;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 3px 5px #00000040;
  width: 120px;
  height: 40px;
  color: ${colors.green_secondary};
  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: #2e6643;
    color: #e6ffef;
    box-shadow: 0px 0px 3px #00000033;
  }
  &:focus {
    outline: 0;
  }
`;

export const DivLoad = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;
`;

export const Card = styled.div``;

export const ButtonAdd = styled.button`
  background-color: transparent;
  border: none;
  color: #1d1d1b;
  &:focus {
    outline: none;
  }
`;
