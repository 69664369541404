import React, {useState, useEffect} from 'react';

import ReactLoading from 'react-loading';
import {
  TitleModal,
  InternDiv,
  Rotule,
  DivInputText,
  InputText,
  DivError,
  ErrorMessage,
  ButtonSave,
} from '../ModalDataPersonal/styles';
import '../styles.css';

import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from '@material-ui/core';
import colors from '~/UI/colors';

export default function ModalSocial({
  open,
  setOpen,
  profile,
  handleSubmit,
  loading,
}) {
  const [website, setWebsite] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');

  useEffect(() => {
    setWebsite(profile?.profile?.website ? profile?.profile?.website : '');
    setInstagram(
      profile?.profile?.instagram ? profile?.profile?.instagram : '',
    );
    setFacebook(profile?.profile?.facebook ? profile?.profile?.facebook : '');
    setLinkedin(profile?.profile?.linkedin ? profile?.profile?.linkedin : '');
  }, [profile]);

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const resetForm = () => {
    setWebsite(profile?.profile?.website ? profile?.profile?.website : '');
    setInstagram(
      profile?.profile?.instagram ? profile?.profile?.instagram : '',
    );
    setFacebook(profile?.profile?.facebook ? profile?.profile?.facebook : '');
    setLinkedin(profile?.profile?.linkedin ? profile?.profile?.linkedin : '');
    setErrors({});
    setTouched({});
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  /* -------------- */
  /* -------------- */
  /* -------------- */

  /* Website */
  const handleChangeWebsite = (value) => {
    setWebsite(value);

    setErrors((errors) => {
      return {
        ...errors,
        website: value.trim() && value.trim().length < 7 ? 'URL inválida' : '',
      };
    });
  };

  const handleBlurWebsite = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        website: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        website: value.trim() && value.trim().length < 7 ? 'URL inválida' : '',
      };
    });
  };

  /* Instagram */
  const handleChangeInstagram = (value) => {
    setInstagram(value);

    setErrors((errors) => {
      return {
        ...errors,
        instagram:
          value.trim() && value.trim().length < 2
            ? 'Nome de usuário inválido'
            : '',
      };
    });
  };

  const handleBlurInstagram = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        instagram: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        instagram:
          value.trim() && value.trim().length < 2
            ? 'Nome de usuário inválido'
            : '',
      };
    });
  };

  /* Facebook */
  const handleChangeFacebook = (value) => {
    setFacebook(value);

    setErrors((errors) => {
      return {
        ...errors,
        facebook:
          value.trim() && value.trim().length < 14 ? 'URL inválida' : '',
      };
    });
  };

  const handleBlurFacebook = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        facebook: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        facebook:
          value.trim() && value.trim().length < 14 ? 'URL inválida' : '',
      };
    });
  };

  /* Linkedin */
  const handleChangeLinkedin = (value) => {
    setLinkedin(value);

    setErrors((errors) => {
      return {
        ...errors,
        linkedin:
          value.trim() && value.trim().length < 14 ? 'URL inválida' : '',
      };
    });
  };

  const handleBlurLinkedin = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        linkedin: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        linkedin:
          value.trim() && value.trim().length < 14 ? 'URL inválida' : '',
      };
    });
  };
  /* -------------- */
  /* -------------- */
  /* -------------- */

  function editSocial(event) {
    event.preventDefault();

    const userUpdated = {
      profile: {
        website: website.trim(),
        instagram: instagram.trim(),
        facebook: facebook.trim(),
        linkedin: linkedin.trim(),
      },
    };
    handleSubmit(userUpdated);
  }

  return (
    <div>
      <Dialog
        scroll="body"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <TitleModal>
          <span>Redes Sociais</span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <form onSubmit={editSocial} style={{padding: '20px'}}>
          <InternDiv>
            <div>
              <DivInputText>
                <Rotule error={errors.website} valid={website.length}>
                  Website
                </Rotule>
                <InputText
                  value={website}
                  error={errors.website}
                  touched={touched.website}
                  onChange={(event) => handleChangeWebsite(event.target.value)}
                  onBlur={(event) => handleBlurWebsite(event.target.value)}
                  placeholder="www.exemplo.com"
                />
                {errors.website && (
                  <DivError>
                    <ErrorMessage>{errors.website}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.instagram} valid={instagram}>
                  Instagram
                </Rotule>
                <InputText
                  value={instagram}
                  error={errors.instagram}
                  touched={touched.instagram}
                  onChange={(event) =>
                    handleChangeInstagram(event.target.value)
                  }
                  onBlur={(event) => handleBlurInstagram(event.target.value)}
                  placeholder="@exemplo"
                />
                {errors.instagram && (
                  <DivError>
                    <ErrorMessage>{errors.instagram}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.facebook} valid={facebook}>
                  Facebook
                </Rotule>
                <InputText
                  value={facebook}
                  error={errors.facebook}
                  touched={touched.facebook}
                  onChange={(event) => handleChangeFacebook(event.target.value)}
                  onBlur={(event) => handleBlurFacebook(event.target.value)}
                  placeholder="facebook.com/exemplo"
                />
                {errors.facebook && (
                  <DivError>
                    <ErrorMessage>{errors.facebook}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.linkedin} valid={linkedin}>
                  Linkedin
                </Rotule>
                <InputText
                  value={linkedin}
                  error={errors.linkedin}
                  touched={touched.linkedin}
                  onChange={(event) => handleChangeLinkedin(event.target.value)}
                  onBlur={(event) => handleBlurLinkedin(event.target.value)}
                  placeholder="linkedin.com/in/exemplo"
                />
                {errors.linkedin && (
                  <DivError>
                    <ErrorMessage>{errors.linkedin}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>
            </div>
            <ButtonSave
              type="submit"
              disabled={loading}
              className={'btnDataPersonal btnload'}>
              {loading ? (
                <ReactLoading
                  type={'spin'}
                  className="load"
                  color={colors.green_secondary}
                  width={'30px'}
                />
              ) : (
                'Editar'
              )}
            </ButtonSave>
          </InternDiv>
        </form>
      </Dialog>
    </div>
  );
}
