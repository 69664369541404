import styled from 'styled-components';
import {ButtonMedium} from '../../../UI/styles';

import colors from '../../../UI/colors';
import fonts from '../../../UI/fonts';
import metrics from '../../../UI/metrics';

export const DivImg = styled.div`
  margin-left: 30px;
  display: flex;
`;

export const NavBar = styled.div`
  width: 100%;
  background-color: ${colors.green_secondary};
  display: flex;
  justify-content: center;

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }
`;

export const LogoProfile = styled.img`
  width: 115px;
  height: 30.06px;
`;

export const ButtonGreen = styled(ButtonMedium)`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  &:hover {
    color: ${colors.green_secondary};
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const TitlePage = styled.div`
  font-weight: bold;
  font-size: 30px;
  font-family: ${fonts.latoBlackItalic};

  @media (max-width: 800px) {
    font-size: 22px;
  }

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const ImageLogo = styled.img`
  height: 35px;
  width: 115px;
  pointer-events: all;
`;

export const GoBack = styled.div`
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: rgba(255, 255, 255, 0.4);
  color: ${colors.white};
  margin-right: 45px;
  transition: background 0.2s ease;

  svg {
    width: 32px;
    height: 32px;
  }
  &:hover {
    background-color: ${colors.green_third};
  }

  @media (max-width: 517px) {
    margin-right: 20px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  margin-left: 150px;
  font-family: ${fonts.latoRegularBold};
  color: ${colors.white};

  @media (max-width: 700px) {
    font-size: 20px;
    margin-left: 70px;
  }

  @media (max-width: 600px) {
    text-align: center;
    margin: 10px auto 0;
    display: block;
  }
`;

export const FirstPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavBarContent = styled.div`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 5px 140px;

  @media (max-width: 873px) {
    padding: 5px 60px;
  }
  @media (max-width: 700px) {
    padding: 5px 40px;
  }

  @media (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }

  @media (max-width: 517px) {
    padding: 5px 20px;
  }
`;
