import React, { useState, useEffect } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';

import styled from 'styled-components';
import colors from '../../../../../UI/colors';
import { Star } from './styles';

const useStyles = makeStyles({
    root: {
        marginTop: '60px',
    },
    item: {
        width: '300px',
        marginTop: '10px',
        "&:hover": {
            backgroundColor: colors.green_primary,
            color: colors.green_secondary,
        }
    },
    itemBottom: {
        width: '200px',
        marginTop: '10px',
        color: colors.green_secondary,
        "&:hover": {
            backgroundColor: colors.green_primary,
            color: colors.green_third,
        }
    }
});

const ITEM_HEIGHT = 48;

export default function SelectMenuRequired(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [choose, setChoose] = useState(props.value);
    const [viewMesssage, setViewMessage] = useState(false)

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    function handleClickChoose(item) {
        setAnchorEl(null)
        props.setValue(item);
        setViewMessage(false);
    }

    function closed() {
        setAnchorEl(null);
        setOpenCollapse(!openCollapse);
        if (props.value) {
            setViewMessage(false)
        } else {
            setViewMessage(true)
        }
    }



    useEffect(() => {
        props.value ? setChoose(props.I18n[props.value]) : setChoose(props.value)
    }, [props.value])


    return (
        <div style={{ minWidth: '200px', marginBottom: '20px' }}>
            <Label color={choose}>{props.label} <Star>*</Star></Label>
            <ButtonFilter disabled={props.disable} type='button' itens={props.itens} color={choose} onClick={handleClick}>{choose ? choose : props.placeHolder} {anchorEl ? <ExpandLess /> : <ExpandMore />}</ButtonFilter>
            <Menu
                className={classes.root}
                style={{}}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => { closed() }}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,


                    },
                }}
            >
                {props.itens.map((item, key) => {
                    return <MenuItem key={key} className={classes.item} onClick={() => handleClickChoose(item)}>{props.I18n[item]}</MenuItem>
                })}
            </Menu>
            {viewMesssage ? <Message>
                Campo Obrigatório
            </Message> : null}
        </div>
    );
}



const ButtonFilter = styled.button`
  height: 50px;
  
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${props => props.color ? '2px solid ' + colors.green_secondary : '#808076 1px solid'};
  color:  ${props => props.color ? '#1D1D1B' : '#808076'};
  width: 100%;
  font-size: 18px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus{
        outline: 0;    
  }
  margin-bottom: 10px;
  padding-left: 0;
  
   /* @media (min-width: 781px) and (max-width: 2560px) {
    max-width: 380px;
              
    }
    @media(max-width: 480px) {
        max-width: 140px;
    } */

`;

const Label = styled.div`
  font-weight: bold;
  color:  ${props => props.color ? colors.green_secondary : '#808076'};
`

const Message = styled.div`
  font-weight: bold;
  font-size: 13px;
  color: red;
`