import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import I18n from '~/i18n/i18';
import apiBackend from '~/services/apiaxios';
import {desencrypta, getRoute, mountYearSelect} from '~/utils';
import ModalEditSucess from '../../../../../../components/ModalEditSucess';
import InputText from '../../Components/inputText';
import MandatoryField from '../../Components/mandatoryField';
/* import addMsgAction from '~/redux/action/action_add_user'; */
import NextPreview from '../../Components/nextPreview';
import RadioSlide from '../../Components/radioButton';
import SelectMenuI18n from '../../Components/selectMenuI18n';
import SelectMenuRequiredI18n from '../../Components/selectMenuRequiredI18n';
import {TitlePage} from '../../Photo/styles';
import {SaveAnnoucement} from '../../Step/styles';
import {requiredFarm, viewStyleInput} from '../../Verifications';
import {DivNext, DivRadio, DivRadioText} from './styles';

import * as Sentry from '@sentry/react';

import SelectYearBuilt from '../../PropertyFeatures/PropertyTwo/Select/selectNumber';

const itensPhaseEN = [
  'Ready to move in',
  'Renovated',
  'Under Construction',
  'On the Plant',
];

const yearOptions = mountYearSelect();

function Status({
  id,
  step,
  setStep,
  year_build,
  setYear_build,
  property_phase,
  setProperty_phase,
  furnished,
  setFurnished,
  appliances,
  setAppliances,
  decorated,
  setDecorated,
  type,
  typeEn,
  setValueP,
}) {
  const [active, setActive] = useState(true);
  const view = useSelector((state) => state.step.data);
  const viewFaseConstructor = viewStyleInput(typeEn);
  const requireFaseConstructor = requiredFarm(typeEn);
  const [openEditSucess, setOpenEditSucess] = useState(false);
  const itensPhase = itensPhaseEN;
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/advertise`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  async function updateStatus() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend
      .put(
        `/v1/property/${id}/edit`,
        {
          year_build,
          property_phase,
          furnished,
          appliances,
          decorated,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        // dispatch(addMsgAction(true));
        setOpenEditSucess(true);

        // dispatch(addMsgAction(true));
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  useEffect(() => {
    setActive(true);
  }, [property_phase]);

  function handleProx(event) {
    event.preventDefault();

    updateStatus();
  }

  function mask(e, setValue) {
    setValue(e.target.value.replace(/\D/g, ''));
  }

  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="Status.title" />
          </TitlePage>

          <MandatoryField />
          {viewFaseConstructor ? (
            requireFaseConstructor ? (
              <SelectMenuI18n
                i18n="ItensPhases"
                placeHolder={<I18n t="Status.placeHolderPhase" />}
                label={<I18n t="Status.labelPhase" />}
                itens={itensPhase}
                value={property_phase}
                setValue={setProperty_phase}
              />
            ) : (
              <SelectMenuRequiredI18n
                i18n="ItensPhases"
                placeHolder={<I18n t="Status.placeHolderPhase" />}
                label={<I18n t="Status.labelPhase" />}
                itens={itensPhase}
                value={property_phase}
                setValue={setProperty_phase}
              />
            )
          ) : null}

          {/*  <InputText
            mask={mask}
            placeHolder={I18n.getTranslation(
              location,
              'Status.placeHolderYear',
            )}
            label={<I18n t="Status.constructYear" />}
            value={year_build}
            setValue={setYear_build}
          /> */}

          <SelectYearBuilt
            placeHolder={I18n.getTranslation(
              location,
              'Status.placeHolderYear',
            )}
            label={<I18n t="Status.constructYear" />}
            itens={yearOptions}
            value={year_build}
            setValue={setYear_build}
          />

          {viewFaseConstructor ? (
            <>
              {' '}
              <DivRadio>
                <DivRadioText value={furnished}>
                  <I18n t="Status.furnished" />{' '}
                </DivRadioText>
                <RadioSlide
                  noMargin
                  location={location}
                  state={furnished}
                  setState={setFurnished}
                />
              </DivRadio>
              <DivRadio>
                <DivRadioText value={appliances}>
                  <I18n t="Status.appliances" />{' '}
                </DivRadioText>
                <RadioSlide
                  noMargin
                  location={location}
                  state={appliances}
                  setState={setAppliances}
                />
              </DivRadio>
              <DivRadio>
                <DivRadioText value={decorated}>
                  <I18n t="Status.decorated" />{' '}
                </DivRadioText>
                <RadioSlide
                  noMargin
                  state={decorated}
                  setState={setDecorated}
                />
              </DivRadio>
            </>
          ) : null}
        </div>

        <DivNext>
          <NextPreview
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>
      <ModalEditSucess
        func={() => {
          window.history.back();
        }}
        open={openEditSucess}
        setOpen={setOpenEditSucess}
      />
    </>
  );
}

export default Status;
