import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {TitleModal, DivName, PInfo} from '../../views/User/styles';
import CloseIcon from '@material-ui/icons/Close';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    borderColor: 'none',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 0,
  },
}));

export default function ModalColab({modalInfo, modal, setModal}) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div
        onClick={handleClose}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
        }}>
        {' '}
        <CloseIcon />
      </div>

      <TitleModal>Informações do(a) Colaborador(a)</TitleModal>
      <DivName>{modalInfo?.name}</DivName>
      <PInfo>
        Tipo de Acesso
        <span style={{fontSize: '16px', marginLeft: '10px'}}>
          {modalInfo?.role_id}
        </span>
      </PInfo>
      <PInfo>
        Cargo{' '}
        <span style={{fontSize: '16px', marginLeft: '10px'}}>
          {modalInfo?.cargo}
        </span>
      </PInfo>
      <PInfo>
        Contato{' '}
        <span style={{fontSize: '16px', marginLeft: '10px'}}>
          {modalInfo?.cellphone}
        </span>
      </PInfo>
      <PInfo>
        Email{' '}
        <span style={{fontSize: '16px', marginLeft: '10px'}}>
          {modalInfo?.email}
        </span>
      </PInfo>
    </div>
  );

  return (
    <div style={{border: 'none'}}>
      <Modal
        open={modal}
        onClose={handleClose}
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
    </div>
  );
}
