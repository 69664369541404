import styled from 'styled-components';

import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const Container = styled.div`
  width: 400px;
  height: 640px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: ${fonts.latoRegular};
`;

export const InternDiv = styled.div`
  padding: 0px 40px 20px;

  max-width: 900px;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0 20px 20px;
  }
`;

export const TitleModal = styled.span`
  padding-top: 20px;
  padding-right: 20px;
  color: ${colors.green_secondary};

  font-size: 24px;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  text-align: center;

  svg {
    position: absolute;
    top: 15px;
    right: 24px;
  }
`;

export const DivInputText = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const DivAlterImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonWhite = styled.button`
  background-color: #ffffff;
  border: none;
  height: 50px;
  width: 40%;
  margin-top: 45px;
  border-radius: 5px;
  color: ${colors.green_secondary};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  &:focus {
    outline: 0;
  }
  box-shadow: 0px 3px 6px #00000033;
  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: #e6ffef 2px solid;
  }
`;

export const ButtonGreen = styled(ButtonWhite)`
  background-color: ${colors.green_secondary};
  color: ${colors.white};
  width: 100%;
  &:hover {
    background-color: ${colors.green_third};
  }
`;

export const TextButton = styled.span`
  margin-left: 10px;
`;

export const ModalResponsive = styled.div`
  @media (min-width: 481px) and (max-width: 700px) {
    width: 100%;
  }
  @media (min-width: 800px) and (max-width: 2560px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Message = styled.div`
  width: 100%;
  font-size: 16px;
`;
export const DivDanger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

export const AdvertTitle = styled.div`
  margin-bottom: 20px;
  color: #a88721;
  font-weight: bold;
  font-size: 24px;
  /*  font-family: 'Lato'; */
`;

export const DivConfirm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
`;

export const ButtonsGreen = styled.button`
  color: ${colors.white};
  background: ${colors.green_secondary};
  font-size: 18px;
  height: 40px;
  width: 100%;
  border: none;
  box-shadow: 0px 3px 6px #00000033;
  &:hover {
    background-color: #f2f2ed;
  }
  &:active {
    background-color: ${colors.green_third};
    color: #e6ffef;
    border: 1px solid #e6ffef;
  }
  &:focus {
    outline: 0;
  }
`;

export const DivNext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
`;

export const DivMetrics = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DivInputMetrics = styled.div`
  width: 90%;
`;

export const DivSelectMetrics = styled.div`
  width: 70px;
  height: 73px;
  border: none;
  border-bottom: ${(props) =>
    props.value
      ? `2px solid ${colors.green_secondary}!important`
      : '1px solid #808076!important'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -1px;
`;

export const DivText = styled.div`
  padding-top: 15px;
  text-align: center;
  color: #1d1d1b;
  font-size: 18px;
  /*   font-family: 'Lato'; */
  font-weight: 500;
`;

export const TitlePage = styled.p`
  font-size: 24px;
  font-family: ${fonts.latoBlackItalic};
  color: #484848;

  display: flex;
`;

export const TitleGrid = styled.div`
  font-family: ${fonts.latoRegularBold};

  font-size: 16px;
  color: #1d1d1b;
  margin-bottom: 20px;
`;
