import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.div``;

export const ButtonMedium = styled.button`
  width: 230px;
  height: 50px;

  font-family: 'Lato Black Original';
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-size: 18px;
  color: ${colors.white};
  background-color: ${colors.green_secondary};

  box-shadow: 0px 1px 3px #00000066;
  border: 1px solid ${colors.green_secondary};
  border-radius: 5px;
  outline: 0;

  transition: 0.2s all ease;

  &:hover:not(:disabled) {
    background-color: ${colors.gray_primary};
    border: 1px solid ${colors.gray_primary};
    color: ${colors.green_secondary};
  }
  &:active {
    background-color: ${colors.green_third};
    border: 1px solid ${colors.green_third};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.green_third};
      border: 1px solid ${colors.green_third};
      color: ${colors.white};
    }
  }
  &:disabled {
    background-color: ${colors.gray_secondary};
    border: 1px solid ${colors.gray_secondary};
    color: ${colors.white};
    cursor: not-allowed;
  }
`;

export const TitleModal = styled.span`
  padding: 20px 15px 20px 30px;
  width: 100%;
  box-sizing: border-box;
  font-size: 24px;
  color: ${colors.dark};
  font-family: 'Lato Black Italic';
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;

  > span {
    svg {
      padding-left: 15px;
    }
  }
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 10px 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const DivInputText = styled.div`
  width: 100%;

  svg {
    color: ${colors.dark_light};
  }

  .passEyes svg {
    position: relative !important;
    left: 92%;
    top: 35px;
    z-index: 1;
    cursor: pointer;
  }

  .passEyes {
    margin-top: -25px;
  }
`;

export const Rotule = styled.p`
  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: 'Lato Bold';
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const InputText = styled.input`
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  color: #1d1d1b;
  border: none;

  margin: 0px !important;
  font-family: 'Lato Regular';

  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};

  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};

    font-size: 16px;
  }
`;

export const DivError = styled.div`
  width: 100%;
  font-family: 'Lato Regular';
`;

export const ErrorMessage = styled.span`
  margin-top: 9.25px;

  color: ${colors.danger_dark};
  font-family: 'Lato Regular';
  font-weight: 500;
  font-size: 14px;
`;

export const ButtonSave = styled(ButtonMedium)`
  width: 100%;
  margin-top: 60px;
  font-size: 18px;
  font-family: ${fonts.latoRegular};
`;
