import styled from 'styled-components';
import colors from '~/UI/colors';
import fonts from '~/UI/fonts';

export const Container = styled.section`
  width: 100%;
  max-width: 1366px;
  min-height: 90vh;
  margin: 0 auto;
  padding: 30px 0px 80px;
  position: relative;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`;

export const SafeAreaView = styled.div`
  padding: 20px 30px;

  border-bottom: 1px solid #d9d9ce;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const EmojiContainer = styled.div`
  padding: 20px;
  > span {
    padding: 10px;
    border: 1px solid #ededed;
    border-radius: 5px;
    display: block;
  }
`;

export const EmojiButton = styled.button`
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid ${colors.green_secondary};
  box-sizing: border-box;
  font-family: 'Lato Bold';
  background: none;
  color: ${colors.green_secondary};
  transition: all 0.2s ease;

  &:hover:not(:active) {
    background: ${colors.gray_primary};
    border-color: ${colors.gray_primary};
  }

  &:active {
    background: ${colors.green_secondary};
    border-color: ${colors.green_secondary};
    color: ${colors.white};
  }
`;

export const Step = styled.div`
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
`;

export const TitleSection = styled.h2`
  color: ${colors.dark};
  font-family: ${fonts.latoBlackItalic};
  font-size: 24px;
  line-height: 29px;
  padding: 0px 8px;
  margin-bottom: 0;
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 20px 30px 20px;

  @media (max-width: 768px) {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const UserTypeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > strong {
    font-family: ${fonts.latoBlack};
    color: ${colors.dark};
    font-size: 16px;
    font-weight: normal;
    padding-right: 40px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    > strong {
      padding-right: 0;
      padding-bottom: 10px;
    }
  }
`;
/* ------------------------- */
/* ------------------------- */
/* ------------------------- */

export const Rotule = styled.p`
  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: ${fonts.latoRegularBold};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const DivInputText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  svg {
    color: ${colors.dark_light};
  }
`;

export const InputText = styled.input`
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  color: #1d1d1b;
  border: none;

  margin: 0px !important;
  font-family: ${fonts.latoRegular};

  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};

  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};

    font-size: 16px;
  }
`;

export const DivError = styled.div`
  width: 100%;
  font-family: ${fonts.latoRegular};
`;

export const ErrorMessage = styled.span`
  margin-top: 9.25px;

  color: ${colors.danger_dark};
  font-family: ${fonts.latoRegular};
  font-weight: 500;
  font-size: 14px;
`;

export const ButtonSave = styled.button`
  width: 100%;
  height: 50px;
  color: ${colors.white};
  font-size: 18px;
  background-color: ${colors.green_secondary};
  border: none;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0000004d;
  font-weight: bold;
  margin-top: 30px;
  font-family: ${fonts.latoBlack};

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
  &:hover:not(:disabled) {
    background: #2e6643;
  }
`;

export const ButtonCopy = styled.button`
  border-radius: 5px;
  background: ${colors.white};
  border: 1px solid ${colors.white};
  color: ${colors.green_secondary};
  box-shadow: 0px 3px 5px #00000033;
  padding: 10px 20px;
  box-sizing: border-box;
  font-family: ${fonts.latoBlack};
  font-size: 16px;
  line-height: 16px;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background: #f2f2ed;
    border-color: #f2f2ed;
  }

  &:active {
    background: ${colors.green_third};
    border-color: ${colors.green_third};
    color: ${colors.white};

    &:hover:not(:disabled) {
      background: ${colors.green_third};
      border-color: ${colors.green_third};
      color: ${colors.white};
    }
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid #d9d9ce !important;
    color: #d9d9ce;
    box-shadow: none;
  }
`;

export const ButtonAds = styled.button`
  min-width: 320px;
  height: 48px;
  letter-spacing: 2.4px;
  font-family: Lato Black;
  /* font-weight: bold; */
  color: #ffffff;
  font-size: 24px;
  border: 2px solid #50b275;
  line-height: 30px;
  border-radius: 5px;
  background-color: ${colors.green_secondary};
  box-shadow: 0px 3px 6px #00000033;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${colors.green_third};
    border: 2px solid ${colors.green_third};
    color: ${colors.green_secondary};
  }

  &:hover:not(:disabled) {
    background: #2e6643;
  }

  &:active {
    background: ${colors.green_third};
    border: 2px solid ${colors.green_third};
    color: #fff;

    &:hover {
      background: ${colors.green_third};
      border: 2px solid ${colors.green_third};
      color: #fff;
    }
  }
  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.gray_secondary};
    color: white;
  }
`;

export const PlanCard = styled.div`
  width: 320px;
  height: 105px;
  border-radius: 15px;
  border: 1px solid #d9d9ce;
  padding: 10px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .planName {
    font-size: 18px;
    font-family: ${fonts.latoBlackItalic};
  }

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price_plan {
      color: ${colors.green_secondary};
      font-size: 18px;
      font-family: ${fonts.latoBold};
    }

    .period {
      font-family: ${fonts.latoMedium};
      font-size: 14px;
    }
  }
`;
