import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import './styles.css';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  TableContainer,
  TitleBold,
  P,
  ActionsContainer,
  TitleBoldView,
  RowPhotoName,
  Results,
  RowClick,
} from './styles';
import {Icons} from '../../../../UI/styles';

import ModalEdit from '../Edit';
import ModalDelete from '../../../../components/ModalDelete';

import {translateProfileType} from '~/utils/profileType';
import SimpleModal from '~/components/Modal';
import ModalAdsForUser from '~/components/ModalAdsForUser';
import ModalDisableAccount from '~/components/ModalDisableAccount';
import ModalEnableAccount from '~/components/ModalEnableAccount';
import ManagerOptions from './ManagerOptions';

const permitted_roles = ['5'];

export default function TableClients({
  listUser,
  infoUser,
  setInfo,
  information,
  setModalRegister,
  total,
  loading,
  page,
  setPage,
  currency,
}) {
  const userStore = useSelector((state) => state.user);
  const [sortName, setSortName] = useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortStatus, setSortStatus] = useState(false);
  const [sortDate, setSortDate] = useState(false);

  const [proibid, setProibid] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [profile, setProfile] = useState({});

  const [user, setUser] = useState('');

  /* Delete modal */
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');

  /* Ads list modal */
  const [modalAdsList, setModalAdsList] = useState(false);

  /* Info modal */
  const [modalInfoVisibility, setModalInfoVisibility] = useState(false);
  const [userInfo, setUserInfo] = useState('');

  /* Disabled and ability account modal */
  const [modalDisableAccount, setModalDisableAccount] = useState(false);
  const [modalEnableAccount, setModalEnableAccount] = useState(false);

  function NameSort() {
    let object = [...information];
    let value = !sortName;
    setSortName(!sortName);

    setSortType(false);
    setSortStatus(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function TypeUserSort() {
    let object = [...information];
    let value = !sortType;
    setSortType(!sortType);

    setSortName(false);
    setSortStatus(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a?.profile?.type_advertiser > b?.profile?.type_advertiser) {
          return -1;
        }
        if (a?.profile?.type_advertiser < b?.profile?.type_advertiser) {
          return 1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a?.profile?.type_advertiser > b?.profile?.type_advertiser) {
          return 1;
        }
        if (a?.profile?.type_advertiser < b?.profile?.type_advertiser) {
          return -1;
        }
        return 0;
      });
    }
    setInfo(object);
  }

  function StatusSort() {
    let object = [...information];
    let value = !sortStatus;
    setSortStatus(!sortStatus);

    setSortType(false);
    setSortName(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a.status > b.status) {
          return -1;
        }
        if (a.status < b.status) {
          return 1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        }
        if (a.status < b.status) {
          return -1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function DateSort() {
    let object = [...information];
    let value = !sortDate;
    setSortDate(!sortDate);

    setSortType(false);
    setSortName(false);
    setSortStatus(false);

    if (value) {
      object.sort((a, b) => {
        let d1 = Date.parse(a.created_at);
        let d2 = Date.parse(b.created_at);
        if (d1 > d2) {
          return 1;
        } else if (d1 < d2) {
          return -1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        let d1 = Date.parse(a.created_at);
        let d2 = Date.parse(b.created_at);
        if (d1 > d2) {
          return -1;
        } else if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function dates(info = '') {
    let date = new Date(info);

    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*  useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  return (
    <>
      <Results>{total} resultados encontrados</Results>
      <TableContainer>
        <Grid
          id="users_table"
          className="tableMobile"
          container
          spacing={3}
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderBottom: '2px solid #BFBFB2',
          }}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <RowClick onClick={() => NameSort()}>
              <TitleBoldView className="tableMobile">Nome</TitleBoldView>
              <span>{sortName ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => TypeUserSort()}>
              <TitleBoldView>Tipo de Usuário</TitleBoldView>{' '}
              <span>{sortType ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={1} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => DateSort()}>
              <TitleBoldView>Data de Criação</TitleBoldView>{' '}
              <span>{sortDate ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={1} sm={6} md={2} lg={2} xl={2}>
            <RowClick>
              <TitleBoldView>Quantidade de Imóveis</TitleBoldView>{' '}
            </RowClick>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2}
            lg={2}
            xl={2}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}>
            <TitleBoldView>Ações</TitleBoldView>
          </Grid>
        </Grid>
        {information?.map((info, key) => (
          <Grid key={key} container spacing={3} className="item__list">
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <TitleBold>Nome</TitleBold>
              <RowPhotoName>
                {info.name} {info.surname ?? ''}
              </RowPhotoName>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <TitleBold>Tipo</TitleBold>
              <P>{translateProfileType(info?.profile?.type_advertiser)}</P>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <TitleBold>Data de Criação</TitleBold>
              <P>{dates(info.created_at)}</P>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <TitleBold>Quantidade de móveis</TitleBold>

              {info?.totalProperties > 0 ? (
                <ActionsContainer>
                  <p
                    className="link__action"
                    onClick={(e) => {
                      setUser(info);
                      setModalAdsList(true);
                    }}>
                    {info?.totalProperties}{' '}
                    {info?.totalProperties > 1 ? 'imóveis' : 'imóvel'}
                  </p>
                </ActionsContainer>
              ) : (
                <ActionsContainer>
                  <p className="link__action empty">Sem imóveis</p>
                </ActionsContainer>
              )}
            </Grid>
            <Grid
              className="buttonActions"
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              style={{}}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 'none', marginRight: 20}}>
                  <ManagerOptions
                    info={info}
                    openInfo={() => {
                      setModalInfoVisibility(true);
                      setUserInfo(info);
                    }}
                    openDisableAccount={() => {
                      setModalDisableAccount(true);
                      setUserInfo(info);
                    }}
                    openEnableAccount={() => {
                      setModalEnableAccount(true);
                      setUserInfo(info);
                    }}
                  />
                </div>
                {isPermitted() && (
                  <Icons
                    style={{flex: 'none'}}
                    onClick={() => {
                      setOpenEdit(true);
                      setProfile(info);
                    }}>
                    <EditOutlinedIcon />
                  </Icons>
                )}
                {isPermitted() && (
                  <Icons
                    style={{flex: 'none'}}
                    onClick={() => {
                      setOpen(true);
                      setId(info.id);
                    }}>
                    <DeleteIcon />
                  </Icons>
                )}
              </div>
            </Grid>
          </Grid>
        ))}
      </TableContainer>

      {isPermitted() && (
        <>
          <ModalDelete
            page={page}
            setPage={setPage}
            listUser={listUser}
            open={open}
            setOpen={setOpen}
            id={id}
            text={'Usuário'}
          />
          <ModalEdit
            page={page}
            listUser={listUser}
            profile={profile}
            setProfile={setProfile}
            open={openEdit}
            setOpen={setOpenEdit}
          />
          <ModalDisableAccount
            info={userInfo}
            listUser={listUser}
            open={modalDisableAccount}
            setOpen={setModalDisableAccount}
          />
          <ModalEnableAccount
            info={userInfo}
            listUser={listUser}
            open={modalEnableAccount}
            setOpen={setModalEnableAccount}
          />
        </>
      )}

      <ModalAdsForUser
        open={modalAdsList}
        setOpen={setModalAdsList}
        user={user}
        setUser={setUser}
        currency={currency}
      />
      <SimpleModal
        modalInfo={userInfo}
        modal={modalInfoVisibility}
        setModal={setModalInfoVisibility}
      />
    </>
  );
}
