import {makeStyles} from '@material-ui/core/styles';
import colors from '../../../UI/colors';
const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: 'none',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #0000004D',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - 260px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '0px 3px 6px #0000004D',
    zIndex: '26',
  },
  menuButton: {
    marginRight: 0,
    color: 'black',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: colors.dark,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // backgroundColor: colors.azulMarinho,
    boxShadow: '3px 0px 10px #0000004D',
    border: 'none',
  },
  drawerClose: {
    backgroundColor: colors.dark,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // backgroundColor: colors.azulMarinho,
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    boxShadow: '3px 0px 10px #0000004D',
    border: 'none',
    color: `${colors.dark} !important`,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    boxShadow: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  ocultLetter: {
    marginRight: '5px',
  },
}));

export default useStyles;
