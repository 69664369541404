import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import PublicRoute from './_PublicRoute';
import PrivateRoute from './_PrivateRoute';

import Login from '../views/Login';
import EditAdvertise from '../views/Annoucement/View/ShowAdsSpecific';
import AllJustify from '../views/Annoucement/AllJustify';
import Photo from '../views/Annoucement/View/Edit/Photo';
import LocalizeOne from '../views/Annoucement/View/Edit/Localize/LocalizeOne';
import Wait from '../views/Annoucement/Wait';
import Drafts from '../views/Annoucement/Drafts';
import Approved from '../views/Annoucement/Approved';
import Disapprove from '../views/Annoucement/Disapprove';
import Inactive from '../views/Annoucement/Inactive';

import Plans from '../views/Plans';
import Colaborators from '../views/User/Colaborators';
import Clients from '../views/User/Clients';
import RealState from '../views/RealState';
import Subscriptions from '../views/Subscriptions';
import AdvertsUpdate from '~/views/Annoucement/View/AdvertsUpdate';

import Notifications from '../views/Notifications';
import SendNotification from '../views/Notifications/SendNotification';

import RealStateRegister from '~/views/RealState/Register';
import RealStateEdit from '~/views/RealState/EditPage';
import Profile from '~/views/Profile';

import ForgotPassword from '~/views/ForgotPassword';
import PasswordRecovery from '~/views/PasswordRecovery';
import Proposal from '~/views/Proposal';

const NotFound = () => <h1>Não encontrado</h1>;

/** ROLES
 * Admin = 5
 * Manager = 4
 * Approver = 3
 * Seller = 2
 */

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
        <PublicRoute
          exact
          path="/password-recovery/:token"
          component={PasswordRecovery}
        />
        <PrivateRoute
          exact
          path="/profile"
          component={Profile}
          permittedRoles={['5', '4', '3', '2', '1']}
        />
        <PrivateRoute
          exact
          path="/announcement"
          component={Wait}
          permittedRoles={['5', '4', '3']}
        />
        <PrivateRoute
          exact
          path="/announcement/drafts"
          component={Drafts}
          permittedRoles={['5', '4', '3']}
        />
        <PrivateRoute
          exact
          path="/announcement/approved"
          component={Approved}
          permittedRoles={['5', '4', '3']}
        />
        <PrivateRoute
          exact
          path="/announcement/disapprove"
          component={Disapprove}
          permittedRoles={['5', '4', '3']}
        />
        <PrivateRoute
          exact
          path="/announcement/inactive"
          component={Inactive}
          permittedRoles={['5', '4', '3']}
        />
        <PrivateRoute
          exact
          path="/view/edit/photos/:id"
          component={Photo}
          permittedRoles={['5', '3']}
        />
        <PrivateRoute
          exact
          path="/view/edit"
          component={AdvertsUpdate}
          permittedRoles={['5', '3']}
        />
        <PrivateRoute
          exact
          path="/view/edit/localize/:id"
          component={LocalizeOne}
          permittedRoles={['5', '3']}
        />
        <PrivateRoute
          exact
          path="/view/justify"
          component={AllJustify}
          permittedRoles={['5', '4', '3', '2']}
        />

        <PrivateRoute
          exact
          path="/view/:id"
          component={EditAdvertise}
          permittedRoles={['5', '4', '3', '2']}
        />
        <PrivateRoute
          exact
          path="/colaborators"
          component={Colaborators}
          permittedRoles={['5', '4']}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          component={Clients}
          permittedRoles={['5', '4']}
        />
        <PrivateRoute
          exact
          path="/users"
          component={Clients}
          permittedRoles={['5', '4']}
        />
        <PrivateRoute
          exact
          path="/subscriptions"
          component={Subscriptions}
          permittedRoles={['5', '4']}
        />
        <PrivateRoute
          exact
          path="/proposal"
          component={Proposal}
          permittedRoles={['5', '4']}
        />
        <PrivateRoute
          exact
          path="/notifications"
          component={Notifications}
          permittedRoles={['5', '4']}
        />
        <PrivateRoute
          exact
          path="/plans"
          component={Plans}
          permittedRoles={['5', '4']}
        />
        <PrivateRoute
          exact
          path="/notifications/send"
          component={SendNotification}
          permittedRoles={['5']}
        />
        <PrivateRoute
          exact
          path="/real-state"
          component={RealState}
          permittedRoles={['5', '4', '2']}
        />
        <PrivateRoute
          exact
          path="/real-state/edit"
          component={RealStateEdit}
          permittedRoles={['5', '2']}
        />
        <PrivateRoute
          exact
          path="/real-state/register"
          component={RealStateRegister}
          permittedRoles={['5', '2']}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
export default Routes;
