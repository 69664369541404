import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../UI/colors';

function RadioSlideWithout({
  permitted,
  openActive,
  setOpenActive,
  state,
  setState,
  openDesative,
  setOpenDesative,
}) {
  function handle() {
    if (state) {
      setOpenDesative(true);
    } else {
      setOpenActive(true);
    }
  }

  return (
    <DivRouded isPermitted={permitted} state={state} onClick={() => handle()}>
      <DivBall></DivBall>
    </DivRouded>
  );
}

export default RadioSlideWithout;

const DivBall = styled.div`
  width: 22px;
  height: 22px;
  margin: 3px;
  border: none;
  box-shadow: 0px 1px 3px #0000004d;
  border-radius: 15px;
  background: ${colors.white};
`;

const Text = styled.div`
  color: #fff;
`;

const DivRouded = styled.div`
  cursor: pointer;
  width: 60px;
  flex-direction: ${(props) => (props.state ? 'row' : 'row-reverse')};
  height: 25px;
  border: none;
  box-shadow: 0px 1px 3px #0000004d;
  border-radius: 15px;
  background-color: ${(props) =>
    !props.isPermitted
      ? colors.gray_primary
      : props.state
      ? colors.green_secondary
      : colors.white};
  display: flex;
  align-items: center;
  ${DivBall} {
    background: ${(props) =>
      !props.isPermitted
        ? colors.gray_secondary
        : props.state
        ? colors.white
        : colors.green_secondary};
  }

  ${Text} {
    color: ${(props) => (props.state ? colors.white : colors.green_secondary)};
  }

  transition: 0.4s;
  justify-content: flex-end;
  align-items: center;
`;
