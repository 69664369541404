/* eslint-disable no-unused-vars */
import React from 'react';
import {useDropzone} from 'react-dropzone';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import styled from 'styled-components';
import colors from '~/UI/colors';
import {fireActionMethods} from '~/utils/swalMethods';
import {fireAction} from '~/utils/swal';

export function Dropzone(props) {
  const fileSelectedHandler = React.useCallback(
    (file) => {
      if (file.length === 1) {
        if (Array.isArray(props.state)) {
          props.setState((old) => [...old, ...file]);
        } else {
          props.setState(file[0]);
        }
      } else if (file.length > 1) {
        var arrayIMG = [];
        file.map((item) => arrayIMG.push(item));
        props.setState((old) => [...old, ...arrayIMG]);
      }
    },
    [props],
  );

  const rejectDrop = React.useCallback(() => {
    return fireAction(
      'Falha no upload',
      'O arquivo escolhido é maior que 1 Mb ou possui um formato não aceito. Tente novamente com um arquivo de imagem no formato .jpeg, .jpg, .png ou .jfif com no máximo 1 Mb de tamanho.',
      'error',
    );
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop: fileSelectedHandler,
    onDropRejected: rejectDrop,
    accept: 'image/jpeg, image/png, image/jpg',
    minSize: 0,
    maxSize: 2048500,
    multiple: !props.oneFile,
  });

  const selectCorrectMessage = React.useMemo(() => {
    if (!isDragActive) {
      return props.children;
    } else if (isDragActive && !isDragReject) {
      return 'Solte a imagem aqui';
    } else if (isDragReject) {
      return 'Arquivo não aceito';
    }
  }, [isDragActive, isDragReject, props.children]);

  return (
    <Container {...getRootProps()} hasDisabled={props.disabled}>
      <input
        disabled={props.disabled}
        multiple={!props.oneFile}
        type="file"
        {...getInputProps()}
        name="image"
      />
      <SpanText>
        <PhotoCameraIcon /> <p>{selectCorrectMessage}</p>
      </SpanText>
    </Container>
  );
}

const Container = styled.div`
  > input {
    display: none;
  }

  color: ${colors.green_secondary};
  font-size: 12px;
  font-weight: 700;
  transition: all 0.2s ease;
  cursor: ${(props) => (props.hasDisabled ? 'wait' : 'pointer')};

  &:hover {
    opacity: 0.7;
  }

  font-family: 'Lato Regular';

  p {
    margin: 0;
  }
  span {
    color: ${(props) =>
      props.hasDisabled ? '#d9dce0' : colors.green_secondary};
  }
`;

export const SpanText = styled.span`
  margin-top: 10.01px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  svg {
    margin-right: 10px;
  }
`;
