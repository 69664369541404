import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import './styles.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {Link, useHistory} from 'react-router-dom';
import {NavLink} from 'react-router-dom';

import FilterNoneIcon from '@material-ui/icons/FilterNone';

import userImg from '../../../assets/iconPerson.jpg';
import logo from '../../../assets/Logotipo/permuti-logo-branco.svg';
import useStyles from './stylesClass';
import GroupIcon from '@material-ui/icons/Group';
import SimpleMenu from './Menu/MenuFilter';

import {
  Container,
  User,
  GroundUser,
  ImgUser,
  NameUser,
  RoleUser,
  DivItem,
  DivIcon,
  UserName,
  /*  */
  AdsContainer,
  PlanContainer,
  UserContainer,
} from './styles';
import {desencrypta} from '../../../utils';
import {TitlePage} from '../../Menu/MenuProfile/styles';
import {ButtonCreateAds, ButtonCreatePlan} from '../../../UI/styles';
import ModalCreateAds from '../../ModalCreateAds';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBed,
  faMoneyCheck,
  faUser,
  faUsers,
  faCity,
  faPoll,
  faBell,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';

import fonts from '../../../UI/fonts';
import transformNameProfile from '~/views/Profile/profile_type';

/* ----------------------------------------------- */
/* ----------------------------------------------- */
/* ----------------------------------------------- */
/* ---------- TEXTO DO ITEM DA SIDEBAR ----------- */
const textItem = {
  fontFamily: [fonts.latoRegularBold, 'sans-serif'].join(','),
  fontSize: 16,
};

/* ---------- CONTAINER DOS ITEMS CLICÁVEIS DA SIDEBAR ----------- */
const ListCustom = withStyles({
  root: {
    '&::-webkit-scrollbar': {
      width: '0',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      outline: '0',
    },
  },
})(List);

const listContainer = {
  marginTop: '20px',
  minHeight: '70vh',
  overflowY: 'auto',
};
/* ----------------------------------------------- */
/* ----------------------------------------------- */
/* ----------------------------------------------- */
/* ----------------------------------------------- */
const sidebarItems = [
  {
    id: 0,
    key: 'user',
    to: '/dashboard',
    text: 'Usuários',
    icon: <GroupIcon />,
    permitted_roles: ['5', '4'],
  },
  {
    id: 1,
    key: 'colaborators',
    to: '/colaborators',
    text: 'Colaboradores',
    icon: <FontAwesomeIcon icon={faUsers} />,
    permitted_roles: ['5', '4'],
  },
  {
    id: 2,
    key: 'plans',
    to: '/plans',
    text: 'Planos',
    icon: <FontAwesomeIcon icon={faMoneyCheck} />,
    permitted_roles: ['5', '4'],
  },
  {
    id: 3,
    key: 'announcement',
    to: '/announcement',
    text: 'Anúncios',
    icon: <FontAwesomeIcon icon={faUsers} />,
    permitted_roles: ['5', '4', '3'],
  },
  {
    id: 4,
    key: 'real-state',
    to: '/real-state',
    text: 'Imobiliárias',
    icon: <FontAwesomeIcon icon={faCity} />,
    permitted_roles: ['5', '4', '2'],
  },
  {
    id: 5,
    key: 'subscriptions',
    to: '/subscriptions',
    text: 'Assinaturas',
    icon: <FontAwesomeIcon icon={faPoll} />,
    permitted_roles: ['5', '4'],
  },
  {
    id: 6,
    key: 'proposal',
    to: '/proposal',
    text: 'Propostas',
    icon: <FontAwesomeIcon icon={faExchangeAlt} />,
    permitted_roles: ['5', '4'],
  },
  {
    id: 7,
    key: 'notifications',
    to: '/notifications',
    text: 'Notificações',
    icon: <FontAwesomeIcon icon={faBell} />,
    permitted_roles: ['5', '4'],
  },
];

export default function Dashboard({title, button, listPlan}) {
  const classes = useStyles();
  const userStore = useSelector((state) => state.user);

  const GetAllOptionsPermitted = React.useCallback(() => {
    const optionsPermitted = sidebarItems
      ?.filter((item) =>
        item?.permitted_roles.includes(userStore?.role_id?.toString()),
      )
      .map((opt, index) => (
        <NavLink
          key={`${opt.id}-${opt.key}-${index}`}
          className="anchor"
          to={opt?.to}
          activeClassName="active">
          <DivItem>
            <ListItem
              button
              key={opt?.key}
              onClick={(event) => handleListItemClick(event, opt?.id)}>
              <ListItemIcon>
                <ListItemIcon>
                  <DivIcon>{opt?.icon}</DivIcon>
                </ListItemIcon>
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{style: textItem}}
                primary={opt?.text}
              />
            </ListItem>
          </DivItem>
        </NavLink>
      ));

    return optionsPermitted;
  }, [userStore?.role_id]);

  const [open, setOpen] = useState(window.innerWidth <= 600 ? false : true);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const history = useHistory();

  const [openModalCreate, setOpenModalCreate] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Container>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}>
            <MenuIcon />
          </IconButton>
          <div
            style={{
              width: '100%',
              color: 'black',
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 8,
              paddingBottom: 8,
            }}>
            <UserContainer>
              <TitlePage admin={true}>{title}</TitlePage>
            </UserContainer>

            <Typography variant="h6" noWrap style={{color: 'black'}}>
              <SimpleMenu />
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          drawerOpenOverride: open,
          drawerCloseOverride: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            paperOpenOverride: open,
            paperCloseOverride: !open,
          }),
        }}>
        <div className={`${classes.toolbar} pd_lat_navbar_header`}>
          <IconButton color="inherit" size="medium" onClick={handleDrawerClose}>
            <MenuIcon style={{color: '#fff'}} />
          </IconButton>
          <img
            alt="logo do permuti"
            src={logo}
            height="30px"
            width="160px"
            style={{marginRight: '20px'}}
          />
        </div>

        <User onClick={() => history.push('/profile')}>
          <GroundUser open={open}>
            <ImgUser
              open={open}
              src={userStore?.avatar ? userStore?.avatar : userImg}
              alt="Avatar"
              width="40px"
              height="40px"
            />
            {open && (
              <UserName>
                <NameUser open={open}>{localStorage.getItem('name')}</NameUser>
                <RoleUser open={open}>
                  {transformNameProfile(localStorage.getItem('role_id'))}
                </RoleUser>
              </UserName>
            )}
          </GroundUser>
        </User>

        <ListCustom style={listContainer} className="__list_menu">
          {GetAllOptionsPermitted()}
        </ListCustom>
      </Drawer>
      <ModalCreateAds open={openModalCreate} setOpen={setOpenModalCreate} />
    </Container>
  );
}
/* 
<NavLink className="anchor" to="/dashboard" activeClassName="active">
<DivItem >
  <ListItem
    button
    key={'user'}
    
    onClick={(event) => handleListItemClick(event, 0)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon >
          <GroupIcon />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Usuários"
    />
  </ListItem>
</DivItem>
</NavLink>

<NavLink
className="anchor"
to="/colaborators"
activeClassName="active">
<DivItem >
  <ListItem
    button
    key={'colaborators'}
    
    onClick={(event) => handleListItemClick(event, 1)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon >
          {' '}
          <FontAwesomeIcon icon={faUsers} />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Colaboradores"
    />
  </ListItem>
</DivItem>
</NavLink>

<NavLink className="anchor" to="/plans" activeClassName="active">
<DivItem >
  <ListItem
    button
    key={'plans'}
    onClick={(event) => handleListItemClick(event, 2)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon >
          {' '}
          <FontAwesomeIcon icon={faMoneyCheck} />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Planos"
    />
  </ListItem>
</DivItem>
</NavLink>

<NavLink
className="anchor"
to="/announcement"
activeClassName="active">
<DivItem >
  <ListItem
    button
    key={'announcement'}
    onClick={(event) => handleListItemClick(event, 3)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon >
          <FontAwesomeIcon icon={faUsers} />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Anúncios"
    />
  </ListItem>
</DivItem>
</NavLink>

<NavLink className="anchor" to="/real-state" activeClassName="active">
<DivItem>
  <ListItem
    button
    key={'real-state'}
    onClick={(event) => handleListItemClick(event, 4)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon>
          <FontAwesomeIcon icon={faCity} />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Imobiliárias"
    />
  </ListItem>
</DivItem>
</NavLink>

<NavLink
className="anchor"
to="/subscriptions"
activeClassName="active">
<DivItem>
  <ListItem
    button
    key={'subscriptions'}
    onClick={(event) => handleListItemClick(event, 5)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon>
          <FontAwesomeIcon icon={faPoll} />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Assinaturas"
    />
  </ListItem>
</DivItem>
</NavLink>

<NavLink className="anchor" to="/proposal" activeClassName="active">
<DivItem >
  <ListItem
    button
    key={'proposal'}
  
    onClick={(event) => handleListItemClick(event, 2)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon >
          {' '}
          <FontAwesomeIcon icon={faExchangeAlt} />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Propostas"
    />
  </ListItem>
</DivItem>
</NavLink>

<NavLink
className="anchor"
to="/notifications"
activeClassName="active">
<DivItem>
  <ListItem
    button
    key={'notifications'}
    onClick={(event) => handleListItemClick(event, 6)}>
    <ListItemIcon>
      <ListItemIcon>
        <DivIcon>
          <FontAwesomeIcon icon={faBell} />
        </DivIcon>
      </ListItemIcon>
    </ListItemIcon>
    <ListItemText
      primaryTypographyProps={{style: textItem}}
      primary="Notificações"
    />
  </ListItem>
</DivItem>
</NavLink> */
