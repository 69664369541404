import styled from 'styled-components';

import colors from '../../UI/colors';
import fonts from '../../UI/fonts';

export const Error = styled.span`
  text-align: left;
  font-size: 14px;
  font-family: ${fonts.latoRegular};
  color: ${colors.danger_dark};
  line-height: 18px;
  margin-top: calc(10px - 0.7px);
`;
