import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import apiBackend from '../../services/apiaxios';
import {makeStyles} from '@material-ui/core';

import {fireAction} from '~/utils/swal';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 10,
    maxWidth: '420px',
  },
}));

export default function ModalDeletePlan({id, open, setOpen, listPlan}) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  async function deletePlan() {
    setIsSubmitting(true);
    try {
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const res = await apiBackend.delete(`/v1/plan/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(res);

      await listPlan();
    } catch (err) {
      const status = err?.response?.status;
      const message = err?.response?.data?.data?.message;

      if (status === 422) {
        if (message === 'Plan in use') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Este plano não pôde ser excluído pois ele está sendo usado em assinaturas por usuários.',
            'error',
          );
        } else if (message === 'Plan not found') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Este plano não pôde ser excluído pois não foi encontrado. Aparentemente ele já foi deletado da base de dados.',
            'error',
          );
        } else if (message === 'Failed to delete stripe related data') {
          fireAction(
            'Não foi possível completar sua solicitação',
            'Não foi possível excluir este plano junto ao Stripe. Por favor, tente novamente mais tarde ou diretamente no Stripe.',
            'error',
          );
        } else {
          fireAction(
            'Erro interno',
            'Não foi possível completar sua solicitação. Por favor, tente novamente mais tarde.',
          );
        }
      } else {
        Sentry.captureException(err);
        fireAction(
          'Erro interno',
          'Não foi possível completar sua solicitação. Por favor, tente novamente mais tarde.',
        );
      }

      console.log(err.response);
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  }

  return (
    <>
      <div>
        <Dialog
          scroll="body"
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          classes={{paper: classes.paper}}
          open={open}>
          <TitleModal>
            <span></span>
            <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
          </TitleModal>
          <InternDiv>
            <ModalResponsive>
              <DivDanger>
                <FontAwesomeIcon
                  size="2x"
                  color="#A88721"
                  icon={faExclamationCircle}
                />
                <AdvertTitle>Atenção</AdvertTitle>
                <Message>
                  Se confirmar a exclusão do plano, ele não poderá mais ser
                  recuperado. Deseja realmente <strong>excluir</strong> o plano?
                </Message>
                <DivConfirm>
                  <ButtonsWhite
                    primary
                    disabled={isSubmitting}
                    onClick={deletePlan}>
                    SIM
                  </ButtonsWhite>
                  <ButtonsWhite disabled={isSubmitting} onClick={handleClose}>
                    NÃO
                  </ButtonsWhite>
                </DivConfirm>
              </DivDanger>
            </ModalResponsive>
          </InternDiv>
        </Dialog>
      </div>
    </>
  );
}
