import styled from 'styled-components';
import fonts from '~/UI/fonts';
import colors from '../../../../../UI/colors';

export const P = styled.div`
  font-family: ${fonts.latoRegular};
  font-size: 14px;
  color: ${colors.dark};
  display: block;
  margin-bottom: 10px !important;
`;

export const FirstPrice = styled.div`
  font-size: 18px;
  font-family: ${fonts.robotoRegularBold};
  color: ${colors.green_secondary};
  display: block;
  margin-bottom: 10px !important;
`;

export const ContainerPrice = styled.div``;
