import styled from 'styled-components';
import {ButtonMedium} from '~/UI/styles';

import colors from '../../../../../UI/colors';
import fonts from '../../../../../UI/fonts';

export const Container = styled.div``;

export const TitleModal = styled.span`
  padding: 20px 30px;
  width: 100%;
  font-size: 24px;
  color: ${colors.green_secondary};
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;

  > span {
    svg {
      padding-left: 15px;
    }
  }
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 20px 30px 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
export const DivRadioGroup = styled.div`
  width: 100%;
`;

export const DivInputText = styled.div`
  width: 100%;

  svg {
    color: ${colors.dark_light};
  }
`;

export const Rotule = styled.p`
  color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.valid
      ? colors.green_secondary
      : colors.dark_light};
  font-family: ${fonts.latoRegularBold};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const InputText = styled.input`
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  color: #1d1d1b;
  border: none;

  margin: 0px !important;
  font-family: ${fonts.latoRegular};

  border-bottom: ${(props) =>
    props.value || props.error ? '2px solid' : '1px solid'};

  border-color: ${(props) =>
    props.error
      ? colors.danger_dark
      : props.value
      ? colors.green_secondary
      : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-family: ${fonts.latoRegularItalic};

    font-size: 16px;
  }
`;

export const DivError = styled.div`
  width: 100%;
  font-family: ${fonts.latoRegular};
`;

export const ErrorMessage = styled.span`
  margin-top: 9.25px;

  color: ${colors.danger_dark};
  font-family: ${fonts.latoRegular};
  font-weight: 500;
  font-size: 14px;
`;

export const ButtonSave = styled(ButtonMedium)`
  width: 100%;
  margin-top: 30px;
  font-size: 18px;
  font-family: ${fonts.latoBlack};
`;
