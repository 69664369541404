/*  
  user: broker / common
  common_user_status = 1 - usuário sem anúncios
  common_user_status = 2 - usuário com anúncios (ativo)
  common_user_status = 3 - usuário com anúncios não pagos (inativo)

  status_broker = 1 - imobiliária Vendidas
  status_broker = 2 - imobiliária Atendidas
  status_broker = 3 - imobiliária Publicadas
  status_broker = 4 - imobiliária Inativas 
  */
export const recipientItems = [
  {
    name: 'Todos as imobiliárias',
    status: '',
    type: 'broker',
  },
  {
    name: 'Imobiliárias vendidas',
    status: '1',
    type: 'broker',
  },
  {
    name: 'Imobiliárias atendidas',
    status: '2',
    type: 'broker',
  },
  {
    name: 'Imobiliárias publicadas',
    status: '3',
    type: 'broker',
  },
  {
    name: 'Imobiliárias inativas',
    status: '4',
    type: 'broker',
  },
  {
    name: 'Todos os usuários',
    status: '',
    type: 'owner',
  },
  {
    name: 'Usuários sem anúncio',
    status: '1',
    type: 'owner',
  },
  {
    name: 'Usuários ativos',
    status: '2',
    type: 'owner',
  },
  {
    name: 'Usuários inativos',
    status: '3',
    type: 'owner',
  },
];
