export default function errorXml(data, user) {
  /*   console.log(data);
  console.log(user); */

  const url = window.URL.createObjectURL(
    new Blob([JSON.stringify(data, null, 2)]),
  );
  const name = user?.profile?.enterprise
    ? user?.profile?.enterprise
    : user?.profile?.company_name
    ? user?.profile?.company_name
    : user?.name
    ? user?.name
    : 'User';
  const date = new Date();

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name} ${date.toUTCString()}.txt`); //or any other extension
  document.body.appendChild(link);
  link.click();
}
