import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './styles.css';
import {Dialog} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {
  InternDiv,
  TitleModal,
  ModalResponsive,
  Message,
  DivDanger,
  AdvertTitle,
  DivConfirm,
  ButtonsWhite,
  ModalContainer,
} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {desencrypta} from '../../utils';
import baseURL from '../../services/api';
import apiBackend from '~/services/apiaxios';
import {fireAction} from '~/utils/swal';
import {fireActionMethods} from '~/utils/swalMethods';

import * as Sentry from '@sentry/react';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '420px',
  },
}));

export default function ModalDeleteLead({open, setOpen, getAllLead, inf}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  // async function deleteProperty() {
  //   let token = desencrypta(localStorage.getItem('@User_informations'));
  //   apiBackend
  //     .delete(`/v1/property/${inf.id}`, {
  //       headers: {Authorization: `Bearer ${token}`},
  //     })
  //     .then((response) => {
  //       handleClose();
  //       fireActionMethods(
  //         'Lead deletado',
  //         'Você excluiu um Lead, ele não poderá mais ser recuperado.',
  //         'success',
  //         getAllLead,
  //       );
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  function deleteLead() {
    let token = desencrypta(localStorage.getItem('@User_informations'));

    apiBackend
      .delete(`v1/real-state/contact/${inf.id}`, {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((response) => {
        handleClose();
        fireActionMethods(
          'Anúncio deletado',
          'Você excluiu um Lead, ele não poderá mais ser recuperado.',
          'success',
          getAllLead,
        );
      })
      .catch((e) => Sentry.captureException(e));
  }

  return (
    <Dialog
      scroll="body"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      classes={{paper: classes.paper}}
      open={open}>
      <ModalContainer>
        <TitleModal>
          <span></span>
          <CloseIcon onClick={() => handleClose()} className={'iconClose'} />
        </TitleModal>
        <InternDiv>
          <ModalResponsive>
            <DivDanger>
              <FontAwesomeIcon
                size="2x"
                color="#A88721"
                icon={faExclamationCircle}
              />
              <AdvertTitle> Atenção</AdvertTitle>
              <Message>
                Se confirmar a exclusão deste Lead, ele não poderá mais ser
                recuperado. Deseja realmente <b>excluir</b> este Lead?
              </Message>
              <DivConfirm>
                <ButtonsWhite onClick={(event) => deleteLead()}>
                  SIM
                </ButtonsWhite>
                <ButtonsWhite onClick={(event) => handleClose()}>
                  NÃO
                </ButtonsWhite>
              </DivConfirm>
            </DivDanger>
          </ModalResponsive>
        </InternDiv>
      </ModalContainer>
    </Dialog>
  );
}
