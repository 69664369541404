import React from 'react';
import styled, {css} from 'styled-components';
import colors from '../UI/colors';
import fonts from '../UI/fonts';
import metrics from '../UI/metrics';

export default function InputNumberCount(props) {
  const [viewMessage, setViewMessage] = React.useState(false);

  function change(event) {
    if (props.mask) {
      props.mask(event, props.setValue);
    } else {
      props.setValue(event.target.value?.replace(/-/g, ''));
    }
  }

  function handleBlur() {
    props.onFocusOut && props.onFocusOut();
    if (props.value) {
      setViewMessage(false);
    } else {
      setViewMessage(true);
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <Label color={props.value}>
        {props.label} {props.isRequired && <Star>*</Star>}
      </Label>
      {props.value !== '' && props.strongLabel ? (
        <StrongLabel>{props.strongLabel}</StrongLabel>
      ) : null}
      <Input
        disabled={props.disable}
        minLength={props.min ? props.min : null}
        maxLength={props.max ? props.max : null}
        color={props.value}
        value={props.value}
        onChange={(event) => change(event)}
        onBlur={handleBlur}
        itens={props.itens}
        placeholder={props.placeHolder}
        inputMode={props.inputMode}
        strongLabel={props.strongLabel}
        pdLeft={
          props.value !== '' && props.strongLabel
            ? props.strongLabel.length * 10
            : 0
        }
      />

      {viewMessage ? <Message>Campo obrigatório</Message> : null}
    </div>
  );
}

const Input = styled.input`
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.value !== ''
      ? '2px solid ' + colors.green_secondary
      : '#808076 1px solid'};
  color: ${(props) => (props.value !== '' ? '#1D1D1B' : '#808076')};
  width: 100%;
  font-size: 16px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  font-family: 'Lato Regular';
  position: relative;

  &::placeholder {
    font-family: 'Lato Regular Italic';
  }

  &:focus {
    outline: 0;
  }
  &:disabled {
  }

  padding-left: ${(props) => (props.pdLeft ? `${props.pdLeft}px` : 0)};
`;

export const StrongLabel = styled.strong`
  content: ${(props) => props.strongLabel};
  position: absolute;
  color: #1d1d1b;
  font-family: ${fonts.latoBlack};
  font-size: 13px;
  letter-spacing: 1.2px;
  z-index: 10;
  top: 38px;
`;

const Message = styled.div`
  font-family: 'Lato Regular Bold';
  font-size: 13px;
  color: red;
`;

const Label = styled.div`
  font-family: 'Lato Regular Bold';
  font-size: 16px;
  color: ${(props) =>
    props.color !== '' ? colors.green_secondary : '#808076'};
`;
export const Star = styled.span`
  color: #a88721;
  margin-left: 2px;
`;
