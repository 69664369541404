import * as reg from '~/utils/regex';

export const emailValidate = (value) =>
  value.trim() && value.trim() && !reg.emailReg.test(value)
    ? 'E-mail inválido'
    : '';

export const adsQtd = (value) =>
  value.trim() && Number(value.trim()) === 0
    ? 'Deve ser positivo e maior que 0'
    : '';
