import styled from 'styled-components';
import metrics from '../../UI/metrics';
import colors from '../../UI/colors';

export const Container = styled.div`
  width: 400px;
  height: 640px;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: 'Lato Regular';
`;

export const Field = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : 0)};
  padding: 10px 20px 0px;
  border-top: ${(props) => (props.bordered ? '1px solid #dedede' : 'none')};

  @media (max-width: 600px) {
    padding: 10px 5px 0;
  }
`;

/* -------------- */
export const DivMetrics = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: ${(props) =>
    props.value ? `2px solid ${colors.green_secondary}` : '#808076 1px solid'};
`;

export const DivInputMetrics = styled.div`
  width: 85%;
`;

export const DivSelectMetrics = styled.div`
  max-width: max-content;
  width: 100%;
  height: 72px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DivText = styled.div`
  padding-top: 8px;
  text-align: center;
  color: #1d1d1b;
  font-size: 18px;
  font-family: 'Lato Regular';
  font-weight: 500;
`;
/* -------------- */
export const MessageInputs = styled.div`
  width: 100%;
  font-size: 13px;
  font-family: 'Lato Regular Bold';
  color: red;
  margin: 0 0 10px;
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 10px 20px 30px 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  padding: 30px;
  font-size: 24px;
  color: #1d1d1b;
  font-family: 'Lato Black Italic';
  display: flex;
  justify-content: space-between;

  line-height: 24px;

  > span {
    padding-right: 10px;
  }
`;

export const DivInputText = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const ButtonRelative = styled.div`
  position: absolute;
  top: 36px;
  right: 15px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  background: #fff;

  .choose {
    font-weight: bold;
    font-size: 16px;
    font-family: ${metrics.font_lato};
  }

  > button {
    width: 30px;
    height: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.green_secondary};
    transition: 0.2s all ease;
    border-radius: 50%;

    > svg {
      pointer-events: none;
    }

    /* ---- */
    &:hover:not(:disabled) {
      background: #f2f2ed;
      color: ${colors.green_secondary};
    }
    &:active {
      background: ${colors.green_secondary};
      color: #fff;
      &:hover {
        background: ${colors.green_secondary};
        color: #fff;
      }
    }

    /* ---- */
  }
`;

export const ButtonRelativeText = styled(ButtonRelative)`
  .choose {
    display: ${(props) => props.value === '' && 'none'};
    font-weight: bold;
    font-size: 16px;
    font-family: ${metrics.font_lato};
  }
  top: -80px;
  left: 100px;
  width: 64px;
`;

export const LabelRadioButton = styled.div`
  font-family: 'Lato Regular Bold';
  font-size: 16px;
  color: ${(props) => (props.color ? colors.green_secondary : '#808076')};
`;

export const StarRadioButton = styled.span`
  color: #a88721;
  margin-left: 2px;
`;
