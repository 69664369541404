import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import {Container} from './styles';
import {geocodeSearch} from '~/services/geocode';
import * as S from '~/views/RealState/Register/styles';
import './styles.css';

function AddressInput({
  value,
  setValue,
  choose,
  setChoose,
  optional,
  label,
  placeholder,
  error,
  touched,
  setLat,
  setLong,
  setViewLat,
  setViewLong,
  setViewLat2,
  setViewLong2,
}) {
  function insert(place) {
    setChoose(place);
    /* TODO novo */
    geocodeSearch({
      place_id: place?.place_id,
      setLatitude: setLat,
      setLongitude: setLong,
      setViewNorthLat: setViewLat,
      setViewNorthLng: setViewLong,
      setViewSouthLat: setViewLat2,
      setViewSouthLng: setViewLong2,
    });
  }

  function handleBlur(value) {
    if (value !== choose?.formatted_address) {
      setValue('');
      setViewLat('');
      setViewLong('');

      /* TODO novo */
      setViewLat2('');
      setViewLong2('');
      /*  */
      setLat('');
      setLong('');

      setChoose({});
    }
  }

  return (
    <S.DivInputText>
      <S.Rotule error={error} valid={value}>
        {label} {!optional && '*'}
      </S.Rotule>
      <Container error={error} value={value}>
        <Autocomplete
          value={value}
          id="input-auto"
          style={{marginBottom: '20px', fontFamily: 'Lato Regular'}}
          onChange={(event) => setValue(event.target.value)}
          onBlur={(event) => handleBlur(event.target.value)}
          onPlaceSelected={(place) => {
            setValue(place?.formatted_address);
            insert(place);
          }}
          placeholder={placeholder}
          types={['(regions)']}
          className={'input-localization'}
          /* componentRestrictions={{country: ['br', 'pt', 'us']}} */
        />
      </Container>
      {error && (
        <S.DivError>
          <S.ErrorMessage>{error}</S.ErrorMessage>
        </S.DivError>
      )}
    </S.DivInputText>
  );
}

export default AddressInput;
