import React, {useState, useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {DivNext, DivRadio, DivRadioText} from './styles';
import {TitlePage} from '../../Photo/styles';
import NextPrev from '../../Components/nextPrev';
import MandatoryField from '../../Components/mandatoryField';
import InputText from '../../Components/inputText';
import RadioSlide from '../../Components/radioButton';
import {desencrypta, getRoute} from '~/utils';
import baseURL from '~/services/api';
import {SaveAnnoucement} from '../../Step/styles';

/* import addMsgAction from '~/redux/action/action_add_user'; */
import {Star} from '../../Components/styles';
import {viewStyleInput, viewSwitchInput} from '../../Verifications';
import I18n from '~/i18n/i18';
import SelectMenuRequiredI18n from '../../Components/selectMenuRequiredI18n';
import MenuChangeObject from '../../NewComponents/MenuChanceObject';
import getTypeProperties from '~/requests/Advertise/typeProperty';
import getTypeStyle from '~/requests/Advertise/typeStyle';
import apiBackend from '~/services/apiaxios';
import NextPreviewPropertyOne from '../../Components/nextPreviewPropertyOne';

import * as Sentry from '@sentry/react';

const itensViewEN = [
  'Not applicable',
  'Sea',
  'Dam',
  'Lake',
  'River',
  'Mountain',
  'Forest',
];

const itemStyleEN = [
  'Standard',
  'Classic',
  'Contemporary',
  'Retro',
  'High Tech',
  'Industrial',
  'Minimalist',
  'Rustic',
  'Colonial',
  'Provencal',
  'Vintage',
  'Tropical',
];

function PropertyOne({
  id,
  typeStyle,
  setTypeStyle,
  typeView,
  setTypeView,
  code,
  setCode,
  type,
  typeEn,
  roof,
  setRoof,
  loft,
  setLoft,
  step,
  setStep,
}) {
  const [active, setActive] = useState(false);
  const view = useSelector((state) => state.step.data);
  const dispatch = useDispatch();
  const viewStyle = viewStyleInput(type['name_en']);
  const viewSwitch = viewSwitchInput(type['name_en']);
  const itensStyle = itemStyleEN;
  const itensView = itensViewEN;
  const [itensBackend, setItensBackEnd] = useState([]);
  const [location, setLocation] = useState({
    pathname: `/${getRoute()}/login`,
    state: {fromDashboard: true},
  });

  useEffect(() => {
    setLocation({
      pathname: `/${getRoute()}/advertise`,
      state: {fromDashboard: true},
    });
  }, [window.location.href]);

  useEffect(() => {
    getTypeStyle(setItensBackEnd);
  }, []);

  useEffect(() => {
    if (typeView) setActive(true);
  }, [typeView]);

  async function updatePropertyOne() {
    let token = desencrypta(localStorage.getItem('@User_informations'));
    apiBackend

      .put(
        `/v1/property/${id}/edit`,
        {
          property_style_id: typeStyle.id,
          penthouse: roof,
          loft,
          waterfront: typeView,
          mls_number: code,
        },
        {headers: {Authorization: `Bearer ${token}`}},
      )
      .then((response) => {
        // dispatch(addMsgAction(true));
        let value = Number.parseInt(step);
        if (viewStyle) value = (value + 1).toString();
        else value = (value + 2).toString();
        console.log();
        setStep(value);

        console.log(response);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  function handleProx(event) {
    event.preventDefault();

    updatePropertyOne();
  }
  return (
    <>
      {view ? (
        <SaveAnnoucement>
          <I18n t="MessageRedux.save" />
        </SaveAnnoucement>
      ) : null}
      <div /* form onSubmit={handleProx} */>
        <div>
          <TitlePage>
            <I18n t="StylesDefinied.title" />
          </TitlePage>
          <MandatoryField />

          {viewStyle ? (
            <MenuChangeObject
              i18n="TypeStyle"
              placeHolder={I18n.getTranslation(
                location,
                'StylesDefinied.placeHolderStyle',
              )}
              label={<I18n t="StylesDefinied.labelStyle" />}
              itens={itensStyle}
              itensBackend={itensBackend}
              value={typeStyle}
              setValue={setTypeStyle}
            />
          ) : null}

          {viewSwitch ? (
            <>
              {' '}
              <DivRadio>
                <DivRadioText value={roof}>
                  <div>
                    <I18n t="StylesDefinied.roof" />
                  </div>
                  <Star> *</Star>
                </DivRadioText>

                <RadioSlide
                  location={location}
                  state={roof}
                  setState={setRoof}
                />
              </DivRadio>
              <DivRadio>
                <DivRadioText value={loft}>
                  <I18n t="StylesDefinied.loft" /> <Star>*</Star>
                </DivRadioText>
                <RadioSlide
                  location={location}
                  state={loft}
                  setState={setLoft}
                />
              </DivRadio>{' '}
            </>
          ) : null}
          <SelectMenuRequiredI18n
            i18n="TypeView"
            placeHolder={I18n.getTranslation(
              location,
              'StylesDefinied.placeHolderTypeView',
            )}
            label={<I18n t="StylesDefinied.labelTypeView" />}
            itens={itensView}
            value={typeView}
            setValue={setTypeView}
          />
          <InputText
            placeHolder={I18n.getTranslation(
              location,
              'StylesDefinied.placeHolderCode',
            )}
            label={<I18n t="StylesDefinied.labelCode" />}
            value={code}
            setValue={setCode}
          />
        </div>

        <DivNext>
          <NextPreviewPropertyOne
            flag={true}
            step={step}
            setStep={setStep}
            active={active}
            handleSubmit={handleProx}
          />
        </DivNext>
      </div>
    </>
  );
}

export default PropertyOne;
