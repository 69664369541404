import styled, {css, keyframes} from 'styled-components';
import colors from '../../../../UI/colors';
import fonts from '../../../../UI/fonts';

export const ContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 793px;
`;

export const PName = styled.p`
  font-size: 16px;
  color: #1d1d1b;
  font-weight: bold;
`;

export const PType = styled.p`
  font-size: 16px;
  color: #1d1d1b;
`;

export const DivInfo = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const Intern = styled.div`
  width: 420px;
  margin-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const DivImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SpanText = styled.span`
  margin-top: 10.01px;
  color: ${colors.green_secondary};
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
`;

export const PEdit = styled.p`
  margin-left: 10px;
`;

export const AvatarContainer = styled.div`
  position: relative;
  img {
    filter: ${(props) => (props.hasUploading ? 'brightness(0.4)' : 'none')};
  }
`;

const spinner = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform:  translate(-50%, -50%) rotate(360deg);
  }
`;

export const SpinnerLoading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform-origin: center;
  display: block;
  border-top: 2px solid ${colors.green_secondary};
  border-left: 2px solid ${colors.green_secondary};

  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: ${spinner} 0.7s linear infinite;
`;

export const ImgUser = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
`;

export const TitleModal = styled.span`
  padding: 20px;
  width: 100%;
  font-size: 24px;
  color: #1d1d1b;
  font-style: italic;
  font-weight: bold;
  font-family: ${fonts.latoBlackItalic};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  width: 400px;
  /* height: 840px; */
  padding: 20px 20px 30px 20px;
  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    padding: 20px 20px 30px 20px;
  }
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 20px 20px 30px 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Rotule = styled.p`
  color: ${(props) =>
    props.valid ? colors.green_secondary : colors.dark_light};
  font-size: 16px;
  margin: 0;
  padding: 0;
`;

export const DivInputText = styled.div`
  width: 100%;
`;
export const InputText = styled.input`
  width: 100% !important;
  height: 40px;
  font-size: 16px;
  color: #1d1d1b;
  border: none;
  border-bottom: solid ${(props) => (props.valid ? '2px' : '1.5px')};
  border-color: ${(props) =>
    props.valid ? colors.green_secondary : colors.dark_light};

  &:focus {
    outline: 0;
  }
  &::-webkit-input-placeholder {
    color: ${colors.dark_light};
    font-style: italic;
    font-size: 16px;
  }
`;

export const Mandatory = styled.p`
  margin-top: 6px;
  padding: 0;
  font-size: 12px;
  color: ${colors.danger};
`;

export const ButtonSave = styled.button`
  width: 100%;
  height: 50px;
  color: ${colors.white};
  font-size: 18px;
  background-color: ${colors.green_secondary};
  border: none;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #0000004d;
  margin-top: 20px;
  &:disabled {
    background-color: ${colors.gray_secondary};
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    background: #2e6643;
  }
`;

export const ImgUserG = styled(ImgUser)`
  width: 80px;
  height: 80px;
  margin: 0;
  opacity: ${(props) => (props.hasUpload ? 0.7 : 1)};
`;

export const SpanTextProfile = styled.span`
  margin-top: 10.01px;
  color: ${colors.green_secondary};
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
`;

export const PEditProfile = styled.p`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
`;

export const DivInfoProfile = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const PNameProfile = styled.p`
  font-size: 24px;
  color: #1d1d1b;
  font-weight: bold;
`;

export const PTypeProfile = styled.p`
  font-size: 18px;
  color: #1d1d1b;
`;

export const TitleModalProfile = styled(TitleModal)`
  margin-bottom: 10px;
`;

export const DivProfile = styled.div`
  background: red;
  flex: 1;
  margin: auto;
`;

export const InfoUser = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const DivCards = styled.div`
  margin-top: 30px;
`;

export const TextCards = styled.div`
  margin-left: 20px;
  color: #1d1d1b;
  font-size: 16px;
  font-weight: bold;
`;

export const SpanCard = styled.span`
  margin-left: 6px;
`;

export const InternCard = styled.div`
  margin-left: 20px;
  padding-top: 17px;
  color: #50b275;
  font-weight: 14px;
  display: flex;
  align-items: center;
`;

export const Card = styled.div`
  cursor: pointer;
  width: 100%;
  min-height: 70px;
  margin-top: 20px;
  background: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  &:hover {
    background-color: ${colors.gray_four};
    color: ${colors.green_secondary};

    ${InternCard} {
      color: ${colors.green_secondary};
    }
  }

  &:active {
    background-color: #2e6643;
    color: ${colors.white};

    ${InternCard} {
      color: ${colors.white};
    }
  }
`;

export const InfosIntern = styled.div`
  margin-left: 20px;
`;
