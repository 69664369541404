import styled from 'styled-components';
import colors from '~/UI/colors';

export const Container = styled.div`
  width: 400px;
  height: 640px;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  font-family: 'Lato';
`;

export const InternDiv = styled.div`
  height: 90%;
  padding: 30px 30px 30px 30px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const TitleModal = styled.span`
  padding: 20px;
  font-size: 24px;
  color: #1d1d1b;
  font-style: italic;
  font-weight: bold;
  font-family: Lato Home;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const TitleModalGreen = styled(TitleModal)`
  color: ${colors.dark};
  margin-bottom: 30px;
`;

export const DivInputText = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const NumberBall = styled.div`
  background-color: ${colors.green_secondary};
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: none;
  margin-right: 10px;
  display: flex;
  color: ${colors.white};
  font-family: Lato Black;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const DivTip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const TextTip = styled.div`
  color: #1d1d1b;
  font-size: 18px;
  font-family: Lato Regular;
  text-align: justify;
`;
