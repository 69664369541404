import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import {AutoSuggestContainer} from './styles.js';

import apiBackend from '~/services/apiaxios';
import {desencrypta} from '~/utils';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

const getSubscribers = (value) =>
  apiBackend.get(
    `/v1/subscriber?term=${value?.trim()}&paginate=true&page=1&limit=5`,
    {
      headers: {
        Authorization: `Bearer ${desencrypta(
          localStorage.getItem('@User_informations'),
        )}`,
      },
    },
  );
const searchAPIDebounced = AwesomeDebouncePromise(getSubscribers, 600);

export default function AutoSuggestSubscribers({
  suggestItem,
  setSuggestItem,
  placeholder = '',
  value = '',
  setValue,
  handleChange,
  handleBlur,
}) {
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestionValue = (suggestion) => {
    /*   setSuggestItem(suggestion); */

    return suggestion.status;
  };

  const renderSuggestion = (suggestion) => (
    <div>
      <span>{suggestion.status}</span>
    </div>
  );

  const onSuggestionsFetchRequested = async ({value}) => {
    const newArray = await searchAPIDebounced(value);

    setSuggestions(newArray?.data?.subscribers?.data);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const shouldRenderSuggestions = (value) => {
    return value.trim().length >= 0;
  };

  const inputProps = {
    placeholder,
    value,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <AutoSuggestContainer>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion={true}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </AutoSuggestContainer>
  );
}

AutoSuggestSubscribers.propTypes = {
  suggestItem: PropTypes.object,
  setSuggestItem: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
};
