import {Grid} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import MyCSVDownload from '~/components/MyCSVDownload';
import apiBackend from '~/services/apiaxios';
import {ButtonSmall, ButtonTransparent} from '~/UI/styles';
import {desencrypta} from '~/utils';
import {ReportContainer} from './styles';
import {ClearFiltersButton} from '~/views/User/styles';
import ModalFilterAnouncement from '../../../components/ModalFilterAnouncement';
// import { Container } from './styles';
import MenuChangeObjectReq from '../MenuChanceObjectRequired';
import SearchComponent from '../SearchComponent';
import {getAlltypes} from '../utils/alltypes';
import RadioButtons from './RadioButton';
import {getAdress} from '../View/utils/finance';

import * as Sentry from '@sentry/react';

/* Convert price to search */
function transformPrice({value, locale}) {
  const data = value;

  if (locale === 'BRL' || locale === 'EUR') {
    const p1 = data.split('.').join('');

    const transform = parseFloat(p1);
    return transform;
  }

  const p1 = data.replace(/,/g, '');
  const transform = parseFloat(p1);
  return transform;
}
/* Convert area to search */
function transformArea({value, locale}) {
  const data = value;

  if (locale === 'm²') {
    const p1 = data.split('.').join('');

    const transform = parseFloat(p1);
    return transform;
  }

  const p1 = data.replace(/,/g, '');
  const transform = parseFloat(p1);
  return transform;
}

function FiltersSearch({
  I18nItens,
  itens,
  search,
  getAllProperties,
  getForReference,
  page,
  setPage,
  setProperties,
  query,
  setQuery,
  properties,
  status,
}) {
  const [userName, setUserName] = useState('');
  const [itensBackend, setItensBackend] = useState([]);
  const [itemTypeChoose, setItemTypeChoose] = useState('');
  const [openFilter, setOpenFilter] = useState(false);

  const [dateStart, setDateStart] = useState(null);
  const [dateFinish, setDateFinish] = useState(null);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [viewNorthLat, setViewNorthLat] = useState('');
  const [viewNorthLng, setViewNorthLng] = useState('');

  /* TODO novo */
  const [viewSouthLat, setViewSouthLat] = useState('');
  const [viewSouthLng, setViewSouthLng] = useState('');
  /*  */

  const [min_price, setMin_price] = useState('');
  const [max_price, setMax_price] = useState('');
  const [currency, setCurrency] = useState('BRL');
  const [area, setArea] = useState('m²');
  const [areaTotalMin, setAreaTotalMin] = useState('');
  const [areaTotalMax, setAreaTotalMax] = useState('');

  const [areaLiveMin, setAreaLiveMin] = useState('');
  const [areaLiveMax, setAreaLiveMax] = useState('');

  /* Reports*/
  const [csvData, setCsvData] = useState([]);
  const [hasSubmitting, setHasSubmitting] = useState(false);

  const [reference, setReference] = useState('');
  const [adsId, setAdsId] = useState('');
  const [clientType, setClientType] = useState('');

  const [searchFor, setSearchFor] = useState('1');

  useEffect(() => {
    setProperties([]);
    getAllProperties(setProperties, page, query);
  }, [page, query]);

  useEffect(() => {
    getAlltypes(setItensBackend);
  }, []);

  const generateReports = React.useCallback(async () => {
    try {
      setHasSubmitting(true);
      const token = desencrypta(localStorage.getItem('@User_informations'));
      const {data} = await apiBackend.get(
        `/v1/property?${
          query.toString() !== '' ? `&${query.toString()}` : ''
        }&status=${status}`,
        {
          headers: {Authorization: `Bearer ${token}`},
        },
      );

      const paymentsInCsv =
        data?.property?.length > 0
          ? data?.property
              ?.map((item) => {
                return {
                  ...item,
                  user: item.user.email,
                  address: getAdress(item?.address),
                  type_property: item?.type_property?.name_en,
                  property_style: item?.property_style?.name_en,
                  intention: null,
                  photo: null,
                  justification: null,
                };
              })
              .sort((a, b) => a.id - b.id)
              .filter(Boolean)
          : null;
      setCsvData(paymentsInCsv);
      setHasSubmitting(false);
    } catch (err) {
      setHasSubmitting(false);
      Sentry.captureException(err);
    }
  }, [query]);

  function handleFilter() {
    var query = new URLSearchParams();

    /* if !reference */
    if (searchFor === '1') {
      if (userName?.trim()) {
        query.append('term', userName?.trim());
      }
      if (dateStart?._isValid) {
        let initial =
          dateStart?._d?.toISOString()?.split('T')[0] + 'T00:00:00.000Z';

        query.append('initial', initial);
      }

      if (dateFinish?._isValid) {
        let end =
          dateFinish?._d?.toISOString()?.split('T')[0] + 'T23:59:59.000Z';
        query.append('end', end);
      }

      if (latitude) {
        query.append('latitude', latitude);
      }

      if (longitude) {
        query.append('longitude', longitude);
      }

      if (viewNorthLat) {
        query.append('view_lat', viewNorthLat);
      }

      if (viewNorthLng) {
        query.append('view_lon', viewNorthLng);
      }

      /* TODO novo */
      if (viewSouthLat) {
        query.append('view_lat2', viewSouthLat);
      }

      if (viewSouthLng) {
        query.append('view_lon2', viewSouthLng);
      }
      /*  */

      if (itemTypeChoose) {
        query.append('type_id', itemTypeChoose.id);
      }

      if (max_price) {
        query.append(
          'price_max',
          transformPrice({value: max_price, locale: currency}),
        );
      }

      if (currency) {
        query.append('coin', currency);
      }

      if (min_price) {
        query.append(
          'price_min',
          transformPrice({value: min_price, locale: currency}),
        );
      }

      if (areaTotalMax) {
        query.append(
          'total_max',
          transformArea({value: areaTotalMax, locale: area}),
        );
      }

      if (areaTotalMax) {
        query.append(
          'total_min',
          transformArea({value: areaTotalMin, locale: area}),
        );
      }

      if (areaLiveMin) {
        query.append(
          'built_min',
          transformArea({value: areaTotalMin, locale: area}),
        );
      }

      if (areaLiveMax) {
        query.append(
          'built_max',
          transformArea({value: areaTotalMin, locale: area}),
        );
      }

      query.append('built', area);
      query.append('total', area);
    } else if (searchFor === '2') {
      if (reference) {
        query.append('reference', reference);
      }
    } else if (searchFor === '3') {
      if (adsId) {
        query.append('property_id', adsId);
      }
    } else {
      if (clientType?.id) {
        query.append('type_advertiser', clientType.type);
      }
    }

    if (query.toString() === '' && page > 1) {
      setQuery(() => {
        setPage(1);
        return new URLSearchParams();
      });
    }

    setQuery(() => {
      setPage(1);
      return query;
    });
  }

  function search(event) {
    handleFilter();
  }

  function handleClearFilter() {
    setUserName('');
    setDateFinish(null);
    setDateStart(null);
    setLatitude('');
    setLongitude('');
    setViewNorthLat('');
    setViewNorthLng('');
    /* TODO novo */
    setViewSouthLat('');
    setViewSouthLng('');
    /*  */
    setMax_price('');
    setMin_price('');
    setAreaTotalMax('');
    setAreaTotalMin('');
    setAreaLiveMin('');
    setProperties([]);
    setReference('');
    setAdsId('');

    setQuery(() => {
      setPage(1);
      return new URLSearchParams();
    });
  }

  /*   useEffect(() => {
    console.log(searchFor);
     handleClearFilter();
  }, [searchFor]); */

  return (
    <>
      <div style={{marginTop: 5}}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <SearchComponent
              placeholder="Busca por nome ou email"
              handleChange={setUserName}
              value={userName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <MenuChangeObjectReq
              itensBackend={itensBackend}
              value={itemTypeChoose}
              setValue={setItemTypeChoose}
              placeHolder={'Busque por tipo de propriedade'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
            <ButtonSmall onClick={(event) => search(event)}>
              PESQUISAR
            </ButtonSmall>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
            <ButtonTransparent
              onClick={() => {
                setOpenFilter(true);
              }}>
              Mais Filtros
            </ButtonTransparent>
          </Grid>
        </Grid>
        {query?.toString() && (
          <ClearFiltersButton onClick={() => handleClearFilter()}>
            Limpar filtros
          </ClearFiltersButton>
        )}
        <ReportContainer>
          {properties?.length > 0 && (
            <MyCSVDownload
              data={csvData}
              handleGenerate={generateReports}
              loading={hasSubmitting}
              filename="PERMUTI_ads_reports.csv"
            />
          )}
        </ReportContainer>
      </div>

      <ModalFilterAnouncement
        open={openFilter}
        setOpen={setOpenFilter}
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateFinish={dateFinish}
        setDateFinish={setDateFinish}
        latitude={latitude}
        setLatitude={setLatitude}
        longitude={longitude}
        setLongitude={setLongitude}
        viewNorthLat={viewNorthLat}
        setViewNorthLat={setViewNorthLat}
        viewNorthLng={viewNorthLng}
        setViewNorthLng={setViewNorthLng}
        /* TODO novo */
        viewSouthLat={viewSouthLat}
        setViewSouthLat={setViewSouthLat}
        viewSouthLng={viewSouthLng}
        setViewSouthLng={setViewSouthLng}
        /*  */
        callBackGetUsers={handleFilter}
        min_price={min_price}
        setMin_price={setMin_price}
        max_price={max_price}
        setMax_price={setMax_price}
        currency={currency}
        setCurrency={setCurrency}
        area={area}
        setArea={setArea}
        areaTotalMin={areaTotalMin}
        setAreaTotalMin={setAreaTotalMin}
        areaTotalMax={areaTotalMax}
        setAreaTotalMax={setAreaTotalMax}
        areaLiveMin={areaLiveMin}
        setAreaLiveMin={setAreaLiveMin}
        areaLiveMax={areaLiveMax}
        setAreaLiveMax={setAreaLiveMax}
        selectedValues={searchFor}
        setSelectedValues={setSearchFor}
        reference={reference}
        setReference={setReference}
        adsId={adsId}
        setAdsId={setAdsId}
        clientType={clientType}
        setClientType={setClientType}
      />
    </>
  );
}

export default FiltersSearch;
