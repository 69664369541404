import styled from 'styled-components';
import colors from '../../UI/colors';

export const DivImg = styled.div`
  width: 260px;
  padding-right: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-content: flex-end;
`;

export const DivReturn = styled.div`
  cursor: pointer;
  &:hover {
    color: ${colors.dark_light};
  }
`;

export const LogoHeader = styled.img`
  width: 115px;
  height: 30.6px;
`;
