import React from 'react';
import * as Sentry from '@sentry/react';
/* import Routes2 from './routes/routes2'; */
import Routes from './routes/routes';
import GlobalStyle from './UI/global';
import './index.css';

function App() {
  return (
    <>
      <Routes />
      <GlobalStyle />
    </>
  );
}

export default Sentry.withProfiler(App);
