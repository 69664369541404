import styled from 'styled-components';
import colors from '../../../UI/colors';
import fonts from '../../../UI/fonts';

export const Container = styled.div``;

export const ButtonFilter = styled.button`
  height: 50px;
  display: flex;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px #00000029;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  color: ${colors.dark};
  padding: 0px 20px 0px 20px;
  font-size: 16px;
  font-family: ${(props) =>
    props.hasChoose ? fonts.latoRegular : fonts.latoRegularItalic};

  svg {
    transition: transform 0.2s linear;
    color: ${colors.green_secondary};
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }

  justify-content: space-between;

  &:focus {
    outline: 0;
  }
`;
