import React, {useState, useEffect} from 'react';
import {DivNext, TitleOptional, ButtonLocalize, CRDiv} from './styles';
import {TitlePage} from '../../Photo/styles';
import Autocomplete from 'react-google-autocomplete';
import styled from 'styled-components';
import colors from '../../../../../../UI/colors';
import './styles.css';
import InputText from '../../Components/inputText';
import {Label} from './styles';
import {Star} from '../../Components/styles';
import baseURL from '../../../../../../services/api';
import {desencrypta} from '../../../../../../utils';
import {MandatoryField, Container, Envolved} from '../../styles';
import SelectMenuRequired from '../../Components/selectMenuRequired';
import InputTextRequired from '../../Components/inputTextRequired';
import NextPrev from '../../Components/nextPrev';
import I18nCountries from '../../../../../../utils/translate/countries.json';
import MenuProfile from '../../../../../../components/Menu/MenuProfile';
import * as Sentry from '@sentry/react';

const itensCountry = ['Brazil', 'United States', 'Portugal'];

function LocalizeOne(props) {
  // Geocode.setApiKey("AIzaSyDYFZcGNmvU2_-LJ63uoVkbPVvxz3b_OcU");
  const [active, setActive] = useState(false);
  const [utf, setUtf] = useState('Br');
  const [loadGoogle, setLoadGoogle] = useState(false);
  const [digit, setDigit] = useState('');
  const [classGoogle, setClassGoogle] = useState('');
  const [classGoogleC, setClassGoogleC] = useState('');
  const [disable, setDisable] = useState(true);
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [label, setLabel] = useState('Cep');
  const [activeCep, setActiveCep] = useState(false);
  const [type, setType] = useState('');
  // Preciso disso
  const viewNumber = verifyTypeNumberOffice(type);
  const viewNumberFloor = verifyTypeStore(type);

  const [load, setLoad] = useState(false);

  const [selectedValueCountry, setSelectValueCountry] = useState('Brazil');
  const [adreess, setAdress] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [address, setAddress] = useState('');
  const [numb, setNumb] = useState('');
  const [streetSuffix, setStreetSuffix] = useState('');
  const [nApartment, setNApartment] = useState('');
  const [cep, setCep] = useState('');
  const [floor, setFloor] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [step, setStep] = useState('2');

  async function getProperties() {
    fetch(`${baseURL}/api/v1/properties/${props.match.params.id}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        Authorization: desencrypta(localStorage.getItem('@User_informations')),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setType(data.property_type && data.property_type);
        setSelectValueCountry(data.country && data.country);
        setState(data.state && data.state);
        setCity(data.city && data.city);
        setNeighborhood(data.neighborhood && data.neighborhood);
        setAddress(data.street_name && data.street_name);
        setDigit(data.street_name && data.street_name);
        setNumb(data.street_number && data.street_number);
        setStreetSuffix(data.street_sufix && data.street_sufix);
        setNApartment(data.unit_number && data.unit_number);
        setCep(data.zip_code && data.zip_code);
        setFloor(data.floor && data.floor);
        setLatitude(data.latitude && data.latitude);
        setLongitude(data.longitude && data.longitude);
        setLoad(true);
      })
      .catch((e) => {
        if (e.status === 401) {
          console.log(e);
        } else {
          Sentry.captureException(e);
        }
      });
  }

  useEffect(() => {
    getProperties();
  }, []);

  function handleProx(event) {
    event.preventDefault();
    saveItens();
  }

  function getLocation() {
    // if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(showPosition);
    // } else {
    // }
  }

  // function showPosition(position) {
  //     geocode(position.coords.latitude, position.coords.longitude);
  //     setLatitude(position.coords.latitude);
  //     setLongitude(position.coords.longitude);
  // }

  // function geocode(lat, lon) {
  //     Geocode.fromLatLng(lat, lon).then(
  //         response => {
  //             const address = response.results[0];
  //             insertButton(address, 1);
  //         },
  //         error => {
  //             console.error(error);
  //         }
  //     );
  // }

  function insertButton(place, number) {
    if (number == 1) {
      place.address_components.map((item) => {
        if (item.types[0] === 'route') {
          setAdress(item.long_name);
        } else if (item.types[0] === 'sublocality_level_1') {
          setNeighborhood(item.long_name);
        } else if (
          item.types[0] === 'administrative_area_level_2' ||
          item.types[0] === 'locality'
        ) {
          setCity(item.long_name);
        } else if (item.types[0] === 'administrative_area_level_1') {
          setState(item.short_name);
        } else if (item.types[0] === 'country') {
          setSelectValueCountry(item.long_name);
        } else if (item.types[0] === 'street_number') {
          setNumb(item.long_name);
        } else {
          if (
            item.types[0] === 'postal_code' ||
            item.types[0] === 'postal_code_prefix'
          ) {
            setCep(item.long_name);
            if (item.long_name) {
              setActiveCep(true);
            } else {
              setActiveCep(false);
            }
          } else {
          }
        }
      });
    }
  }

  async function saveItens() {
    fetch(`${baseURL}/api/v1/properties/` + props.match.params.id, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached

      headers: {
        'Content-Type': 'application/json',
        Authorization: desencrypta(localStorage.getItem('@User_informations')),
      },
      body: JSON.stringify({
        property: {
          country: selectedValueCountry,
          state: state,
          city: city,
          neighborhood: neighborhood,
          street_name: digit || address,
          street_number: numb,
          street_sufix: streetSuffix,
          unit_number: nApartment,
          zip_code: cep,
          floor: floor,
          latitude: latitude,
          longitude: longitude,
        },
      }), // body data type must match "Content-Type" header
    })
      .then(
        (response) =>
          (window.location.pathname = `view/edit/adjust/${props.match.params.id}`),
      )
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  useEffect(() => {
    if (selectedValueCountry === 'Brazil') {
      setUtf('Br');
    } else if (selectedValueCountry === 'United States') {
      setUtf('Us');
    } else if (selectedValueCountry === 'Portugal') {
      setUtf('Pt');
    } else {
      setUtf('Us');
    }

    setLoadGoogle(true);
  }, [selectedValueCountry]);

  useEffect(() => {
    if (digit && digit.length > 0) {
      setClassGoogle('value');
    } else {
      setClassGoogle('normal');
    }
  }, [digit, selectedValueCountry]);

  useEffect(() => {
    if (city && city.length > 0) {
      setClassGoogleC('value');
    } else {
      setClassGoogleC('normal');
    }
  }, [city, selectedValueCountry]);

  useEffect(() => {
    if (type === 'Condo' || type === 'Office') {
      if (
        city &&
        state &&
        neighborhood &&
        cep.length >= min - 1 &&
        numb &&
        floor &&
        nApartment &&
        (adreess || digit)
      ) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else if (
      city &&
      state &&
      neighborhood &&
      cep.length >= min - 1 &&
      numb &&
      (adreess || digit)
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [city, state, adreess, neighborhood, cep, numb, nApartment, floor]);

  function insert(place, number) {
    if (place.geometry.location.lat() && place.geometry.location.lng()) {
      setLatitude(place.geometry.location.lat());
      setLongitude(place.geometry.location.lng());
    }

    if (number === 1) {
      place.address_components.map((item) => {
        if (item.types[0] === 'route') {
          setAdress(item.long_name);
          setDigit(item.long_name);
        } else if (item.types[0] === 'sublocality_level_1') {
          setNeighborhood(item.long_name);
        } else if (
          item.types[0] === 'locality' ||
          (item.types[0] === 'administrative_area_level_2' && utf !== 'Us')
        ) {
          setCity(item.long_name);
        } else if (
          item.types[0] === 'administrative_area_level_1' &&
          utf !== 'Pt'
        ) {
          setState(item.short_name);
        } else if (item.types[0] === 'country') {
        } else if (item.types[0] === 'street_number') {
          setNumb(item.long_name);
        } else {
          if (
            item.types[0] === 'postal_code' ||
            item.types[0] === 'postal_code_prefix'
          ) {
            setCep(item.long_name);

            if (item.long_name) {
              setActiveCep(true);
            } else {
              setActiveCep(false);
            }
          } else {
          }
        }
      });
    }
  }

  useEffect(() => {
    setCep('');
    if (selectedValueCountry === 'United States') {
      setLabel('ZIP CODE');
      setMin(5);
      setMax(5);
    } else if (selectedValueCountry === 'Brazil') {
      setLabel('CEP');
      setMin(9);
      setMax(9);
    } else if (selectedValueCountry === 'Portugal') {
      setLabel('Código Postal');
      setMin(4);
      setMax(8);
    } else {
      setLabel('ZIP CODE');
      setMin(5);
      setMax(5);
    }
  }, [selectedValueCountry]);

  function cepNumber(event) {
    if (selectedValueCountry === 'United States') {
      setCep(
        event.target.value.replace(/[^0-9]/g, '').replace(/(\d{5})/, '$1'),
      );
    } else if (selectedValueCountry === 'Brazil') {
      setCep(
        event.target.value
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{5})(\d{3}).*/, '$1-$2'),
      );
    } else if (selectedValueCountry === 'Portugal') {
      setCep(event.target.value.replace(/^(\d{4})(\d{3}).*/, '$1-$2'));
    } else {
      setCep(event.target.value.replace(/[^0-9]/g, ''));
    }
  }

  function renderGoogle() {
    return (
      <Autocomplete
        value={digit}
        id="input-auto"
        style={{marginBottom: '20px'}}
        onChange={(event) => {
          setAdress(event.target.value);
          setDigit(event.target.value);
        }}
        onPlaceSelected={(place) => {
          setCep('');
          setState('');
          setCity('');
          setAdress('');
          setNeighborhood('');
          setDisable(false);
          insert(place, 1);
        }}
        placeholder={'Informe a Rua/Avenida'}
        types={['address']}
        className={classGoogle}
        componentRestrictions={{country: utf}}
      />
    );
  }

  function renderGoogleCity() {
    return (
      <Autocomplete
        value={city}
        id="input-auto"
        style={{marginBottom: '20px'}}
        onChange={(event) => {
          setCity(event.target.value);
        }}
        onPlaceSelected={(place) => {
          setCep('');
          setState('');
          setCity('');
          setNeighborhood('');
          setDisable(false);
          insert(place, 1);
        }}
        placeholder={'Qual a cidade?'}
        // types={["(administrative_area_level_2)"]}
        types={['(cities)']}
        className={classGoogleC}
        componentRestrictions={{country: utf}}
      />
    );
  }

  function verifyTypeNumberOffice() {
    if (type === 'Condo' || type === 'Office') {
      return true;
    } else {
      return false;
    }
  }

  function verifyTypeStore() {
    if (type === 'Store') {
      return true;
    } else {
      return false;
    }
  }

  if (load) {
    return (
      <>
        <MenuProfile />
        <div className="flex-page">
          <Container>
            <Envolved>
              <form style={{width: '100%'}} onSubmit={handleProx}>
                <TitlePage>Onde se localiza o seu imóvel?</TitlePage>
                {/* <ButtonLocalize type={"button"} onClick={() => getLocation()}>Usar localização atual</ButtonLocalize> */}
                {/* <TitleOptional>Ou insira o local nos campos abaixo.</TitleOptional> */}

                <MandatoryField>Campos Obrigatórios *</MandatoryField>

                <SelectMenuRequired
                  I18n={I18nCountries}
                  placeHolder={'Selecione o país/região...'}
                  label={'Qual país/região está o imóvel?'}
                  itens={itensCountry}
                  value={selectedValueCountry}
                  setValue={setSelectValueCountry}
                />

                <Label classes={classGoogle}>
                  Rua/Avenida <Star>*</Star>
                </Label>
                {utf === 'Br' && renderGoogle()}
                {utf === 'Us' && renderGoogle()}
                {utf === 'Pt' && renderGoogle()}

                <InputTextRequired
                  placeHolder={'Qual o nome do bairro?'}
                  label={'Bairro'}
                  value={neighborhood}
                  setValue={setNeighborhood}
                />

                <Label classes={classGoogleC}>
                  Cidade <Star>*</Star>
                </Label>
                {utf === 'Br' && renderGoogleCity()}
                {utf === 'Us' && renderGoogleCity()}
                {utf === 'Pt' && renderGoogleCity()}

                <InputTextRequired
                  placeHolder={'Qual o estado?'}
                  label={'Estado'}
                  value={state}
                  setValue={setState}
                />

                <CRDiv>
                  <InputTextRequired
                    placeHolder={'Qual o número do imóvel?'}
                    label={'Número'}
                    value={numb}
                    setValue={setNumb}
                  />
                  <InputTextRequired
                    min={min}
                    max={max}
                    mask={cepNumber}
                    placeHolder={'Endereço postal'}
                    label={label}
                    value={cep}
                    setValue={setCep}
                  />
                </CRDiv>

                {viewNumber ? (
                  <CRDiv>
                    <InputTextRequired
                      value={nApartment}
                      setValue={setNApartment}
                      placeHolder={'Qual o número do apartamento?'}
                      label={'Número do apartamento'}
                    />
                    <InputTextRequired
                      value={floor}
                      setValue={setFloor}
                      placeHolder={'Qual o andar?'}
                      label={'Número do andar'}
                    />
                  </CRDiv>
                ) : null}

                {viewNumberFloor ? (
                  <InputText
                    value={floor}
                    setValue={setFloor}
                    placeHolder={'Qual o andar?'}
                    label={'Número do andar'}
                  />
                ) : null}

                <InputText
                  value={streetSuffix}
                  setValue={setStreetSuffix}
                  placeHolder={'Qual o complemento'}
                  label={'Complemento'}
                />
                <DivNext>
                  <NextPrev
                    link={`view/edit/type/${props.match.params.id}`}
                    step={step}
                    setStep={setStep}
                    active={active}
                  />
                </DivNext>
              </form>
            </Envolved>
          </Container>
        </div>
        ;
      </>
    );
  } else {
    return <div></div>;
  }
}

export default LocalizeOne;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  display: flex;
  background-color: ${colors.white};
  border: none;
  border-bottom: ${(props) =>
    props.value ? '2px solid ' + colors.green_secondary : '#808076 1px solid'};
  color: ${(props) => (props.value ? '#1D1D1B' : '#808076')};
  width: 100%;
  font-size: 18px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  &:focus {
    outline: 0;
  }
  margin-bottom: 20px;
`;
