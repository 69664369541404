import styled from 'styled-components';
import fonts from '~/UI/fonts';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${(props) => props.mg || 0};

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.latoRegular};
  font-size: 16px;
  margin-left: ${(props) => (props.hasSpace ? '25px' : '0px')};

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  color: ${(props) => (props.checked ? '#1D1D1B' : '#808076')};
  font-family: 'Lato Regular Bold';
`;
