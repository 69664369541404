import styled from 'styled-components';

export const ActionsContainer = styled.div`
  margin-top: 5px;

  @media (max-width: 571px) {
    button {
      width: 100%;
    }
  }
`;

export const InputField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ContainerPagination = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  width: 100%;
  display: grid;
  place-items: center;
`;
export const ReportContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 20px;
`;
