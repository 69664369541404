/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import VMasker from 'vanilla-masker';
import * as validate from '~/utils/regex';
import {
  TitleModal,
  InternDiv,
  Rotule,
  DivInputText,
  InputText,
  DivError,
  ErrorMessage,
  ButtonSave,
} from '../ModalDataPersonal/styles';
import '../styles.css';

import AddressInput from '../../components/AddressInput';

import CloseIcon from '@material-ui/icons/Close';
import {Dialog} from '@material-ui/core';

import ReactLoading from 'react-loading';
import colors from '~/UI/colors';

import {handleChangeCepMask} from '~/utils/masks';

export default function ModalAddress({
  open,
  setOpen,
  profile,
  handleSubmit,
  loading,
}) {
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [lastPlaceChoose, setLastPlaceChoose] = useState(null);

  useEffect(() => {
    setCountry(profile?.profile?.country ? profile?.profile?.country : '');
    setState(profile?.profile?.state ? profile?.profile?.state : '');
    setCity(profile?.profile?.city ? profile?.profile?.city : '');
    setCep(profile?.profile?.zip_code ? profile?.profile?.zip_code : '');
    setAddress(profile?.profile?.address ? profile?.profile?.address : '');
    setNeighborhood(
      profile?.profile?.neighborhood ? profile?.profile?.neighborhood : '',
    );
    setLatitude(profile?.profile?.latitude ? profile?.profile?.latitude : '');
    setLongitude(
      profile?.profile?.longitude ? profile?.profile?.longitude : '',
    );
    setNumber(
      profile?.profile?.address_number ? profile?.profile?.address_number : '',
    );
    setComplement(
      profile?.profile?.address_complement
        ? profile?.profile?.address_complement
        : '',
    );
    setLastPlaceChoose(
      profile?.profile?.address
        ? {
            address_components: [
              {
                long_name: profile?.profile?.address,
                types: ['route'],
              },
            ],
          }
        : null,
    );
  }, [profile]);

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const resetForm = () => {
    setCountry(profile?.profile?.country ? profile?.profile?.country : '');
    setState(profile?.profile?.state ? profile?.profile?.state : '');
    setCity(profile?.profile?.city ? profile?.profile?.city : '');
    setCep(profile?.profile?.zip_code ? profile?.profile?.zip_code : '');
    setAddress(profile?.profile?.address ? profile?.profile?.address : '');
    setNeighborhood(
      profile?.profile?.neighborhood ? profile?.profile?.neighborhood : '',
    );
    setLatitude(profile?.profile?.latitude ? profile?.profile?.latitude : '');
    setLongitude(
      profile?.profile?.longitude ? profile?.profile?.longitude : '',
    );
    setNumber(
      profile?.profile?.address_number ? profile?.profile?.address_number : '',
    );
    setComplement(
      profile?.profile?.address_complement
        ? profile?.profile?.address_complement
        : '',
    );
    setLastPlaceChoose(
      profile?.profile?.address
        ? {
            address_components: [
              {
                long_name: profile?.profile?.address,
                types: ['route'],
              },
            ],
          }
        : null,
    );
    setErrors({});
    setTouched({});
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  /* ---------- */
  /* ---------- */
  /* ---------- */
  /* ------------------- */
  /* Country */
  const handleChangeCountry = (value) => {
    setCountry(value);

    setErrors((errors) => {
      return {
        ...errors,
        country: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 2
          ? 'Mínimo de 2 caracteres'
          : '',
      };
    });
  };

  const handleBlurCountry = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        country: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        country: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 2
          ? 'Mínimo de 2 caracteres'
          : '',
      };
    });
  };
  /* ------------------- */
  /* State */
  const handleChangeState = (value) => {
    setState(value);

    setErrors((errors) => {
      return {
        ...errors,
        state: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 2
          ? 'Mínimo de 2 caracteres'
          : '',
      };
    });
  };

  const handleBlurState = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        state: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        state: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 2
          ? 'Mínimo de 2 caracteres'
          : '',
      };
    });
  };
  /* ------------------- */
  /* City */
  const handleChangeCity = (value) => {
    setCity(value);

    setErrors((errors) => {
      return {
        ...errors,
        city: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  const handleBlurCity = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        city: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        city: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? 'Mínimo de 3 caracteres'
          : '',
      };
    });
  };

  /* ------------------- */
  /* Cep */
  const handleChangeCep = (value) => {
    setCep(value);

    setErrors((errors) => {
      return {
        ...errors,
        cep: !value.trim()
          ? 'Campo não preenchido'
          : !validate.cepRegex.test(value)
          ? 'CEP inválido'
          : '',
      };
    });
  };

  const handleBlurCep = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        cep: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        cep: !value.trim()
          ? 'Campo não preenchido'
          : !validate.cepRegex.test(value)
          ? 'CEP inválido'
          : '',
      };
    });
  };

  /* ------------------- */
  /* Address */
  const handleChangeAddress = (value) => {
    setAddress(value);

    setErrors((errors) => {
      return {
        ...errors,
        address: !value.trim() ? 'Campo não preenchido' : '',
      };
    });
  };

  const handleBlurAddress = (value) => {
    if (lastPlaceChoose) {
      const route = lastPlaceChoose?.address_components?.find(
        (item) => item.types[0] === 'route',
      );
      if (route) {
        route.long_name !== value && setAddress(route.long_name);
      }
    }

    setTouched((toucheds) => {
      return {
        ...toucheds,
        address: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        address: !value.trim()
          ? 'Campo não preenchido'
          : !latitude && !longitude
          ? 'Escolha uma opção'
          : '',
      };
    });
  };

  /* ------------------- */
  /* Neighborhood */
  const handleChangeNeighborhood = (value) => {
    setNeighborhood(value);

    setErrors((errors) => {
      return {
        ...errors,
        neighborhood: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? `Mínimo de 3 caracteres`
          : '',
      };
    });
  };

  const handleBlurNeighborhood = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        neighborhood: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        neighborhood: !value.trim()
          ? 'Campo não preenchido'
          : value.trim().length < 3
          ? `Mínimo de 3 caracteres`
          : '',
      };
    });
  };

  /* ------------------- */
  /* Complement */
  const handleChangeComplement = (value) => {
    setComplement(value);

    setTouched((toucheds) => {
      return {
        ...toucheds,
        address: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        complement:
          value.trim() && value.trim().length < 3
            ? 'Mínimo de 3 caracteres'
            : '',
      };
    });
  };

  const handleBlurComplement = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        complement: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        complement:
          value.trim() && value.trim().length < 3
            ? 'Mínimo de 3 caracteres'
            : '',
      };
    });
  };

  /* ------------------- */
  /* Number */
  const handleChangeNumber = (value) => {
    setNumber(value);

    setErrors((errors) => {
      return {
        ...errors,
        number: !value.trim() ? 'Campo não preenchido' : '',
      };
    });
  };

  const handleBlurNumber = (value) => {
    setTouched((toucheds) => {
      return {
        ...toucheds,
        number: true,
      };
    });

    setErrors((errors) => {
      return {
        ...errors,
        number: !value.trim() ? 'Campo não preenchido' : '',
      };
    });
  };
  /* ---------- */
  /* ---------- */
  /* ---------- */

  function editAddress(event) {
    event.preventDefault();

    const userUpdated = {
      profile: {
        country: country.trim(),
        state: state.trim(),
        city: city.trim(),
        neighborhood: neighborhood.trim(),
        address: address.trim(),
        address_number: number.trim(),
        address_complement: complement.trim(),
        zip_code: cep.trim(),
        latitude,
        longitude,
      },
    };

    handleSubmit(userUpdated);
  }

  return (
    <div>
      <Dialog
        scroll="body"
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <TitleModal>
          <span>Endereço</span>
          <CloseIcon onClick={handleClose} className={'iconClose'} />
        </TitleModal>
        <form onSubmit={editAddress} style={{padding: '20px'}}>
          <InternDiv>
            <div>
              <DivInputText>
                <Rotule error={errors.country} valid={country}>
                  País/Região *
                </Rotule>
                <InputText
                  error={errors.country}
                  touched={touched.country}
                  value={country}
                  onChange={(event) => handleChangeCountry(event.target.value)}
                  onBlur={(event) => handleBlurCountry(event.target.value)}
                  placeholder="Informe o país/região que reside"
                />
                {errors.country && (
                  <DivError>
                    <ErrorMessage>{errors.country}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.state} valid={state}>
                  Estado *
                </Rotule>
                <InputText
                  error={errors.state}
                  touched={touched.state}
                  value={state}
                  onChange={(event) => handleChangeState(event.target.value)}
                  onBlur={(event) => handleBlurState(event.target.value)}
                  placeholder="Informe o estado..."
                />
                {errors.state && (
                  <DivError>
                    <ErrorMessage>{errors.state}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.city} valid={city}>
                  Cidade *
                </Rotule>
                <InputText
                  error={errors.city}
                  touched={touched.city}
                  value={city}
                  onChange={(event) => handleChangeCity(event.target.value)}
                  onBlur={(event) => handleBlurCity(event.target.value)}
                  placeholder="Informe a cidade..."
                />
                {errors.city && (
                  <DivError>
                    <ErrorMessage>{errors.city}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.cep} valid={cep}>
                  CEP *
                </Rotule>
                <InputText
                  error={errors.cep}
                  touched={touched.cep}
                  value={cep}
                  onChange={(event) =>
                    handleChangeCep(
                      VMasker.toPattern(
                        event.target.value,
                        handleChangeCepMask(event.target.value),
                      ),
                    )
                  }
                  onBlur={(event) => handleBlurCep(event.target.value)}
                  placeholder="Insira o CEP"
                />
                {errors.cep && (
                  <DivError>
                    <ErrorMessage>{errors.cep}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <AddressInput
                hasMargin
                label="Endereço"
                placeholder="Informe o endereço..."
                handleChange={handleChangeAddress}
                handleBlur={handleBlurAddress}
                value={address}
                setValue={setAddress}
                setLat={setLatitude}
                setLong={setLongitude}
                error={errors.address}
                setErrors={setErrors}
                touched={touched.address}
                setPlace={setLastPlaceChoose}
              />

              <DivInputText className="divMargin">
                <Rotule error={errors.neighborhood} valid={neighborhood}>
                  Bairro *
                </Rotule>
                <InputText
                  error={errors.neighborhood}
                  touched={touched.neighborhood}
                  value={neighborhood}
                  onChange={(event) =>
                    handleChangeNeighborhood(event.target.value)
                  }
                  onBlur={(event) => handleBlurNeighborhood(event.target.value)}
                  placeholder="Informe o bairro..."
                />
                {errors.neighborhood && (
                  <DivError>
                    <ErrorMessage>{errors.neighborhood}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.number} valid={number}>
                  Número *
                </Rotule>
                <InputText
                  error={errors.number}
                  touched={touched.number}
                  value={number}
                  onChange={(event) => handleChangeNumber(event.target.value)}
                  onBlur={(event) => handleBlurNumber(event.target.value)}
                  placeholder="Número/código"
                />
                {errors.number && (
                  <DivError>
                    <ErrorMessage>{errors.number}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>

              <DivInputText className="divMargin">
                <Rotule error={errors.complement} valid={complement}>
                  Complemento
                </Rotule>
                <InputText
                  error={errors.complement}
                  touched={touched.complement}
                  value={complement}
                  onChange={(event) =>
                    handleChangeComplement(event.target.value)
                  }
                  onBlur={(event) => handleBlurComplement(event.target.value)}
                  placeholder="Insira o apartamento"
                />
                {errors.complement && (
                  <DivError>
                    <ErrorMessage>{errors.complement}</ErrorMessage>
                  </DivError>
                )}
              </DivInputText>
            </div>
            <ButtonSave
              disabled={
                !country.trim() ||
                country.trim().length < 3 ||
                !state.trim() ||
                state.trim().length < 2 ||
                !city.trim() ||
                city.trim() < 3 ||
                !cep.trim() ||
                !validate.cepRegex.test(cep) ||
                !address.trim() ||
                !neighborhood.trim() ||
                neighborhood.trim() < 3 ||
                !complement.trim() ||
                complement.trim().length < 5 ||
                !number.trim() ||
                !latitude ||
                !longitude ||
                loading
              }
              className={'btnDataAddress btnload'}>
              {loading ? (
                <ReactLoading
                  type={'spin'}
                  className="load"
                  color={colors.green_secondary}
                  width={'30px'}
                />
              ) : (
                'Editar'
              )}
            </ButtonSave>
          </InternDiv>
        </form>
      </Dialog>
    </div>
  );
}
