import {Grid} from '@material-ui/core';
import React from 'react';
import I18n from '~/i18n/i18';
import {useLocation} from 'react-router-dom';

import {ContainerIntentions, Value} from './styles';
import {transformCurrency} from '~/views/Annoucement/utils';

function formatMoney(price, curr, currency, location) {
  const curSymb = I18n.getTranslation(
    location,
    `CurrencySymbol.${
      transformCurrency(
        {
          price: price,
          currency: curr,
        },
        currency,
      )[0]
    }`,
  );

  const money = transformCurrency(
    {
      price: price,
      currency: curr,
    },
    currency,
  )[1];

  return `${curSymb} ${money}`;
}

function mountPhrase(intention, currency, langName, location) {
  const typeProperty = intention?.type_property
    ? intention?.type_property?.name_pt
    : '';

  const neighborhood = intention?.neighborhood ? intention.neighborhood : '';
  const city = intention?.city ? intention?.city : '';
  const uf = intention?.state ? intention?.state : '';
  const country = intention?.country
    ? I18n.getTranslation(location, `Country.${intention?.country}`)
    : '';

  const address = [neighborhood, city, uf, country]?.filter(
    (item) => item !== '',
  );

  const minPrice = intention?.min_price
    ? ` a partir de ${formatMoney(
        intention?.min_price,
        intention?.currency,
        currency,
        location,
      )}`
    : '';
  const maxPrice = intention?.max_price
    ? ` até ${formatMoney(
        intention?.max_price,
        intention?.currency,
        currency,
        location,
      )}`
    : '';

  const concat = I18n.getTranslation(location, 'ViewAds.In');

  const finalPhrase = `${typeProperty} ${concat} ${address?.join(
    ', ',
  )}${minPrice}${maxPrice}`;

  return finalPhrase;
}

function ItemAccordion({currency, intention, langName}) {
  const location = useLocation();

  return (
    <Grid item xs={12} sm={12} md lg xl>
      <ContainerIntentions>
        <Value>{mountPhrase(intention, currency, langName, location)}</Value>
      </ContainerIntentions>
    </Grid>
  );
}

export default ItemAccordion;
