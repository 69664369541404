import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import deleteImg from '~/assets/delete.svg';
import dollarImg from '~/assets/dollar-sign.svg';
import editImg from '~/assets/edit.svg';
import colors from '../../../../UI/colors';
import fonts from '../../../../UI/fonts';
import {Container, Icons} from './styles';
import ModalDeleteLead from '../../../../components/ModalDeleteLead';

const styleItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  fontFamily: fonts.latoRegularBold,
  color: colors.dark,
};

const svgStyle = {
  color: colors.green_secondary,
  marginRight: 10,
  width: 26,
  height: 26,
  flex: 'none',
};

export default function ManagerOptionsLead({
  info,
  getAllProperties,
  getAllPropertiesPrice,
  getAllLeads,
}) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);

  /* --- MODALS --- */
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalPrice, setModalPrice] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditPhotos = (info) => {
    alert('Feature em desenvolvimento.');
    /*  history.push({
      pathname: `/view/${info.id}/edit`,
      state: {
        id: info.id,
        step: '1',
        property: info,
      },
    }); */
  };

  return (
    <>
      <Container>
        <Icons onClick={handleClick} isOpen={Boolean(anchorEl)}>
          <MoreHorizIcon />
        </Icons>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setOpenCollapse(!openCollapse);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <MenuItem
            style={styleItem}
            onClick={() => {
              history.push('/real-state/register', {info: info});
            }}>
            <img src={editImg} alt="Editar" style={svgStyle} /> Atender/Vender
          </MenuItem>

          <MenuItem
            style={styleItem}
            onClick={() => {
              setModalDelete(true);
              setAnchorEl(null);
            }}>
            <img src={deleteImg} alt="Deletar" style={svgStyle} /> Excluir
          </MenuItem>
        </Popover>
      </Container>
      {/* ---- MODAIS ---- */}

      <ModalDeleteLead
        open={modalDelete}
        setOpen={setModalDelete}
        inf={info}
        getAllLead={getAllLeads}
      />
    </>
  );
}
