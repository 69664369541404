import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import './styles.css';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  TableContainer,
  TitleBold,
  P,
  TitleBoldView,
  RowPhotoName,
  Results,
  RowClick,
} from './styles';
import {Icons} from '../../../../UI/styles';

import ModalDelete from '../../../../components/ModalDelete';
import MenuChoose from '../Menu';
import ModalEditColaborator from '../ModalEdit';

import {translateAccountRoleId} from '~/utils/profileType';

const permitted_roles = ['5'];

export default function TableColaborators({
  infoUser,
  info,
  setInfo,
  setModalRegister,
  setModalEdit,
  setModal,
  setModalInfo,
  total,
  listUser,
  loading,
  page,
  setPage,
}) {
  const userStore = useSelector((state) => state.user);
  const [sortName, setSortName] = useState(false);
  const [sortType, setSortType] = useState(false);
  const [sortJob, setSortJob] = useState(false);
  const [sortDate, setSortDate] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState('');
  const [colab, setColab] = useState('');
  const [proibid, setProibid] = useState(false);

  function NameSort() {
    let object = [...info];
    let value = !sortName;
    setSortName(!sortName);

    setSortType(false);
    setSortJob(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function TypeUserSort() {
    let object = [...info];
    let value = !sortType;
    setSortType(!sortType);

    setSortName(false);
    setSortJob(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a.role_id > b.role_id) {
          return -1;
        }
        if (a.role_id < b.role_id) {
          return 1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a.role_id > b.role_id) {
          return 1;
        }
        if (a.role_id < b.role_id) {
          return -1;
        }
        return 0;
      });
    }
    setInfo(object);
  }

  function JobSort() {
    let object = [...info];
    let value = !sortJob;
    setSortJob(!sortJob);

    setSortType(false);
    setSortName(false);
    setSortDate(false);

    if (value) {
      object.sort((a, b) => {
        if (a.cargo > b.cargo) {
          return -1;
        }
        if (a.cargo < b.cargo) {
          return 1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        if (a.cargo > b.cargo) {
          return 1;
        }
        if (a.cargo < b.cargo) {
          return -1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function DateSort() {
    let object = [...info];
    let value = !sortDate;
    setSortDate(!sortDate);

    setSortType(false);
    setSortName(false);
    setSortJob(false);

    if (value) {
      object.sort((a, b) => {
        let d1 = Date.parse(a.created_at);
        let d2 = Date.parse(b.created_at);
        if (d1 > d2) {
          return 1;
        } else if (d1 < d2) {
          return -1;
        }
        return 0;
      });
    } else {
      object.sort((a, b) => {
        let d1 = Date.parse(a.created_at);
        let d2 = Date.parse(b.created_at);
        if (d1 > d2) {
          return -1;
        } else if (d1 < d2) {
          return 1;
        }
        return 0;
      });
    }

    setInfo(object);
  }

  function dates(info) {
    let date = new Date(info);
    return (
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
      '/' +
      (date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()) +
      '/' +
      date.getFullYear()
    );
  }

  const isPermitted = React.useCallback(
    () => permitted_roles.includes(userStore?.role_id?.toString()),
    [userStore?.role_id],
  );

  /*   useEffect(() => {
    console.log(isPermitted());
  }, [isPermitted]); */

  return (
    <>
      <Results>{total} resultados encontrados</Results>

      <TableContainer>
        <Grid
          className="tableMobile"
          container
          spacing={3}
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderBottom: '2px solid #BFBFB2',
          }}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <RowClick onClick={() => NameSort()}>
              <TitleBoldView className="tableMobile">Nome</TitleBoldView>
              <span>{sortName ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => JobSort()}>
              <TitleBoldView>Cargo</TitleBoldView>{' '}
              <span>{sortJob ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={1} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => TypeUserSort()}>
              <TitleBoldView>Tipo de Acesso</TitleBoldView>{' '}
              <span>{sortType ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid item xs={1} sm={6} md={2} lg={2} xl={2}>
            <RowClick onClick={() => DateSort()}>
              <TitleBoldView>Data de Criação</TitleBoldView>{' '}
              <span>{sortDate ? <ExpandLess /> : <ExpandMore />}</span>
            </RowClick>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={1}
            lg={1}
            xl={1}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}>
            <TitleBoldView>Ações</TitleBoldView>
          </Grid>
        </Grid>
        {info?.map((information, key) => {
          return (
            <Grid key={key} container spacing={3} className="item__list">
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <TitleBold>Nome</TitleBold>
                <RowPhotoName>
                  {/*  <ImgUser
                    src={userImg}
                    width="40px"
                    style={{marginRight: '20px'}}
                  />{' '} */}
                  {information?.name} {information?.surname ?? ''}
                </RowPhotoName>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Cargo</TitleBold>
                <P>{information?.cargo}</P>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Tipo de Acesso</TitleBold>
                <P>{translateAccountRoleId(information?.role_id)}</P>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <TitleBold>Data de Criação</TitleBold>
                <P>{dates(information?.created_at)}</P>
              </Grid>
              <Grid
                className="buttonActions"
                item
                xs={12}
                sm={4}
                md={2}
                lg={2}
                xl={2}
                style={{}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Icons
                    style={{flex: 'none'}}
                    onClick={() => {
                      setModal(true);
                      setModalInfo(information);
                    }}>
                    <MoreHorizIcon />
                  </Icons>
                  {isPermitted() && (
                    <Icons
                      style={{flex: 'none'}}
                      onClick={() => {
                        setColab(information);
                        setOpenEdit(true);
                      }}>
                      <EditOutlinedIcon />
                    </Icons>
                  )}
                  {isPermitted() && (
                    <Icons
                      style={{flex: 'none'}}
                      onClick={() => {
                        setId(information.id);
                        setOpenDelete(true);
                      }}>
                      <DeleteIcon />
                    </Icons>
                  )}
                </div>
              </Grid>
            </Grid>
          );
        })}
      </TableContainer>
      {isPermitted() && (
        <>
          <ModalDelete
            open={openDelete}
            setOpen={setOpenDelete}
            /* ------ */
            id={id}
            page={page}
            setPage={setPage}
            listUser={listUser}
            text={'Colaborador'}
          />
          <ModalEditColaborator
            page={page}
            listUser={listUser}
            colab={colab}
            setColab={setColab}
            open={openEdit}
            setOpen={setOpenEdit}
          />
        </>
      )}
    </>
  );
}
