import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useDispatch} from 'react-redux';
import colors from '~/UI/colors';
import {
  DivButton,
  ButtonBack,
  ButtonForward,
  DivButtonIntern,
  TextForward,
  TextBack,
} from './styles';
/* import addMsgAction from '~/redux/action/action_add_user'; */
import I18n from '~/i18n/i18';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function NextPrevNumber({active, link, step, setStep, number, handleSubmit}) {
  const dispatch = useDispatch();

  function alterBool() {
    // dispatch(addMsgAction(false));
  }

  function returnStep(event) {
    event.preventDefault();
    alterBool();
    setStep((step - number).toString());
  }

  return (
    <DivButton>
      <DivButtonIntern>
        <ButtonBack
          type="button"
          onClick={(event) => {
            returnStep(event);
          }}>
          {/* <ArrowBackIosIcon style={{color: colors.green_secondary}} /> */}
          <FontAwesomeIcon icon={faChevronLeft} />
        </ButtonBack>
        <TextBack>
          <I18n t="NextPrevComponent.back" />
        </TextBack>
      </DivButtonIntern>
      <DivButtonIntern>
        <TextForward active={active}>
          <I18n t="NextPrevComponent.next" />
        </TextForward>
        <ButtonForward
          type="button"
          disabled={!active}
          onClick={(event) => handleSubmit(event)}>
          {/* <ArrowForwardIosIcon style={{color: colors.white}} /> */}
          <FontAwesomeIcon icon={faChevronRight} />
        </ButtonForward>
      </DivButtonIntern>
    </DivButton>
  );
}

export default NextPrevNumber;
