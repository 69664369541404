import React, {useState} from 'react';
import {DivButtonsSwitch, ButtonsSwitch} from './styles';
import {Grid} from '@material-ui/core';
import colors from '../../UI/colors';
import {useLocation, useHistory} from 'react-router-dom';
import {useEffect} from 'react';

function ButtonsAd() {
  const location = useLocation();
  const [currentPressed, setCurretPressed] = useState('0');
  let history = useHistory();

  function compare(value) {
    switch (value) {
      case '/announcement':
        return setCurretPressed('0');
      case '/announcement/':
        return setCurretPressed('0');
      case '/announcement/approved':
        return setCurretPressed('1');
      case '/announcement/disapprove':
        return setCurretPressed('2');
      case '/announcement/drafts':
        return setCurretPressed('3');
      case '/announcement/inactive':
        return setCurretPressed('4');
      default:
        return setCurretPressed('0');
    }
  }

  useEffect(() => {
    let currentLocation = location.pathname;
    console.log(currentLocation);
    compare(currentLocation);
  }, [location]);

  function handleClick(event, route) {
    event.preventDefault();

    if (route) {
      compare('/announcement/' + route);
      history.push('/announcement/' + route);
    } else {
      history.push('/announcement/');
      compare('/announcement');
    }
  }

  return (
    <DivButtonsSwitch>
      <Grid container /* spacing={1} */>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <ButtonsSwitch
            pressed={currentPressed === '0' ? 1 : 0}
            onClick={(event) => handleClick(event, '')}
            color={'#A88721'}>
            Pendentes Aprovação
          </ButtonsSwitch>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <ButtonsSwitch
            pressed={currentPressed === '1' ? 1 : 0}
            onClick={(event) => handleClick(event, 'approved')}
            color="#6B915C">
            Aprovados
          </ButtonsSwitch>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <ButtonsSwitch
            pressed={currentPressed === '2' ? 1 : 0}
            onClick={(event) => handleClick(event, 'disapprove')}
            color="#CC2C29">
            Desaprovados
          </ButtonsSwitch>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <ButtonsSwitch
            pressed={currentPressed === '3' ? 1 : 0}
            onClick={(event) => handleClick(event, 'drafts')}
            color="#999991">
            Rascunhos
          </ButtonsSwitch>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
          <ButtonsSwitch
            pressed={currentPressed === '4' ? 1 : 0}
            onClick={(event) => handleClick(event, 'inactive')}
            color="#1D1D1B">
            Inativos
          </ButtonsSwitch>
        </Grid>
      </Grid>
    </DivButtonsSwitch>
  );
}

export default ButtonsAd;
